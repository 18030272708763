// tslint:disable: object-literal-sort-keys
/**
 * definition of an Address with all data returnable from Google Maps service
 */

export interface IAddressPart {
  shortName: string;
  longName: string;
}

export interface IAddress {
  street: IAddressPart;
  nbr: IAddressPart;
  city: IAddressPart;
  region: IAddressPart;
  state: IAddressPart;
  zipCode: IAddressPart;
  country: IAddressPart;
  formatedAddress: string; // Google translated address
  placeId: string;
  long: string;
  lat: string;
  unformatedAddress: string; // UI Input field
  isChanged: boolean; // Trigger for google cloud function to recalculate the address
}
