import {
  DOCUMENTTYPE,
  TaxOfficeLetter,
  Timestamp,
} from '@expresssteuer/models';

/**
 * Request of finance office to provide information with due date
 */
export abstract class TaxInformationRequest extends TaxOfficeLetter {
  documentType: DOCUMENTTYPE.taxoffice_request = DOCUMENTTYPE.taxoffice_request;
  /**
   * current due date
   */
  abstract dueDate: Timestamp | null;
  /**
   * amount of reminders for this request letter
   */
  abstract reminderCount: number | null;
  /**
   * amount of times, we extended the due date
   */
  abstract extendedDueDateCount: number;
  /**
   * list of associated reminders
   */
  abstract taxOfficeReminderIds: string[] | null;
  /**
   * list of all content block generated for this request
   */
  abstract generatedBlocksIds: string[] | null;
  /**
   * list of blocks that were marked as completed
   */
  abstract completedBlockIds: string[] | null;
}
