import { DOCUMENTTYPE, TaxOfficeLetter } from '@expresssteuer/models';

export abstract class TaxOfficeInformation extends TaxOfficeLetter {
  documentType: DOCUMENTTYPE.taxoffice_information =
    DOCUMENTTYPE.taxoffice_information;
  /**
   * list of all content block generated for this request
   */
  abstract generatedBlocksIds: string[] | null;
  /**
   * list of blocks that were marked as completed
   */
  abstract completedBlockIds: string[] | null;
}
