export class BaseMessageTemplate {
  public id? = '';
  public message = '';
  public name? = '';
  public subject? = '';

  public usage?: {
    email: boolean;
    whatsapp: boolean;
  } = {
    email: false,
    whatsapp: false,
  };
}

export class WhatsappTemplate extends BaseMessageTemplate {}

export class EmailTemplate extends BaseMessageTemplate {}
