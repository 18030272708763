import { Component } from '@angular/core';
import { AuthService } from '../../../app/services/auth/auth.service';
import { BusinessService } from '../../../app/services/business/business.service';
import { ClientService } from '../../../app/services/client/client.service';

@Component({
  selector: 'app-debug',
  templateUrl: './debug.component.html',
  styleUrls: ['./debug.component.scss'],
})
export class DebugComponent {
  fontsize = 14;
  collapsed = true;
  constructor(
    public authService: AuthService,
    public clientService: ClientService,
    public businessService: BusinessService
  ) {}
}
