/**
 * Stripe data to keep local
 */
export class PaymentStripe {
  public static getTemplate(): PaymentStripe {
    return {
      customerId: '',
      clientSecret: '',
      defaultPaymentMethodId: '',
      sourceId: '',
      mandate: false,
      mandateId: '',
    };
  }

  /**
   * The customerId as she is stored on stripe
   */
  customerId: string = '';
  /**
   * The client secret to be stored to do some further actions
   * This needs to be reconsidered if we realy store it here.
   * Usualy the secret key is to make transaction on session with the client secure.
   * However, in order to do some off session manipulation we store the key.
   */
  clientSecret: string = '';
  /**
   * If the client has a payment method, this is his default payment method.
   * If the client has multiple payment methods, the defaut one needs to be set
   */
  defaultPaymentMethodId: string = '';
  /**
   * The source id is a payment source id.
   * Behind the source can be a card, apple pay or sepa
   */
  sourceId: string = '';
  /**
   * If we have a valid mandate.
   * The mandate allows us to collect money from the payment method off session.
   * If mandate = true, we will collect money directly. If no mandate, we send the invoice for payment
   */
  mandate: boolean = false;
  mandateId: string = '';
}
