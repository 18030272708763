import { Timestamp } from '@google-cloud/firestore';
import { Message, MESSAGETYPE } from '../message';
import { MessageAttachment } from '../messageAttachment';
import { IFrom } from './inboxFrom';

export class InboxMessage implements IFrom {
  public static getFromMessage(message: Message): InboxMessage {
    const fromEmail =
      message.metadata?.fromEmail && message.metadata.fromEmail.length > 0
        ? message.metadata.fromEmail
        : null;
    const fromMobile =
      message.metadata?.fromMobile && message.metadata.fromMobile.length > 0
        ? message.metadata.fromMobile
        : null;

    return {
      id: message.id,
      path: '',
      recieved: message.metadata.created,
      type: message.type,
      from: {
        displayName: fromEmail || fromMobile || '',
        senderAddress: fromEmail || fromMobile || '',
      },
      message: message.message,
      subject: message.subject,
      attachments: message.attachments,
      intent: message.intent,
    };
  }

  /**
   * Messagecenter MessageId
   */
  id: string = '';
  path: string = '';
  recieved: Timestamp | null = null;
  type: MESSAGETYPE = MESSAGETYPE.WHATSAPP;
  from: {
    displayName?: string;
    customerId?: string;
    senderAddress: string;
  } = {
    displayName: '',
    senderAddress: '',
  };

  subject?: string;
  message: string | null = null;
  intent?: any | null = null;
  attachments: MessageAttachment[] = [];
}
