import {
  DOCUMENTTYPE,
  TaxOfficeLetter,
  Timestamp,
} from '@expresssteuer/models';

/**
 * reminder for request letters to provide info ASAP
 */
export abstract class TaxOfficeReminder extends TaxOfficeLetter {
  documentType: DOCUMENTTYPE.taxoffice_reminder =
    DOCUMENTTYPE.taxoffice_reminder;
  /**
   * current due date
   */
  abstract dueDate: Timestamp | null;
  /**
   * associated request document
   */
  abstract taxOfficeRequestId: string | null;
}
