import { EnvironmentCampaign } from '../campaign/campaign';
import { EnvironmentPartner } from '../partner/partner';
import { PriceFixed } from '../pricing/priceFix';
import { PriceVariable } from '../pricing/priceVariable';

/**
 * Configurations for a Domain
 * Collection /domains
 */
export class Domain {
  /**
   * The Id of the document has to be the fully qualified domain name
   */
  id: string = '';

  /**
   * The partner the domain belongs to.
   * If not set, expresssteuer
   */
  partner?: EnvironmentPartner;
  /**
   * The allowed campaign.
   * The campaign will bot be changed if set in the taxform
   * If campaigns is not set, every campaign is allowed.
   * If set, only those campaigns are allowed to choice from
   */
  campaigns?: EnvironmentCampaign[];
  /**
   * The selected default price for this domain.
   * If not set, needs to be set elsewhere
   */
  price?: PriceFixed | PriceVariable;

  /**
   * If we allow vouchers to be set
   */
  allowVouchers: boolean = true;
}
