export enum DataSource {
  /**
   * added by client in app/retention funnel
   */
  clientFunnel = 'CLIENT_FUNNEL',

  /**
   * added by system after vmdbImport
   */
  dataRetrieval = 'DATA_RETRIEVAL',

  /**
   * added by system based on available optimizations
   */
  autoOptimization = 'AUTO_OPTIMIZATION',

  /**
   * added by employee based on available optimizations
   */
  manualOptimization = 'MANUAL_OPTIMIZATION',

  /**
   * added by employee manually
   */
  employeeEntry = 'EMPLOYEE_ENTRY',
}
