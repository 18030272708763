export interface AssetCertificationData {
  /**
   * Name des Anbieters
   */
  providerName: string;

  /** Art der Anlage */
  assetType: string;

  contractNumber: string;

  /** Vermögenswirksame Leistungen */
  vwlAmount: string;
}
