export interface CalculationData {
  return: number;
  marge_total: number;
  marge_taxAdvisor: number;
  marge_expresssteuer: number;
  discount: number;
  discountValue: number;
  companyShort: string;
  mwst: number;
  difference: number;
  incomeTax: number;
  totalTax: number;
  household: number;
  solidarityTax: number;
  churchTax: number;
  payedTotalTax: number;
  actualTotalTax: number;
}
