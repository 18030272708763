import { Timestamp } from '@firebase/firestore-types';
import { Bank } from '../bank/bank';
import { BankProvider } from './bankProvider';

export class BankAccount {
  static getTemplate(provider: BankProvider, clientId: string): BankAccount {
    return {
      clientId,
      provider,
      status: 'active',
      iban: '',
      bic: '',
      currency: 'EUR',
      active: true,
      created: null,
      updated: null,
      balance: 0,
    };
  }

  static toBank(bankAccount: BankAccount): Bank {
    return {
      bankBIC: bankAccount.bic || '',
      bankCity: bankAccount.address?.city || '',
      bankCode: bankAccount.code || '',
      bankName: bankAccount.name || '',
      bankPLZ: bankAccount.address?.postcode || '',
      IBAN: bankAccount.iban || '',
      valid: bankAccount.active,
      validated: bankAccount.active,
    };
  }

  id?: string | null = null;
  clientId: string | null = null;

  /**
   * Bank Name
   */
  name?: string = '';
  /**
   * Bank Code
   */
  code?: string = '';
  /**
   * Bank Address
   */
  address?: {
    street: string;
    nbr: string;
    postcode: string;
    city: string;
  };

  /**
   * @deprecated This field is intended to be removed Qonto specific
   */
  slug?: string | null = null;
  iban: string | null = null;
  bic: string | null = null;
  currency: string = 'EUR';
  provider: BankProvider | null = null;

  /**
   * The TaxAdvisor used on creation of the bank
   */
  taxadvisorId?: string | null = null;

  /**
   * Do we use this account actively?
   * Only one account per customer can be active at the same time
   */
  active: boolean = true;
  /**
   * Current balance of the account
   * in cents
   */
  balance: number = 0;
  /**
   * This is the status from the bank
   */
  status: 'new' | 'valid' | 'invalid' | 'active' | 'closed' = 'active';
  created: Timestamp | null = null;
  updated: Timestamp | null = null;

  /**
   * If activity is set to sched = scheduled, we start syncing the data within the account
   * If synchronisation is set, the backend is processing and updating the account
   * If idle, nothing is happening;
   */
  activity?: 'sched' | 'sync' | 'idle' | 'error' = 'idle';
  /**
   * Last time an activity was performed
   */
  lastActivity?: Timestamp | null = null;

  /**
   * Viban Account or Real Account
   */
  type?: 'physical' | 'virtual' | null = null;
}
