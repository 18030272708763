// import { NursingInsurance } from "../data/NursingInsurance";
// import { UnemploymentInsurance } from "../data/UnemploymentInsurance";
// import { PensionInsurance } from "../data/PensionInsurance";
// import { HealthInsurance } from "../data/HealthInsurance";
import {
  HealthInsurance,
  NursingInsurance,
  PensionInsurance,
  States,
  StateType,
  UnemploymentInsurance,
} from '../data/data';

export class State {
  public state: States;
  public type: StateType; // Neues oder altes Bundesland
  public validForYears: Array<number>; // In welchen Jahren ist der Wert gültig (2018)
  public churchPercentage: number = 0; // Kirchensteuer im Bundesland (0-100%)
  public offsiteFlat: number = 0;
  public offsiteFlat24: number = 0;

  public healthInsurance: HealthInsurance;
  public pensionInsurance: PensionInsurance;
  public unemploymentInsurance: UnemploymentInsurance;
  public nursingInsurance: NursingInsurance;

  constructor(
    state: States,
    type: StateType,
    healthInsurance: HealthInsurance,
    pensionInsurance: PensionInsurance,
    unemploymentInsurance: UnemploymentInsurance,
    nursingInsurance: NursingInsurance,
    validForYears: Array<number>
  ) {
    this.state = state;
    this.type = type;

    this.healthInsurance = healthInsurance;
    this.pensionInsurance = pensionInsurance;
    this.unemploymentInsurance = unemploymentInsurance;
    this.nursingInsurance = nursingInsurance;

    this.validForYears = validForYears;

    if (this.state === 'BY' || this.state === 'BW') {
      this.churchPercentage = 0.08;
    } else {
      this.churchPercentage = 0.09;
    }
  }
}
