//testing purposes don't blame me bro
export interface DocumentState {
  uploaded: boolean;
  processed: boolean;
  virusChecked: boolean;
  malicious: boolean | null;
  verified: boolean;
}
export abstract class ClientFileUploadState {
  abstract thumbnailSmallUri: string | null;
  abstract thumbnailBigUri: string | null;
  abstract documentState: DocumentState;
  abstract fileName: string;
  abstract fileExtension: string;
  /*
   * name of the file on clients device
   */
  abstract originalUploadName: string;
  /**
   * Id: ${fileName}
   */
  abstract id: string;
  abstract shouldBeDeleted: boolean;
  abstract clientFacingTaskId: string;
  abstract binaryDocumentReference: string | null;

  static getTemplate(input: {
    fileName: string;
    originUploadName: string;
    fileExtension: string;
  }): ClientFileUploadState {
    return {
      thumbnailBigUri: null,
      thumbnailSmallUri: null,
      documentState: this.getDocumentStateTemplate(),
      clientFacingTaskId: '',
      fileName: input.fileName,
      originalUploadName: input.originUploadName,
      shouldBeDeleted: false,
      id: input.fileName,
      fileExtension: input.fileExtension,
      binaryDocumentReference: null,
    };
  }

  static getDocumentStateTemplate(): DocumentState {
    return {
      processed: false,
      uploaded: false,
      verified: false,
      virusChecked: false,
      malicious: null,
    };
  }
}

export type ClientFileUploadMetadata = {
  clientId: string;
  firestoreDocumentId: string;
  fileExtension: string;
  originalFileName: string;
};
