import firebase from 'firebase/app';

export class CohortItem {
  public static getTemplate(): CohortItem {
    return {
      hours: 0,
      hoursSinceEnter: 0,
    };
  }
  /**
   * when the Item has been fullfilled
   */
  reached?: firebase.firestore.Timestamp;
  /**
   * Hours since last status or 0 if first
   */
  hours: number = 0;
  /**
   * Hours since entered (first item)
   */
  hoursSinceEnter: number = 0;
}

/**
 * Cohort analysis for a taxcase
 */
export class TaxCaseCohort {
  public static getTemplate(): TaxCaseCohort {
    return {
      totalHours: 0,
    };
  }
  /**
   * When the case entered the system
   */
  enter?: CohortItem = CohortItem.getTemplate();
  /**
   * Has taken into progress
   */
  progress?: CohortItem = CohortItem.getTemplate();
  /**
   * Waiting for data => can be from client and or
   */
  waiting_data?: CohortItem = CohortItem.getTemplate();
  /**
   * When the case has been sent to the taxoffice
   */
  taxofficeSent?: CohortItem = CohortItem.getTemplate();
  /**
   * When the case has been returned from taxoffice
   */
  taxofficeReturn?: CohortItem = CohortItem.getTemplate();
  /**
   * Sent an invoice or got requested payment
   */
  paymentRequested?: CohortItem = CohortItem.getTemplate();
  /**
   * We recieved the payment
   */
  paymentRecieved?: CohortItem = CohortItem.getTemplate();
  /**
   * Case is done
   */
  done?: CohortItem = CohortItem.getTemplate();

  /**
   * Total hours since created
   */
  totalHours: number = 0;

  created?: firebase.firestore.Timestamp;
  updated?: firebase.firestore.Timestamp;
}
