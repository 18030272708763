export interface IEnvironmentContact {
  displayName: string;
  email: string;
  phone: string;
}

export interface IEnvironmentDetails {
  id: string;
  acceptToSendWageTax: boolean;
  discount: number;
  company: string;
  companyShort: string;
  contacts?: IEnvironmentContact[];
  email?: {
    fromEmail: string;
    fromName: string;
  };
}
