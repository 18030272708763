import { Timestamp } from '@google-cloud/firestore';

/**
 * Represents an authorization
 */
export interface Authorization {
  /**
   * when we recieved or created the authorization
   */
  created: Timestamp | null;
  /**
   * if the authorization is deactivated and when
   */
  deactivated?: Timestamp | null;
  /**
   * If the authorization is set and active
   */
  active: boolean;
  /**
   * When has the authorization been activated
   */
  active_since?: Timestamp | null;
  /**
   * If the authorization has a time limit
   */
  active_until?: Timestamp | null;
}
export interface ClientAuthorizations {
  vollmachtsDB: Authorization;
}
