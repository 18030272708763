export interface ReligiousConfessionData extends Record<string, unknown> {
  religionName: string;
}
export class ReligiousConfessionData {
  public static getTemplate(): ReligiousConfessionData {
    return {
      religionName: '',
    };
  }
}
