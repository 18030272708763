import firebase from 'firebase/app';
import 'firebase/firestore';

export class User {
  public static getTemplate(): User {
    return {
      id: '',
      authId: '',
      email: '',
      displayName: '',
      photoUrl: '',
      enabled: true,
      lastSeen: firebase.firestore.Timestamp.now(),
    };
  }

  /**
   * Returns the basic System user
   * */
  public static getSystemUser(): User {
    return {
      enabled: true,
      displayName: 'Benedikt',
      email: 'fragen+systemuser@expresssteuer.de',
      authId: 'benedikt',
      id: '1aGHvt0r6BblPPvqZRc5',
      photoUrl: '/assets/users/benedikt.png',
      lastSeen: null,
      groups: [],
      roles: [],
    };
  }

  id: string = '';
  authId: string = '';
  email: string = '';
  displayName: string = '';
  photoUrl: string = ''; // TODO migration from photoURL to photoUrl
  enabled: boolean = true;
  lastSeen: firebase.firestore.Timestamp | null = null;

  groups?: Group[] = [];
  roles?: Role[] = [];
}

export class Role {
  applications: string = '';
  module: string = '';
  action: string = '';
  view: boolean = false;
  read: boolean = false;
  write: boolean = false;
  delete: boolean = false;
}

export class Group {
  application: string = '';
  name: string = '';
  roles: Role[] = [];
}
