export class EnvironmentPublic {
  public static getTemplate(): EnvironmentPublic {
    return {
      id: '',
      campaignid: '',
      css: '',
      cssContent: '',
      default: false,
      domains: [],
      js: '',
    };
  }

  id = '';
  campaignid = '';
  css = '';
  cssContent = '';
  default = false;
  domains: string[] = [];
  js = '';
}
