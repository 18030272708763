import { DatePipe } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import {
  AngularFireAuthModule,
  USE_EMULATOR as USE_AUTH_EMULATOR,
} from '@angular/fire/auth';
import {
  AngularFirestoreModule,
  SETTINGS,
  USE_EMULATOR as USE_FIRESTORE_EMULATOR,
} from '@angular/fire/firestore';
import {
  AngularFireFunctionsModule,
  REGION,
  USE_EMULATOR as USE_FUNCTIONS_EMULATOR,
} from '@angular/fire/functions';
import { AngularFirePerformanceModule } from '@angular/fire/performance';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IntercomModule } from 'ng-intercom';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ModalModule } from 'ngx-bootstrap/modal';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DebugComponent } from './core/debug/debug.component';
import { MaterialModule } from './material/material.module';

/**
 * The following can be used to enable hot reload, while still keeping auth token
 * Due to architecture this works against dynamic imports and should not be enabled on prod
 * Issue should be solved with firebase 9
 */
// import firebase from 'firebase';
// if (environment.emulator) {
//   const app = firebase.initializeApp(environment.firebase);
//   // NOTE: Sequence may be important initialize Auth first.
//   app.auth().useEmulator('http://localhost:9099');
//   app.firestore().settings({ host: 'localhost:8080', ssl: false });
//   app.functions().useEmulator('localhost', 5001);
// }

// AoT requires an exported function for factories
export class Controler {
  public static HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient, './assets/i18n/');
  }
}

@NgModule({
  declarations: [AppComponent, DebugComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    ModalModule.forRoot(),
    AlertModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirePerformanceModule,
    AngularFirestoreModule, // firestore
    AngularFireAuthModule, // auth
    AngularFireStorageModule, // storage
    AngularFireFunctionsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: Controler.HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    IntercomModule.forRoot({
      appId: 'td1s7h6e', // from your Intercom config
      updateOnRouterChange: true, // will automatically run `update` on router event changes. Default: `false`
    }),
  ],
  // providers: [{ provide: REGION, useValue: 'europe-west3' }, localConnection,
  providers: [
    {
      provide: USE_AUTH_EMULATOR,
      useValue: environment.emulator ? ['localhost', 9099] : undefined,
    },
    {
      provide: USE_FIRESTORE_EMULATOR,
      useValue: environment.emulator ? ['localhost', 8080] : undefined,
    },
    {
      provide: USE_FUNCTIONS_EMULATOR,
      useValue: environment.emulator ? ['localhost', 5001] : undefined,
    },
    { provide: REGION, useValue: 'europe-west3' },
    {
      provide: SETTINGS,
      useValue: environment.emulator
        ? {
            host: 'localhost:8080',
            ssl: false,
          }
        : undefined,
    },
    DatePipe,
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue: { useUtc: true },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
