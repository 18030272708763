import firebase from 'firebase/app';
import 'firebase/firestore';
import { Vat } from '../invoice/vat';

export class CampaignMarge {
  public static getTemplate(): CampaignMarge {
    return {
      percent: 0.2,
      value: 0,
      upperLimit: 5000,
      lowerLimit: 0.01,
      taxAdviserFlat: 0,
      taxAdviserFlatIncludedInMarge: false,
      freeRate: false,
      freeRateLimit: 0,
    };
  }
  public percent = 0; // Prozentuale Marge auf Erstattung
  public value = 0; // Fixe Marge in Euro

  // Total Cost
  public upperLimit = 0; // 499€
  public lowerLimit = 0; // min 25€

  public taxAdviserFlat = 0; // Steuerberater Pauschale
  public taxAdviserFlatIncludedInMarge = true;

  public freeRate = false; // Wenn true, dann wenn unter freeRateLimit kostenlos
  public freeRateLimit = 0; // Wenn Wert der Rückerstallung unter diesen Betrag geht, dann kostenlos
}

export class CampaignDiscount {
  public static getTemplate(): CampaignDiscount {
    return {
      percent: 0,
      value: 0,
    };
  }
  public percent = 0;
  public value = 0;
}

export class CampaignStatistic {
  public static getTemplate(): CampaignStatistic {
    return {};
  }
  public counters_status?: any = {};
  public counters_substatus?: any = {};
}

/**
 * The result of a calculation for the customer
 */
export class CampaignCalculation {
  /**
   * Expresssteuer Marge
   */
  marge: {
    gross: number;
    net: number;
    vat: number;
  } = { gross: 0, net: 0, vat: 0 };

  /**
   * External TaxAdvisor marge
   */
  taxAdvisor: {
    gross: number;
    net: number;
    vat: number;
  } = { gross: 0, net: 0, vat: 0 };

  /**
   * The cost for the Customer
   */
  customer: {
    gross: number;
    net: number;
    vat: number;
  } = { gross: 0, net: 0, vat: 0 };

  /**
   * The vat used
   */
  vat: {
    percentage: number;
  } = { percentage: 0 };
}

export class Campaign {
  static calculate(
    totalRefund: number,
    campaign: Campaign
  ): CampaignCalculation | null {
    const vat = Vat.getVat();

    let grossCustomerCost = 0.0; // Gesammte Kosten für Kunde (mit/ohne Steuerberater) + mwst
    let netCustomerCost = 0.0; // Gesammte Kosten für Kunde (mit/ohne Steuerberater)
    let grossMarginEXPS = 0.0;
    let netMarginEXPS = 0.0; // Unsere Marge in Euro

    const grossTaxAdvisor: number = campaign.marge.taxAdviserFlat; // Steuerberater Kosten (0 wenn inkludiert?)
    let netTaxAdvisor = 0.0;

    // Kundenrabatt in Prozent
    let customerDiscountValue = 0.0; // Kundenrabatt in Euro

    if (campaign.marge.value > 0) {
      grossCustomerCost = campaign.marge.value;
    }
    if (campaign.marge.percent > 0) {
      grossCustomerCost = totalRefund * campaign.marge.percent;
    }

    if (campaign.marge.taxAdviserFlatIncludedInMarge) {
      // ist inkludiert
    } else {
      // nicht inkludiert
      grossCustomerCost += campaign.marge.taxAdviserFlat;
    }

    if (grossCustomerCost >= campaign.marge.upperLimit) {
      grossCustomerCost = campaign.marge.upperLimit;
    }
    if (grossCustomerCost <= campaign.marge.lowerLimit) {
      grossCustomerCost = campaign.marge.lowerLimit;
    }

    // Rabatt
    if (campaign.discount.percent > 0) {
      if (campaign.marge.taxAdviserFlatIncludedInMarge) {
        customerDiscountValue = grossCustomerCost * campaign.discount.percent;
      } else {
        customerDiscountValue =
          (grossCustomerCost - campaign.marge.taxAdviserFlat) *
          campaign.discount.percent;
      }
      grossCustomerCost -= customerDiscountValue;
    } else if (campaign.discount.value > 0) {
      grossCustomerCost -= campaign.discount.value;
    }

    // Brutto
    grossMarginEXPS = grossCustomerCost - grossTaxAdvisor;

    // MWST
    const vatMargin = grossMarginEXPS - grossMarginEXPS / (vat + 1);
    const vatTaxAdvisor = grossTaxAdvisor - grossTaxAdvisor / (vat + 1);
    const vatCustomer = vatMargin + vatTaxAdvisor;

    // Netto
    netTaxAdvisor = grossTaxAdvisor - vatTaxAdvisor;
    netMarginEXPS = grossMarginEXPS - vatMargin;
    netCustomerCost = grossCustomerCost - vatCustomer;

    const data: CampaignCalculation = {
      marge: {
        gross: grossMarginEXPS,
        net: netMarginEXPS,
        /**
         * The vat value calculated
         */
        vat: grossMarginEXPS - netMarginEXPS,
      },

      customer: {
        gross: grossCustomerCost,
        net: netCustomerCost,
        /**
         * The vat value calculated
         */
        vat: grossCustomerCost - netCustomerCost,
      },

      taxAdvisor: {
        gross: grossTaxAdvisor,
        net: netTaxAdvisor,
        /**
         * The vat value calculated
         */
        vat: grossTaxAdvisor - netTaxAdvisor,
      },

      vat: {
        percentage: vat,
      },
    };

    return data;
  }

  static getTemplate(): Campaign {
    return {
      id: '3x1pGc1aqpuubHTmommO',
      partnerName: 'EXPS Standardpreis',
      promoCode: '',
      name: 'EXPS Standardpricing 20 %',
      from: null,
      to: null,
      discount: CampaignDiscount.getTemplate(),
      marge: CampaignMarge.getTemplate(),
    };
  }
  id = '';
  partnerName = '';
  promoCode = '';
  name = '';
  from: firebase.firestore.Timestamp | null = null;
  to: firebase.firestore.Timestamp | null = null;
  discount: CampaignDiscount = CampaignDiscount.getTemplate();
  marge: CampaignMarge = CampaignMarge.getTemplate();
  statistics?: CampaignStatistic;
}
