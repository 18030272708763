import firebase from 'firebase/app';

export class ChecklistItem {
  public static getTemplate(): ChecklistItem {
    return {
      lastCheck: null,
      completed: null,
    };
  }
  lastCheck: firebase.firestore.Timestamp | null = null;
  completed: firebase.firestore.Timestamp | null = null;
  value?: boolean | number | string | firebase.firestore.Timestamp;
  note?: string;
}
