export interface SendGridNotification {
  email: string;
  event: string;
  ip: string;
  response: string;
  sg_event_id: string;
  sg_message_id: string;
  sg_template_id: string;
  sg_template_name: string;
  sid: string;
  timestamp: number;
  tls: string;
}
