import { IJobCalculation } from '../../../interfaces/job';

export class JobCalculation implements IJobCalculation {
  public static getTemplate(): JobCalculation {
    return {
      wage: {
        partial: {
          wage: 0.0,
          wageNet: 0.0,
        },
        fullyear: {
          wage: 0.0,
          wageNet: 0.0,
        },
        daysWorked: 0.0,
        shortTimeWorking: {
          days: 0.0,
          percentage: 0,
        },
      },
      taxable: {
        partial: {
          taxableIncome: 0.0, // zu versteuerndes Einkommen gearbeitet
          payedIncomeTax: 0.0,
          churchTax: 0.0,
          solidarityTax: 0.0,
        },
        fullyear: {
          taxableIncome: 0.0, // zu versteuerndes Einkommen volles jahr
          payedIncomeTax: 0.0,
          payedIncomeTaxWorkdays: 0.0,
          solidarityTax: 0.0,
          churchTax: 0.0,
        },
      },
      insurances: {
        partial: {
          health: 0.0,
          pension: 0.0,
          unemployment: 0.0,
          nursing: 0.0,
        },
        fullyear: {
          health: 0.0,
          pension: 0.0,
          unemployment: 0.0,
          nursing: 0.0,
        },
      },
      homeoffice: {
        days: 0.0,
        value: 0.0,
      },
      commuter: {
        daysWorked: {
          home: { value: 0.0 },
          relocation: { value: 0.0 },
        },
        distance: {
          home: { value: 0.0 },
          relocation: { value: 0.0 },
        },
        calculation: {
          return: 0.0,
        },
      },
    };
  }
  /**
   * Wage Setting
   */
  public wage!: {
    partial: {
      wage: number;
      /**
       * @deprecated we currently do not use it for any calculation
       */
      wageNet: number;
    };
    fullyear: {
      wage: number;
      /**
       * @deprecated we currently do not use it for any calculation
       */
      wageNet: number;
    };
    daysWorked: number;

    /**
     * shortTimeWorking (Kurzarbeit)
     */
    shortTimeWorking: {
      /**
       * days worked during short time
       */
      days: number;
      /**
       * percentage of the reduction
       */
      percentage: number;
    };
  };
  public taxable!: {
    partial: {
      taxableIncome: number; // zu versteuerndes Einkommen gearbeitet
      payedIncomeTax: number; // zu bezahlende Einkommenssteuer gearbeitet
      solidarityTax: number;
      churchTax: number;
    };
    fullyear: {
      taxableIncome: number; // zu versteuerndes Einkommen volles jahr
      payedIncomeTax: number; // bezahlte Einkommenssteuer volles Jahr
      /**
       * TODO: Refactor to partial
       */
      payedIncomeTaxWorkdays: number; // bezahlte Einkommenssteuer volles Jahr auf Arbeitstage (/365 * arbeitstage)
      solidarityTax: number;
      churchTax: number;
    };
  };
  public insurances!: {
    partial: {
      health: number;
      pension: number;
      unemployment: number;
      nursing: number;
    };
    fullyear: {
      health: number;
      pension: number;
      unemployment: number;
      nursing: number;
    };
  };

  public homeoffice: {
    days: number;
    value: number;
  } = { days: 0.0, value: 0.0 };

  public commuter!: {
    daysWorked: {
      home: {
        value: number;
      };
      relocation: {
        value: number;
      };
    };

    distance: {
      home: {
        value: number;
      };
      relocation: {
        value: number;
      };
    };
    calculation: {
      return: number;
    };
  };
}
