export class FinanceOffice {
  buFaNr: string = '';
  name: string = '';
  address = {
    street: '',
    buildingNumber: '',
    plz: '',
    city: '',
  };
  tel: string = '';
  fax: string = '';
  mail: string = '';
  url: string = '';
  bundeszentralamtUrl: string = '';
  bankAccounts: FinanceOfficeBankAccount[] = [];

  public static getTemplate(): FinanceOffice {
    return {
      buFaNr: '',
      name: '',
      address: {
        street: '',
        buildingNumber: '',
        plz: '',
        city: '',
      },
      tel: '',
      fax: '',
      mail: '',
      url: '',
      bundeszentralamtUrl: '',
      bankAccounts: [],
    };
  }
}

export class FinanceOfficeBankAccount {
  bic: string = '';
  financeInstitute: string = '';
  iban: string = '';
}
