import firebase from 'firebase/app';
import { Tenant } from '../tenant/tenant';
import { User } from '../user/user';

export enum ACTIVITYTYPE {
  'REMINDER' = 'reminder',
  'AUTO_SEND_EMAIL' = 'auto_send_email',
  'AUTO_SEND_WHATSAPP' = 'auto_send_whatsapp',
  'NOTE' = 'note',
}

export class Task {
  public id = '';
  public uid?: string = ''; // If we need a unique category id like 'task_check_hasChildren'
  public type: ACTIVITYTYPE = ACTIVITYTYPE.NOTE;
  public taskmetadata: TaskMetadata = TaskMetadata.getTemplate();
  public reminder: TaskReminder | null = null;
  public subject = '';
  public message = '';
}

export class TaskMetadata {
  public static getTemplate(): TaskMetadata {
    return {
      createdAt: null,
      createdBy: null,
      updatedBy: null,
      updatedAt: null,
      assignedAt: null,
      assignedTo: null,
      clientId: '',
      taxcaseId: '',
      deleted: false,
      isDone: false,
      isDoneAt: null,
      documentId: '',
      messageId: '',
    };
  }

  public createdAt: firebase.firestore.Timestamp | null = null;
  public createdBy: User | null = null;
  public updatedAt: firebase.firestore.Timestamp | null = null;
  public updatedBy: User | null = null;
  public assignedAt: firebase.firestore.Timestamp | null = null;
  public assignedTo: User | null = null;
  public clientId = '';
  public taxcaseId = '';
  public messageId? = '';
  public documentId? = '';
  public taskId? = ''; // Tasks
  public isDone = false;
  public isDoneAt: firebase.firestore.Timestamp | null = null;
  public deleted = false;
  public tenant?: Tenant | null;
}

export class TaskReminder {
  public remindAt: firebase.firestore.Timestamp | null = null;
  public subject = '';
  public message = '';
}

export type TaxcaseComment = Omit<Task, 'type'> & { type: ACTIVITYTYPE.NOTE };
