import { Message } from '../../communication/message';
import { Timestamp } from '../../helpers/timestamp';

export interface ScheduledMessage {
  clientId: string;
  status: ScheduledMessageStatus;
  /**
   * message should be sent after this timestamp OR
   * message should be sent not before this timestamp
   */
  earliestDelivery: Timestamp;
  message: Message;
  relatedMessageBuildingTaskIds: string[];
  /**
   * Optional becasue old messages were not migrated.
   * TODO use "keyof typeof AGGREGATOR_TEMPLATE_FACTORIES" -> move to models
   */
  templateId?: string;
}

export enum ScheduledMessageStatus {
  Scheduled = 'SCHEDULED',
  Deactivated = 'DEACTIVATED',
  Sent = 'SENT',
}
