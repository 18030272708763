import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Client, ClientTaxCase, TaxForm } from '@expresssteuer/models';
import firebase from 'firebase/app';
import { Observable, of } from 'rxjs';
import {
  catchError,
  distinctUntilKeyChanged,
  filter,
  map,
  shareReplay,
  switchMap,
} from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { Logger, LoggerService } from '../logger/logger.service';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  public client$: Observable<Client> = this.authService.userId$.pipe(
    switchMap((userId) =>
      this.afStore
        .collection<Client>('clients', (ref) =>
          ref.where('userId', '==', userId)
        )
        .valueChanges()
        .pipe(
          filter((clients) => clients.length !== 0),
          map((clients) => {
            if (clients.length > 1) {
              console.error('Multiple clients for this uid found!');
            }
            return clients[0];
          })
        )
    ),
    shareReplay(1)
  );

  /**
   * @deprecated Should be the same as authService.userId$ after migration
   */
  public clientId$ = this.client$.pipe(
    distinctUntilKeyChanged('id'),
    map((client) => client.id)
  );

  safeClientTaxcases$ = this.authService.user$.pipe(
    filter((user) => !user.isAnonymous),
    switchMap((user) => {
      return this.afStore
        .collection<Client>('clients')
        .doc(user.uid)
        .collection<ClientTaxCase>('customerTaxCases')
        .valueChanges({ idField: 'id' });
    }),
    shareReplay(1)
  );

  safeClientId$ = this.authService.user$.pipe(
    filter((user) => !user.isAnonymous),
    map((user) => user.uid)
  );

  safeClient$ = this.authService.user$.pipe(
    filter((user) => !user.isAnonymous),
    switchMap((user) =>
      this.afStore
        .collection<Client>('clients', (ref) =>
          ref.where('userId', '==', user.uid)
        )
        .valueChanges()
        .pipe(
          filter((clients) => clients.length !== 0),
          map((clients) => {
            if (clients.length > 1) {
              console.error('Multiple clients for this uid found!');
            }
            return clients[0];
          })
        )
    ),
    shareReplay(1)
  );

  clientTaxcases$ = this.authService.userId$.pipe(
    switchMap((clientId) =>
      this.afStore
        .collection<Client>('clients')
        .doc(clientId)
        .collection<ClientTaxCase>('customerTaxCases')
        .valueChanges({ idField: 'id' })
    ),
    catchError((err) => {
      console.error('error on clienttaxcase retrieve pipe');
      console.error(err);
      return of([]);
    }),
    shareReplay(1)
  );

  logger: Logger;
  client: Client;

  constructor(
    private afStore: AngularFirestore,
    public authService: AuthService
  ) {
    this.logger = LoggerService.logger(ClientService.name);
    this.client$.subscribe((client) => {
      this.client = client;
    });
  }

  public allTaxforms(
    client: Client
  ): Promise<firebase.firestore.QuerySnapshot<TaxForm>> {
    console.log(client);
    if (!client) {
      return;
    }
    return this.afStore
      .collection<TaxForm>('taxforms', (ref) =>
        ref
          .where('uid', '==', client.userId)
          .where('status', '==', 'newcasecreated')
          .where(
            'information.yearOfReimbursement',
            '>',
            (new Date().getFullYear() - 5).toString()
          )
          .limit(10)
      )
      .get()
      .toPromise();
  }

  public update(client: Partial<Client>): Promise<void> {
    if (client?.id.length > 5) {
      return this.afStore
        .collection('clients')
        .doc<Client>(client.id)
        .update(client);
    }
  }

  public save(client: Client): Promise<void> {
    if (client?.id.length > 5) {
      return this.afStore
        .collection('clients')
        .doc<Client>(client.id)
        .set(client);
    }
  }
}
