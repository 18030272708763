export enum Reason {
  /**
   * Basis Unterlagen
   */
  BASE_DOCUMENTS = 'base_documents',
  /**
   * Weitere Unterlange
   */
  ADDITIONAL_DOCUMENTS = 'additional_documents',
  /**
   * Nachzahlung
   */
  BACKPAYER = 'backpayer',
  /**
   * Keine Abgabepflicht
   */
  NO_PAY_DUTY = 'no_pay_duty',
  /**
   * Hat zu lange gedauert
   */
  TOOK_TOO_LONG = 'too_long',
  /**
   * Rentner
   */
  PENSION = 'pension',
  /**
   * Soldat
   */
  SOLDIER = 'soldier',
  /**
   * Privat versichert
   */
  PRIVATLY_ENSURED = 'privatly_ensured',
  /**
   * Beamter
   */
  CIVIL_SERVANT = 'civil_servant',
  /**
   * Kunde ist ungehalten
   */
  BAD_CUSTOMER = 'bad_customer',
}

export const ReasonTexts: Record<Reason, string> = {
  base_documents: 'Basis Unterlagen fehlen',
  additional_documents: 'Weitere Unterlagen fehlen',
  backpayer: 'Nachzahlung',
  no_pay_duty: 'Keine Abgabepflicht',
  too_long: 'Hat zu lange gedauert',
  pension: 'Rentner',
  soldier: 'Soldat',
  privatly_ensured: 'Privat versichert',
  civil_servant: 'Beamter',
  bad_customer: 'Kunde ist ungehalten',
};
