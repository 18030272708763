export class RelocationFlat {
  public from: Date;
  public flat: number;
  public flatMarried: number;
  public flatChild: number;
  constructor(
    from: Date,
    flat: number,
    flatMarried: number,
    flatChild: number
  ) {
    this.from = from;
    this.flat = flat;
    this.flatMarried = flatMarried;
    this.flatChild = flatChild;
  }
}
