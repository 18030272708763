import { Timestamp } from '../../helpers/timestamp';
import { iVoucher } from './iVoucher';
import { VOUCHERTYPE } from './voucherType';

export class VoucherFixprice implements iVoucher {
  public static getTemplate(): VoucherFixprice {
    return {
      code: '',
      type: VOUCHERTYPE.fixed,
      price: 0.0,
    };
  }
  code: string = '';
  valid?: Timestamp | undefined;
  type: VOUCHERTYPE = VOUCHERTYPE.fixed;
  /**
   * The price reduction fixed by this voucher
   */
  price: number = 0.0;
}
