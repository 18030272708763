import { Client } from './client';

export class ClientElasticResult {
  public hits: ClientHits = new ClientHits();
  public max_score = 0;
  public total = {
    relation: '',
    value: 0,
  };
  public timed_out = false;
  public took = 0;
}

export class ClientHits {
  public max_score = 0;
  public hits: ClientHit[] = [];
  public total = 0;
  public relation = '';
}

export class ClientHit {
  public _id = '';
  public _index = '';
  public _score = 0;
  public _type = '_doc';
  public _source: Client = Client.getTemplate();
}
