import moment from 'moment';
import { ExpsAddress } from '../taxform/address/address';

export enum DATEFORMAT {
  DEFAULT = 'DD.MM.YYYY',
  FILE_DATETIME = 'DD_MM_YYYY_HH_mm_ss',
  FILE_DATE = 'DD_MM_YYYY',
  ELSTER_DATE = 'YYYYMMDD',
  ELSTER_TIME = 'HHmmss',
  ELSTER_FROMTO = 'DD.MM',
  ELSTER_FROMTOYEAR = 'DD.MM.YYYY',
}

export class ElsterDate {
  public static format(
    date?: Date,
    format: DATEFORMAT = DATEFORMAT.DEFAULT
  ): string {
    moment.locale('de');

    if (date) {
      const momentDate = moment(date);
      momentDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

      const str = momentDate.format(format);

      // console.log(str);

      return str;
    } else {
      return new Date().toDateString();
    }
  }
}

export class ElsterSpecial {
  public static elsteraddress(address: ExpsAddress): string {
    if (address !== undefined) {
      const aAddress =
        address.zipCode.longName +
        ', ' +
        address.city.longName +
        ', ' +
        address.street.longName +
        ' ' +
        address.nbr.longName;
      return aAddress.trim();
    } else {
      return '';
    }
  }
}

export class ElsterNumber {
  public static format(value: number | null, fraction = 2): string {
    if (value === null) {
      value = 0;
    }
    try {
      return value.toFixed(fraction).replace('.', ',');
    } catch (err) {
      // console.error('ElsterNumber.format', err);
      return (-0.01).toFixed(fraction).replace('.', ',');
    }
  }
}
