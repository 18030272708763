/**
 * The marge for Expresssteuer
 */
export class Marge {
  public static getTemplate(): Marge {
    return {
      min: 0,
      max: 0,
    };
  }
  /**
   * the minimum price it will cost for the customer
   * this price can also not be lowered with a voucher
   */
  min: number = 0;
  /**
   * the maximum price it will cost for the client
   */
  max: number = 0;
}
