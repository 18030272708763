import firebase from 'firebase/app';
import 'firebase/firestore';

export class StatusHistory {
  rejected: firebase.firestore.Timestamp | null = null; // wird nicht genutzt
  entry: firebase.firestore.Timestamp | null = null; // neue
  waiting: firebase.firestore.Timestamp | null = null; // In Progress, Daten, Unterlagen fehlen fehlen
  paid: firebase.firestore.Timestamp | null = null; // Complete, bevor abgesendet and Fa
  sent: firebase.firestore.Timestamp | null = null; // Ans Finanzamt übermittelt
  received: firebase.firestore.Timestamp | null = null; // Prozess der Datenbfrage durch das FA gestartet und wartet auf Antwort
  finished: firebase.firestore.Timestamp | null = null; // Bescheid erhalten und SEPA eingezogen

  public static getTemplate(): StatusHistory {
    return {
      rejected: null,
      entry: firebase.firestore.Timestamp.now(),
      waiting: null,
      paid: null,
      sent: null,
      received: null,
      finished: null,
    };
  }
}
