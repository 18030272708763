import { iPrice } from './iPrice';
import { PriceFixed } from './priceFix';

export class Pricing {
  positive: Positive = Positive.getTemplate();

  negative: Negative = Negative.getTemplate();
}

/**
 * The price if the return was positive
 */
export class Positive {
  public static getTemplate(): Positive {
    return {
      price: PriceFixed.getTemplate(),
    };
  }
  price: iPrice = PriceFixed.getTemplate();
}
/**
 * The price if the return is negative
 */
export class Negative {
  public static getTemplate(): Negative {
    return {
      price: PriceFixed.getTemplate(),
    };
  }
  price: iPrice = PriceFixed.getTemplate();
}
