/**
 * A document 1 time for the client
 * and multiple time for every possible partner he had over time
 * INFO: If the same partner occours multiple times on different clients, and we search for the partner by taxId we also have to check which is the right case in combination with the clientId
 * If the taxId from the client the document id is the clientId
 * If the taxId is from the partner, the document id is partner_[taxId]
 * will be kept in collection /clients/[clientId]/taxIds/
 */
export class TaxId {
  static buildDocumentId(taxId: { clientId: string }): string {
    return taxId.clientId;
  }

  id?: string = '';
  /**
   * 11 Letters TaxId
   * This id is unique for a person during his livetime in Germany
   */
  taxId: number = 12345678901;
  /**
   * The id of the Client
   */
  clientId: string = '';
}
