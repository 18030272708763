/**
 * Returns the days difference of the dates including end day.
 * Order of dates does not matter, its is always absolute
 */

export function daysDiff(date1: Date, date2: Date): number {
  const timeDiff = Math.abs(date2.getTime() - date1.getTime());
  // +1 to include end date
  const dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1;
  return dayDiff;
}

export function ageInYear(
  birthDate: Date | null | undefined,
  year: number
): number {
  if (!birthDate) {
    return 24; // Most of our customers ar over 23
  }

  return year - birthDate.getFullYear();
}
