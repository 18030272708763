import { Timestamp } from '@google-cloud/firestore';

export enum InvoiceSide {
  /**
   * Customer gets credits so EXPS => Customer
   */
  'credit' = 'credit',
  /**
   * Customer pays us so CUSTOMER => EXPS
   */
  'debit' = 'debit',
}

export class InvoiceLine {
  id: string = '';
  /**
   * If the invoiceline is + or - for the customer
   */
  side: InvoiceSide = InvoiceSide.credit;

  /**
   * Amount in Euro = 10
   */
  amount: number = 0;
  /**
   * Same as amount, but in cents = 10 Euro = 1000
   */
  amount_cents: number = 0;
  /**
   * The used var 19% (0.19) 16%
   */
  vatPercentage: number = 0.19;
  /**
   * VAT as number. basically 16% of amount (vat included)
   */
  vatValue: number = 0;

  /**
   * Referenze information
   */
  reference = '';

  metadata: InvoiceLineMetadata = InvoiceLineMetadata.getTemplate();
}

export class InvoiceLineMetadata {
  static getTemplate(): InvoiceLineMetadata {
    return {
      created: null,
      invoiceId: null,
      clientId: null,
      taxcaseId: null,
      deletable: false,
      labels: [],
    };
  }
  /**
   * Invoice line created
   */
  created: Timestamp | null = null;
  /**
   * If linked to an invoice, the invoice id is set.
   * If null, this invoice line is open and needs to be linked to an invoice.
   */
  invoiceId: string | null = null;
  /**
   * the client the invoiceline is related to
   */
  clientId: string | null = null;
  /**
   * Optional the taxcase the invoiceline is related to
   */
  taxcaseId: string | null = null;

  /**
   * If deletable = true this invoiceline can be removed from an operator.
   * @example If we provide a discount to the client but decide to remove it, discounts will be marked as deletable.
   * On the other hand, open debts based on an provided service are not deletable. Instead we offer discounts which will cancel out the payment.
   */
  deletable: boolean = false;

  /**
   * Labels are to contain information to reference the invoice line belongs to a certain process
   */
  labels: InvoiceLineLabel[] = [];
}

/**
 * A Key Value Pair to store specific properties of an Invoice
 */
export class InvoiceLineLabel {
  label = '';
  value: string | number | Timestamp | boolean | null = null;
}
