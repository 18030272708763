import { daysDiff, DAYS_PER_MONTH } from '@expresssteuer/date-helper';
import { WagestatementData } from '../../client/extraction';
import { Timestamp } from '../../helpers/timestamp';
import {
  HOMEOFFICE,
  IWorksetup,
  JOBSTATUS,
  OFFSITE,
} from '../../interfaces/job';
import { ExpsAddress } from '../address/address';
import { Profession } from '../profession/profession';
import { JobCalculation } from './calculation/jobcalculation';
import { Employer } from './employer';
import { JOBSOURCE } from './jobsource';
import { ShortTimeWork } from './shortTimeWork';

export enum OFFSITETYPE {
  'DRIVING' = 'driving',
  'CLIENT' = 'client',
  'CONSTRUCTIONSITE' = 'constructionsite',
  'FIXEDPOSITION' = 'fixedposition',
}

export class OffsiteDay {
  public static getTemplate(): OffsiteDay {
    return {
      days: 0.0,
      hourse: 0.0,
    };
  }
  public days = 0.0;
  public hourse = 0.0;
}

export class Worksetup implements IWorksetup {
  public static getTemplate(): Worksetup {
    return {
      offsite: null,
      homeoffice: null,
      offsitetype: null,
      travelingForEmployer: false,
      hasMoneyFromEmployer: false, // Food from Employer
      shortTime: null,
      offsitedays: [],
      food: {
        // TODO REFACTORING hasMoneyFromEmployer goes into here
        subsidy: false,
        value: 0.0,
      },
      /**
       * Travelcosts
       * subsidy => gets support from
       */
      transportation: {
        // Reisekosten
        subsidy: false,
        value: 0.0,
        flat: 0.0,
      },
      travelcost: {
        // Fahrtkosten
        subsidy: false,
        value: 0.0, // Stuerfrei
        flat: 0.0, // Pauschale
      },
    };
  }

  homeoffice: HOMEOFFICE | null = null;
  offsite: OFFSITE | null = null;
  offsitetype: OFFSITETYPE | null = null;
  offsitedays?: OffsiteDay[] = [];
  travelingForEmployer = false;
  hasMoneyFromEmployer = false; // Food from Employer
  shortTime?: ShortTimeWork | null = null;

  public transportation: {
    subsidy: boolean;
    value: number;
    flat: number;
  } = { subsidy: false, value: 0.0, flat: 0.0 };
  public food: {
    subsidy: boolean;
    value: number;
  } = { subsidy: false, value: 0.0 };
  public travelcost: {
    // Fahrtkosten
    subsidy: boolean;
    value: number; // Stuerfrei
    flat: number; // Pauschale
  } = { subsidy: false, value: 0.0, flat: 0.0 };
}

/**
 * Values from the CRM Wage Statement
 */
export class JobWageStatementValues {
  /** Bruttoarbeitslohn */
  public wage = 0.0;
  /** Einbehaltene Lohnsteuer */
  public income = 0.0;
  /** Einbehaltener Solidaritätszuschlag */
  public solidarity = 0.0;
  /** Einbehaltene Kirchensteuer des Arbeitnehmers */
  public church = 0.0;
  public static getTemplate(): JobWageStatementValues {
    return {
      wage: 0.0,
      income: 0.0,
      solidarity: 0.0,
      church: 0.0,
    };
  }
}

export class Job {
  public static getTemplate(isPartner = false): Job {
    return {
      source: JOBSOURCE.CLIENT,
      partner: isPartner,
      status: JOBSTATUS.employed,
      profession: Profession.getTemplate(),
      unionMembership: false,
      unionMember: '',
      start: null,
      end: null,
      fullYear: true,
      eTin: '',
      workdays: 5.0, // Wieviele Tage die Woche wird gearbeitet
      monthlySalary: 0.0,
      employer: Employer.getTemplate(),
      workingAddress: ExpsAddress.getTemplate(),
      calculation: JobCalculation.getTemplate(),
      wagestatement: JobWageStatementValues.getTemplate(),
      distance: {
        home: { text: '', value: 0.0 },
        relocation: { text: '', value: 0.0 },
      },
      duration: {
        home: { text: '', value: 0.0 },
        relocation: { text: '', value: 0.0 },
      },
      worksetup: Worksetup.getTemplate(),
    };
  }
  public static getClientJobs(jobs: Job[]): Job[] {
    return jobs.filter((aJob) => !aJob.partner);
  }

  public static getPartnerJobs(jobs: Job[]): Job[] {
    return jobs.filter((aJob) => aJob.partner);
  }

  /**
   * Summarizes all Wages from the jobs and calculates the yearly salary based on the days worked
   * @param jobs
   */
  public static getYearlyWage(jobs: Job[]): number {
    let wage = 0;
    for (const job of jobs) {
      if (job.fullYear) {
        wage += job.monthlySalary * 12;
      } else {
        wage += (job.monthlySalary / DAYS_PER_MONTH) * Job.getDaysWorked(job);
      }
    }

    return wage;
  }

  /**
   * Return the wage per monthe based on days worked
   * @param wage wage for period of time
   * @param from
   * @param to
   * @returns
   */
  public static calculateMonthlyWage(
    wage: number,
    from: Timestamp,
    to: Timestamp
  ): number {
    const days = daysDiff(from.toDate(), to.toDate());

    const monthlyWage = Math.round((wage / days) * DAYS_PER_MONTH * 100) / 100;
    return monthlyWage;
  }

  /**
   * Calculates the days worked for a job.
   */
  private static getDaysWorked(job: Job): number {
    if (job.fullYear) {
      return 365;
    } else {
      if (!job.start || !job.end) {
        throw new Error(
          'Job fullyear is false, but start and enddate is not set'
        );
      }

      try {
        const daysDifference = daysDiff(job.start.toDate(), job.end.toDate());

        if (daysDifference > 365) {
          return 365;
        }
        if (daysDifference < 0) {
          return 0;
        }

        return daysDifference;
      } catch (err) {
        throw new Error(
          'Calculation of days diff for job failed. Probably there is no valid Timestamp in job.start or job.end'
        );
      }
    }
  }

  public static mergeJobWithWagestatement(
    wagestatement: WagestatementData,
    job: Job
  ): Job {
    job.source = JOBSOURCE.MERGED;

    job.employer.name = wagestatement.employerName;

    if (wagestatement.start && wagestatement.end) {
      const monthlyWage = Job.calculateMonthlyWage(
        wagestatement.wage.gross,
        wagestatement.start,
        wagestatement.end
      );
      job.monthlySalary = monthlyWage;
    }

    job.start = wagestatement.start;
    job.end = wagestatement.end;
    job.fullYear = false;

    job.wagestatement = JobWageStatementValues.getTemplate();
    job.wagestatement.wage = wagestatement.wage.gross;
    job.wagestatement.income = wagestatement.wage.wageTaxWithold;
    job.wagestatement.solidarity =
      wagestatement.wage.solidaritySurchargeWithold;
    job.wagestatement.church = wagestatement.wage.churchTaxWithold_employee;

    // Verpflegungspauschale
    const foodCost = wagestatement.foodCost;
    if (foodCost > 0) {
      job.worksetup.travelingForEmployer = true;
      job.worksetup.hasMoneyFromEmployer = true;
      job.worksetup.food.subsidy = true;
      job.worksetup.food.value = foodCost;
    }

    // Fahrtkosten
    const travelCost = wagestatement.travelCost;
    if (travelCost > 0) {
      job.worksetup.travelingForEmployer = true;
      job.worksetup.hasMoneyFromEmployer = true;
      job.worksetup.travelcost.subsidy = true;
      job.worksetup.travelcost.value = travelCost;
    }

    return job;
  }
  public static convertWagestatementToJob(
    wagestatement: WagestatementData,
    clientOrPartner: 'client' | 'partner'
  ): Job {
    let job = Job.getTemplate();
    job = this.mergeJobWithWagestatement(wagestatement, job);
    job.source = JOBSOURCE.TAXOFFICE;
    job.profession.labelDe = 'UNBEKANNT';
    job.partner = clientOrPartner === 'partner';
    return job;
  }

  /**
   * @deprecated
   */
  public source?: JOBSOURCE = JOBSOURCE.CLIENT;
  public partner = false;
  public status = JOBSTATUS.employed;
  public profession: Profession = Profession.getTemplate();
  /**
   * @deprecated use property array on partner/information object
   */
  public unionMembership = false;
  /**
   * @deprecated use property array on partner/information object
   */
  public unionMember = '';
  public taxClass?: 1 | 2 | 3 | 4 | 5 | 6 | null = null;
  public eTin: string = '';
  public start: Timestamp | null = null;
  public end: Timestamp | null = null;
  public fullYear = true;
  public workdays = 5; // Wieviele Tage die Woche wird gearbeitet
  public monthlySalary = 2400;
  public employer: Employer = Employer.getTemplate();
  public workingAddress: ExpsAddress = ExpsAddress.getTemplate();
  public calculation: JobCalculation = JobCalculation.getTemplate();
  public wagestatement: JobWageStatementValues =
    JobWageStatementValues.getTemplate();
  public distance = {
    home: { text: '', value: 0.0 },
    relocation: { text: '', value: 0.0 },
  };
  public duration = {
    home: { text: '', value: 0.0 },
    relocation: { text: '', value: 0.0 },
  };
  public worksetup: Worksetup = Worksetup.getTemplate();
}
