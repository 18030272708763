/**
 *  Config
 */
export class Configuration {
  public openYears: OpenYear[] = [];
}

/**
 *  OpenYear
 */
export class OpenYear {
  public year: string = '';
}
