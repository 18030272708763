import { ExpsAddress } from '../address/address';

export class Employer {
  public static getTemplate(): Employer {
    return {
      name: '',
      address: ExpsAddress.getTemplate(),
    };
  }
  public name: string = '';
  /**
   * TODO: Not used yet, but we should migrate Job.workingaddress to here
   */
  public address: ExpsAddress = ExpsAddress.getTemplate();
}
