import firebase from 'firebase/app';

export enum GOVERNENTALAIDTYPE {
  'UNEMPLOYMENT' = 'unemployment', // Arbeitslosengeld
  'SICKNESS' = 'sickness', // Krankengeld
  'LIFEANNUITY' = 'lifeAnnuity', // Leibrente
  'PARENTAL' = 'parental', // Elterngeld
  'MOTHER' = 'mother', // Muttergeld
  'ALIMONY' = 'alimony', // Unterhalt
  'INSOLVENCY' = 'insolvency', // Insolvenzgeld
  'SHORTTIMEWORK' = 'shorttimework', // Kurzarbeitsgeld
}

/**
 * Object Describes anGovermental aid
 * * valid expense types
 *  - unemployment
 *  - sickness
 *  - lifeAnnuity
 *  - parental
 *  - mother
 *  - alimony
 *  - insolvency
 *  - shorttimework
 */
export interface IGovermentalAid {
  category: GOVERNENTALAIDTYPE;
  description: string;
  value: number | null;
  startDate: firebase.firestore.Timestamp | null;
  endDate: firebase.firestore.Timestamp | null;
  active: boolean;
}
