import firebase from 'firebase/app';
import { VOUCHERTYPE } from './voucherType';

export interface iVoucher {
  type: VOUCHERTYPE;

  /**
   * The code that will activate this voucher
   */
  code: string;

  /**
   * If value is set, the voucher is valid until this date.
   * If undefined, the voucher is indefinite valid
   */
  valid?: firebase.firestore.Timestamp;
}
