import firebase from 'firebase/app';
import { Client } from '../client/client';
import { Message } from './message';

export class MessageGroup {
  public static getTemplate(): MessageGroup {
    return {
      id: '',
      messages: [],
      created: null,
      client: null,
    };
  }

  public id: string = '';
  public messages: Message[] = [];
  public created: firebase.firestore.Timestamp | null = null;
  public client: Client | null = null;
}
