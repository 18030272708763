import { ITaxReturnPerson } from '../../interfaces/iTaxform';

export class TaxReturnPerson implements ITaxReturnPerson {
  public static getTemplate(): TaxReturnPerson {
    return {
      gross_wage: 0.0,
      gross_wage_fullYear: 0.0,
      net_wage: 0.0,
      net_wage_fullYear: 0.0,
      taxable_income: 0.0,
      taxable_income_fullYear: 0.0,
      incomeTax: 0.0,
      incomeTax_fullYear: 0.0,
      solidarityTax: 0.0,
      solidarityTax_fullYear: 0.0,
      churchTax: 0.0,
      churchTax_fullYear: 0.0,
      unionmembership: 0.0,
      commuterReduction: 0.0,
      advertisingExpenses: 0.0,
      disability: 0.0,
      insurances: {
        partial: {
          health: 0.0,
          pension: 0.0,
          unemployment: 0.0,
          nursing: 0.0,
        },
      },
    };
  }

  public gross_wage: number = 0.0;
  public gross_wage_fullYear: number = 0.0;
  public net_wage: number = 0.0;
  public net_wage_fullYear: number = 0.0;
  public taxable_income: number = 0.0;
  public taxable_income_fullYear: number = 0.0;
  public incomeTax: number = 0.0;
  public incomeTax_fullYear: number = 0.0;
  public solidarityTax: number = 0.0;
  public solidarityTax_fullYear: number = 0.0;
  public churchTax: number = 0.0;
  public churchTax_fullYear: number = 0.0;
  public unionmembership: number = 0.0;
  public commuterReduction: number = 0.0;
  public advertisingExpenses: number = 0.0;
  public disability: number = 0.0;
  public insurances!: {
    partial: {
      health: number;
      pension: number;
      unemployment: number;
      nursing: number;
    };
  };
}
