import { iPrice } from './iPrice';
import { Marge } from './marge';
import { PRICETYPE } from './priceType';

export class PriceVariable implements iPrice {
  public static getTemplate(): PriceVariable {
    return {
      marge: Marge.getTemplate(),
      percentage: 0.0,
      type: PRICETYPE.variable,
    };
  }

  type: PRICETYPE = PRICETYPE.variable;
  marge: Marge = Marge.getTemplate();
  percentage: number = 0.0;
}
