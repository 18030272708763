import { Timestamp } from '@expresssteuer/models';

/**
 * Additional values extracted from the wagestatement
 * These values can be additional expenses or if subsidy (Arbeitgeberzuschuss) can be sumarized and added to the subsidy section
 */
export interface Additionals {
  description: string;
  subsidy: boolean;
  value: number;
}

export interface SocialInsurance {
  /** Arbeitgeberanteil zur gesetzlichen Rentenversicherung */
  pension_employer: number;
  /** Arbeitnehmeranteil zu gesetzlichen Rentenversicherung */
  pension_employee: number;
  /**Arbeitnehmeranteil zur gesetzlichen Krankenversicherung */
  health_employee: number;
  /** Arbeitnehmeranteil zu sozialen Pflegeversicherung */
  socialCare_employee: number;
  /** Arbeitnehmeranteil zu Arbeitslosenversicherung */
  unemployment_employee: number;
}

export interface Wage {
  /** Bruttoarbeitslohn */
  gross: number;
  /** Einbehaltene Lohnsteuer */
  wageTaxWithold: number;
  /** Einbehaltener Solidaritätszuschlag */
  solidaritySurchargeWithold: number;
  /** Einbehaltene Kirchensteuer des Arbeitnehmers */
  churchTaxWithold_employee: number;
}

export interface WagestatementData extends Record<string, unknown> {
  /** Hash of object used as a id to simplify mapping*/
  id: string;
  /** Ab wann sind die Besteuerungsmerkmale gültig */
  validFrom: Timestamp | null;
  /** Welche Steuerklasse gilt in diesem Job */
  taxClass: 1 | 2 | 3 | 4 | 5 | 6 | undefined;

  eTin: string;

  /**  Name des Arbeitgebers */
  employerName: string;
  /** Jobdatum von */
  start: Timestamp | null;
  /** Jobdatum bis */
  end: Timestamp | null;
  /** Verpflegung bei Auswärtstätigkeit */
  foodCost: number;
  /** Verpflegungspauschale bei Auswärtstätigkeit */
  foodCostFlat?: number;
  /** steuerfreie Arbeitgeberleistungen für Fahrten zwischen Wohnung und Arbeitsstätte */
  travelCost: number;
  /** pauschal besteuerte Arbeitgeberleistungen für Fahrten zwischen Wohnung und Arbeitsstätte */
  travelCostFlat?: number;
  /** Kurzarbeitergeld */
  shortTimeWorkMoney: number;

  /**
   * Sonstige Angaben zu Arbeitslohn und Entschädigungen
   */
  additionalInformationOnWagesAndCompensation?: AdditionalInformationOnWagesAndCompensation;

  additionals: Additionals[];

  wage: Wage;
  socialSecurity: SocialInsurance;
}

/**
 * Sonstige Angaben zu Arbeitslohn und Entschädigungen
 */
export interface AdditionalInformationOnWagesAndCompensation {
  /**
   * ermäßigt besteuerter Arbeitslohn für mehrere Kalenderjahre
und ermäßigt besteuerte Entschädigungen
   */
  reducedWagesAndCompensations: number;
  /**
 * einbehaltene Lohnsteuer bei ermäßigt besteuertem
Arbeitslohn
 */
  withheldIncomeTaxesOnReducedTaxedWages: number;

  /**
   * einbehaltener Solidaritätszuschlag bei ermäßigt besteuertem
Arbeitslohn
   */
  withheldSolidatyTaxOnReducedTaxedWages: number;

  withheldChurchTaxOnReducedTaxedWages: number;
}
export class WagestatementData {
  public static getTemplate(): WagestatementData {
    return {
      id: '',
      eTin: '',
      employerName: '',
      start: null,
      end: null,
      foodCost: 0,
      foodCostFlat: 0,
      travelCost: 0,
      travelCostFlat: 0,
      shortTimeWorkMoney: 0,
      additionals: [],
      wage: {
        churchTaxWithold_employee: 0,
        gross: 0,
        solidaritySurchargeWithold: 0,
        wageTaxWithold: 0,
      },
      additionalInformationOnWagesAndCompensation: {
        reducedWagesAndCompensations: 0,
        withheldIncomeTaxesOnReducedTaxedWages: 0,
        withheldSolidatyTaxOnReducedTaxedWages: 0,
        withheldChurchTaxOnReducedTaxedWages: 0,
      },
      taxClass: 1,
      socialSecurity: {
        health_employee: 0,
        pension_employee: 0,
        pension_employer: 0,
        socialCare_employee: 0,
        unemployment_employee: 0,
      },
      validFrom: null,
    };
  }
}
