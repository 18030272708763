<div class="debug-view" [style.font-size]="fontsize + 'px'">
  <div class="options">
    Debug View
    <button (click)="collapsed = !collapsed">toggle</button>
    <button (click)="fontsize = fontsize + 1">+</button>
    <button (click)="fontsize = fontsize - 1">-</button>
  </div>
  <div class="customcontent" *ngIf="!collapsed">
    <div class="red">
      AuthService.user:
      <span *ngIf="authService.user$ | async as user"
        >{{ user.uid }} {{ user.isAnonymous }}{{ user | json }}</span
      >
    </div>
    <div class="green">
      ClientService.client:
      <span *ngIf="clientService.client$ | async as client"
        ><a href="http://localhost:4201/client/{{ client.id }}">{{
          client.id
        }}</a>
        {{ client | json }}</span
      >
    </div>
    <div class="blue">
      businessService.taxform:
      <span *ngIf="businessService.taxForm as taxForm"
        >{{ taxForm.id }}{{ taxForm | json }}
      </span>
    </div>
    <div>
      ClientService.clientTaxcases:
      <span *ngIf="clientService.clientTaxcases$ | async as clientTaxcases"
        >{{ clientTaxcases.length }} {{ clientTaxcases | json }}</span
      >
    </div>
  </div>
</div>
