import { Timestamp } from '../../helpers/timestamp';

export class TaxOfficeDeadline {
  public static getTemplate(): TaxOfficeDeadline {
    return {
      noticeDate: null,
      reminderDate: null,
    };
  }

  noticeDate: Timestamp | null = null;
  reminderDate: Timestamp | null = null;
}
