import {
  IEnvironmentContact,
  IEnvironmentDetails,
} from '../interfaces/iEnvironment';

export class EnvironmentDetails implements IEnvironmentDetails {
  public static getTemplate(): EnvironmentDetails {
    return {
      id: 'localhost',
      acceptToSendWageTax: false,
      discount: 0,
      company: 'EXPS-local',
      companyShort: 'EXPSLOCAL',
      contacts: [],
      email: {
        fromEmail: '',
        fromName: '',
      },
    };
  }

  public static getDefault(): EnvironmentDetails {
    return {
      id: 'localhost',
      acceptToSendWageTax: false,
      discount: 0,
      company: 'EXPS',
      companyShort: 'EXPS',
      contacts: [],
      email: {
        fromEmail: '',
        fromName: '',
      },
    };
  }

  id = '';
  acceptToSendWageTax = false;
  discount = 0;
  company = 'UNDEFINED';
  companyShort = 'UD';
  contacts?: IEnvironmentContact[] = [];
  email?: {
    fromEmail: string;
    fromName: string;
  };
}
