<router-outlet #o="outlet"></router-outlet>

<div
  id="imprintModal"
  class="modal modalMinHeight200 modal-left fade in"
  role="dialog"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="close" data-dismiss="modal">×</button>
        <div class="row">
          <div class="col-sm-12"></div>

          <div class="col-sm-12">
            <h2 class="c23" id="h.h5tjues6l6d9">
              <span class="c11">Impressum</span>
            </h2>
            <h4 class="c20" id="h.ct1dxewq26ua">
              <span class="c8">Angaben gem&auml;&szlig; &sect; 5 TMG:</span>
            </h4>
            <p class="c22 c9"><span class="c5"></span></p>
            <p class="c10"><span class="c0">ExpressSteuer GmbH</span></p>
            <p class="c10"><span class="c0">Am Sandtorkai 73</span></p>
            <p class="c10"><span class="c0">20457 Hamburg</span></p>
            <h4 class="c19" id="h.syv22x90vl0a"><span class="c6"></span></h4>
            <h4 class="c3" id="h.co34nsq8490c">
              <span class="c8">Vertreten durch:</span>
            </h4>
            <p class="c22"><span class="c0">Mehdi Afridi</span></p>
            <p class="c22"><span class="c0">Dennis Konrad</span></p>
            <p class="c16">
              <span class="c0"
                >Maximilian Graf Lambsdorff<br />Konstantin Loebner</span
              >
            </p>
            <p class="c1"><span class="c0"></span></p>
            <h4 class="c3" id="h.oj8hwiwu0rlc">
              <span class="c8">Kontakt:</span>
            </h4>
            <p class="c7">
              <span class="c14">E-Mail: </span
              ><span class="c12"
                ><a class="c17" href="mailto:info@expresssteuer.com"
                  >info@expresssteuer.com</a
                ></span
              >
            </p>
            <p class="c7">
              <span class="c14">Telefone: +49(0)40228657029</span>
            </p>
            <p class="c7 c9"><span class="c0"></span></p>
            <h4 class="c3" id="h.b2vsft2qru8w">
              <span class="c8">Registereintrag:</span>
            </h4>
            <p class="c2">
              <span class="c0">Amtsgericht Hamburg: HRB 135813</span>
            </p>
            <p class="c2 c9"><span class="c0"></span></p>
            <h4 class="c15" id="h.wu6rmrxmn65c">
              <span class="c8">Hinweis: </span>
            </h4>
            <p class="c2">
              <span class="c13"
                >Die Europ&auml;ische Kommission stellt eine Plattform f&uuml;r
                die au&szlig;ergerichtliche Streitbeilegung (OS-Plattform)
                bereit. ExpressSteuer ist weder verpflichtet, noch bereit, an
                dem Streitschlichtungsverfahren teilzunehmen. Die OS-Plattform
                ist unter </span
              ><span class="c21"
                ><a
                  class="c17"
                  href="https://www.google.com/url?q=https://ec.europa.eu/consumers/odr/main/index.cfm?event%3Dmain.home2.show%26lng%3DDE&amp;sa=D&amp;source=editors&amp;ust=1650901536443967&amp;usg=AOvVaw0UUG-4CywlE1irrHQ_JZVj"
                  >https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&amp;lng=DE</a
                ></span
              ><span class="c13">&nbsp;abrufbar.</span>
            </p>
            <p class="c2 c9"><span class="c0"></span></p>
            <h2 class="c18" id="h.j4bp1gwwf07g">
              <span class="c11">Haftungsausschluss (Disclaimer)</span>
            </h2>
            <h4 class="c3" id="h.xouehuwacw14">
              <span class="c8">Haftung f&uuml;r Inhalte</span>
            </h4>
            <p class="c2">
              <span class="c0"
                >Als Diensteanbieter sind wir gem&auml;&szlig; &sect; 7 Abs.1
                TMG f&uuml;r eigene Inhalte auf diesen Seiten nach den
                allgemeinen Gesetzen verantwortlich. Nach &sect;&sect; 8 bis 10
                TMG sind wir als Diensteanbieter jedoch nicht verpflichtet,
                &uuml;bermittelte oder gespeicherte fremde Informationen zu
                &uuml;berwachen oder nach Umst&auml;nden zu forschen, die auf
                eine rechtswidrige T&auml;tigkeit hinweisen. Verpflichtungen zur
                Entfernung oder Sperrung der Nutzung von Informationen nach den
                allgemeinen Gesetzen bleiben hiervon unber&uuml;hrt. Eine
                diesbez&uuml;gliche Haftung ist jedoch erst ab dem Zeitpunkt der
                Kenntnis einer konkreten Rechtsverletzung m&ouml;glich. Bei
                Bekanntwerden von entsprechenden Rechtsverletzungen werden wir
                diese Inhalte umgehend entfernen.</span
              >
            </p>
            <p class="c2 c9"><span class="c0"></span></p>
            <p class="c2 c9"><span class="c0"></span></p>
            <h4 class="c3" id="h.6t4lqdixa244">
              <span class="c8">Haftung f&uuml;r Links</span>
            </h4>
            <p class="c2">
              <span class="c0"
                >Unser Angebot enth&auml;lt Links zu externen Webseiten Dritter,
                auf deren Inhalte wir keinen Einfluss haben. Deshalb k&ouml;nnen
                wir f&uuml;r diese fremden Inhalte auch keine Gew&auml;hr
                &uuml;bernehmen. F&uuml;r die Inhalte der verlinkten Seiten ist
                stets der jeweilige Anbieter oder Betreiber der Seiten
                verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
                Verlinkung auf m&ouml;gliche Rechtsverst&ouml;&szlig;e
                &uuml;berpr&uuml;ft. Rechtswidrige Inhalte waren zum Zeitpunkt
                der Verlinkung nicht erkennbar. Eine permanente inhaltliche
                Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
                Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
                Bekanntwerden von Rechtsverletzungen werden wir derartige Links
                umgehend entfernen.</span
              >
            </p>
            <h4 class="c3" id="h.k09lf50nyku">
              <span class="c8">Urheberrecht</span>
            </h4>
            <p class="c2">
              <span class="c0"
                >Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
                diesen Seiten unterliegen dem deutschen Urheberrecht. Die
                Vervielf&auml;ltigung, Bearbeitung, Verbreitung und jede Art der
                Verwertung au&szlig;erhalb der Grenzen des Urheberrechtes
                bed&uuml;rfen der schriftlichen Zustimmung des jeweiligen Autors
                bzw. Erstellers. Downloads und Kopien dieser Seite sind nur
                f&uuml;r den privaten, nicht kommerziellen Gebrauch gestattet.
                Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
                wurden, werden die Urheberrechte Dritter beachtet. Insbesondere
                werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie
                trotzdem auf eine Urheberrechtsverletzung aufmerksam werden,
                bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden
                von Rechtsverletzungen werden wir derartige Inhalte umgehend
                entfernen.</span
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  id="dataprivacyModal"
  class="modal modalMinHeight200 modal-left fade in"
  role="dialog"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="close" data-dismiss="modal">×</button>
        <div class="row">
          <div class="col-sm-12"></div>

          <div class="col-sm-12">
            <h2 class="c23" id="h.gjdgxs">
              <span class="c28 c42">Datenschutzhinweise</span>
            </h2>
            <h3 class="c37" id="h.30j0zll">
              <span class="c20">I. Allgemeines</span>
            </h3>
            <p class="c3">
              <span class="c0"
                >Verantwortliche f&uuml;r die hierin beschriebenen
                Datenverarbeitungsvorg&auml;nge ist die
              </span>
            </p>
            <p class="c3 c11"><span class="c0"></span></p>
            <p class="c3"><span class="c0">ExpressSteuer GmbH</span></p>
            <p class="c3"><span class="c0">Am Sandtorkai 73</span></p>
            <p class="c3"><span class="c0">20457 Hamburg</span></p>
            <p class="c3">
              <span class="c0">Email: info@expresssteuer.com </span>
            </p>
            <p class="c3">
              <span class="c0">Telefon: +49 (0) 40228682756 </span>
            </p>
            <p class="c3">
              <span class="c0"
                >(&bdquo;wir&ldquo; oder &bdquo;ExpressSteuer&ldquo;).</span
              >
            </p>
            <p class="c22 c47">
              <span class="c0"
                >Der Datenschutzbeauftragte des Verantwortlichen ist:</span
              >
            </p>
            <p class="c3 c22">
              <span class="c0"
                >Rechtsanwalt und Fachanwalt f&uuml;r
                Informationstechnologierecht</span
              >
            </p>
            <p class="c3 c22"><span class="c0">Dr. Christian Rauda</span></p>
            <p class="c3 c22">
              <span class="c0">GRAEF Rechtsanw&auml;lte Digital PartG mbB</span>
            </p>
            <p class="c3 c22"><span class="c0">Jungfrauenthal 8</span></p>
            <p class="c3 c22"><span class="c0">20149 Hamburg</span></p>
            <p class="c3 c22">
              <span class="c0">Telefon: +49.40.80 6000 9 - 0</span>
            </p>
            <p class="c3 c22">
              <span class="c0">Telefax: +49.40.80 6000 9 - 10</span>
            </p>
            <p class="c3 c22">
              <span class="c2">E-Mail: </span
              ><span class="c0">datenschutzbeauftragter@expresssteuer.com</span>
            </p>
            <p class="c3 c22">
              <span class="c2">E-Website: </span
              ><span class="c2 c48"
                ><a
                  class="c41"
                  href="https://www.google.com/url?q=http://www.graef.eu/&amp;sa=D&amp;source=editors&amp;ust=1650901703750879&amp;usg=AOvVaw1dK4NlWcAB7s93hJA-Ec_2"
                  >www.graef.eu</a
                ></span
              ><span class="c0">&ldquo;</span>
            </p>
            <p class="c3 c11"><span class="c0"></span></p>
            <p class="c3 c11"><span class="c0"></span></p>
            <p class="c3" id="h.1fob9te"><span class="c0">1. Begriffe</span></p>
            <p class="c3">
              <span class="c0"
                >1.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&bdquo;Personenbezogene
                Daten&ldquo; sind alle Informationen, die sich auf eine
                identifizierte oder identifizierbare nat&uuml;rliche Person
                beziehen. Hierzu geh&ouml;ren etwa Deine E-Mailadresse oder Dein
                Name, aber unter anderem auch Dein Einkommen oder Deine
                IP-Adresse.</span
              >
            </p>
            <p class="c3">
              <span class="c0"
                >1.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&bdquo;Verarbeitung&ldquo;
                oder &bdquo;verarbeiten&ldquo; meint jeden mit oder ohne Hilfe
                automatisierter Verfahren ausgef&uuml;hrten Vorgang oder jede
                solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten
                wie beispielsweise deren Erfassung oder Speicherung.</span
              >
            </p>
            <p class="c3"><span class="c0">..</span></p>
            <p class="c3">
              <span class="c0"
                >1.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Erg&auml;nzend
                verweisen wir auf die Begriffsbestimmungen in Art. 4 der
                Datenschutzgrundverordnung (&bdquo;DSGVO&ldquo;) und die in
                unseren Allgemeinen Gesch&auml;ftsbedingungen definierten
                Begriffe.</span
              >
            </p>
            <p class="c3 c11 c29"><span class="c0"></span></p>
            <h3 class="c40" id="h.3znysh7">
              <span class="c20"
                >II. Verarbeitungsprozesse bei Erbringung unserer Leistung</span
              >
            </h3>
            <p class="c3">
              <span class="c0"
                >Im Rahmen unserer Leistung mandatieren wir einen Steuerberater
                in Deinem Namen und in Deinem Auftrag und bezahlen die Kosten
                f&uuml;r die Erstellung und Einreichung Deiner
                Steuererkl&auml;rung. Hierzu ist &nbsp;ist eine Verarbeitung
                bestimmter personenbezogener Daten unumg&auml;nglich. Dabei
                beachten wir die geltenden Datenschutzvorschriften, insbesondere
                die der DSGVO. Im Folgenden informieren wir Dich dar&uuml;ber,
                wie wir Deine personenbezogenen Daten im Zusammenhang mit der
                Erbringung unserer Leistungen verarbeiten und welchen Zwecken
                diese Verarbeitung dient. Wir stellen auf unserer Webseite eine
                Web-App zur Verf&uuml;gung, &uuml;ber die Du mit uns
                kommunizieren kannst (&bdquo;App&ldquo;). Du kannst diese App
                auch nutzen, um mit dem Steuerberater zu kommunizieren, der in
                Deinem Namen und Auftrag bearbeitet wurde.</span
              >
            </p>
            <p class="c3 c11"><span class="c0"></span></p>
            <h4 class="c27" id="h.2et92p0">
              <span class="c28 c2">1. &nbsp;Art der Daten</span>
            </h4>
            <p class="c3">
              <span class="c0"
                >Wenn Du einen Leistungsvertrag mit uns abschlie&szlig;t und
                Deine pers&ouml;nlichen Daten &uuml;bermittelst, f&uuml;hren wir
                vertragsgem&auml;&szlig; diese Daten mit den anonymen Daten aus
                dem Steuerrechner zusammen, wir speichern und verarbeiten sie zu
                Zwecken unseres Vertrages. Davon sind die folgenden
                personenbezogenen Daten umfasst.</span
              >
            </p>
            <p class="c3" id="h.tyjcwt">
              <span class="c0"
                >1.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Kontakt-
                und abwicklungsrelevante Daten</span
              >
            </p>
            <p class="c3">
              <span class="c0"
                >(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Deinen Vor-
                und Nachnamen</span
              >
            </p>
            <p class="c3">
              <span class="c0"
                >(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Deine
                aktuelle Anschrift</span
              >
            </p>
            <p class="c3">
              <span class="c0"
                >(c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Deine
                Telefonnummer</span
              >
            </p>
            <p class="c3">
              <span class="c0"
                >(d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Deine
                E-Mail-Adresse</span
              >
            </p>
            <p class="c3">
              <span class="c0"
                >(e)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Deinen
                Geburtstag und damit Dein Alter</span
              >
            </p>
            <p class="c3">
              <span class="c0"
                >(f)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Deine
                IBAN</span
              >
            </p>
            <p class="c3 c11"><span class="c0"></span></p>
            <p class="c3" id="h.3dy6vkm">
              <span class="c0"
                >1.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Allgemeine
                steuerlich relevante Angaben</span
              >
            </p>
            <p class="c3">
              <span class="c0"
                >(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;die
                Information, f&uuml;r welches Jahr Du die Steuererkl&auml;rung
                abgeben m&ouml;chtest</span
              >
            </p>
            <p class="c3">
              <span class="c0"
                >(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Informationen
                dar&uuml;ber, ob und ggf. wie viele Kinder Du hast und wie alt
                sie sind, wie sie hei&szlig;en,</span
              >
            </p>
            <p class="c3">
              <span class="c0"
                >(c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;die
                Information dar&uuml;ber, ob und ggf. wann Du in dem
                betreffenden Steuerjahr umgezogen bist, einschlie&szlig;lich
                Adressen der Wohnungen</span
              >
            </p>
            <p class="c3">
              <span class="c0"
                >(d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;die
                Information dar&uuml;ber, wie gro&szlig; Deine Wohnung ist</span
              >
            </p>
            <p class="c3">
              <span class="c0"
                >(e)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Deinen
                Job-Status (bspw. Angestellter, Arbeiter)</span
              >
            </p>
            <p class="c3">
              <span class="c0"
                >(f)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Deinen
                Beruf</span
              >
            </p>
            <p class="c3">
              <span class="c0"
                >(g)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Informationen
                dar&uuml;ber, wie oft Du im betreffenden Steuerjahr dienstlich
                unterwegs warst (bspw. bei Kunden, im Au&szlig;endienst oder auf
                einer Baustelle)</span
              >
            </p>
            <p class="c3">
              <span class="c0"
                >(h)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;die
                Information dar&uuml;ber, ob Du im betreffenden Steuerjahr von
                Deinem Arbeitgeber eine Verpflegungspauschale f&uuml;r Tage
                erhalten hast, an denen Du nicht an Deinem festen Arbeitsplatz
                gearbeitet hast</span
              >
            </p>
            <p class="c3">
              <span class="c0"
                >(i)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Informationen
                dar&uuml;ber, wie viele Arbeitstage Deine Woche hat</span
              >
            </p>
            <p class="c3">
              <span class="c0"
                >(j)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;die
                Information dar&uuml;ber, zu welchem Zeitpunkt im betreffenden
                Steuerjahr Du Deine T&auml;tigkeit aufgenommen hast</span
              >
            </p>
            <p class="c3">
              <span class="c0"
                >(k)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Dein
                Brutto-Monatsgehalt</span
              >
            </p>
            <p class="c3">
              <span class="c0"
                >(l)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Informationen
                dar&uuml;ber, ob und ggf. wie lange und wie viel Insolvenzgeld
                Du im betreffenden Steuerjahr erhalten hast</span
              >
            </p>
            <p class="c3">
              <span class="c0"
                >(m)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Informationen
                dar&uuml;ber, ob und ggf. wie lange und wie viel
                Kurzarbeitergeld Du im betreffenden Steuerjahr erhalten
                hast</span
              >
            </p>
            <p class="c3">
              <span class="c0"
                >(n)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;die Adresse,
                unter der Du Deine Arbeit im betreffenden Steuerjahr
                haupts&auml;chlich verrichtet hast</span
              >
            </p>
            <p class="c3">
              <span class="c0"
                >(o)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Informationen
                dar&uuml;ber, ob und ggf. wie viele Ausgaben f&uuml;r
                Arbeitsmittel, Ausbildungen oder Fortbildungen, Bewerbungen,
                Dienstreisen, Dein Arbeitszimmer, Handwerkerleistungen,
                haushaltsnahe Dienstleistungen oder Spenden Du im betreffenden
                Steuerjahr hattest</span
              >
            </p>
            <p class="c3">
              <span class="c0"
                >(p)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Informationen,
                die Du uns &uuml;ber die konkret abgefragten Informationen
                hinaus mitteilst (bspw. Informationen zu etwaigen
                Unterhaltszahlungen)</span
              >
            </p>
            <p class="c3 c11"><span class="c0"></span></p>
            <p class="c3" id="h.1t3h5sf">
              <span class="c0"
                >1.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Besondere
                Kategorien personenbezogener Daten nach Art. 9 Abs. 1 DSGVO
                (&bdquo;Sensible Daten&ldquo;)</span
              >
            </p>
            <p class="c3">
              <span class="c0"
                >(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Dein
                Familienstand</span
              >
            </p>
            <p class="c3">
              <span class="c0"
                >(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Deine
                Religionszugeh&ouml;rigkeit</span
              >
            </p>
            <p class="c3">
              <span class="c0"
                >(c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Informationen
                dar&uuml;ber, ob und ggf. wie lange und wie viel Krankengeld Du
                im betreffenden Steuerjahr erhalten hast</span
              >
            </p>
            <p class="c3">
              <span class="c0"
                >(d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Deine
                Behinderungen und ggf. Dein Behinderungsgrad</span
              >
            </p>
            <p class="c3">
              <span class="c0"
                >(e)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Deine
                Wohnsituation</span
              >
            </p>
            <p class="c3">
              <span class="c0"
                >(f)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Deine
                Gewerkschaftszugeh&ouml;rigkeit und ggf. den Namen der
                Gewerkschaft</span
              >
            </p>
            <p class="c3" id="h.4d34og8">
              <span class="c0"
                >1.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Verarbeitungszweck</span
              >
            </p>
            <p class="c3">
              <span class="c0"
                >Wir verarbeiten die in Ziffern II.1.1, II.1.2 und II.1.3
                genannten personenbezogenen Daten, um</span
              >
            </p>
            <p class="c3" id="h.2s8eyo1">
              <span class="c0"
                >(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;diese an
                einen Steuerberater weiterzugeben, damit dieser Deine
                Steuererkl&auml;rung erstellen kann,</span
              >
            </p>
            <p class="c3" id="h.17dp8vu">
              <span class="c0"
                >(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Dir eine
                vereinfachte Nutzung unserer Leistungen in Bezug auf weitere
                Steuerjahre erm&ouml;glichen zu k&ouml;nnen, und</span
              >
            </p>
            <p class="c3 c11"><span class="c0"></span></p>
            <p class="c3">
              <span class="c0"
                >1.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Rechtsgrundlage
                der Verarbeitung</span
              >
            </p>
            <p class="c3" id="h.26in1rg">
              <span class="c2"
                >Rechtsgrundlage f&uuml;r die Verarbeitung der in Ziffern II.1.1
                und II.1.2 genannten personenbezogenen </span
              ><span class="c2"
                >Daten zu den in Ziffer II.1.4 genannten Zwecken</span
              ><span class="c0"
                >&nbsp;ist Art. 6 Abs. 1 S. 1 lit. b DSGVO (Durchf&uuml;hrung
                unseres Vertrags mit Dir bzw. vorvertragliche
                Ma&szlig;nahmen).</span
              >
            </p>
            <p class="c3" id="h.mh8bdot0xtfo">
              <span class="c2"
                >Wir helfen Dir, den voraussichtlichen Betrag einer
                m&ouml;glichen Steuerru&#776;ckzahlung zu ermitteln und Deine
                Steuererkl&auml;rung u&#776;ber einen Steuerberater/eine
                Steuerberaterin oder einen Rechtsanwalt/eine Rechtsanw&auml;ltin
                einreichen zu lassen. Um dies tun zu k&ouml;nnen, mu&#776;ssen
                wir auch Sensible Daten von Dir verarbeiten. Das kann
                beispielsweise Gesundheitsdaten (z.B. die Information
                daru&#776;ber ob Du eine Behinderung hast), Deine
                religi&ouml;sen &Uuml;berzeugungen (das ergibt sich ggf. aus der
                Information, ob Du Kirchensteuer zahlst), Deine
                Gewerkschaftszugeh&ouml;rigkeit sowie ggf. Informationen zur
                Deiner sexuellen Orientierung (das ergibt sich ggf. aus der
                Information zu Deinem Familienstand) oder zu Deiner politischen
                Meinung (das kann sich beispielsweise aus Deinem Beruf selbst
                ergeben) betreffen. Welche Sensiblen Daten wir genau verarbeiten
                ergibt sich aus den Angaben, die Du im Klick-Menu&#776; machst.
                Diese Sensiblen Daten werden Teil Deiner Steuererkl&auml;rung
                und haben gegebenenfalls Auswirkungen auf den als wahrscheinlich
                errechneten Betrag Ihrer Steuerru&#776;ckzahlung. Wir
                mu&#776;ssen diese Sensiblen Daten daher verarbeiten, um den
                Betrag Deiner Steuerru&#776;ckzahlung zutreffend fu&#776;r Dich
                ermitteln zu k&ouml;nnen. Wir m&ouml;chten Dir au&szlig;erdem
                erm&ouml;glichen, Deine Eingabe vom selben PC fortzusetzen, wenn
                Du sie aus irgendwelchen Gru&#776;nden nicht beim ersten Mal
                abschlie&szlig;en kannst. Dazu mu&#776;ssen wir Deine Sensiblen
                Daten auch speichern, wenn Du noch keinen Vertrag mit uns
                abgeschlossen hast. Damit wir Deine Sensiblen Daten zu den
                genannten Zwecken verarbeiten du&#776;rfen, ben&ouml;tigen wir
                Deine Einwilligung. Du kannst Deine Einwilligung jederzeit,
                beispielsweise durch einfache E-Mail an </span
              ><span class="c2">datenschutz@expresssteuer.com</span
              ><span class="c0"
                >, widerrufen. Wir du&#776;rfen Deine Sensiblen Daten ab dem
                Zeitpunkt des Zugangs Deines Widerrufs bei uns nicht mehr
                nutzen. Bitte beachte, dass bis zu diesem Zeitpunkt bereits
                erfolgte Datenverarbeitungen dadurch nicht
                unrechtm&auml;&szlig;ig werden. Bitte beachte auch, dass die
                Verarbeitung der oben genannten Sensiblen Daten fu&#776;r die
                Erbringung unserer Dienste erforderlich ist. Wenn Du also Deine
                Einwilligung widerrufst, k&ouml;nnen wir Dir nicht weiter
                helfen.</span
              >
            </p>
            <p class="c3 c11"><span class="c0"></span></p>
            <h4 class="c27" id="h.lnxbz9">
              <span class="c28 c2"
                >2. Vervollst&auml;ndigung und Korrektur Deiner Angaben</span
              >
            </h4>
            <p class="c3" id="h.35nkun2">
              <span class="c0"
                >2.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Wir bitten
                Dich ggf. auch, zur Weiterleitung an den mandatierten
                Steuerberater Deine Lohnsteuerbescheinigung an uns zu senden
                oder fragen individuell weitere Informationen bei Dir ab, die Du
                uns noch nicht, unvollst&auml;ndig oder fehlerhaft mitgeteilt
                hast. Dabei handelt es sich um die folgenden personenbezogenen
                Daten:</span
              >
            </p>
            <p class="c3">
              <span class="c0"
                >(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;die unter
                Ziffer II.1 genannten Daten</span
              >
            </p>
            <p class="c3">
              <span class="c0"
                >(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Deine
                Steueridentifikationsnummer</span
              >
            </p>
            <p class="c3">
              <span class="c0"
                >(c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Deine
                eTIN</span
              >
            </p>
            <p class="c3">
              <span class="c0"
                >(d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Deine
                Steuerklasse</span
              >
            </p>
            <p class="c3">
              <span class="c0"
                >(e)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Deine
                Personalnummer</span
              >
            </p>
            <p class="c3">
              <span class="c0"
                >(f)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Deinen
                exakten Bruttoarbeitslohn</span
              >
            </p>
            <p class="c3">
              <span class="c0"
                >(g)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Informationen
                dar&uuml;ber, wie hoch der exakte Solidarit&auml;tszuschlag, die
                Kirchensteuer, der Krankenkassenbeitrag, der
                Rentenversicherungsbeitrag, der
                Arbeitslosenversicherungsbeitrag, der Krankenkassenzusatzbeitrag
                ist, den Du gezahlt hast</span
              >
            </p>
            <p class="c3">
              <span class="c0"
                >(h)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Etwaige
                Nachweise/Belege f&uuml;r s&auml;mtliche unter II.1 in dieser
                Erkl&auml;rung aufgef&uuml;hrten Daten.</span
              >
            </p>
            <p class="c3 c11"><span class="c0"></span></p>
            <p class="c3" id="h.1ksv4uv">
              <span class="c0"
                >2.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Anfrage des
                Steuerberaters bei dem Finanzamt</span
              >
            </p>
            <p class="c3">
              <span class="c0"
                >Dar&uuml;ber hinaus wird der mandatierte Steuerberater bei dem
                Finanzamt erforderlichenfalls Unterlagen anfragen und in der App
                hinterlegen, die ggf. personenbezogene Daten von Dir enthalten.
                Auch diese ben&ouml;tigen wir zur Vervollst&auml;ndigung oder
                ggf. Berichtigung Deiner Angaben. Dabei handelt es sich um die
                folgenden personenbezogenen Daten:</span
              >
            </p>
            <p class="c3">
              <span class="c0"
                >(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;die unter
                Ziffer II.1 genannten personenbezogenen Daten</span
              >
            </p>
            <p class="c3">
              <span class="c0"
                >(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;die unter
                Ziffer II.2.1 genannten personenbezogenen Daten</span
              >
            </p>
            <p class="c3">
              <span class="c0"
                >Ebenso wird der mandatierte Steuerberater ggf.
                Sachstandsanfragen nach Einreichung Deiner Steuererkl&auml;rung
                stellen oder dem Finanzamt &Auml;nderungen Deiner Angaben
                mitteilen.</span
              >
            </p>
            <p class="c3 c11"><span class="c0"></span></p>
            <p class="c3">
              <span class="c0"
                >2.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Wir
                verarbeiten die unter Ziffern II.2.1 und II.2.2 genannten
                personenbezogenen Daten, um
              </span>
            </p>
            <p class="c3" id="h.44sinio">
              <span class="c0"
                >(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
              >
            </p>
            <p class="c3">
              <span class="c0"
                >sicherzustellen, dass wir eine etwaige Kommunikation des
                Steuerberaters, der Deine Steuererkl&auml;rung f&uuml;r Dich
                erstellen und einreichen wird, mit dem Finanzamt so reibungslos
                wie m&ouml;glich gestalten k&ouml;nnen,</span
              >
            </p>
            <p class="c3">
              <span class="c0"
                >(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Deine
                Angaben zu Deinem Account speichern zu k&ouml;nnen und Dir so
                eine vereinfachte Nutzung der App in Bezug auf weitere
                Steuerjahre zu erm&ouml;glichen,</span
              >
            </p>
            <p class="c3" id="h.2jxsxqh">
              <span class="c0"
                >(c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;die
                dar&uuml;ber hinaus, vor allem in Bezug auf die technische
                Unterst&uuml;tzung, von uns nach unseren Allgemeinen
                Gesch&auml;ftsbedingungen geschuldeten Leistungen erbringen zu
                k&ouml;nnen,
              </span>
            </p>
            <p class="c3 c11" id="h.3j2qqm3"><span class="c0"></span></p>
            <p class="c3">
              <span class="c0"
                >2.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Rechtsgrundlage
                der Verarbeitung</span
              >
            </p>
            <p class="c3">
              <span class="c0"
                >Rechtsgrundlage f&uuml;r die Verarbeitung der in Ziffern II.2.1
                und II.2.2 genannten personenbezogenen Daten zu den in Ziffern
                II.2.2 bis II.2.3(c) genannten Zwecken ist Art. 6 Abs. 1
                S.&nbsp;1 lit.&nbsp;b DSGVO (Durchf&uuml;hrung unseres Vertrags
                mit Dir bzw. vorvertragliche Ma&szlig;nahmen).</span
              >
            </p>
            <p class="c3">
              <span class="c0"
                >Soweit sensible Daten von der Verarbeitung umfasst sind,
                erfolgt diese auf Grundlage Deiner Einwilligung (Art. 6 Abs. 1
                S.1 lit. a und Art. 9 Abs. 2 lit.&nbsp;a DSGVO).</span
              >
            </p>
            <h4 class="c27" id="h.1y810tw">
              <span class="c28 c2">3. Identifikation</span>
            </h4>
            <p class="c16">
              <span class="c2"
                >3.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Die Abgabe
                der Steuererkl&auml;rung erfordert eine Identifikation des
                Steuerpflichtigen. Wir &uuml;bernehmen die Durchf&uuml;hrung
                dieser Identifikation f&uuml;r den Steuerberater. Aus diesem
                Grund bitten wir Dich, uns eine Kopie Deines Personalausweises
                zu schicken. Wir speichern dieses Dokument im Auftrag des
                Steuerberaters, damit dieser Deine Identifikation sp&auml;ter
                jederzeit nachweisen kann.</span
              ><span>&nbsp;</span
              ><span class="c0"
                >Der Kopie des Personalausweises werden die folgenden
                personenbezogenen Daten entnommen und zum Zweck Deiner
                Identifikation verarbeitet:</span
              >
            </p>
            <p class="c16">
              <span class="c0"
                >(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Dein
                Foto</span
              >
            </p>
            <p class="c16">
              <span class="c0"
                >(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Deine
                Ausweisnummer</span
              >
            </p>
            <p class="c16">
              <span class="c0"
                >(c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Deine
                Unterschrift</span
              >
            </p>
            <p class="c16">
              <span class="c0"
                >(e)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;das
                Ablaufdatum Deines Personalausweises</span
              >
            </p>
            <p class="c16">
              <span class="c0"
                >(f)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;die
                ausstellende Beh&ouml;rde</span
              >
            </p>
            <p class="c16">
              <span class="c0"
                >3.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Rechtsgrundlage
                f&uuml;r die Identifikation und Speicherung der dazu
                erforderlichen Unterlagen ist Art. 6 Abs. 1 S.&nbsp;1 lit. c
                DSGVO in Verbindung mit &sect;&nbsp;87d&nbsp;Abs. 2 AO.</span
              >
            </p>
            <h4 class="c67" id="h.4i7ojhp">
              <span class="c28 c2">4. Alterspr&uuml;fung</span>
            </h4>
            <p class="c5">
              <span class="c0"
                >Da wir unsere Leistungen nur vollj&auml;hrigen Personen
                anbieten, nutzen wir die das Geburtsdatum, das wir Deinem
                Personalausweis entnehmen auch, um eine
                Vollj&auml;hrigkeitspr&uuml;fung vorzunehmen. Wenn Du noch
                minderj&auml;hrig bist, m&uuml;ssen wir Dich nach erfolgter
                Pr&uuml;fung als Kunden leider ablehnen.</span
              >
            </p>
            <p class="c5">
              <span class="c0"
                >Rechtsgrundlage hierf&uuml;r ist Art. 6 Abs. 1 S.1 lit. f
                DSGVO. Unser legitimes Interesse besteht darin sicherzustellen,
                dass wir keine unwirksamen Vertr&auml;ge mit Minderj&auml;hrigen
                abschlie&szlig;en.</span
              >
            </p>
            <h4 class="c27" id="h.2xcytpi">
              <span class="c28 c2">5. Account</span>
            </h4>
            <p class="c5" id="h.1ci93xb">
              <span class="c0"
                >5.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Wir
                erstellen in der App einen pers&ouml;nlichen Account f&uuml;r
                Dich. In diesem Account speichern wir die gesammelten oben
                genannten personenbezogenen Daten im Zusammenhang mit der
                Durchf&uuml;hrung des Vertrages mit Dir. Daneben verarbeiten wir
                im Zusammenhang mit Deinem Account auch die folgenden
                personenbezogenen Daten:</span
              >
            </p>
            <p class="c5">
              <span class="c0"
                >(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;das
                Initialpasswort, das wir Dir zuschicken, damit Du auf Deinen
                Account zugreifen kannst</span
              >
            </p>
            <p class="c5">
              <span class="c0"
                >(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Daten und
                Zeitpunkte, zu denen Du Dich jeweils in Deinen Account
                eingeloggt hast</span
              >
            </p>
            <p class="c5">
              <span class="c0"
                >(c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ggf. ob und
                wann Du Deinen Nutzungsvertrag mit uns gek&uuml;ndigt hast</span
              >
            </p>
            <p class="c5 c11" id="h.3whwml4"><span class="c0"></span></p>
            <p class="c5 c11"><span class="c0"></span></p>
            <p class="c5"><span class="c0">5.2</span></p>
            <p class="c5">
              <span class="c0"
                >Rechtsgrundlage f&uuml;r die Verarbeitung der in Ziffer II.5.1
                genannten personenbezogenen Daten ist Art. 6 Abs. 1 S. 1 lit. b
                DSGVO (Durchf&uuml;hrung unseres Vertrags mit Dir bzw.
                vorvertraglicher Ma&szlig;nahmen).</span
              >
            </p>
            <p class="c5">
              <span class="c0"
                >Soweit sensible Daten von der Verarbeitung umfasst sind,
                erfolgt diese auf Grundlage Deiner Einwilligung in Verbindung
                mit Art. 6 Abs. 1 S.1 lit. a und Art. 9 Abs. 2 lit.&nbsp;a
                DSGVO.</span
              >
            </p>
            <h4 class="c27" id="h.49x2ik5">
              <span class="c28 c2">6. Steuerbescheid</span>
            </h4>
            <p class="c5" id="h.2p2csry">
              <span class="c0"
                >6.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Der
                Steuerberater, der Deine Steuererkl&auml;rung f&uuml;r Dich
                erstellt hat, &uuml;bermittelt uns eine Kopie Deines
                Steuerbescheids, wenn er bei ihm eingegangen ist, damit wir
                wissen, wie hoch unser Erstattungsanteil ist.
              </span>
            </p>
            <p class="c5 c11"><span class="c0"></span></p>
            <p class="c5">
              <span class="c0"
                >6.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Rechtsgrundlage
                f&uuml;r die Verarbeitung der in Ziffer II.6.1 genannten
                personenbezogenen Daten ist Art. 6 Abs. 1 S.&nbsp;1 lit. b DSGVO
                (Durchf&uuml;hrung unseres Vertrags mit Dir).</span
              >
            </p>
            <p class="c5">
              <span class="c0"
                >Soweit Sensible Daten von der Verarbeitung umfasst sind,
                erfolgt diese auf Grundlage Deiner Einwilligung in Verbindung
                mit Art. 6 Abs. 1 S.1 lit. a und Art. 9 Abs. 2 lit.&nbsp;a
                DSGVO.</span
              >
            </p>
            <h4 class="c27" id="h.32hioqz">
              <span class="c28 c2"
                >7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Verarbeitung
                in weiteren Steuerjahren</span
              >
            </h4>
            <p class="c5" id="h.1hmsyys">
              <span class="c0"
                >Wir erm&ouml;glichen Dir, wenn Du damit einverstanden bist,
                auch mit Blick auf weitere Steuerjahre Deine
                Steuererkl&auml;rung von uns bezahlen zu lassen.
              </span>
            </p>
            <p class="c5 c11" id="h.41mghml"><span class="c0"></span></p>
            <h3 class="c37" id="h.2grqrue">
              <span class="c20">III. App </span>
            </h3>
            <h4 class="c27" id="h.vx1227">
              <span class="c28 c2">1. Aufruf im Browser</span>
            </h4>
            <p class="c5">
              <span class="c0"
                >1.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Die App
                basiert auf Google Firebase, einem Service der Google Ireland
                Limited, Gordon House, Barrow Street, Dublin 4, Ireland. Wenn Du
                die App in Deinem Browser aufrufst, werden personenbezogene
                Daten durch Deinen Browser an Google &uuml;bermittelt. Dies
                geschieht durch den Einsatz von Log Files, in denen Deine
                IP-Adresse gespeichert ist. Neben der IP-Adresse vermitteln uns
                die Log Files die folgenden Informationen:</span
              >
            </p>
            <p class="c5">
              <span class="c0"
                >(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Datum und
                Uhrzeit Deines Besuchs, Zeitzonendifferenz zur GMT</span
              >
            </p>
            <p class="c5">
              <span class="c0"
                >(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Aufgerufene
                Unterseite</span
              >
            </p>
            <p class="c5">
              <span class="c0"
                >(c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Zugriffsstatus/http-Statuscode</span
              >
            </p>
            <p class="c5">
              <span class="c0"
                >(d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&Uuml;bertragene
                Datenmenge</span
              >
            </p>
            <p class="c5">
              <span class="c0"
                >(e)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Website, von
                der die Anforderung kommt (Referrer-URL)</span
              >
            </p>
            <p class="c5">
              <span class="c0"
                >(f)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Art, Sprache
                und Version des Browsers und alle verwendeten Add-ons]</span
              >
            </p>
            <p class="c5">
              <span class="c0"
                >(g)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;genutztes
                Betriebssystem und dessen Oberfl&auml;che</span
              >
            </p>
            <p class="c5 c11"><span class="c0"></span></p>
            <p class="c5">
              <span class="c0"
                >1.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Die
                Verarbeitung dieser personenbezogenen Daten beruht auf Art. 6
                Abs. 1 S.1 lit. f DSGVO (berechtigtes Interesse). Unser
                berechtigtes Interesse besteht darin, Dir die App in guter
                Qualit&auml;t als Webversion anbieten zu k&ouml;nnen und ihre
                Stabilit&auml;t und Sicherheit gew&auml;hrleisten zu k&ouml;nnen
                und durch statistische Auswertungen die App st&auml;ndig
                verbessern zu k&ouml;nnen.</span
              >
            </p>
            <h4 class="c27" id="h.3fwokq0">
              <span class="c28 c2">2. Cookies</span>
            </h4>
            <p class="c5">
              <span class="c0"
                >2.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Wenn Du die
                App in Deinem Browser aufrufst, verwenden wir au&szlig;erdem
                Cookies. Cookies sind kleine Textdateien, die auf Deinem
                Endger&auml;t gespeichert werden. Diese Textdateien k&ouml;nnen
                von uns ausgelesen werden und helfen dabei, Dich zu
                identifizieren, wenn Du die App erneut aufrufst. Einige Cookies
                dienen der Speicherung von Pr&auml;ferenzen und werden nach dem
                Ende der Browser-Sitzung, also nach Schlie&szlig;en des
                Browsers, wieder gel&ouml;scht (sog. Session-Cookies). Andere
                Cookies dienen dazu, die die App besser auf
                Nutzerbed&uuml;rfnisse anzupassen und verbleiben auf dem
                Endger&auml;t (sog. persistente Cookies). Nachfolgend
                erl&auml;utern wir Dir, welche Cookies wir verwenden und wie Du
                diese Nutzung von Cookies verhindern kannst.</span
              >
            </p>
            <p class="c5 c11"><span class="c0"></span></p>
            <p class="c5">
              <span class="c0"
                >2.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Wir
                verwenden zum Teil eigene Cookies, arbeiten aber auch mit
                anderen Dienstleistern zusammen, die uns bei der Verbesserung
                der App unterst&uuml;tzen oder zus&auml;tzliche Funktionen
                anbieten und die unter Umst&auml;nden ihre eigenen Cookies
                verwenden.</span
              >
            </p>
            <p class="c5">
              <span class="c0"
                >Aktuell verwenden wir die folgenden Cookies:</span
              >
            </p>
            <p class="c5 c11"><span class="c0"></span></p>
            <a id="t.577098095da117e8a64642df5912e36b5809a2e5"></a
            ><a id="t.0"></a>
            <table class="c51 c59">
              <tbody>
                <tr class="c60">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6"><span class="c45">Name</span></p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6"><span class="c45">Zwecke</span></p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6"><span class="c45">Rechtsgrundlage</span></p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c45">Speicherdauer</span></p>
                  </td>
                </tr>
                <tr class="c15">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6"><span class="c7">__stripe_mid</span></p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Dieser Cookie ist notwendig, um
                        Kreditkartentransaktionen auf der Webseite
                        durchzuf&uuml;hren. Der Dienst wird von Stripe.com
                        bereitgestellt, das Online-Transaktionen
                        erm&ouml;glicht, ohne dass Kreditkarteninformationen
                        gespeichert werden.</span
                      >
                    </p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Rechtsgrundlage f&uuml;r die diesbez&uuml;gliche
                        Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DSGVO</span
                      >
                    </p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c7">1 Jahr</span></p>
                  </td>
                </tr>
                <tr class="c15">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6"><span class="c7">__stripe_sid</span></p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Dieser Cookie ist notwendig, um
                        Kreditkartentransaktionen auf der Webseite
                        durchzuf&uuml;hren. Der Dienst wird von Stripe.com
                        bereitgestellt, das Online-Transaktionen
                        erm&ouml;glicht, ohne dass Kreditkarteninformationen
                        gespeichert werden.</span
                      >
                    </p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Rechtsgrundlage f&uuml;r die diesbez&uuml;gliche
                        Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DSGVO</span
                      >
                    </p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c7">1 Tag</span></p>
                  </td>
                </tr>
                <tr class="c25">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6"><span class="c7">m</span></p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Bestimmt das Ger&auml;t, mit dem auf die Webseite
                        zugegriffen wird. Dadurch kann die Webseite entsprechend
                        formatiert werden.</span
                      >
                    </p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Rechtsgrundlage f&uuml;r die diesbez&uuml;gliche
                        Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DSGVO</span
                      >
                    </p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c7">2 Jahre</span></p>
                  </td>
                </tr>
                <tr class="c54">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7">intercom.intercom-state</span>
                    </p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Speichert eine eindeutige ID-Zeichenfolge f&uuml;r jede
                        Chat-Box-Sitzung. Dies erm&ouml;glicht der
                        Webseite-Unterst&uuml;tzung, fr&uuml;here Probleme zu
                        sehen und sich wieder mit dem vorherigen
                        Unterst&uuml;tzer zu verbinden.</span
                      >
                    </p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Rechtsgrundlage f&uuml;r die diesbez&uuml;gliche
                        Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DSGVO</span
                      >
                    </p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c7">Persistent</span></p>
                  </td>
                </tr>
                <tr class="c26">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7">@firebase/performance/config</span>
                    </p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Registriert die Geschwindigkeit und Leistung der
                        Webseite. Diese Funktion kann im Zusammenhang mit
                        Statistiken und Lastenausgleich verwendet werden.</span
                      >
                    </p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Rechtsgrundlage f&uuml;r die diesbez&uuml;gliche
                        Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. a DSGVO</span
                      >
                    </p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c7">Persistent</span></p>
                  </td>
                </tr>
                <tr class="c26">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7">@firebase/performance/configexpire</span>
                    </p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Registriert die Geschwindigkeit und Leistung der
                        Webseite. Diese Funktion kann im Zusammenhang mit
                        Statistiken und Lastenausgleich verwendet werden.</span
                      >
                    </p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Rechtsgrundlage f&uuml;r die diesbez&uuml;gliche
                        Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. a DSGVO</span
                      >
                    </p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c7">Persistent</span></p>
                  </td>
                </tr>
                <tr class="c19">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c4">_hjCachedUserAttributes</span>
                    </p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c4"
                        >Wird im Zusammenhang mit der
                        Benutzer-Feedback-Funktionalit&auml;t der Website
                        verwendet. Stellt fest, ob dem Benutzer eine
                        Feedback-Nachricht angezeigt wurde.</span
                      >
                    </p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c4">Art. 6 Abs. 1 lit. f) DSGVO</span>
                    </p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c4">Session</span></p>
                  </td>
                </tr>
                <tr class="c25">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7">_hjIncludedInPageviewSample</span>
                    </p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Wird verwendet, um festzustellen, ob die Navigation und
                        Interaktionen der Benutzer in der Datenanalyse der
                        Website enthalten sind.</span
                      >
                    </p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Rechtsgrundlage f&uuml;r die diesbez&uuml;gliche
                        Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. a DSGVO</span
                      >
                    </p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c7">1 Tag</span></p>
                  </td>
                </tr>
                <tr class="c8">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7">_hjUserAttributesHash</span>
                    </p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Gibt an, ob die Cookie-Daten im Browser des Besuchers
                        aktualisiert werden m&uuml;ssen.</span
                      >
                    </p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Rechtsgrundlage f&uuml;r die diesbez&uuml;gliche
                        Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. a DSGVO</span
                      >
                    </p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c7">1 Tag</span></p>
                  </td>
                </tr>
                <tr class="c39">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6"><span class="c7">_dc_gtm_UA-#</span></p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Wird von Google Tag Manager genutzt, um das Laden des
                        Google-Analytics-Skript-Tags zu steuern.</span
                      >
                    </p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Rechtsgrundlage f&uuml;r die diesbez&uuml;gliche
                        Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. a DSGVO</span
                      >
                    </p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c7">1 Tag</span></p>
                  </td>
                </tr>
                <tr class="c35">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6"><span class="c7">_ga</span></p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Registriert eine eindeutige ID, die verwendet wird, um
                        statistische Daten dazu, wie der Besucher die Website
                        nutzt, zu generieren.</span
                      >
                    </p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Rechtsgrundlage f&uuml;r die diesbez&uuml;gliche
                        Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. a DSGVO</span
                      >
                    </p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c7">2 Jahre</span></p>
                  </td>
                </tr>
                <tr class="c8">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6"><span class="c7">_gat</span></p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Wird von Google Analytics verwendet, um die
                        Anforderungsrate einzuschr&auml;nken</span
                      >
                    </p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Rechtsgrundlage f&uuml;r die diesbez&uuml;gliche
                        Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. a DSGVO</span
                      >
                    </p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c7">1 Tag</span></p>
                  </td>
                </tr>
                <tr class="c35">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6"><span class="c7">_gid</span></p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Registriert eine eindeutige ID, die verwendet wird, um
                        statistische Daten dazu, wie der Besucher die Website
                        nutzt, zu generieren.</span
                      >
                    </p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Rechtsgrundlage f&uuml;r die diesbez&uuml;gliche
                        Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. a DSGVO</span
                      >
                    </p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c7">1 Tag</span></p>
                  </td>
                </tr>
                <tr class="c34">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6"><span class="c7">pagead/landing</span></p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Sammelt Daten zum Besucherverhalten auf mehreren
                        Webseiten, um relevantere Werbung zu pr&auml;sentieren -
                        Dies erm&ouml;glicht es der Webseite auch, die Anzahl
                        der Anzeige der gleichen Werbung zu begrenzen.</span
                      >
                    </p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Rechtsgrundlage f&uuml;r die diesbez&uuml;gliche
                        Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. a DSGVO</span
                      >
                    </p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c7">Session</span></p>
                  </td>
                </tr>
                <tr class="c31">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6"><span class="c7">test_cookie</span></p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Verwendet, um zu &uuml;berpr&uuml;fen, ob der Browser
                        des Benutzers Cookies unterst&uuml;tzt.</span
                      >
                    </p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Rechtsgrundlage f&uuml;r die diesbez&uuml;gliche
                        Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DSGVO</span
                      >
                    </p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c7">1 Tag</span></p>
                  </td>
                </tr>
                <tr class="c35">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6"><span class="c7">_gcl_au</span></p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Wird von Google AdSense zum Experimentieren mit
                        Werbungseffizienz auf Webseiten verwendet, die ihre
                        Dienste nutzen.</span
                      >
                    </p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Rechtsgrundlage f&uuml;r die diesbez&uuml;gliche
                        Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. a DSGVO</span
                      >
                    </p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c7">3 Monate</span></p>
                  </td>
                </tr>
                <tr class="c46">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6"><span class="c4">_schn</span></p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c4"
                        >Verfolgt die einzelnen Sitzungen auf den Websites, so
                        kann die Website statistische Daten von mehreren
                        Besuchen zusammenstellen - diese Daten k&ouml;nnen auch
                        zur Erstellung von Leads f&uuml;r Marketingzwecke
                        verwendet werden.</span
                      >
                    </p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c4">Art. 6 Abs. 1 lit. a) DSGVO</span>
                    </p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c4">Session</span></p>
                  </td>
                </tr>
                <tr class="c61">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6"><span class="c4">_scid</span></p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c4"
                        >Legt eine eindeutige ID f&uuml;r den Besucher fest, die
                        es Drittanbieter-Werbetreibenden erm&ouml;glicht, den
                        Besucher mit relevanter Werbung anzusprechen. Dieser
                        Pairing-Dienst wird von Werbe-Hubs von Drittanbietern
                        bereitgestellt, die Echtzeitgebote f&uuml;r
                        Werbetreibende erm&ouml;glichen.</span
                      >
                    </p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c4">Art. 6 Abs. 1 lit. a) DSGVO</span>
                    </p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c4">13 Monate</span></p>
                  </td>
                </tr>
                <tr class="c35">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6"><span class="c7">tr</span></p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Wird von Facebook genutzt, um eine Reihe von
                        Werbeprodukten anzuzeigen, zum Beispiel Echtzeitgebote
                        dritter Werbetreibender.</span
                      >
                    </p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Rechtsgrundlage f&uuml;r die diesbez&uuml;gliche
                        Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. a DSGVO</span
                      >
                    </p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c7">Session</span></p>
                  </td>
                </tr>
                <tr class="c15">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6"><span class="c7">ads/ga-audiences</span></p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Wird von Google AdWords verwendet, um Besucher erneut
                        einzubinden, die basierend auf dem Online-Verhalten des
                        Besuchers auf verschiedenen Websites zu Kunden wechseln
                        k&ouml;nnen.</span
                      >
                    </p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Rechtsgrundlage f&uuml;r die diesbez&uuml;gliche
                        Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. a DSGVO</span
                      >
                    </p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c7">Session</span></p>
                  </td>
                </tr>
                <tr class="c19">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6"><span class="c7">unifiedPixel</span></p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Sammelt Daten &uuml;ber die Navigation und das
                        Verhalten des Benutzers auf der Website - Daraus werden
                        statistische Berichte und Heatmaps f&uuml;r den
                        Website-Besitzer erstellt.</span
                      >
                    </p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Rechtsgrundlage f&uuml;r die diesbez&uuml;gliche
                        Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. a DSGVO</span
                      >
                    </p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c7">Session</span></p>
                  </td>
                </tr>
                <tr class="c44">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6"><span class="c4">v3</span></p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c4 c43"
                        >Verwendet von Pinterest, um die Nutzung der Dienste zu
                        verfolgen.</span
                      >
                    </p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6 c11"><span class="c7"></span></p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c4">Session</span></p>
                  </td>
                </tr>
                <tr class="c54">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6"><span class="c4">sc_at</span></p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c4"
                        >Wird von Snapchat verwendet, um Werbeinhalte auf der
                        Website umzusetzen. Das Cookie erkennt die Effizienz der
                        Anzeigen und sammelt Besucherdaten f&uuml;r die weitere
                        Besuchersegmentierung.</span
                      >
                    </p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c4">Art. 6 Abs. 1 lit. a) DSGVO</span>
                    </p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c4">1 Jahr</span></p>
                  </td>
                </tr>
                <tr class="c8">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6"><span class="c4">ad_group</span></p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c4"
                        >Wird verwendet um zu erkennen, von welcher
                        Werbekampange der Nutzer gekommen ist</span
                      >
                    </p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c4">Art. 6 Abs. 1 lit. a) DSGVO</span>
                    </p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c4">29 Tage</span></p>
                  </td>
                </tr>
                <tr class="c44">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6"><span class="c4">tt_sessionId</span></p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c43 c4"
                        >Verbindet die Nutzer Session ID mit einer eindeutigen
                        interne Kunden ID</span
                      >
                    </p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6 c11"><span class="c7"></span></p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c4">Session</span></p>
                  </td>
                </tr>
                <tr class="c26">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6"><span class="c4">i</span></p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c4"
                        >Registriert die Geschwindigkeit und Leistung der
                        Webseite. Diese Funktion kann im Zusammenhang mit
                        Statistiken und Lastenausgleich verwendet werden.</span
                      >
                    </p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c4">Art. 6 Abs. 1 lit. a) DSGVO</span>
                    </p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c4">Session</span></p>
                  </td>
                </tr>
                <tr class="c31">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6"><span class="c7">CookieConsent</span></p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Speichert den Zustimmungsstatus des Benutzers f&uuml;r
                        Cookies auf der aktuellen Dom&auml;ne.</span
                      >
                    </p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Rechtsgrundlage f&uuml;r die diesbez&uuml;gliche
                        Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DSGVO</span
                      >
                    </p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c7">1 Jahr</span></p>
                  </td>
                </tr>
                <tr class="c31">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7">CookieConsentBulkSetting-#</span>
                    </p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Aktiviert die Zustimmung zur Cookie-Nutzung f&uuml;r
                        mehrere Webseiten</span
                      >
                    </p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Rechtsgrundlage f&uuml;r die diesbez&uuml;gliche
                        Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DSGVO</span
                      >
                    </p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c7">Persistent</span></p>
                  </td>
                </tr>
                <tr class="c46">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7">_hjAbsoluteSessionInProgress</span>
                    </p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Dieses Cookie wird benutzt, um zu z&auml;hlen, wie oft
                        eine Website von verschiedenen Besuchern besucht wurde
                        &ndash; dies wird durch das Zuordnen einer
                        zuf&auml;lligen ID zu einem Besucher erledigt, damit der
                        Besucher nicht zweimal registriert wird.</span
                      >
                    </p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Rechtsgrundlage f&uuml;r die diesbez&uuml;gliche
                        Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. a DSGVO</span
                      >
                    </p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c7">1 Tag</span></p>
                  </td>
                </tr>
                <tr class="c26">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6"><span class="c7">_hjFirstSeen</span></p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Dieses Cookie wird benutzt, um festzustellen, ob der
                        Besucher die Website zuvor besucht hat oder, ob er ein
                        neuer Besucher auf der Website ist.</span
                      >
                    </p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Rechtsgrundlage f&uuml;r die diesbez&uuml;gliche
                        Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. a DSGVO</span
                      >
                    </p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c7">1 Tag</span></p>
                  </td>
                </tr>
                <tr class="c25">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6"><span class="c7">_hjid</span></p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Legt eine eindeutige ID f&uuml;r die Sitzung fest.
                        Dadurch kann die Webseite Daten &uuml;ber
                        Besucherverhalten f&uuml;r statistische Zwecke
                        erhalten.</span
                      >
                    </p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Rechtsgrundlage f&uuml;r die diesbez&uuml;gliche
                        Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. a DSGVO</span
                      >
                    </p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c7">1 Jahr</span></p>
                  </td>
                </tr>
                <tr class="c25">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6"><span class="c7">_hjid</span></p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Legt eine eindeutige ID f&uuml;r die Sitzung fest.
                        Dadurch kann die Webseite Daten &uuml;ber
                        Besucherverhalten f&uuml;r statistische Zwecke
                        erhalten.</span
                      >
                    </p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Rechtsgrundlage f&uuml;r die diesbez&uuml;gliche
                        Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. a DSGVO</span
                      >
                    </p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c7">Persistent</span></p>
                  </td>
                </tr>
                <tr class="c35">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7">_hjIncludedInSessionSample</span>
                    </p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Registriert Daten auf dem Webseite-Verhalten der
                        Besucher. Dies wird f&uuml;r interne Analysen und
                        Webseite-Optimierung verwendet.</span
                      >
                    </p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Rechtsgrundlage f&uuml;r die diesbez&uuml;gliche
                        Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. a DSGVO</span
                      >
                    </p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c7">1 Tag</span></p>
                  </td>
                </tr>
                <tr class="c25">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7">_hjRecordingLastActivity</span>
                    </p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Legt eine eindeutige ID f&uuml;r die Sitzung fest.
                        Dadurch kann die Webseite Daten &uuml;ber
                        Besucherverhalten f&uuml;r statistische Zwecke
                        erhalten.</span
                      >
                    </p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Rechtsgrundlage f&uuml;r die diesbez&uuml;gliche
                        Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. a DSGVO</span
                      >
                    </p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c7">Session</span></p>
                  </td>
                </tr>
                <tr class="c25">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6"><span class="c7">_hjTLDTest</span></p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Registriert statistische Daten &uuml;ber das Verhalten
                        der Besucher auf der Webseite. Wird vom Webmaster
                        f&uuml;r interne Analysen verwendet.</span
                      >
                    </p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Rechtsgrundlage f&uuml;r die diesbez&uuml;gliche
                        Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. a DSGVO</span
                      >
                    </p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c7">Session</span></p>
                  </td>
                </tr>
                <tr class="c25">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6"><span class="c7">hjViewportId</span></p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Legt eine eindeutige ID f&uuml;r die Sitzung fest.
                        Dadurch kann die Webseite Daten &uuml;ber
                        Besucherverhalten f&uuml;r statistische Zwecke
                        erhalten.</span
                      >
                    </p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Rechtsgrundlage f&uuml;r die diesbez&uuml;gliche
                        Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. a DSGVO</span
                      >
                    </p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c7">Session</span></p>
                  </td>
                </tr>
                <tr class="c55">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6"><span class="c4">ubpv</span></p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c4"
                        >Legt fest, ob ein Besucher die Webseite sofort
                        verl&auml;sst - Diese Informationen werden f&uuml;r
                        interne Statistiken und Analysen des
                        Webseiten-Betreibers verwendet.</span
                      >
                    </p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c4">Art. 6 Abs. 1 lit. a) DSGVO</span>
                    </p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c4">6 Monate</span></p>
                  </td>
                </tr>
                <tr class="c62">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6"><span class="c4">ubvs</span></p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c4"
                        >Erfasst Daten &uuml;ber Besuche des Benutzers auf der
                        Website, wie z. B. die Anzahl der Besuche,
                        durchschnittliche Verweildauer auf der Website und
                        welche Seiten geladen wurden, mit dem Ziel der
                        Erstellung von Berichten f&uuml;r die Optimierung der
                        Website-Inhalte.</span
                      >
                    </p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c4">Art. 6 Abs. 1 lit. a) DSGVO</span>
                    </p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c4">179 Tage</span></p>
                  </td>
                </tr>
                <tr class="c62">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6"><span class="c4">ubvt</span></p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c4"
                        >Erfasst Daten &uuml;ber Besuche des Benutzers auf der
                        Website, wie z. B. die Anzahl der Besuche,
                        durchschnittliche Verweildauer auf der Website und
                        welche Seiten geladen wurden, mit dem Ziel der
                        Erstellung von Berichten f&uuml;r die Optimierung der
                        Website-Inhalte.</span
                      >
                    </p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c4">Art. 6 Abs. 1 lit. a) DSGVO</span>
                    </p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c4">2 Tage</span></p>
                  </td>
                </tr>
                <tr class="c19">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6"><span class="c7">collect</span></p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Wird verwendet, um Daten zu Google Analytics &uuml;ber
                        das Ger&auml;t und das Verhalten des Besuchers zu
                        senden. Erfasst den Besucher &uuml;ber Ger&auml;te und
                        Marketingkan&auml;le hinweg.</span
                      >
                    </p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Rechtsgrundlage f&uuml;r die diesbez&uuml;gliche
                        Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. a DSGVO</span
                      >
                    </p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c7">Session</span></p>
                  </td>
                </tr>
                <tr class="c13">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6"><span class="c4">X-AB</span></p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c4"
                        >Dieser Cookie wird vom Betreiber der Webseite im
                        Zusammenhang mit Tests mit mehreren Variationen
                        verwendet. Dies ist ein Tool, mit dem Inhalte auf der
                        Webseite kombiniert oder ge&auml;ndert werden
                        k&ouml;nnen. Dadurch kann die Webseite die beste
                        Variation / Edition der Webseite finden.</span
                      >
                    </p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c4">Art. 6 Abs. 1 lit. a) DSGVO</span>
                    </p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c4">1 Tag</span></p>
                  </td>
                </tr>
                <tr class="c35">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6"><span class="c7">_fbp</span></p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Wird von Facebook genutzt, um eine Reihe von
                        Werbeprodukten anzuzeigen, zum Beispiel Echtzeitgebote
                        dritter Werbetreibender.</span
                      >
                    </p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Rechtsgrundlage f&uuml;r die diesbez&uuml;gliche
                        Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. a DSGVO</span
                      >
                    </p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c7">3 Monate</span></p>
                  </td>
                </tr>
                <tr class="c68">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7">_hjRecordingEnabled</span>
                    </p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Dieses Cookie wird benutzt, um den Besucher zu
                        identifizieren und Werbe-Relevanz zu optimieren, indem
                        Besucherdaten von verschiedenen Websites gesammelt
                        werden &ndash; dieser Austausch von Besucherdaten wird
                        normalerweise von einem Drittanbieter-Datencenter oder
                        Ad-Exchange-Dienst zur Verf&uuml;gung gestellt.</span
                      >
                    </p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Rechtsgrundlage f&uuml;r die diesbez&uuml;gliche
                        Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. a DSGVO</span
                      >
                    </p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c7">Session</span></p>
                  </td>
                </tr>
                <tr class="c50">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6"><span class="c4">_pin_unauth</span></p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c4"
                        >Verwendet von Pinterest, um die Nutzung der Dienste zu
                        verfolgen.</span
                      >
                    </p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c4">Art. 6 Abs. 1 lit. a) DSGVO</span>
                    </p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c4">1 Jahr</span></p>
                  </td>
                </tr>
                <tr class="c19">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6"><span class="c7">outbrain_cid_fetch</span></p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Sammelt Daten &uuml;ber die Navigation und das
                        Verhalten des Benutzers auf der Website - Daraus werden
                        statistische Berichte und Heatmaps f&uuml;r den
                        Website-Besitzer erstellt.</span
                      >
                    </p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c7"
                        >Rechtsgrundlage f&uuml;r die diesbez&uuml;gliche
                        Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. a DSGVO</span
                      >
                    </p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c7">1 Tag</span></p>
                  </td>
                </tr>
                <tr class="c58">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c4"
                        >snowplowOutQueue_ubSnowplow_sp-ub_get</span
                      >
                    </p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c4"
                        >Erfasst statistische Daten zu Website-Besuchen des
                        Benutzers, wie z. B. die Anzahl der Besuche,
                        durchschnittliche Verweildauer auf der Website und
                        welche Seiten geladen wurden. Der Zweck ist die
                        Segmentierung der Benutzer der Website nach Faktoren wie
                        Demografie und geografische Lage, damit Medien- und
                        Marketing-Agenturen ihre Zielgruppen strukturieren und
                        verstehen k&ouml;nnen, um ma&szlig;geschneiderte
                        Online-Werbung zu erm&ouml;glichen.</span
                      >
                    </p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c4">Art. 6 Abs. 1 lit. a) DSGVO</span>
                    </p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c4">Persistent</span></p>
                  </td>
                </tr>
                <tr class="c31">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c4"
                        >snowplowOutQueue_ubSnowplow_sp-ub_get.expires</span
                      >
                    </p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c4"
                        >Enth&auml;lt das Verfallsdatum f&uuml;r das Cookie mit
                        entsprechendem Namen.</span
                      >
                    </p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c4">Art. 6 Abs. 1 lit. a) DSGVO</span>
                    </p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c4">Persistent</span></p>
                  </td>
                </tr>
                <tr class="c19">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6"><span class="c4">_sp_id.662e</span></p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c4"
                        >Wird verwendet, um eine h&ouml;here Ausfallsicherheit
                        der Website, einen erh&ouml;hten Schutz vor Datenverlust
                        und eine bessere Ladegeschwindigkeit der Webseite zu
                        gew&auml;hrleisten.</span
                      >
                    </p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c4">Art. 6 Abs. 1 lit. f) DSGVO</span>
                    </p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c4">Persistent</span></p>
                  </td>
                </tr>
                <tr class="c19">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c4">_sp_id.662e.expires</span>
                    </p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c4"
                        >Wird verwendet, um eine h&ouml;here Ausfallsicherheit
                        der Website, einen erh&ouml;hten Schutz vor Datenverlust
                        und eine bessere Ladegeschwindigkeit der Webseite zu
                        gew&auml;hrleisten.</span
                      >
                    </p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c4">Art. 6 Abs. 1 lit. f) DSGVO</span>
                    </p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c4">Persistent</span></p>
                  </td>
                </tr>
                <tr class="c19">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6"><span class="c4">_sp_ses.662e</span></p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c4"
                        >Wird verwendet, um eine h&ouml;here Ausfallsicherheit
                        der Website, einen erh&ouml;hten Schutz vor Datenverlust
                        und eine bessere Ladegeschwindigkeit der Webseite zu
                        gew&auml;hrleisten.</span
                      >
                    </p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c4">Art. 6 Abs. 1 lit. f) DSGVO</span>
                    </p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c4">Persistent</span></p>
                  </td>
                </tr>
                <tr class="c19">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c4">_sp_ses.662e.expires</span>
                    </p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c4"
                        >Wird verwendet, um eine h&ouml;here Ausfallsicherheit
                        der Website, einen erh&ouml;hten Schutz vor Datenverlust
                        und eine bessere Ladegeschwindigkeit der Webseite zu
                        gew&auml;hrleisten.</span
                      >
                    </p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c4">Art. 6 Abs. 1 lit. f) DSGVO</span>
                    </p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c4">Persistent</span></p>
                  </td>
                </tr>
                <tr class="c65">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c4">intercom.intercom-state-td1s7h6e</span>
                    </p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c4"
                        >Speichert eine unique chat box ID, um den Nutzer wieder
                        mit dem gleichen Chatagenten zu verbinden.</span
                      >
                    </p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c4">Art. 6 Abs. 1 lit. f) DSGVO</span>
                    </p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c4">Persistent</span></p>
                  </td>
                </tr>
                <tr class="c8">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c4">intercom-id-td1s7h6e</span>
                    </p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c4"
                        >Erm&ouml;glicht eine Chatkonversation &uuml;ber mehrere
                        Seiten hinweg ohne Unterbrechung zu f&uuml;hren.</span
                      >
                    </p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c4">Art. 6 Abs. 1 lit. f) DSGVO</span>
                    </p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c4">270 Tage</span></p>
                  </td>
                </tr>
                <tr class="c31">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c4">intercom-session-td1s7h6e</span>
                    </p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c4"
                        >Erm&ouml;glicht gestartete Unterhaltungen
                        Seiten&uuml;bergreifend fortzuf&uuml;hren.
                      </span>
                    </p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6">
                      <span class="c4">Art. 6 Abs. 1 lit. f) DSGVO</span>
                    </p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c4">6 Tage</span></p>
                  </td>
                </tr>
                <tr class="c44">
                  <td class="c14" colspan="1" rowspan="1">
                    <p class="c6"><span class="c4">tt_appInfo</span></p>
                  </td>
                  <td class="c12" colspan="1" rowspan="1">
                    <p class="c6 c11"><span class="c7"></span></p>
                  </td>
                  <td class="c1" colspan="1" rowspan="1">
                    <p class="c6 c11"><span class="c7"></span></p>
                  </td>
                  <td class="c17" colspan="1" rowspan="1">
                    <p class="c6"><span class="c4">Session</span></p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p class="c5 c11"><span class="c0"></span></p>
            <p class="c5">
              <span class="c0"
                >Weitere Informationen hierzu findest Du in den folgenden
                Abschnitten.</span
              >
            </p>
            <h4 class="c27" id="h.1v1yuxt">
              <span class="c28 c2">3. Cookies f&uuml;r Marketing</span>
            </h4>
            <p class="c5">
              <span class="c2"
                >3.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
              ><span class="c56"
                >Wie viele Unternehmen verwenden wir zusammen mit unseren
                Werbepartnern und -anbietern Cookies und &auml;hnliche
                Analyse-Technologien, wenn Du &uuml;ber das Cookie-Banner Deine
                Zustimmung dazu erteilt hast. Nach Deiner Zustimmung verwenden
                wir personenbezogene Daten von Dir, um Dir mittels Retargeting
                auf anderen Websites Werbung f&uuml;r uns anzuzeigen und unsere
                Marketingkampagnen zu verbessern. Diese Verarbeitung nehmen wir
                insbesondere dann vor, wenn Du den Eingabeprozess im Fragebogen
                zur Generierung Deiner Steuererkl&auml;rung nicht abgeschlossen
                hast. Wenn wir Werbung &uuml;ber die Werbeformate unser
                Werbepartner und -anbieter durchf&uuml;hren, haben wir die
                M&ouml;glichkeit, Informationen &uuml;ber die Nutzer von
                ExpressSteuer, von denen wir vermuten, dass diese der Zielgruppe
                eines Werbekunden angeh&ouml;ren bzw. diese bestimmte Merkmale
                aufweisen, in verschl&uuml;sselter Form an den jeweiligen
                Werbepartner und -anbieter weiterzugeben.</span
              >
            </p>
            <p class="c5 c11"><span class="c0"></span></p>
            <p class="c5">
              <span class="c0"
                >3.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Zu diesem
                Zweck verarbeiten wir die folgenden personenbezogenen Daten von
                Dir:</span
              >
            </p>
            <p class="c5">
              <span class="c0"
                >(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Deine
                IP-Adresse</span
              >
            </p>
            <p class="c5">
              <span class="c0">weiterhin, sofern von Dir eingegeben: </span>
            </p>
            <p class="c5">
              <span class="c0"
                >(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Email</span
              >
            </p>
            <p class="c5">
              <span class="c0"
                >(c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Geschlecht</span
              >
            </p>
            <p class="c5">
              <span class="c0"
                >(d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;PLZ, Stadt,
                Land</span
              >
            </p>
            <p class="c5">
              <span class="c0"
                >(e)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Vor- und
                Nachname</span
              >
            </p>
            <p class="c5">
              <span class="c0"
                >(f)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Telefonnummer</span
              >
            </p>
            <p class="c5">
              <span class="c0"
                >(g)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Anzahl an
                Sitzungen
              </span>
            </p>
            <p class="c5">
              <span class="c0"
                >(h)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sprache</span
              >
            </p>
            <p class="c5">
              <span class="c0"
                >(i)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Alter</span
              >
            </p>
            <p class="c5 c11"><span class="c0"></span></p>
            <p class="c5 c11"><span class="c0"></span></p>
            <p class="c5 c11"><span class="c0"></span></p>
            <p class="c5 c11"><span class="c0"></span></p>
            <p class="c5 c11"><span class="c0"></span></p>
            <p class="c5 c11"><span class="c0"></span></p>
            <p class="c5 c11"><span class="c0"></span></p>
            <p class="c5 c11"><span class="c0"></span></p>
            <p class="c5 c11"><span class="c0"></span></p>
            <p class="c5">
              <span class="c0"
                >3.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Zur
                Durchf&uuml;hrung unserer Marketingkampagnen bedienen wir uns
                folgender Dienstleister:</span
              >
            </p>
            <p class="c5 c11"><span class="c0"></span></p>
            <a id="t.e57f5a670efaa8764e3cbff19817c4023484bb68"></a
            ><a id="t.1"></a>
            <table class="c51 c29">
              <tbody>
                <tr class="c18">
                  <td class="c24" colspan="1" rowspan="1">
                    <p class="c5"><span class="c0">Anbieter</span></p>
                  </td>
                  <td class="c10" colspan="1" rowspan="1">
                    <p class="c5"><span class="c0">Kontakt</span></p>
                  </td>
                  <td class="c10" colspan="1" rowspan="1">
                    <p class="c5"><span class="c0">Dienst</span></p>
                  </td>
                  <td class="c10" colspan="1" rowspan="1">
                    <p class="c5">
                      <span class="c0">Datenschutzhinweise</span>
                    </p>
                  </td>
                </tr>
                <tr class="c18">
                  <td class="c24" colspan="1" rowspan="1">
                    <p class="c5"><span class="c0">Facebook Ads</span></p>
                  </td>
                  <td class="c10" colspan="1" rowspan="1">
                    <p class="c5">
                      <span class="c0"
                        >Facebook Ireland Ltd. 4 Grand Canal Square Grand Canal
                        Harbour Dublin 2 Ireland</span
                      >
                    </p>
                  </td>
                  <td class="c10" colspan="1" rowspan="1">
                    <p class="c5">
                      <span class="c0">Werbung; Conversion-Tracking</span>
                    </p>
                  </td>
                  <td class="c10" colspan="1" rowspan="1">
                    <p class="c5">
                      <span class="c0"
                        >https://www.facebook.com/about/privacy/
                        https://www.facebook.com/policies/cookies/</span
                      >
                    </p>
                  </td>
                </tr>
                <tr class="c18">
                  <td class="c24" colspan="1" rowspan="1">
                    <p class="c5"><span class="c0">Google Ads</span></p>
                  </td>
                  <td class="c10" colspan="1" rowspan="1">
                    <p class="c5">
                      <span class="c0"
                        >Google Ireland Limited Gordon House, Barrow Street,
                        Dublin 4, Ireland</span
                      >
                    </p>
                  </td>
                  <td class="c10" colspan="1" rowspan="1">
                    <p class="c5">
                      <span class="c0">Werbung; Conversion-Tracking</span>
                    </p>
                  </td>
                  <td class="c10" colspan="1" rowspan="1">
                    <p class="c5">
                      <span class="c0"
                        >http://www.google.com/policies/privacy/</span
                      >
                    </p>
                  </td>
                </tr>
                <tr class="c18">
                  <td class="c24" colspan="1" rowspan="1">
                    <p class="c5"><span class="c0">Google Analytics</span></p>
                  </td>
                  <td class="c10" colspan="1" rowspan="1">
                    <p class="c5">
                      <span class="c0"
                        >Google Ireland Limited Gordon House, Barrow Street,
                        Dublin 4, Ireland</span
                      >
                    </p>
                  </td>
                  <td class="c10" colspan="1" rowspan="1">
                    <p class="c5">
                      <span class="c0"
                        >Analyse f&uuml;r die Anzeigen von Werbetreibenden;
                        In-App-Analyse; Conversion-Tracking</span
                      >
                    </p>
                  </td>
                  <td class="c10" colspan="1" rowspan="1">
                    <p class="c5">
                      <span class="c0"
                        >http://www.google.com/policies/privacy/
                        https://support.google.com/analytics/answer/6004245?hl=en</span
                      >
                    </p>
                  </td>
                </tr>
                <tr class="c18">
                  <td class="c24" colspan="1" rowspan="1">
                    <p class="c5"><span class="c0">Google Tag Manager</span></p>
                  </td>
                  <td class="c10" colspan="1" rowspan="1">
                    <p class="c5">
                      <span class="c0"
                        >Google Ireland Limited Gordon House, Barrow Street,
                        Dublin 4, Ireland</span
                      >
                    </p>
                  </td>
                  <td class="c10" colspan="1" rowspan="1">
                    <p class="c5"><span class="c0">Tag-Manager</span></p>
                  </td>
                  <td class="c10" colspan="1" rowspan="1">
                    <p class="c5">
                      <span class="c0"
                        >http://www.google.com/policies/privacy/</span
                      >
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <h4 class="c27" id="h.4f1mdlm">
              <span class="c28 c2">4. Chat-Funktion</span>
            </h4>
            <p class="c5">
              <span class="c0"
                >4.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Wir bieten
                Dir auf die M&ouml;glichkeit, mit uns zu chatten, um
                unkompliziert Fragen stellen und schnell Antworten erhalten zu
                k&ouml;nnen. Wenn Du diese Funktion nutzt, verarbeiten wir die
                folgenden personenbezogenen Daten von Dir:</span
              >
            </p>
            <p class="c5">
              <span class="c0"
                >(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Deine
                IP-Adresse</span
              >
            </p>
            <p class="c5">
              <span class="c0"
                >(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;die von Dir
                mitgeteilten personenbezogenen Daten, also Deinen Namen, Deine
                E-Mail-Adresse und Telefonnummer sowie die Beschreibung Deines
                konkreten Anliegens</span
              >
            </p>
            <p class="c5 c11"><span class="c0"></span></p>
            <p class="c5">
              <span class="c0"
                >4.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Rechtsgrundlage
                f&uuml;r diese Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. b DSGVO
                (Durchf&uuml;hrung unseres Vertrags mit Dir bzw.
                vorvertraglicher Ma&szlig;nahmen).</span
              >
            </p>
            <h4 class="c27" id="h.2u6wntf">
              <span class="c28 c2">5. Kontaktaufnahme mit ExpressSteuer</span>
            </h4>
            <p class="c5">
              <span class="c0"
                >5.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Solltest Du
                anders als &uuml;ber die Chat-Funktion direkt zu uns Kontakt
                aufnehmen, z.B. wegen technischer Fragen oder Fragen zu Deiner
                Steuererkl&auml;rung, verarbeiten wir die von Dir mitgeteilten
                personenbezogenen Daten, also Deinen Namen, Deine E-Mail-Adresse
                und Telefonnummer sowie die Beschreibung Deines konkreten
                Anliegens. Diese personenbezogenen Daten verarbeiten wir, um
                Dein Anliegen bearbeiten zu k&ouml;nnen.</span
              >
            </p>
            <p class="c5 c11"><span class="c0"></span></p>
            <p class="c5">
              <span class="c0"
                >5.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Rechtsgrundlage
                f&uuml;r diese Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. b DSGVO.
              </span>
            </p>
            <h3 class="c37" id="h.19c6y18">
              <span class="c20">IV. Weitergabe personenbezogener Daten</span>
            </h3>
            <p class="c5">
              <span class="c0"
                >Die Durchf&uuml;hrung unserer Leistungen erfordert teilweise
                die Weitergabe personenbezogener Daten.</span
              >
            </p>
            <h4 class="c63" id="h.pkj3x3fce75r">
              <span class="c28 c2"
                >1.Weitergabe von personenbezogenen Daten an Steuerberater oder
                Rechtsanwalt</span
              >
            </h4>
            <p class="c5 c22">
              <span class="c0"
                >Zur &Uuml;bermittlung Deiner Steuererkl&auml;rung an das
                Finanzamt ist die Weitergabe Deiner personenbezogenen Daten an
                einen Steuerberater erforderlich, mit dem Du ebenfalls einen
                Vertrag schlie&szlig;t. Bei den weiterleitenden Daten handelt es
                sich wiederum auch um besondere Kategorien personenbezogener
                Daten im Sinne von Art. 9 DSGVO (&bdquo;Sensible Daten&ldquo;).
                Diese Sensiblen Daten k&ouml;nnen beispielsweise
                Gesundheitsdaten (z.B. die Information daru&#776;ber ob Du eine
                Behinderung hast), Deine religi&ouml;sen &Uuml;berzeugungen (das
                ergibt sich ggf. aus der Information, ob Du Kirchensteuer
                zahlst), Deine Gewerkschaftszugeh&ouml;rigkeit sowie ggf.
                Informationen zur Deiner sexuellen Orientierung (das ergibt sich
                ggf. aus der Information zu Deinem Familienstand) oder zu Deiner
                politischen Meinung (das kann sich beispielsweise aus Deinem
                Beruf selbst ergeben) umfassen. Welche Sensiblen Daten wir genau
                verarbeiten ergibt sich aus den Angaben, die Du im
                Klick-Menu&#776; t&auml;tigen wirst. Wir mu&#776;ssen diese
                Sensiblen Daten an einen Steuerberater weiterleiten, damit
                dieser die Steuererkl&auml;rung fu&#776;r Dich einreichen kann.
                Damit wir Deine Sensiblen Daten an die Steuerberater
                weiterleiten du&#776;rfen, ben&ouml;tigen wir Deine
                Einwilligung. Weitere Informationen zu den Details der
                Datenverarbeitung durch uns oder den Steuerberater entnimm bitte
                dieser Datenschutzerkl&auml;rung und der
                Datenschutzerkl&auml;rung von des Steuerberaters.</span
              >
            </p>
            <p class="c5 c22">
              <span class="c2"
                >Du kannst Deine Einwilligung jederzeit, beispielsweise durch
                einfache E-Mail an </span
              ><span class="c2">datenschutz@expresssteuer.com</span
              ><span class="c0"
                >, widerrufen. Wir du&#776;rfen Ihre Sensiblen Daten ab dem
                Zeitpunkt des Zugangs Deines Widerrufs bei uns nicht mehr an den
                Steuerberater weiterleiten. Bitte beachte, dass eine bis zu
                diesem Zeitpunkt bereits erfolgte Datenweitergabe dadurch nicht
                unrechtm&auml;&szlig;ig wird und auch, dass eine sp&auml;ter
                erfolgende Verarbeitung Deiner Sensiblen Daten durch den
                Steuerberater &nbsp;nicht rechtswidrig wird. Bitte beachte auch,
                dass die Weitergabe der oben genannten Sensiblen Daten fu&#776;r
                die Erbringung unserer Dienste erforderlich ist. Wenn Du also
                Deine Einwilligung widerrufst, k&ouml;nnen wir Dir nicht weiter
                dabei helfen, einen Steuerberater/eine Steuerberaterin oder
                einen Rechtsanwalt/eine Rechtsanw&auml;ltin zu beauftragen,
                der/die Deine Steuererkl&auml;rung fu&#776;r Dich
                einreicht.</span
              >
            </p>
            <p class="c5 c11"><span class="c0"></span></p>
            <h4 class="c27" id="h.t9ak7rbmm2um">
              <span class="c32">2</span
              ><span class="c28 c2"
                >. Weitergabe personenbezogener Daten an
                Auftragsverarbeiter</span
              >
            </h4>
            <p class="c5">
              <span class="c0"
                >Um unsere Leistungen vollst&auml;ndig anbieten zu k&ouml;nnen,
                setzen wir externe Dienstleister ein, die in unserem Auftrag
                personenbezogene Daten verarbeiten. Diese Dienstleister wurden
                von uns sorgf&auml;ltig ausgew&auml;hlt und sind an unsere
                Weisungen gebunden. Sie verf&uuml;gen zudem &uuml;ber geeignete
                organisatorische und technische Ma&szlig;nahmen zum Schutz
                Deiner Rechte und werden von uns regelm&auml;&szlig;ig
                kontrolliert. Die Zusammenarbeit mit solchen Dienstleistern ist
                jeweils vertraglich mit einer Auftragsverarbeitungsvereinbarung
                nach Art. 28 DSGVO geregelt. Wir nutzen f&uuml;r die Erbringung
                unserer Leistungen folgende Auftragsverarbeiter:</span
              >
            </p>
            <p class="c5 c11"><span class="c0"></span></p>
            <p class="c5 c11"><span class="c0"></span></p>
            <p class="c5 c11"><span class="c0"></span></p>
            <p class="c5 c11"><span class="c0"></span></p>
            <p class="c5">
              <span class="c49">3</span
              ><span class="c28 c38">. IT-Infrastruktur</span>
            </p>
            <p class="c5">
              <span class="c2"
                >Unsere IT-Infrastruktur basiert auf der Google Cloud Platform,
                ein Service der Google Commerce Limited (&quot;GCL&quot;),
                &nbsp;Gordon House, Barrow Street, Dublin 4, Ireland. Hinweise
                zum Datenschutz von Google Cloud findest Du hier: </span
              ><span class="c2 c30"
                ><a
                  class="c41"
                  href="https://www.google.com/url?q=http://www.google.com/policies/privacy/&amp;sa=D&amp;source=editors&amp;ust=1650901703865365&amp;usg=AOvVaw23hXJaJF33S6MfH0ap3fYX"
                  >http://www.google.com/policies/privacy/</a
                ></span
              ><span class="c0"
                >. &nbsp;Wir nutzen Google Firebase Hosting, ein Service der
                Google Ireland Limited, Gordon House, Barrow Street, Dublin 4,
                Ireland. Hinweise zum Datenschutz von Google Firebase findest Du
                hier: http://www.google.com/policies/privacy/ Um die als Web-App
                angebotene App bereitstellen zu k&ouml;nnen, leiten wir
                personenbezogene Daten an Google weiter.</span
              >
            </p>
            <h4 class="c27" id="h.28h4qwu">
              <span class="c32">4</span
              ><span class="c28 c2"
                >. Weitergabe personenbezogener Daten an Dritte</span
              >
            </h4>
            <p class="c5">
              <span class="c0"
                >Die Durchf&uuml;hrung unserer Leistung erfordert teilweise auch
                die Weitergabe personenbezogener Daten an Dritte.</span
              >
            </p>
            <p class="c5">
              <span class="c0"
                >4.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&Uuml;bermittlung
                personenbezogener Daten an einen Steuerberater</span
              >
            </p>
            <p class="c5 c29">
              <span class="c0"
                >Wir &uuml;bermitteln Deine personenbezogenen Daten im oben
                beschriebenen Umfang an den in Deinem Namen und Auftrag
                mandatierten Steuerberater, &nbsp;der Deine Steuererkl&auml;rung
                f&uuml;r Dich erstellen und einreichen wird. Dieser
                Steuerberater verarbeitet die personenbezogenen Daten im Rahmen
                seiner gesetzlichen Befugnisse in eigener Verantwortlichkeit.
                N&auml;here Informationen findest Du dazu in der
                Datenschutzerkl&auml;rung des Steuerberaters. Rechtsgrundlage
                f&uuml;r die &Uuml;bermittlung an den Steuerberater ist Art. 6
                Abs. 1 S.&nbsp;1 lit. b DSGVO. Die Weitergabe Sensibler Daten
                basiert auf Deiner Einwilligung in Verbindung mit Art. 6 Abs. 1
                S.&nbsp;1 lit. a DSGVO und Art. 9 Abs.&nbsp;2 lit. a
                DSGVO.</span
              >
            </p>
            <p class="c5 c11 c29"><span class="c0"></span></p>
            <p class="c5" id="h.nmf14n">
              <span class="c0"
                >4.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Kommunikationsdienstleister
              </span>
            </p>
            <p class="c5 c29">
              <span class="c0"
                >Damit wir mit Dir via Email, Messenger, SMS und Telefon
                kommunizieren k&ouml;nnen, arbeiten wir mir mit Dienstleistern
                zusammen. Dies setzt voraus, dass wir die in der jeweiligen
                Kommunikation enthaltenen personenbezogenen Daten und Deine
                Kontaktdaten an folgende Dienstleister weitergeben m&uuml;ssen:
              </span>
            </p>
            <a id="t.5d332d5f2b26c2d794ce1eb29fc8c731b3df843f"></a
            ><a id="t.2"></a>
            <table class="c29 c51">
              <tbody>
                <tr class="c18">
                  <td class="c33" colspan="1" rowspan="1">
                    <p class="c5"><span class="c0">Anbieter</span></p>
                  </td>
                  <td class="c36" colspan="1" rowspan="1">
                    <p class="c5"><span class="c0">Kontakt</span></p>
                  </td>
                  <td class="c36" colspan="1" rowspan="1">
                    <p class="c5"><span class="c0">Medium</span></p>
                  </td>
                </tr>
                <tr class="c18">
                  <td class="c33" colspan="1" rowspan="1">
                    <p class="c5"><span class="c0">Twillio</span></p>
                  </td>
                  <td class="c36" colspan="1" rowspan="1">
                    <p class="c5">
                      <span class="c0"
                        >Twilio Ireland Limited, 25-28 North Wall Quay, Dublin
                        1, Irland</span
                      >
                    </p>
                  </td>
                  <td class="c36" colspan="1" rowspan="1">
                    <p class="c5"><span class="c0">Telefon, SMS,</span></p>
                  </td>
                </tr>
                <tr class="c18">
                  <td class="c33" colspan="1" rowspan="1">
                    <p class="c5"><span class="c0">CloudMailIn</span></p>
                  </td>
                  <td class="c36" colspan="1" rowspan="1">
                    <p class="c5">
                      <span class="c0"
                        >Dynamic Edge Software Ltd. Registered Company 7444971
                        in England</span
                      >
                    </p>
                  </td>
                  <td class="c36" colspan="1" rowspan="1">
                    <p class="c5"><span class="c0">Inbound Email</span></p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p class="c5 c11"><span class="c0"></span></p>
            <p class="c5 c11"><span class="c0"></span></p>
            <p class="c5 c53">
              <span class="c0"
                >Rechtsgrundlage f&uuml;r die diesbez&uuml;gliche Verarbeitung
                ist Art. 6 Abs. 1 S.&nbsp;1 lit. b.
              </span>
            </p>
            <p class="c5 c11 c53"><span class="c0"></span></p>
            <p class="c52">
              <span class="c20"
                >V. Keine automatisierte Entscheidungsfindung</span
              >
            </p>
            <p class="c9">
              <span class="c0"
                >Es findet keine automatisierte Entscheidungsfindung gem. Art.
                22 Abs.1 oder 4 DSGVO statt.</span
              >
            </p>
            <p class="c9 c11"><span class="c0"></span></p>
            <p class="c9">
              <span class="c2"
                >Es werden keine Daten and Drittl&auml;nder weitergegeben.</span
              >
            </p>
            <p class="c5 c11"><span class="c0"></span></p>
            <h4 class="c57" id="h.46r0co2">
              <span class="c21">VI. Speicherdauer</span>
            </h4>
            <p class="c5">
              <span class="c0"
                >Wir bewahren Deine personenbezogenen Daten nur so lange auf,
                wie wir sie zur Erreichung des jeweiligen Verarbeitungszwecks
                ben&ouml;tigen und l&ouml;schen sie im Anschluss, es sei denn
                wir sind zur weiteren Aufbewahrung aufgrund gesetzlicher
                Vorschriften berechtigt oder verpflichtet.</span
              >
            </p>
            <h4 class="c57" id="h.2lwamvv">
              <span class="c21">VII. Deine Rechte</span>
            </h4>
            <p class="c3">
              <span class="c0"
                >Du hast das Recht, uns gegen&uuml;ber die nachfolgend
                aufgef&uuml;hrten Rechte hinsichtlich der Dich betreffenden
                personenbezogenen Daten geltend zu machen:</span
              >
            </p>
            <p class="c5">
              <span class="c0"
                >(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Das Recht
                auf Auskunft &uuml;ber Deine von uns verarbeiteten
                personenbezogenen Daten einschlie&szlig;lich der
                Verarbeitungszwecke nach Art. 15 DSGVO,</span
              >
            </p>
            <p class="c5">
              <span class="c0"
                >(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;das Recht
                auf Berichtigung unrichtiger personenbezogener Daten nach Art.
                16 DSGVO,</span
              >
            </p>
            <p class="c5">
              <span class="c0"
                >(c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;das Recht
                auf L&ouml;schung Deiner personenbezogenen Daten (&bdquo;Recht
                auf Vergessenwerden&ldquo;) nach Art. 17 DSGVO,</span
              >
            </p>
            <p class="c5">
              <span class="c0"
                >(d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;das Recht
                auf Einschr&auml;nkung der Verarbeitung Deiner personenbezogenen
                Daten nach Art. 18 DSGVO,</span
              >
            </p>
            <p class="c5">
              <span class="c0"
                >(e)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;das Recht
                nach Art. 20 DSGVO, eine Kopie Deiner personenbezogenen Daten in
                einem strukturierten, g&auml;ngigen und maschinenlesbaren Format
                zu erhalten, sofern deren Verarbeitung auf Deiner Einwilligung
                beruht oder diese im Rahmen einer oder im Hinblick auf eine
                Vertragsbeziehung mit Dir verarbeitet werden (&bdquo;Recht auf
                Daten&uuml;bertragbarkeit&ldquo;),
              </span>
            </p>
            <p class="c5">
              <span class="c0"
                >(f)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;das Recht
                nach Art. 7 Abs. 3 DSGVO Deine Einwilligung, auf der die
                Verarbeitung Deiner personenbezogenen Daten beruht, jederzeit zu
                widerrufen; sowie<br />(g)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;das
                Recht der Verarbeitung Deiner personenbezogenen Daten jederzeit
                zu widersprechen, sofern wir Deine personenbezogenen Daten zu
                Zwecken der Direktwerbung verarbeiten oder sofern wir Deine
                personenbezogenen Daten zur Verfolgung unser berechtigten
                Interessen verarbeiten und Gr&uuml;nde vorliegen, die sich aus
                Deiner besonderen Situation ergeben (Art. 21 Abs. 1
                DSGVO).</span
              >
            </p>
            <p class="c5">
              <span class="c0"
                >Diese Rechte kannst Du per E-Mail an
                datenschutz@expressteuer.com oder postalisch ExpressSteuer GmbH,
                Am Sandtorkai 73, 20457 Hamburg, Email:
                datenschutz@expresssteuer.com Telefon: +49 (0) 40228682756
                &nbsp;Hamburg geltend machen. Du hast dar&uuml;ber hinaus das
                Recht, Beschwerde bei einer Datenschutzaufsichtsbeh&ouml;rde
                &uuml;ber unsere Verarbeitung Deiner personenbezogenen Daten
                einzulegen.</span
              >
            </p>
            <p class="c5 c11"><span class="c0"></span></p>
            <p class="c5"><span class="c2">Stand: 10. August 2021</span></p>
            <p class="c5 c11"><span class="c43 c38 c64"></span></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  id="agbModal"
  class="modal modalMinHeight200 modal-left fade in"
  role="dialog"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="close" data-dismiss="modal">×</button>
        <div class="row">
          <div class="col-sm-12">
            <div class="col-sm-12"></div>

            <div class="col-sm-12">
              <h2 class="c15">
                <span class="c22"
                  >Leistungsvereinbarung mit ExpressSteuer GmbH</span
                >
              </h2>
              <p class="c2 c6"><span class="c11"></span></p>
              <h4 class="c1" id="h.30j0zll">
                <span class="c11">1. Sachlicher Geltungsbereich</span>
              </h4>
              <p class="c2 c6"><span class="c3"></span></p>
              <p class="c2">
                <span
                  >1.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Diese
                  Leistungsvereinbarung (&bdquo;Vereinbarung&ldquo;) der
                  ExpressSteuer GmbH, Am Sandtorkai 73, 20457 Hamburg
                  (&bdquo;ExpressSteuer&ldquo;, &bdquo;Wir&ldquo;) gilt f&uuml;r
                  alle Leistungen von ExpressSteuer gegen&uuml;ber Kunden
                  (&bdquo;Kunde&ldquo;, &bdquo;Sie&ldquo;).</span
                >
              </p>
              <p class="c2 c6"><span class="c3"></span></p>
              <p class="c2">
                <span class="c3"
                  >1.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ExpressSteuer
                  bietet die Bezahlung der Kosten an, die f&uuml;r die
                  Erstellung und Einreichung einer Steuererkl&auml;rung in
                  Deutschland durch einen Steuerberater anf&auml;llt.
                </span>
              </p>
              <h4 class="c0" id="h.1fob9te"><span class="c3"></span></h4>
              <h4 class="c1">
                <span class="c11"
                  >2. Pers&ouml;nlicher Geltungsbereich und Leistung</span
                >
              </h4>
              <p class="c2 c6"><span class="c3"></span></p>
              <ol class="c7 lst-kix_s0b19jb25t53-1 start" start="1">
                <li class="c2 c17 li-bullet-0" id="h.2et92p0">
                  <span class="c3"
                    >Die Nutzung unserer Leistungen ist nur vollj&auml;hrigen
                    nat&uuml;rlichen Personen gestattet. ExpressSteuer ist
                    berechtigt, Kunden die Erbringung seiner Leistungen zu
                    verweigern, wenn diese den Nachweis &uuml;ber ihre
                    Vollj&auml;hrigkeit auf Anfrage nicht erbringen k&ouml;nnen.
                  </span>
                </li>
              </ol>
              <p class="c2 c8 c6"><span class="c3"></span></p>
              <ol class="c7 lst-kix_s0b19jb25t53-1" start="2">
                <li class="c2 c17 li-bullet-0">
                  <span class="c3"
                    >Sofern diese Vereinbarung zwischen dem Kunden und
                    ExpressSteuer zustande gekommen ist, mandatiert
                    ExpressSteuer namens und im Auftrag des Kunden einen
                    Steuerberater, &uuml;bermittelt diesem die steuerlich
                    relevanten personenbezogenen Daten des Kunden und zahlt die
                    nach Vertragsschluss mit dem Kunden entstandenen und zur
                    Erstellung und Einreichung einer Steuererkl&auml;rung
                    erforderlichen Steuerberatunsgkosten. ExpressSteuer leistet
                    diese Zahlungen direkt zu H&auml;nden des von dem Kunden
                    beauftragten Steuerberaters. Der Kunde erteilt diesem
                    entsprechende Geldempfangsvollmacht. Eine Auszahlung an den
                    Kunden ausgeschlossen.</span
                  >
                </li>
              </ol>
              <p class="c2 c8 c6"><span class="c3"></span></p>
              <ol class="c7 lst-kix_s0b19jb25t53-1" start="3">
                <li class="c2 c17 li-bullet-0">
                  <span class="c3"
                    >Sofern nicht mit Wissen und Billigung von ExpressSteuer
                    eine anderweitige Honorarvereinbarung getroffen wurde, zahlt
                    ExpressSteuer Steuerberatungsgeb&uuml;hren gem&auml;&szlig;
                    Steuerberatungsverg&uuml;tungsverordnung (StBVV). Die auf
                    die Kosten entfallende Umsatzsteuer zahlt ExpressSteuer nur,
                    soweit der Kunde nicht zum Vorsteuerabzug berechtigt ist.
                    Nicht &uuml;bernommen werden Reisekosten des Kunden,
                    Hebegeb&uuml;hren, Kosten f&uuml;r einen
                    Unterbevollm&auml;chtigten.</span
                  >
                </li>
              </ol>
              <p class="c2 c6 c8"><span class="c11"></span></p>
              <h4 class="c1">
                <span class="c11"
                  >3. Einr&auml;umung von Nutzungsrechten an der App und
                  Account-Erstellung in der App</span
                >
              </h4>
              <p class="c5 c6"><span class="c3"></span></p>
              <p class="c2">
                <span class="c3"
                  >3.1 ExpressSteuer legt unmittelbar nach Vertragsschluss einen
                  Account f&uuml;r den Kunden an. Der Kunde kann seinen Account
                  nutzen, um den Bearbeitungsstatus seiner eingereichten
                  Stuererkl&auml;rung(en) nachzuverfolgen oder seine Angaben zu
                  aktualisieren.
                </span>
              </p>
              <p class="c5 c6"><span class="c3"></span></p>
              <p class="c2">
                <span class="c3"
                  >3.2 ExpressSteuer sendet dem Kunden ein initiales Passwort
                  zu, mit welchem dem Kunden der Zugriff auf seinen Account
                  erm&ouml;glicht wird. Unmittelbar nach Erhalt dieses
                  Initialpassworts sowie danach in regelm&auml;&szlig;igen
                  Abst&auml;nden ist der Kunde verpflichtet, sein Passwort zu
                  &auml;ndern. Der Kunde ist verpflichtet, seinen Benutzernamen
                  wie auch sein Kennwort vor unberechtigtem Zugriff Dritter zu
                  sch&uuml;tzen. Er ist weiter verpflichtet, ExpressSteuer
                  unverz&uuml;glich zu informieren, sobald er Hinweise darauf
                  erh&auml;lt, dass seine Zugangsdaten von unberechtigten
                  Dritten missbraucht wurden oder missbraucht werden.
                  ExpressSteuer beh&auml;lt sich vor, den Zugang zur App nach
                  dreimaliger Eingabe eines falschen Kennworts zu sperren.</span
                >
              </p>
              <p class="c5 c6"><span class="c3"></span></p>
              <p class="c2">
                <span class="c3"
                  >3.3 ExpressSteuer r&auml;umt dem Kunden f&uuml;r die Dauer
                  des Nutzungszeitraums unentgeltlich ein zeitlich
                  beschr&auml;nktes, nicht exklusives, territorial nicht
                  beschr&auml;nktes, aber nicht unterlizenzierbares
                  Nutzungsrecht an der App ein. Das Nutzungsrecht umfasst das
                  Recht, die App zu nutzen, um mit ExpressSteuer zu
                  kommunizieren oder auf seinen Account zuzugreifen.
                  Au&szlig;erdem umfasst sind diejenigen Rechte, die
                  ben&ouml;tigt werden, um die Beauftragung von ExpressStuer
                  &uuml;ber die App zu erm&ouml;glichen.</span
                >
              </p>
              <p class="c5 c6"><span class="c3"></span></p>
              <p class="c5">
                <span class="c3"
                  >3.4 Der Kunde ist verpflichtet, die App nicht zu anderen
                  Zwecken als in dieser Vereinbarung vorgesehen oder
                  missbr&auml;uchlich zu nutzen.
                </span>
              </p>
              <p class="c5 c6"><span class="c3"></span></p>
              <p class="c5">
                <span class="c3"
                  >3.5 Der Kunde verpflichtet sich, jegliche Manipulation der
                  App zu unterlassen.
                </span>
              </p>
              <p class="c5 c6"><span class="c3"></span></p>
              <p class="c2 c8 c6"><span class="c11"></span></p>
              <p class="c2"><span class="c11">4. Zahlungsleistung </span></p>
              <p class="c2 c6"><span class="c11"></span></p>
              <p class="c2">
                <span class="c3"
                  >4.1 ExpressSteuer erbringt gegen&uuml;ber dem Kunden eine
                  Zahlungsleistung, indem ExpressSteuer f&uuml;r den Kunden die
                  Kosten bezahlt, die der Kunde demjenigen Steuerberater, den
                  wir in seinem Namen und Auftrag mandatieren, f&uuml;r die
                  Erstellung und Einreichung einer Steuererkl&auml;rung in
                  Deutschland schuldet (&bdquo;Steuerberaterkosten&ldquo;). Die
                  Auswahl des vom Kunden mandatierten Steuerberaters obliegt
                  ExpressSteuer.</span
                >
              </p>
              <p class="c2 c6"><span class="c3"></span></p>
              <p class="c2">
                <span class="c3"
                  >4.2 Die Zahlung erfolgt direkt an den Steuerberater, eine
                  Auszahlung an den Kunden ist nicht m&ouml;glich.
                </span>
              </p>
              <p class="c5 c6"><span class="c3"></span></p>
              <p class="c2">
                <span class="c3"
                  >4.3 ExpressSteuer hat keinen Anspruch gegen den Kunden auf
                  R&uuml;ckzahlung der durch ExpressSteuer gezahlten
                  Steuerberaterkosten. Einziger Zahlungsanspruch von
                  ExpressSteuer ist der Erstattungsanteil einer Steuererstattung
                  des Kunden (siehe &sect; 5). Sofern keine Steuererstattung
                  erfolgt, steht ExpressSteuer kein Anspruch gegen den Kunden
                  zu.</span
                >
              </p>
              <p class="c2 c6"><span class="c11"></span></p>
              <p class="c2">
                <span class="c11"
                  >5. Beteiligung von ExpressSteuer an etwaigen
                  Steuererstattungen
                </span>
              </p>
              <h4 class="c0 c8 c9"><span class="c11"></span></h4>
              <h4 class="c1 c8 c9">
                <span class="c3"
                  >5.1 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Von einer
                  Steuererstattung aus einem Veranlagungsjahr, f&uuml;r das
                  ExpressSteuer die Erstellung und Einreichung einer
                  Steuererkl&auml;rung bezahlt hat
                  (&bdquo;Steuererstattung&ldquo;), erh&auml;lt ExpressSteuer
                  einen Anteil von 20% (&bdquo;Erstattungsanteil&ldquo;), wenn
                  nichts anderes vereinbart ist. &nbsp;</span
                >
              </h4>
              <h4 class="c0"><span class="c3"></span></h4>
              <h4 class="c1 c8 c9">
                <span class="c3"
                  >5.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Unter
                  &bdquo;Steuererstattung&ldquo; in diesem Sinne f&auml;llt
                  jeder Verm&ouml;gensvorteil, insbesondere jede Geldleistung
                  (inkl. Zinsen) oder Befreiung von einer Verbindlichkeit, die
                  der Kunde nach Abschluss dieser Vereinbarung aufgrund einer
                  gerichtlichen oder beh&ouml;rdlichen Entscheidung, eines
                  gerichtlichen oder au&szlig;ergerichtlichen Vergleiches, eines
                  Anerkenntnisses oder sonst f&uuml;r das Veranlagungsjahr
                  zur&uuml;ckerh&auml;lt.</span
                >
              </h4>
              <h4 class="c0"><span class="c3"></span></h4>
              <h4 class="c1 c8 c9">
                <span class="c3"
                  >5.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Der
                  Anspruch von ExpressSteuer auf Zahlung des Erstattungsanteils
                  wird f&auml;llig, sobald die Steuererstattung dem Kunden oder
                  seinem Vertreter zuflie&szlig;t, im Falle der Befreiung von
                  der Verbindlichkeit, sobald diese wirksam wird und ansonsten,
                  sobald ein erlangter Verm&ouml;gensvorteil eintritt.</span
                >
              </h4>
              <p class="c5 c6"><span class="c3"></span></p>
              <p class="c2 c8 c9">
                <span class="c3"
                  >5.4. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Der
                  Kunde erteilt ExpressSteuer die Befugnis, den Steuerberater
                  anzuweisen, den Erstattungsanteil an ExpressSteuer GmbH
                  auszukehren.</span
                >
              </p>
              <p class="c2 c8 c6 c9"><span class="c3"></span></p>
              <p class="c2 c8 c9">
                <span class="c3"
                  >5.5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ExpressSteuer
                  kann Kunden, mit denen die Zahlung eines Festbetrags als
                  Gegenleistung f&uuml;r die Leistung von ExpressSteuer
                  vereinbart ist, anbieten, die Zahlung des Festpreises aus
                  einer Steuererstattung aus einem anderen Veranlagungsjahr
                  vorzunehmen. Es kann also entsprechend der Vereinbarung der
                  Parteien f&uuml;r die Leistung der ExpressSteuer f&uuml;r ein
                  bestimmtes Veranlagungsjahr ein Festpreis vereinbart sein und
                  f&uuml;r ein anderes Veranlagungsjahr ein Erstattungsanteil.
                  Der Kunde erteilt in diesem Fall ExpressSteuer die Befugnis,
                  den Steuerberater anzuweisen, aus der Steuererstattung neben
                  dem Erstattungsanteil auch den Festpreis an ExpressSteuer GmbH
                  auszukehren. Der Kunde erteilt ExpressSteuer ferner die
                  Befugnis, den Steuerberater anzuweisen, aus einer
                  Steuererstattung vom Kunden noch nicht bezahlte
                  Erstattungsanteile an ExpressGmbH auszukehren, die der Kunde
                  ExpressSteuer GmbH aus der Vergangenheit schuldet.</span
                >
              </p>
              <h4 class="c0"><span class="c3"></span></h4>
              <h4 class="c0"><span class="c3"></span></h4>
              <h4 class="c1">
                <span class="c11">6. Auskunftsverpflichtungen des Kunden</span>
              </h4>
              <h4 class="c0"><span class="c11"></span></h4>
              <p class="c2 c8 c9">
                <span class="c3"
                  >6.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Der Kunde
                  wird unaufgefordert bzw. auf Verlangen von ExpressSteuer
                  Auskunft dar&uuml;ber erteilen, ob, in welcher Form und in
                  welchem Umfang Steuererstattungen zugeflossen sind.</span
                >
              </p>
              <p class="c2 c6"><span class="c3"></span></p>
              <p class="c2">
                <span class="c3"
                  >6.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Der Kunde
                  ist ferner verpflichtet, einem von ExpressSteuer
                  beauftragten&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Rechtsanwalt,
                  Steuerberater oder Wirtschaftspr&uuml;fer Einsicht in
                  s&auml;mtliche Unterlagen zu gestatten, die Aufschluss
                  &uuml;ber die Frage geben k&ouml;nnen, ob, in welcher Form und
                  in welcher H&ouml;he Steuererstattungen erfolgt sind.</span
                >
              </p>
              <p class="c2 c6"><span class="c3"></span></p>
              <p class="c2">
                <span class="c3"
                  >6.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Bez&uuml;glich
                  der bezahlten Erstellung und Einreichung einer
                  Steuererkl&auml;rung entbindet der Kunde den mandatierten
                  Steuerberater hiermit gegen&uuml;ber ExpressSteuer
                  vollst&auml;ndig von der Schweigepflicht.</span
                >
              </p>
              <p class="c2 c6"><span class="c3"></span></p>
              <p class="c2">
                <span class="c3"
                  >6.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Der Kunde
                  wird &uuml;ber den mandatierten Steuerberater ExpressSteuer
                  laufend und unverz&uuml;glich &uuml;ber den Gang des
                  Verfahrens informiert halten. Dar&uuml;ber hinaus wird der
                  Kunde ExpressSteuer unaufgefordert und unverz&uuml;glich
                  &uuml;ber s&auml;mtliche, bisher nicht bekannte Umst&auml;nde
                  informieren, die f&uuml;r die Bewertung oder Durchsetzung der
                  Anspr&uuml;che von Bedeutung sein k&ouml;nnen. Diese Pflichten
                  wird der Kunde auch seinem Steuerberater auferlegen.</span
                >
              </p>
              <h4 class="c0"><span class="c11"></span></h4>
              <h4 class="c0"><span class="c3"></span></h4>
              <h4 class="c1">
                <span class="c11"
                  >7. Kommunikation zwischen ExpressSteuer und dem Kunden</span
                >
              </h4>
              <p class="c2 c6" id="h.3dy6vkm"><span class="c3"></span></p>
              <p class="c2">
                <span class="c3"
                  >Jegliche Kommunikation zwischen dem Kunden und ExpressSteuer
                  erfolgt &uuml;ber die App oder per E-Mail, SMS
                  Messenger-Dienste oder Post. Dem Kunden ist bewusst, dass
                  Korrespondenz per unverschl&uuml;sselter E-Mail erhebliche
                  Sicherheitsrisiken in sich birgt. Insbesondere k&ouml;nnen
                  E-Mails unbemerkt verloren gehen oder von Dritten gelesen,
                  abgefangen, verf&auml;lscht oder gef&auml;lscht werden. Daraus
                  k&ouml;nnen erhebliche Sch&auml;den entstehen. Der Kunde
                  erm&auml;chtigt ExpressSteuer gleichwohl, die Korrespondenz
                  auch per unverschl&uuml;sselter E-Mail zu f&uuml;hren, solange
                  und soweit er nicht ausdr&uuml;cklich etwas anderes anordnet.
                  &nbsp; &nbsp;</span
                >
              </p>
              <h4 class="c0" id="h.3j2qqm3"><span class="c3"></span></h4>
              <h4 class="c0" id="h.3as4poj"><span class="c3"></span></h4>
              <h4 class="c1">
                <span class="c11"
                  >8.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Allgemeine
                  Pflichten des Kunden</span
                >
              </h4>
              <p class="c5 c6"><span class="c3"></span></p>
              <p class="c2">
                <span class="c3"
                  >8.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Die von
                  ExpressSteuer abgefragten Informationen sind vollst&auml;ndig
                  und zutreffend anzugeben. Der Kunde ist sich bewusst, dass es
                  sich hierbei um eine wesentliche Vertragspflicht
                  handelt.</span
                >
              </p>
              <p class="c2 c6"><span class="c3"></span></p>
              <p class="c2">
                <span class="c3"
                  >8.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Der Kunde
                  ist verpflichtet, ExpressSteuer unverz&uuml;glich &uuml;ber
                  eine &Auml;nderung seiner Kontaktdaten zu informieren. Eine
                  Anpassung jeglicher Kontakt- oder
                  steuererkl&auml;rungsrelevanter Informationen kann der Kunde
                  auch per E-Mail vornehmen.</span
                >
              </p>
              <p class="c2 c6"><span class="c3"></span></p>
              <p class="c2">
                <span class="c3"
                  >8.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Der Kunde
                  ist verpflichtet, seine Authentifizierung zu erm&ouml;glichen
                  und ExpressSteuer bei Ma&szlig;nahmen, die zu diesem Zweck
                  ergriffen wurden, zu unterst&uuml;tzen.</span
                >
              </p>
              <p class="c2 c6"><span class="c3"></span></p>
              <p class="c2" id="h.gjdgxs">
                <span class="c3"
                  >8.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Der Kunde
                  ist zur Mitwirkung bei der Erstellung seiner
                  Steuererkl&auml;rung verpflichtet. Insbesondere hat er dem
                  Steuerberater diejenigen Informationen und Dokumente
                  (beispielsweise die Lohnsteuerbescheinigung oder eine
                  Lohnabrechnung, Nachweise &uuml;ber Betreuungskosten,
                  Schulkosten, Ausw&auml;rtst&auml;tigkeiten, Arbeitsmittel,
                  etc.) zur Verf&uuml;gung zu stellen, die dieser f&uuml;r die
                  Erstellung, Finalisierung und Einreichung der
                  Steuererkl&auml;rung ben&ouml;tigt. Sofern der Kunde trotz
                  Aufforderung seitens ExpressSteuer innerhalb einer
                  angemessenen Frist seiner Mitwirkungspflicht nicht nachkommt,
                  ist ExpressSteuer berechtigt, diese Vereinbarung zu
                  k&uuml;ndigen. In diesem Fall kommt der Kunde f&uuml;r die
                  entstandenen Steuerberaterkosten auf.
                </span>
              </p>
              <p class="c2 c6"><span class="c3"></span></p>
              <p class="c2">
                <span class="c3"
                  >8.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sind die
                  vom Kunden vorvertraglich &uuml;bermittelten Informationen
                  unzutreffend, hat ExpressSteuer das Recht, diesen Vertrag zu
                  k&uuml;ndigen, sofern die sich Falschangaben auf die H&ouml;he
                  einer m&ouml;glichen Steuerr&uuml;ckerstattung um mehr als 25%
                  auswirken. Die bis dahin etwaig angefallenen
                  Steuerberatungskosten tr&auml;gt ExpressSteuer.</span
                >
              </p>
              <p class="c2 c6"><span class="c3"></span></p>
              <p class="c5 c6"><span class="c3"></span></p>
              <h4 class="c1">
                <span class="c11"
                  >9. R&uuml;cktrittsrecht von ExpressSteuer</span
                >
              </h4>
              <p class="c2 c6"><span class="c3"></span></p>
              <p class="c2">
                <span class="c3"
                  >ExpressSteuer steht ein R&uuml;cktrittrecht zu, sofern der
                  Kunde gegen seine Pflicht aus &sect; 8.1
                  verst&ouml;&szlig;t.</span
                >
              </p>
              <p class="c2 c6"><span class="c3"></span></p>
              <p class="c5 c6"><span class="c3"></span></p>
              <h4 class="c1" id="h.147n2zr">
                <span class="c11">10. Haftungsbeschr&auml;nkung</span>
              </h4>
              <p class="c2 c6" id="h.3o7alnk"><span class="c3"></span></p>
              <p class="c2">
                <span class="c3"
                  >10.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ExpressSteuer
                  haftet unbeschr&auml;nkt f&uuml;r Sch&auml;den aus der
                  Verletzung des Lebens, des K&ouml;rpers oder der Gesundheit,
                  die auf einer Pflichtverletzung von ExpressSteuer, eines
                  gesetzlichen Vertreters oder Erf&uuml;llungsgehilfen von
                  ExpressSteuer beruhen sowie f&uuml;r Sch&auml;den, die durch
                  Fehlen einer von ExpressSteuer garantierten Beschaffenheit
                  hervorgerufen wurden oder bei arglistigem Verhalten von
                  ExpressSteuer.</span
                >
              </p>
              <p class="c2 c6"><span class="c3"></span></p>
              <p class="c2" id="h.ihv636">
                <span class="c3"
                  >10.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ExpressSteuer
                  haftet unbeschr&auml;nkt f&uuml;r Sch&auml;den, die durch
                  ExpressSteuer oder durch ihre gesetzlichen Vertreter oder
                  Erf&uuml;llungsgehilfen vors&auml;tzlich oder durch grobe
                  Fahrl&auml;ssigkeit verursacht wurden.</span
                >
              </p>
              <p class="c2 c6" id="h.32hioqz"><span class="c3"></span></p>
              <p class="c2">
                <span class="c3"
                  >10.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Bei der
                  leicht fahrl&auml;ssig verursachten Verletzung wesentlicher
                  Vertragspflichten haftet Expresssteuer au&szlig;er in den
                  F&auml;llen der Ziffern 10.1, 10.2, oder 10.4 der H&ouml;he
                  nach begrenzt auf den vertragstypisch vorhersehbaren Schaden.
                  Wesentliche Vertragspflichten sind abstrakt solche Pflichten,
                  deren Erf&uuml;llung die ordnungsgem&auml;&szlig;e
                  Durchf&uuml;hrung eines Vertrages &uuml;berhaupt erst
                  erm&ouml;glicht und auf deren Einhaltung die Vertragsparteien
                  regelm&auml;&szlig;ig vertrauen d&uuml;rfen.</span
                >
              </p>
              <p class="c2 c6"><span class="c3"></span></p>
              <p class="c2" id="h.1hmsyys">
                <span class="c3"
                  >10.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Die
                  Haftung nach dem Produkthaftungsgesetz bleibt
                  unber&uuml;hrt.</span
                >
              </p>
              <p class="c2 c6" id="h.41mghml"><span class="c3"></span></p>
              <p class="c2">
                <span class="c3"
                  >10.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Im
                  &Uuml;brigen ist eine Haftung von Expresssteuer
                  ausgeschlossen.</span
                >
              </p>
              <p class="c2 c6"><span class="c3"></span></p>
              <p class="c2">
                <span class="c3"
                  >10.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Die
                  Verj&auml;hrungsfrist f&uuml;r die Geltendmachung von
                  Schadensersatzanspr&uuml;chen gegen Expresssteuer betr&auml;gt
                  ein (1) Jahr au&szlig;er in den F&auml;llen der Ziffern 10.1,
                  10.2, oder 10.4.</span
                >
              </p>
              <h4 class="c0" id="h.2grqrue"><span class="c3"></span></h4>
              <p class="c5 c6"><span class="c3"></span></p>
              <h4 class="c1"><span class="c11">11. Widerrufsrecht</span></h4>
              <p class="c2 c6"><span class="c3"></span></p>
              <p class="c2">
                <span class="c3"
                  >11.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Dem
                  Kunden steht ein Widerrufsrecht zu.</span
                >
              </p>
              <p class="c2 c6"><span class="c3"></span></p>
              <p class="c20">
                <span class="c3"
                  >11.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Widerrufsbelehrung</span
                >
              </p>
              <p class="c6 c20"><span class="c3"></span></p>
              <p class="c20 c6"><span class="c3"></span></p>
              <a id="t.f560a885cb4bb0c40f34538bb3069c600fd41ec7"></a
              ><a id="t.0"></a>
              <table class="c23">
                <tbody>
                  <tr class="c30">
                    <td class="c18 c26" colspan="1" rowspan="1">
                      <h4 class="c13" id="h.4f1mdlm">
                        <span class="c4">Widerrufsbelehrung</span>
                      </h4>
                      <p class="c5 c6"><span class="c4"></span></p>
                      <h5 class="c13" id="h.3fwokq0">
                        <span class="c4"
                          >Sie haben das Recht, binnen 14 Tagen ohne Angabe von
                          Gr&uuml;nden diesen Vertrag zu widerrufen. Die
                          Widerrufsfrist betr&auml;gt vierzehn Tage ab dem Tag
                          des Vertragsabschlusses.</span
                        >
                      </h5>
                      <h5 class="c13 c6"><span class="c4"></span></h5>
                      <h5 class="c13">
                        <span class="c12"
                          >Um Ihr Widerrufsrecht auszu&uuml;ben, m&uuml;ssen Sie
                          uns, ExpressSteuer GmbH, Am Sandtorkai 73, 20457
                          Hamburg </span
                        ><span class="c12 c14"
                          >Adresse, info@expresssteuer.com</span
                        ><span class="c4"
                          >&nbsp;mittels einer eindeutigen Erkl&auml;rung (z. B.
                          ein mit der Post versandter Brief oder E-Mail)
                          &uuml;ber Ihren Entschluss, diesen Vertrag zu
                          widerrufen, informieren. Sie k&ouml;nnen daf&uuml;r
                          das beigef&uuml;gte Muster-Widerrufsformular
                          verwenden, das jedoch nicht vorgeschrieben ist.</span
                        >
                      </h5>
                      <h5 class="c13 c6"><span class="c4"></span></h5>
                      <h5 class="c13">
                        <span class="c4"
                          >Zur Wahrung der Widerrufsfrist reicht es aus, dass
                          Sie die Mitteilung &uuml;ber die Aus&uuml;bung des
                          Widerrufsrechts vor Ablauf der Widerrufsfrist
                          absenden.</span
                        >
                      </h5>
                      <h4 class="c13 c24" id="h.1v1yuxt">
                        <span class="c4"></span>
                      </h4>
                      <h4 class="c13 c24"><span class="c4"></span></h4>
                      <h5 class="c13">
                        <span class="c4"
                          >Besonderer Hinweis zum vorzeitigen Erl&ouml;schen des
                          Widerrufsrechts</span
                        >
                      </h5>
                      <h5 class="c13 c6"><span class="c4"></span></h5>
                      <h5 class="c13">
                        <span class="c4"
                          >Ihr Widerrufsrecht erlischt dann, wenn ExpressSteuer
                          seine Leistung vollst&auml;ndig erbracht hat und mit
                          der Ausf&uuml;hrung der Leistung erst begonnen hat,
                          nachdem Sie dazu Ihre ausdr&uuml;ckliche Zustimmung
                          gegeben haben und gleichzeitig Ihre Kenntnis davon
                          best&auml;tigt haben, dass Sie Ihr Widerrufsrecht bei
                          vollst&auml;ndiger Vertragserf&uuml;llung durch
                          ExpressSteuer verlieren.</span
                        >
                      </h5>
                      <p class="c5 c6"><span class="c3 c18"></span></p>
                      <p class="c5 c6"><span class="c3 c18"></span></p>
                      <h4 class="c13">
                        <span class="c4">Folgen des Widerrufs</span>
                      </h4>
                      <h5 class="c13 c6"><span class="c4"></span></h5>
                      <h5 class="c13">
                        <span class="c4"
                          >Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen
                          alle Zahlungen, die wir von Ihnen erhalten haben,
                          einschlie&szlig;lich der Lieferkosten (mit Ausnahme
                          der zus&auml;tzlichen Kosten, die sich daraus ergeben,
                          dass Sie eine andere Art der Lieferung als die von uns
                          angebotene, g&uuml;nstigste Standardlieferung
                          gew&auml;hlt haben), unverz&uuml;glich und
                          sp&auml;testens binnen 14 Tagen ab dem Tag
                          zur&uuml;ckzuzahlen, an dem die Mitteilung &uuml;ber
                          Ihren Widerruf bei uns eingegangen ist. F&uuml;r diese
                          R&uuml;ckzahlung verwenden wir dasselbe
                          Zahlungsmittel, das Sie bei der urspr&uuml;nglichen
                          Transaktion eingesetzt haben, es sei denn, mit Ihnen
                          wurde ausdr&uuml;cklich etwas anderes vereinbart; in
                          keinem Fall werden Ihnen wegen dieser R&uuml;ckzahlung
                          Entgelte berechnet.</span
                        >
                      </h5>
                      <p class="c5 c6"><span class="c3 c18"></span></p>
                      <h5 class="c13">
                        <span class="c4"
                          >Haben Sie verlangt, dass die Dienstleistungen
                          w&auml;hrend der Widerrufsfrist beginnen sollen, so
                          haben Sie uns einen angemessenen Betrag zu zahlen, der
                          dem Anteil der bis zu dem Zeitpunkt, zu dem Sie uns
                          von der Aus&uuml;bung des Widerrufsrechts hinsichtlich
                          dieses Vertrags unterrichten, bereits erbrachten
                          Dienstleistungen im Vergleich zum Gesamtumfang der im
                          Vertrag vorgesehenen Dienstleistung entspricht.</span
                        >
                      </h5>
                      <h5 class="c13 c6"><span class="c4"></span></h5>
                      <p class="c5 c6"><span class="c3 c18"></span></p>
                      <p class="c20">
                        <span class="c4">Muster-Widerrufsformular</span>
                      </p>
                      <p class="c20 c6"><span class="c4"></span></p>
                      <h5 class="c13" id="h.vx1227">
                        <span class="c4"
                          >Wenn Sie diesen Vertrag widerrufen wollen, dann
                          k&ouml;nnen Sie dieses Formular ausf&uuml;llen und uns
                          zur&uuml;cksenden:</span
                        >
                      </h5>
                      <ul class="c7 lst-kix_8bheh6cf63y3-0 start">
                        <li class="c13 c19 li-bullet-1">
                          <h5 style="display: inline">
                            <span class="c4">An:</span>
                          </h5>
                        </li>
                      </ul>
                      <p class="c5 c25">
                        <span class="c3 c18"
                          >ExpressSteuer GmbH, Am Sandtorkai 73, 20457 Hamburg,
                          info@expresssteuer.com</span
                        >
                      </p>
                      <p class="c5 c25">
                        <span class="c4"
                          >Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*)
                          abgeschlossenen Vertrag &uuml;ber die Erbringung der
                          folgenden Dienstleistung _______________</span
                        >
                      </p>
                      <ul class="c7 lst-kix_8bheh6cf63y3-0">
                        <li class="c13 c19 li-bullet-1">
                          <h5 style="display: inline">
                            <span class="c4"
                              >Bestellt am (*)/ erhalten am (*)</span
                            >
                          </h5>
                        </li>
                        <li class="c13 c19 li-bullet-1">
                          <h5 style="display: inline">
                            <span class="c4">Name des/der Verbraucher(s)</span>
                          </h5>
                        </li>
                        <li class="c13 c19 li-bullet-1">
                          <h5 style="display: inline">
                            <span class="c4"
                              >Anschrift des/der Verbraucher(s)</span
                            >
                          </h5>
                        </li>
                        <li class="c13 c19 li-bullet-1">
                          <h5 style="display: inline">
                            <span class="c4"
                              >Unterschrift des/der Verbraucher(s) (nur bei
                              Mitteilung auf Papier)</span
                            >
                          </h5>
                        </li>
                        <li class="c13 c19 li-bullet-1">
                          <h5 style="display: inline">
                            <span class="c4">Datum</span>
                          </h5>
                        </li>
                      </ul>
                      <h5 class="c13" id="h.ec41mmo458fc">
                        <span class="c4">________________________</span>
                      </h5>
                      <h5 class="c13" id="h.udfsivpr9ukh">
                        <span class="c12"
                          >(*) Unzutreffendes bitte streichen</span
                        >
                      </h5>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p class="c5 c6"><span class="c3"></span></p>
              <p class="c5 c6"><span class="c3"></span></p>
              <h4 class="c1">
                <span class="c11">12. &nbsp;Geheimhaltung</span>
              </h4>
              <p class="c2 c6"><span class="c3"></span></p>
              <p class="c2">
                <span class="c3"
                  >Grunds&auml;tzlich sind der Abschluss und der Inhalt dieses
                  Vertrages auf Dauer geheim zu halten. Soweit eine Offenlegung
                  f&uuml;r n&uuml;tzlich gehalten wird, werden sich die Parteien
                  abstimmen und im Rahmen des billigen Ermessens eine
                  einvernehmliche Entscheidung treffen. Kommt diese nicht
                  zustande, bleibt es bei der Geheimhaltungspflicht.</span
                >
              </p>
              <p class="c2 c6"><span class="c3"></span></p>
              <h4 class="c0"><span class="c11"></span></h4>
              <h4 class="c1"><span class="c11">13. OS-Plattform</span></h4>
              <h4 class="c0"><span class="c3"></span></h4>
              <p class="c2">
                <span
                  >Die Europ&auml;ische Kommission stellt eine Plattform
                  f&uuml;r die au&szlig;ergerichtliche Streitbeilegung
                  (OS-Plattform) bereit. ExpressSteuer ist weder verpflichtet,
                  noch bereit, an dem Streitschlichtungsverfahren teilzunehmen.
                  Die OS-Plattform ist unter </span
                ><span class="c27"
                  ><a
                    class="c29"
                    href="https://www.google.com/url?q=https://ec.europa.eu/consumers/odr/main/index.cfm?event%3Dmain.home2.show%26lng%3DDE&amp;sa=D&amp;source=editors&amp;ust=1650902098912360&amp;usg=AOvVaw1MpV55m7qVKbZYR8-ulsVG"
                    >https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&amp;lng=DE</a
                  ></span
                ><span class="c3">&nbsp;abrufbar.</span>
              </p>
              <p class="c2 c6"><span class="c3"></span></p>
              <p class="c2 c6"><span class="c3"></span></p>
              <p class="c2"><span class="c11">14. Schlussbestimmungen</span></p>
              <p class="c2 c6"><span class="c3"></span></p>
              <p class="c2">
                <span class="c3"
                  >Sollte eine der vorstehenden Bestimmungen unwirksam sein oder
                  werden oder sollte der Vertrag eine unvorhergesehene
                  Regelungsl&uuml;cke aufweisen, bleibt die Rechtswirksamkeit
                  der &uuml;brigen Bestimmungen oder Teile solcher Bestimmungen
                  hiervon unber&uuml;hrt. An die Stelle der unwirksamen oder
                  fehlenden Bestimmungen treten die jeweiligen gesetzlichen
                  Regelungen.
                </span>
              </p>
              <p class="c2 c6"><span class="c3"></span></p>
              <p class="c2">
                <span class="c3"
                  >Es gilt das Recht der Bundesrepublik Deutschland unter
                  Ausschluss des UN-Kaufrechts und des Kollisionsrechts.</span
                >
              </p>
              <p class="c2 c6"><span class="c3"></span></p>
              <p class="c2 c6"><span class="c3"></span></p>
              <p class="c2 c6"><span class="c3"></span></p>
              <p class="c6 c10"><span class="c3"></span></p>
              <p class="c10 c6"><span class="c3"></span></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="statiticInfo" class="modal fade in" role="dialog">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="close" data-dismiss="modal">×</button>

        {{ 'headline.calculation.asterixHint' | translate }}
      </div>
    </div>
  </div>
</div>

<div id="cashOutInfo" class="modal modal-left fade in" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="close" data-dismiss="modal">×</button>

        * Rechnerischer Auszahlbetrag nach Abzug unserer Servicegebühren,
        einschließlich Mehrwertsteuer; vorbehaltlich der Richtigkeit der
        gemachten Angaben.
      </div>
    </div>
  </div>
</div>

<div id="thirdPartyCookieModal" class="modal fade in" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="close" data-dismiss="modal">×</button>

        <div class="row">
          <div class="col-sm-12">
            <p>
              Die Ersterstellung deiner Steuererklärung über ExpressSteuer setzt
              die Speicherung von Cookies voraus. Dein Browser lässt keine
              Cookies von Drittanbietern zu. Bitte deaktiviere in deinen
              Browsereinstellungen
              <br />
              das Blockieren der Cookies von Drittanbietern, um deine
              Steuererklärung über ExpressSteuer erstellen zu können oder klicke
              einfach auf den Button, um die Steuererklärung in einem neuen
              Fenster zu erstellen
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12" style="margin-bottom: 50px">
            <br />
            <br />
            <a
              href="http://app-{{ getPartnerName() }}.expresssteuer.com/"
              target="_blank"
              class="next"
              type="submit"
              >Hier weiter zu deiner Steuererklärung</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-debug *ngIf="environment.debug.view"></app-debug>
