import { Timestamp } from '../helpers/timestamp';

export abstract class ClientContractSignRequest {
  public static getTemplate(
    input: Partial<ClientContractSignRequest> & {
      signature: string;
      clientId: string;
      createdAt: Timestamp;
      contractName: string;
      contractVersion: string;
    }
  ): ClientContractSignRequest {
    return {
      signature: input.signature,
      clientId: input.clientId,
      createdAt: input.createdAt,
      contractName: input.contractName,
      contractVersion: input.contractVersion,
    };
  }
  abstract createdAt: Timestamp;
  abstract signature: string;
  abstract clientId: string;
  abstract contractName: string;
  abstract contractVersion: string;
  abstract pathToContract?: string;
  static collectionName = 'client_sign_request';
}
