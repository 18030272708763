// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import handlebars from 'handlebars/dist/cjs/handlebars';

export class TemplateEngine<T> {
  switchHelperSwitch(value: any, options: any) {
    handlebars.__switch_stack__.push({
      switch_match: false,
      switch_value: value,
    });
    const html = options.fn(this);
    handlebars.__switch_stack__.pop();
    return html;
  }
  switchHelperCase(...args: any[]) {
    const options = args.pop();
    const caseValues = args;
    const stack =
      handlebars.__switch_stack__[handlebars.__switch_stack__.length - 1];

    if (stack.switch_match || caseValues.indexOf(stack.switch_value) === -1) {
      return '';
    } else {
      stack.switch_match = true;
      return options.fn(this);
    }
  }
  switch_value: any;
  constructor() {
    handlebars.registerHelper('money', (value: number) => {
      return value.toLocaleString('de-DE', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2,
      });
    });

    handlebars.registerHelper('json', (value: any) => {
      return JSON.stringify(value, undefined, 2);
    });

    handlebars.__switch_stack__ = [];
    handlebars.registerHelper('switch', this.switchHelperSwitch);
    handlebars.registerHelper('case', this.switchHelperCase);
    handlebars.registerHelper('default', (options: any) => {
      const stack =
        handlebars.__switch_stack__[handlebars.__switch_stack__.length - 1];
      if (!stack.switch_match) {
        return options.fn(this);
      }
    });

    /**
     * helpers for ifs
     * 
     * @example 
     * {{#if (or 
        (eq section1 "foo")
        (ne section2 "bar"))}}
        content
      {{/if}}
     */
    handlebars.registerHelper({
      eq: (v1: any, v2: any) => v1 === v2,
      ne: (v1: any, v2: any) => v1 !== v2,
      lt: (v1: number, v2: number) => v1 < v2,
      gt: (v1: number, v2: number) => v1 > v2,
      lte: (v1: number, v2: number) => v1 <= v2,
      gte: (v1: number, v2: number) => v1 >= v2,
      and(...values: boolean[]) {
        return Array.prototype.every.call(values, Boolean);
      },
      or(...values: boolean[]) {
        return Array.prototype.slice.call(values, 0, -1).some(Boolean);
      },
    });

    handlebars.registerHelper('fallback', (a: any, b: string) => {
      return a ? a : b;
    });
  }

  public replace(template: string, replace: T): string {
    const hbTemplate = handlebars.compile<T>(template);
    return hbTemplate(replace, {});
  }
}
