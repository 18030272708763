import { iPrice } from './iPrice';
import { PRICETYPE } from './priceType';

export class PriceFixed implements iPrice {
  public static getTemplate(): PriceFixed {
    return {
      type: PRICETYPE.fixed,
      price: 0.0,
    };
  }

  type: PRICETYPE = PRICETYPE.fixed;

  /**
   * The fixed price the customer has to pay
   */
  price: number = 0.0;
}
