export class TwilioMessage {
  MediaContentType0?: string;
  MediaUrl0?: string;
  SmsMessageSid: string = '';
  NumMedia: string = '';
  ProfileName: string = '';
  SmsSid: string = '';
  WaId: string = '';
  SmsStatus: string = '';
  Body: string = '';
  To: string = '';
  NumSegments: string = '';
  MessageSid: string = '';
  AccountSid: string = '';
  From: string = '';
  ApiVersion: string = '';
  from: string = '';
  body: string = '';
  to: string = '';
  dateCreated: Date = new Date();
}
