export interface UrlEvent {
  event: string;
  variant: boolean;
  product: string;
  dynamicURL: string;
  offerValue?: number;
  caseId?: string;
  isTest?: boolean;
  source?: 'andorra' | 'app';
}
