import {
  isObjectFromFields,
  ObjectFromFields,
  SerializedFieldType,
  TypeFromField,
} from '../serialized-field/serialized-field';
import { BinaryDocument, DOCUMENTTYPE } from './document';

export const CommonField = {
  firstname: {
    labels: [{ de: 'Vorname' }],
    type: SerializedFieldType.string as SerializedFieldType.string,
    optional: true as const,
  },
  lastname: {
    labels: [{ de: 'Nachname' }],
    type: SerializedFieldType.string as SerializedFieldType.string,
    optional: true as const,
  },
  workCompanyName: {
    labels: [{ de: 'Arbeitgeber Name' }],
    type: SerializedFieldType.string as SerializedFieldType.string,
    optional: true as const,
  },
  workCompanyAddress: {
    labels: [{ de: 'Arbeitgeber Adresse' }],
    type: SerializedFieldType.string as SerializedFieldType.string,
    optional: true as const,
  },
  birthdate: {
    labels: [{ de: 'Geburtsdatum' }],
    type: SerializedFieldType.timestamp as SerializedFieldType.timestamp,
    optional: true as const,
  },
  creationDate: {
    labels: [{ de: 'Erstellungsdatum' }],
    type: SerializedFieldType.timestamp as SerializedFieldType.timestamp,
    optional: true as const,
  },
  address: {
    labels: [{ de: 'Adresse' }],
    type: SerializedFieldType.string as SerializedFieldType.string,
    optional: true as const,
  },
  validUntil: {
    labels: [{ de: 'Gültigkeitsdatum' }],
    type: SerializedFieldType.timestamp as SerializedFieldType.timestamp,
    optional: true as const,
  },
  validFrom: {
    labels: [{ de: 'Gültig seit' }],
    type: SerializedFieldType.timestamp as SerializedFieldType.timestamp,
    optional: true as const,
  },
  taxId: {
    labels: [{ de: 'Steuer Id' }],
    type: SerializedFieldType.number as SerializedFieldType.number,
    optional: true as const,
  },
  totalAmount: {
    labels: [{ de: 'Gesamtsumme' }],
    type: SerializedFieldType.number as SerializedFieldType.number,
    optional: true as const,
  },
  taxRate: {
    labels: [{ de: 'Steuersatz' }],
    type: SerializedFieldType.factor as SerializedFieldType.factor,
    optional: true as const,
  },
  deadline: {
    labels: [{ de: 'Frist' }],
    type: SerializedFieldType.timestamp as SerializedFieldType.timestamp,
    optional: true as const,
  },
  iban: {
    labels: [{ de: 'IBAN' }],
    type: SerializedFieldType.iban as SerializedFieldType.iban,
    optional: true as const,
  },
  taxNumber: {
    labels: [{ de: 'Steuernummer' }],
    type: SerializedFieldType.string as SerializedFieldType.string,
    optional: true as const,
  },
  // Taxnumber with finance-office id makes it unique throughout germany (12 digits)
  uniqueTaxNumber: {
    labels: [{ de: 'eindeutige Steuernummer' }],
    type: SerializedFieldType.string as SerializedFieldType.string,
    optional: true as const,
  },
  taxYear: {
    labels: [{ de: 'Steuerjahr' }],
    type: SerializedFieldType.number as SerializedFieldType.number,
    optional: true as const,
  },
  passportNbr: {
    labels: [{ de: 'Ausweis Nr.' }],
    type: SerializedFieldType.string as SerializedFieldType.string,
    optional: true as const,
  },
  nationality: {
    labels: [{ de: 'Nationalität' }],
    type: SerializedFieldType.string as SerializedFieldType.string,
    optional: true as const,
  },
  placeofBirth: {
    labels: [{ de: 'Geburtsort' }],
    type: SerializedFieldType.string as SerializedFieldType.string,
    optional: true as const,
  },
  dateOfIssue: {
    labels: [{ de: 'Ausstellungsdatum' }],
    type: SerializedFieldType.timestamp as SerializedFieldType.timestamp,
    optional: true as const,
  },
  sex: {
    labels: [{ de: 'Geschlecht' }],
    type: SerializedFieldType.string as SerializedFieldType.string,
    optional: true as const,
  },
  healthInsuranceCompany: {
    labels: [{ de: 'Versicherung' }],
    type: SerializedFieldType.string as SerializedFieldType.string,
    optional: true as const,
  },
  grossIncome: {
    labels: [{ de: 'Brutto Einkommen' }],
    type: SerializedFieldType.string as SerializedFieldType.string,
    optional: true as const,
  },
  netIncome: {
    labels: [{ de: 'Netto Einkommen' }],
    type: SerializedFieldType.string as SerializedFieldType.string,
    optional: true as const,
  },
};

/**
 * Fields for specific document types
 * @property [key in DOCUMENTTYPE]: SerializedFields;
 */
export const DocumentTypeFields = {
  [DOCUMENTTYPE.identitycard]: {
    firstname: CommonField.firstname,
    lastname: CommonField.lastname,
    birthdate: CommonField.birthdate,
    validUntil: CommonField.validUntil,
    nationality: CommonField.nationality,
    placeofBirth: CommonField.placeofBirth,
  },
  [DOCUMENTTYPE.passport]: {
    firstname: CommonField.firstname,
    lastname: CommonField.lastname,
    birthdate: CommonField.birthdate,
    validUntil: CommonField.validUntil,
    passportNbr: CommonField.passportNbr,
    nationality: CommonField.nationality,
    placeofBirth: CommonField.placeofBirth,
    dateOfIssue: CommonField.dateOfIssue,
    sex: CommonField.sex,
  },
  [DOCUMENTTYPE.identitycardBackside]: {
    firstname: CommonField.firstname,
    lastname: CommonField.lastname,
  },
  [DOCUMENTTYPE.wagestatement]: {
    firstname: CommonField.firstname,
    lastname: CommonField.lastname,
    taxId: CommonField.taxId,
    taxYear: CommonField.taxYear,
    birthdate: CommonField.birthdate,
    healthInsuranceCompany: CommonField.healthInsuranceCompany,
    address: CommonField.address,
    grossIncome: CommonField.grossIncome,
    netIncome: CommonField.netIncome,
  },

  [DOCUMENTTYPE.incometaxstatement]: {
    firstname: CommonField.firstname,
    lastname: CommonField.lastname,
    birthdate: CommonField.birthdate,
    taxId: CommonField.taxId,
    address: CommonField.address,
    workCompanyName: CommonField.workCompanyName,
    workCompanyAddress: CommonField.workCompanyAddress,
    taxNumber: CommonField.taxNumber,
  },
  [DOCUMENTTYPE.plainTaxId]: {
    firstname: CommonField.firstname,
    lastname: CommonField.lastname,
    taxId: CommonField.taxId,
  },
  [DOCUMENTTYPE.receipt]: {
    creationDate: CommonField.creationDate,
    totalAmount: CommonField.totalAmount,
  },
  [DOCUMENTTYPE.expense]: {
    creationDate: CommonField.creationDate,
    totalAmount: CommonField.totalAmount,
    taxRate: CommonField.taxRate,
  },
  [DOCUMENTTYPE.invoice]: {
    creationDate: CommonField.creationDate,
    totalAmount: CommonField.totalAmount,
    taxRate: CommonField.taxRate,
  },
  [DOCUMENTTYPE.handicappedId]: {
    firstname: CommonField.firstname,
    lastname: CommonField.lastname,
    validUntil: CommonField.validUntil,
  },
  [DOCUMENTTYPE.handicappedIdBackside]: {
    firstname: CommonField.firstname,
    lastname: CommonField.lastname,
    birthdate: CommonField.birthdate,
    validFrom: CommonField.validFrom,
  },
  [DOCUMENTTYPE.residentPermit]: {
    firstname: CommonField.firstname,
    lastname: CommonField.lastname,
    birthdate: CommonField.birthdate,
    validUntil: CommonField.validUntil,
  },
  [DOCUMENTTYPE.residentPermitBackside]: {
    firstname: CommonField.firstname,
    lastname: CommonField.lastname,
  },
  [DOCUMENTTYPE.driver_licence]: {
    firstname: CommonField.firstname,
    lastname: CommonField.lastname,
    birthdate: CommonField.birthdate,
    validUntil: CommonField.validUntil,
  },
  [DOCUMENTTYPE.driver_licence_backside]: {
    firstname: CommonField.firstname,
    lastname: CommonField.lastname,
  },

  [DOCUMENTTYPE.other]: {},
  [DOCUMENTTYPE.audio]: {},
  [DOCUMENTTYPE.video]: {},
  // TODO
  [DOCUMENTTYPE.taxstatement]: {
    taxId: CommonField.taxId,
    taxNumber: CommonField.taxNumber,
    taxYear: CommonField.taxYear,
    creationDate: CommonField.creationDate,
    uniqueTaxNumber: CommonField.uniqueTaxNumber,
  },
  [DOCUMENTTYPE.changeTaxStatement]: {
    taxId: CommonField.taxId,
    taxNumber: CommonField.taxNumber,
    taxYear: CommonField.taxYear,
    creationDate: CommonField.creationDate,
    uniqueTaxNumber: CommonField.uniqueTaxNumber,
  },
  [DOCUMENTTYPE.predictedTaxStatement]: {
    taxId: CommonField.taxId,
    taxNumber: CommonField.taxNumber,
    taxYear: CommonField.taxYear,
    creationDate: CommonField.creationDate,
    uniqueTaxNumber: CommonField.uniqueTaxNumber,
  },
  [DOCUMENTTYPE.prepaidTaxStatement]: {
    taxId: CommonField.taxId,
    taxNumber: CommonField.taxNumber,
    taxYear: CommonField.taxYear,
    creationDate: CommonField.creationDate,
    uniqueTaxNumber: CommonField.uniqueTaxNumber,
  },
  // generated
  [DOCUMENTTYPE.signature]: {},
  // Handled by vollmachtsextrakt
  [DOCUMENTTYPE.pensionWithdrawalNotice]: {},
  [DOCUMENTTYPE.healthInsurance]: {},
  [DOCUMENTTYPE.assetCertification]: {},
  [DOCUMENTTYPE.certificateWageReplacemenBenefits]: {},
  [DOCUMENTTYPE.riester]: {},
  [DOCUMENTTYPE.ruerup]: {},
  [DOCUMENTTYPE.masterdata]: {},
  [DOCUMENTTYPE.religiousConfession]: {},
  [DOCUMENTTYPE.capitalGainsWithTaxExemption]: {},
  [DOCUMENTTYPE.subsidiesFromAuthorities]: {},
  //Handled by elster service
  [DOCUMENTTYPE.taxoffice]: {},
  [DOCUMENTTYPE.testtaxoffice]: {},
  // handled by something
  [DOCUMENTTYPE.representationAuthorization]: {},
  [DOCUMENTTYPE.taxoffice_request]: {
    deadline: CommonField.deadline,
    creationDate: CommonField.creationDate,
    taxId: CommonField.taxId,
    taxNumber: CommonField.taxNumber,
    taxYear: CommonField.taxYear,
  },
  [DOCUMENTTYPE.taxoffice_information]: {
    creationDate: CommonField.creationDate,
    taxId: CommonField.taxId,
    taxNumber: CommonField.taxNumber,
    taxYear: CommonField.taxYear,
  },
  [DOCUMENTTYPE.taxoffice_reminder]: {
    creationDate: CommonField.creationDate,
    deadline: CommonField.deadline,
    taxId: CommonField.taxId,
    taxNumber: CommonField.taxNumber,
    taxYear: CommonField.taxYear,
  },
  taxoffice_letter: {},
  bankaccount: { iban: CommonField.iban },
};

/**
 * A document with a specific type and matching fields
 */
export type SpecificDocument<TType extends DOCUMENTTYPE> = BinaryDocument<
  TType,
  ObjectFromFields<typeof DocumentTypeFields[TType]>
>;

export type ParsedFields<TType extends DOCUMENTTYPE> =
  SpecificDocument<TType>['parsedFields'];

type ParsedFieldKeysPerType = {
  [key in DOCUMENTTYPE]: keyof typeof DocumentTypeFields[key];
};

export type ParsedFieldKey =
  ParsedFieldKeysPerType[keyof ParsedFieldKeysPerType];

export type TypeOfParsedField<TParsedFieldKey extends ParsedFieldKey> =
  TypeFromField<typeof CommonField[TParsedFieldKey]>;

/**
 * @example
 * ```
let doc: BinaryDocument = ...
if (isSpecificDocument(doc, DOCUMENTTYPE.identitycard)) {
  doc.type === DOCUMENTTYPE.identitycard; // true
  doc.parsedFields.birthdate; // Property 'birthdate' DOES exist
}
if (isSpecificDocument(doc, DOCUMENTTYPE.other)) {
  doc.type === DOCUMENTTYPE.other; // true
  doc.parsedFields.birthdate; // Property 'birthdate' does NOT exist
}
 * ```
 */
export function isSpecificDocument<TType extends DOCUMENTTYPE>(
  document: BinaryDocument<
    unknown,
    Record<string | number, unknown> | undefined
  >,
  type: TType
): document is SpecificDocument<TType> {
  if (document.type !== type) {
    return false;
  }
  const fieldsForType = DocumentTypeFields[type];
  if (
    (!fieldsForType || Object.keys(fieldsForType).length === 0) &&
    !document.parsedFields
  ) {
    return true;
  }

  return (
    !!fieldsForType &&
    !!document.parsedFields &&
    typeof document.parsedFields === 'object' &&
    isObjectFromFields(document.parsedFields, fieldsForType)
  );
}

/**
 * some for isSpecificDocument
 */
export function isSomeSpecificDocument(
  document: BinaryDocument<string, undefined>,
  documentTypes: DOCUMENTTYPE[]
): boolean {
  return documentTypes.some((documentType) =>
    isSpecificDocument(document, documentType)
  );
}
