import { PriceFixed } from '../pricing/priceFix';
import { PriceVariable } from '../pricing/priceVariable';

export class EnvironmentCampaign {
  /**
   * The Name of the Campaign
   * @example A Company manufacturing Everything Campaign
   */
  displayName: string = '';
  /**
   * The short name used
   * @example ACME
   */
  name: string = '';

  /**
   * The price for this campaign.
   * if set, it will overwrite the price used by default in the domain
   */
  price?: PriceVariable | PriceFixed;
}
