import { IAddress } from '../../interfaces/address';
import { ConvertExpsAddress } from './convert-exps-address';

export class AddressOptional {
  public static getTemplate(): AddressOptional {
    return {
      nbr: null,
    };
  }
  public nbr: string | null = null;
}

export class ExpsAddress implements IAddress {
  public static getAddressLine(address: ExpsAddress): string {
    return `${address.street.longName} ${address.nbr.longName}, ${address.zipCode.longName} ${address.city.longName}, ${address.country.longName}`;
  }

  public static getTemplate(
    street: string = '',
    nbr: string = '',
    zipCode: string = '',
    city: string = '',
    state: string = '',
    stateShort: string = '',
    region: string = '',
    country: string = ''
  ): ExpsAddress {
    const addresString = `${street} ${nbr}, ${zipCode} ${city}, ${country}`;

    return {
      formatedAddress: addresString.length > 10 ? addresString : '',
      placeId: '',
      long: '',
      lat: '',
      unformatedAddress: addresString.length > 10 ? addresString : '',
      isChanged: true,

      street: { shortName: street, longName: street },
      nbr: { shortName: nbr, longName: nbr },
      city: { shortName: city, longName: city },
      region: { shortName: region, longName: region },
      state: { shortName: state, longName: stateShort },
      zipCode: { shortName: zipCode, longName: zipCode },
      country: { shortName: country, longName: country },
    };
  }

  public static getAddress(addr: ExpsAddress): ExpsAddress {
    if (addr.optional) {
      if (addr.optional.nbr && addr.optional.nbr?.length > 0) {
        addr.nbr.shortName = addr.optional.nbr;
        addr.nbr.shortName = addr.optional.nbr;
      }
    }

    return addr;
  }

  public static isEmpty(addr: ExpsAddress) {
    return [
      Boolean(addr?.city.longName),
      Boolean(addr?.country.longName),
      Boolean(addr?.nbr.longName),
      Boolean(addr?.street.longName),
      Boolean(addr?.zipCode.longName),
    ].includes(true);
  }

  public static getFromGooglePlaceResult =
    ConvertExpsAddress.getExpsAddressFromPlaces;

  public street = { shortName: '', longName: '' };
  public nbr = { shortName: '', longName: '' };
  public city = { shortName: '', longName: '' };
  public region = { shortName: '', longName: '' };
  public state = { shortName: '', longName: '' };
  public zipCode = { shortName: '', longName: '' };
  public country = { shortName: '', longName: '' };
  /**
   * Contains the full address string
   */
  public formatedAddress: string = '';
  public placeId: string = '';
  public long: string = '';
  public lat: string = '';
  /**
   * @deprecated We do not use this anymore. This was for the address in the ui to be set
   */
  public unformatedAddress: string = '';
  /**
   * @deprecated We do not use this anymore. Changes are detected in the backend automatically
   */
  public isChanged: boolean = true;
  public optional?: AddressOptional = AddressOptional.getTemplate();
}
