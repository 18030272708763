/**
 * The response from Twilio over the webhook
 */
export interface TwilioGridNotification {
  AccountSid: string;
  ApiVersion: string;
  ChannelInstallSid: string;
  ChannelPrefix: string;
  ChannelToAddress: string;
  ErrorCode: string;
  EventType: string;
  From: string;
  MessageSid: string;
  MessageStatus: string;
  SmsSid: string;
  SmsStatus: string;
  To: string;
}
