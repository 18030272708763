import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Router } from '@angular/router';
import firebase from 'firebase/app';
import { delay, filter, map, shareReplay, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Logger, LoggerService } from '../logger/logger.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  logger: Logger;

  /**
   * @deprecated
   * use and observe `user$` instead
   */
  public user: firebase.User;

  public user$ = this.afAuth.user.pipe(
    delay(environment.emulator ? 1000 : 1000), // TODO auth seems to be messed up here
    tap((async (user: firebase.User) => {
      this.logger.info(user);
      const url = window.location.href;

      this.logger.debug(`checking login options: ${url}`);

      const isLoginUrl = await this.afAuth.isSignInWithEmailLink(url);
      if (isLoginUrl) {
        this.logger.debug('isLoginUrl, signing InWithEmailLink');
        const email = decodeURIComponent(
          url.split('/').filter((e) => e.indexOf('@') > -1)[0]
        );
        const credentials = await this.afAuth
          .signInWithEmailLink(email, url)
          .catch(async (e) => {
            this.logger.error('signing InWithEmailLink failed: redirectig', e);
            // await this.router.navigateByUrl('/'); // TODO change to error page?
            return null;
          });
        // remove signInOption from URL for reload to be possible as the url expires after the first use
        const urlTree = this.router.createUrlTree([], {
          queryParams: { mode: undefined }, // TODO extend to all queryParams?
          queryParamsHandling: 'merge',
          preserveFragment: true,
        });
        await this.router.navigateByUrl(urlTree);

        this.logger.debug('sign InWithEmailLink end');
        return;
      }
      if (!user) {
        this.logger.debug('no user, signInAnonymously');
        void this.signInAnonymously();
      }

      if (user) {
        this.logger.log('user found');
        if (user.isAnonymous) {
          this.logger.log(`uid ${user.uid}: is anonymous`);
        } else {
          this.logger.log(`uid ${user.uid}: is NOT anonymous`);
        }
        this.user = user;
        return;
      }
    }) as unknown as any),
    shareReplay(1)
  );
  public userId$ = this.user$.pipe(
    filter((user) => user !== null),
    map((user) => user.uid),
    shareReplay(1)
  );

  constructor(
    public afAuth: AngularFireAuth,
    private afFunctions: AngularFireFunctions,
    private router: Router
  ) {
    this.logger = LoggerService.logger(AuthService.name);
  }

  public signIn(
    email: string,
    password: string
  ): Promise<firebase.auth.UserCredential> {
    return new Promise((resolve, reject) => {
      this.afAuth
        .setPersistence('local')
        .then(() => {
          this.afAuth
            .signInWithEmailAndPassword(email, password)
            .then((userCredential) => {
              this.user = userCredential.user;
              resolve(userCredential);
            })
            .catch((e) => {
              reject(e);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }

  public sendPasswordResetEmail(email: string, redirectUrl: string) {
    const actionCodeSettings = {
      url:
        'https://app.expresssteuer.com/' +
        redirectUrl +
        '/reset-password/continue',
      handleCodeInApp: true,
    };
    return this.afAuth.sendPasswordResetEmail(email, actionCodeSettings);
  }

  /**
   * Authenticates the user as anonymous and sets the user as the active user
   *
   * @example await this.authenticationAnonymous();
   */
  public signInAnonymously(): Promise<firebase.auth.UserCredential> {
    return new Promise<firebase.auth.UserCredential>((resolve, reject) => {
      this.afAuth
        .setPersistence('local')
        .then(() => {
          this.afAuth
            .signInAnonymously()
            .then((userCredential) => {
              this.user = userCredential.user;
              this.logger.debug('signInAnonymously success');
              resolve(userCredential);
            })
            .catch((err) => {
              this.logger.error('signInAnonymously error', err);
              console.log('Error authenticateAnonymous', err);
              reject(err);
            });
        })
        .catch((err) => {
          this.logger.error('signInAnonymously error persistence', err);
        });
    });
  }

  public async signInWithEmailLink(email: string): Promise<void> {
    console.log('Sign in with Email Flow starts');

    const aUrl = window.location.href;
    try {
      await this.afAuth.setPersistence('local');
      const emailSignin = await this.afAuth.isSignInWithEmailLink(aUrl);
      console.log(`Sign in with email : ${emailSignin.toString()}`);

      if (emailSignin) {
        try {
          const credential = await this.afAuth.signInWithEmailLink(email, aUrl);
          console.log(credential);
          return;
        } catch (err) {
          console.error('Authentication via Email failed', err);
          throw err;
        }
      } else {
        return;
      }
    } catch (err) {
      console.log('Sign in with Email', err);
      throw err;
    }
  }

  public async isSignInWithEmailLink(): Promise<boolean> {
    const aUrl = window.location.href;

    try {
      const emailSignin = await this.afAuth.isSignInWithEmailLink(aUrl);
      return emailSignin;
    } catch (err) {
      console.log('Sign in with Email', err);
      return false;
    }

    return false;
  }

  /**
   * V2 also checks if there is a client account
   * Allows to check if the user exists in firebase authentication and also checks if there is a client in the database
   *
   * @param email the email to check if there is an account
   */
  public async hasAccount(
    email: string,
    checkClient: boolean = true
  ): Promise<boolean> {
    try {
      let hasUser = false;
      const signInMethods = await this.afAuth.fetchSignInMethodsForEmail(email);
      if (signInMethods.length > 0) {
        hasUser = true;
      }

      const callable = this.afFunctions.httpsCallable(
        'httpsAuthCustomerExists'
      );
      const requestParams = { email };
      const result = await callable(requestParams).toPromise();

      const hasAccount = result.hasAccount;

      if (checkClient) {
        if (hasUser && hasAccount) {
          return true;
        } else {
          return false;
        }
      } else {
        if (hasUser) {
          return true;
        } else {
          return false;
        }
      }
    } catch (error) {
      return false;
    }
  }

  public async linkWithEmail(email: string): Promise<void> {
    if (!this.user.isAnonymous) {
      this.logger.warn('User is not anonymous');
      return;
    }
    const credential = firebase.auth.EmailAuthProvider.credential(
      email,
      Math.random().toString(36).slice(-8)
    );

    try {
      // const newUser = await firebase.auth().currentUser.linkWithCredential(credential);
      const userCredentials = await this.user.linkWithCredential(credential);
      this.user = userCredentials.user;
      this.logger.info(
        'Anonymous account successfully upgraded with',
        credential.providerId
      );
    } catch (err) {
      this.logger.error('transform new user failed', err);
    }
  }

  public async loginAsAdmin() {
    await this.afAuth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
    console.log('Authentication complete');
  }

  public async signOut(): Promise<void> {
    // TODO: delete local storage taxformid
    return this.afAuth.signOut();
  }
}
