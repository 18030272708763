import { Bank } from '../bank/bank';

export abstract class EscrowAccount {
  abstract bank: Bank;
}

export const expresssteuerEscrowAccount: EscrowAccount = {
  bank: {
    validated: true,
    valid: true,
    bankCode: '?', // TODO
    bankBIC: 'SOLADEST600',
    bankPLZ: '70144',
    bankCity: 'Stuttgart',
    IBAN: 'DE26600501010405436987',
    bankName: 'BW Bank',
  },
};
