/**
 * Range in which the VAT was reduced in Germany due
 * to the Corona virus.
 */
const coronaReducedVatRange = {
  /**
   * VAT during the Date range
   */
  value: 0.16,
  /**
   * Start date of the range
   */
  start: new Date(2020, 6, 1),
  /**
   * End date of the range
   */
  end: new Date(2020, 11, 31),
  /**
   * Check if a date is within this range
   */
  isInRange: function (this, dt: Date) {
    return dt >= this.start && dt <= this.end;
  },
};

export class Vat {
  /**
   * Get the Value Added Tax rate in Germany that is/was
   * valid on a specific date.
   */
  public static getVat(date: Date = new Date()): number {
    let vat = 0.19; // Default VAT

    if (coronaReducedVatRange.isInRange(date)) {
      vat = coronaReducedVatRange.value;
    }

    return vat;
  }
}
