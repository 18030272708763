import { SmartTaskOnAClient, TASKTYPE } from './smart-task';

export const MESSAGE_TASK_TYPES = [
  TASKTYPE.DOCUMENT_REQUEST as const,
  TASKTYPE.INFORMATION_REQUEST as const,
];

export const BLOCKING_SCHEDULED_MESSAGE_TASK_TYPES = [
  TASKTYPE.DOCUMENT_CLASSIFICATION as const,
  TASKTYPE.MESSAGE_PROCESSING as const,
];

export const VOLL = [
  TASKTYPE.DOCUMENT_CLASSIFICATION as const,
  TASKTYPE.MESSAGE_PROCESSING as const,
];

export type MESSAGETASKTYPE = typeof MESSAGE_TASK_TYPES[number];
export type BLOCKINGSCHEDULEDMESSAGETASKTYPE =
  typeof BLOCKING_SCHEDULED_MESSAGE_TASK_TYPES[number];

export type MessageTask = SmartTaskOnAClient & {
  type: MESSAGETASKTYPE;
};

export type BlockingScheduledMessagesTask = SmartTaskOnAClient & {
  type: BLOCKINGSCHEDULEDMESSAGETASKTYPE;
};
