import { set as setByPath } from 'lodash';

/**
 * A collection of helpers to deal with deep, yet optional objects.
 */

/**
 * A type with all properties and subproperties optional.
 */
export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

/**
 * A path to a property in an object.
 * @example
 * ```
 * const a = {x:{y: [123], z: 'abc'}};
 * const path:Path = ['x', 'y', 0] // ==> represents a path to 123 in `a`
 * ```
 */
export type Path = (string | number)[];

/**
 * First element type of an array.
 */
export type Head<U> = U extends [any, ...any[]]
  ? ((...args: U) => any) extends (head: infer H, ...args: any) => any
    ? H
    : never
  : never;

/**
 * Type of an array without the first element.
 */
export type Tail<U> = U extends [any, any, ...any[]]
  ? ((...args: U) => any) extends (head: any, ...args: infer T) => any
    ? T
    : never
  : never;

/**
 * Type of an deep property at an path.
 * @example
 * ```
 * TraversePath<{ x: { y: number, z: string } }, ['x', 'z']> // leads to `string`
 * ```
 */
export type TraversePath<
  O extends any,
  T extends any[]
> = Head<T> extends keyof O
  ? {
      0: O[Head<T>];
      1: TraversePath<O[Head<T>], Tail<T>>;
    }[Tail<T> extends never ? 0 : 1]
  : never;

/**
 * Get a property of an object at a `Path`.
 * @throws if a property along the path does not exist.
 */
export function getHelper<O extends object, P extends Path>(
  obj: O,
  ...path: P
): TraversePath<O, P> {
  const [head, ...tail] = path;
  if (!obj.hasOwnProperty(head)) {
    throw new TypeError(`object has no property: ${head}`);
  }
  if (tail.length) {
    return getHelper((obj as any)[head], ...tail);
  }
  return (obj as any)[head];
}

/**
 * Set a property of an object to a `Path`.
 * Creates deep objects, if a parent does not yet exist.
 */
export function setHelper<O extends object, P extends Path>(
  obj: O,
  value: TraversePath<O, P>,
  ...path: P
) {
  function isNumeric(val: number | string) {
    if (typeof val === 'number') return true;
    if (typeof val !== 'string') return false;
    return !isNaN(val as any) && !isNaN(parseFloat(val));
  }
  const stringPath = path.reduce((acc, curr) => {
    if (isNumeric(curr)) {
      return `${acc}[${curr}]`;
    }
    if (acc) {
      return `${acc}.${curr}`;
    }
    return `${curr}`;
  }, '');
  setByPath(obj, stringPath, value);
}

/**
 * A helper to create and read from deep objects.
 */
export class DeepObjectHelper<O extends object> {
  /**
   * @param obj is the object to write to and read from.
   */
  constructor(public obj: DeepPartial<O>) {}

  /**
   * Get a value from the deep object via a path.
   * @example
   * ```
   * const obj = {
   *   b: {
   *     c: 1,
   *     x: 42
   *   }
   * };
   *
   * ..getByPath('b', 'x') === 42 // true
   * ```
   */
  getByPath<P extends Path>(...path: P): TraversePath<DeepPartial<O>, P> {
    return getHelper(this.obj, ...path);
  }

  /**
   * Set a value in the deep object via a path.
   * @example
   * ```
   * const obj = {
   *   b: {
   *     c: 1,
   *     x: 42
   *   }
   * };
   *
   * ..setByPath(123, 'b', 'x')
   * obj.b.x === 123 // true
   * ```
   */
  setByPath<P extends Path>(value: TraversePath<O, P>, ...path: P) {
    return setHelper(this.obj as any, value, ...path);
  }

  /**
     * Get a property from the deep object via a `Selector`.
     * A `fallback` value can be provided to be assigned and returned in case the selected path is empty.
     * @example
     * ```
       const xa: A = {
            // a: { d: "" },
            b: 11,
            c: []
        };
        const test = new DeepObjectHelper(xa);
        const a = test.getter(e => e.a, { d: "aaaaa" });
     * ```
     */
  getter<V>(
    selector: Selector<O, V>,
    fallback?: V extends object ? DeepPartial<V> : V
  ): DeepPartial<V> {
    const path = this.pathBySelector(selector);
    try {
      let res = this.getByPath(...path);
      if (!res) {
        throw new Error();
      }
      return res;
    } catch (e) {}
    this.setByPath(fallback as any, ...path);
    return this.getByPath(...path);
  }

  /**
   * Set a property in the deep object via a `Selector`.
   */
  setter<V>(selector: Selector<O, V>, value: V | DeepPartial<V> | null) {
    const path = this.pathBySelector(selector);
    this.setByPath(value as any, ...path);
  }

  /**
   * Add an element to an array property in the deep object via a `Selector`.
   */
  adder<V>(selector: Selector<O, V[]>, value: DeepPartial<V>) {
    const arr = this.getter(selector, []);
    arr.push(value);
    this.setter(selector, arr);
  }

  private pathBySelector(selector: (e: O) => any) {
    return pathBySelector(selector);
  }
}

/**
 * @see `pathBySelector`
 */
export type Selector<O, R = any> = (e: O) => R;

/**
 * Build a `Path` using a type safe `Selector`.
 * @example
 * ```
 * const path = pathBySelector(e=>e.a.b[1].x);
 * expect(path).to.eql(['a', 'b', 1, 'x']); // true
 * ```
 */
export function pathBySelector<O extends object, R = any>(
  selector: Selector<O, R>
): Path {
  const path: Path = [];
  const validator: ProxyHandler<any> = {
    get(_target: any, key: any) {
      path.push(key);
      return new Proxy({}, validator);
    },
  };
  const proxy = new Proxy({}, validator);
  selector(proxy);
  return path;
}
/**
 * Build a `DotPath` that can be used in firestores update method using a type safe `Selector`.
 * Firebase does not support atomic array updates eg. `a.b[1].x` (add `ArrayUnion` or delete `ArrayRemove` is supported).
 *
 * @example
 * ```
 *  const result = firebaseDotPathBySelector((e: any) => e.a.b.c);
 *  expect(result).toBe('a.b.c');
 * ```
 */
export function firebaseDotPathBySelector<O extends object, R = any>(
  selector: Selector<O, R>
): string {
  return pathBySelector(selector).join('.');
}

export function xmlQueryBySelector<O extends object, R = any>(
  selector: Selector<O, R>
): string {
  const path: Path = [];
  const validator: ProxyHandler<any> = {
    get(_target: any, key: any) {
      const returner = new Proxy({}, validator);

      let newValue = '';
      if (`${key[0]}`.match(/[0-9]/)?.length === 1) {
        const numb = parseInt(key);
        newValue += `:nth-of-type(${numb + 1})`;
      } else {
        if (path.length > 0) {
          newValue += '>';
        }
        newValue += `${key}`;
      }
      path.push(newValue);
      return returner;
    },
  };
  const proxy = new Proxy({}, validator);
  selector(proxy);
  return path.join('');
}
