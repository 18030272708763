import { Campaign } from '../campaign/campaign';
import { EnvironmentDetails } from '../environment/environment';

export enum CALCULATIONSTATUS {
  OK = 'ok',
  ERROR = 'error',
}

export class CalculationData2 {
  public static getTemplate(): CalculationData2 {
    return {
      status: CALCULATIONSTATUS.OK,
      environment: EnvironmentDetails.getTemplate(),
      taxformReturn: {
        incomeTax: 0,
        totalTax: 0,
        household: 0,
        solidarityTax: 0,
        churchTax: 0,
      },
      campaign: Campaign.getTemplate(),
      offer: {
        taxToPay: 0,
        taxRefund: 0,
        discount: 0,
        gross: {
          marge: 0,
          customerCost: 0,
        },
        net: {
          marge: 0,
          taxAdvisor: 0,
          margePercent: 0,
          customerCost: 0,
        },
        vat: {
          percent: 0,
          marge: 0,
          taxAdvisor: 0,
          customerCost: 0,
        },
      },
    };
  }

  public status?: CALCULATIONSTATUS = CALCULATIONSTATUS.OK;
  public environment: EnvironmentDetails = EnvironmentDetails.getTemplate();
  public taxformReturn = {
    incomeTax: 0,
    totalTax: 0,
    household: 0,
    solidarityTax: 0,
    churchTax: 0,
  };
  public campaign: Campaign = Campaign.getTemplate();
  public offer = {
    taxToPay: 0,
    taxRefund: 0,
    /**
     * discount on price based on campaign
     */
    discount: 0,

    gross: {
      /**
       * Gross marge in euro for expresssteuer
       */
      marge: 0,
      /**
       * Gross costs for customer. This is what he has to pay to expresssteuer
       */
      customerCost: 0,
    },
    net: {
      marge: 0,
      taxAdvisor: 0,
      margePercent: 0,
      customerCost: 0,
    },
    vat: {
      /**
       * The used percentage 0.16, 0.19
       */
      percent: 0,
      /**
       * This is the vat for the customer to pay
       * @example 25-25/1.19
       */
      marge: 0,
      /**
       * separated tax advisor vat
       */
      taxAdvisor: 0,
      /**
       * vat on the value the customer has to pay
       */
      customerCost: 0,
    },
  };
}
