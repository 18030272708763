import { DAYS_PER_MONTH, DAYS_PER_YEAR } from '@expresssteuer/date-helper';
import firebase from 'firebase/app';
import {
  GOVERNENTALAIDTYPE,
  IGovermentalAid,
} from '../../interfaces/govermentalAid';

export enum BASE {
  'day' = 'day',
  'month' = 'month',
  'year' = 'year',
}

/**
 * @description A Govermental Aid is money from the goverment.
 * * valid expense types
 *  - unemployment
 *  - sickness
 *  - lifeAnnuity
 *  - parental
 *  - mother
 *  - alimony
 *  - insolvency
 *  - shorttimework
 */

export class GovermentalAid implements IGovermentalAid {
  public static getTemplate(
    category: GOVERNENTALAIDTYPE = GOVERNENTALAIDTYPE.UNEMPLOYMENT,
    value: number | null = null,
    description = 'undeclared'
  ): GovermentalAid {
    return {
      description: description,
      category: category,
      value: value,
      startDate: null,
      endDate: null,
      active: false,
      isCalculated: false,
    };
  }

  /**
   * Returns the value recalculated to the specific time period
   * @example if the aim is 10 base = day and you go for getValue(aid, 'month') you get 300 (10 * 30) back
   * @param aid the aid to get the value from
   * @param base the base into whicht you wanna recalculate
   */
  public static getValue(aid: GovermentalAid, base: BASE): number {
    if (!aid.value) {
      throw new Error('aid.value is not set');
    }

    const aidBase = aid.base || BASE.month;

    return this.convertDayToBase(this.convertToDay(aid.value, aidBase), base);
  }

  /**
   * Convert the value from the base to a daily rate
   * @example if a gov aid is 100 base:month this function will return 100 / 30 = 3.33
   * @param value the value to change
   * @param base the base the value is set from
   * @returns the value in a daily rate
   */
  private static convertToDay(value: number, base: BASE): number {
    switch (base) {
      case BASE.day:
        return value;
      case BASE.month:
        return value / DAYS_PER_MONTH;
      case BASE.year:
        return value / DAYS_PER_YEAR;
      default:
        throw new Error('base is not allowed');
    }
  }
  /**
   * Convert a aid value from a daily rate to a base rate
   * @example if a gov aid is 100 base:month this function will return 100 * 30 = 3000
   * @param value the value set as value per day
   * @param base the base in which to convert
   * @returns the converted value
   */
  private static convertDayToBase(value: number, base: BASE): number {
    switch (base) {
      case BASE.day:
        return value;
      case BASE.month:
        return value * DAYS_PER_MONTH;
      case BASE.year:
        return value * DAYS_PER_YEAR;
      default:
        throw new Error('base is not allowed');
    }
  }

  /**
   * @description Arbeitslosengeld = unemployment, Krankengeld = sickness,Leibrente = lifeAnnuity, Elterngeld = parental, Muttergeld = motherUnterhalt = alimony, Insolvenzgeld = insolvency, Kurzarbeitsgeld = shorttimework
   */
  category: GOVERNENTALAIDTYPE = GOVERNENTALAIDTYPE.UNEMPLOYMENT;
  description = '';
  /**
   * The full value recieved from the goverment during the specified time
   */
  value: number | null = null;
  /**
   * start of recieving aid. must be withing the year of reimbursement. If null, fullyear
   */
  startDate: firebase.firestore.Timestamp | null = null;
  /**
   * end of recieving aid. must be withing the year of reimbursement. If null, fullyear
   */
  endDate: firebase.firestore.Timestamp | null = null;
  /**
   * This flaw is used in the web ui. A client can set a gov but deactivate and the values are still saved
   */
  active = true;

  /*****************************
   * On what base is the number of.
   * Is it a daily rate, monthly rate or for the full year
   */
  base?: BASE = BASE.month;
  /**
   * Calculated values are only there during internal calculations and will be removed before starting a new Calculation
   */
  isCalculated = false;
}

export const PREDEFINEDAIDS: { [key: string]: GovermentalAid } = {
  Insolvensgeld: quickCreate('Insolvensgeld', GOVERNENTALAIDTYPE.INSOLVENCY),
  Mutterschaftsgeld: quickCreate(
    'Mutterschaftsgeld',
    GOVERNENTALAIDTYPE.MOTHER
  ),
  Elterngeld: quickCreate('Elterngeld', GOVERNENTALAIDTYPE.PARENTAL),
  Kurzarbeitsgeld: quickCreate(
    'Kurzarbeitsgeld',
    GOVERNENTALAIDTYPE.SHORTTIMEWORK
  ),
  Krankengeld: quickCreate('Krankengeld', GOVERNENTALAIDTYPE.SICKNESS),
  Arbeitslosengeld: quickCreate(
    'Arbeitslosengeld',
    GOVERNENTALAIDTYPE.UNEMPLOYMENT
  ),
};

function quickCreate(
  description: string,
  category: GOVERNENTALAIDTYPE
): GovermentalAid {
  const govermentalAid = GovermentalAid.getTemplate(
    category,
    null,
    description
  );
  govermentalAid.startDate = null;
  govermentalAid.endDate = null;
  return govermentalAid;
}
