import { DataSource } from '../data-source/data-source';

export interface IAdditionalExpense {
  id: string;
  type: EXPENSETYPE | string;
  category: ADDITIONALEXPENSECATEGORY;
  subcategory: ADDITIONALEXPENSESUBCATEGORY;
  description: string;
  value: number | null;
  partial: boolean;
  dataSource?: DataSource;
}

export enum ADDITIONALEXPENSECATEGORY {
  /**
   * Arbeitsmittel
   */
  'workequipment' = 'workequipment',
  /**
   * Werbungskosten
   */
  'advertise' = 'advertise',
  /**
   * Sonstige Aufwendungen
   */
  'special' = 'special',
  /**
   * Haushaltsnahe Dienstleistungen
   */
  'household' = 'household',
}

export enum ADDITIONALEXPENSESUBCATEGORY {
  'NONE' = 'none',
  /**
   * Arbeitsmittel
   */
  'WORKEQUIPMENT' = 'workequipment',
  /**
   * Arbeitszimmer
   */
  'WORKROOM' = 'workroom',
  /**
   * Fortbildungskosten
   */
  'EDUCATION' = 'education',
  /**
   * Bewerbungskosten
   */
  'APPLICATION' = 'application',
  /**
   * Umzugskosten
   */
  'RELOCATION' = 'relocation',
  /** Verpflegungsmehraufwand */
  'FOODRATE' = 'foodrate',
  /**
   * Reisekosten
   */
  'TRAVELCOST' = 'travelcost',
  /**
   * Doppelte Haushaltskosten
   */
  'DOUBLEHOUSEHOLDCOST' = 'doublehouseholdcost',
  'ENTERTAINSCOST' = 'entertainscost',
  /**
   * Spenden
   */
  'DONATION' = 'donation',
  /**
   * Aussergewöhliche Belastungen
   */
  'EXTRABURDEN' = 'extraburden',
  /**
   * Haushaltsnahe Dienstleistungen
   */
  'HOUSEHOLDSERVICES' = 'householdservices',
  /**
   * Handwerkerleistungen
   */
  'CRAFTSMANSERVICES' = 'craftmanservices',
  /**
   * Unterhalt
   */
  'LIVING' = 'living',
  /**
   * Gewerkschaftsbeiträge
   */
  'UNION' = 'union',
  /**
   * Rente
   */
  'PENSION' = 'pension',
  /**
   * Alles andere
   */
  'OTHER' = 'other',
}

export const AdditionalExpenseSubcategoryMapping = {
  [ADDITIONALEXPENSECATEGORY.workequipment]: [
    ADDITIONALEXPENSESUBCATEGORY.WORKEQUIPMENT,
  ],
  [ADDITIONALEXPENSECATEGORY.advertise]: [
    ADDITIONALEXPENSESUBCATEGORY.WORKROOM,
    ADDITIONALEXPENSESUBCATEGORY.EDUCATION,
    ADDITIONALEXPENSESUBCATEGORY.APPLICATION,
    ADDITIONALEXPENSESUBCATEGORY.RELOCATION,
    ADDITIONALEXPENSESUBCATEGORY.DOUBLEHOUSEHOLDCOST,
  ],
  [ADDITIONALEXPENSECATEGORY.special]: [
    ADDITIONALEXPENSESUBCATEGORY.LIVING,
    ADDITIONALEXPENSESUBCATEGORY.DONATION,
    ADDITIONALEXPENSESUBCATEGORY.EXTRABURDEN,
  ],
  [ADDITIONALEXPENSECATEGORY.household]: [
    ADDITIONALEXPENSESUBCATEGORY.HOUSEHOLDSERVICES,
    ADDITIONALEXPENSESUBCATEGORY.CRAFTSMANSERVICES,
  ],
};

/**
 * These german labels are used as a description prefill
 */
export const AdditionalExpenseDescriptions: Record<
  ADDITIONALEXPENSESUBCATEGORY,
  string
> = {
  none: '',
  workequipment: 'Arbeitsmittel',
  workroom: 'Arbeitszimmer',
  education: 'Ausbildung',
  application: 'Werbungskosten',
  relocation: 'Umzugskosten',
  foodrate: 'Verpflegungsmehraufwand',
  travelcost: 'Reisekosten',
  doublehouseholdcost: 'Doppelte Haushaltsführung',
  entertainscost: '',
  donation: 'Spende',
  extraburden: 'Krankheitskosten',
  householdservices: 'Haushaltsnahe Dienstleistungen',
  craftmanservices: 'Handwerkerleistungen',
  living: 'Unterhalt',
  union: 'Gewerkschaftsbeiträge',
  pension: 'Pension',
  other: 'Weitere Kosten',
};

export enum EXPENSETYPE {
  'QUESTION' = 'question', // from the Profession questions
  'EXPENSE' = 'expense', // from the Profession automatic expenses
  'OPTIMIZATION' = 'optimization', // optimization from the profession
  'MANUAL' = 'manual', // manually from the user
}

/**
 * Object Describes an additional expense
 * valid expense types
 *  - workequipment
 *  - advertise
 *  - special
 *  - household
 *
 *   // Arbeitsmittel = workequipment, Werbungskosten = advertise,
 *   // Sonderausgaben = special, Steuerermässigung nach §35a EStG = household
 */
export class AdditionalExpense implements IAdditionalExpense {
  public static getTemplate(
    type: EXPENSETYPE = EXPENSETYPE.MANUAL
  ): AdditionalExpense {
    return {
      id: '',
      type: type,
      description: '',
      category: ADDITIONALEXPENSECATEGORY.advertise,
      subcategory: ADDITIONALEXPENSESUBCATEGORY.NONE,
      value: 0.0,
      partial: false,
    };
  }
  public id = '';
  public dataSource?: DataSource;
  public type: string | EXPENSETYPE = EXPENSETYPE.MANUAL;
  public category: ADDITIONALEXPENSECATEGORY =
    ADDITIONALEXPENSECATEGORY.advertise; // Arbeitsmittel = workequipment, Werbungskosten = advertise,
  public subcategory: ADDITIONALEXPENSESUBCATEGORY =
    ADDITIONALEXPENSESUBCATEGORY.NONE;
  // Sonderausgaben = special, Steuerermässigung nach §35a EStG = household
  public description = '';
  public partial = false;
  public value: number | null = null;
}
export const PREDEFINEDEXPENSES: {
  readonly [key: string]: IAdditionalExpense;
} = {
  get arbeitsmittel() {
    return quickCreate(
      ADDITIONALEXPENSECATEGORY.workequipment,
      ADDITIONALEXPENSESUBCATEGORY.WORKEQUIPMENT,
      'Arbeitsmittel'
    );
  },
  get fortbildungskosten() {
    return quickCreate(
      ADDITIONALEXPENSECATEGORY.advertise,
      ADDITIONALEXPENSESUBCATEGORY.EDUCATION,
      'Ausbildungs/Fortbildungskosten'
    );
  },
  get bewerbungskosten() {
    return quickCreate(
      ADDITIONALEXPENSECATEGORY.advertise,
      ADDITIONALEXPENSESUBCATEGORY.APPLICATION,
      'Bewerbungskosten'
    );
  },
  get reisekosten() {
    return quickCreate(
      ADDITIONALEXPENSECATEGORY.advertise,
      ADDITIONALEXPENSESUBCATEGORY.OTHER,
      'Reisekosten'
    );
  },
  get arbeitszimmer() {
    return quickCreate(
      ADDITIONALEXPENSECATEGORY.advertise,
      ADDITIONALEXPENSESUBCATEGORY.WORKROOM,
      'Arbeitszimmer'
    );
  },
  get handwerkerleistungen() {
    return quickCreate(
      ADDITIONALEXPENSECATEGORY.household,
      ADDITIONALEXPENSESUBCATEGORY.NONE,
      'Handwerkerleistungen'
    );
  },
  get spenden() {
    return quickCreate(
      ADDITIONALEXPENSECATEGORY.special,
      ADDITIONALEXPENSESUBCATEGORY.NONE,
      'Spenden'
    );
  },
  get berufsbekleidung() {
    return quickCreate(
      ADDITIONALEXPENSECATEGORY.workequipment,
      ADDITIONALEXPENSESUBCATEGORY.OTHER,
      'Berufsbekleidung'
    );
  },
  get berufsbekleidung_reingung() {
    return quickCreate(
      ADDITIONALEXPENSECATEGORY.workequipment,
      ADDITIONALEXPENSESUBCATEGORY.OTHER,
      'Berufsbekleidung Reingung'
    );
  },
  get fachliteratur() {
    return quickCreate(
      ADDITIONALEXPENSECATEGORY.advertise,
      ADDITIONALEXPENSESUBCATEGORY.EDUCATION,
      'Fachliteratur'
    );
  },
};

export const JOBSPECIFICEXPENSES =
  // : {
  //   [jobLabel: string]: {
  //     [key in ExpenseType]: number | null;
  //   };
  // }
  {
    get 'Ambulante Pflege'() {
      return {
        reisekosten: null,
        berufsbekleidung_reingung: 189,
        berufsbekleidung: 80,
        fachliteratur: 80,
        spenden: 90,
        bewerbungskosten: 90,
      };
    },
    get Außendienst() {
      return {
        reisekosten: 200,
        berufsbekleidung_reingung: null,
        berufsbekleidung: null,
        fachliteratur: null,
        spenden: 90,
        bewerbungskosten: 90,
      };
    },
    get Bau() {
      return {
        reisekosten: 200,
        berufsbekleidung_reingung: null,
        berufsbekleidung: null,
        fachliteratur: null,
        spenden: 90,
        bewerbungskosten: 90,
      };
    },
    get Büro() {
      return {
        reisekosten: null,
        berufsbekleidung_reingung: 189,
        berufsbekleidung: 189,
        fachliteratur: 80,
        spenden: 90,
        bewerbungskosten: 90,
      };
    },
    get 'Einzelhandel, Verkäufer'() {
      return {
        reisekosten: null,
        berufsbekleidung_reingung: 160,
        berufsbekleidung: 80,
        fachliteratur: null,
        spenden: 90,
        bewerbungskosten: 90,
      };
    },
    get Fahrer() {
      return {
        reisekosten: null,
        berufsbekleidung_reingung: 160,
        berufsbekleidung: null,
        fachliteratur: null,
        spenden: 90,
        bewerbungskosten: 90,
      };
    },
    get 'Freie Berufe'() {
      return {
        reisekosten: 200,
        berufsbekleidung_reingung: null,
        berufsbekleidung: null,
        fachliteratur: 80,
        spenden: 90,
        bewerbungskosten: 90,
      };
    },
    get Gastro() {
      return {
        reisekosten: 200,
        berufsbekleidung_reingung: 160,
        berufsbekleidung: 80,
        fachliteratur: null,
        spenden: 90,
        bewerbungskosten: 90,
      };
    },
    get Homeoffice() {
      return {
        reisekosten: 200,
        berufsbekleidung_reingung: null,
        berufsbekleidung: null,
        fachliteratur: null,
        spenden: 90,
        bewerbungskosten: 90,
      };
    },
    get Lager() {
      return {
        reisekosten: null,
        berufsbekleidung_reingung: 160,
        berufsbekleidung: 80,
        fachliteratur: null,
        spenden: 90,
        bewerbungskosten: 90,
      };
    },
    get 'Pflege, (Kinder-) Betreuung'() {
      return {
        reisekosten: null,
        berufsbekleidung_reingung: 189,
        berufsbekleidung: 189,
        fachliteratur: 80,
        spenden: 90,
        bewerbungskosten: 90,
      };
    },
    get Produktion() {
      return {
        reisekosten: null,
        berufsbekleidung_reingung: 189,
        berufsbekleidung: 189,
        fachliteratur: 80,
        spenden: 90,
        bewerbungskosten: 90,
      };
    },
    get 'Renigung, Hausmeister'() {
      return {
        reisekosten: null,
        berufsbekleidung_reingung: 189,
        berufsbekleidung: 189,
        fachliteratur: 80,
        spenden: 90,
        bewerbungskosten: 90,
      };
    },
    get Sonstiges() {
      return {
        reisekosten: null,
        berufsbekleidung_reingung: 189,
        berufsbekleidung: 189,
        fachliteratur: 80,
        spenden: 90,
        bewerbungskosten: 90,
      };
    },
    get Werkstatt() {
      return {
        reisekosten: null,
        berufsbekleidung_reingung: 189,
        berufsbekleidung: 189,
        fachliteratur: 80,
        spenden: 90,
        bewerbungskosten: 90,
      };
    },
    get Unknown() {
      return {
        reisekosten: null,
        berufsbekleidung_reingung: null,
        berufsbekleidung: null,
        fachliteratur: null,
        spenden: 90,
        bewerbungskosten: null,
      };
    },
  };

export type JobLabel = keyof typeof JOBSPECIFICEXPENSES;
export type ExpenseType = keyof typeof PREDEFINEDEXPENSES;

function quickCreate(
  category: ADDITIONALEXPENSECATEGORY,
  subcategory: ADDITIONALEXPENSESUBCATEGORY,
  description: string,
  id = ''
): IAdditionalExpense {
  return {
    id,
    type: EXPENSETYPE.MANUAL,
    description: description,
    category: category,
    subcategory: subcategory,
    value: 0,
    partial: false,
  };
}
