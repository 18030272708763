import { isAbstractTimestamp } from '@expresssteuer/firebase-helper';
import firebase from 'firebase/app';

export enum SerializedFieldType {
  string = 'string',
  number = 'number',
  timestamp = 'timestamp',
  factor = 'factor', // TODO integrate into classification front-end and co
  iban = 'iban',
}

export interface SerializedField {
  type: SerializedFieldType;
  optional?: boolean;
}

export type SerializedFields = {
  [key in string | number]: SerializedField;
};

function isSerializedFieldType(val: unknown, type: SerializedFieldType) {
  switch (type) {
    case SerializedFieldType.string:
      return typeof val === 'string';
    case SerializedFieldType.number:
      return typeof val === 'number';
    case SerializedFieldType.timestamp:
      return isAbstractTimestamp(val);
    case SerializedFieldType.factor:
      return typeof val === 'number' && val >= 0 && val <= 1;
    default:
      return false;
  }
}

/**
 * @example
 * ```
isObjectFromFields({ abc: 'abc' }, { abc: { type: SerializedFieldType.string } }); // true
isObjectFromFields({ abc: 1 }, { abc: { type: SerializedFieldType.string } }); // false
isObjectFromFields({ abc: null }, { abc: { type: SerializedFieldType.string } }); // false
isObjectFromFields({ abc: null }, { abc: { type: SerializedFieldType.string, optional: true } }); // true
 * ```
 */
export function isObjectFromFields<TFields extends SerializedFields>(
  obj: any,
  fields: TFields
): obj is ObjectFromFields<TFields> {
  if (!obj || typeof obj !== 'object') {
    return false;
  }
  return !Object.keys(fields).some((fieldName) => {
    // find one where it does not match
    const field = fields[fieldName];
    const value = obj?.[fieldName];
    if (field.optional && [null, undefined].includes(value as any)) {
      return false;
    }
    return !isSerializedFieldType(value, field.type);
  });
}

export type TypeFromField<U extends SerializedField> =
  U['type'] extends SerializedFieldType.string
    ? string
    : U['type'] extends SerializedFieldType.number
    ? number
    : U['type'] extends SerializedFieldType.timestamp
    ? firebase.firestore.Timestamp
    : U['type'] extends SerializedFieldType.factor
    ? number
    : never;

type ObjectFromFieldsRequiredKeys<TFields extends SerializedFields> = {
  [K in keyof TFields]: {
    0: never;
    1: K;
  }[TFields[K]['optional'] extends true ? 0 : 1];
}[keyof TFields];
type ObjectFromFieldsOptionalKeys<TFields extends SerializedFields> = {
  [K in keyof TFields]: {
    0: never;
    1: K;
  }[TFields[K]['optional'] extends true ? 1 : 0];
}[keyof TFields];
type ObjectFromFieldsAsRequired<TFields extends SerializedFields> = {
  [K in keyof TFields]: TypeFromField<TFields[K]>;
};
type ObjectFromFieldsAsOptional<TFields extends SerializedFields> = {
  [K in keyof TFields]?: TypeFromField<TFields[K]>;
};

/**
 * @example ```
type Example = ObjectFromFields<
  {
    abc1: { type: SerializedFieldType.timestamp; optional: true },
    abc2: { type: SerializedFieldType.string }
  }
>;

// Example ≈

{
  abc1?: firebase.firestore.Timestamp;
  abc2: string;
}
```
 */
export type ObjectFromFields<TFields extends SerializedFields> =
  ObjectFromFieldsAsRequired<
    Pick<TFields, ObjectFromFieldsRequiredKeys<TFields>>
  > &
    ObjectFromFieldsAsOptional<
      Pick<TFields, ObjectFromFieldsOptionalKeys<TFields>>
    >;
