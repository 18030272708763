export enum CalculationFields {
  'health' = 'health',
  'pension' = 'pension',
  'unemployment' = 'unemployment',
  'nursing' = 'nursing',
}

export enum StateType {
  new,
  old,
}
export enum States {
  'Baden-Württemberg' = 'BW',
  'Bayern' = 'BY',
  'Berlin' = 'BE',
  'Brandenburg' = 'BB',
  'Bremen' = 'HB',
  'Hamburg' = 'HH',
  'Hessen' = 'HE',
  'Mecklenburg-Vorpommern' = 'MV',
  'Niedersachsen' = 'NI',
  'Nordrhein-Westfalen' = 'NRW',
  'Rheinland-Pfalz' = 'RP',
  'Saarland' = 'SL',
  'Sachsen' = 'SN',
  'Sachsen-Anhalt' = 'SA',
  'Schleswig-Holstein' = 'SH',
  'Thüringen' = 'TH',
}

export class InsuranceClass {
  public health: number = 0.0;
  public pension: number = 0.0;
  public unemployment: number = 0.0;
  public nursing: number = 0.0;
}

export class Insurance {
  _percent: number = 0;
  cap: number = 0;
  calculationField: keyof InsuranceClass;

  constructor(
    percent: number,
    cap: number,
    calculationField: keyof InsuranceClass
  ) {
    this._percent = percent;
    this.cap = cap;
    this.calculationField = calculationField;
  }

  public percent(): number {
    return this._percent;
  }
}

export class HealthInsurance extends Insurance {
  additionalFee: number;

  constructor(percent: number, cap: number, additionalFee: number = 0.0095) {
    super(percent, cap, 'health');
    this.additionalFee = additionalFee;
  }

  public percent(): number {
    return this._percent + this.additionalFee;
  }
}
export class PensionInsurance extends Insurance {
  constructor(percent: number, cap: number) {
    super(percent, cap, 'pension');
  }
}
export class UnemploymentInsurance extends Insurance {
  constructor(percent: number, cap: number) {
    super(percent, cap, 'unemployment');
  }
}
export class NursingInsurance extends Insurance {
  constructor(percent: number, cap: number) {
    super(percent, cap, 'nursing');
  }

  public getPercent(age: number, hasChildren: boolean): number {
    if (age >= 23 && hasChildren === false) {
      //console.log('is over 23 and has no children!');
      return this._percent + 0.0025;
    } else {
      return this._percent;
    }
  }
}
