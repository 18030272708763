import { Timestamp } from '@google-cloud/firestore';
import firebase from 'firebase/app';
import { CalculationData2 } from '../calculation/calculation';
import { Client, ClientShort } from '../client/client';
import { ClientNotification } from '../client/notifications';
import { EnvironmentDetails } from '../environment/environment';
import { FirestoreMigration } from '../firestore/firestoreMigration';
import { PRODUCTTYPE } from '../interfaces/iTaxform';
import { Payment } from '../payment/payment';
import { TaxAdvisorBankAccountShort } from '../taxadvisor/bankaccounts/taxadvisorbank';
import { TaxAdvisor, TaxAdvisorShort } from '../taxadvisor/taxadvisor';
import { TaxForm } from '../taxform/taxform';
import { Tenant } from '../tenant/tenant';
import { User } from '../user/user';
import { Reason } from './rejectReason';

/**
 * Defines if the taxcase is ready or needs further
 * information from client or another process
 */
export enum TAXCASEPROCESS {
  /**
   * Automatic generated for a new year
   */
  RETENTION,
  /**
   * Interviewed by customer success team
   */
  INTERVIEW,
}
export enum TAXCASESTATUS {
  /**
   * New TaxCase created, or copy of a taxcase
   * => reject or inProgress
   */
  ENTRY = 'entry',
  /**
   * deleted taxcases
   * @deprecated we do not use this anymore
   */
  DELETED = 'deleted',
  /**
   * Taxcase has been rejected by us or the client has rejected
   */
  REJECT = 'reject',
  /**
   * We are working on the taxcase
   */
  INPROGRESS = 'inProgress',
  /**
   * The taxcase is done and paid
   */
  DONE = 'done',
  /**
   * @deprecated Do not use
   */
  VIRTUAL_MY = 'virtual_my',
}
export enum TAXCASESTATUS_REJECT {
  /**
   * The case or client is suspicous
   * We rejected the case
   */
  FRAUD = 'fraud',
  /**
   * There is an error in the data/technical
   * We cann not process the taxcase and stop
   */
  ERROR = 'error',
  /**
   * The case was a testcase and has been marked as testcase
   */
  TEST = 'test',
  /**
   * The case has been rejected because the case is double in our system
   */
  DOUBLE = 'double',
  /**
   * If nothing else matches, we add them here
   */
  OTHER = 'other',
  /**
   * The customer has declined
   */
  CUSTOMERDECLINED = 'customer_declinde',
  /**
   * We rejected because there was no response from the client
   */
  NORESPONSE = 'no_customer_response',
  /**
   * We rejected because the case is unatractive for us.
   */
  UNATTRACTIVE = 'unattractive',
  /**
   * The customer does not belong to the right target group like : official, recrut or pensioneer
   */
  WRONG_TARGETGROUP = 'wrong_targetgroup',
}

export enum TAXCASESTATUS_INPROGRESS {
  /**
   * Waiting for data from the client.
   * Missing documents or recipes
   */
  WAITINGDATA = 'waiting_data',

  /**
   * Waiting for the data from the Vollmachtsdatebank from the taxoffice
   */
  WAITINGFORDATARETRIEVAL = 'waiting_dataretrievel', // Datenabruf vom FA eingeleitet, warten auf Daten

  /**
   * Waiting for data changes after the case has been duplicated
   * @deprecated This status should be removed in the future.
   */
  WAITINGFORDATARETRIEVAL_CHANGES = 'waiting_dataretrievel_changes', // Fall wurde dupliziert, kunde muss noch auf änderungen abgefragt werden.

  /**
   * Sent to taxoffice and waiting for data to be returned
   */
  WAITINGFORDATARETRIEVAL_SENT = 'waiting_dataretrievel_sent', // Datenabruf vom FA im tool erfasst

  /**
   * We recieved the data from the FA.
   * The data will be merged if one job or add new jobs taxform.jobs with type @see JOBTYPE [TAXOFFICE]
   */
  WAITINGFORDATARETRIEVAL_RETURNED = 'waiting_dataretrievel_returned', // Datenabruf vom FA analysiert

  /**
   * We requested the taxid of the client from the taxoffice
   */
  WAITINGFORTAXID = 'waiting_taxid', // Datenabruf vom FA eingeleitet

  /**
   * We are waiting for client data. But the client is not responding
   * @deprecated This status should be removed and we send it to REJECT or automatically send more messages until he is answering
   */
  NORESPONSE = 'no_customer_response',

  /**
   * If the taxcase is ready and we would like to get a test.
   * If the test was successful, we send it to ELSTER_TEST_COMPLETE
   */
  ELSTER_TEST_PREPARE = 'elster_test_prepare',

  /**
   * AUTOMATIC SET
   * If the test was successful
   */
  ELSTER_TEST_COMPLETE = 'elster_test_complete',

  /**
   * Preparation for sending out client approval for this taxcase
   */
  WAITING_FOR_CLIENT_APPROVAL_PREPARE = 'waiting_for_client_approval_prepare',

  /**
   * The taxcase could not be sent automatically to the client because of erros
   * This might be that the customer is anonymous, or we found multiple clients with the same id
   */
  WAITING_FOR_CLIENT_APPROVAL_ERROR = 'waiting_for_client_approval_error',

  /**
   * Now the client has the option to further edit his taxcase. cf. single touchpoint flow
   */
  WAITING_FOR_CLIENT_EDITS = 'WAITING_FOR_CLIENT_EDITS',

  /**
   * The Taxcase is ready to be sent to the tax office from our side. Now the customer has to approve it before it is send to elster.
   */
  WAITING_FOR_CLIENT_APPROVAL = 'waiting_for_client_approval',

  /**
   * If the test was successful, we can prepare to send to production
   * Setting this state, we send to the taxoffice!
   */
  ELSTER_PROD_PREPARE = 'elster_prod_prepare',

  /**
   * The Taxcase is ready to be sent to the taxoffice.
   * The Test are successful
   */
  READYTOSEND = 'readytosend',

  /**
   * We are ready to sent to taxoffice, but an error occurred
   * Represents technical errors, not errors in the form returned from the elster service
   */
  READYTOSENDWITHERROR = 'readytosendwitherror',

  /**
   * The Taxcase has successfully been sent to the taxoffice
   */
  SENTTOTAXOFFICE = 'senttotaxoffice',

  /**
   * The Taxoffice needs additional informations from us
   */
  QUESTIONSFROMTAXOFFICE = 'questionsfromtaxoffice',

  /**
   * The TaxCase has been returned from the taxoffice and we have the "Bescheid"
   */
  RETURNEDFROMTAXOFFICE = 'returnedfromtaxoffice',

  /**
   * We are going against a "Bescheid" from the taxoffice
   */
  APPEALED = 'appealed',

  /**
   * We generate a SEPA for collecting our marge from the client
   * @deprecated go for generic terms instead of sepa when we implement additional payment methods
   */
  SEPA = 'sepa',

  /**
   * SEPA has been processed
   * * @deprecated go for generic terms instead of sepa when we implement additional payment methods
   */
  SEPAPROCESSED = 'sepaprocessed',

  /**
   * Payment has been declined by the client
   */
  PAYMENTDECLINED = 'paymentdeclined',

  /**
   * Payment sent and we are waiting for the money to arrive on our bank
   */
  WAITINGFORMONEY = 'waitingformoney',

  /**
   * Money is on our bank. We wait 90 days in case of sepa in this state because a client could reject the payment for 90 days
   */
  MONEYRECIEVED = 'moneyrecieved',

  /**
   * Payment with this client is not possible.
   * Possibly multiple attends or technical issues
   */
  PAYMENTNOTPOSSIBLE = 'paymentnotpossible', // Bezahlung abgelehnt oder Kunde hat zurückgezogen oder falsche Daten

  /**
   * We are over 90 days or the payment is done and can not be recollected by the client
   * Next status is done
   */
  PAID = 'paid',
}

export const TAXCASE_EDIT_BLOCKING_STATUSES: (
  | TAXCASESTATUS_INPROGRESS
  | TAXCASESTATUS_REJECT
)[] = [
  TAXCASESTATUS_INPROGRESS.WAITING_FOR_CLIENT_APPROVAL_PREPARE,
  TAXCASESTATUS_INPROGRESS.WAITING_FOR_CLIENT_APPROVAL,
  TAXCASESTATUS_INPROGRESS.ELSTER_PROD_PREPARE,
  TAXCASESTATUS_INPROGRESS.SENTTOTAXOFFICE,
];

export class TaxCaseStatusHelper {
  /**
   * Converts TaxCase Status to
   * @param status
   * @param substatus
   * @returns
   */
  static getTaxCaseStatusName(status: TaxCaseStatus): {
    status: string;
    substatus: string | null;
  } {
    const result = {
      status: TaxCaseStatusHelper.getStatusName(status.current),
      substatus: status.substatus
        ? TaxCaseStatusHelper.getSubStatusName(status.substatus)
        : null,
    };
    return result;
  }

  static getStatusName(status: TAXCASESTATUS): string {
    switch (status) {
      case TAXCASESTATUS.ENTRY:
        return 'Neuer Fall';
      case TAXCASESTATUS.INPROGRESS:
        return 'In Bearbeitung';
      case TAXCASESTATUS.REJECT:
        return 'Abgelehnt';
      case TAXCASESTATUS.DONE:
        return 'Abgeschlossen';
      default:
        throw new Error('Unknown status');
    }
  }

  static getSubStatusName(
    substatus: TAXCASESTATUS_REJECT | TAXCASESTATUS_INPROGRESS
  ): string {
    switch (substatus) {
      case undefined:
        return '';
      case null:
        return '';
      case TAXCASESTATUS_INPROGRESS.APPEALED:
        return 'Einspruch';

      case TAXCASESTATUS_INPROGRESS.ELSTER_PROD_PREPARE:
        return 'Elster <Vorbereitung>';

      case TAXCASESTATUS_INPROGRESS.ELSTER_TEST_COMPLETE:
        return 'Elster Test <Abgeschlossen>';

      case TAXCASESTATUS_INPROGRESS.ELSTER_TEST_PREPARE:
        return 'Elster Test <Vorbereitung>';

      case TAXCASESTATUS_INPROGRESS.MONEYRECIEVED:
        return 'Geld von Finanzamt erhalten';

      case TAXCASESTATUS_INPROGRESS.PAID:
        return 'Ausbezahlt';

      case TAXCASESTATUS_INPROGRESS.PAYMENTDECLINED:
        return 'Bezahlung abgelehnt';

      case TAXCASESTATUS_INPROGRESS.PAYMENTNOTPOSSIBLE:
        return 'Bezahlung nicht möglich';

      case TAXCASESTATUS_INPROGRESS.QUESTIONSFROMTAXOFFICE:
        return 'Fragen von Finanzamt';

      case TAXCASESTATUS_INPROGRESS.READYTOSEND:
        return 'Übermittlung ans Finanzamt <Bereit>';

      case TAXCASESTATUS_INPROGRESS.READYTOSENDWITHERROR:
        return 'Übermittlung ans Finanzamt <Fehler>';

      case TAXCASESTATUS_INPROGRESS.RETURNEDFROMTAXOFFICE:
        return 'Bescheid vom Finanzamt erhalten';

      case TAXCASESTATUS_INPROGRESS.SENTTOTAXOFFICE:
        return 'Übermittlung ans Finanzamt <Übermittelt>';

      case TAXCASESTATUS_INPROGRESS.SEPAPROCESSED:
        return 'SEPA bazahlung abgeschlossen';

      case TAXCASESTATUS_INPROGRESS.WAITINGDATA:
        return 'Warte auf Kundendaten';

      case TAXCASESTATUS_INPROGRESS.WAITINGFORDATARETRIEVAL:
        return 'Warte auf VMDB Daten';

      case TAXCASESTATUS_INPROGRESS.WAITINGFORDATARETRIEVAL_RETURNED:
        return 'VMDB Daten erhalten';

      case TAXCASESTATUS_INPROGRESS.WAITINGFORDATARETRIEVAL_SENT:
        return 'VMDB Datenabruf';

      case TAXCASESTATUS_INPROGRESS.WAITINGFORMONEY:
        return 'Warte auf Bezahlung';

      case TAXCASESTATUS_INPROGRESS.WAITINGFORTAXID:
        return 'Warte auf Steuer-ID';

      case TAXCASESTATUS_INPROGRESS.WAITING_FOR_CLIENT_APPROVAL:
        return 'Warte auf Kundenfreigabe';

      case TAXCASESTATUS_INPROGRESS.WAITING_FOR_CLIENT_APPROVAL_PREPARE:
        return 'Warte auf Kundenfreigabe <Vorbereitung>';
      case TAXCASESTATUS_INPROGRESS.WAITING_FOR_CLIENT_APPROVAL_ERROR:
        return 'Warte auf Kundenfreigabe <Fehler>';

      case TAXCASESTATUS_REJECT.CUSTOMERDECLINED:
        return 'Kunde hat abgelehnt';
      case TAXCASESTATUS_REJECT.DOUBLE:
        return 'Doppelter Fall';
      case TAXCASESTATUS_REJECT.ERROR:
        return 'Technischer Fehler';
      case TAXCASESTATUS_REJECT.FRAUD:
        return 'Betrug';
      case TAXCASESTATUS_REJECT.NORESPONSE:
        return 'Keine Antwort vom Kunden';
      case TAXCASESTATUS_REJECT.OTHER:
        return 'Sonstiger Grund';
      case TAXCASESTATUS_REJECT.TEST:
        return 'Testfall';
      case TAXCASESTATUS_REJECT.UNATTRACTIVE:
        return 'Keine Erstattung/Marge';
      case TAXCASESTATUS_REJECT.WRONG_TARGETGROUP:
        return 'Falsche Zielgruppe';

      default:
        throw new Error('Unknown status');
    }
  }
}

export class TaxFormCalculation {
  public taxform: TaxForm | null = null;
  public calculationData: CalculationData2 | null = null;
}
export class TaxCaseStatus {
  public static getTemplate(): TaxCaseStatus {
    return {
      current: TAXCASESTATUS.ENTRY,
      substatus: null,
      archived: false,
    };
  }
  archived?: boolean = false;
  current: TAXCASESTATUS = TAXCASESTATUS.ENTRY;
  substatus: TAXCASESTATUS_REJECT | TAXCASESTATUS_INPROGRESS | null = null;
}

export class TaxCaseTaxform {
  public static getTemplate(): TaxCaseTaxform {
    return {
      current: TaxCaseTaxformData.getTemplate(),
      original: TaxCaseTaxformData.getTemplate(),
    };
  }
  public current: TaxCaseTaxformData = TaxCaseTaxformData.getTemplate();
  public original: TaxCaseTaxformData = TaxCaseTaxformData.getTemplate();
}

export class TaxCaseTaxformData {
  public static getTemplate(): TaxCaseTaxformData {
    return {
      taxForm: TaxForm.getTemplate(),
      calculation: null,
    };
  }
  /**
   * The amount of taxreturn estimated by our employees
   */
  expectedTaxReturn?: number | null;
  /**
   * Taxreturn based on taxoffice document "real taxreturn"
   * DE : Abweichender Betrag nach Bescheid vom Finanzamt
   * */
  differentTaxReturn?: number;
  differentTaxReturnReasons?: string[]; // Gründe für Abweichung => Texte
  officialNoticeReturnDate?: firebase.firestore.Timestamp | null = null; // Datum des Bescheids vom Finanzamt
  taxForm: TaxForm = TaxForm.getTemplate();
  calculation: CalculationData2 | null = CalculationData2.getTemplate();
  client?: Client;
}

export class NecessaryItem {
  public static getTemplate(): NecessaryItem {
    return {
      taxId: '', // => /client/ID/taxIds/ID
      eTin: '', // => /client/ID/taxsettings/{year}
      taxClass: '',
      wage: 0.0, // --
      incomeTax: 0.0, // --
      solidarityTax: 0.0, // --
      churchTax: 0.0, // --
    };
  }
  /**
   * "SteuerID" of the client
   */
  public taxId = '';
  public eTin = '';
  /**
   * @deprecated use job.taxClass
   */
  public taxClass = '';
  /**
   * Wage for the full year of the client. This has to come from the "Lohnsteuerbescheinigung"
   * @deprecated We now correct the wage of the client directly instead of setting it here wagestate sment
   */
  public wage = 0.0;
  /**
   * @deprecated use direct on job wagestatement
   */
  public incomeTax = 0.0;
  /**
   * @deprecated use direct on job wagestatement
   */
  public solidarityTax = 0.0;
  /**
   * @deprecated use direct on job wagestatement
   */
  public churchTax = 0.0;
}

export class TaxCaseMetadata {
  public static getTemplate(): TaxCaseMetadata {
    return {
      isTest: false,
      forced: false,
      assigned: null,
      updated: null,
      environment: EnvironmentDetails.getTemplate(),
      status: TaxCaseStatus.getTemplate(),
      domain: '',
      created: null,
      changed: null,
      isCopy: false,

      taxadvisor: TaxAdvisor.getTaxadvisorShort(TaxAdvisor.getTemplate()),
      rejectReasons: [],
      deadline: null,
      taxadvisorBankAccount: null,
      selfSubmit: false,
    };
  }
  public product?: PRODUCTTYPE = PRODUCTTYPE.ONSUCCESS;
  public process?: TAXCASEPROCESS;
  public payment?: Payment | null = null;
  public isTest = false;
  public isCopy? = false;
  /**
   * Is forced to to his tax Statement
   * DE : Zur Abgabe der Steuererklärung verpflichtet
   */
  forced?: boolean = false;

  /**
   * @deprecated use taxsettings.taxAdvisorId instead
   */
  public taxadvisor: TaxAdvisorShort = TaxAdvisor.getTaxadvisorShort(
    TaxAdvisor.getTemplate()
  );
  /**
   * The bank account from the taxadvisor used for this transaction
   * This is where the money returns from the finance department.
   * If the money should flow to the customer directly, use the customer bank information
   */
  public taxadvisorBankAccount?: TaxAdvisorBankAccountShort | null =
    TaxAdvisorBankAccountShort.getTemplate();

  public assigned: User | null = null;
  public updated: User | null = null;

  public environment: EnvironmentDetails = EnvironmentDetails.getTemplate();
  public domain = '';
  /**
   * @deprecated use metadata
   */
  public system?: { domain: '' } = { domain: '' }; // DEPRECATED TODO: REMOVE
  public created: firebase.firestore.Timestamp | null = null;
  public changed: firebase.firestore.Timestamp | null = null;
  public isTaxOfficeTestSent?: boolean = false;
  public isTaxOfficeProdSent?: boolean = false;
  /**
   * @deprecated this is not used anymore. Will be replaced with Tasks
   */
  public hasOpenTasks?: boolean = true; // Temporär, Manueller switch ob es noch Todos gibt auch wenn das System keine kennt.
  public status: TaxCaseStatus = TaxCaseStatus.getTemplate(); // NEW STATUS
  /**
   * Defines the tenant. If null = expresssteuer
   */
  public tenant?: Tenant | null;

  public rejectReasons?: Reason[] = [];
  /**
   * German : Frist
   * The enddate the taxcase has to be at the taxoffice+
   */
  public deadline?: Timestamp | null = null;

  /**
   * Date when to send to the taxoffice (automatically)
   */
  public sendToTaxoffice?: Timestamp | null = null;

  /**
   * wheter this taxcase is submitted via exp sw cert and does not contain info about taxadvisors work
   * Defaults to false but will be loaded from client on create or when entering test prepare state
   */
  public selfSubmit?: boolean = false;
}

export class TaxCase {
  /**
   * Build a TaxCase ID
   * @param partner The short company short name from environment
   * @param year The year of reimbursement the taxcase is for
   * @param type N = New, C = Copy, R = Retension
   * @param created Created Timestamp provide now or the timestamp when to create, if you date back Use client or admin timestamp accordingly
   * @returns a unique taxcase id
   * @example newId = TaxCase.id(taxcase.environment.companyShort, 2020, 'C', Timestamp.now());
   */
  public static id(
    partner = 'EXPS',
    year = 2020,
    type: 'N' | 'C' | 'R' = 'C',
    created: Timestamp
  ): string {
    const datecode =
      ((created.toDate().getUTCFullYear().toString().substr(2, 2) +
        (created.toDate().getUTCMonth() + 1)) as string) +
      created.toDate().getUTCDate().toString();
    //unique guid string
    const unique = Math.random().toString(36).substring(2, 8);
    const id = `${partner}-${year}${type}-${datecode}${unique}`;
    return id;
  }

  id = '';

  notifications: ClientNotification[] = [];

  migration?: FirestoreMigration;

  client: ClientShort = ClientShort.getTemplate();
  year = '';
  /**
   * @deprecated this is not used anymore. Use the taxcase.id instead
   */
  caseId? = ''; // DEPRECATED => use id instead
  //status: CASESTATUS = CASESTATUS.ENTRY;  // DEPRECATET OBSOLETE STATE // states : rejected, entry, waiting, paid, sent, received, finished
  metadata: TaxCaseMetadata = TaxCaseMetadata.getTemplate();

  taxform: TaxCaseTaxform = TaxCaseTaxform.getTemplate();

  optimizationIndicators?: TaxcaseOptimizationIndicators;

  public static getTemplate(): TaxCase {
    return {
      id: '',
      notifications: [],
      client: ClientShort.getTemplate(),
      year: '',
      caseId: '',
      // client: Client.getTemplate(),
      // status: CASESTATUS.ENTRY,
      // states : rejected, entry, waiting, paid, sent, received, finished
      metadata: TaxCaseMetadata.getTemplate(),
      taxform: TaxCaseTaxform.getTemplate(),
      optimizationIndicators: TaxcaseOptimizationIndicators.getTemplate(),
    };
  }
}

export abstract class TaxcaseOptimizationIndicators {
  static getTemplate(
    options?: Partial<TaxcaseOptimizationIndicators>
  ): TaxcaseOptimizationIndicators {
    return {
      isClientExpensesBasedOnJobGroupsOptimized: false,
      isPartnerExpensesBasedOnJobGroupsOptimized: false,
      ...options,
    };
  }

  abstract isClientExpensesBasedOnJobGroupsOptimized?: boolean;
  abstract isPartnerExpensesBasedOnJobGroupsOptimized?: boolean;
}
