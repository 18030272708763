export namespace Elster2020E10 {
  export type ElsterPerson = 'PersonA' | 'PersonB';
  export type UnterstuetztePerson = any;
  export type LaufendeNummerBetrieb = any;

  export interface I13a {
    /**
     * Ermittlung_des_Gewinns_aus_Land-_und_Forstwirtschaft_nach_Durchschnittssätzen_(§_13a_EStG)_für_das_Wirtschaftsjahr
     * @maxLength: 9
     */
    Anl_13a: {
      Laufende_Nummer_Betrieb: LaufendeNummerBetrieb;
      /**
       * Allgemeine_Angaben_zum_Betrieb
       * @maxLength: 1
       */
      Allg_Betr: {
        /**
         * (Betriebs-)Steuernummer
         */
        E2940301: string;
        /**
         * Beginn_des_Wirtschaftsjahres
         */
        E2940401: string;
        /**
         * Ende_des_Wirtschaftsjahres
         */
        E2940402: string;
        /**
         * Rechtsform_des_Betriebs
         */
        E2940501: string;
        /**
         * Betriebsinhaber
         */
        E2940601: string;
      }[];
      /**
       * 1._Gewinnermittlung
       * @maxLength: 1
       */
      Gew_Erm: {
        /**
         * Landwirtschaftliche_Nutzung
         * @maxLength: 1
         */
        Landw_Ntz: {
          /**
           * Grundbetrag_für_die_selbst_bewirtschafteten_Flächen_der_landwirtschaftlichen_Nutzung
           * @maxLength: 1
           */
          Gbetr_sbew_Fl: {
            /**
             * Eigentumsflächen_des_Betriebsvermögens,_die_am_15._Mai_des_Wirtschaftsjahres_der_landwirtschaftlichen_Nutzung_dienen_(ohne_Sondernutzungen)
             */
            E2940701: string;
            /**
             * Hof-_/_Gebäudeflächen_(ohne_Grund_und_Boden_für_Wohngebäude),_die_am_15._Mai_des_Wirtschaftsjahres_der_landwirtschaftlichen_Nutzung_dienen_oder_ihr_zugeordnet_werden
             */
            E2940801: string;
            /**
             * In_den_Zeilen_7_und_8_nicht_enthaltene,_am_15._Mai_des_Wirtschaftsjahres_zugepachtete_oder_unentgeltlich_von_Dritten_überlassene_Flächen_der_landwirtschaftlichen_Nutzung
             */
            E2940901: string;
            /**
             * In_den_Zeilen_7_bis_9_enthaltene,_am_15._Mai_des_Wirtschaftsjahres_verpachtete_oder_unentgeltlich_an_Dritte_überlassene_Flächen_der_landwirtschaftlichen_Nutzung
             */
            E2941001: string;
            /**
             * Selbst_bewirtschaftete_Flächen_der_landwirtschaftlichen_Nutzung_am_15._Mai_des_Wirtschaftsjahres
             */
            E2941101: string;
            /**
             * Grundbetrag_für_die_selbst_bewirtschafteten_Flächen_der_landwirtschaftlichen_Nutzung_(Selbst_bewirtschaftete_Flächen_der_landwirtschaftlichen_Nutzung_am_15._Mai_des_Wirtschaftsjahres_laut_Zeile_11_*_350_EUR_/_ha)
             */
            E2941301: string;
          }[];
          /**
           * Zuschlag_für_Tierzucht_und_Tierhaltung
           * @maxLength: 1
           */
          Zuschl_Tier: {
            /**
             * Tierbestand_des_Betriebs_-_Gesamtsumme_der_Vieheinheiten_(VE)_laut_Anlage_L_Zeile_86_(gegebenenfalls_\"0\")
             */
            E2941401: string;
            /**
             * Im_Rahmen_von_Tätigkeiten_laut_Zeile_62_berücksichtigter_Tierbestand_-_VE_-
             */
            E2941501: string;
            /**
             * Maßgebender_Tierbestand_-_VE_-_(Zeile_14_abzüglich_Zeile_15)
             */
            E2941601: string;
            /**
             * Für_den_Zuschlag_für_Tierzucht_und_Tierhaltung_maßgebender_Tierbestand_-_VE_-_(Maßgebender_Tierbestand_in_Vieheinheiten_laut_Zeile_16_abzüglich_25,00_Vieheinheiten)
             */
            E2941801: string;
            /**
             * Zuschlag_für_Tierzucht_und_Tierhaltung_(Für_den_Zuschlag_für_Tierzucht_und_Tierhaltung_maßgebender_Tierbestand_laut_Zeile_18_*_300_EUR_/_Vieheinheit)
             */
            E2942001: string;
            /**
             * Gewinn_der_landwirtschaftlichen_Nutzung_(Zeile_13_zuzüglich_Zeile_20;_Übertrag_in_Zeile_76)
             */
            E2942101: string;
          }[];
        }[];

        /**
         * (nach_§_51_EStDV_ermittelter_Gewinn)
         * @maxLength: 1
         */
        Forst_Ntz: {
          /**
           * Selbst_bewirtschaftete_Flächen_der_forstwirtschaftlichen_Nutzung_am_15._Mai_des_Wirtschaftsjahres
           */
          E2942201: string;
          /**
           * Einnahmen
           * @maxLength: 1
           */
          Einn: {
            /**
             * Verwertung_von_Holz_auf_dem_Stamm
             */
            E2942301: string;
            /**
             * Verwertung_von_eingeschlagenem_Holz
             */
            E2942401: string;
            /**
             * Ergebnis_Holznutzungen
             */
            E2942501: string;
            /**
             * Übrige_Forstwirtschaft
             */
            E2942601: string;
          }[];
          /**
           * Pauschale_Betriebsausgaben
           * @maxLength: 1
           */
          BA_Pausch: {
            /**
             * Verwertung_von_Holz_auf_dem_Stamm_(20_Prozent)
             */
            E2942302: string;
            /**
             * Verwertung_von_eingeschlagenem_Holz_(55_Prozent)
             */
            E2942402: string;
            /**
             * Ergebnis_Holznutzungen
             */
            E2942502: string;
          }[];
          /**
           * Gesondert_abziehbare_Betriebsausgaben
           * @maxLength: 1
           */
          BA_ges_abz: {
            /**
             * Ergebnis_Holznutzungen
             */
            E2942503: string;
            /**
             * Übrige_Forstwirtschaft
             */
            E2942602: string;
          }[];
          /**
           * Gewinn
           * @maxLength: 1
           */
          Gew: {
            /**
             * Ergebnis_Holznutzungen
             */
            E2942504: string;
            /**
             * Übrige_Forstwirtschaft
             */
            E2942603: string;
            /**
             * Gewinn_der_forstwirtschaftlichen_Nutzung_(Übertrag_in_Zeile_77)
             */
            E2942701: string;
          }[];
        }[];

        /**
         * Sondernutzungen_nach_Anlage_1a_Nummer_2_zu_§_13a_EStG_(pauschaler_Gewinn_nach_§_13a_Absatz_6_EStG)
         * @maxLength: 1
         */
        Sond_Nutz: {
          /**
           * Weinbau
           * @maxLength: 1
           */
          WB: {
            /**
             * Am_15._Mai_des_Wirtschaftsjahres_selbst_bewirtschaftete_Fläche
             */
            E2943101: string;
            /**
             * Gewinn_der_Sondernutzung
             */
            E2943102: string;
          }[];
          /**
           * Obstbau
           * @maxLength: 1
           */
          Obst: {
            /**
             * Am_15._Mai_des_Wirtschaftsjahres_selbst_bewirtschaftete_Fläche
             */
            E2943103: string;
            /**
             * Gewinn_der_Sondernutzung
             */
            E2943104: string;
          }[];
          /**
           * Gemüsebau_Freiland
           * @maxLength: 1
           */
          Gem_FL: {
            /**
             * Am_15._Mai_des_Wirtschaftsjahres_selbst_bewirtschaftete_Fläche
             */
            E2943201: string;
            /**
             * Gewinn_der_Sondernutzung
             */
            E2943202: string;
          }[];
          /**
           * Gemüsebau_Unterglas
           * @maxLength: 1
           */
          Gem_UG: {
            /**
             * Am_15._Mai_des_Wirtschaftsjahres_selbst_bewirtschaftete_Fläche
             */
            E2943203: string;
            /**
             * Gewinn_der_Sondernutzung
             */
            E2943204: string;
          }[];
          /**
           * Blumen_/_Zierpflanzenbau_Freiland
           * @maxLength: 1
           */
          Blum_FL: {
            /**
             * Am_15._Mai_des_Wirtschaftsjahres_selbst_bewirtschaftete_Fläche
             */
            E2943301: string;
            /**
             * Gewinn_der_Sondernutzung
             */
            E2943302: string;
          }[];
          /**
           * Blumen_/_Zierpflanzenbau_Unterglas
           * @maxLength: 1
           */
          Blum_UG: {
            /**
             * Am_15._Mai_des_Wirtschaftsjahres_selbst_bewirtschaftete_Fläche
             */
            E2943303: string;
            /**
             * Gewinn_der_Sondernutzung
             */
            E2943304: string;
          }[];
          /**
           * Baumschulen
           * @maxLength: 1
           */
          Baum: {
            /**
             * Am_15._Mai_des_Wirtschaftsjahres_selbst_bewirtschaftete_Fläche
             */
            E2943401: string;
            /**
             * Gewinn_der_Sondernutzung
             */
            E2943402: string;
          }[];
          /**
           * Hopfenbau
           * @maxLength: 1
           */
          Hopfen: {
            /**
             * Am_15._Mai_des_Wirtschaftsjahres_selbst_bewirtschaftete_Fläche
             */
            E2943403: string;
            /**
             * Gewinn_der_Sondernutzung
             */
            E2943404: string;
          }[];
          /**
           * Teichwirtschaft
           * @maxLength: 1
           */
          Teichw: {
            /**
             * Am_15._Mai_des_Wirtschaftsjahres_selbst_bewirtschaftete_Fläche
             */
            E2943501: string;
            /**
             * Gewinn_der_Sondernutzung
             */
            E2943502: string;
          }[];
          /**
           * Spargelbau
           * @maxLength: 1
           */
          Spargel: {
            /**
             * Am_15._Mai_des_Wirtschaftsjahres_selbst_bewirtschaftete_Fläche
             */
            E2943503: string;
            /**
             * Gewinn_der_Sondernutzung
             */
            E2943504: string;
          }[];
          /**
           * Fischzucht_für_Binnenfischerei_und_Teichwirtschaft
           * @maxLength: 1
           */
          Fisch_Z: {
            /**
             * Am_15._Mai_des_Wirtschaftsjahres_selbst_bewirtschaftete_Fläche
             */
            E2943601: string;
            /**
             * Gewinn_der_Sondernutzung
             */
            E2943602: string;
          }[];
          /**
           * Weihnachtsbaumkulturen
           * @maxLength: 1
           */
          WB_Kul: {
            /**
             * Am_15._Mai_des_Wirtschaftsjahres_selbst_bewirtschaftete_Fläche
             */
            E2943603: string;
            /**
             * Gewinn_der_Sondernutzung
             */
            E2943604: string;
          }[];
          /**
           * Binnenfischerei
           * @maxLength: 1
           */
          Binnen_F: {
            /**
             * Jahresfang_in_kg
             */
            E2943701: string;
            /**
             * Gewinn_der_Sondernutzung
             */
            E2943702: string;
          }[];
          /**
           * Imkerei
           * @maxLength: 1
           */
          Imker: {
            /**
             * Anzahl_der_Völker
             */
            E2943703: string;
            /**
             * Gewinn_der_Sondernutzung
             */
            E2943704: string;
          }[];
          /**
           * Wanderschäferei
           * @maxLength: 1
           */
          Schaef: {
            /**
             * Anzahl_der_Mutterschafe
             */
            E2943801: string;
            /**
             * Gewinn_der_Sondernutzung
             */
            E2943802: string;
          }[];
          /**
           * Zwischensumme
           * @maxLength: 1
           */
          Zwi_SN: {
            /**
             * Zwischensumme_(Summe_der_Gewinne_der_Zeilen_31_bis_38)
             */
            E2943901: string;
          }[];

          /**
           * (nach_§_4_Absatz_3_EStG_ermittelter_Gewinn)
           * @maxLength: 1
           */
          Weit_SN: {
            /**
             * Tabakanbau
             */
            E2944001: string;
            /**
             * Kurzumtriebsplantagen
             */
            E2944101: string;
            /**
             * Sonstige_weitere_Sondernutzungen
             * @maxLength: 2
             */
            Sonst: {
              /**
               * Art
               */
              E2944201: string;
              /**
               * Betrag
               */
              E2944202: string;
            }[];
          }[];
          /**
           * Gewinn_der_Sondernutzungen
           * @maxLength: 1
           */
          Gew: {
            /**
             * Gewinn_der_Sondernutzungen_(Ergebnis_der_Zeilen_39_bis_42;_Übertrag_in_Zeile_78)
             */
            E2944401: string;
          }[];
        }[];
        /**
         * Sondergewinne
         * @maxLength: 1
         */
        Sond_Gew: {
          /**
           * Gewinne_aus_der_Veräußerung_/_Entnahme_von_Grund_und_Boden,_dazugehörigem_Aufwuchs,_Gebäuden,_immateriellen_Wirtschaftsgütern_und_Beteiligungen
           * @maxLength: 1
           */
          Verae_Ent_GuB_etc: {
            /**
             * Veräußerungspreis_/_Entnahmewert
             */
            E2944501: string;
            /**
             * davon_abziehbare_Betriebsausgaben
             */
            E2944601: string;
            /**
             * Gewinn
             */
            E2944602: string;
          }[];
          /**
           * Gewinne_aus_der_Veräußerung_/_Entnahme_von_übrigen_Wirtschaftsgütern_des_Anlagevermögens_und_von_Tieren_(Veräußerungspreis_/_Entnahmewert_je_Wirtschaftsgut_brutto_mehr_als_15.000_EUR)
           * @maxLength: 1
           */
          Verae_Ent_ueb_WG: {
            /**
             * Veräußerungspreis_/_Entnahmewert
             */
            E2944701: string;
            /**
             * davon_abziehbare_Betriebsausgaben
             */
            E2944801: string;
            /**
             * Gewinn
             */
            E2944802: string;
          }[];
          /**
           * Gewinne_aus_Entschädigungen_für_den_Verlust,_den_Untergang_oder_die_Wertminderung_der_in_den_Zeilen_45_bis_48_genannten_Wirtschaftsgüter
           * @maxLength: 1
           */
          Entschae: {
            /**
             * Entschädigungen
             */
            E2944901: string;
            /**
             * davon_abziehbare_Betriebsausgaben
             */
            E2945001: string;
            /**
             * Gewinn
             */
            E2945002: string;
          }[];
          /**
           * Weitere_Sondergewinne
           * @maxLength: 1
           */
          Weitere1: {
            /**
             * Bildung_von_Rücklagen,_Übertragung_von_stillen_Reserven_(§_6c_EStG,_R_6.6_EStR;_Übertrag_aus_Zeile_87)
             */
            E2945101: string;
            /**
             * Gewinne_aus_der_Auflösung_von_Rücklagen_(§_6c_EStG,_R_6.6_EStR;_Übertrag_aus_Zeile_87)
             */
            E2945201: string;
            /**
             * Betriebseinnahmen_nach_§_9b_Absatz_2_EStG
             */
            E2945301: string;
            /**
             * Betriebsausgaben_nach_§_9b_Absatz_2_EStG
             */
            E2945401: string;
          }[];
          /**
           * Einnahmen_aus_dem_Grunde_nach_gewerblichen_Tätigkeiten,_die_der_Land-_und_Forstwirtschaft_zugerechnet_werden
           * @maxLength: 1
           */
          Gewe_Taet: {
            /**
             * Einnahmen_aus_dem_Absatz_von_Erzeugnissen_gemäß_R_15.5_Absatz_3_bis_8_EStR
             */
            E2946101: string;
            /**
             * Einnahmen_aus_Dienstleistungen_gemäß_R_15.5_Absatz_9_und_10_EStR
             */
            E2946201: string;
            /**
             * Summe_der_Einnahmen
             */
            E2946301: string;
            /**
             * Pauschale_Betriebsausgaben_60_Prozent
             */
            E2946401: string;
            /**
             * Gewinn
             */
            E2946402: string;
            /**
             * Nachrichtlich:_Einnahmen_des_Betriebs_im_Sinne_der_R_15.5_Absatz_11_EStR_(nur_erforderlich,_wenn_die_Zeilen_61_bis_63_auszufüllen_sind)
             */
            E2946501: string;
          }[];
          /**
           * Weitere_Sondergewinne
           * @maxLength: 1
           */
          Weitere2: {
            /**
             * Rückvergütungen_nach_§_22_KStG_aus_Hilfs-_und_Nebengeschäften
             */
            E2946601: string;
            /**
             * Sondergewinne_(Ergebnis_der_Zeilen_46_bis_66;_Übertrag_in_Zeile_79)
             */
            E2946701: string;
          }[];
        }[];
        /**
         * Einnahmen_aus_Vermietung_und_Verpachtung_von_Wirtschaftsgütern_des_Betriebsvermögens
         * @maxLength: 1
         */
        VuV_WG_BV: {
          /**
           * Einnahmen_aus_Vermietung_von_bebauten_Grundstücken
           */
          E2946801: string;
          /**
           * Einnahmen_aus_Vermietung_und_Verpachtung_von_unbebauten_Grundstücken
           */
          E2946901: string;
          /**
           * Einnahmen_aus_Vermietung_und_Verpachtung_von_übrigen_Wirtschaftsgütern
           */
          E2947001: string;
          /**
           * Einnahmen_aus_Vermietung_und_Verpachtung_von_Wirtschaftsgütern_des_land-_und_forstwirtschaftlichen_Betriebsvermögens_(Übertrag_in_Zeile_80)
           */
          E2947101: string;
        }[];
        /**
         * Einnahmen_aus_Kapitalvermögen,_soweit_zum_land-_und_forstwirtschaftlichen_Betrieb_gehörend
         * @maxLength: 1
         */
        KapV_Betr: {
          /**
           * Einnahmen_aus_Kapitalvermögen,_die_nach_§_3_Nummer_40_EStG_oder_§_8b_KStG_(teilweise)_steuerfrei_sind_(Eintrag_in_voller_Höhe)
           */
          E2947201: string;
          /**
           * Nach_§_3_Nummer_40_EStG_oder_§_8b_KStG_steuerfreier_Teil
           */
          E2947301: string;
          /**
           * Überschuss
           */
          E2947302: string;
          /**
           * Einnahmen_aus_Kapitalvermögen,_die_in_voller_Höhe_steuerpflichtig_sind
           */
          E2947401: string;
          /**
           * Einnahmen_aus_Kapitalvermögen,_soweit_sie_zu_den_Einkünften_aus_Land-_und_Forstwirtschaft_gehören_(§_20_Absatz_8_EStG;_Übertrag_in_Zeile_81)
           */
          E2947501: string;
        }[];
        /**
         * Ermittlung_des_Gewinns_nach_Durchschnittssätzen
         * @maxLength: 1
         */
        Erm_Gew_DSS: {
          /**
           * Gewinn_der_landwirtschaftlichen_Nutzung_(Übertrag_aus_Zeile_21)
           */
          E2947601: string;
          /**
           * Gewinn_der_forstwirtschaftlichen_Nutzung_(Übertrag_aus_Zeile_27)
           */
          E2947701: string;
          /**
           * Gewinn_der_Sondernutzungen_(Übertrag_aus_Zeile_44)
           */
          E2947801: string;
          /**
           * Sondergewinne_(Übertrag_aus_Zeile_67)
           */
          E2947901: string;
          /**
           * Einnahmen_aus_Vermietung_und_Verpachtung_von_Wirtschaftsgütern_des_land-_und_forstwirtschaftlichen_Betriebsvermögens_(Übertrag_aus_Zeile_71)
           */
          E2948001: string;
          /**
           * Einnahmen_aus_Kapitalvermögen,_soweit_sie_zu_den_Einkünften_aus_Land-_und_Forstwirtschaft_gehören_(Übertrag_aus_Zeile_75)
           */
          E2948101: string;
        }[];
        /**
         * Gewinn_nach_§_13a_EStG
         * @maxLength: 1
         */
        Gew_P13a: {
          /**
           * Nach_Durchschnittssätzen_ermittelter_Gewinn_(§_13a_EStG)_für_das_Wirtschaftsjahr_$VZ$_/_$VZ+1$_($VZ$)
           */
          E2948201: string;
          /**
           * Hinzurechnungen_und_Abrechnungen_bei_Wechsel_der_Gewinnermittlungsart_(Bezeichnung)
           */
          E2948304: string;
          /**
           * Hinzurechnungen_und_Abrechnungen_bei_Wechsel_der_Gewinnermittlungsart_(Betrag)
           */
          E2948303: string;
          /**
           * Steuerpflichtiger_Gewinn
           */
          E2948402: string;
        }[];
      }[];
      /**
       * 2._Ergänzende_Angaben
       * @maxLength: 1
       */
      Erg_Ang: {
        /**
         * Rücklagen_und_stille_Reserven
         * @maxLength: 1
         */
        Rueckl_st_R: {
          /**
           * Bildung_/_Übertragung
           * @maxLength: 1
           */
          Bild_Ueb: {
            /**
             * Rücklagen_nach_§_6c_in_Verbindung_mit_§_6b_EStG,_R_6.6_EStR
             */
            E2948301: string;
            /**
             * Übertragung_von_stillen_Reserven_nach_§_6c_in_Verbindung_mit_§_6b_EStG,_R_6.6_EStR
             */
            E2948401: string;
            /**
             * Gesamtsumme
             */
            E2948501: string;
          }[];
          /**
           * Auflösung
           * @maxLength: 1
           */
          Aufloes: {
            /**
             * Rücklagen_nach_§_6c_in_Verbindung_mit_§_6b_EStG,_R_6.6_EStR
             */
            E2948302: string;
            /**
             * Gesamtsumme
             */
            E2948502: string;
          }[];
          /**
           * Erläuterungen
           * @maxLength: 1
           */
          Erlaeut: {
            /**
             * Erläuterungen_zur_Bildung_/_Übertragung_/_Auflösung_von_Rücklagen_und_stillen_Reserven
             */
            E2948503: string;
          }[];
        }[];
        /**
         * Außerordentliche_Holznutzungen
         * @maxLength: 1
         */
        Ao_HNtz: {
          /**
           * Tarifbegünstigte_Einkünfte_aus_außerordentlichen_Holznutzungen_(§_34b_EStG)_sind_in_der_Anlage_34b_erklärt
           */
          E2948601: string;
        }[];
      }[];
    }[];
  }
  export interface I34b {
    /**
     * Einkünfte_aus_außerordentlichen_Holznutzungen_nach_§_34b_EStG
     * @maxLength: 99
     */
    Anl_34b: {
      /**
       * Bezeichnung_des_Betriebs
       */
      E0940401: string;
      /**
       * Berechnungsgrundlagen_zur_Ermittlung_der_Einkünfte_aus_außerordentlichen_Holznutzungen_nach_§_34b_EStG
       * @maxLength: 1
       */
      Ber_Grdl_P34b: {
        /**
         * Nutzungssatz
         * @maxLength: 1
         */
        Nutz_Satz: {
          /**
           * Nutzungssatz_(cbm/F)
           */
          E0910701: string;
          /**
           * von_der_Finanzbehörde_festgesetzt_für_den_Zeitraum_vom_-_bis
           */
          E0910602: string;
          /**
           * pauschal_mit_5_cbm/F_je_Hektar
           */
          E0910702: string;
          /**
           * forstwirtschaftlich_genutzte_Fläche_(ha)
           */
          E0910703: string;
        }[];
        /**
         * Holznutzungen,_die_aus_volks-_/_staatswirtschaftlichen_Gründen_erfolgten
         * @maxLength: 1
         */
        H_Nutz_st_vw: {
          /**
           * Im_Wirtschaftsjahr_$VZ$_/_$VZ+1$_($VZ$)_wurden_folgende_Holznutzungen_verwertet:
           */
          E0910102: string;
          /**
           * Erläuterung_der_im_Wirtschaftsjahr_$VZ$_/_$VZ+1$_($VZ$)_verwerteten_Holznutzungen
           */
          E0910103: string;
        }[];
        /**
         * Holznutzungen_infolge_höherer_Gewalt
         * @maxLength: 1
         */
        H_Nutz_Hoeher: {
          /**
           * Wirtschaftsjahr_des_Nachweises_$VZ$_/_$VZ+1$_($VZ$)
           * @maxLength: 1
           */
          WJ: {
            /**
             * Anerkennung_der_Finanzbehörde_vom
             */
            E0910404: string;
            /**
             * anerkannte_Holzmenge_im_Ganzen_(cbm/F)
             */
            E0910405: string;
            /**
             * davon_besondere_Schadensereignisse_(cbm/F)
             */
            E0910406: string;
            /**
             * verwertete_Holzmenge_im_laufenden_Wirtschaftsjahr_$VZ$_/_$VZ+1$_($VZ$)_(cbm/F)
             */
            E0910407: string;
            /**
             * verbleibende_Holzmenge_(cbm/F)
             */
            E0910408: string;
          }[];
          /**
           * Wirtschaftsjahr_des_Nachweises_$VZ-1$_/_$VZ$_($VZ-1$)
           * @maxLength: 1
           */
          WJ_M1: {
            /**
             * Anerkennung_der_Finanzbehörde_vom
             */
            E0910504: string;
            /**
             * anerkannte_Holzmenge_im_Ganzen_(cbm/F)
             */
            E0910505: string;
            /**
             * davon_besondere_Schadensereignisse_(cbm/F)
             */
            E0910506: string;
            /**
             * verwertete_Holzmenge_in_Vorjahren_(cbm/F)
             */
            E0910507: string;
            /**
             * verwertete_Holzmenge_im_laufenden_Wirtschaftsjahr_$VZ$_/_$VZ+1$_($VZ$)_(cbm/F)
             */
            E0910508: string;
            /**
             * verbleibende_Holzmenge_(cbm/F)
             */
            E0910509: string;
          }[];
          /**
           * Wirtschaftsjahr_des_Nachweises_$VZ-2$_/_$VZ-1$_($VZ-2$)
           * @maxLength: 1
           */
          WJ_M2: {
            /**
             * Anerkennung_der_Finanzbehörde_vom
             */
            E0910604: string;
            /**
             * anerkannte_Holzmenge_im_Ganzen_(cbm/F)
             */
            E0910605: string;
            /**
             * davon_besondere_Schadensereignisse_(cbm/F)
             */
            E0910606: string;
            /**
             * verwertete_Holzmenge_in_Vorjahren_(cbm/F)
             */
            E0910607: string;
            /**
             * verwertete_Holzmenge_im_laufenden_Wirtschaftsjahr_$VZ$_/_$VZ+1$_($VZ$)_(cbm/F)
             */
            E0910608: string;
            /**
             * verbleibende_Holzmenge_(cbm/F)
             */
            E0910609: string;
          }[];
          /**
           * Wirtschaftsjahr_des_Nachweises_$VZ-3$_/_$VZ-2$_($VZ-3$)_und_früher
           * @maxLength: 1
           */
          WJ_M3: {
            /**
             * Anerkennung_der_Finanzbehörde_vom
             */
            E0910704: string;
            /**
             * anerkannte_Holzmenge_im_Ganzen_(cbm/F)
             */
            E0910705: string;
            /**
             * davon_besondere_Schadensereignisse_(cbm/F)
             */
            E0910706: string;
            /**
             * verwertete_Holzmenge_in_Vorjahren_(cbm/F)
             */
            E0910707: string;
            /**
             * verwertete_Holzmenge_im_laufenden_Wirtschaftsjahr_$VZ$_/$VZ+1$_($VZ$)_(cbm/F)
             */
            E0910708: string;
            /**
             * verbleibende_Holzmenge_(cbm/F)
             */
            E0910709: string;
          }[];
          /**
           * Summe
           * @maxLength: 1
           */
          Gesamt: {
            /**
             * Summe_(verwertete_Holzmenge_im_laufenden_Wirtschaftsjahr)_(cbm/F)
             */
            E0910807: string;
          }[];
        }[];
        /**
         * Besondere_Schadensereignisse_(§_34b_Absatz_5_EStG,_R_34b.7_EStR)
         * @maxLength: 1
         */
        Bes_Schad_E: {
          /**
           * Wirtschaftsjahr_$VZ-3$_/_$VZ-2$_($VZ-3$)_und_früher
           * @maxLength: 1
           */
          WJ_M3: {
            /**
             * Verbliebenes_Begünstigungsvolumen_(cbm/F)
             */
            E0910901: string;
            /**
             * aus_besonderen_Schadensereignissen_anerkannt_(cbm/F)
             */
            E0911001: string;
            /**
             * Zwischensumme_(Begünstigungsvolumen_-_cbm/F)
             */
            E0911104: string;
            /**
             * im_Wirtschaftsjahr_insgesamt_verwertete_Holznutzung_infolge_höherer_Gewalt_(cbm/F)
             */
            E0911202: string;
            /**
             * verbleibendes_Begünstigungsvolumen_(gegebenenfalls_\"0\"_-_cbm/F)
             */
            E0911302: string;
          }[];
          /**
           * Wirtschaftsjahr_$VZ-2$_/_$VZ-1$_($VZ-2$)
           * @maxLength: 1
           */
          WJ_M2: {
            /**
             * Verbliebenes_Begünstigungsvolumen_(cbm/F)
             */
            E0910902: string;
            /**
             * aus_besonderen_Schadensereignissen_anerkannt_(cbm/F)
             */
            E0911002: string;
            /**
             * Zwischensumme_(Begünstigungsvolumen_-_cbm/F)
             */
            E0911105: string;
            /**
             * im_Wirtschaftsjahr_insgesamt_verwertete_Holznutzung_infolge_höherer_Gewalt_(cbm/F)
             */
            E0911203: string;
            /**
             * verbleibendes_Begünstigungsvolumen_(gegebenenfalls_\"0\"_-_cbm/F)
             */
            E0911303: string;
          }[];
          /**
           * Wirtschaftsjahr_$VZ-1$_/_$VZ$_($VZ-1$)
           * @maxLength: 1
           */
          WJ_M1: {
            /**
             * Verbliebenes_Begünstigungsvolumen_(cbm/F)
             */
            E0910903: string;
            /**
             * aus_besonderen_Schadensereignissen_anerkannt_(cbm/F)
             */
            E0911003: string;
            /**
             * Zwischensumme_(Begünstigungsvolumen_-_cbm/F)
             */
            E0911106: string;
            /**
             * im_Wirtschaftsjahr_insgesamt_verwertete_Holznutzung_infolge_höherer_Gewalt_(cbm/F)
             */
            E0911204: string;
            /**
             * verbleibendes_Begünstigungsvolumen_(gegebenenfalls_\"0\"_-_cbm/F)
             */
            E0911304: string;
          }[];
          /**
           * Wirtschaftsjahr_$VZ$_/_$VZ+1$_($VZ$)
           * @maxLength: 1
           */
          WJ: {
            /**
             * Verbliebenes_Begünstigungsvolumen_(cbm/F)
             */
            E0910904: string;
            /**
             * aus_besonderen_Schadensereignissen_anerkannt_(cbm/F)
             */
            E0911004: string;
            /**
             * Zwischensumme_(Begünstigungsvolumen_-_cbm/F)
             */
            E0911107: string;
            /**
             * im_Wirtschaftsjahr_insgesamt_verwertete_Holznutzung_infolge_höherer_Gewalt_(cbm/F)
             */
            E0911205: string;
            /**
             * verbleibendes_Begünstigungsvolumen_(gegebenenfalls_\"0\"_-_cbm/F)
             */
            E0911305: string;
          }[];
        }[];
        /**
         * Maßgebende_Holznutzungen_infolge_höherer_Gewalt
         * @maxLength: 1
         */
        Mgeb_HNtz_h_G: {
          /**
           * Im_Wirtschaftsjahr_$VZ$_/_$VZ+1$_($VZ$)_nach_§_34b_Absatz_5_EStG,_R_34b.7_EStR_begünstigt
           */
          E0911404: string;
          /**
           * Im_Wirtschaftsjahr_$VZ$_/_$VZ+1$_($VZ$)_nach_§_34b_Absatz_3_EStG_begünstigt
           */
          E0911503: string;
        }[];
      }[];
      /**
       * Ermittlung_der_Einkünfte_aus_außerordentlichen_Holznutzungen_nach_§_34b_EStG
       * @maxLength: 1
       */
      Erm_Ek_P34b: {
        /**
         * Sämtliche_Holznutzungen
         * @maxLength: 1
         */
        HNtz_ges: {
          /**
           * Einnahmen_aus_der_Verwertung_sämtlicher_Holznutzungen
           */
          E0911101: string;
          /**
           * damit_in_sachlichem_Zusammenhang_stehende_Betriebsausgaben
           */
          E0911102: string;
          /**
           * Einkünfte_aus_sämtlichen_Holznutzungen
           */
          E0911103: string;
        }[];
        /**
         * Außerordentliche_Holznutzungen
         * @maxLength: 1
         */
        HNtz_aord: {
          /**
           * Gründe
           * @maxLength: 1
           */
          Grund: {
            /**
             * volks-_/_staatswirtschaftliche_Gründe_(cbm/F)
             */
            E0911201: string;
            /**
             * höhere_Gewalt_(ohne_besondere_Schadensereignisse_im_Sinne_des_§_34b_Absatz_5_EStG)_(cbm/F)
             */
            E0911301: string;
            /**
             * Summe_aus_volks-_und_staatswirtschaftlichen_Gründen_und_höherer_Gewalt_(cbm/F)
             */
            E0911401: string;
            /**
             * besondere_Schadensereignisse_(§_34b_Absatz_5_EStG)_(cbm/F)
             */
            E0911501: string;
          }[];
          /**
           * sämtliche_Holznutzungen
           * @maxLength: 1
           */
          HNtz_saemt: {
            /**
             * Maßgebende_Holznutzungen_(verwertete_Holzmengen)_(cbm/F)
             */
            E0911601: string;
            /**
             * Einkünfte_aus_den_Holznutzungen_des_Wirtschaftsjahrs_$VZ$_/_$VZ+1$_($VZ$)
             */
            E0911801: string;
          }[];
          /**
           * ordentliche_Holznutzung
           * @maxLength: 1
           */
          HNtz_ord: {
            /**
             * Maßgebende_Holznutzungen_(verwertete_Holzmengen)_(cbm/F)
             */
            E0911602: string;
            /**
             * Aufteilungsmaßstab_nach_dem_Verhältnis_der_Holzmengen_(maßgebende_Holznutzungen)
             */
            E0911701: string;
            /**
             * Einkünfte_aus_den_Holznutzungen_des_Wirtschaftsjahrs_$VZ$_/_$VZ+1$_($VZ$)
             */
            E0911802: string;
          }[];
          /**
           * Außerordentliche_Holznutzung_ohne_Nutzungssatz_/_bis_zur_Höhe_des_Nutzungssatzes
           * @maxLength: 1
           */
          Ao_o_Ntz_S: {
            /**
             * Summe_aus_volks-_und_staatswirtschaftlichen_Gründen_und_höherer_Gewalt_(cbm/F)
             */
            E0911402: string;
            /**
             * Maßgebende_Holznutzungen_(verwertete_Holzmengen)_(cbm/F)
             */
            E0911603: string;
            /**
             * Aufteilungsmaßstab_nach_dem_Verhältnis_der_Holzmengen_(maßgebende_Holznutzungen)
             */
            E0911702: string;
            /**
             * Einkünfte_aus_den_Holznutzungen_des_Wirtschaftsjahrs_$VZ$_/_$VZ+1$_($VZ$)
             */
            E0911803: string;
            /**
             * Einkünfte_aus_außerordentlichen_Holznutzungen_des_Wirtschaftsjahrs_$VZ$_/_$VZ+1$_($VZ$),_die_auf_das_Kalenderjahr_$VZ$_entfallen
             */
            E0911901: string;
            /**
             * Einkünfte_aus_außerordentlichen_Holznutzungen_des_Wirtschaftsjahrs_$VZ-1$_/_$VZ$,_die_auf_das_Kalenderjahr_$VZ$_entfallen
             */
            E0912001: string;
            /**
             * Einkünfte_aus_außerordentlichen_Holznutzungen_im_Kalenderjahr_$VZ$_laut_den_Zeilen_39_und_40
             */
            E0944101: string;
            /**
             * Einkünfte_aus_außerordentlichen_Holznutzungen_im_Kalenderjahr_$VZ$_laut_gesonderter_(und_einheitlicher)_Feststellung
             */
            E0944201: string;
            /**
             * Summe_der_Beträge_aus_Zeile_41_Spalte_3_und_Zeile_42_Spalte_3
             */
            E0912101: string;
          }[];
          /**
           * Außerordentliche_Holznutzung_über_dem_Nutzungssatz
           * @maxLength: 1
           */
          Ao_ue_Ntz_S: {
            /**
             * Summe_aus_volks-_und_staatswirtschaftlichen_Gründen_und_höherer_Gewalt_(cbm/F)
             */
            E0911403: string;
            /**
             * Maßgebende_Holznutzungen_(verwertete_Holzmengen)_(cbm/F)
             */
            E0911604: string;
            /**
             * Aufteilungsmaßstab_nach_dem_Verhältnis_der_Holzmengen_(maßgebende_Holznutzungen)
             */
            E0911703: string;
            /**
             * Einkünfte_aus_den_Holznutzungen_des_Wirtschaftsjahrs_$VZ$_/_$VZ+1$_($VZ$)
             */
            E0911804: string;
            /**
             * Einkünfte_aus_außerordentlichen_Holznutzungen_des_Wirtschaftsjahrs_$VZ$_/_$VZ+1$_($VZ$),_die_auf_das_Kalenderjahr_$VZ$_entfallen
             */
            E0911902: string;
            /**
             * Einkünfte_aus_außerordentlichen_Holznutzungen_des_Wirtschaftsjahrs_$VZ-1$_/_$VZ$,_die_auf_das_Kalenderjahr_$VZ$_entfallen
             */
            E0912002: string;
            /**
             * Einkünfte_aus_außerordentlichen_Holznutzungen_im_Kalenderjahr_$VZ$_laut_den_Zeilen_39_und_40
             */
            E0944102: string;
            /**
             * Einkünfte_aus_außerordentlichen_Holznutzungen_im_Kalenderjahr_$VZ$_laut_gesonderter_(und_einheitlicher)_Feststellung
             */
            E0944202: string;
            /**
             * Summe_der_Beträge_aus_Zeile_41_Spalte_4_und_Zeile_42_Spalte_4
             */
            E0912102: string;
          }[];
          /**
           * Außerordentliche_Holznutzung_aus_besonderen_Schadensereignissen
           * @maxLength: 1
           */
          Ao_bes_Ereig: {
            /**
             * besondere_Schadensereignisse_(§_34b_Absatz_5_EStG)_(cbm/F)
             */
            E0911502: string;
            /**
             * Maßgebende_Holznutzungen_(verwertete_Holzmengen)_(cbm/F)
             */
            E0911605: string;
            /**
             * Aufteilungsmaßstab_nach_dem_Verhältnis_der_Holzmengen_(maßgebende_Holznutzungen)
             */
            E0911704: string;
            /**
             * Einkünfte_aus_den_Holznutzungen_des_Wirtschaftsjahrs_$VZ$_/_$VZ+1$_($VZ$)
             */
            E0911805: string;
            /**
             * Einkünfte_aus_außerordentlichen_Holznutzungen_des_Wirtschaftsjahrs_$VZ$_/_$VZ+1$_($VZ$),_die_auf_das_Kalenderjahr_$VZ$_entfallen
             */
            E0911903: string;
            /**
             * Einkünfte_aus_außerordentlichen_Holznutzungen_des_Wirtschaftsjahrs_$VZ-1$_/_$VZ$,_die_auf_das_Kalenderjahr_$VZ$_entfallen
             */
            E0912003: string;
            /**
             * Einkünfte_aus_außerordentlichen_Holznutzungen_im_Kalenderjahr_$VZ$_laut_den_Zeilen_39_und_40
             */
            E0944103: string;
            /**
             * Einkünfte_aus_außerordentlichen_Holznutzungen_im_Kalenderjahr_$VZ$_laut_gesonderter_(und_einheitlicher)_Feststellung
             */
            E0944203: string;
            /**
             * Summe_der_Beträge_aus_Zeile_41_Spalte_5_und_Zeile_42_Spalte_5
             */
            E0912103: string;
          }[];
        }[];
      }[];
    }[];
  }
  export interface I35a {
    /**
     * Haushaltsnahe_Beschäftigungsverhältnisse,_Dienstleistungen_und_Handwerkerleistungen
     * @maxLength: 1
     */
    HA_35a: {
      /**
       * Steuerermäßigung_für_Aufwendungen
       * @maxLength: 1
       */
      St_Erm: {
        /**
         * Geringfügige_Beschäftigungen_im_Privathaushalt_-_sogenannte_Minijobs_-
         * @maxLength: 1
         */
        Minijobs: {
          /**
           * Einzelangaben
           * @maxLength: 12
           */
          Einz: {
            /**
             * Art_der_Tätigkeit
             */
            E0104206: string;
            /**
             * Betrag
             */
            E0104108: string;
          }[];
          /**
           * Summe
           * @maxLength: 1
           */
          Sum: {
            /**
             * Summe_der_Aufwendungen_(abzüglich_Erstattungen)
             */
            E0104109: string;
          }[];
        }[];

        /**
         * Pflege-_und_Betreuungsleistungen_im_Haushalt,_bei_eigener_Heimunterbringung_in_den_Heimkosten_enthaltene_Aufwendungen_für_Dienstleistungen,_die_mit_denen_einer_Haushaltshilfe_vergleichbar_sind;_das_in_Zeile_14_der_Anlage_Außergewöhnliche_Belastungen_als_Erstattung_für_häusliche_Pflege-_und_Betreuungskosten_berücksichtigte_Pflegegeld_(§_37_SGB_XI)_/_Pflegetagegeld
         * @maxLength: 1
         */
        Hhn_BV_DL: {
          /**
           * Einzelangaben
           * @maxLength: 50
           */
          Einz: {
            /**
             * Art_der_Tätigkeit_/_Aufwendungen
             */
            E0107206: string;
            /**
             * Aufwendungen_(abzüglich_Erstattungen)
             */
            E0107207: string;
          }[];
          /**
           * Summe
           * @maxLength: 1
           */
          Sum: {
            /**
             * Summe_der_Aufwendungen_(abzüglich_Erstattungen)
             */
            E0107208: string;
          }[];
        }[];

        /**
         * für_Renovierungs-,_Erhaltungs-_und_Modernisierungsmaßnahmen_im_eigenen_Haushalt_(ohne_öffentlich_geförderte_Maßnahmen,_für_die_zinsverbilligte_Darlehen_oder_steuerfreie_Zuschüsse_in_Anspruch_genommen_werden,_zum_Beispiel_KfW-Bank,_landeseigener_Förderbanken_oder_Gemeinden)
         * @maxLength: 1
         */
        Handw_L: {
          /**
           * Einzelangaben
           * @maxLength: 50
           */
          Einz: {
            /**
             * Art_der_Aufwendungen
             */
            E0111217: string;
            /**
             * Rechnungsbetrag
             */
            E0170601: string;
            /**
             * Betrag_darin_enthaltene_Lohnanteile,_Maschinen-_und_Fahrtkosten_inklusive_Umsatzsteuer
             */
            E0111214: string;
          }[];
          /**
           * Summe
           * @maxLength: 1
           */
          Sum: {
            /**
             * Summe_steuerlich_berücksichtigungsfähiger_Lohnanteile,_Maschinen-_und_Fahrtkosten_inklusive_Umsatzsteuer
             */
            E0111215: string;
          }[];
        }[];
        /**
         * Nur_bei_Alleinstehenden_und_Eintragungen_in_den_Zeilen_19_bis_21_der_Anlage_Außergewöhnliche_Belastungen_und_/_oder_in_den_Zeilen_4_bis_9_der_Anlage_Haushaltsnahe_Aufwendungen:
         * @maxLength: 1
         */
        Alleinst: {
          /**
           * Es_bestand_ganzjährig_ein_gemeinsamer_Haushalt_mit_einer_oder_mehreren_anderen_alleinstehenden_Person(en)_(Anzahl_der_weiteren_Personen_im_Haushalt)
           */
          E0107606: string;
          /**
           * Angaben_zu_Person(en),_mit_denen_ein_gemeinsamer_Haushalt_bestand
           * @maxLength: 12
           */
          Pers_gem_HH: {
            /**
             * Name,_Vorname,_Geburtsdatum
             */
            E0104706: string;
          }[];
        }[];
        /**
         * Nur_bei_Alleinstehenden_oder_Einzelveranlagung_von_Ehegatten_/_Lebenspartnern_und_Eintragungen_in_den_Zeilen_19_bis_21_der_Anlage_Außergewöhnliche_Belastungen_und_/_oder_in_den_Zeilen_4_bis_9_der_Anlage_Haushaltsnahe_Aufwendungen:
         * @maxLength: 1
         */
        Alleinst_EVEG_Auft: {
          /**
           * Laut_einzureichendem_gemeinsamen_Antrag_ist_der_Höchstbetrag_für_die_Aufwendungen_laut_Zeile_19_der_Anlage_Außergewöhnliche_Belastungen_und_/_oder_Zeile_4_der_Anlage_Haushaltsnahe_Aufwendungen_in_einem_anderen_Verhältnis_als_je_zur_Hälfte_aufzuteilen._Der_bei_mir_zu_berücksichtigende_Anteil_beträgt_(in_%)
           */
          E0107607: string;
          /**
           * Laut_einzureichendem_gemeinsamen_Antrag_ist_der_Höchstbetrag_für_die_Aufwendungen_laut_Zeile_20_der_Anlage_Außergewöhnliche_Belastungen_und_/_oder_Zeile_5_der_Anlage_Haushaltsnahe_Aufwendungen_in_einem_anderen_Verhältnis_als_je_zur_Hälfte_aufzuteilen._Der_bei_mir_zu_berücksichtigende_Anteil_beträgt_(in_%)
           */
          E0107704: string;
          /**
           * Laut_einzureichendem_gemeinsamen_Antrag_ist_der_Höchstbetrag_für_die_Aufwendungen_laut_Zeile_21_der_Anlage_Außergewöhnliche_Belastungen_und_/_oder_Zeile_9_der_Anlage_Haushaltsnahe_Aufwendungen_in_einem_anderen_Verhältnis_als_je_zur_Hälfte_aufzuteilen._Der_bei_mir_zu_berücksichtigende_Anteil_beträgt_(in_%)
           */
          E0107803: string;
        }[];
        /**
         * Nur_in_Fällen_der_Zusammenveranlagung_oder_Einzelveranlagung_von_Ehegatten_/_Lebenspartnern_und_Eintragungen_in_den_Zeilen_19_bis_21_der_Anlage_Außergewöhnliche_Belastungen_und_/_oder_in_den_Zeilen_4_bis_9_der_Anlage_Haushaltsnahe_Aufwendungen_$Person.Wert$:
         * @maxLength: 2
         */
        ZV_EVEG: {
          Person: ElsterPerson;
          /**
           * Es_wurde_$VZ$_ein_gemeinsamer_Haushalt_begründet_oder_aufgelöst_und_für_einen_Teil_des_Kalenderjahres_ein_Einzelhaushalt_geführt
           */
          E0107605: string;
        }[];
      }[];
    }[];
  }
  export interface I35c {
    /**
     * Aufwendungen_für_energetische_Maßnahmen_bei_zu_eigenen_Wohnzwecken_genutzten_Gebäuden
     * @maxLength: 9
     */
    EM_35c: {
      /**
       * ${vordrucknr}._Begünstigtes_Objekt
       * @maxLength: 1
       */
      Obj: {
        /**
         * Standort_des_Wohngebäudes_/_der_Eigentumswohnung
         * @maxLength: 1
         */
        Allg: {
          /**
           * Straße,_Hausnummer
           */
          E0240401: string;
          /**
           * Herstellungsbeginn_des_Gebäudes
           */
          E0240402: string;
          /**
           * Postleitzahl,_Ort_(gegebenenfalls_ausländischer_Staat)
           */
          E0240501: string;
          /**
           * Einheitswert-Aktenzeichen_(ohne_Sonderzeichen)
           */
          E0240601: string;
          /**
           * Das_Objekt_wurde_nach_dem_1.1.2020_erworben.
           */
          E0240701: string;
          /**
           * Gesamtfläche_(m²)
           */
          E0240801: string;
          /**
           * davon_ausschließliche_Nutzung_zu_eigenen_Wohnzwecken_oder_in_Teilen_unentgeltliche_Überlassung_zu_Wohnzwecken_an_andere_Personen_(m²)
           */
          E0240802: string;
        }[];

        /**
         * Bitte_die_Bescheinigung(en)_des_ausführenden_Fachunternehmens_/_der_Person_mit_Ausstellungsberechtigung_nach_§_21_Energieeinsparverordnung_(EnEV)_einreichen_-
         * @maxLength: 1
         */
        Aufw: {
          /**
           * Baubeginn_der_energetischen_Maßnahme
           */
          E0240901: string;
          /**
           * Wärmedämmung_von_Wänden
           * @maxLength: 10
           */
          Waende: {
            /**
             * Aufwendungen_für_die_Wärmedämmung_von_Wänden
             */
            E0241001: string;
          }[];
          /**
           * Wärmedämmung_von_Dachflächen
           * @maxLength: 10
           */
          Dach: {
            /**
             * Aufwendungen_für_die_Wärmedämmung_von_Dachflächen
             */
            E0241101: string;
          }[];
          /**
           * Wärmedämmung_von_Geschossdecken
           * @maxLength: 10
           */
          Geschossd: {
            /**
             * Aufwendungen_für_die_Wärmedämmung_von_Geschossdecken
             */
            E0241201: string;
          }[];
          /**
           * Erneuerung_der_Fenster_und_/_oder_Außentür(en)
           * @maxLength: 10
           */
          Fenst_Tuer: {
            /**
             * Aufwendungen_für_die_Erneuerung_der_Fenster_und_/_oder_Außentür(en)
             */
            E0241301: string;
          }[];
          /**
           * Erneuerung_oder_Einbau_einer_Lüftungsanlage
           * @maxLength: 10
           */
          Lueftung: {
            /**
             * Aufwendungen_für_die_Erneuerung_oder_den_Einbau_einer_Lüftungsanlage
             */
            E0241401: string;
          }[];
          /**
           * Erneuerung_der_Heizungsanlage
           * @maxLength: 10
           */
          Heizung: {
            /**
             * Aufwendungen_für_die_Erneuerung_der_Heizungsanlage
             */
            E0241501: string;
          }[];
          /**
           * Einbau_von_digitalen_Systemen_zur_energetischen_Betriebs-_und_Verbrauchsoptimierung
           * @maxLength: 10
           */
          Digital: {
            /**
             * Aufwendungen_für_den_Einbau_von_digitalen_Systemen_zur_energetischen_Betriebs-_und_Verbrauchsoptimierung
             */
            E0241601: string;
          }[];
          /**
           * Optimierung_bestehender_Heizungsanlagen_(älter_als_2_Jahre)
           * @maxLength: 10
           */
          Heizung_alt: {
            /**
             * Aufwendungen_für_die_Optimierung_bestehender_Heizungsanlagen_(älter_als_2_Jahre)
             */
            E0241701: string;
          }[];
          /**
           * Erteilung_der_Bescheinigung(en)
           * @maxLength: 10
           */
          Bescheinig: {
            /**
             * Aufwendungen_für_die_Erteilung_der_Bescheinigung(en)
             */
            E0241801: string;
          }[];
          /**
           * Summe
           * @maxLength: 1
           */
          Sum: {
            /**
             * Summe_der_Aufwendungen_für_energetische_Maßnahmen
             */
            E0241901: string;
          }[];
          /**
           * Planerische_Begleitung_oder_Beaufsichtigung_durch_den_Energieberater
           * @maxLength: 1
           */
          Energieberat: {
            /**
             * Aufwendungen_für_die_planerische_Begleitung_oder_Beaufsichtigung_durch_den_Energieberater
             */
            E0242001: string;
          }[];

          /**
           * Bei_Installation_eines_Gas-Brennwertkessels_ist_innerhalb_von_2_Jahren_ab_Datum_der_Installation_des_Gas-Brennwertkessels_der_Nachweis_der_Umsetzung_der_Hybridisierung_gemäß_den_Anforderungen_aus_Anlage_6.4_der_Energetische_Sanierungsmaßnahmen-Verordnung_zu_erbringen._Die_Steuerermäßigung_ist_erstmalig_in_dem_Kalenderjahr_zu_gewähren,_in_dem_die_energetische_Maßnahme_abgeschlossen_wurde._Dies_ist_der_Fall,_wenn_die_Schlussrechnung_des_Fachunternehmens_erteilt_wurde_und_der_Nachweis_der_Hybridisierung_vorliegt.
           * @maxLength: 1
           */
          Hybrid: {
            /**
             * In_Zeile_15_enthaltene_Aufwendungen_für_die_Installation_eines_effizienten_Gas-Brennwertgerätes,_das_für_die_künftige_Einbindung_erneuerbarer_Energien_vorbereitet_ist_(Hybridisierung)
             */
            E0242101: string;
            /**
             * Der_Nachweis_zur_Umsetzung_der_Hybridisierung_liegt_vor_und_wird_eingereicht:
             */
            E0242201: string;
          }[];
        }[];
        /**
         * Falls_das_Objekt_im_Eigentum_mehrerer_Personen_steht:
         * @maxLength: 1
         */
        Eigent: {
          /**
           * $Person.Wert$
           * @maxLength: 2
           */
          StPfl: {
            Person: ElsterPerson;
            /**
             * Miteigentumsanteil
             */
            E0242301: string;
          }[];
          /**
           * Weitere_Miteigentümer
           * @maxLength: 10
           */
          Miteigent: {
            /**
             * Name,_Vorname,_Geburtsdatum,_Adresse_weiterer_Miteigentümer
             */
            E0242401: string;
          }[];
        }[];
        /**
         * Nur_ausfüllen,_soweit_in_Zeile_19_und_/_oder_in_Zeile_20_Aufwendungen_enthalten_sind,_für_die_der_Abzug_als_außergewöhnliche_Belastung_beantragt_wird
         * @maxLength: 1
         */
        AgB: {
          /**
           * In_Zeile_19_enthaltene_Aufwendungen,_für_die_der_Abzug_(soweit_möglich)_als_außergewöhnliche_Belastungen_beantragt_wird
           */
          E0242601: string;
          /**
           * In_Zeile_20_enthaltene_Aufwendungen,_für_die_der_Abzug_(soweit_möglich)_als_außergewöhnliche_Belastungen_beantragt_wird
           */
          E0242701: string;
        }[];
      }[];
    }[];
  }
  export interface IAgB {
    /**
     * Außergewöhnliche_Belastungen_/_Pauschbeträge
     * @maxLength: 1
     */
    AgB: {
      /**
       * bei_erstmaliger_Beantragung_/_Änderung_bitte_Nachweis_einreichen_-
       * @maxLength: 2
       */
      Beh: {
        Person: ElsterPerson;
        /**
         * Ausweis_/_Rentenbescheid_/_Bescheinigung
         * @maxLength: 1
         */
        Ausw_Rentb_Besch: {
          /**
           * gültig_von
           */
          E0109101: string;
          /**
           * gültig_bis
           */
          E0109102: string;
          /**
           * unbefristet_gültig
           */
          E0109103: string;
          /**
           * Grad_der_Behinderung
           */
          E0109708: string;
        }[];
        /**
         * Ich_bin
         * @maxLength: 1
         */
        Geh_Steh_Blind_Hilfl: {
          /**
           * geh-_und_stehbehindert_(Merkzeichen_\"G\"_oder_\"aG\")
           */
          E0109707: string;
          /**
           * blind_/_ständig_hilflos_(Merkzeichen_\"Bl\"_und_/_oder_\"H\"),_schwerstpflegebedürftig_(Pflegegrad_4_oder_5)
           */
          E0109706: string;
        }[];
      }[];
      /**
       * Hinterbliebenen-Pauschbetrag:_$Person.Wert$
       * @maxLength: 2
       */
      Hinterbl: {
        Person: ElsterPerson;
        /**
         * Ich_beantrage_den_Hinterbliebenen-Pauschbetrag
         */
        E0109704: string;
      }[];

      /**
       * bei_erstmaliger_Beantragung_/_Änderung_bitte_Nachweis_einreichen_-
       * @maxLength: 1
       */
      Pflege_PB: {
        /**
         * Einzelangaben
         * @maxLength: 5
         */
        Einz: {
          /**
           * Die_unentgeltliche_persönliche_Pflege_einer_ständig_hilflosen_Person_in_ihrer_oder_in_meiner_Wohnung_erfolgte_durch_(1_=_steuerpflichtige_Person_/_Ehemann_/_Person_A,_2_=_Ehefrau_/_Person_B,_3_=_beide_Ehegatten_/_Lebenspartner)
           */
          E0106507: string;
          /**
           * Name,_Anschrift_und_Verwandtschaftsverhältnis_der_hilflosen_Person
           */
          E0110601: string;
          /**
           * Anzahl_weiterer_Pflegepersonen
           */
          E0106603: string;
        }[];
      }[];
      /**
       * Andere_Aufwendungen
       * @maxLength: 1
       */
      And_Aufw: {
        /**
         * Krankheitskosten_(zum_Beispiel_Arznei-,_Heil-_und_Hilfsmittel,_Kurkosten)
         * @maxLength: 1
         */
        Krankh: {
          /**
           * Einzelangaben
           * @maxLength: 50
           */
          Einz: {
            /**
             * Art_der_Aufwendungen
             */
            E0161301: string;
            /**
             * Höhe_der_Aufwendung
             */
            E0161302: string;
            /**
             * Anspruch_auf_zu_erwartende_/_Erhaltene_Versicherungsleistungen,_Beihilfen,_Unterstützungen_und_so_weiter
             */
            E0161303: string;
          }[];
          /**
           * Summen
           * @maxLength: 1
           */
          Sum: {
            /**
             * Summe_der_Aufwendungen
             */
            E0161304: string;
            /**
             * Summe_der_Ansprüche_auf_zu_erwartende_/_Erhaltene_Versicherungsleistungen,_Beihilfen,_Unterstützungen_und_so_weiter
             */
            E0161305: string;
          }[];
        }[];
        /**
         * Pflegekosten_(zum_Beispiel_häusliche_Pflege_und_Heimunterbringung)
         * @maxLength: 1
         */
        Pflege: {
          /**
           * Einzelangaben
           * @maxLength: 50
           */
          Einz: {
            /**
             * Art_der_Aufwendungen
             */
            E0161401: string;
            /**
             * Höhe_der_Aufwendung
             */
            E0161402: string;
            /**
             * Anspruch_auf_zu_erwartende_/_Erhaltene_Versicherungsleistungen,_Beihilfen,_Unterstützungen_und_so_weiter
             */
            E0161403: string;
          }[];
          /**
           * Summen
           * @maxLength: 1
           */
          Sum: {
            /**
             * Summe_der_Aufwendungen
             */
            E0161404: string;
            /**
             * Summe_der_Ansprüche_auf_zu_erwartende_/_Erhaltene_Versicherungsleistungen,_Beihilfen,_Unterstützungen_und_so_weiter
             */
            E0161405: string;
          }[];
        }[];
        /**
         * Behinderungsbedingte_Aufwendungen_(zum_Beispiel_Umbaukosten)
         * @maxLength: 1
         */
        Beh_Aufw: {
          /**
           * Einzelangaben
           * @maxLength: 50
           */
          Einz: {
            /**
             * Art_der_Aufwendungen
             */
            E0161501: string;
            /**
             * Höhe_der_Aufwendung
             */
            E0161502: string;
            /**
             * Anspruch_auf_zu_erwartende_/_Erhaltene_Versicherungsleistungen,_Beihilfen,_Unterstützungen_und_so_weiter
             */
            E0161503: string;
          }[];
          /**
           * Summen
           * @maxLength: 1
           */
          Sum: {
            /**
             * Summe_der_Aufwendungen
             */
            E0161504: string;
            /**
             * Summe_der_Ansprüche_auf_zu_erwartende_/_Erhaltene_Versicherungsleistungen,_Beihilfen,_Unterstützungen_und_so_weiter
             */
            E0161505: string;
          }[];
        }[];
        /**
         * Behinderungsbedingte_Kfz-Kosten
         * @maxLength: 1
         */
        Beh_Kfz: {
          /**
           * Einzelangaben
           * @maxLength: 50
           */
          Einz: {
            /**
             * Art_der_Aufwendungen
             */
            E0161601: string;
            /**
             * Höhe_der_Aufwendung
             */
            E0161602: string;
            /**
             * Anspruch_auf_zu_erwartende_/_Erhaltene_Versicherungsleistungen,_Beihilfen,_Unterstützungen_und_so_weiter
             */
            E0161603: string;
          }[];
          /**
           * Summen
           * @maxLength: 1
           */
          Sum: {
            /**
             * Summe_der_Aufwendungen
             */
            E0161604: string;
            /**
             * Summe_der_Ansprüche_auf_zu_erwartende_/_Erhaltene_Versicherungsleistungen,_Beihilfen,_Unterstützungen_und_so_weiter
             */
            E0161605: string;
          }[];
        }[];
        /**
         * Bestattungskosten_(zum_Beispiel_Grabstätte,_Sarg,_Todesanzeige)
         * @maxLength: 1
         */
        Bestatt: {
          /**
           * Einzelangaben
           * @maxLength: 50
           */
          Einz: {
            /**
             * Art_der_Aufwendungen
             */
            E0161701: string;
            /**
             * Höhe_der_Aufwendung
             */
            E0161702: string;
            /**
             * Anspruch_auf_zu_erwartende_/_Erhaltene_Versicherungsleistungen,_Beihilfen,_Unterstützungen;_Wert_des_Nachlasses_und_so_weiter
             */
            E0161703: string;
          }[];
          /**
           * Summen
           * @maxLength: 1
           */
          Sum: {
            /**
             * Summe_der_Aufwendungen
             */
            E0161704: string;
            /**
             * Summe_der_Ansprüche_auf_zu_erwartende_/_Erhaltene_Versicherungsleistungen,_Beihilfen,_Unterstützungen;_Wert_des_Nachlasses_und_so_weiter
             */
            E0161705: string;
          }[];
        }[];
        /**
         * Sonstige_außergewöhnliche_Belastungen
         * @maxLength: 1
         */
        Sonst: {
          /**
           * Einzelangaben
           * @maxLength: 50
           */
          Einz: {
            /**
             * Art_der_Aufwendungen
             */
            E0161801: string;
            /**
             * Höhe_der_Aufwendung
             */
            E0161802: string;
            /**
             * Anspruch_auf_zu_erwartende_/_Erhaltene_Versicherungsleistungen,_Beihilfen,_Unterstützungen;_Wert_des_Nachlasses_und_so_weiter
             */
            E0161803: string;
          }[];
          /**
           * Summen
           * @maxLength: 1
           */
          Sum: {
            /**
             * Summe_der_Aufwendungen
             */
            E0161804: string;
            /**
             * Summe_der_Ansprüche_auf_zu_erwartende_/_Erhaltene_Versicherungsleistungen,_Beihilfen,_Unterstützungen;_Wert_des_Nachlasses_und_so_weiter
             */
            E0161805: string;
          }[];
        }[];
        /**
         * Für_folgende_Aufwendungen_wird_die_Steuerermäßigung_für_haushaltsnahe_Beschäftigungsverhältnisse_/_Dienstleistungen_/_Handwerkerleistungen_beantragt,_soweit_sie_wegen_Abzugs_der_zumutbaren_Belastung_nicht_als_außergewöhnliche_Belastungen_berücksichtigt_werden_(die_Beträge_sind_nicht_zusätzlich_in_den_Zeilen_4_bis_9_der_Anlage_Haushaltsnahe_Aufwendungen_einzutragen):
         * @maxLength: 1
         */
        Hhn_BV_DL_HL: {
          /**
           * Die_in_Zeile_14_enthaltenen_Pflegeleistungen_im_Rahmen_eines_geringfügigen_Beschäftigungsverhältnisses_im_Privathaushalt_-_sogenannter_Minijob_-_betragen:_Aufwendungen_(abzüglich_Erstattungen)
           */
          E0106813: string;
          /**
           * Die_in_Zeile_14_enthaltenen_übrigen_haushaltsnahen_Pflegeleistungen_(ohne_Minijob)_und_in_Heimunterbringungskosten_enthaltenen_Aufwendungen_für_Dienstleistungen,_die_denen_einer_Haushaltshilfe_vergleichbar_sind,_betragen:_Aufwendungen_(abzüglich_Erstattungen)
           */
          E0106906: string;
          /**
           * Die_in_Zeile_13_bis_18_enthaltenen_Arbeitskosten_für_Handwerkerleistungen_betragen:_Aufwendungen_(abzüglich_Erstattungen)
           */
          E0107005: string;
        }[];
      }[];
    }[];
  }
  export interface IAUS {
    /**
     * Renten_und_andere_Leistungen_aus_ausländischen_Versicherungen_/_ausländischen_Rentenverträgen_/_ausländischen_betrieblichen_Versorgungseinrichtungen
     * @maxLength: 2
     */
    R_AUS: {
      Person: ElsterPerson;
      /**
       * Ausländische_Leibrenten_und_Leistungen,_die_mit_Leistungen_eines_inländischen_Versorgungsträgers_(gesetzliche_Rentenversicherungen,_landwirtschaftliche_Alterskasse_und_berufsständische_Versorgungseinrichtungen)_vergleichbar_sind
       * @maxLength: 1
       */
      Leibr_gesetzl: {
        /**
         * $#/R_AUS/Leibr_gesetzl/Einz$._Rente
         * @maxLength: 15
         */
        Einz: {
          /**
           * Staat_des_Leistungsbezugs
           */
          E1820402: string;
          /**
           * Rentenbetrag_(einschließlich_Einmalzahlung_und_Leistungen)
           */
          E1820401: string;
          /**
           * Rentenanpassungsbetrag_(in_Zeile_5_enthalten)
           */
          E1820501: string;
          /**
           * Beginn_der_Rente
           */
          E1820601: string;
          /**
           * Beginn_der_1._vorhergehenden_Rente
           */
          E1820701: string;
          /**
           * Ende_der_1._vorhergehenden_Rente
           */
          E1820801: string;
          /**
           * Beginn_der_2._vorhergehenden_Rente
           */
          E1820702: string;
          /**
           * Ende_der_2._vorhergehenden_Rente
           */
          E1820802: string;
          /**
           * Beginn_der_3._vorhergehenden_Rente
           */
          E1820703: string;
          /**
           * Ende_der_3._vorhergehenden_Rente
           */
          E1820803: string;
          /**
           * Nachzahlungen_für_mehrere_vorangegangene_Jahre_/_Kapitalauszahlung_(in_Zeile_5_enthalten)
           */
          E1820901: string;
          /**
           * Öffnungsklausel:
           * @maxLength: 1
           */
          Oeff_Kl: {
            /**
             * Prozentsatz_(laut_Bescheinigung_Ihres_ausländischen_Versorgungsträgers_/_laut_gesonderter_Ermittlung)
             */
            E1821001: string;
            /**
             * die_Rente_erlischt_/_wird_umgewandelt_spätestens_am
             */
            E1821101: string;
            /**
             * bei_Einmalzahlung:_Betrag
             */
            E1821201: string;
          }[];
        }[];
      }[];

      /**
       * (ohne_Renten_laut_Zeile_4_bis_13)
       * @maxLength: 1
       */
      Leibr_priv: {
        /**
         * $#/R_AUS/Leibr_priv/Einz$._Rente
         * @maxLength: 15
         */
        Einz: {
          /**
           * Staat_des_Leistungsbezugs
           */
          E1821402: string;
          /**
           * Rentenbetrag
           */
          E1821301: string;
          /**
           * Beginn_der_Rente
           */
          E1821401: string;
          /**
           * Geburtsdatum_des_Erblassers_bei_Garantiezeitrenten
           */
          E1821501: string;
          /**
           * Die_Rente_erlischt_mit_dem_Tod_von
           */
          E1821601: string;
          /**
           * Die_Rente_erlischt_/_wird_umgewandelt_spätestens_am
           */
          E1821701: string;
          /**
           * Nachzahlungen_für_mehrere_vorangegangene_Jahre_(in_Zeile_15_enthalten)
           */
          E1821801: string;
        }[];
      }[];
      /**
       * Leistungen_aus_ausländischen_betrieblichen_Altersversorgungseinrichtungen,_die_mit_inländischen_betrieblichen_Altersversorgungseinrichtungen_vergleichbar_sind
       * @maxLength: 1
       */
      Leist_bAV: {
        /**
         * $#/R_AUS/Leist_bAV/Einz$._Rente
         * @maxLength: 15
         */
        Einz: {
          /**
           * Staat_des_Leistungsbezugs
           */
          E1823101: string;
          /**
           * Leistungen_aus_einer_ausländischen_betrieblichen_Altersversorgungseinrichtung,_soweit_diese_auf_im_Inland_geförderten_Beiträgen_beruhen
           */
          E1823201: string;
          /**
           * Lebenslange_Leibrente_aus_einer_ausländischen_betrieblichen_Altersversorgungseinrichtung,_soweit_diese_auf_im_Inland_nicht_geförderten_Beiträgen_beruht
           */
          E1823301: string;
          /**
           * Beginn_der_Rente
           */
          E1823401: string;
          /**
           * Geburtsdatum_des_Erblassers_bei_Garantiezeitrenten
           */
          E1823501: string;
          /**
           * Abgekürzte_Leibrente_aus_einer_ausländischen_betrieblichen_Altersversorgungseinrichtung,_soweit_diese_auf_im_Inland_nicht_geförderten_Beiträgen_beruht
           */
          E1823601: string;
          /**
           * Beginn_der_Rente
           */
          E1823701: string;
          /**
           * Die_Rente_erlischt_/_wird_umgewandelt_spätestens_am
           */
          E1823801: string;
          /**
           * Einmalleistungen_aus_einer_ausländischen_betrieblichen_Altersversorgungseinrichtung,_soweit_diese_auf_im_Inland_nicht_geförderten_Beiträgen_beruhen
           */
          E1823901: string;
          /**
           * Datum_des_Vertragsabschlusses
           */
          E1824001: string;
        }[];
      }[];
      /**
       * Werbungskosten
       * @maxLength: 1
       */
      Wk: {
        /**
         * Werbungskosten_zu_den_Zeilen_5_und_15_-_ohne_Werbungskosten_laut_Zeile_42_-
         * @maxLength: 1
         */
        Leibr: {
          /**
           * Einzelangaben
           * @maxLength: 99
           */
          Einz: {
            /**
             * Art_der_Aufwendungen
             */
            E1821901: string;
            /**
             * Betrag
             */
            E1821902: string;
          }[];
          /**
           * Summe
           * @maxLength: 1
           */
          Sum: {
            /**
             * Summe
             */
            E1821903: string;
          }[];
        }[];
        /**
         * Werbungskosten_zu_den_Zeilen_10_und_20
         * @maxLength: 1
         */
        Leibr_Nachz: {
          /**
           * Einzelangaben
           * @maxLength: 99
           */
          Einz: {
            /**
             * Art_der_Aufwendungen
             */
            E1822001: string;
            /**
             * Betrag
             */
            E1822002: string;
          }[];
          /**
           * Summe
           * @maxLength: 1
           */
          Sum: {
            /**
             * Summe
             */
            E1822003: string;
          }[];
        }[];
        /**
         * Werbungskosten_zu_den_Zeilen_32_und_39
         * @maxLength: 1
         */
        Leist_bAV: {
          /**
           * Einzelangaben
           * @maxLength: 99
           */
          Einz: {
            /**
             * Art_der_Aufwendungen
             */
            E1824301: string;
            /**
             * Betrag
             */
            E1824302: string;
          }[];
          /**
           * Summe
           * @maxLength: 1
           */
          Sum: {
            /**
             * Summe
             */
            E1824303: string;
          }[];
        }[];
        /**
         * Werbungskosten_zu_den_Zeilen_33_und_36
         * @maxLength: 1
         */
        Leibr_bAV: {
          /**
           * Einzelangaben
           * @maxLength: 99
           */
          Einz: {
            /**
             * Art_der_Aufwendungen
             */
            E1824401: string;
            /**
             * Betrag
             */
            E1824402: string;
          }[];
          /**
           * Summe
           * @maxLength: 1
           */
          Sum: {
            /**
             * Summe
             */
            E1824403: string;
          }[];
        }[];
      }[];
      /**
       * Steuerstundungsmodelle
       * @maxLength: 1
       */
      Steuerstund_Mod: {
        /**
         * Einkünfte_aus_Gesellschaften_/_Gemeinschaften_/_ähnlichen_Modellen_im_Sinne_des_§_15b_EStG_(laut_Aufstellung)_-_Bezeichnung
         */
        E1822101: string;
        /**
         * Einkünfte_aus_Gesellschaften_/_Gemeinschaften_/_ähnlichen_Modellen_im_Sinne_des_§_15b_EStG_(laut_Aufstellung)_-_Betrag
         */
        E1822102: string;
      }[];
    }[];
  }
  export interface IAV13a {
    /**
     * Anlageverzeichnis_zur_Anlage_13a_(§_13a_EStG)_für_das_Wirtschaftsjahr
     * @maxLength: 9
     */
    AV13a: {
      TODO_FK_Laufende_Nummer_Betrieb: {
        /**
         * Beginn
         */
        E2950401: string;
        /**
         * Ende
         */
        E2950402: string;
      }[];
      /**
       * Grund_und_Boden
       * @maxLength: 1
       */
      G_u_B: {
        /**
         * Bewertung_nach_§_55_Absatz_2_bis_4_EStG
         * @maxLength: 1
         */
        Bew_P55_Abs_2_4: {
          /**
           * Anschaffungs-_/_Herstellungskosten_oder_an_deren_Stelle_tretender_Wert
           */
          E2950501: string;
          /**
           * Buchwert_zu_Beginn_des_Wirtschaftsjahres
           */
          E2950502: string;
          /**
           * Zugänge
           */
          E2950503: string;
          /**
           * Abgänge
           */
          E2950505: string;
          /**
           * Buchwert_am_Ende_des_Wirtschaftsjahres
           */
          E2950506: string;
        }[];
        /**
         * Bewertung_mit_dem_höheren_Teilwert_(§_55_Absatz_5_EStG)
         * @maxLength: 1
         */
        Bew_P55_Abs_5: {
          /**
           * Anschaffungs-_/_Herstellungskosten_oder_an_deren_Stelle_tretender_Wert
           */
          E2950601: string;
          /**
           * Buchwert_zu_Beginn_des_Wirtschaftsjahres
           */
          E2950602: string;
          /**
           * Zugänge
           */
          E2950603: string;
          /**
           * Abgänge
           */
          E2950605: string;
          /**
           * Buchwert_am_Ende_des_Wirtschaftsjahres
           */
          E2950606: string;
        }[];
        /**
         * Ansatz_mit_den_Anschaffungs-_/_Herstellungskosten_oder_an_deren_Stelle_tretenden_Wert
         * @maxLength: 1
         */
        Ans_AK_HK: {
          /**
           * Anschaffungs-_/_Herstellungskosten_oder_an_deren_Stelle_tretender_Wert
           */
          E2950701: string;
          /**
           * Buchwert_zu_Beginn_des_Wirtschaftsjahres
           */
          E2950702: string;
          /**
           * Zugänge
           */
          E2950703: string;
          /**
           * Abgänge
           */
          E2950705: string;
          /**
           * Buchwert_am_Ende_des_Wirtschaftsjahres
           */
          E2950706: string;
        }[];
      }[];
      /**
       * Aufwuchs
       * @maxLength: 1
       */
      Aufwuchs: {
        /**
         * Stehendes_Holz
         * @maxLength: 1
         */
        Steh_Holz: {
          /**
           * Anschaffungs-_/_Herstellungskosten_oder_an_deren_Stelle_tretender_Wert
           */
          E2950801: string;
          /**
           * Buchwert_zu_Beginn_des_Wirtschaftsjahres
           */
          E2950802: string;
          /**
           * Zugänge
           */
          E2950803: string;
          /**
           * Abgänge
           */
          E2950805: string;
          /**
           * Buchwert_am_Ende_des_Wirtschaftsjahres
           */
          E2950806: string;
        }[];
        /**
         * Dauerkulturen
         * @maxLength: 1
         */
        Dauerkult: {
          /**
           * Anschaffungs-_/_Herstellungskosten_oder_an_deren_Stelle_tretender_Wert
           */
          E2950901: string;
          /**
           * Buchwert_zu_Beginn_des_Wirtschaftsjahres
           */
          E2950902: string;
          /**
           * Zugänge
           */
          E2950903: string;
          /**
           * Absetzung_für_Abnutzung_(AfA)
           */
          E2950904: string;
          /**
           * Abgänge
           */
          E2950905: string;
          /**
           * Buchwert_am_Ende_des_Wirtschaftsjahres
           */
          E2950906: string;
        }[];
      }[];
      /**
       * Gebäude
       * @maxLength: 1
       */
      Geb: {
        /**
         * Wirtschaftsgebäude
         * @maxLength: 1
         */
        Wirt_Geb: {
          /**
           * Anschaffungs-_/_Herstellungskosten_oder_an_deren_Stelle_tretender_Wert
           */
          E2951001: string;
          /**
           * Buchwert_zu_Beginn_des_Wirtschaftsjahres
           */
          E2951002: string;
          /**
           * Zugänge
           */
          E2951003: string;
          /**
           * Absetzung_für_Abnutzung_(AfA)
           */
          E2951004: string;
          /**
           * Abgänge
           */
          E2951005: string;
          /**
           * Buchwert_am_Ende_des_Wirtschaftsjahres
           */
          E2951006: string;
        }[];
        /**
         * Wohngebäude
         * @maxLength: 1
         */
        Wohn_Geb: {
          /**
           * Anschaffungs-_/_Herstellungskosten_oder_an_deren_Stelle_tretender_Wert
           */
          E2951101: string;
          /**
           * Buchwert_zu_Beginn_des_Wirtschaftsjahres
           */
          E2951102: string;
          /**
           * Zugänge
           */
          E2951103: string;
          /**
           * Absetzung_für_Abnutzung_(AfA)
           */
          E2951104: string;
          /**
           * Abgänge
           */
          E2951105: string;
          /**
           * Buchwert_am_Ende_des_Wirtschaftsjahres
           */
          E2951106: string;
        }[];
      }[];
      /**
       * Immaterielle_Wirtschaftsgüter
       * @maxLength: 1
       */
      Immat_WG: {
        /**
         * Abnutzbare_immaterielle_Wirtschaftsgüter
         * @maxLength: 1
         */
        Abnb: {
          /**
           * Anschaffungs-_/_Herstellungskosten_oder_an_deren_Stelle_tretender_Wert
           */
          E2951201: string;
          /**
           * Buchwert_zu_Beginn_des_Wirtschaftsjahres
           */
          E2951202: string;
          /**
           * Zugänge
           */
          E2951203: string;
          /**
           * Absetzung_für_Abnutzung_(AfA)
           */
          E2951204: string;
          /**
           * Abgänge
           */
          E2951205: string;
          /**
           * Buchwert_am_Ende_des_Wirtschaftsjahres
           */
          E2951206: string;
        }[];
        /**
         * Nicht_abnutzbare_immaterielle_Wirtschaftsgüter
         * @maxLength: 1
         */
        N_abnb: {
          /**
           * Anschaffungs-_/_Herstellungskosten_oder_an_deren_Stelle_tretender_Wert
           */
          E2951301: string;
          /**
           * Buchwert_zu_Beginn_des_Wirtschaftsjahres
           */
          E2951302: string;
          /**
           * Zugänge
           */
          E2951303: string;
          /**
           * Abgänge
           */
          E2951305: string;
          /**
           * Buchwert_am_Ende_des_Wirtschaftsjahres
           */
          E2951306: string;
        }[];
      }[];
      /**
       * Beteiligungen
       * @maxLength: 1
       */
      Bet: {
        /**
         * Anschaffungs-_/_Herstellungskosten_oder_an_deren_Stelle_tretender_Wert
         */
        E2951401: string;
        /**
         * Buchwert_zu_Beginn_des_Wirtschaftsjahres
         */
        E2951402: string;
        /**
         * Zugänge
         */
        E2951403: string;
        /**
         * Abgänge
         */
        E2951405: string;
        /**
         * Buchwert_am_Ende_des_Wirtschaftsjahres
         */
        E2951406: string;
      }[];
    }[];
  }
  export interface IAV {
    /**
     * Für_alle_vom_Anbieter_übermittelten_Altersvorsorgebeiträge_wird_ein_zusätzlicher_Sonderausgabenabzug_geltend_gemacht.
     * @maxLength: 1
     */
    AV: {
      /**
       * Allgemeine_Angaben:_$Person.Wert$
       * @maxLength: 2
       */
      Allg: {
        Person: ElsterPerson;
        /**
         * Mitgliedsnummer_der_landwirtschaftlichen_Alterskasse
         */
        E2003815: string;
      }[];
      /**
       * Berechnungsgrundlagen:_$Person.Wert$
       * @maxLength: 2
       */
      Ber_Grundl: {
        Person: ElsterPerson;
        /**
         * Unmittelbare_Begünstigung
         * @maxLength: 1
         */
        Begue_unmitt: {
          /**
           * Ich_bin_für_das_Jahr_$VZ$_unmittelbar_begünstigt
           */
          E2003901: string;
          /**
           * Beitragspflichtige_Einnahmen_im_Sinne_der_inländischen_gesetzlichen_Rentenversicherung_$VZ-1$
           */
          E2004001: string;
          /**
           * Inländische_Besoldung,_Amtsbezüge_und_Einnahmen_beurlaubter_Beamter_$VZ-1$
           */
          E2004101: string;
          /**
           * Entgeltersatzleistungen_$VZ-1$
           */
          E2004201: string;
          /**
           * Tatsächliches_Entgelt_$VZ-1$
           */
          E2004301: string;
          /**
           * Jahres(brutto)betrag_der_Rente_wegen_voller_Erwerbsminderung_oder_Erwerbsunfähigkeit_in_der_inländischen_gesetzlichen_Rentenversicherung_$VZ-1$
           */
          E2004401: string;
          /**
           * Inländische_Versorgungsbezüge_wegen_Dienstunfähigkeit_$VZ-1$
           */
          E2004501: string;
          /**
           * Einkünfte_aus_Land-_und_Forstwirtschaft_$VZ-2$
           */
          E2004601: string;
          /**
           * Jahres(brutto)betrag_der_Rente_wegen_voller_Erwerbsminderung_oder_Erwerbsunfähigkeit_nach_dem_Gesetz_über_die_Alterssicherung_der_Landwirte_$VZ-1$
           */
          E2004701: string;
          /**
           * Einnahmen_aus_einer_Beschäftigung,_die_einer_ausländischen_gesetzlichen_Rentenversicherungspflicht_unterlag_und_/_oder_Jahres(brutto)betrag_der_Rente_wegen_voller_Erwerbsminderung_oder_Erwerbsunfähigkeit_aus_einer_ausländischen_gesetzlichen_Rentenversicherung_$VZ-1$
           */
          E2004801: string;
        }[];
        /**
         * Mittelbare_Begünstigung
         * @maxLength: 1
         */
        Begue_mitt: {
          /**
           * Ich_bin_für_das_Jahr_$VZ$_mittelbar_begünstigt
           */
          E2004901: string;
        }[];
      }[];
      /**
       * Angaben_zu_Kindern,_für_die_ein_Anspruch_auf_Kinderzulage_besteht
       * @maxLength: 1
       */
      Ang_Kinder: {
        /**
         * Bei_Eltern,_die_miteinander_verheiratet_sind_oder_miteinander_eine_Lebenspartnerschaft_führen_und_$VZ$_nicht_dauernd_getrennt_gelebt_haben:
         * @maxLength: 1
         */
        Elt_Verh: {
          /**
           * Geboren_vor_dem_1.1.2008
           * @maxLength: 1
           */
          Geb_v_2008: {
            /**
             * Anzahl_der_Kinder,_für_die_für_$VZ$_Kindergeld_festgesetzt_worden_ist_und_die_bei_der_Zusammenveranlagung_der_Mutter_/_Person_B_zugeordnet_werden_oder_die_bei_der_Zusammenveranlagung_von_Person_A_auf_Person_B_übertragen_wurden
             */
            E2002103: string;
            /**
             * Anzahl_der_Kinder,_für_die_für_$VZ$_Kindergeld_festgesetzt_worden_ist_und_für_die_bei_Zusammenveranlagung_oder_Einzelveranlagung_von_Ehegatten_/_Lebenspartnern_die_Kinderzulage_von_der_Mutter_auf_den_Vater_/_von_Person_B_auf_Person_A_übertragen_wurde,_die_bei_Einzelveranlagung_von_Ehegatten_/_Lebenspartnern_der_Mutter_/_Person_A_zugeordnet_werden_oder_die_bei_Zusammenveranlagung_Person_A_zugeordnet_werden
             */
            E2002204: string;
            /**
             * Anzahl_der_bei_Einzelveranlagung_von_Ehegatten_/_Lebenspartnern_von_der_Mutter_auf_den_Vater_übertragenen_Kinderzulagen_(Eintragung_nur_in_der_Steuererklärung_der_übertragenden_Person)
             */
            E2021801: string;
          }[];
          /**
           * Geboren_nach_dem_31.12.2007
           * @maxLength: 1
           */
          Geb_n_2007: {
            /**
             * Anzahl_der_Kinder,_für_die_für_$VZ$_Kindergeld_festgesetzt_worden_ist_und_die_bei_der_Zusammenveranlagung_der_Mutter_/_Person_B_zugeordnet_werden_oder_die_bei_der_Zusammenveranlagung_von_Person_A_auf_Person_B_übertragen_wurden
             */
            E2002104: string;
            /**
             * Anzahl_der_Kinder,_für_die_für_$VZ$_Kindergeld_festgesetzt_worden_ist_und_für_die_bei_Zusammenveranlagung_oder_Einzelveranlagung_von_Ehegatten_/_Lebenspartnern_die_Kinderzulage_von_der_Mutter_auf_den_Vater_/_von_Person_B_auf_Person_A_übertragen_wurde,_die_bei_Einzelveranlagung_von_Ehegatten_/_Lebenspartnern_der_Mutter_/_Person_A_zugeordnet_werden_oder_die_bei_Zusammenveranlagung_Person_A_zugeordnet_werden
             */
            E2002205: string;
            /**
             * Anzahl_der_bei_Einzelveranlagung_von_Ehegatten_/_Lebenspartnern_von_der_Mutter_auf_den_Vater_übertragenen_Kinderzulagen_(Eintragung_nur_in_der_Steuererklärung_der_übertragenden_Person)
             */
            E2021802: string;
          }[];
        }[];
        /**
         * Bei_allen_anderen_Kindergeldberechtigten:
         * @maxLength: 1
         */
        And_KiG_Berecht: {
          /**
           * Geboren_vor_dem_1.1.2008
           * @maxLength: 1
           */
          Geb_v_2008: {
            /**
             * Anzahl_der_Kinder,_für_die_für_den_ersten_Anspruchszeitraum_$VZ$_Kindergeld_gegenüber_der_steuerpflichtigen_Person_/_Ehemann_/_Person_A_festgesetzt_worden_ist
             */
            E2005101: string;
            /**
             * Anzahl_der_Kinder,_für_die_für_den_ersten_Anspruchszeitraum_$VZ$_Kindergeld_gegenüber_Ehefrau_/_Person_B_festgesetzt_worden_ist
             */
            E2005201: string;
          }[];
          /**
           * Geboren_nach_dem_31.12.2007
           * @maxLength: 1
           */
          Geb_n_2007: {
            /**
             * Anzahl_der_Kinder,_für_die_für_den_ersten_Anspruchszeitraum_$VZ$_Kindergeld_gegenüber_der_steuerpflichtigen_Person_/_Ehemann_/_Person_A_festgesetzt_worden_ist
             */
            E2005102: string;
            /**
             * Anzahl_der_Kinder,_für_die_für_den_ersten_Anspruchszeitraum_$VZ$_Kindergeld_gegenüber_Ehefrau_/_Person_B_festgesetzt_worden_ist
             */
            E2005202: string;
          }[];
        }[];
      }[];
      /**
       * Altersvorsorgeverträge,_für_die_kein_zusätzlicher_Sonderausgabenabzug_geltend_gemacht_wird:_$Person.Wert$
       * @maxLength: 2
       */
      Kein_SA_Abz: {
        Person: ElsterPerson;
        /**
         * Für_nachfolgende_Altersvorsorgeverträge_möchte_ich_keinen_zusätzlichen_Sonderausgabenabzug_geltend_machen.
         */
        E2023101: string;
        /**
         * $index$._Vertrag
         * @maxLength: 9
         */
        Vertr: {
          /**
           * Anbieternummer
           */
          E2023201: string;
          /**
           * Zertifizierungsnummer
           */
          E2023202: string;
          /**
           * Vertragsnummer
           */
          E2023301: string;
        }[];
      }[];
    }[];
  }
  export interface IbAV {
    /**
     * Leistungen_aus_inländischen_Altersvorsorgeverträgen_und_aus_der_inländischen_betrieblichen_Altersversorgung
     * @maxLength: 2
     */
    RAV_bAV: {
      Person: ElsterPerson;
      /**
       * Leistungen
       * @maxLength: 1
       */
      Leist_AV_betr: {
        /**
         * $#/RAV_bAV/Leist_AV_betr/Einz$._Rente
         * @maxLength: 15
         */
        Einz: {
          /**
           * Leistungen_aus_einem_Altersvorsorgevertrag,_einem_Pensionsfonds,_einer_Pensionskasse_oder_aus_einer_Direktversicherung_laut_Nummer_1_der_Leistungsmitteilung
           */
          E1803101: string;
          /**
           * Leistungen_aus_einem_Pensionsfonds_laut_Nummer_2_der_Leistungsmitteilung
           */
          E1803201: string;
          /**
           * Bemessungsgrundlage_für_den_Versorgungsfreibetrag
           */
          E1803303: string;
          /**
           * Maßgebendes_Kalenderjahr_des_Versorgungsbeginns
           */
          E1803409: string;
          /**
           * Bei_unterjähriger_Zahlung:_Erster_Monat,_für_den_Versorgungsbezüge_gezahlt_wurden
           */
          E1803509: string;
          /**
           * Bei_unterjähriger_Zahlung:_Letzter_Monat,_für_den_Versorgungsbezüge_gezahlt_wurden
           */
          E1803510: string;
          /**
           * Leistungen_zur_Abfindung_einer_Kleinbetragsrente_laut_Nummer_3_der_Leistungsmitteilung
           */
          E1803605: string;
          /**
           * Leistungen_aus_einer_betrieblichen_Altersversorgung_laut_Nummer_4_der_Leistungsmitteilung
           */
          E1803301: string;
          /**
           * In_Zeile_10_enthaltener_Rentenanpassungsbetrag
           */
          E1803806: string;
          /**
           * Beginn_der_Leistung
           */
          E1803401: string;
          /**
           * Beginn_der_vorhergehenden_Leistung
           */
          E1803805: string;
          /**
           * Ende_der_vorhergehenden_Leistung
           */
          E1803905: string;
          /**
           * Leibrente_aus_einem_Altersvorsorgevertrag_oder_aus_einer_betrieblichen_Altersversorgung_laut_Nummer_5_oder_Leistungen_wegen_schädlicher_Verwendung_laut_Nummer_9a_der_Leistungsmitteilung
           */
          E1803501: string;
          /**
           * Beginn_der_Rente
           */
          E1803601: string;
          /**
           * Geburtsdatum_des_Erblassers_bei_Rentengarantiezeit
           */
          E1804005: string;
          /**
           * Abgekürzte_Leibrente_aus_einem_Altersvorsorgevertrag_oder_aus_einer_betrieblichen_Altersversorgung_laut_Nummer_6_oder_Leistungen_wegen_schädlicher_Verwendung_laut_Nummer_9b_der_Leistungsmitteilung
           */
          E1803701: string;
          /**
           * Beginn_der_Rente
           */
          E1803801: string;
          /**
           * Die_Rente_erlischt_/_wird_umgewandelt_spätestens_am
           */
          E1803901: string;
          /**
           * Andere_Leistungen_laut_den_Nummern_7,_8_und_10_oder_Leistungen_wegen_schädlicher_Verwendung_laut_den_Nummern_9c_und_9d_der_Leistungsmitteilung_oder_der_Auflösungsbetrag_bei_Aufgabe_der_Selbstnutzung_oder_der_Reinvestitionsabsicht_vor_dem_Beginn_der_Auszahlungsphase_oder_der_Verminderungsbetrag_laut_Bescheid_der_Zentralen_Zulagenstelle_für_Altersvermögen
           */
          E1804001: string;
          /**
           * Auflösungsbetrag_bei_Wahl_der_Einmalbesteuerung_des_Wohnförderkontos_laut_Bescheid_der_Zentralen_Zulagenstelle_für_Altersvermögen
           */
          E1804505: string;
          /**
           * Auflösungsbetrag_bei_Aufgabe_der_Selbstnutzung_oder_der_Reinvestitionsabsicht_nach_dem_Beginn_der_Auszahlungsphase_laut_Bescheid_der_Zentralen_Zulagenstelle_für_Altersvermögen
           */
          E1804601: string;
          /**
           * Beginn_der_Auszahlungsphase
           */
          E1804705: string;
          /**
           * Zeitpunkt_der_Aufgabe_der_Selbstnutzung_oder_Reinvestitionsabsicht
           */
          E1804801: string;
          /**
           * Nachzahlungen_für_mehrere_vorangegangene_Jahre_(laut_Nummer_11_der_Leistungsmitteilung)
           */
          E1804701: string;
        }[];
      }[];
      /**
       * Werbungskosten
       * @maxLength: 1
       */
      Wk: {
        /**
         * Werbungskosten_zu_den_Zeilen_4_und_21
         * @maxLength: 1
         */
        RBM_Nr_1_7_8_10_9c_9d: {
          /**
           * Einzelangaben
           * @maxLength: 99
           */
          Einz: {
            /**
             * Art_der_Aufwendungen
             */
            E1805301: string;
            /**
             * Betrag
             */
            E1805302: string;
          }[];
          /**
           * Summe
           * @maxLength: 1
           */
          Sum: {
            /**
             * Summe
             */
            E1805303: string;
          }[];
        }[];
        /**
         * Werbungskosten_zu_Zeile_5
         * @maxLength: 1
         */
        RBM_Nr_2: {
          /**
           * Einzelangaben
           * @maxLength: 99
           */
          Einz: {
            /**
             * Art_der_Aufwendungen
             */
            E1805304: string;
            /**
             * Betrag
             */
            E1805306: string;
          }[];
          /**
           * Summe
           * @maxLength: 1
           */
          Sum: {
            /**
             * Summe
             */
            E1805305: string;
          }[];
        }[];
        /**
         * Werbungskosten_zu_den_Zeilen_10,_15_und_18
         * @maxLength: 1
         */
        RBM_Nr_4_5_9a_6_9b: {
          /**
           * Einzelangaben
           * @maxLength: 99
           */
          Einz: {
            /**
             * Art_der_Aufwendungen
             */
            E1805104: string;
            /**
             * Betrag
             */
            E1805105: string;
          }[];
          /**
           * Summe
           * @maxLength: 1
           */
          Sum: {
            /**
             * Summe
             */
            E1805106: string;
          }[];
        }[];
        /**
         * Werbungskosten_zu_Zeile_22
         * @maxLength: 1
         */
        ZfA_Aufl_Wohnf: {
          /**
           * Einzelangaben
           * @maxLength: 99
           */
          Einz: {
            /**
             * Art_der_Aufwendungen
             */
            E1805501: string;
            /**
             * Betrag
             */
            E1805502: string;
          }[];
          /**
           * Summe
           * @maxLength: 1
           */
          Sum: {
            /**
             * Summe
             */
            E1805503: string;
          }[];
        }[];
        /**
         * Werbungskosten_zu_Zeile_23
         * @maxLength: 1
         */
        ZfA_Aufg_Selbstn: {
          /**
           * Einzelangaben
           * @maxLength: 99
           */
          Einz: {
            /**
             * Art_der_Aufwendungen
             */
            E1805602: string;
            /**
             * Betrag
             */
            E1805603: string;
          }[];
          /**
           * Summe
           * @maxLength: 1
           */
          Sum: {
            /**
             * Summe
             */
            E1805604: string;
          }[];
        }[];
        /**
         * Werbungskosten_zu_Zeile_9_sowie_zu_Nachzahlungen_(Zeile_26),_die_in_den_Einnahmen_der_Zeilen_4,_21_bis_23_enthalten_sind
         * @maxLength: 1
         */
        RBM_Nr_3_ua: {
          /**
           * Einzelangaben
           * @maxLength: 99
           */
          Einz: {
            /**
             * Art_der_Aufwendungen
             */
            E1805307: string;
            /**
             * Betrag
             */
            E1805308: string;
          }[];
          /**
           * Summe
           * @maxLength: 1
           */
          Sum: {
            /**
             * Summe
             */
            E1805309: string;
          }[];
        }[];
        /**
         * Werbungskosten_zu_Nachzahlungen_(Zeile_26),_die_in_den_Einnahmen_der_Zeilen_5,_10,_15_und_18_enthalten_sind
         * @maxLength: 1
         */
        RBM_11_Nachz: {
          /**
           * Einzelangaben
           * @maxLength: 99
           */
          Einz: {
            /**
             * Art_der_Aufwendungen
             */
            E1806201: string;
            /**
             * Betrag
             */
            E1806202: string;
          }[];
          /**
           * Summe
           * @maxLength: 1
           */
          Sum: {
            /**
             * Summe
             */
            E1806203: string;
          }[];
        }[];
      }[];
    }[];
  }
  export interface IBET {
    /**
     * laut_gesonderter_und_einheitlicher_Feststellung_(Beteiligungen)
     * @maxLength: 2
     */
    KAP_BET: {
      Person: ElsterPerson;
      /**
       * Erträge
       * @maxLength: 1
       */
      Ertr: {
        /**
         * $index$._Beteiligung
         * @maxLength: 99
         */
        Beteiligungen: {
          /**
           * Gemeinschaft,_Finanzamt_und_Steuernummer
           */
          E1903101: string;
        }[];
        /**
         * Erträge_mit_inländischem_Steuerabzug
         * @maxLength: 1
         */
        M_inl_St_Abz: {
          /**
           * Kapitalerträge
           */
          E1903301: string;
          /**
           * In_Zeile_6_enthaltene_Gewinne_aus_Aktienveräußerungen
           */
          E1903501: string;
          /**
           * In_Zeile_6_enthaltene_Gewinne_aus_der_Veräußerung_bestandsgeschützter_Alt-Anteile_im_Sinne_des_§_56_Absatz_6_Satz_1_Nummer_2_InvStG
           */
          E1920801: string;
          /**
           * Nicht_ausgeglichene_Verluste_ohne_Verluste_aus_der_Veräußerung_von_Aktien
           */
          E1903801: string;
          /**
           * Nicht_ausgeglichene_Verluste_aus_der_Veräußerung_von_Aktien
           */
          E1903901: string;
          /**
           * Verluste_aus_der_ganzen_oder_teilweisen_Uneinbringlichkeit_einer_Kapitalforderung,_Ausbuchung,_Übertragung_wertlos_gewordener_Wirtschaftsgüter_im_Sinne_des_§_20_Absatz_1_EStG_oder_aus_einem_sonstigen_Ausfall_von_Wirtschaftsgütern_im_Sinne_des_§_20_Absatz_1_EStG
           */
          E1921302: string;
        }[];
        /**
         * Erträge_ohne_inländischen_Steuerabzug
         * @maxLength: 1
         */
        O_inl_St_Abz: {
          /**
           * Kapitalerträge_(ohne_Beträge_laut_Zeile_22_der_Anlage_KAP-BET_sowie_ohne_Beträge_der_Zeile_47_der_Anlage_KAP)
           */
          E1904001: string;
          /**
           * In_Zeile_14_enthaltene_Gewinne_aus_Aktienveräußerungen
           */
          E1904201: string;
          /**
           * In_Zeile_14_enthaltene_Gewinne_aus_der_Veräußerung_bestandsgeschützter_Alt-Anteile_im_Sinne_des_§_56_Absatz_6_Satz_1_Nummer_2_InvStG
           */
          E1921301: string;
          /**
           * In_Zeile_14_enthaltene_Verluste_ohne_Verluste_aus_der_Veräußerung_von_Aktien
           */
          E1904202: string;
          /**
           * In_Zeile_14_enthaltene_Verluste_aus_der_Veräußerung_von_Aktien
           */
          E1904302: string;
          /**
           * Verluste_aus_der_ganzen_oder_teilweisen_Uneinbringlichkeit_einer_Kapitalforderung,_Ausbuchung,_Übertragung_wertlos_gewordener_Wirtschaftsgüter_im_Sinne_des_§_20_Absatz_1_EStG_oder_aus_einem_sonstigen_Ausfall_von_Wirtschaftsgütern_im_Sinne_des_§_20_Absatz_1_EStG
           */
          E1922101: string;
          /**
           * Gewinn_/_Verlust_aus_der_Veräußerung_anteiliger_Wirtschaftsgüter_bei_Veräußerung_einer_unmittelbaren_oder_mittelbaren_Beteiligung_an_einer_Personengesellschaft
           */
          E1904401: string;
          /**
           * In_Zeile_22_enthaltene_Gewinne_/_Verluste_aus_Aktienveräußerungen
           */
          E1904602: string;
          /**
           * In_Zeile_22_enthaltene_Verluste_aus_der_ganzen_oder_teilweisen_Uneinbringlichkeit_einer_Kapitalforderung,_Ausbuchung,_Übertragung_wertlos_gewordener_Wirtschaftsgüter_im_Sinne_des_§_20_Absatz_1_EStG_oder_aus_einem_sonstigen_Ausfall_von_Wirtschaftsgütern_im_Sinne_des_§_20_Absatz_1_EStG
           */
          E1922401: string;
        }[];
        /**
         * Erträge,_die_der_tariflichen_Einkommensteuer_unterliegen
         * @maxLength: 1
         */
        Tar_ESt_unterl: {
          /**
           * Hinzurechnungsbetrag_nach_§_10_AStG
           */
          E1904703: string;
          /**
           * Laufende_Einkünfte_aus_sonstigen_Kapitalforderungen_jeder_Art,_aus_stiller_Gesellschaft_und_partiarischen_Darlehen_(ohne_Betrag_laut_Zeile_49_der_Anlage_KAP)
           */
          E1904501: string;
          /**
           * Gewinne_aus_der_Veräußerung_oder_Einlösung_von_Kapitalanlagen_aus_sonstigen_Kapitalforderungen_jeder_Art,_aus_stiller_Gesellschaft_und_partiarischen_Darlehen,_Verluste_aus_der_ganzen_oder_teilweisen_Uneinbringlichkeit_der_Kapitalforderungen
           */
          E1904601: string;
          /**
           * Kapitalerträge_aus_Lebensversicherungen_im_Sinne_des_§_20_Absatz_1_Nummer_6_Satz_2_EStG
           */
          E1904704: string;
          /**
           * Bezüge_und_Einnahmen_im_Sinne_des_§_32d_Absatz_2_Nummer_4_EStG_(ohne_Betrag_laut_Zeile_49_der_Anlage_KAP)_-_Korrespondenzprinzip_-
           */
          E1922301: string;
        }[];
      }[];
      /**
       * Steuerabzugsbeträge_zu_Erträgen_in_den_Zeilen_6_bis_24
       * @maxLength: 1
       */
      St_Abz_Betr_Ert_m_o_inl_StAbz: {
        /**
         * Kapitalertragsteuer
         */
        E1904702: string;
        /**
         * Solidaritätszuschlag
         */
        E1904902: string;
        /**
         * Kirchensteuer_zur_Kapitalertragsteuer
         */
        E1904802: string;
        /**
         * Angerechnete_ausländische_Steuern
         */
        E1905002: string;
        /**
         * Anrechenbare_noch_nicht_angerechnete_ausländische_Steuern
         */
        E1905102: string;
        /**
         * Fiktive_ausländische_Quellensteuern_(nicht_in_den_Zeilen_33_und_/_oder_34_enthalten)
         */
        E1905202: string;
      }[];
      /**
       * Anzurechnende_Steuern_zu_Erträgen_in_den_Zeilen_26_bis_29_sowie_aus_anderen_Einkunftsarten
       * @maxLength: 1
       */
      Anzr_StErtr_tar_Est_u_and_Ek_Art: {
        /**
         * Kapitalertragsteuer
         */
        E1905301: string;
        /**
         * Solidaritätszuschlag
         */
        E1905501: string;
        /**
         * Kirchensteuer_zur_Kapitalertragsteuer
         */
        E1905401: string;
      }[];
    }[];
  }
  export interface ICorona {
    /**
     * Corona-Soforthilfen,_Überbrückungshilfen_und_vergleichbare_Zuschüsse
     * @maxLength: 1
     */
    Corona: {
      /**
       * -_in_den_Anlagen_G,_L_und_/_oder_S_der_Einkommensteuererklärung_sowie_in_den_jeweiligen_Gewinnermittlungen_als_steuerpflichtige_Betriebseinnahmen_enthalten_-
       * @maxLength: 1
       */
      Corona: {
        /**
         * $Person.Wert$
         * @maxLength: 2
         */
        ESt: {
          Person: ElsterPerson;
          /**
           * Wurden_im_Jahr_$VZ$_für_einen_/_mehrere_Betrieb(e)_und_/_oder_für_eine_/_mehrere_selbständige_Tätigkeit(en)_Corona-Soforthilfen,_Überbrückungshilfen_und_/_oder_vergleichbare_Zuschüsse_bezogen?
           */
          E0840401: string;
          /**
           * Falls_Zeile_4_mit_\"Ja\"_beantwortet_wurde:_Für_folgende_Betriebe_und_/_oder_selbständige_Tätigkeiten_wurden_Soforthilfen,_Überbrückungshilfen_und_/_oder_vergleichbare_Zuschüsse_bezogen_(Einzutragen_ist_für_jeden_Betrieb_der_Saldo_zwischen_den_erhaltenen_und_den_im_gleichen_Kalenderjahr_zurückgezahlten_Hilfen):
           * @maxLength: 1
           */
          Hilfen: {
            /**
             * Einzelangaben
             * @maxLength: 99
             */
            Einz: {
              /**
               * Bezeichnung_des_Betriebs_/_Betriebssteuernummer
               */
              E0840501: string;
              /**
               * Betrag
               */
              E0840503: string;
            }[];
            /**
             * Summe
             * @maxLength: 1
             */
            Sum: {
              /**
               * Gesamtbetrag_der_Soforthilfen,_Überbrückungshilfen_und_/_oder_vergleichbaren_Zuschüsse
               */
              E0841101: string;
            }[];
          }[];
        }[];
      }[];
    }[];
  }
  export interface IEst1A {
    /**
     * Hauptvordruck_ESt_1_A
     * @maxLength: 1
     */
    ESt1A: {
      /**
       * Art_der_Erklärung
       * @maxLength: 1
       */
      Art_Erkl: {
        /**
         * Einkommensteuererklärung
         */
        E0100001: string;
        /**
         * Festsetzung_der_Arbeitnehmer_-_Sparzulage
         */
        E0100002: string;
        /**
         * Erklärung_zur_Festsetzung_der_Kirchensteuer_auf_Kapitalerträge
         */
        E0100009: string;
        /**
         * Erklärung_zur_Feststellung_des_verbleibenden_Verlustvortrags
         */
        E0100003: string;
      }[];
      /**
       * Angaben_zu_Belegen
       * @maxLength: 1
       */
      Belege: {
        /**
         * Belege_werden_nachgereicht.
         */
        E0100012: string;

        /**
         * (Ankreuzfeld; Feld wird nicht gedruckt) Diese Steuererklärung wurde digital signiert.
         * TODO Attention, this field was manually added as its not part of the documentation...
         */
        E0100013: string;
      }[];
      /**
       * Angaben_zum_Finanzamt
       * @maxLength: 1
       */
      Finanzamt: {
        /**
         * Bei_Wohnsitzwechsel:_bisheriges_Finanzamt
         */
        E0100006: string;
      }[];
      /**
       * Allgemeine_Angaben
       * @maxLength: 1
       */
      Allg: {
        /**
         * Telefonische_Rückfragen_tagsüber_unter_Nummer
         */
        E0100008: string;

        /**
         * (Feld wird nicht direkt gedruckt; spezielle Darstellung auf Unterschriftenzeile) Datum zur Unterschrift auf der Unterschriftszeile; nur bei nicht authentifiziert übermittelten Fällen
         * TODO Attention, this field was manually added as its not part of the documentation...
         */
        E0102605: string;

        /**
         * Steuerpflichtige_Person._Nur_bei_Zusammenveranlagung:_Ehemann_oder_Person_A_(Ehegatte_A_/_Lebenspartner(in)_A_nach_dem_LPartG)
         * @maxLength: 1
         */
        A: {
          /**
           * Identifikationsnummer
           */
          E0100081: string;
          /**
           * Geburtsdatum
           */
          E0100401: string;
          /**
           * Name
           */
          E0100201: string;
          /**
           * Vorname
           */
          E0100301: string;
          /**
           * Titel,_akademischer_Grad
           */
          E0101004: string;
          /**
           * Religion
           */
          E0100402: string;
          /**
           * Straße_(derzeitige_Adresse)
           */
          E0101104: string;
          /**
           * Hausnummer
           */
          E0101206: string;
          /**
           * Hausnummerzusatz
           */
          E0101207: string;
          /**
           * Adressergänzung
           */
          E0101301: string;
          /**
           * Postleitzahl_(Inland)
           */
          E0100601: string;
          /**
           * Postleitzahl_(Ausland)
           */
          E0101405: string;
          /**
           * Wohnort
           */
          E0100602: string;
          /**
           * Staat_(falls_Wohnsitz_im_Ausland)
           */
          E0101403: string;
          /**
           * Ausgeübter_Beruf
           */
          E0100403: string;
          /**
           * Verheiratet_/_Lebenspartnerschaft_begründet_seit_dem
           */
          E0100701: string;
          /**
           * Verwitwet_seit_dem
           */
          E0100702: string;
          /**
           * Geschieden_/_Lebenspartnerschaft_aufgehoben_seit_dem
           */
          E0100703: string;
          /**
           * Dauernd_getrennt_lebend_seit_dem
           */
          E0100704: string;
        }[];

        /**
         * Bitte_füllen_Sie_die_Zeilen_22_bis_26_nur_aus,_wenn_die_Adressangaben_von_den_Zeilen_11_bis_15_abweichen
         * @maxLength: 1
         */
        B: {
          /**
           * Identifikationsnummer
           */
          E0100082: string;
          /**
           * Geburtsdatum
           */
          E0101001: string;
          /**
           * Name
           */
          E0100901: string;
          /**
           * Vorname
           */
          E0100801: string;
          /**
           * Titel,_akademischer_Grad
           */
          E0102003: string;
          /**
           * Religion
           */
          E0101002: string;
          /**
           * Straße
           */
          E0102105: string;
          /**
           * Hausnummer
           */
          E0102202: string;
          /**
           * Hausnummerzusatz
           */
          E0102203: string;
          /**
           * Adressergänzung
           */
          E0102301: string;
          /**
           * Postleitzahl_(Inland)
           */
          E0101701: string;
          /**
           * Postleitzahl_(Ausland)
           */
          E0102505: string;
          /**
           * Wohnort
           */
          E0101702: string;
          /**
           * Staat_(falls_Anschrift_im_Ausland)
           */
          E0102404: string;
          /**
           * Ausgeübter_Beruf
           */
          E0101003: string;
        }[];
        /**
         * Bei_Ehegatten_/_Lebenspartnern:_Veranlagungsart
         * @maxLength: 1
         */
        Vlg_Art: {
          /**
           * Zusammenveranlagung
           */
          E0101201: string;
          /**
           * Einzelveranlagung_von_Ehegatten_/_Lebenspartnern
           */
          E0102602: string;
          /**
           * Wir_haben_Gütergemeinschaft_vereinbart
           */
          E0101205: string;
        }[];
        /**
         * Bankverbindung
         * @maxLength: 1
         */
        BV: {
          /**
           * Es_ist_keine_Bankverbindung_vorhanden
           */
          E0102002: string;
          /**
           * IBAN_(inländisches_Geldinstitut)
           */
          E0102102: string;
          /**
           * IBAN_(ausländisches_Geldinstitut)
           */
          E0102603: string;
          /**
           * BIC_zur_IBAN_des_ausländischen_Geldinstituts
           */
          E0102201: string;
          /**
           * Kontoinhaber_ist_die_steuerpflichtige_Person,_nur_bei_Zusammenveranlagung:_Ehemann_/_Person_A
           */
          E0101601: string;
          /**
           * Kontoinhaber_ist_die_Ehefrau_/_Person_B
           */
          E0102402: string;
          /**
           * Name_des_Kontoinhabers_(im_Fall_der_Abtretung_bitte_amtlichen_Abtretungsvordruck_einreichen)
           */
          E0101602: string;
        }[];
        /**
         * Elektronische_Bekanntgabe_beziehungsweise_Bekanntgabe_in_Papierform_an_eine_andere_Person
         * @maxLength: 1
         */
        DIVA: {
          /**
           * Der_Steuerbescheid_soll_nicht_mir_/_uns_zugesandt_werden,_sondern:
           * @maxLength: 1
           */
          DIVA_Papier_Steuerberater: {
            /**
             * Name
             */
            E0101801: string;
            /**
             * Zustell-_/_Beraternummer
             */
            E0102103: string;
            /**
             * Vorname
             */
            E0101901: string;
            /**
             * Straße
             */
            E0103702: string;
            /**
             * Hausnummer
             */
            E0103805: string;
            /**
             * Hausnummerzusatz
             */
            E0103806: string;
            /**
             * Postfach
             */
            E0103902: string;
            /**
             * Postleitzahl_(Inland)
             */
            E0102502: string;
            /**
             * Postleitzahl_(Ausland)
             */
            E0103505: string;
            /**
             * Wohnort
             */
            E0102503: string;
            /**
             * Staat_(falls_Anschrift_im_Ausland)
             */
            E0103603: string;
          }[];

          /**
           * Geänderte_Bescheide_können_nicht_durch_Bereitstellung_zum_elektronischen_Abruf,_sondern_nur_in_Papierform_bekannt_gegeben_werden.
           * @maxLength: 1
           */
          DIVA_digital_StpflPerson: {
            /**
             * Einwilligung_in_die_Bescheidbekanntgabe_in_elektronischer_Form
             * @maxLength: 1
             */
            Einwilligung: {
              /**
               * Die_Hinweise_zur_Bescheidbekanntgabe_in_elektronischer_Form_habe_ich_zur_Kenntnis_genommen.
               */
              E0103121: string;
              /**
               * Ich_willige_in_die_Bescheidbekanntgabe_in_elektronischer_Form_ein._Einen_Bescheid_auf_dem_Postweg_erhalte_ich_dann_in_der_Regel_nicht_mehr_(Ausnahme_siehe_vorletzten_Absatz_der_Hinweise).
               */
              E0103104: string;
            }[];
            /**
             * Die_Benachrichtigung_über_den_bereitstehenden_Bescheid_in_elektronischer_Form_soll_an_folgende_E-Mail-Adresse_erfolgen
             * @maxLength: 1
             */
            Benachrichtigung: {
              /**
               * E-Mail-Adresse
               */
              E0103105: string;
              /**
               * Wiederholung_E-Mail-Adresse_(erneute_Eingabe_der_E-Mail-Adresse)
               */
              E0103106: string;
            }[];
          }[];

          /**
           * Die_mit_dieser_Steuererklärung_erteilte_Bekanntgabevollmacht_für_Angehörige_der_steuerberatenden_Berufe_im_Sinne_der_§§_3_und_4_StBerG_sowie_die_Einwilligung_zur_elektronischen_Bekanntgabe_gehen_anderen_erteilten_Bekanntgabevollmachten_für_diesen_Veranlagungszeitraum_vor.
           * @maxLength: 1
           */
          DIVA_digital_Steuerberater: {
            /**
             * Einwilligung_in_die_Bescheidbekanntgabe_in_elektronischer_Form
             * @maxLength: 1
             */
            Einwilligung: {
              /**
               * Die_Hinweise_zur_Bescheidbekanntgabe_in_elektronischer_Form_habe_ich_zur_Kenntnis_genommen.
               */
              E0103122: string;
              /**
               * Ich_willige_in_die_Bescheidbekanntgabe_in_elektronischer_Form_ein._Einen_Bescheid_auf_dem_Postweg_erhalte_ich_dann_in_der_Regel_nicht_mehr_(Ausnahme_siehe_drittletzten_Absatz_der_Hinweise).
               */
              E0103107: string;
            }[];
            /**
             * Bestätigung_der_Datenübermittlung_als_Angehörige(r)_der_steuerberatenden_Berufe
             * @maxLength: 1
             */
            Bestaetigung: {
              /**
               * Ich_bestätige,_dass_ich_eine_Angehörige_/_ein_Angehöriger_der_steuerberatenden_Berufe_im_Sinne_der_§§_3_und_4_des_StBerG_bin.
               */
              E0103108: string;
            }[];
            /**
             * Die_Benachrichtigung_über_den_bereitstehenden_elektronischen_Bescheid_soll_an_folgende_E-Mail-Adresse_erfolgen
             * @maxLength: 1
             */
            Benachrichtigung: {
              /**
               * E-Mail-Adresse
               */
              E0103109: string;
              /**
               * Wiederholung_E-Mail-Adresse_(erneute_Eingabe_der_E-Mail-Adresse)
               */
              E0103110: string;
            }[];

            /**
             * Der_Steuerbescheid_soll_nicht_der_/_dem_/_den_Steuerpflichtigen_bekannt_gegeben_werden,_sondern:
             * @maxLength: 1
             */
            Adresse: {
              /**
               * Name
               */
              E0103111: string;
              /**
               * Vorname
               */
              E0103113: string;
              /**
               * Straße
               */
              E0103114: string;
              /**
               * Hausnummer
               */
              E0103115: string;
              /**
               * Hausnummerzusatz
               */
              E0103116: string;
              /**
               * Postfach
               */
              E0103117: string;
              /**
               * Postleitzahl
               */
              E0103118: string;
              /**
               * Wohnort
               */
              E0103119: string;
            }[];
          }[];
        }[];
      }[];
      /**
       * Bei_der_Anfertigung_dieser_Steuererklärung_/_dieses_Antrags_hat_mitgewirkt:
       * @maxLength: 1
       */
      Mitwirk: {
        /**
         * Name
         */
        E0102401: string;
        /**
         * Vorname
         */
        E0102501: string;
        /**
         * Adresse
         */
        E0102601: string;
        /**
         * Wohnort
         */
        E0102701: string;
        /**
         * zusätzliche_Angaben
         */
        E0102702: string;
        /**
         * Telefon
         */
        E0102801: string;
        /**
         * Bearbeiterkennzeichen
         */
        E0102802: string;
        /**
         * Mandantennummer
         */
        E0102810: string;
        /**
         * Die_Steuererklärung_wurde_unter_Mitwirkung_eines_Angehörigen_der_steuerberatenden_Berufe_im_Sinne_der_§§_3_und_4_des_Steuerberatungsgesetzes_erstellt.
         */
        E0110906: string;
        /**
         * (Feld_wird_nicht_gedruckt)_Mandantennummer_für_Bescheiddatenübermittlung
         */
        E0102811: string;
      }[];
      /**
       * Antrag_auf_Festsetzung_der_Arbeitnehmer-Sparzulage:_$Person.Wert$
       * @maxLength: 2
       */
      AN_Sp_Zul: {
        Person: ElsterPerson;
        /**
         * Für_alle_vom_Anbieter_übermittelten_Vermögensbildungsbescheinigungen_wird_die_Festsetzung_der_Arbeitnehmer-Sparzulage_beantragt
         */
        E0109109: string;
      }[];

      /**
       * ohne_Beträge_laut_Zeile_28_der_Anlage_N_-
       * @maxLength: 2
       */
      Eink_Ers: {
        Person: ElsterPerson;
        /**
         * Einkommensersatzleistungen,_die_dem_Progressionsvorbehalt_unterliegen,_zum_Beispiel_Arbeitslosengeld,_Elterngeld,_Insolvenzgeld,_Krankengeld,_Mutterschaftsgeld,_Verdienstausfallentschädigung_(Infektionsschutzgesetz)
         * @maxLength: 1
         */
        Inl: {
          /**
           * Einzelangaben
           * @maxLength: 20
           */
          Einz: {
            /**
             * Art_der_Leistung
             */
            E0104110: string;
            /**
             * Betrag
             */
            E0104113: string;
          }[];
          /**
           * Summe
           * @maxLength: 1
           */
          Sum: {
            /**
             * Gesamtbetrag
             */
            E0104801: string;
          }[];
        }[];
        /**
         * Einkommensersatzleistungen:_Vergleichbare_Leistungen_im_Sinne_der_Zeile_43_aus_einem_EU-_/_EWR-Staat_oder_der_Schweiz
         * @maxLength: 1
         */
        Ausl: {
          /**
           * Einzelangaben
           * @maxLength: 20
           */
          Einz: {
            /**
             * Art_der_Leistung
             */
            E0103906: string;
            /**
             * Betrag
             */
            E0103907: string;
          }[];
          /**
           * Summe
           * @maxLength: 1
           */
          Sum: {
            /**
             * Gesamtbetrag
             */
            E0103910: string;
          }[];
        }[];
      }[];
      /**
       * Angaben_bei_Unterhaltsleistungen_an_bedürftige_Personen
       * @maxLength: 1
       */
      OG_Ber: {
        /**
         * Einnahmen_und_Zahlungen,_die_bei_der_Berechnung_der_Opfergrenze_zu_berücksichtigen_sind:
         * @maxLength: 1
         */
        Einn_Zahl: {
          /**
           * Bafög
           */
          E0125001: string;
          /**
           * Wohngeld
           */
          E0125002: string;
          /**
           * Steuerzahlungen
           */
          E0125003: string;
          /**
           * Steuererstattungen
           */
          E0125004: string;
        }[];
        /**
         * Weitere_steuerfreie_Einnahmen,_die_bei_der_Berechnung_der_Opfergrenze_zu_berücksichtigen_sind:
         * @maxLength: 1
         */
        Einn: {
          /**
           * Bezeichnung
           */
          E0125006: string;
          /**
           * Betrag
           */
          E0125007: string;
        }[];
        /**
         * Weitere_Zahlungen,_die_bei_der_Berechnung_der_Opfergrenze_zu_berücksichtigen_sind:
         * @maxLength: 1
         */
        Zahlung: {
          /**
           * Bezeichnung
           */
          E0125009: string;
          /**
           * Betrag
           */
          E0125010: string;
        }[];
      }[];

      /**
       * Wenn_über_die_Angaben_in_der_Steuererklärung_hinaus_weitere_oder_abweichende_Angaben_oder_Sachverhalte_berücksichtigt_werden_sollen,_kreuzen_Sie_bitte_dieses_Feld_an._Gleiches_gilt,_wenn_bei_den_in_der_Steuererklärung_erfassten_Angaben_bewusst_eine_von_der_Verwaltungsauffassung_abweichende_Rechtsauffassung_zugrunde_gelegt_wurde._Falls_Sie_mit_Abgabe_der_Steuererklärung_lediglich_Belege_und_Aufstellungen_einreichen,_ist_keine_Eintragung_vorzunehmen.
       * @maxLength: 1
       */
      Erg_Ang: {
        /**
         * Über_die_Angaben_in_der_Steuererklärung_hinaus_sind_weitere_oder_abweichende_Angaben_oder_Sachverhalte_zu_berücksichtigen.
         */
        E0109814: string;
        /**
         * Ergänzende_Angaben_zur_Steuererklärung
         */
        E0109815: string;
      }[];
      /**
       * Nur_bei_Wechsel_der_Religionszugehörigkeit_im_Kalenderjahr_$VZ$:_$Person.Wert$
       * @maxLength: 2
       */
      Rel_Wechs: {
        Person: ElsterPerson;
        /**
         * Religionszugehörigkeit_im_Januar
         */
        E0100410: string;
        /**
         * Religionszugehörigkeit_im_Februar
         */
        E0100411: string;
        /**
         * Religionszugehörigkeit_im_März
         */
        E0100412: string;
        /**
         * Religionszugehörigkeit_im_April
         */
        E0100413: string;
        /**
         * Religionszugehörigkeit_im_Mai
         */
        E0100414: string;
        /**
         * Religionszugehörigkeit_im_Juni
         */
        E0100415: string;
        /**
         * Religionszugehörigkeit_im_Juli
         */
        E0100416: string;
        /**
         * Religionszugehörigkeit_im_August
         */
        E0100417: string;
        /**
         * Religionszugehörigkeit_im_September
         */
        E0100418: string;
        /**
         * Religionszugehörigkeit_im_Oktober
         */
        E0100419: string;
        /**
         * Religionszugehörigkeit_im_November
         */
        E0100420: string;
        /**
         * Religionszugehörigkeit_im_Dezember
         */
        E0100421: string;
      }[];
    }[];
  }
  export interface IEST {
    /**
     * Weitere_Angaben_und_Anträge_in_Fällen_mit_Auslandsbezug
     * @maxLength: 1
     */
    WA_ESt: {
      /**
       * Angaben_bei_zeitweiser_unbeschränkter_Steuerpflicht_im_Kalenderjahr_$VZ$
       * @maxLength: 1
       */
      Zeitw_Stpfl_Unb: {
        /**
         * Wohnsitz_oder_gewöhnlicher_Aufenthalt_im_Inland_(Steuerpflichtige_Person_/_Ehemann_/_Person_A)_vom
         */
        E0105102: string;
        /**
         * Wohnsitz_oder_gewöhnlicher_Aufenthalt_im_Inland_(Steuerpflichtige_Person_/_Ehemann_/_Person_A)_bis
         */
        E0105103: string;
        /**
         * Wohnsitz_oder_gewöhnlicher_Aufenthalt_im_Inland_(Ehefrau_/_Person_B)_vom
         */
        E0105104: string;
        /**
         * Wohnsitz_oder_gewöhnlicher_Aufenthalt_im_Inland_(Ehefrau_/_Person_B)_bis
         */
        E0105105: string;
        /**
         * Ausländische_Einkünfte,_die_außerhalb_der_in_den_Zeilen_4_und_/_oder_5_genannten_Zeiträume_bezogen_wurden_und_nicht_der_deutschen_Einkommensteuer_unterlegen_haben
         */
        E0105101: string;
        /**
         * In_Zeile_6_enthaltene_außerordentliche_Einkünfte_im_Sinne_der_§§_34,_34b_EStG
         */
        E0105201: string;
      }[];
      /**
       * Bei_Beendigung_der_unbeschränkten_Steuerpflicht:_$Person.Wert$:
       * @maxLength: 2
       */
      Beend_Stpfl: {
        Person: ElsterPerson;
        /**
         * Mir_gehörte_im_Zeitpunkt_der_Beendigung_der_unbeschränkten_Steuerpflicht_(Wegzug)_eine_Beteiligung_im_Sinne_des_§_17_EStG_an_einer_in-_oder_ausländischen_Kapitalgesellschaft_/_Genossenschaft
         */
        E0110004: string;
        /**
         * Im_Zeitraum_zwischen_Beendigung_der_unbeschränkten_Steuerpflicht_bis_zur_Abgabe_der_Einkommensteuererklärung_$VZ$_lag_mein_Wohnsitz_zumindest_zeitweise_in_einem_niedrig_besteuernden_Gebiet_im_Sinne_des_§_2_Absatz_2_AStG
         */
        E0109812: string;
      }[];
      /**
       * Angaben_zum_Antrag_auf_unbeschränkte_Steuerpflicht_(§_1_Absatz_3_EStG)_(Nur_bei_Personen_ohne_Wohnsitz_oder_gewöhnlichen_Aufenthalt_im_Inland,_die_beantragen,_als_unbeschränkt_steuerpflichtig_behandelt_zu_werden)
       * @maxLength: 1
       */
      Antrag_unb_Stpfl: {
        /**
         * Ich_beantrage_für_die_Anwendung_personen-_und_familienbezogener_Steuervergünstigungen_als_unbeschränkt_steuerpflichtig_behandelt_zu_werden_(\"Bescheinigung_EU_/_EWR\"_oder_\"Bescheinigung_außerhalb_EU_/_EWR\"_bitte_einreichen).
         */
        E0109906: string;
        /**
         * Summe_der_nicht_der_deutschen_Einkommensteuer_unterliegenden_Einkünfte_(gegebenenfalls_0)_(Steuerpflichtige_Person_/_Ehemann_/_Person_A)
         */
        E0105301: string;
        /**
         * Wohnsitzstaat_(Steuerpflichtige_Person_/_Ehemann_/_Person_A)
         */
        E0110306: string;
        /**
         * Summe_der_nicht_der_deutschen_Einkommensteuer_unterliegenden_Einkünfte_(gegebenenfalls_0)_(Ehefrau_/_Person_B)
         */
        E0105303: string;
        /**
         * Wohnsitzstaat_(Ehefrau_/_Person_B)
         */
        E0110307: string;
        /**
         * In_Zeile_11_enthaltene_Kapitalerträge,_die_der_Abgeltungsteuer_unterliegen_oder_-_im_Fall_von_ausländischen_Kapitalerträgen_-_unterliegen_würden_(Steuerpflichtige_Person_/_Ehemann_/_Person_A)
         */
        E0110304: string;
        /**
         * In_Zeile_11_enthaltene_Kapitalerträge,_die_der_Abgeltungsteuer_unterliegen_oder_-_im_Fall_von_ausländischen_Kapitalerträgen_-_unterliegen_würden_(Ehefrau_/_Person_B)
         */
        E0110305: string;
        /**
         * In_Zeile_11_enthaltene_außerordentliche_Einkünfte_im_Sinne_der_§§_34,_34b_EStG_(Steuerpflichtige_Person_/_Ehegatten_/_Lebenspartner)
         */
        E0105401: string;
      }[];
      /**
       * Angaben_zum_im_EU-_/_EWR-Ausland_oder_in_der_Schweiz_lebenden_Ehegatten_/_Lebenspartner
       * @maxLength: 1
       */
      Eheg_EU_EWR: {
        /**
         * Ich_beantrage_als_Staatsangehöriger_eines_EU-_/_EWR-Staates_die_Anwendung_familienbezogener_Steuervergünstigungen._Nachweis_ist_einzureichen_(zum_Beispiel_\"Bescheinigung_EU_/_EWR\")._Die_nicht_der_deutschen_Besteuerung_unterliegenden_Einkünfte_beider_Ehegatten_/_Lebenspartner_sind_in_Zeile_11_enthalten.
         */
        E0105901: string;
      }[];
      /**
       * Angaben_bei_Angehörigen_des_deutschen_öffentlichen_Dienstes_ohne_Wohnsitz_oder_gewöhnlichen_Aufenthalt_im_Inland,_die_im_dienstlichen_Auftrag_außerhalb_der_EU_oder_des_EWR_tätig_sind
       * @maxLength: 1
       */
      Ang_Dt_oeff_D: {
        /**
         * Ich_beantrage_die_Anwendung_familienbezogener_Steuervergünstigungen_(\"Bescheinigung_EU_/_EWR\"_bitte_einreichen).
         */
        E0106101: string;
      }[];
      /**
       * Anzurechnende_Steuern:_$Person.Wert$:
       * @maxLength: 2
       */
      Anzur_Steu: {
        Person: ElsterPerson;
        /**
         * Steuerabzugsbeträge_nach_§_50a_EStG
         */
        E0110106: string;
        /**
         * Solidaritätszuschlag_zu_Zeile_16
         */
        E0110205: string;
      }[];

      /**
       * abweichend_von_den_Zeilen_11_bis_26_des_Hauptvordrucks_ESt_1_A
       * @maxLength: 2
       */
      WS_im_Ausl: {
        Person: ElsterPerson;
        /**
         * Einzelangaben
         * @maxLength: 10
         */
        Einz: {
          /**
           * Anschrift
           */
          E0110703: string;
          /**
           * Staat
           */
          E0110704: string;
          /**
           * vom
           */
          E0141801: string;
          /**
           * bis
           */
          E0141802: string;
        }[];
      }[];
      /**
       * Länderbezogener_Bericht_multinationaler_Unternehmensgruppen:_$Person.Wert$
       * @maxLength: 2
       */
      L_bez_Ber_mult_UG: {
        Person: ElsterPerson;
        /**
         * Ich_habe_ein_inländisches_Unternehmen_im_Sinne_des_§_138a_AO
         */
        E0142001: string;
      }[];
      /**
       * Mitteilung_von_grenzüberschreitenden_Steuergestaltungen
       * @maxLength: 1
       */
      Steuergest_138d_AO: {
        /**
         * Ich_bin_/_Wir_sind_Nutzer_einer_grenzüberschreitenden_Steuergestaltung_nach_§_138d_folgende_AO,_deren_steuerlicher_Vorteil_sich_erstmals_im_Jahr_$VZ$_auswirken_soll.
         */
        E0191101: string;
        /**
         * Einzelangaben
         * @maxLength: 10
         */
        Reg_Nr: {
          /**
           * Registriernummer
           */
          E0143201: string;
          /**
           * Offenlegungsnummer
           */
          E0143301: string;
          /**
           * Ich_habe_/_Wir_haben_im_Jahr_$VZ$_eine_grenzüberschreitende_Steuergestaltung_verwirklicht,_für_die_mir_/_uns_noch_keine_Registriernummer_und_Offenlegungsnummer_vorliegt.
           */
          E0143401: string;
        }[];
      }[];
    }[];
  }
  export interface IFW {
    /**
     * Förderung_des_Wohneigentums
     * @maxLength: 99
     */
    FW: {
      /**
       * Allgemeine_Angaben_zum_Objekt
       * @maxLength: 1
       */
      Allg: {
        /**
         * Lage_der_Wohnung_(Ort,_Straße,_Hausnummer)
         */
        E0400101: string;
        /**
         * Im_Ferien-_oder_Wochenendgebiet_belegen
         */
        E0400102: string;
        /**
         * Zum_Dauerwohnen_baurechtlich_zugelassen
         */
        E0400103: string;
        /**
         * Angaben_zum_Eigentum
         * @maxLength: 5
         */
        Eigent: {
          /**
           * Eigentümer_(Namen,_gegebenenfalls_Miteigentumsanteile)
           */
          E0400201: string;
          /**
           * Miteigentumsanteil
           */
          E0400203: string;
        }[];
        /**
         * Weitere_Angaben_zum_Objekt
         * @maxLength: 1
         */
        Weit_Ang: {
          /**
           * Einfamilienhaus_/_Eigentumswohnung
           */
          E0403001: string;
          /**
           * Anderes_Haus
           */
          E0403002: string;
          /**
           * mit_Anzahl_Wohnungen
           */
          E0403003: string;
          /**
           * davon_eigengenutzt:
           */
          E0403004: string;
          /**
           * Ausbau_/_Erweiterung_einer_eigengenutzten_Wohnung
           */
          E0403005: string;
          /**
           * Kaufvertrag_vom
           */
          E0403101: string;
          /**
           * Bauantrag_gestellt_am
           */
          E0403102: string;
          /**
           * Baubeginn_am
           */
          E0403103: string;
          /**
           * Angeschafft_am
           */
          E0403104: string;
          /**
           * Fertig_gestellt_am
           */
          E0403105: string;
          /**
           * Eigengenutzt_ab
           */
          E0403106: string;
          /**
           * Nutzfläche_des_Hauses_in_m²
           */
          E0403107: string;
          /**
           * Der_Abzugsbetrag_wird_für_ein_Folgeobjekt_beansprucht
           */
          E0403201: string;
          /**
           * Fläche_der_Wohnung_/_Erweiterung_/_des_Anbaus_in_m²
           */
          E0403203: string;
          /**
           * davon_eigenbetrieblich_/_beruflich_genutzt_oder_vermietet_in_m²
           */
          E0403204: string;
          /**
           * Für_das_Objekt_laut_Zeile_4_wurde_ein_Antrag_auf_Eigenheimzulage_gestellt.
           */
          E0403302: string;
          /**
           * Für_folgende_Objekte_wurden_bereits_Abzugsbeträge_/_erhöhte_Absetzungen_beansprucht:
           */
          E0403301: string;
        }[];
      }[];
      /**
       * Abzugsbetrag_nach_§_10f_EStG
       * @maxLength: 1
       */
      Abz_Betr_P10f: {
        /**
         * Bei_Bauantrag_/_Einreichung_der_Bauunterlagen_vor_dem_1.1.2004:_Aufwendungen
         * @maxLength: 1
         */
        Ant_Unt_v_2004: {
          /**
           * wie_Vorjahr
           */
          E0401001: string;
          /**
           * fertig_gestellt_$VZ$
           */
          E0401002: string;
          /**
           * Abzugsbetrag_bis_zu_10_Prozent
           */
          E0401003: string;
        }[];
        /**
         * Bei_Bauantrag_/_Einreichung_der_Bauunterlagen_nach_dem_31.12.2003:_Aufwendungen
         * @maxLength: 1
         */
        Ant_Unt_n_2003: {
          /**
           * wie_Vorjahr
           */
          E0401402: string;
          /**
           * fertig_gestellt_$VZ$
           */
          E0401101: string;
          /**
           * Abzugsbetrag_bis_zu_9_Prozent
           */
          E0401102: string;
        }[];
      }[];
      /**
       * Abzugsbetrag_nach_§_10e_EStG
       * @maxLength: 1
       */
      Abz_Betr_P10e: {
        /**
         * bei_Kaufvertrag_/_Bauantrag_/_Herstellungsbeginn_vor_dem_1.1.1996,_wenn_kein_Antrag_auf_Eigenheimzulage_gestellt_wird:
         * @maxLength: 1
         */
        KV_BA_Hbeg_v_1996: {
          /**
           * Abzugsbetrag_wie_$VZ-1$
           */
          E0403401: string;
          /**
           * Abzugsbetrag_nach_besonderer_Berechnung
           */
          E0403501: string;
          TODO_FK_E0403402: {
            /**
             * Nachholung_von_Abzugsbeträgen_nach_besonderer_Berechnung_(nachträgliche_Anschaffungs-_/_Herstellungskosten,_noch_nicht_in_Anspruch_genommene_Abzugsbeträge)
             */
            E0401803: string;
            /**
             * $VZ$_werden_in_Anspruch_genommen
             */
            E0401804: string;
          }[];
          /**
           * Anschaffungs-_/_Herstellungskosten
           * @maxLength: 1
           */
          AK_HK: {
            /**
             * Grund_und_Boden_insgesamt
             */
            E0403601: string;
            /**
             * davon_50%
             */
            E0403602: string;
            /**
             * Gebäude
             */
            E0403603: string;
          }[];
          /**
           * Nachträgliche_Anschaffungs-_/_Herstellungskosten_$VZ$
           * @maxLength: 1
           */
          N_AK_HK: {
            /**
             * Grund_und_Boden_insgesamt
             */
            E0403701: string;
            /**
             * davon_50%
             */
            E0403702: string;
            /**
             * Gebäude
             */
            E0403703: string;
          }[];
          /**
           * Zusammenstellung_der_Aufwendungen
           * @maxLength: 1
           */
          Zus_Stell_AW: {
            /**
             * Eine_Zusammenstellung_der_erstmals_geltend_gemachten_Aufwendungen_hat_vorgelegen.
             */
            E0403604: string;
            /**
             * Eine_Zusammenstellung_der_erstmals_geltend_gemachten_Aufwendungen_ist_beigefügt.
             */
            E0403704: string;
          }[];
          /**
           * Summe
           * @maxLength: 1
           */
          Sum: {
            /**
             * Grund_und_Boden,_davon_50%
             */
            E0403801: string;
            /**
             * Gebäude
             */
            E0403802: string;
            /**
             * Gesamtsumme
             */
            E0403803: string;
          }[];
          /**
           * auf_die_eigengenutzte_Wohnung_entfallen
           * @maxLength: 1
           */
          Ant_eig_Whg: {
            /**
             * Prozent
             */
            E0403901: string;
            /**
             * Betrag
             */
            E0403902: string;
          }[];
          /**
           * davon_entfallen_auf_eigenbetrieblich_/_beruflich_genutzte_oder_vermietete_Räume
           * @maxLength: 1
           */
          Ntzg_betr_beruf: {
            /**
             * Art_der_Nutzung
             */
            E0404001: string;
            /**
             * Prozent
             */
            E0404002: string;
            /**
             * Betrag
             */
            E0404003: string;
          }[];
          /**
           * Bemessungsgrundlage
           * @maxLength: 1
           */
          BMG: {
            /**
             * Bemessungsgrundlage_(höchstens_168.740,-_EUR,_bei_Kaufvertrag_nach_dem_31.12.1993_einer_mehr_als_2_Kalenderjahre_vor_der_Anschaffung_fertig_gestellten_Wohnung:_76.694,-_EUR)
             */
            E0404101: string;
          }[];
          /**
           * Abzugsbetrag
           * @maxLength: 1
           */
          Abz_Betr: {
            /**
             * nach_§_10e_EStG:_Kaufvertrag_/_Bauantrag_/_Herstellungsbeginn_nach_dem_30.09.1991:_->_6%_(1._bis_4._Jahr)
             */
            E0404201: string;
            /**
             * nach_§_10e_EStG:_Kaufvertrag_/_Bauantrag_/_Herstellungsbeginn_nach_dem_30.09.1991:_->_5%_(5._bis_8._Jahr)
             */
            E0404202: string;
          }[];
          /**
           * Nachholung_von_Abzugsbeträgen
           * @maxLength: 1
           */
          Nachhol_Abz: {
            /**
             * Nachholung_von_Abzugsbeträgen
             */
            E0404301: string;
            /**
             * davon_%
             */
            E0404302: string;
            /**
             * Anteiliger_Betrag
             */
            E0404303: string;
            /**
             * die_vor_$VZ$_nicht_in_Anspruch_genommen_wurden_(nur_bei_Kaufvertrag_/_Bauantrag_/_Herstellungsbeginn_nach_dem_30.9.1991)
             */
            E0404401: string;
            /**
             * Summe_Abzugsbeträge
             */
            E0404402: string;
          }[];
          /**
           * $VZ$_werden_in_Anspruch_genommen
           * @maxLength: 1
           */
          Anspruch_VZ: {
            /**
             * Betrag
             */
            E0404501: string;
          }[];
        }[];
      }[];
      /**
       * Steuerermäßigung_für_Kinder_bei_Inanspruchnahme_eines_Abzugsbetrags_nach_§_10e_Absätze_1_bis_5_EStG
       * @maxLength: 1
       */
      St_Erm_Kind: {
        /**
         * Antrag_auf_Steuerermäßigung_nach_§_34f_Absätze_2_und_3_EStG
         * @maxLength: 1
         */
        P34f_Abs_2_3: {
          /**
           * Anzahl_der_Kinder,_die_im_Begünstigungszeitraum_auf_Dauer_zum_Haushalt_gehörten_(vergleiche_Anlage(n)_Kind)
           */
          E0405901: string;
          /**
           * In_den_Vorjahren_nicht_berücksichtigter_§_34f_EStG-Betrag
           */
          E0405902: string;
        }[];
      }[];
      /**
       * Zusätzliche_Angaben_zu_Zuschüssen
       * @maxLength: 1
       */
      Zus_Ang_Zuschue: {
        /**
         * $VZ$_vereinnahmte_oder_bewilligte_Zuschüsse_aus_öffentlichen_Mitteln_zu_den_Anschaffungs-_/_Herstellungskosten_(laut_Erläuterung)
         */
        E0402204: string;
        /**
         * Erläuterung_zu_vereinnahmten_oder_bewilligten_Zuschüssen
         */
        E0402205: string;
      }[];
    }[];
  }
  export interface IGRE {
    /**
     * zur_Einkommensteuererklärung_von_Grenzgängern
     * @maxLength: 2
     */
    N_GRE: {
      Person: ElsterPerson;
      /**
       * $index$._Staat
       * @maxLength: 9
       */
      Arb_V: {
        /**
         * 1._Ausländische_Einkünfte_aus_nichtselbständiger_Arbeit
         * @maxLength: 1
         */
        Ek: {
          /**
           * als_Grenzgänger_nach
           */
          E0220403: string;
        }[];
        /**
         * Angaben_zum_Arbeitslohn
         * @maxLength: 1
         */
        ArbL: {
          /**
           * Bruttoarbeitslohn_laut_beigefügtem_Lohnausweis_des_Arbeitgebers_nebst_Anlagen_(bei_Grenzgängern_in_die_Schweiz:_laut_Zeile_8_des_Lohnausweises;_bitte_auch_Gehaltsmitteilungen_einreichen)
           * @maxLength: 1
           */
          Brutto_AL: {
            /**
             * Betrag_in_CHF
             */
            E0220601: string;
            /**
             * Betrag_in_EUR_(gegebenenfalls_umgerechnet)
             */
            E0220602: string;
          }[];

          /**
           * (soweit_im_Bruttoarbeitslohn_laut_Zeile_5_enthalten)
           * @maxLength: 1
           */
          Abz_Stfr_Bez: {
            /**
             * Leistungen_aus_der_Arbeitslosenversicherung
             * @maxLength: 1
             */
            AVers: {
              /**
               * Betrag_in_CHF
               */
              E0221101: string;
              /**
               * Betrag_in_EUR_(gegebenenfalls_umgerechnet)
               */
              E0221102: string;
            }[];
            /**
             * Kinder-_und_Ausbildungszulage
             * @maxLength: 1
             */
            Ki_Ausb_Zul: {
              /**
               * Betrag_in_CHF
               */
              E0220801: string;
              /**
               * Betrag_in_EUR_(gegebenenfalls_umgerechnet)
               */
              E0220802: string;
            }[];
            /**
             * Steuerfreies_Krankentaggeld
             * @maxLength: 1
             */
            KTG: {
              /**
               * Betrag_in_CHF
               */
              E0220903: string;
              /**
               * Betrag_in_EUR_(gegebenenfalls_umgerechnet)
               */
              E0220904: string;
            }[];
            /**
             * SUVA_-_Geld_(laut_ergänzender_Bescheinigung_des_Arbeitgebers)
             * @maxLength: 1
             */
            SUVA: {
              /**
               * Betrag_in_CHF
               */
              E0221001: string;
              /**
               * Betrag_in_EUR_(gegebenenfalls_umgerechnet)
               */
              E0221002: string;
            }[];
            /**
             * Sonntags-,_Feiertags-_und_Nachtzuschläge
             * @maxLength: 1
             */
            Zuschl: {
              /**
               * Betrag_in_CHF
               */
              E0220901: string;
              /**
               * Betrag_in_EUR_(gegebenenfalls_umgerechnet)
               */
              E0220902: string;
            }[];
            /**
             * Mutterschaftsentschädigung_nach_EOG,_IV-Taggelder
             * @maxLength: 1
             */
            Muttersch_IV: {
              /**
               * Betrag_in_CHF
               */
              E0221105: string;
              /**
               * Betrag_in_EUR_(gegebenenfalls_umgerechnet)
               */
              E0221106: string;
            }[];
            /**
             * Direktversicherungsbeiträge
             * @maxLength: 1
             */
            DirektV: {
              /**
               * Betrag_in_CHF
               */
              E0221203: string;
              /**
               * Betrag_in_EUR_(gegebenenfalls_umgerechnet)
               */
              E0221204: string;
            }[];
            /**
             * Sonstige_(zum_Beispiel_Optionsrechte)
             * @maxLength: 1
             */
            Sonst: {
              /**
               * Bezeichnung
               */
              E0221301: string;
              /**
               * Betrag_in_CHF
               */
              E0221302: string;
              /**
               * Betrag_in_EUR_(gegebenenfalls_umgerechnet)
               */
              E0221303: string;
            }[];
            /**
             * Verbleiben
             * @maxLength: 1
             */
            Verbl: {
              /**
               * Betrag_in_CHF
               */
              E0221401: string;
              /**
               * Betrag_in_EUR_(gegebenenfalls_umgerechnet)
               */
              E0221402: string;
            }[];
          }[];

          /**
           * (soweit_im_Bruttoarbeitslohn_nicht_enthalten)
           * @maxLength: 1
           */
          Zuz_stpfl: {
            /**
             * Fahrtkostenersatz
             * @maxLength: 1
             */
            Fahrk_Ers: {
              /**
               * Betrag_in_CHF
               */
              E0221601: string;
              /**
               * Betrag_in_EUR_(gegebenenfalls_umgerechnet)
               */
              E0221602: string;
            }[];
            /**
             * Spesen
             * @maxLength: 1
             */
            Spesen: {
              /**
               * Betrag_in_CHF
               */
              E0221701: string;
              /**
               * Betrag_in_EUR_(gegebenenfalls_umgerechnet)
               */
              E0221702: string;
            }[];

            /**
             * (vergleiche_Zeile_96)
             * @maxLength: 1
             */
            AG_Beitr_Koll_KTG: {
              /**
               * Betrag_in_CHF
               */
              E0221703: string;
              /**
               * Betrag_in_EUR_(gegebenenfalls_umgerechnet)
               */
              E0221704: string;
            }[];

            /**
             * (vergleiche_Zeile_97)
             * @maxLength: 1
             */
            AG_Beitr_NBUV: {
              /**
               * Betrag_in_CHF
               */
              E0221603: string;
              /**
               * Betrag_in_EUR_(gegebenenfalls_umgerechnet)
               */
              E0221604: string;
            }[];
            /**
             * Beiträge_des_Arbeitgebers_ins_Überobligatorium_(Säule_2b_der_Schweizer_Altersvorsorge)
             * @maxLength: 1
             */
            AG_Beitr_Ueberobl: {
              /**
               * Betrag_in_CHF
               */
              E0221705: string;
              /**
               * Betrag_in_EUR_(gegebenenfalls_umgerechnet)
               */
              E0221706: string;
            }[];
            /**
             * Corona-Erwerbsersatzentschädigung_der_AHV/IV_wegen_Unterbrechung_der_nichtselbständigen_Erwerbstätigkeit_(laut_gesondertem_Nachweis)
             * @maxLength: 1
             */
            Corona: {
              /**
               * Betrag_in_CHF
               */
              E0221904: string;
              /**
               * Betrag_in_EUR_(gegebenenfalls_umgerechnet)
               */
              E0221905: string;
            }[];
            /**
             * Sonstige_(zum_Beispiel_Wert_der_überlassenen_Aktien,_PKW-Überlassung)
             * @maxLength: 1
             */
            Sonst: {
              /**
               * Bezeichnung
               */
              E0221901: string;
              /**
               * Betrag_in_CHF
               */
              E0221902: string;
              /**
               * Betrag_in_EUR_(gegebenenfalls_umgerechnet)
               */
              E0221903: string;
            }[];
          }[];
          /**
           * Steuerpflichtiger_Arbeitslohn
           * @maxLength: 1
           */
          Stpfl_ArbL: {
            /**
             * Betrag_in_CHF
             */
            E0222201: string;
            /**
             * Betrag_in_EUR_(gegebenenfalls_umgerechnet)
             */
            E0222202: string;
          }[];
          /**
           * In_Zeile_21_enthaltene_ermäßigt_zu_besteuernde_Bezüge_(zum_Beispiel_Entschädigungen,_Arbeitslohn_für_mehrere_Jahre)
           * @maxLength: 1
           */
          Erm_Bez: {
            /**
             * Betrag_in_CHF
             */
            E0222401: string;
            /**
             * Betrag_in_EUR_(gegebenenfalls_umgerechnet)
             */
            E0222402: string;
          }[];
          /**
           * In_der_Schweiz_erhobene_Abzugssteuer_(höchstens_4,5_Prozent_von_Zeile_5_und_19)
           * @maxLength: 1
           */
          Abzugsst_CH: {
            /**
             * Betrag_in_CHF
             */
            E0222301: string;
            /**
             * Betrag_in_EUR_(gegebenenfalls_umgerechnet)
             */
            E0222302: string;
          }[];
          /**
           * darin_enthaltene_Abzugssteuer_auf_Erwerbsersatzentschädigungen_laut_Zeile_19
           * @maxLength: 1
           */
          Abzugsst_CH_Cor: {
            /**
             * Betrag_in_CHF
             */
            E0222403: string;
            /**
             * Betrag_in_EUR_(gegebenenfalls_umgerechnet)
             */
            E0222404: string;
          }[];
          /**
           * Angaben_über_Zeiten_und_Gründe_der_Nichtbeschäftigung
           * @maxLength: 1
           */
          Nichtbeschaeft: {
            /**
             * Begründung
             */
            E0222801: string;
          }[];
          /**
           * Steuerfrei_erhaltene_Aufwandsentschädigungen_/_Einnahmen
           * @maxLength: 1
           */
          Stfr_Entsch: {
            /**
             * aus_der_Tätigkeit_als
             */
            E0223101: string;
            /**
             * Betrag_in_EUR
             */
            E0223102: string;
          }[];
        }[];

        /**
         * ohne_Beträge_laut_Zeile_84_und_85_-
         * @maxLength: 1
         */
        Wk: {
          /**
           * Wege_zwischen_Wohnung_und_erster_Tätigkeitsstätte_/_Sammelpunkt_/_weiträumigem_Tätigkeitsgebiet_(Entfernungspauschale)
           * @maxLength: 1
           */
          EP: {
            /**
             * Erste_Tätigkeitsstätte_($#/N_GRE/Arb_V/Wk/EP/Erste_Taetig$._Angabe)
             * @maxLength: 50
             */
            Erste_Taetig: {
              /**
               * Erste_Tätigkeitsstätte_(Postleitzahl,_Ort_und_Straße)
               */
              E0223401: string;
              /**
               * vom_-_bis
               */
              E0223103: string;
              /**
               * Arbeitstage_je_Woche
               */
              E0223402: string;
              /**
               * Urlaubs-,_Krankheits-,_Heimarbeits-_und_Dienstreisetage
               */
              E0223403: string;
              /**
               * aufgesucht_an_Tagen
               */
              E0223701: string;
              /**
               * einfache_Entfernung_(auf_volle_Kilometer_abgerundet)
               */
              E0223702: string;
              /**
               * davon_mit_eigenem_oder_zur_Nutzung_überlassenen_PKW_zurückgelegt
               */
              E0223703: string;
              /**
               * davon_mit_Sammelbeförderung_des_Arbeitgebers_zurückgelegt
               */
              E0223704: string;
              /**
               * davon_mit_öffentlichen_Verkehrsmitteln,_Motorrad,_Fahrrad_oder_Ähnliches,_als_Fußgänger,_als_Mitfahrer_einer_Fahrgemeinschaft_zurückgelegt
               */
              E0223705: string;
              /**
               * Aufwendungen_für_Fahrten_mit_öffentlichen_Verkehrsmitteln_(ohne_Flug-_und_Fährkosten)
               */
              E0223405: string;
              /**
               * Behinderungsgrad_mindestens_70_oder_mindestens_50_und_Merkzeichen_\"G\"
               */
              E0223706: string;
            }[];
            /**
             * Sammelpunkt_/_nächstgelegener_Zugang_zum_weiträumigen_Tätigkeitsgebiet_($#/N_GRE/Arb_V/Wk/EP/Sammelp$._Angabe)
             * @maxLength: 50
             */
            Sammelp: {
              /**
               * Sammelpunkt_/_nächstgelegener_Zugang_zum_weiträumigen_Tätigkeitsgebiet_(Postleitzahl,_Ort_und_Straße)
               */
              E0223304: string;
              /**
               * vom_-_bis
               */
              E0223305: string;
              /**
               * Arbeitstage_je_Woche
               */
              E0223306: string;
              /**
               * Urlaubs-,_Krankheits-,_Heimarbeits-_und_Dienstreisetage
               */
              E0223307: string;
              /**
               * aufgesucht_an_Tagen
               */
              E0223605: string;
              /**
               * einfache_Entfernung_(auf_volle_Kilometer_abgerundet)
               */
              E0223606: string;
              /**
               * davon_mit_eigenem_oder_zur_Nutzung_überlassenem_PKW_zurückgelegt
               */
              E0223607: string;
              /**
               * davon_mit_Sammelbeförderung_des_Arbeitgebers_zurückgelegt
               */
              E0223608: string;
              /**
               * davon_mit_öffentlichen_Verkehrsmitteln,_Motorrad,_Fahrrad_oder_Ähnliches,_als_Fußgänger,_als_Mitfahrer_einer_Fahrgemeinschaft_zurückgelegt
               */
              E0223609: string;
              /**
               * Aufwendungen_für_Fahrten_mit_öffentlichen_Verkehrsmitteln_(ohne_Flug-_und_Fährkosten)
               */
              E0223610: string;
              /**
               * Behinderungsgrad_mindestens_70_oder_mindestens_50_und_Merkzeichen_\"G\"
               */
              E0223614: string;
            }[];
          }[];
          /**
           * Beiträge_zu_Berufsverbänden
           * @maxLength: 1
           */
          Berufsverb: {
            /**
             * Einzelangaben
             * @maxLength: 5
             */
            Einzel: {
              /**
               * Bezeichnung_der_Verbände
               */
              E0224301: string;
              /**
               * Betrag
               */
              E0224303: string;
            }[];
            /**
             * Summe
             * @maxLength: 1
             */
            Sum: {
              /**
               * Betrag
               */
              E0224302: string;
            }[];
          }[];
          /**
           * Aufwendungen_für_Arbeitsmittel_-_soweit_nicht_steuerfrei_ersetzt_-
           * @maxLength: 1
           */
          Arbeitsmittel: {
            /**
             * Einzelangaben
             * @maxLength: 99
             */
            Einz: {
              /**
               * Art_der_Arbeitsmittel
               */
              E0224401: string;
              /**
               * Betrag
               */
              E0224402: string;
            }[];
            /**
             * Summe
             * @maxLength: 1
             */
            Sum: {
              /**
               * Betrag
               */
              E0224501: string;
            }[];
          }[];
          /**
           * Aufwendungen_für_ein_häusliches_Arbeitszimmer
           * @maxLength: 1
           */
          Arb_Zim: {
            /**
             * Einzelangaben
             * @maxLength: 99
             */
            Einz: {
              /**
               * Art_der_Aufwendungen
               */
              E0224601: string;
              /**
               * Betrag
               */
              E0224602: string;
            }[];
            /**
             * Summe
             * @maxLength: 1
             */
            Sum: {
              /**
               * Betrag
               */
              E0224603: string;
            }[];
          }[];
          /**
           * Fortbildungskosten_-_soweit_nicht_steuerfrei_ersetzt_-
           * @maxLength: 1
           */
          Fortb: {
            /**
             * Einzelangaben
             * @maxLength: 99
             */
            Einz: {
              /**
               * Bezeichnung
               */
              E0224201: string;
              /**
               * Betrag
               */
              E0224202: string;
            }[];
            /**
             * Summe
             * @maxLength: 1
             */
            Sum: {
              /**
               * Betrag
               */
              E0224203: string;
            }[];
          }[];
          /**
           * Weitere_Werbungskosten_-_soweit_nicht_steuerfrei_ersetzt_-
           * @maxLength: 1
           */
          Weitere_Wk: {
            /**
             * Flug-_und_Fährkosten_bei_Wegen_zwischen_Wohnung_und_erster_Tätigkeitsstätte_/_Sammelpunkt_/_weiträumigem_Tätigkeitsgebiet
             * @maxLength: 99
             */
            Flug: {
              /**
               * Art_der_Aufwendungen
               */
              E0224204: string;
              /**
               * Betrag
               */
              E0224205: string;
            }[];
            /**
             * Sonstiges_(zum_Beispiel_Bewerbungskosten,_Kontoführungsgebühren)
             * @maxLength: 99
             */
            Sonst: {
              /**
               * Art_der_Aufwendungen
               */
              E0224701: string;
              /**
               * Betrag
               */
              E0224702: string;
            }[];
            /**
             * Summe
             * @maxLength: 1
             */
            Sum: {
              /**
               * Betrag
               */
              E0224901: string;
            }[];
          }[];
          /**
           * Reisekosten_bei_beruflich_veranlassten_Auswärtstätigkeiten
           * @maxLength: 1
           */
          AWT: {
            /**
             * Die_Fahrten_wurden_ganz_oder_teilweise_mit_einem_Firmenwagen_oder_im_Rahmen_einer_unentgeltlichen_Sammelbeförderung_des_Arbeitgebers_durchgeführt
             */
            E0224403: string;
            /**
             * Fahrt-_und_Übernachtungskosten,_Reisenebenkosten
             */
            E0224604: string;
            /**
             * Betrag
             */
            E0224605: string;
            /**
             * Pauschbeträge_für_Berufskraftfahrer_bei_Übernachtung_im_Kfz_(Anzahl_der_Tage)
             */
            E0224802: string;
            /**
             * Vom_Arbeitgeber_steuerfrei_ersetzt
             */
            E0224703: string;
          }[];
          /**
           * Pauschbeträge_für_Mehraufwendungen_für_Verpflegung
           * @maxLength: 1
           */
          VMA: {
            /**
             * Bei_einer_Auswärtstätigkeit_im_Inland
             * @maxLength: 1
             */
            Inl: {
              /**
               * Abwesenheit_von_mehr_als_8_Stunden_(bei_Auswärtstätigkeit_ohne_Übernachtung):_Anzahl_der_Tage
               */
              E0224710: string;
              /**
               * An-_und_Abreisetage_(bei_einer_mehrtägigen_Auswärtstätigkeit_mit_Übernachtung):_Anzahl_der_Tage
               */
              E0224709: string;
              /**
               * Abwesenheit_von_24_Stunden:_Anzahl_der_Tage
               */
              E0224711: string;
              /**
               * Kürzungsbeträge_wegen_Mahlzeitengestellung_(eigene_Zuzahlungen_sind_gegebenenfalls_gegenzurechnen)
               */
              E0224801: string;
            }[];
            /**
             * Bei_einer_Auswärtstätigkeit_im_Ausland
             * @maxLength: 1
             */
            Ausl: {
              /**
               * Aufstellung_zur_Berechnung_der_Mehraufwendungen_für_Verpflegung
               * @maxLength: 99
               */
              Aufst: {
                /**
                 * Ausländischer_Staat
                 */
                E0224903: string;
                /**
                 * Abwesenheit_von_mehr_als_8_Stunden_(bei_Auswärtstätigkeit_ohne_Übernachtung):_Anzahl_der_Tage
                 */
                E0224904: string;
                /**
                 * Pauschbetrag_bei_Abwesenheit_von_mehr_als_8_Stunden_(bei_Auswärtstätigkeit_ohne_Übernachtung)
                 */
                E0224905: string;
                /**
                 * An-_und_Abreisetage_(bei_einer_mehrtägigen_Auswärtstätigkeit_mit_Übernachtung):_Anzahl_der_Tage
                 */
                E0224906: string;
                /**
                 * Pauschbetrag_für_An-_und_Abreisetage_(bei_einer_mehrtägigen_Auswärtstätigkeit_mit_Übernachtung)
                 */
                E0224907: string;
                /**
                 * Abwesenheit_von_24_Stunden:_Anzahl_der_Tage
                 */
                E0224908: string;
                /**
                 * Pauschbetrag_bei_Abwesenheit_von_24_Stunden
                 */
                E0224909: string;
              }[];
              /**
               * Kürzungsbetrag
               * @maxLength: 1
               */
              Kuerz_Mahlz: {
                /**
                 * Kürzungsbetrag_wegen_Mahlzeitengestellung_(eigene_Zahlungen_sind_gegebenenfalls_gegenzurechnen)
                 */
                E0224910: string;
              }[];
              /**
               * Summe
               * @maxLength: 1
               */
              Sum: {
                /**
                 * Mehraufwendungen_für_Verpflegung_bei_einer_Auswärtstätigkeit_im_Ausland
                 */
                E0224911: string;
              }[];
            }[];
            /**
             * Steuerfreier_Ersatz
             * @maxLength: 1
             */
            VMA_Ersatz: {
              /**
               * Vom_Arbeitgeber_steuerfrei_ersetzt
               */
              E0225201: string;
            }[];
          }[];
          /**
           * Mehraufwendungen_für_doppelte_Haushaltsführung_($#/N_GRE/Arb_V/Wk/DHHF$._Angabe)
           * @maxLength: 10
           */
          DHHF: {
            /**
             * Allgemeine_Angaben
             * @maxLength: 1
             */
            Allg: {
              /**
               * Der_doppelte_Haushalt_wurde_aus_beruflichem_Anlass_begründet_am
               */
              E0224902: string;
              /**
               * Grund
               */
              E0225003: string;
              /**
               * Der_doppelte_Haushalt_hat_seitdem_ununterbrochen_bestanden_bis
               */
              E0225108: string;
              /**
               * Beschäftigungsort_(Postleitzahl,_Ort,_sowie_zusätzlich_der_Staat,_falls_im_Ausland_und_abweichend_vom_Staat_in_dem_der_doppelte_Haushalt_liegt)
               */
              E0225202: string;
              /**
               * Der_doppelte_Haushalt_liegt_im_Ausland
               */
              E0225204: string;
              /**
               * Der_doppelte_Haushalt_liegt_im_Ausland:_Staat
               */
              E0225203: string;
              /**
               * Es_liegt_ein_eigener_Hausstand_am_Lebensmittelpunkt_vor
               */
              E0225302: string;
              /**
               * (Postleitzahl,_Ort_des_eigenen_Hausstandes)
               */
              E0225404: string;
              /**
               * seit
               */
              E0225405: string;
              /**
               * Der_Begründung_des_doppelten_Haushalts_ist_eine_Auswärtstätigkeit_am_selben_Beschäftigungsort_unmittelbar_vorausgegangen
               */
              E0226103: string;
              /**
               * Anstelle_der_Mehraufwendungen_für_doppelte_Haushaltsführung_werden_in_den_Zeilen_31_bis_36_Fahrtkosten_für_mehr_als_eine_Heimfahrt_wöchentlich_geltend_gemacht
               */
              E0226206: string;
            }[];
            /**
             * Fahrtkosten
             * @maxLength: 1
             */
            Fahrtk: {
              /**
               * Die_Fahrten_wurden_mit_einem_Firmenwagen_oder_im_Rahmen_einer_unentgeltlichen_Sammelbeförderung_des_Arbeitgebers_durchgeführt
               */
              E0226305: string;
              /**
               * Erste_Fahrt_zum_Ort_der_ersten_Tätigkeitsstätte_und_letzte_Fahrt_zum_eigenen_Hausstand
               * @maxLength: 1
               */
              Erst_Letzt: {
                /**
                 * mit_privatem_Kfz
                 * @maxLength: 1
                 */
                Priv_Kfz: {
                  /**
                   * gefahrene_km
                   */
                  E0226405: string;
                  /**
                   * Kilometersatz_bei_Einzelnachweis
                   */
                  E0226406: string;
                  /**
                   * Berechnung_des_Kilometersatzes_bei_Einzelnachweis
                   */
                  E0226407: string;
                }[];
                /**
                 * mit_privatem_Motorrad_/_Motorroller
                 * @maxLength: 1
                 */
                Priv_Motorr: {
                  /**
                   * gefahrene_km
                   */
                  E0226502: string;
                  /**
                   * Kilometersatz_bei_Einzelnachweis
                   */
                  E0226503: string;
                  /**
                   * Berechnung_des_Kilometersatzes_bei_Einzelnachweis
                   */
                  E0226504: string;
                }[];
                /**
                 * mit_öffentlichen_Verkehrsmitteln_oder_entgeltlicher_Sammelbeförderung
                 * @maxLength: 1
                 */
                Oeff_VM: {
                  /**
                   * Betrag
                   */
                  E0226602: string;
                }[];
              }[];
              /**
               * Wöchentliche_Heimfahrten
               * @maxLength: 1
               */
              Woech_Heimf: {
                /**
                 * einfache_Entfernung_(ohne_Flugstrecken)_in_km
                 */
                E0226702: string;
                /**
                 * Anzahl
                 */
                E0226703: string;
                /**
                 * Kosten_für_öffentliche_Verkehrsmittel_(ohne_Flug-_und_Fährkosten)
                 */
                E0226803: string;
              }[];
              /**
               * Nur_bei_Behinderungsgrad_von_mindestens_70_oder_mindestens_50_und_Merkzeichen_\"G\"
               * @maxLength: 1
               */
              Behind: {
                /**
                 * einfache_Entfernung_(ohne_Flugstrecken)
                 */
                E0226903: string;
                /**
                 * davon_mit_privatem_Kfz_zurückgelegt_in_km
                 */
                E0226904: string;
                /**
                 * Anzahl
                 */
                E0226905: string;
                /**
                 * Kilometersatz_bei_Einzelnachweis
                 */
                E0226906: string;
                /**
                 * Berechnung_des_Kilometersatzes_bei_Einzelnachweis
                 */
                E0226907: string;
                /**
                 * Kosten_für_öffentliche_Verkehrsmittel_(ohne_Flug-_und_Fährkosten)
                 */
                E0227003: string;
              }[];
              /**
               * Flug-_und_Fährkosten_/_entgeltliche_Sammelbeförderung
               * @maxLength: 1
               */
              Flug_Faehr: {
                /**
                 * Flug-_und_Fährkosten_(zu_den_Zeilen_70_bis_73)_oder_Kosten_für_entgeltliche_Sammelbeförderung_für_Heimfahrten
                 */
                E0227103: string;
              }[];
            }[];
            /**
             * Kosten_der_Unterkunft_am_Ort_der_ersten_Tätigkeitsstätte
             * @maxLength: 1
             */
            Unterkunft: {
              /**
               * Aufwendungen_(zum_Beispiel_Miete_einschließlich_Stellplatz-_/_Garagenkosten,_Nebenkosten)
               */
              E0227203: string;
              /**
               * Größe_der_Zweitwohnung_des_doppelten_Haushalts_im_Ausland_in_qm
               */
              E0227303: string;
            }[];

            /**
             * Die_Verpflegungsmehraufwendungen_laut_Zeilen_77_bis_80_können_nur_für_einen_Zeitraum_von_drei_Monaten_nach_Bezug_der_Unterkunft_am_Ort_der_ersten_Tätigkeitsstätte_geltend_gemacht_werden;_geht_der_doppelten_Haushaltsführung_eine_Auswärtstätigkeit_voraus,_ist_dieser_Zeitraum_auf_den_Dreimonatszeitraum_anzurechnen.
             * @maxLength: 1
             */
            VMA: {
              /**
               * Bei_einer_doppelten_Haushaltsführung_im_Inland
               * @maxLength: 1
               */
              Inl: {
                /**
                 * An-_und_Abreisetage:_Anzahl_der_Tage
                 */
                E0227603: string;
                /**
                 * Abwesenheit_von_24_Stunden:_Anzahl_der_Tage
                 */
                E0227703: string;
                /**
                 * Kürzungsbetrag_wegen_Mahlzeitengestellung_(eigene_Zuzahlungen_sind_gegebenenfalls_gegenzurechnen)
                 */
                E0227505: string;
              }[];
              /**
               * Bei_einer_doppelten_Haushaltsführung_im_Ausland
               * @maxLength: 1
               */
              Ausl: {
                /**
                 * Aufstellung_zur_Berechnung_der_Mehraufwendungen_für_Verpflegung
                 * @maxLength: 1
                 */
                Aufst: {
                  /**
                   * An-_und_Abreisetage:_Anzahl_der_Tage
                   */
                  E0227903: string;
                  /**
                   * Pauschbetrag_für_An-_und_Abreisetage
                   */
                  E0227904: string;
                  /**
                   * Abwesenheit_von_24_Stunden:_Anzahl_der_Tage
                   */
                  E0227905: string;
                  /**
                   * Pauschbetrag_bei_Abwesenheit_von_24_Stunden
                   */
                  E0227906: string;
                  /**
                   * Kürzungsbetrag_wegen_Mahlzeitengestellung_(eigene_Zuzahlungen_sind_gegebenenfalls_gegenzurechnen)
                   */
                  E0227907: string;
                }[];
                /**
                 * Summe
                 * @maxLength: 1
                 */
                Sum: {
                  /**
                   * Summe_der_Verpflegungsmehraufwendungen_bei_einer_doppelten_Haushaltsführung_im_Ausland
                   */
                  E0227908: string;
                }[];
              }[];
            }[];
            /**
             * Sonstige_Aufwendungen_(zum_Beispiel_Kosten_für_den_Umzug,_die_Einrichtung_und_den_Hausrat,_jedoch_ohne_Kosten_der_Unterkunft_laut_Zeile_75)
             * @maxLength: 1
             */
            Sonst_Aufw: {
              /**
               * Art_der_Aufwendungen
               */
              E0227503: string;
              /**
               * Betrag
               */
              E0227504: string;
            }[];
          }[];
          /**
           * Zusammenfassung_Mehraufwendungen_für_doppelte_Haushaltsführung
           * @maxLength: 1
           */
          VMA_ges: {
            /**
             * vom_Arbeitgeber_/_von_der_Agentur_für_Arbeit_insgesamt_steuerfrei_ersetzt
             */
            E0226501: string;
          }[];
        }[];

        /**
         * Die_in_den_Zeilen_84_bis_85_erklärten_Werbungskosten_dürfen_nicht_in_den_Zeilen_31_bis_83_enthalten_sein_-
         * @maxLength: 1
         */
        Wk_Sonder: {
          /**
           * Werbungskosten_zu_Entschädigungen_/_Arbeitslohn_für_mehrere_Jahre_laut_Zeile_21
           * @maxLength: 1
           */
          Erm_best: {
            /**
             * Art_der_Aufwendungen
             */
            E0222702: string;
            /**
             * Betrag_in_EUR
             */
            E0222703: string;
          }[];
        }[];
        /**
         * 3._Sonderausgaben_im_Zusammenhang_mit_Arbeitslohn_laut_Zeile_5
         * @maxLength: 1
         */
        SA: {
          /**
           * Ausländische_Beiträge
           * @maxLength: 1
           */
          Ausl_Beitr: {
            /**
             * Bruttoarbeitslohn_laut_Zeile_5
             * @maxLength: 1
             */
            BAL: {
              /**
               * Betrag_in_CHF
               */
              E0228501: string;
              /**
               * Betrag_in_EUR_(gegebenenfalls_umgerechnet)
               */
              E0228502: string;
            }[];
            /**
             * Versicherungsleistungen_bei_Unfall,_Krankheit,_Invalidität;_Familienzulagen_(zum_Beispiel_Kinder-,_Ausbildungs-,_Unterhaltszulage),_Freibetrag_für_AHV-Renten-Bezieher
             * @maxLength: 1
             */
            Vers_Leist: {
              /**
               * Betrag_in_CHF
               */
              E0228601: string;
              /**
               * Betrag_in_EUR_(gegebenenfalls_umgerechnet)
               */
              E0228602: string;
            }[];
            /**
             * Bemessungsgrundlage_Sozialabgaben
             * @maxLength: 1
             */
            BMG_Sozialabg: {
              /**
               * Betrag_in_CHF
               */
              E0228701: string;
              /**
               * Betrag_in_EUR_(gegebenenfalls_umgerechnet)
               */
              E0228702: string;
            }[];
            /**
             * Erwerbsersatzordnung_(EO)_(0,225_Prozent_von_Zeile_93)
             * @maxLength: 1
             */
            EO: {
              /**
               * Betrag_in_CHF
               */
              E0227601: string;
              /**
               * Betrag_in_EUR_(gegebenenfalls_umgerechnet)
               */
              E0227602: string;
            }[];
            /**
             * Arbeitslosenversicherung_(von_Zeile_93)_1,1_Prozent_für_Lohnteile_bis_148.200_CHF_/_137.826_EUR,_weitere_0,5_Prozent_für_Lohnteile_über_148.200_CHF_/_137.826_EUR
             * @maxLength: 1
             */
            AVers: {
              /**
               * Betrag_in_CHF
               */
              E0227801: string;
              /**
               * Betrag_in_EUR_(gegebenenfalls_umgerechnet)
               */
              E0227802: string;
            }[];
            /**
             * Beiträge_zur_Krankentaggeldversicherung
             * @maxLength: 1
             */
            KTGV: {
              /**
               * Betrag_in_CHF
               */
              E0227701: string;
              /**
               * Betrag_in_EUR_(gegebenenfalls_umgerechnet)
               */
              E0227702: string;
            }[];
            /**
             * 50_Prozent_der_Beiträge_zur_NBUV
             * @maxLength: 1
             */
            NBUV: {
              /**
               * Betrag_in_CHF
               */
              E0227901: string;
              /**
               * Betrag_in_EUR_(gegebenenfalls_umgerechnet)
               */
              E0227902: string;
            }[];
            /**
             * Sonstige_Vorsorgeaufwendungen
             * @maxLength: 1
             */
            Sonst_Vors: {
              /**
               * Betrag_in_CHF
               */
              E0228101: string;
              /**
               * Betrag_in_EUR_(gegebenenfalls_umgerechnet)
               */
              E0228102: string;
            }[];
            /**
             * AHV_/_IV_5,05_Prozent_von_Zeile_93
             * @maxLength: 1
             */
            AN_AHV_IV: {
              /**
               * Betrag_in_CHF
               */
              E0226901: string;
              /**
               * Betrag_in_EUR_(gegebenenfalls_umgerechnet)
               */
              E0226902: string;
            }[];
            /**
             * Beiträge_des_Arbeitnehmers_ins_Obligatorium_(Säule_2a)
             * @maxLength: 1
             */
            AN_Beitr_Obl: {
              /**
               * Betrag_in_CHF
               */
              E0229103: string;
              /**
               * Betrag_in_EUR_(gegebenenfalls_umgerechnet)
               */
              E0229104: string;
            }[];
            /**
             * Summe_Arbeitnehmerbeiträge
             * @maxLength: 1
             */
            Su_AN_Beitr: {
              /**
               * Betrag_in_CHF
               */
              E0227401: string;
              /**
               * Betrag_in_EUR_(gegebenenfalls_umgerechnet)
               */
              E0227402: string;
            }[];
            /**
             * AHV_/_IV_laut_Zeile_99
             * @maxLength: 1
             */
            AG_AHV_IV: {
              /**
               * Betrag_in_CHF
               */
              E0227001: string;
              /**
               * Betrag_in_EUR_(gegebenenfalls_umgerechnet)
               */
              E0227002: string;
            }[];
            /**
             * Beiträge_des_Arbeitgebers_ins_Obligatorium_(Säule_2a)
             * @maxLength: 1
             */
            AG_Beitr_Obl: {
              /**
               * Betrag_in_CHF
               */
              E0229401: string;
              /**
               * Betrag_in_EUR_(gegebenenfalls_umgerechnet)
               */
              E0229402: string;
            }[];
            /**
             * Summe_Arbeitgeberbeiträge
             * @maxLength: 1
             */
            Su_AG_Beitr: {
              /**
               * Betrag_in_CHF
               */
              E0227501: string;
              /**
               * Betrag_in_EUR_(gegebenenfalls_umgerechnet)
               */
              E0227502: string;
            }[];
          }[];
        }[];
        /**
         * 4._Angaben_zum_Arbeitgeber
         * @maxLength: 1
         */
        AG_Ang: {
          /**
           * Name_und_Anschrift_des_Arbeitgebers
           * @maxLength: 1
           */
          Name_Adr: {
            /**
             * Name_(Bezeichnung)
             */
            E0229605: string;
            /**
             * Straße_und_Hausnummer
             */
            E0229703: string;
            /**
             * Postleitzahl,_Ort
             */
            E0229803: string;
          }[];
        }[];
        /**
         * 5._Angaben_zur_Firmenwagengestellung
         * @maxLength: 3
         */
        Firmenwagen: {
          /**
           * Es_stand_im_Jahr_$VZ$_ein_Firmenwagen_zur_Mitbenutzung_zur_Verfügung
           */
          E0229913: string;
          /**
           * Inländischer_Bruttolistenpreis_zum_Zeitpunkt_der_Erstzulassung_inklusive_Sonderausstattung
           */
          E0229914: string;
        }[];
        /**
         * 6._Krankentaggeldversicherung
         * @maxLength: 1
         */
        KTGV: {
          /**
           * Besteht_aufgrund_der_vorgenannten_Tätigkeit_ein_Anspruch_auf_Krankentaggeld_aus_einem_Kollektivversicherungsvertrag?
           * @maxLength: 1
           */
          Anspr: {
            /**
             * Nein
             */
            E0229931: string;
            /**
             * Ja,_bei
             */
            E0229932: string;
            /**
             * Name_und_Anschrift_der_Versicherung_/_Krankenkasse
             */
            E0229933: string;
          }[];
          /**
           * Wer_bezahlt_die_Versicherungsprämien?
           * @maxLength: 1
           */
          Zahlg: {
            /**
             * Arbeitgeber_(in_Prozent)
             */
            E0229951: string;
            /**
             * Arbeitnehmer_(in_Prozent)
             */
            E0229952: string;
          }[];
          /**
           * Wie_hoch_ist_der_auf_den_Arbeitnehmer_entfallende_Anteil_an_den_Versicherungsprämien_in_die_Krankentaggeldversicherung?_Bitte_in_Schweizer_Franken_(CHF)_angeben.
           * @maxLength: 1
           */
          Anteil: {
            /**
             * Anteil_des_Arbeitgebers_in_CHF
             */
            E0229961: string;
            /**
             * Anteil_des_Arbeitnehmers_in_CHF
             */
            E0229962: string;
          }[];
        }[];
        /**
         * 7._Nichtberufsunfallversicherung
         * @maxLength: 1
         */
        NBUV: {
          /**
           * Wer_bezahlt_die_Versicherungsprämien?
           * @maxLength: 1
           */
          Zahlg: {
            /**
             * Arbeitgeber_(in_Prozent)
             */
            E0229907: string;
            /**
             * Arbeitnehmer_(in_Prozent)
             */
            E0229908: string;
          }[];
          /**
           * Wie_hoch_ist_der_auf_den_Arbeitnehmer_entfallende_Anteil_an_den_Versicherungsprämien_in_die_Nichtberufsunfallversicherung?_Bitte_in_Schweizer_Franken_(CHF)_angeben.
           * @maxLength: 1
           */
          Anteil: {
            /**
             * Anteil_des_Arbeitgebers
             */
            E0229909: string;
            /**
             * Anteil_des_Arbeitnehmers
             */
            E0229910: string;
          }[];
        }[];
        /**
         * 8._Angaben_zu_den_Alterseinkünften
         * @maxLength: 1
         */
        Alterseink: {
          /**
           * Ich_habe_in_$VZ$_Leistungen_aus_der_ersten_(AHV_/_IV),_zweiten_(zum_Beispiel_Pensionskassen)_und_/_oder_dritten_Säule_der_Schweizer_Altersvorsorge_erhalten
           */
          E0228303: string;
          /**
           * Ich_habe_in_$VZ$_keine_Leistungen_aus_der_ersten_(AHV/IV),_zweiten_(zum_Beispiel_Pensionskassen)_und_/_oder_dritten_Säule_der_Schweizer_Altersvorsorge_erhalten
           */
          E0228304: string;
        }[];
      }[];
    }[];
  }
  export interface IG {
    /**
     * Einkünfte_aus_Gewerbebetrieb
     * @maxLength: 2
     */
    G: {
      Person: ElsterPerson;
      /**
       * Gewinn_(ohne_die_Beträge_in_den_Zeilen_31,_36,_42,_44,_45_und_48;_bei_ausländischen_Einkünften:_Anlage_AUS_beachten)
       * @maxLength: 1
       */
      Gew: {
        /**
         * Gewinn_als_Einzelunternehmer
         * @maxLength: 1
         */
        Einz_U: {
          /**
           * 2._Betrieb
           * @maxLength: 2
           */
          Betr_1_2: {
            /**
             * Betrieb
             */
            E0800301: string;
            /**
             * Betrag
             */
            E0800302: string;
          }[];
          /**
           * Weitere_Betriebe
           * @maxLength: 1
           */
          Weit_Betr: {
            /**
             * Einzelangaben
             * @maxLength: 99
             */
            Einz: {
              /**
               * Betrieb
               */
              E0800401: string;
              /**
               * Betrag
               */
              E0800404: string;
            }[];
            /**
             * Summe
             * @maxLength: 1
             */
            Sum: {
              /**
               * Summe
               */
              E0800402: string;
            }[];
          }[];
        }[];
        /**
         * Gewinn_laut_gesonderter_Feststellung
         * @maxLength: 1
         */
        Ges_Fest: {
          /**
           * Einzelangaben
           * @maxLength: 99
           */
          Einz: {
            /**
             * Betriebsfinanzamt_und_Steuernummer
             */
            E0800501: string;
            /**
             * Betrag
             */
            E0800504: string;
          }[];
          /**
           * Summe
           * @maxLength: 1
           */
          Sum: {
            /**
             * Summe
             */
            E0800502: string;
          }[];
        }[];
        /**
         * Gewinn_als_Mitunternehmer
         * @maxLength: 1
         */
        M_Unt: {
          /**
           * $index$._Beteiligung
           * @maxLength: 99
           */
          Beteil: {
            /**
             * Gesellschaft,_Finanzamt_und_Steuernummer
             */
            E0800601: string;
            /**
             * Betrag
             */
            E0800602: string;
          }[];
        }[];
        /**
         * Gesellschaften_/_Gemeinschaften_/_ähnliche_Modelle_im_Sinne_des_§_15b_EStG
         * @maxLength: 1
         */
        Ges_Gem_P15b: {
          /**
           * Einzelangaben
           * @maxLength: 99
           */
          Einz: {
            /**
             * Gesellschaft
             */
            E0801001: string;
            /**
             * Betrag
             */
            E0801006: string;
          }[];
          /**
           * Summe
           * @maxLength: 1
           */
          Sum: {
            /**
             * Summe
             */
            E0801002: string;
          }[];
        }[];
        /**
         * In_den_Zeilen_4_bis_11_und_48_nicht_enthaltener_steuerfreier_Teil_der_Einkünfte,_für_die_das_Teileinkünfteverfahren_gilt
         * @maxLength: 1
         */
        TEV_stfr_T: {
          /**
           * Betrag
           */
          E0800904: string;
        }[];
        /**
         * In_den_Zeilen_4_bis_11_und_48_enthaltene_positive_Einkünfte_im_Sinne_des_§_2_Absatz_4_UmwStG
         * @maxLength: 1
         */
        P_Ek_P2_Abs_4_UmwStG: {
          /**
           * In_den_Zeilen_4_bis_11_und_48_enthaltene_positive_Einkünfte_im_Sinne_des_§_2_Absatz_4_UmwStG
           */
          E0801414: string;
        }[];
        /**
         * In_den_Zeilen_4_bis_11_und_48_enthaltene_Gewinne,_die_gemäß_§_5a_EStG_ermittelt_wurden_beziehungsweise_enthaltene_Einkünfte_aus_einem_verpachteten_/_ruhenden_Betrieb_im_Sinne_des_§_16_Absatz_3b_EStG
         * @maxLength: 1
         */
        Ek_P5a_od_verp_ruh_Bet: {
          /**
           * Bezeichnung
           */
          E0801404: string;
          /**
           * Betrag
           */
          E0801405: string;
        }[];
        /**
         * In_den_Zeilen_4_bis_11_und_48_enthaltene_Gewinne_aus_Holznutzungen,_die_den_ermäßigten_Steuersätzen_des_§_34b_EStG_unterworfen_werden_sollen
         * @maxLength: 1
         */
        Holzn_P34b: {
          /**
           * im_Sinne_des_§_34b_Absatz_1_EStG_in_Verbindung_mit_Absatz_3_Nummer_2_EStG
           * @maxLength: 1
           */
          P3_Nr_2: {
            /**
             * Bezeichnung
             */
            E0801406: string;
            /**
             * Betrag
             */
            E0801407: string;
          }[];
          /**
           * im_Sinne_des_§_34b_Absatz_1_EStG_in_Verbindung_mit_Absatz_3_Nummer_1_EStG
           * @maxLength: 1
           */
          P3_Nr_1: {
            /**
             * Bezeichnung
             */
            E0801408: string;
            /**
             * Betrag
             */
            E0801409: string;
          }[];
          /**
           * Kalamitätsnutzungen_bei_Einschlagsbeschränkung_im_Sinne_des_§_34b_Absatz_1_Nummer_2_EStG_in_Verbindung_mit_Absatz_3_Nummer_2_EStG_in_Verbindung_mit_§_5_Absatz_1_FSchAusglG
           * @maxLength: 1
           */
          Abs1_Nr2_iVm_Abs3_Nr2: {
            /**
             * Bezeichnung
             */
            E0801412: string;
            /**
             * Betrag
             */
            E0801413: string;
          }[];
        }[];
        /**
         * Ich_beantrage_für_den_in_den_Zeilen_4_bis_11_und_36_enthaltenen_Gewinn_die_Begünstigung_nach_§_34a_EStG_und_/_oder_es_wurde_zum_31.12.$VZ-1$_ein_nachversteuerungspflichtiger_Betrag_festgestellt.
         * @maxLength: 1
         */
        Beg_P34a: {
          /**
           * Einzureichende_Anlage(n)_34a_(Anzahl)
           */
          E0801703: string;
        }[];
      }[];
      /**
       * Zusätzliche_Angaben_bei_Steuerermäßigung_nach_§_35_EStG
       * @maxLength: 1
       */
      St_Erm_P35: {
        /**
         * Angaben_zur_Gewerbesteuer_/_zum_Gewerbesteuer-Messbetrag
         * @maxLength: 1
         */
        Ang_GSt_GMB: {
          /**
           * Angaben_zur_Gewerbesteuer_/_zum_Gewerbesteuer-Messbetrag_einzelner_Betriebe_(2._Angabe)
           * @maxLength: 2
           */
          Einz_Betr: {
            /**
             * Für_$VZ$_festzusetzender_(anteiliger)_Gewerbesteuer-Messbetrag_im_Sinne_des_§_35_EStG_des_Betriebs_/_des_Mitunternehmeranteils_(ohne_Gewerbesteuer-Messbetrag,_der_auf_nach_§_5a_Absatz_1_EStG_ermittelten_Gewinn_oder_Gewinn_im_Sinne_des_§_18_Absatz_3_UmwStG_entfällt)_-_Berechnung_laut_Aufstellung_-
             * @maxLength: 1
             */
            Festzus_GMB_VZ: {
              /**
               * laut_Zeile
               */
              E0801605: string;
              /**
               * Gewerbeertrag_(auf_volle_100_EUR_abgerundet)
               */
              E0801607: string;
              /**
               * Freibetrag
               */
              E0801608: string;
              /**
               * Gewerbesteuer-Messbetrag
               */
              E0801606: string;
            }[];
            /**
             * Für_$VZ$_tatsächlich_zu_zahlende_Gewerbesteuer,_die_auf_den_Gewerbesteuer-Messbetrag_entfällt_-_Berechnung_laut_Aufstellung_-
             * @maxLength: 1
             */
            Zu_zah_GSt_VZ: {
              /**
               * Hebesatz_in_Prozent
               */
              E0801705: string;
              /**
               * Gewerbesteuer
               */
              E0801704: string;
            }[];
          }[];
          /**
           * Angaben_zur_Gewerbesteuer_/_zum_Gewerbesteuer-Messbetrag_weiterer_Betriebe
           * @maxLength: 1
           */
          Weit_Betr: {
            /**
             * Einzelaufstellung
             * @maxLength: 99
             */
            Einz: {
              /**
               * Für_$VZ$_festzusetzender_(anteiliger)_Gewerbesteuer-Messbeträge_im_Sinne_des_§_35_EStG_des_Betriebs_/_des_Mitunternehmeranteils_(ohne_Gewerbesteuermessbetrag,_der_auf_nach_§_5a_Absatz_1_EStG_ermittelte_Gewinne_oder_Gewinne_im_Sinne_des_§_18_Absatz_3_UmwStG_entfällt)_-_Berechnung_laut_Aufstellung_-
               * @maxLength: 1
               */
              Festzus_GMB_VZ: {
                /**
                 * Gewerbeertrag_(auf_volle_100_EUR_abgerundet)
                 */
                E0802007: string;
                /**
                 * Freibetrag
                 */
                E0802008: string;
                /**
                 * Gewerbesteuer-Messbetrag
                 */
                E0802009: string;
              }[];
              /**
               * Für_$VZ$_tatsächlich_zu_zahlende_Gewerbesteuer,_die_auf_den_Gewerbesteuer-Messbetrag_entfällt_-_Berechnung_laut_Aufstellung_-
               * @maxLength: 1
               */
              Zu_zah_GSt_VZ: {
                /**
                 * Hebesatz_in_Prozent
                 */
                E0802104: string;
                /**
                 * Gewerbesteuer
                 */
                E0802105: string;
              }[];
            }[];
            /**
             * Summen
             * @maxLength: 1
             */
            Sum: {
              /**
               * Summe_aller_weiteren_für_$VZ$_festzusetzenden_(anteiligen)_Gewerbesteuer-Messbeträge_im_Sinne_des_§_35_EStG_der_Betriebe_/_der_Mitunternehmeranteile_laut_den_Zeilen_4_bis_11_und_48_(ohne_Gewerbesteuer-Messbeträge,_die_auf_nach_§_5a_Absatz_1_EStG_ermittelte_Gewinne_oder_Gewinne_im_Sinne_des_§_18_Absatz_3_UmwStG_entfallen)_-_Berechnung_laut_Aufstellung_-
               * @maxLength: 1
               */
              Festzus_GMB_VZ: {
                /**
                 * Summe
                 */
                E0801004: string;
              }[];
              /**
               * Summe_aller_weiteren_für_$VZ$_tatsächlich_zu_zahlenden_Gewerbesteuern,_die_auf_die_Gewerbesteuer-Messbeträge_laut_Zeile_20_entfallen_-_Berechnung_laut_Aufstellung_-
               * @maxLength: 1
               */
              Zu_zah_GSt_VZ: {
                /**
                 * Summe
                 */
                E0801604: string;
              }[];
            }[];
          }[];

          /**
           * laut_gesonderter_Aufstellung_-
           * @maxLength: 1
           */
          Se_HB_P35_mitt_Bet: {
            /**
             * Einzelangaben
             * @maxLength: 99
             */
            Einz: {
              /**
               * Bezeichnung
               */
              E0802207: string;
              /**
               * Betrag
               */
              E0802208: string;
            }[];
            /**
             * Summe
             * @maxLength: 1
             */
            Sum: {
              /**
               * Summe_der_Höchstbeträge_nach_§_35_EStG_aus_mittelbaren_Beteiligungen
               */
              E0802205: string;
            }[];
          }[];
        }[];
      }[];
      /**
       * Veräußerungsgewinn_vor_Abzug_etwaiger_Freibeträge
       * @maxLength: 1
       */
      VAe_G_v_FB: {
        /**
         * bei_Veräußerung_/_Aufgabe_eines_ganzen_Betriebs,_eines_Teilbetriebs,_eines_ganzen_Mitunternehmeranteils_(§_16_EStG),_bei_Veräußerung_eines_einbringungsgeborenen_Anteils_an_einer_Kapitalgesellschaft_(§_21_UmwStG_in_der_am_21.05.2003_geltenden_Fassung)_oder_in_gesetzlich_gleichgestellten_Fällen,_zum_Beispiel_Wegzug_in_das_Ausland:
         * @maxLength: 1
         */
        Betr_TBetr_MUAnt: {
          /**
           * Veräußerungsgewinn,_für_den_der_Freibetrag_nach_§_16_Absatz_4_EStG_wegen_dauernder_Berufsunfähigkeit_oder_Vollendung_des_55._Lebensjahres_beantragt_wird._Für_nach_dem_31.12.1995_erfolgte_Veräußerungen_/_Aufgaben_wurde_der_Freibetrag_nach_§_16_Absatz_4_EStG_bei_keiner_Einkunftsart_in_Anspruch_genommen.
           */
          E0801301: string;
          /**
           * In_Zeile_31_enthaltener_steuerpflichtiger_Teil,_für_den_das_Teileinkünfteverfahren_gilt
           */
          E0801503: string;
          /**
           * Auf_den_Veräußerungsgewinn_laut_Zeile_31_wurde_zumindest_teilweise_§_6b_oder_§_6b_in_Verbindung_mit_§_6c_EStG_angewendet._Die_Übertragungen_von_aufgedeckten_stillen_Reserven_und_/_oder_die_in_Anspruch_genommenen_Rücklagen_nach_§_6b_Absatz_1_bis_9_gegebenenfalls_in_Verbindung_mit_§_6c_EStG_betragen
           */
          E0803306: string;
          /**
           * Auf_den_Veräußerungsgewinn_laut_Zeile_31_wurde_zumindest_teilweise_§_6b_oder_§_6b_in_Verbindng_mit_§_6c_EStG_angewendet._Die_Übertragungen_von_aufgedeckten_stillen_Reserven_und_/_oder_die_in_Anspruch_genommenen_Rücklagen_nach_§_6b_Absatz_10_gegebenenfalls_in_Verbindung_mit_§_6c_EStG_betragen
           */
          E0803401: string;
          /**
           * Veräußerungsgewinn_laut_Zeile_31,_für_den_der_ermäßigte_Steuersatz_des_§_34_Absatz_3_EStG_wegen_dauernder_Berufsunfähigkeit_oder_Vollendung_des_55._Lebensjahres_beantragt_wird._Für_nach_dem_31.12.2000_erfolgte_Veräußerungen_/_Aufgaben_wurde_der_ermäßigte_Steuersatz_des_§_34_Absatz_3_EStG_bei_keiner_Einkunftsart_in_Anspruch_genommen.
           */
          E0801602: string;
          /**
           * Veräußerungsgewinn(e),_für_den_/_die_der_Freibetrag_nach_§_16_Absatz_4_EStG_nicht_beantragt_wird_oder_nicht_zu_gewähren_ist.
           */
          E0801401: string;
          /**
           * In_Zeile_36_enthaltener_steuerpflichtiger_Teil,_für_den_das_Teileinkünfteverfahren_gilt
           */
          E0801803: string;
          /**
           * Auf_den_/_die_Veräußerungsgewinn(e)_laut_Zeile_36_wurde_zumindest_teilweise_§_6b_Absatz_1_bis_9_gegebenenfalls_in_Verbindung_mit_§_6c_EStG_angewendet
           */
          E0803806: string;
          /**
           * Auf_den_/_die_Veräußerungsgewinn(e)_laut_Zeile_36_wurde_zumindest_teilweise_§_6b_Absatz_10_gegebenenfalls_in_Verbindung_mit_§_6c_EStG_angewendet
           */
          E0803903: string;
          /**
           * In_Zeile_36_enthaltener_Veräußerungsgewinn,_für_den_der_ermäßigte_Steuersatz_des_§_34_Absatz_3_EStG_wegen_dauernder_Berufsunfähigkeit_oder_Vollendung_des_55._Lebensjahres_beantragt_wird._Für_nach_dem_31.12.2000_erfolgte_Veräußerungen_/_Aufgaben_wurde_der_ermäßigte_Steuersatz_des_§_34_Absatz_3_EStG_bei_keiner_Einkunftsart_in_Anspruch_genommen.
           */
          E0801903: string;
          /**
           * In_Zeile_40_enthaltener_steuerpflichtiger_Teil,_für_den_das_Teileinkünfteverfahren_gilt
           */
          E0802004: string;
          /**
           * Veräußerungsverlust_nach_§_16_EStG
           */
          E0802006: string;
          /**
           * In_Zeile_42_enthaltener_steuerpflichtiger_Teil,_für_den_das_Teileinkünfteverfahren_gilt
           */
          E0802103: string;
          /**
           * Steuerpflichtiger_Teil_des_Veräußerungsgewinns_bei_Veräußerung_von_Anteilen_an_Kapitalgesellschaften_/_Genossenschaften_nach_§_17_EStG,_§_6_AStG,_§_13_UmwStG_und_in_gesetzlich_gleichgestellten_Fällen
           */
          E0801501: string;
          /**
           * Steuerpflichtiger_Teil_des_Veräußerungsgewinns_bei_Veräußerung_von_Anteilen_an_Kapitalgesellschaften_/_Genossenschaften_nach_§_17_EStG,_§_6_AStG,_§_13_UmwStG_und_in_gesetzlich_gleichgestellten_Fällen:_Beteiligungsquote
           */
          E0804005: string;
          /**
           * Zu_berücksichtigender_Teil_des_Veräußerungsverlusts_bei_Veräußerung_von_Anteilen_an_Kapitalgesellschaften_/_Genossenschaften_nach_§_17_EStG,_§_13_UmwStG_und_in_gesetzlich_gleichgestellten_Fällen
           */
          E0802302: string;
          /**
           * Zu_den_Zeilen_31_bis_41_sowie_44_und_45:
           * @maxLength: 1
           */
          VAeGew_o_VAeVerl: {
            /**
             * Erwerber_ist_eine_Gesellschaft,_an_der_die_veräußernde_Person_oder_ein_Angehöriger_beteiligt_ist_(laut_Aufstellung).
             */
            E0801601: string;
            /**
             * Erläuterung_zur_Beteiligung_der_veräußernden_Person_oder_eines_Angehörigen_an_der_erwerbenden_Gesellschaft
             */
            E0804209: string;
            /**
             * Die_Betriebsaufgabe_erstreckt_sich_über_mehr_als_ein_Kalenderjahr
             */
            E0804210: string;
          }[];
        }[];
      }[];
      /**
       * Sonstiges
       * @maxLength: 1
       */
      Sonst: {
        /**
         * In_den_Zeilen_4_bis_12_enthaltene_begünstigte_sonstige_Gewinne_im_Sinne_des_§_34_Absatz_2_Nummer_2_bis_4_EStG:
         */
        E0801701: string;
        /**
         * Zuzurechnendes_Einkommen_der_Organgesellschaft_(Gesellschaft,_Finanzamt_und_Steuernummer)
         */
        E0802001: string;
        /**
         * Zuzurechnendes_Einkommen_der_Organgesellschaft
         */
        E0802002: string;
        /**
         * Anteile_an_Kapitalgesellschaften,_Bezugsrechte_sind_$VZ$_übertragen_worden
         * @maxLength: 1
         */
        Ant_KapG_BezR: {
          /**
           * Anteile_an_Kapitalgesellschaften,_Bezugsrechte_sind_$VZ$_übertragen_worden_(Einzelangaben_laut_Aufstellung).
           */
          E0802301: string;
          /**
           * Einzelangaben
           */
          E0804905: string;
        }[];
      }[];
      /**
       * Gewerbliche_Tierzucht_/_-haltung
       * @maxLength: 1
       */
      Tierz_Tierh: {
        /**
         * In_den_Zeilen_4_bis_12,_31,_36_und_42_außer_Ansatz_gelassene_Verluste
         */
        E0802401: string;
        /**
         * In_den_Zeilen_4_bis_12,_31,_36_und_42_enthaltene_ungekürzte_Gewinne
         */
        E0802402: string;
        /**
         * In_den_Zeilen_4_bis_12,_31,_36_und_42_verrechnete_Verluste_aus_anderen_Jahren
         */
        E0802403: string;
        /**
         * Die_$VZ-1$_nach_Maßgabe_des_§_10d_Absatz_1_EStG_vorzunehmende_Verrechnung_nicht_ausgeglichener_negativer_Einkünfte_$VZ$_aus_Zeile_50_soll_wie_folgt_begrenzt_werden
         */
        E0803302: string;
      }[];
      /**
       * Gewerbliche_Termingeschäfte
       * @maxLength: 1
       */
      Term_Gesch: {
        /**
         * In_den_Zeilen_4_bis_12,_31,_36_und_42_außer_Ansatz_gelassene_Verluste
         */
        E0802505: string;
        /**
         * In_den_Zeilen_4_bis_12,_31,_36_und_42_enthaltene_ungekürzte_Gewinne
         */
        E0802506: string;
        /**
         * In_den_Zeilen_4_bis_12,_31,_36_und_42_verrechnete_Verluste_aus_anderen_Jahren
         */
        E0802507: string;
        /**
         * Die_$VZ-1$_nach_Maßgabe_des_§_10d_Absatz_1_EStG_vorzunehmende_Verrechnung_nicht_ausgeglichener_negativer_Einkünfte_$VZ$_aus_Zeile_52_soll_wie_folgt_begrenzt_werden
         */
        E0803404: string;
      }[];
      /**
       * Verluste_aus_Beteiligungen_(REIT)
       * @maxLength: 1
       */
      Verl_Bet: {
        /**
         * an_einer_REIT-AG,_anderen_REIT-Körperschaften,_-Personenvereinigungen_oder_-Vermögensmassen
         * @maxLength: 1
         */
        REIT_AG_Koe: {
          /**
           * In_den_Zeilen_4_bis_12,_31,_36_und_42_außer_Ansatz_gelassene_Verluste
           */
          E0804206: string;
          /**
           * In_den_Zeilen_4_bis_12,_31,_36_und_42_enthaltene_ungekürzte_Gewinne
           */
          E0804207: string;
          /**
           * In_den_Zeilen_4_bis_12,_31,_36_und_42_verrechnete_Verluste_aus_anderen_Jahren
           */
          E0804208: string;
          /**
           * Die_$VZ-1$_nach_Maßgabe_des_§_10d_Absatz_1_EStG_vorzunehmende_Verrechnung_nicht_ausgeglichener_negativer_Einkünfte_$VZ$_aus_Zeile_54_soll_wie_folgt_begrenzt_werden:
           */
          E0804302: string;
        }[];
      }[];
      /**
       * Zinsaufwendungen_nach_§_4h_EStG_(Anlage_Zinsschranke)
       * @maxLength: 1
       */
      Zins_AW: {
        /**
         * Für_die_in_den_Zeilen_4_bis_6_genannten_Betriebe_ist_die_Anlage_Zinsschranke_beigefügt._Beigefügte_Anlage(n)_Zinsschranke_(Anzahl)
         */
        E0805606: string;
      }[];
    }[];
  }
  export interface II {
    /**
     * Investmenterträge,_die_nicht_dem_inländischen_Steuerabzug_unterlegen_haben
     * @maxLength: 2
     */
    KAP_I: {
      Person: ElsterPerson;
      /**
       * Laufende_Erträge_aus_Investmentanteilen,_die_nicht_dem_inländischen_Steuerabzug_unterlegen_haben_(zum_Beispiel_bei_im_Ausland_verwahrten_Investmentanteilen)
       * @maxLength: 1
       */
      Lfd_Ert_Inv_Ant_o_inl_StAbz: {
        /**
         * Ausschüttungen_nach_§_2_Absatz_11_InvStG_(einschließlich_des_ausländischen_Steuerabzugs_auf_den_Kapitalertrag)_aus
         * @maxLength: 1
         */
        Ausschuett_P2_Abs11_InvStG: {
          /**
           * Aktienfonds_im_Sinne_des_§_2_Absatz_6_InvStG_(vor_Teilfreistellung)
           */
          E1940401: string;
          /**
           * Mischfonds_im_Sinne_des_§_2_Absatz_7_InvStG_(vor_Teilfreistellung)
           */
          E1940501: string;
          /**
           * Immobilienfonds_im_Sinne_des_§_2_Absatz_9_Satz_1_InvStG_(vor_Teilfreistellung_und_ohne_Beträge_laut_Zeile_7)
           */
          E1940601: string;
          /**
           * Auslands-Immobilienfonds_im_Sinne_des_§_2_Absatz_9_Satz_2_InvStG_(vor_Teilfreistellung)
           */
          E1940701: string;
          /**
           * sonstigen_Investmentfonds
           */
          E1940801: string;
        }[];

        /**
         * gegebenenfalls_Übertrag_aus_Zeile_46_oder_laut_Aufstellung_des_ausländischen_Kreditinstituts_-
         * @maxLength: 1
         */
        Vorabpausch_P18_InvStG: {
          /**
           * Aktienfonds_im_Sinne_des_§_2_Absatz_6_InvStG_(vor_Teilfreistellung)
           */
          E1940902: string;
          /**
           * Mischfonds_im_Sinne_des_§_2_Absatz_7_InvStG_(vor_Teilfreistellung)
           */
          E1941002: string;
          /**
           * Immobilienfonds_im_Sinne_des_§_2_Absatz_9_Satz_1_InvStG_(vor_Teilfreistellung_und_ohne_Beträge_laut_Zeile_12)
           */
          E1941102: string;
          /**
           * Auslands-Immobilienfonds_im_Sinne_des_§_2_Absatz_9_Satz_2_InvStG_(vor_Teilfreistellung)
           */
          E1941202: string;
          /**
           * sonstigen_Investmentfonds
           */
          E1941302: string;
        }[];
      }[];

      /**
       * gegebenenfalls_Übertrag_aus_Zeile_55,_56_und_/_oder_57_oder_laut_Aufstellung_des_ausländischen_Kreditinstituts_-
       * @maxLength: 1
       */
      G_u_V_Verae_InvAnt_o_inl_StAbz: {
        /**
         * Aktienfonds_im_Sinne_des_§_2_Absatz_6_InvStG_(vor_Teilfreistellung)
         */
        E1940901: string;
        /**
         * In_Zeile_14_enthaltene_Gewinne_aus_der_Veräußerung_bestandsgeschützter_Alt-Anteile_im_Sinne_des_§_56_Absatz_6_Satz_1_Nummer_2_InvStG_(vor_Teilfreistellung)
         */
        E1941001: string;
        /**
         * Gewinne_und_Verluste_aus_der_fiktiven_Veräußerung_von_nicht_bestandsgeschützten_Alt-Anteilen_im_Sinne_des_§_56_Absatz_2_in_Verbindung_mit_Absatz_3_Satz_1_InvStG_(nicht_in_Zeile_14_enthalten)
         */
        E1941101: string;
        /**
         * Mischfonds_im_Sinne_des_§_2_Absatz_7_InvStG_(vor_Teilfreistellung)
         */
        E1941201: string;
        /**
         * In_Zeile_17_enthalte_Gewinne_aus_der_Veräußerung_bestandsgeschützter_Alt-Anteile_im_Sinne_des_§_56_Absatz_6_Satz_1_Nummer_2_InvStG_(vor_Teilfreistellung)
         */
        E1941301: string;
        /**
         * Gewinne_und_Verluste_aus_der_fiktiven_Veräußerung_von_nicht_bestandsgeschützten_Alt-Anteilen_im_Sinne_des_§_56_Absatz_2_in_Verbindung_mit_Absatz_3_Satz_1_InvStG_(nicht_in_Zeile_17_enthalten)
         */
        E1941401: string;
        /**
         * Immobilienfonds_im_Sinne_des_§_2_Absatz_9_Satz_1_InvStG_(vor_Teilfreistellung_und_ohne_Beträge_laut_Zeile_23)
         */
        E1941501: string;
        /**
         * In_Zeile_20_enthaltene_Gewinne_aus_der_Veräußerung_bestandsgeschützter_Alt-Anteile_im_Sinne_des_§_56_Absatz_6_Satz_1_Nummer_2_InvStG_(vor_Teilfreistellung)
         */
        E1941601: string;
        /**
         * Gewinne_und_Verluste_aus_der_fiktiven_Veräußerung_von_nicht_bestandsgeschützten_Alt-Anteilen_im_Sinne_von_§_56_Absatz_2_in_Verbindung_mit_Absatz_3_Satz_1_InvStG_(nicht_in_Zeile_20_enthalten)
         */
        E1941701: string;
        /**
         * Auslands-Immobilienfonds_im_Sinne_des_§_2_Absatz_9_Satz_2_InvStG_(vor_Teilfreistellung)
         */
        E1941801: string;
        /**
         * In_Zeile_23_enthaltene_Gewinne_aus_der_Veräußerung_bestandsgeschützter_Alt-Anteile_im_Sinne_des_§_56_Absatz_6_Satz_1_Nummer_2_InvStG_(vor_Teilfreistellung)
         */
        E1941901: string;
        /**
         * Gewinne_und_Verluste_aus_der_fiktiven_Veräußerung_von_nicht_bestandsgeschützten_Alt-Anteilen_im_Sinne_von_§_56_Absatz_2_in_Verbindung_mit_Absatz_3_Satz_1_InvStG_(nicht_in_Zeile_23_enthalten)
         */
        E1942001: string;
        /**
         * Sonstige_Investmentfonds
         */
        E1942101: string;
        /**
         * In_Zeile_26_enthaltene_Gewinne_aus_der_Veräußerung_bestandsgeschützter_Alt-Anteile_im_Sinne_des_§_56_Absatz_6_Satz_1_Nummer_2_InvStG
         */
        E1942201: string;
        /**
         * Gewinne_und_Verluste_aus_der_fiktiven_Veräußerung_von_nicht_bestandsgeschützten_Alt-Anteilen_im_Sinne_von_§_56_Absatz_2_in_Verbindung_mit_Absatz_3_Satz_1_InvStG_(nicht_in_Zeile_26_enthalten)
         */
        E1942301: string;
      }[];
      /**
       * Zwischengewinne_nach_dem_Investmentsteuergesetz_2004
       * @maxLength: 1
       */
      Zwi_Gew_InvStG_2004: {
        /**
         * Bei_Veräußerung_von_vor_dem_1.1.2018_angeschafften_Investmentanteilen:_Zwischengewinne_aus_fiktiven_Veräußerungen_zum_31.12.2017_nach_§_56_Absatz_2_in_Verbindung_mit_Absatz_3_InvStG
         */
        E1942401: string;
      }[];
      /**
       * Ermittlung_der_Vorabpauschalen_zu_Zeile_9_bis_13
       * @maxLength: 1
       */
      Erm_VP: {
        /**
         * $#/KAP_I/Erm_VP/Fonds$._Investmentfonds
         * @maxLength: 999
         */
        Fonds: {
          /**
           * Allgemeine_Angaben_zum_Investmentfonds
           * @maxLength: 1
           */
          Allg: {
            /**
             * ISIN
             */
            E1943102: string;
            /**
             * Fondsbezeichnung
             */
            E1943202: string;
            /**
             * Art_des_Investmentfonds
             */
            E1943302: string;
          }[];
          /**
           * Ermittlung_des_Basisertrags
           * @maxLength: 1
           */
          Basisertr: {
            /**
             * Rücknahme-,_Börsen-_oder_Marktpreis_für_einen_Investmentanteil_zu_Beginn_des_Kalenderjahres_$VZ-1$
             */
            E1943402: string;
            /**
             * Basisertrag_(Zeile_34_×_0,364_Prozent)
             */
            E1943502: string;
          }[];
          /**
           * Mehrbetrag_je_Investmentanteil_nach_§_18_Absatz_1_Satz_3_InvStG
           * @maxLength: 1
           */
          Mehrbetr: {
            /**
             * Letzter_Rücknahmepreis_$VZ-1$
             */
            E1943602: string;
            /**
             * abzüglich_erster_Rücknahmepreis_$VZ-1$_laut_Zeile_34
             */
            E1943702: string;
            /**
             * zuzüglich_Ausschüttungen_$VZ-1$
             */
            E1943802: string;
            /**
             * Mehrbetrag
             */
            E1943902: string;
          }[];
          /**
           * Ermittlung_der_Vorabpauschale_je_Investmentanteil
           * @maxLength: 1
           */
          VP_Ant: {
            /**
             * Niedrigerer_Wert_aus_Basisertrag_(Zeile_35)_oder_Mehrbetrag_je_Investmentanteil_(Zeile_39)
             */
            E1944002: string;
            /**
             * abzüglich_Ausschüttungen_$VZ-1$
             */
            E1944102: string;
            /**
             * Zwischenergebnis_Zeile_40_abzüglich_Zeile_41
             */
            E1944201: string;
            /**
             * bei_unterjährigem_Erwerb_im_Jahr_$VZ-1$:_Kürzung_Wert_laut_Zeile_42_um_1/12_für_jeden_vollen_Monat_vor_Erwerb
             */
            E1944301: string;
            /**
             * Zwischenergebnis_(Zeile_42_abzüglich_Zeile_43)
             */
            E1944401: string;
          }[];
          /**
           * Ermittlung_der_Vorabpauschale_für_den_Investmentfonds
           * @maxLength: 1
           */
          VP_Fonds: {
            /**
             * Anzahl_der_Anteile_(mit_Nachkommastellen)
             */
            E1944501: string;
            /**
             * Vorabpauschale_(Zeile_44_x_Zeile_45)
             */
            E1944601: string;
          }[];
        }[];
      }[];
      /**
       * Ermittlung_der_Gewinne_und_Verluste_aus_der_Veräußerung_von_Investmentanteilen_zu_Zeile_14_bis_28
       * @maxLength: 1
       */
      Erm_G_u_V_Verae_InvAnt_o_inl_StAbz: {
        /**
         * $#/KAP_I/Erm_G_u_V_Verae_InvAnt_o_inl_StAbz/Fonds$._Investmentfonds
         * @maxLength: 999
         */
        Fonds: {
          /**
           * ISIN_(Internationale_Wertpapiernummer)
           */
          E1943101: string;
          /**
           * Fondsbezeichnung
           */
          E1943201: string;
          /**
           * Art_des_Investmentfonds
           */
          E1943301: string;
          /**
           * Anzahl_der_veräußerten_Anteile_(mit_Nachkommastellen)
           */
          E1943401: string;
          /**
           * Veräußerungspreis
           */
          E1943501: string;
          /**
           * abzüglich_Anschaffungskosten_(bei_Anschaffung_vor_dem_1.1.2018_:_fiktive_Anschaffungskosten_im_Sinne_des_§_56_Absatz_2_InvStG)
           */
          E1943601: string;
          /**
           * abzüglich_Veräußerungskosten
           */
          E1943701: string;
          /**
           * abzüglich_während_Besitzzeit_angesetzter_Vorabpauschalen_(vor_Teilfreistellung)
           */
          E1945401: string;
          /**
           * Veräußerungsgewinn_/_-verlust_(Zeile_51_abzüglich_Zeile_52_bis_54)
           */
          E1943801: string;
          /**
           * Veräußerung_von_vor_dem_1.1.2018_angeschafften_Investmentanteilen
           * @maxLength: 1
           */
          Vor_2018_ang: {
            /**
             * Bei_Anschaffung_vor_dem_1.1.2009:_Wert_laut_Zeile_55
             */
            E1944001: string;
            /**
             * Bei_Anschaffung_nach_dem_31.12.2008_und_vor_dem_1.1.2018:_fiktiver_Veräußerungsgewinn_zum_31.12.2017
             */
            E1944101: string;
          }[];
        }[];
      }[];
    }[];
  }
  export interface IKAP {
    /**
     * Einkünfte_aus_Kapitalvermögen_/_Anrechnung_von_Steuern
     * @maxLength: 2
     */
    KAP: {
      Person: ElsterPerson;
      /**
       * Anträge
       * @maxLength: 1
       */
      Ant: {
        /**
         * Ich_beantrage_die_Günstigerprüfung_für_sämtliche_Kapitalerträge.
         */
        E1900401: string;
        /**
         * Ich_beantrage_eine_Überprüfung_des_Steuereinbehalts_für_bestimmte_Kapitalerträge
         */
        E1900501: string;
      }[];
      /**
       * Erklärung_zur_Kirchensteuerpflicht
       * @maxLength: 1
       */
      KiSt_Pfl: {
        /**
         * Ich_bin_kirchensteuerpflichtig_und_habe_Kapitalerträge_erzielt,_von_denen_Kapitalertragsteuer,_aber_keine_Kirchensteuer_einbehalten_wurde)
         */
        E1900601: string;
      }[];
      /**
       * Kapitalerträge,_die_dem_inländischen_Steuerabzug_unterlegen_haben
       * @maxLength: 1
       */
      KapErt_inl_StAbz: {
        /**
         * Beträge_laut_Steuerbescheinigung(en)
         * @maxLength: 1
         */
        Betr_lt_StBesch: {
          /**
           * Kapitalerträge
           */
          E1900701: string;
          /**
           * In_Zeile_7_enthaltene_Gewinne_aus_Aktienveräußerungen
           */
          E1900901: string;
          /**
           * In_Zeile_7_enthaltene_Gewinne_aus_der_Veräußerung_bestandsgeschützter_Alt-Anteile_im_Sinne_des_§_56_Absatz_6_Satz_1_Nummer_2_InvStG
           */
          E1900804: string;
          /**
           * In_Zeile_7_enthaltene_Ersatzbemessungsgrundlage
           */
          E1901101: string;
          /**
           * Nicht_ausgeglichene_Verluste_ohne_Verluste_aus_der_Veräußerung_von_Aktien
           */
          E1901201: string;
          /**
           * Nicht_ausgeglichene_Verluste_aus_der_Veräußerung_von_Aktien
           */
          E1901301: string;
          /**
           * Verluste_aus_der_ganzen_oder_teilweisen_Uneinbringlichkeit_einer_Kapitalforderung,_Ausbuchung,_Übertragung_wertlos_gewordener_Wirtschaftsgüter_im_Sinne_des_§_20_Absatz_1_EStG_oder_aus_einem_sonstigen_Ausfall_von_Wirtschaftsgütern_im_Sinne_des_§_20_Absatz_1_EStG
           */
          E1901502: string;
        }[];
        /**
         * korrigierte_Beträge_(laut_Aufstellung)
         * @maxLength: 1
         */
        Korr_Betr: {
          /**
           * Kapitalerträge
           */
          E1900702: string;
          /**
           * Erläuterungen_zum_korrigierten_Betrag
           */
          E1900703: string;
          /**
           * In_Zeile_7_enthaltene_Gewinne_aus_Aktienveräußerungen
           */
          E1900902: string;
          /**
           * Erläuterungen_zum_korrigierten_Betrag
           */
          E1900903: string;
          /**
           * In_Zeile_7_enthaltene_Gewinne_aus_der_Veräußerung_bestandsgeschützter_Alt-Anteile_im_Sinne_des_§_56_Absatz_6_Satz_1_Nummer_2_InvStG
           */
          E1900805: string;
          /**
           * Erläuterungen_zum_korrigierten_Betrag
           */
          E1900806: string;
          /**
           * In_Zeile_7_enthaltene_Ersatzbemessungsgrundlage
           */
          E1901102: string;
          /**
           * Erläuterungen_zum_korrigierten_Betrag
           */
          E1901103: string;
          /**
           * Nicht_ausgeglichene_Verluste_ohne_Verluste_aus_der_Veräußerung_von_Aktien
           */
          E1901202: string;
          /**
           * Erläuterungen_zum_korrigierten_Betrag
           */
          E1901203: string;
          /**
           * Nicht_ausgeglichene_Verluste_aus_der_Veräußerung_von_Aktien
           */
          E1901302: string;
          /**
           * Erläuterungen_zum_korrigierten_Betrag
           */
          E1901303: string;
          /**
           * Verluste_aus_der_ganzen_oder_teilweisen_Uneinbringlichkeit_einer_Kapitalforderung,_Ausbuchung,_Übertragung_wertlos_gewordener_Wirtschaftsgüter_im_Sinne_des_§_20_Absatz_1_EStG_oder_aus_einem_sonstigen_Ausfall_von_Wirtschaftsgütern_im_Sinne_des_§_20_Absatz_1_EStG
           */
          E1901503: string;
          /**
           * Erläuterungen_zum_korrigierten_Betrag
           */
          E1901504: string;
        }[];
      }[];
      /**
       * Sparer-Pauschbetrag
       * @maxLength: 1
       */
      Sp_PB: {
        /**
         * In_Anspruch_genommener_Sparer-Pauschbetrag,_der_auf_die_in_den_Zeilen_7_bis_15,_30_und_33_erklärten_Kapitalerträge_entfällt_(gegebenenfalls_\"0\")
         */
        E1901401: string;
        /**
         * In_Anspruch_genommener_Sparer-Pauschbetrag,_der_auf_die_in_der_Anlage_KAP_nicht_erklärten_Kapitalerträge_entfällt_(gegebenenfalls_\"0\")
         */
        E1901402: string;
      }[];

      /**
       * ohne_Investmenterträge_laut_Anlage_KAP-INV_-
       * @maxLength: 1
       */
      KapErt_kein_inl_StAbz: {
        /**
         * Inländische_Kapitalerträge_(ohne_Betrag_laut_Zeile_26)
         */
        E1901501: string;
        /**
         * Ausländische_Kapitalerträge_(ohne_Betrag_laut_Zeile_47)
         */
        E1901702: string;
        /**
         * In_den_Zeilen_18_und_19_enthaltene_Gewinne_aus_Aktienveräußerungen_im_Sinne_des_§_20_Absatz_2_Satz_1_Nummer_1_EStG
         */
        E1901701: string;
        /**
         * In_den_Zeilen_18_und_19_enthaltene_Verluste_ohne_Verluste_aus_der_Veräußerung_von_Aktien
         */
        E1901802: string;
        /**
         * In_den_Zeilen_18_und_19_enthaltene_Verluste_aus_der_Veräußerung_von_Aktien_im_Sinne_des_§_20_Absatz_2_Satz_1_Nummer_1_EStG
         */
        E1901903: string;
        /**
         * Verluste_aus_der_ganzen_oder_teilweisen_Uneinbringlichkeit_einer_Kapitalforderung,_Ausbuchung,_Übertragung_wertlos_gewordener_Wirtschaftsgüter_im_Sinne_des_§_20_Absatz_1_EStG_oder_aus_einem_sonstigen_Ausfall_von_Wirtschaftsgütern_im_Sinne_des_§_20_Absatz_1_EStG
         */
        E1902501: string;
        /**
         * Zinsen,_die_vom_Finanzamt_für_Steuererstattungen_gezahlt_wurden
         */
        E1902001: string;
      }[];

      /**
       * (nicht_in_den_Zeilen_7,_18_und_19_der_Anlage_KAP_sowie_in_den_Zeilen_6_und_14_der_Anlage_KAP-BET_enthalten)
       * @maxLength: 1
       */
      KapErt_tar_Est: {
        /**
         * Hinzurechnungsbetrag_nach_§_10_AStG
         * @maxLength: 1
         */
        HinzB_P10_AStG: {
          /**
           * Hinzurechnungsbetrag_nach_§_10_AStG
           */
          E1902202: string;
        }[];
        /**
         * Laufende_Einkünfte_aus_sonstigen_Kapitalforderungen_jeder_Art,_aus_stiller_Gesellschaft_und_partiarischen_Darlehen
         * @maxLength: 1
         */
        Lfd_Ek_so_KapFord: {
          /**
           * Laufende_Einkünfte_aus_sonstigen_Kapitalforderungen_jeder_Art,_aus_stiller_Gesellschaft_und_partiarischen_Darlehen_(ohne_Betrag_laut_Zeile_49)
           */
          E1902101: string;
        }[];
        /**
         * Gewinn_aus_der_Veräußerung_oder_Einlösung_von_Kapitalanlagen
         * @maxLength: 1
         */
        Gew_Verae_Einl_KapAnl: {
          /**
           * Gewinn_aus_der_Veräußerung_oder_Einlösung_von_Kapitalanlagen_aus_sonstigen_Kapitalforderungen_jeder_Art,_aus_stiller_Gesellschaft_und_partiarischen_Darlehen,_Verluste_aus_der_ganzen_oder_teilweisen_Uneinbringlichkeit_der_Kapitalforderungen
           */
          E1902201: string;
        }[];
        /**
         * Kapitalerträge_aus_Lebensversicherungen_im_Sinne_des_§_20_Absatz_1_Nummer_6_Satz_2_EStG
         * @maxLength: 1
         */
        KapErt_LV: {
          /**
           * Kapitalerträge_aus_Lebensversicherungen_im_Sinne_des_§_20_Absatz_1_Nummer_6_Satz_2_EStG
           */
          E1902302: string;
        }[];
        /**
         * Beantragung_der_Anwendung_der_tariflichen_Einkommensteuer
         * @maxLength: 1
         */
        Antr_Anw_tarifl_Est: {
          /**
           * Ich_beantrage_für_die_Einkünfte_laut_Zeile_32_die_Anwendung_der_tariflichen_Einkommensteuer
           */
          E1902301: string;
        }[];
        /**
         * Laufende_Einkünfte_aus_einer_unternehmerischen_Beteiligung_an_einer_Kapitalgesellschaft
         * @maxLength: 1
         */
        Lfd_Ek_unt_Bet_KG: {
          /**
           * Einzelangaben
           * @maxLength: 10
           */
          Einz: {
            /**
             * Gesellschaft,_Finanzamt_und_Steuernummer
             */
            E1902401: string;
          }[];
          /**
           * Summe
           * @maxLength: 1
           */
          Sum: {
            /**
             * Betrag
             */
            E1902402: string;
          }[];
        }[];
        /**
         * Bezüge_und_Einnahmen_im_Sinne_des_§_32d_Absatz_2_Nummer_4_EStG
         * @maxLength: 1
         */
        Bez_Einn_P32d_Abs2_Nr4: {
          /**
           * Bezüge_und_Einnahmen_im_Sinne_des_§_32d_Absatz_2_Nummer_4_EStG_(ohne_Betrag_laut_Zeile_49)_-_Korrespondenzprinzip_-
           */
          E1902601: string;
        }[];
        /**
         * Einkünfte_aus_Spezial-Investmentanteilen_im_Sinne_des_§_20_Absatz_1_Nummer_3a_EStG
         * @maxLength: 1
         */
        Ek_Spez_Investm_Ant: {
          /**
           * Ich_habe_Einkünfte_aus_Spezial-Investmentanteilen_im_Sinne_des_§_20_Absatz_1_Nummer_3a_EStG_erzielt.
           */
          E1902701: string;
          /**
           * Aufstellung_zu_Einkünften_aus_Spezial-Investmentanteilen_im_Sinne_des_§_20_Absatz_1_Nummer_3a_EStG
           */
          E1902702: string;
        }[];
      }[];
      /**
       * Kapitalerträge._für_die_die_ermäßigte_Besteuerung_nach_§_34_Absatz_1_EStG_anzuwenden_ist
       * @maxLength: 1
       */
      Erm_Best_34EStG: {
        /**
         * In_den_Zeilen_7,_18_und_/_oder_19_der_Anlage_KAP_sowie_in_den_Zeilen_6_und_14_der_Anlage_KAP-BET_enthaltene_Erträge
         */
        E1903502: string;
        /**
         * In_den_Zeilen_27_bis_29,_32_und_/_oder_33_der_Anlage_KAP_sowie_in_den_Zeilen_25_bis_27_und_/_oder_29_der_Anlage_KAP-BET_enthaltene_Erträge
         */
        E1903601: string;
      }[];

      /**
       * laut_Bescheinigungen
       * @maxLength: 1
       */
      St_Abz_Betr_Inl_u_Inv_Ert: {
        /**
         * Kapitalertragsteuer
         */
        E1904701: string;
        /**
         * Solidaritätszuschlag
         */
        E1904901: string;
        /**
         * Kirchensteuer_zur_Kapitalertragsteuer
         */
        E1904801: string;
        /**
         * Angerechnete_ausländische_Steuern
         */
        E1905001: string;
        /**
         * Anrechenbare_noch_nicht_angerechnete_ausländische_Steuern
         */
        E1905101: string;
        /**
         * Fiktive_ausländische_Quellensteuer_(nicht_in_den_Zeilen_40_und_/_oder_41_enthalten)
         */
        E1905201: string;
      }[];

      /**
       * laut_Bescheinigungen
       * @maxLength: 1
       */
      Anzr_St_KapEtr_tar_Est_u_and_Ek_Art: {
        /**
         * Kapitalertragsteuer
         */
        E1905502: string;
        /**
         * Solidaritätszuschlag
         */
        E1905602: string;
        /**
         * Kirchensteuer_zur_Kapitalertragsteuer
         */
        E1905702: string;
      }[];
      /**
       * Beschränkung_der_Anrechenbarkeit_der_Kapitalertragsteuer_nach_§_36a_EStG
       * @maxLength: 1
       */
      Beschrae_Anr_KapESt_P36a: {
        /**
         * Ich_habe_Kapitalerträge_erzielt,_bei_denen_die_Voraussetzungen_für_eine_volle_Anrechnung_der_Kapitalertragsteuer_nach_§_36a_EStG_nicht_erfüllt_sind
         */
        E1905603: string;
      }[];

      /**
       * (laut_Feststellung)
       * @maxLength: 1
       */
      Fam_Stift_P15_AStG: {
        /**
         * Bezeichnung,_Finanzamt_und_Steuernummer
         */
        E1905704: string;
        /**
         * Einkünfte_einer_ausländischen_Familienstiftung,_die_nicht_der_tariflichen_Einkommensteuer_unterliegen
         */
        E1905703: string;
        /**
         * Anzurechnende_ausländische_Steuern_(zu_Zeile_47)
         */
        E1905802: string;
        /**
         * Einkünfte_einer_ausländischen_Familienstiftung,_die_der_tariflichen_Einkommensteuer_unterliegen_(siehe_Zeile_18_der_Anlage_AUS)
         */
        E1905903: string;
      }[];

      /**
       * Einkünfte_aus_Gesellschaften_/_Gemeinschaften_/_ähnlichen_Modellen_im_Sinne_des_§_15b_EStG_(laut_Aufstellung)
       * @maxLength: 1
       */
      St_Stund_Mod: {
        /**
         * Aufstellung
         * @maxLength: 10
         */
        Bez_Ges_Gem: {
          /**
           * Bezeichnung
           */
          E1905901: string;
        }[];
        /**
         * Summe
         * @maxLength: 1
         */
        Betr_Sum: {
          /**
           * Betrag
           */
          E1905902: string;
        }[];
      }[];
    }[];
  }
  export interface IKind {
    /**
     * ${vordrucknr}._Anlage_Kind
     * @maxLength: 14
     */
    Kind: {
      /**
       * Angaben_zum_Kind
       * @maxLength: 1
       */
      Ang_Kind: {
        /**
         * Allgemeine_Angaben
         * @maxLength: 1
         */
        Allg: {
          /**
           * Identifikationsnummer
           */
          E0500406: string;
          /**
           * Vorname
           */
          E0500107: string;
          /**
           * gegebenenfalls_abweichender_Familienname
           */
          E0500108: string;
          /**
           * Geburtsdatum
           */
          E0500701: string;
          /**
           * Anspruch_auf_Kindergeld_(einschließlich_Kinderbonus)_oder_vergleichbare_Leistungen_für_$VZ$
           */
          E0500702: string;
          /**
           * Es_besteht_kein_Anspruch_auf_Kindergeld._Anstelle_des_Anspruchs_auf_Kindergeld_ist_das_eingetragene_Kindergeld_in_die_Günstigerprüfung_einzubeziehen.
           */
          E0500705: string;
          /**
           * Für_die_Kindergeldfestsetzung_zuständige_Familienkasse
           */
          E0500706: string;
        }[];
        /**
         * Wohnsitz
         * @maxLength: 1
         */
        WS: {
          /**
           * Wohnsitz_im_Inland:
           * @maxLength: 9
           */
          Inl: {
            /**
             * vom_-_bis
             */
            E0500703: string;
            /**
             * gegebenenfalls_abweichende_Adresse
             */
            E0500102: string;
          }[];
          /**
           * Wohnsitz_im_Ausland:
           * @maxLength: 9
           */
          Ausl: {
            /**
             * vom_-_bis
             */
            E0500704: string;
            /**
             * gegebenenfalls_abweichende_Adresse
             */
            E0500103: string;
            /**
             * Wohnsitz_(Staat)
             */
            E0500104: string;
          }[];
        }[];
      }[];
      /**
       * Kindschaftsverhältnis_zur_steuerpflichtigen_Person_/_Ehemann_/_Person_A
       * @maxLength: 1
       */
      K_Verh_A: {
        /**
         * Kindschaftsverhältnis
         * @maxLength: 1
         */
        KV: {
          /**
           * 1_=_leibliches_Kind_/_Adoptivkind,_2_=_Pflegekind,_3_=_Enkelkind_/_Stiefkind
           */
          E0500807: string;
          /**
           * Kindschaftsverhältnis_bestand_vom_-_bis
           */
          E0500601: string;
        }[];
      }[];
      /**
       * Kindschaftsverhältnis_zur_Ehefrau_/_Person_B
       * @maxLength: 1
       */
      K_Verh_B: {
        /**
         * Kindschaftsverhältnis
         * @maxLength: 1
         */
        KV: {
          /**
           * 1_=_leibliches_Kind_/_Adoptivkind,_2_=_Pflegekind,_3_=_Enkelkind_/_Stiefkind
           */
          E0500808: string;
          /**
           * Kindschaftsverhältnis_bestand_vom_-_bis
           */
          E0500805: string;
        }[];
      }[];
      /**
       * Kindschaftsverhältnis_zu_einer_anderen_Person
       * @maxLength: 1
       */
      K_Verh_and_P: {
        /**
         * Kindschaftsverhältnis_zu
         * @maxLength: 1
         */
        Ang_Pers: {
          /**
           * Name,_Vorname
           */
          E0501103: string;
          /**
           * Geburtsdatum_dieser_Person
           */
          E0501104: string;
          /**
           * Dauer_des_Kindschaftsverhältnisses_vom_-_bis
           */
          E0501903: string;
          /**
           * Letzte_bekannte_Adresse
           */
          E0501105: string;
          /**
           * Art_des_Kindschaftsverhältnisses_(1_=_leibliches_Kind_/_Adoptivkind;_2_=_Pflegekind)
           */
          E0501106: string;
        }[];
        /**
         * Weitere_Angaben
         * @maxLength: 1
         */
        Weit_Ang: {
          /**
           * Der_andere_Elternteil_lebte_im_Ausland_vom_-_bis
           */
          E0503903: string;
          /**
           * Das_Kindschaftsverhältnis_zum_anderen_Elternteil_ist_durch_dessen_Tod_erloschen_am
           */
          E0501102: string;
          /**
           * Der_Wohnsitz_oder_gewöhnliche_Aufenthalt_des_anderen_Elternteils_ist_nicht_zu_ermitteln_oder_der_Vater_des_Kindes_ist_amtlich_nicht_feststellbar
           */
          E0501513: string;
        }[];
      }[];
      /**
       * Angaben_für_ein_volljähriges_Kind
       * @maxLength: 1
       */
      Ang_vollj_Ki: {
        /**
         * hat_ein_freiwilliges_soziales_oder_ökologisches_Jahr_(Jugendfreiwilligendienstegesetz),_eine_europäische_Freiwilligenaktivität,_einen_entwicklungspolitischen_Freiwilligendienst,_einen_Freiwilligendienst_aller_Generationen_(§_2_Absatz_1a_SGB_VII),_einen_Internationalen_Jugendfreiwilligendienst,_Bundesfreiwilligendienst_oder_einen_Anderen_Dienst_im_Ausland_(§_5_Bundesfreiwilligendienstgesetz)_geleistet
         * @maxLength: 12
         */
        Ausb: {
          /**
           * Zeitraum_vom_-_bis
           */
          E0501610: string;
          /**
           * Erläuterungen_zu_den_Berücksichtigungszeiträumen
           */
          E0501408: string;
        }[];
        /**
         * Kind_ohne_Beschäftigung
         * @maxLength: 6
         */
        Arb_los: {
          /**
           * Das_Kind_war_ohne_Beschäftigung_und_bei_einer_Agentur_für_Arbeit_als_arbeitsuchend_gemeldet_vom_-_bis
           */
          E0501802: string;
        }[];
        /**
         * Behindertes_Kind
         * @maxLength: 2
         */
        Behind: {
          /**
           * Das_Kind_war_wegen_einer_vor_Vollendung_des_25._Lebensjahres_eingetretenen_Behinderung_außerstande,_sich_selbst_finanziell_zu_unterhalten_vom_-_bis
           */
          E0501905: string;
        }[];
      }[];
      /**
       * Angaben_zur_Erwerbstätigkeit_eines_volljährigen_Kindes
       * @maxLength: 1
       */
      Ang_Erw_Taet_vj_K: {
        /**
         * Das_Kind_hat_bereits_eine_erstmalige_Berufsausbildung_oder_ein_Erststudium_abgeschlossen
         */
        E0502204: string;
        /**
         * Falls_Zeile_20_mit_Ja_beantwortet_wurde:_Das_Kind_war_erwerbstätig_(kein_Ausbildungsdienstverhältnis)
         */
        E0502401: string;
        /**
         * Falls_Zeile_21_mit_Ja_beantwortet_wurde:
         * @maxLength: 1
         */
        Ki_erw_t: {
          /**
           * Angaben_zu_geringfügigen_Beschäftigungsverhältnissen
           * @maxLength: 1
           */
          Minijob: {
            /**
             * Das_Kind_übte_eine_/_mehrere_geringfügige_Beschäftigung(en)_im_Sinne_der_§§_8,_8a_SGB_IV_(sogenannter_Minijob)_aus
             */
            E0502501: string;
            /**
             * Einzelangaben
             * @maxLength: 12
             */
            Einz: {
              /**
               * Beschäftigungszeitraum_vom_-_bis
               */
              E0502502: string;
              /**
               * (Vereinbarte)_regelmäßige_wöchentliche_Arbeitszeit_der_Tätigkeit(en)
               */
              E0502618: string;
            }[];
          }[];
          /**
           * Angaben_zu_anderen_Erwerbstätigkeiten
           * @maxLength: 1
           */
          And_ErwT: {
            /**
             * Das_Kind_übte_andere_Erwerbstätigkeiten_aus
             */
            E0502616: string;
            /**
             * Einzelangaben
             * @maxLength: 12
             */
            Einz: {
              /**
               * Erwerbszeitraum_vom_-_bis
               */
              E0502617: string;
              /**
               * (Vereinbarte)_regelmäßige_wöchentliche_Arbeitszeit_der_Tätigkeit(en)
               */
              E0502713: string;
            }[];
          }[];
        }[];
      }[];

      /**
       * (Nicht_in_der_Anlage_Vorsorgeaufwand_enthalten)
       * @maxLength: 1
       */
      KV_PV: {
        /**
         * Aufwendungen_von_mir_/_uns_als_Versicherungsnehmer_geschuldet_und_von_mir_/_uns_getragen
         * @maxLength: 1
         */
        AW_Stpfl: {
          /**
           * Beiträge_zu_Krankenversicherungen_des_Kindes_(nur_Basisabsicherung,_keine_Wahlleistungen)
           */
          E0503110: string;
          /**
           * Beiträge_zur_sozialen_Pflegeversicherung_und_/_oder_zur_privaten_Pflege-Pflichtversicherung
           */
          E0503310: string;
          /**
           * Von_den_Versicherungen_laut_den_Zeilen_31_und_/_oder_32_erstattete_Beträge
           */
          E0503409: string;
          /**
           * Über_die_Basisabsicherung_hinausgehende_Beiträge_zu_Kranken-_und_Pflegeversicherungen_des_Kindes_(zum_Beispiel_für_Wahlleistungen,_Zusatzversicherungen)_abzüglich_erstatteter_Beiträge
           */
          E0503609: string;
        }[];
        /**
         * Aufwendungen_vom_Kind_als_Versicherungsnehmer_geschuldet_und_von_mir_/_uns_getragen
         * @maxLength: 1
         */
        AW_Kind: {
          /**
           * Beiträge_zu_Krankenversicherungen_des_Kindes_(nur_Basisabsicherung,_keine_Wahlleistungen)
           */
          E0503111: string;
          /**
           * In_Zeile_35_enthaltene_Beiträge,_aus_denen_sich_ein_Anspruch_auf_Krankengeld_ergibt
           */
          E0503209: string;
          /**
           * Beiträge_zur_sozialen_Pflegeversicherung_und_/_oder_zur_privaten_Pflege-Pflichtversicherung
           */
          E0503311: string;
          /**
           * Von_den_Versicherungen_laut_den_Zeilen_35_und_/_oder_37_erstattete_Beträge
           */
          E0503410: string;
          /**
           * In_Zeile_38_enthaltene_Beiträge,_aus_denen_sich_ein_Anspruch_auf_Krankengeld_ergibt
           */
          E0503509: string;
          /**
           * Zuschuss_von_dritter_Seite_zu_den_Beiträgen_laut_den_Zeilen_35_und_/_oder_37_(zum_Beispiel_nach_§_13a_BAföG)
           */
          E0503610: string;
        }[];
      }[];

      /**
       * (Nicht_in_der_Anlage_Vorsorgeaufwand_enthalten)
       * @maxLength: 1
       */
      KV_PV_ausl: {
        /**
         * Aufwendungen_von_mir_/_uns_/_dem_Kind_als_Versicherungsnehmer_geschuldet_und_von_mir_/_uns_getragen
         * @maxLength: 1
         */
        AW: {
          /**
           * Beiträge_(abzüglich_steuerfreier_Zuschüsse_und_/_oder_Erstattungen)_zu_ausländischen_Kranken-_und_Pflegeversicherungen_des_Kindes,_die_mit_inländischen_gesetzlichen_Kranken-_und_Pflegeversicherungen_vergleichbar_sind_(nur_Basisabsicherung)
           */
          E0503822: string;
          /**
           * In_Zeile_41_enthaltene_Beiträge,_aus_denen_sich_ein_Anspruch_auf_Krankengeld_ergibt
           */
          E0503823: string;
        }[];
      }[];
      /**
       * Übertragung_des_Kinderfreibetrags_/_des_Freibetrags_für_den_Betreuungs-_und_Erziehungs-_oder_Ausbildungsbedarf
       * @maxLength: 1
       */
      Ueb_KFB_FB_BEA: {
        /**
         * Übertragung,_weil_der_andere_Elternteil_seiner_Unterhaltspflicht_nicht_nachkommt_oder_nicht_unterhaltspflichtig_ist
         * @maxLength: 1
         */
        Kein_U_a_EltT: {
          /**
           * Ich_beantrage_den_vollen_Kinderfreibetrag_und_den_vollen_Freibetrag_für_den_Betreuungs-_und_Erziehungs-_oder_Ausbildungsbedarf,_weil_der_andere_Elternteil_seiner_Unterhaltsverpflichtung_nicht_zu_mindestens_75_Prozent_nachkommt_oder_mangels_Leistungsfähigkeit_nicht_unterhaltspflichtig_ist
           */
          E0503907: string;
          /**
           * Unterhaltsleistungen_nach_dem_Unterhaltsvorschussgesetz
           * @maxLength: 12
           */
          Ztr: {
            /**
             * Falls_die_Frage_in_Zeile_43_mit_Ja_beantwortet_wurde:_Es_wurden_Unterhaltsleistungen_nach_dem_Unterhaltsvorschussgesetz_gezahlt_vom_-_bis
             */
            E0503911: string;
          }[];
        }[];
        /**
         * Übertragung_aus_anderen_Gründen
         * @maxLength: 1
         */
        And_Gruend: {
          /**
           * Übertragung_vom_anderen_Elternteil
           * @maxLength: 1
           */
          Ueb_v_and_EltT: {
            /**
             * Ich_beantrage_den_vollen_Freibetrag_für_den_Betreuungs-_und_Erziehungs-_oder_Ausbildungsbedarf,_weil_das_minderjährige_Kind_bei_dem_anderen_Elternteil_nicht_gemeldet_war.
             */
            E0503906: string;
            /**
             * Einzelangaben
             * @maxLength: 12
             */
            Ztr: {
              /**
               * Zeitraum_vom_-_bis
               */
              E0504106: string;
            }[];
          }[];
          /**
           * Übertragung_auf_Stief-_/_Großelternteil
           * @maxLength: 1
           */
          Stie_Gro_Elt: {
            /**
             * Nur_beim_Stief-_/_Großelternteil:_Ich_/_wir_beantrage(n)_die_Übertragung_des_Kinderfreibetrags_und_des_Freibetrags_für_den_Betreuungs-_und_Erziehungs-_oder_Ausbildungsbedarf,_weil_ich_/_wir_das_Kind_in_meinem_/_unserem_Haushalt_aufgenommen_habe(n)_oder_ich_/_wir_als_Großelternteil_gegenüber_dem_Kind_unterhaltspflichtig_bin_/_sind
             */
            E0504301: string;
            /**
             * Zeitraum_der_Haushaltszugehörigkeit_/_Unterhaltsverpflichtung
             * @maxLength: 12
             */
            Ztr: {
              /**
               * Zeitraum_vom_-_bis
               */
              E0504302: string;
            }[];
          }[];
          /**
           * Übertragung_laut_Anlage_K
           * @maxLength: 1
           */
          Anlage_K: {
            /**
             * Nur_beim_Stief-_/_Großelternteil:_Der_Kinderfreibetrag_und_der_Freibetrag_für_den_Betreuungs-_und_Erziehungs-_oder_Ausbildungsbedarf_sind_laut_Anlage_K_zu_übertragen
             */
            E0503905: string;
            /**
             * Nur_bei_den_berechtigten_Elternteilen:_Der_Übertragung_des_Kinderfreibetrags_und_des_Freibetrags_für_den_Betreuungs-_und_Erziehungs-_oder_Ausbildungsbedarf_auf_den_Stief-_/_Großelternteil_wurde_laut_Anlage_K_zugestimmt.
             */
            E0503904: string;
          }[];
        }[];
      }[];
      /**
       * Entlastungsbetrag_für_Alleinerziehende
       * @maxLength: 6
       */
      EfA: {
        /**
         * Das_Kind_war_mit_mir_in_der_gemeinsamen_Wohnung_gemeldet_vom_-_bis:
         */
        E0503801: string;
        /**
         * Für_das_Kind_wurde_mir_Kindergeld_ausgezahlt_vom_-_bis:
         */
        E0503802: string;
        /**
         * Außer_mir_war(en)_in_der_gemeinsamen_Wohnung_eine_/_mehrere_volljährige_Person(en)_gemeldet,_für_die_(zeitweise)_kein_Anspruch_auf_Kindergeld_oder_Freibeträge_für_Kinder_bestand:
         */
        E0503701: string;
        /**
         * Falls_ja:_vom_-_bis
         */
        E0503803: string;
        /**
         * Es_bestand_eine_Haushaltsgemeinschaft_mit_mindestens_einer_weiteren_volljährigen_Person,_für_die_(zeitweise)_kein_Anspruch_auf_Kindergeld_oder_Freibeträge_für_Kinder_bestand:
         */
        E0503821: string;
        /**
         * Falls_ja:_vom_-_bis
         */
        E0503804: string;
        /**
         * $index$._Person
         * @maxLength: 5
         */
        Hh_Gem: {
          /**
           * Name,_Vorname
           */
          E0503805: string;
          /**
           * Verwandtschaftsverhältnis
           */
          E0503806: string;
          /**
           * Beschäftigung_/_Tätigkeit
           */
          E0503807: string;
        }[];
      }[];
      /**
       * Freibetrag_zur_Abgeltung_eines_Sonderbedarfs_bei_Berufsausbildung_eines_volljährigen_Kindes
       * @maxLength: 1
       */
      FB_Abgelt_Sbed_BA_vj_Ki: {
        /**
         * Es_handelte_sich_zumindest_zeitweise_um_eine_auswärtige_Unterbringung_im_Ausland
         */
        E0505113: string;
        /**
         * Einzelaufstellung
         * @maxLength: 12
         */
        Einz: {
          /**
           * Das_Kind_war_auswärtig_untergebracht:_vom_-_bis
           */
          E0504806: string;
          /**
           * Anschrift(en)_des_Kindes
           */
          E0504805: string;
          /**
           * Staat(en)_-_falls_im_Ausland
           */
          E0505203: string;
        }[];
        /**
         * Nur_bei_nicht_zusammen_veranlagten_Eltern:
         * @maxLength: 1
         */
        Elt_k_ZV: {
          /**
           * Laut_gesondertem_gemeinsamen_Antrag_ist_der_Freibetrag_zur_Abgeltung_eines_Sonderbedarfs_bei_Berufsausbildung_in_einem_anderen_Verhältnis_als_je_zur_Hälfte_aufzuteilen._Der_bei_mir_zu_berücksichtigende_Anteil_beträgt_(in_Prozent)
           */
          E0506202: string;
        }[];
      }[];
      /**
       * Schulgeld_für_den_Besuch_einer_Privatschule
       * @maxLength: 1
       */
      Schulgeld: {
        /**
         * Einzelaufstellung
         * @maxLength: 5
         */
        Einz: {
          /**
           * Bezeichnung_der_Schule_oder_deren_Träger
           */
          E0505606: string;
          /**
           * Gesamtaufwendungen_der_Eltern:_Einzelbetrag
           */
          E0504405: string;
        }[];
        /**
         * Summe
         * @maxLength: 1
         */
        Sum: {
          /**
           * Gesamtaufwendungen_der_Eltern:_Summe
           */
          E0505607: string;
        }[];
        /**
         * Nur_bei_nicht_zusammen_veranlagten_Eltern:
         * @maxLength: 1
         */
        Elt_k_ZV: {
          /**
           * Das_von_mir_übernommene_Schulgeld_beträgt
           */
          E0504505: string;
          /**
           * Laut_gesondertem_gemeinsamen_Antrag_ist_für_das_Kind_der_Höchstbetrag_für_das_Schulgeld_in_einem_anderen_Verhältnis_als_je_zur_Hälfte_aufzuteilen._Der_bei_mir_zu_berücksichtigende_Anteil_beträgt_(in_Prozent):
           */
          E0504603: string;
        }[];
      }[];
      /**
       * Übertragung_des_Behinderten-_und_/_oder_Hinterbliebenen-Pauschbetrags
       * @maxLength: 1
       */
      Ueb_PB_Beh_Hbl: {
        /**
         * Die_Übertragung_des_Behinderten-Pauschbetrags_wird_beantragt:
         * @maxLength: 1
         */
        Beh: {
          /**
           * Ausweis_/_Rentenbescheid_/_Bescheinigung
           * @maxLength: 1
           */
          Ausw_Rentb_Besch: {
            /**
             * gültig_von
             */
            E0504601: string;
            /**
             * gültig_bis
             */
            E0504602: string;
            /**
             * unbefristet_gültig
             */
            E0505908: string;
            /**
             * Grad_der_Behinderung
             */
            E0505809: string;
          }[];
          /**
           * Das_Kind_ist
           * @maxLength: 1
           */
          Geh_Steh: {
            /**
             * geh-_und_stehbehindert_(Merkzeichen_\"G\"_oder_\"aG\")
             */
            E0505808: string;
          }[];
          /**
           * Das_Kind_ist
           * @maxLength: 1
           */
          Blind_Hilfl: {
            /**
             * blind_/_ständig_hilflos_(Merkzeichen_\"Bl\"_und_/_oder_\"H\");_schwerstpflegebedürftig_(Pflegegrad_4_oder_5)
             */
            E0505807: string;
          }[];
        }[];
        /**
         * Die_Übertragung_des_Hinterbliebenen-Pauschbetrags_wird_beantragt:
         * @maxLength: 1
         */
        Hbl: {
          /**
           * Die_Übertragung_des_Hinterbliebenen-Pauschbetrags_wird_beantragt.
           */
          E0505805: string;
        }[];
        /**
         * Nur_bei_nicht_zusammen_veranlagten_Eltern:
         * @maxLength: 1
         */
        Elt_k_ZV: {
          /**
           * Laut_gesondertem_gemeinsamen_Antrag_sind_die_für_das_Kind_zu_gewährenden_Pauschbeträge_für_Behinderte_/_Hinterbliebene_in_einem_anderen_Verhältnis_als_je_zur_Hälfte_aufzuteilen._Der_bei_mir_zu_berücksichtigende_Anteil_beträgt_(in_Prozent):
           */
          E0506007: string;
        }[];
      }[];
      /**
       * Kinderbetreuungskosten
       * @maxLength: 1
       */
      KBK: {
        /**
         * Art_der_Dienstleistung
         * @maxLength: 1
         */
        Art: {
          /**
           * Einzelaufstellung
           * @maxLength: 50
           */
          Einz: {
            /**
             * Art_der_Dienstleistung
             */
            E0506101: string;
            /**
             * Name_und_Anschrift_des_Dienstleisters
             */
            E0506102: string;
            /**
             * vom_-_bis
             */
            E0506103: string;
            /**
             * Betrag
             */
            E0506104: string;
          }[];
          /**
           * Gesamtaufwendungen_der_Eltern
           * @maxLength: 1
           */
          Sum: {
            /**
             * Summe
             */
            E0506105: string;
          }[];
        }[];
        /**
         * Steuerfreier_Ersatz_/_Erstattungen
         * @maxLength: 1
         */
        Ersatz_Erstatt: {
          /**
           * Einzelangaben
           * @maxLength: 6
           */
          Einz: {
            /**
             * Zeitraum_(vom_-_bis)
             */
            E0506506: string;
            /**
             * Betrag
             */
            E0506505: string;
          }[];
          /**
           * Summe
           * @maxLength: 1
           */
          Sum: {
            /**
             * Steuerfreier_Ersatz_(zum_Beispiel_vom_Arbeitgeber),_Erstattungen
             */
            E0506504: string;
          }[];
        }[];
        /**
         * Angaben_zum_Haushalt
         * @maxLength: 1
         */
        Ang_HH: {
          /**
           * Es_handelt_sich_um_einen_Sonderfall_(zum_Beispiel_Trennung_des_zusammenveranlagten_Ehepaares_während_des_Jahres_und_Umzug_des_Kindes_von_einem_Elternteil_zum_anderen),_der_in_den_vorhandenen_Feldern_nicht_erklärt_werden_kann._Ergänzende_Angaben_werden_daher_als_Anlage_eingereicht.
           */
          E0504704: string;
          /**
           * Gemeinsamer_Haushalt_der_Elternteile
           * @maxLength: 6
           */
          Gem_HH_Elt: {
            /**
             * Es_bestand_ein_gemeinsamer_Haushalt_der_Elternteile_vom_-_bis
             */
            E0504807: string;
            /**
             * Das_Kind_gehörte_zu_unserem_Haushalt_vom_-_bis
             */
            E0504808: string;
          }[];
          /**
           * Kein_gemeinsamer_Haushalt_der_Elternteile
           * @maxLength: 6
           */
          K_gem_HH_Elt: {
            /**
             * Es_bestand_kein_gemeinsamer_Haushalt_der_Elternteile_vom_-_bis
             */
            E0505201: string;
            /**
             * Das_Kind_gehörte_zu_meinem_Haushalt_vom_-_bis
             */
            E0505202: string;
            /**
             * Das_Kind_gehörte_zum_Haushalt_des_anderen_Elternteils_vom_-_bis
             */
            E0508901: string;
          }[];
        }[];
        /**
         * Nur_bei_nicht_zusammen_veranlagten_Eltern:
         * @maxLength: 1
         */
        Elt_k_ZV: {
          /**
           * Ich_habe_Kinderbetreuungskosten_in_folgender_Höhe_getragen:
           * @maxLength: 1
           */
          Kosten: {
            /**
             * Einzelangaben
             * @maxLength: 6
             */
            Einz: {
              /**
               * Zeitraum_(vom_-_bis)
               */
              E0506606: string;
              /**
               * Betrag
               */
              E0506605: string;
            }[];
            /**
             * Summe
             * @maxLength: 1
             */
            Sum: {
              /**
               * Ich_habe_Kinderbetreuungskosten_in_folgender_Höhe_getragen
               */
              E0506604: string;
            }[];
          }[];
          /**
           * Aufteilung_des_Höchstbetrages_für_die_Kinderbetreuung
           * @maxLength: 1
           */
          Auft_HB_Ki_Bet: {
            /**
             * Laut_übereinstimmendem_Antrag_ist_für_das_Kind_der_Höchstbetrag_für_die_Kinderbetreuung_in_einem_anderen_Verhältnis_als_je_zur_Hälfte_aufzuteilen._Der_bei_mir_zu_berücksichtigende_Anteil_beträgt_(in_Prozent):
             */
            E0508601: string;
          }[];
        }[];
      }[];
    }[];
  }
  export interface IL {
    /**
     * Einkünfte_aus_Land-_und_Forstwirtschaft
     * @maxLength: 99
     */
    L: {
      /**
       * 6_=_§_13a_Absatz_3_bis_7_EStG
       * @maxLength: 1
       */
      Art_Gew_Erm: {
        /**
         * Gewinnermittlungsart
         */
        E0900407: string;
      }[];
      /**
       * Gewinn_(ohne_die_Beträge_in_den_Zeilen_31,_36_und_42;_bei_ausländischen_Einkünften:_Anlage_AUS_beachten)
       * @maxLength: 1
       */
      Gewinn: {
        /**
         * Gewinn_als_Einzelunternehmer_/_der_Gemeinschaft_/_der_Gesellschaft
         * @maxLength: 1
         */
        Einz_Unt: {
          /**
           * im_Wirtschaftsjahr_vom_(Tag,_Monat)_-_bis_(Tag,_Monat)
           */
          E0900101: string;
          /**
           * Gewinn_nach_§_4_Absatz_1_oder_Absatz_3_EStG
           * @maxLength: 1
           */
          P4_Abs_1_3: {
            /**
             * Gewinn_in_$VZ-1$_/_$VZ$_($VZ$)
             */
            E0900202: string;
            /**
             * auf_das_Kalenderjahr_$VZ$_entfallen_(Steuerpflichtige_Person_/_Ehemann_/_Person_A_/_Gemeinschaft_/_Gesellschaft)
             */
            E0900203: string;
            /**
             * auf_das_Kalenderjahr_$VZ$_entfallen_(Ehefrau_/_Person_B)
             */
            E0900204: string;
            /**
             * Gewinn_in_$VZ$_/_$VZ+1$
             */
            E0900301: string;
            /**
             * auf_das_Kalenderjahr_$VZ$_entfallen_(Steuerpflichtige_Person_/_Ehemann_/_Person_A_/_Gemeinschaft_/_Gesellschaft)
             */
            E0900302: string;
            /**
             * auf_das_Kalenderjahr_$VZ$_entfallen_(Ehefrau_/_Person_B)
             */
            E0900303: string;
          }[];
          /**
           * Gewinn_nach_§_13a_EStG
           * @maxLength: 1
           */
          P13a: {
            /**
             * Gewinn_in_$VZ-1$_/_$VZ$_($VZ$)
             */
            E0900405: string;
            /**
             * auf_das_Kalenderjahr_$VZ$_entfallen_(Steuerpflichtige_Person_/_Ehemann_/_Person_A_/_Gemeinschaft_/_Gesellschaft)
             */
            E0900403: string;
            /**
             * auf_das_Kalenderjahr_$VZ$_entfallen_(Ehefrau_/_Person_B)
             */
            E0900404: string;
            /**
             * Gewinn_in_$VZ$_/_$VZ+1$
             */
            E0900502: string;
            /**
             * auf_das_Kalenderjahr_$VZ$_entfallen_(Steuerpflichtige_Person_/_Ehemann_/_Person_A_/_Gemeinschaft_/_Gesellschaft)
             */
            E0900503: string;
            /**
             * auf_das_Kalenderjahr_$VZ$_entfallen_(Ehefrau_/_Person_B)
             */
            E0900504: string;
          }[];
        }[];
        /**
         * Gewinn_laut_gesonderter_Feststellung:_$Person.Wert$
         * @maxLength: 2
         */
        Ges_Fest: {
          Person: ElsterPerson;
          /**
           * Gewinn_laut_gesonderter_Feststellung_(§_4_Absatz_1_oder_Absatz_3_EStG):_Betriebsfinanzamt,_Steuernummer
           */
          E0901005: string;
          /**
           * Gewinn_laut_gesonderter_Feststellung_(§_4_Absatz_1_oder_Absatz_3_EStG):_Betrag
           */
          E0901007: string;
          /**
           * Gewinn_laut_gesonderter_Feststellung_(§_13a_EStG):_Betriebsfinanzamt,_Steuernummer
           */
          E0901101: string;
          /**
           * Gewinn_laut_gesonderter_Feststellung_(§_13a_EStG):_Betrag
           */
          E0901103: string;
        }[];
        /**
         * Gewinn_als_Mitunternehmer:_$Person.Wert$
         * @maxLength: 2
         */
        MU: {
          Person: ElsterPerson;
          /**
           * Gewinn_als_Mitunternehmer_(§_4_Absatz_1_oder_Absatz_3_EStG):_Gesellschaft,_Finanzamt,_Steuernummer
           */
          E0900711: string;
          /**
           * Gewinn_als_Mitunternehmer_(§_4_Absatz_1_oder_Absatz_3_EStG):_Betrag
           */
          E0900701: string;
          /**
           * Gewinn_als_Mitunternehmer_(§_13a_EStG):_Gesellschaft,_Finanzamt,_Steuernummer
           */
          E0900611: string;
          /**
           * Gewinn_als_Mitunternehmer_(§_13a_EStG):_Betrag
           */
          E0900601: string;
          /**
           * Gewinn_als_Mitunternehmer_einer_Gesellschaft_/_Gemeinschaft_/_eines_ähnlichen_Modells_im_Sinne_des_§_15b_EStG
           */
          E0900913: string;
          /**
           * Gewinn_als_Mitunternehmer_einer_Gesellschaft_/_Gemeinschaft_/_eines_ähnlichen_Modells_im_Sinne_des_§_15b_EStG:_Betrag
           */
          E0900903: string;
        }[];
        /**
         * Teileinkünfteverfahren:_$Person.Wert$
         * @maxLength: 2
         */
        TEV: {
          Person: ElsterPerson;
          /**
           * In_den_Gewinnen_des_Kalenderjahres_$VZ$_(Zeile_6_bis_13)_nicht_enthaltener_steuerfreier_Teil_der_Einkünfte,_für_die_das_Teileinkünfteverfahren_gilt
           */
          E0901001: string;
          /**
           * In_den_Gewinnen_des_Kalenderjahres_$VZ$_(Zeile_6_bis_13)_nicht_enthaltener_steuerfreier_Teil_der_Einkünfte,_für_die_das_Teileinkünfteverfahren_gilt_-_Aufstellung
           */
          E0901003: string;
        }[];
        /**
         * Einkünfte_im_Sinne_des_§_2_Absatz_4_UmwStG:_$Person.Wert$
         * @maxLength: 2
         */
        P2_Abs_4_UmwStG: {
          Person: ElsterPerson;
          /**
           * In_den_Zeilen_6_bis_13_enthaltene_positive_Einkünfte_im_Sinne_des_§_2_Absatz_4_UmwStG
           */
          E0901403: string;
        }[];
        /**
         * Begünstigung_der_nicht_entnommenen_Gewinne
         * @maxLength: 1
         */
        Beg_P34a: {
          /**
           * Ich_beantrage_für_den_in_den_Zeilen_6,_7,_10,_12_und_36_enthaltenen_Gewinn_die_Begünstigung_nach_§_34a_EStG_und_/_oder_es_wurde_zum_31.12.$VZ-1$_ein_nachversteuerungspflichtiger_Betrag_festgestellt._Einzureichende_Anlage(n)_34a_(Anzahl)
           */
          E0901304: string;
        }[];
      }[];
      /**
       * Sonstiges:_$Person.Wert$
       * @maxLength: 2
       */
      Sonst: {
        Person: ElsterPerson;
        /**
         * In_den_Zeilen_6_bis_14_enthaltene_begünstigte_sonstige_Gewinne_im_Sinne_des_§_34_Absatz_2_Nummer_2_bis_4_EStG
         */
        E0901601: string;
      }[];
      /**
       * Antrag_nach_§_13a_Absatz_2_EStG
       * @maxLength: 1
       */
      Ant_P13a_Abs_2: {
        /**
         * für_die_Wirtschaftsjahre_$VZ$_/_$VZ+1$_bis_$VZ+3$_/_$VZ+4$
         * @maxLength: 1
         */
        WJ: {
          /**
           * Stellen_Sie_den_Antrag_und_ermitteln_Sie_den_Gewinn_durch_Betriebsvermögensvergleich,_sind_Sie_auch_für_die_Wirtschaftsjahre_$VZ+1$_/_$VZ+2$_bis_$VZ+3$_/_$VZ+4$_verpflichtet,_den_Gewinn_in_gleicher_Weise_zu_ermitteln._Entsprechendes_gilt_bei_einem_Antrag_auf_Besteuerung_des_Gewinns,_der_durch_Vergleich_der_Betriebseinnahmen_mit_den_Betriebsausgaben_ermittelt_wird,_es_sei_denn,_dass_Sie_vorher_buchführungspflichtig_werden.
           * @maxLength: 1
           */
          Erlaeut: {
            /**
             * Ich_/_Wir_beantrage(n),_den_durch
             */
            E0902601: string;
            /**
             * Betriebsvermögensvergleich_ermittelten_Gewinn_der_Besteuerung_zugrunde_zu_legen.
             */
            E0902602: string;
            /**
             * Aufzeichnung_und_Vergleich_der_Betriebseinnahmen_mit_den_Betriebsausgaben_ermittelten_Gewinn_der_Besteuerung_zugrunde_zu_legen.
             */
            E0902603: string;
          }[];
        }[];
      }[];
      /**
       * Veräußerungsgewinn_vor_Abzug_des_Freibetrags_bei_Veräußerung_/_Aufgabe_eines_ganzen_Betriebs,_eines_Teilbetriebs_oder_eines_ganzen_Mitunternehmeranteils_(§§_14,_16_EStG):
       * @maxLength: 1
       */
      VAe_G_v_FB: {
        /**
         * $Person.Wert$
         * @maxLength: 2
         */
        Pers: {
          Person: ElsterPerson;
          /**
           * Veräußerungsgewinn,_für_den_der_Freibetrag_nach_den_§§_14,_16_Absatz_4_EStG_wegen_dauernder_Berufsunfähigkeit_oder_Vollendung_des_55._Lebensjahres_beantragt_wird._Für_nach_dem_31.12.1995_erfolgte_Veräußerungen_/_Aufgaben_wurde_der_Freibetrag_nach_§_16_Absatz_4_EStG_bei_keiner_Einkunftsart_in_Anspruch_genommen.
           */
          E0901201: string;
          /**
           * In_Zeile_31_enthaltener_steuerpflichtiger_Teil,_für_den_das_Teileinkünfteverfahren_gilt
           */
          E0901603: string;
          /**
           * Auf_den_Veräußerungsgewinn_laut_Zeile_31_wurde_zumindest_teilweise_§_6b_oder_§_6b_in_Verbindung_mit_§_6c_EStG_angewendet._Die_Übertragungen_von_aufgedeckten_stillen_Reserven_und_/_oder_die_in_Anspruch_genommenen_Rücklagen_nach_§_6b_Absatz_1_bis_9_gegebenenfalls_in_Verbindung_mit_§_6c_EStG_betragen
           */
          E0903324: string;
          /**
           * Auf_den_Veräußerungsgewinn_laut_Zeile_31_wurde_zumindest_teilweise_§_6b_oder_§_6b_in_Verbindung_mit_§_6c_EStG_angewendet._Die_Übertragungen_von_aufgedeckten_stillen_Reserven_und_/_oder_die_in_Anspruch_genommenen_Rücklagen_nach_§_6b_Absatz_10_gegebenenfalls_in_Verbindung_mit_§_6c_EStG_betragen
           */
          E0903401: string;
          /**
           * Veräußerungsgewinn_laut_Zeile_31,_für_den_der_ermäßigte_Steuersatz_des_§_34_Absatz_3_EStG_wegen_dauernder_Berufsunfähigkeit_oder_Vollendung_des_55._Lebensjahres_beantragt_wird._Für_nach_dem_31.12.2000_erfolgte_Veräußerungen_/_Aufgaben_wurde_der_ermäßigte_Steuersatz_des_§_34_Absatz_3_EStG_bei_keiner_Einkunftsart_in_Anspruch_genommen.
           */
          E0901704: string;
          /**
           * Veräußerungsgewinn(e),_für_den_/_die_der_Freibetrag_nach_den_§§_14,_16_Absatz_4_EStG_nicht_beantragt_wird_oder_nicht_zu_gewähren_ist
           */
          E0901302: string;
          /**
           * In_Zeile_36_enthaltener_steuerpflichtiger_Teil,_für_den_das_Teileinkünfteverfahren_gilt
           */
          E0901904: string;
          /**
           * Auf_den_/_die_Veräußerungsgewinn(e)_laut_Zeile_36_wurde_zumindest_teilweise_§_6b_Absatz_1_bis_9_gegebenenfalls_in_Verbindung_mit_§_6c_EStG_angewendet.
           */
          E0903713: string;
          /**
           * Auf_den_/_die_Veräußerungsgewinn(e)_laut_Zeile_36_wurde_zumindest_teilweise_§_6b_Absatz_10_gegebenenfalls_in_Verbindung_mit_§_6c_EStG_angewendet
           */
          E0903903: string;
          /**
           * In_Zeile_36_enthaltener_Veräußerungsgewinn,_für_den_der_ermäßigte_Steuersatz_des_§_34_Absatz_3_EStG_wegen_dauernder_Berufsunfähigkeit_oder_Vollendung_des_55._Lebensjahres_beantragt_wird._Für_nach_dem_31.12.2000_erfolgte_Veräußerungen_/_Aufgaben_wurde_der_ermäßigte_Steuersatz_des_§_34_Absatz_3_EStG_bei_keiner_Einkunftsart_in_Anspruch_genommen.
           */
          E0902002: string;
          /**
           * In_Zeile_40_enthaltener_steuerpflichtiger_Teil,_für_den_das_Teileinkünfteverfahren_gilt
           */
          E0902104: string;
          /**
           * Veräußerungsverlust_nach_den_§§_14,_16_EStG
           */
          E0902204: string;
          /**
           * In_Zeile_42_enthaltener_steuerpflichtiger_Teil,_für_den_das_Teileinkünfteverfahren_gilt
           */
          E0902307: string;
        }[];
        /**
         * Zu_den_Zeilen_31_bis_41:
         * @maxLength: 1
         */
        Abfr_Erwerb_Ges: {
          /**
           * Erwerber_ist_eine_Gesellschaft,_an_der_die_veräußernde_Person_oder_ein_Angehöriger_beteiligt_ist_(laut_Aufstellung).
           */
          E0901501: string;
          /**
           * Erläuterung_zur_Beteiligung_der_veräußernden_Person_oder_eines_Angehörigen_an_der_erwerbenden_Gesellschaft
           */
          E0901502: string;
          /**
           * Die_Betriebsaufgabe_erstreckt_sich_über_mehr_als_ein_Kalenderjahr_(Steuerpflichtige_Person_/_Ehemann_/_Person_A_/_Gemeinschaft_/_Gesellschaft)
           */
          E0901503: string;
          /**
           * Die_Betriebsaufgabe_erstreckt_sich_über_mehr_als_ein_Kalenderjahr_(Ehefrau_/_Person_B)
           */
          E0901504: string;
        }[];
      }[];
      /**
       * Flächen_zu_Beginn_des_Wirtschaftsjahres
       * @maxLength: 1
       */
      Flaechen_Beginn_WJ: {
        /**
         * Eigentümer_/_Nutzender
         */
        E0902905: string;
        /**
         * Eigentumsflächen_des_Betriebsvermögens_(ohne_Flächen_laut_Zeile_47)
         * @maxLength: 1
         */
        Eig_Fl_BV: {
          /**
           * Erläuterungen
           */
          E0903221: string;
          /**
           * Landwirtschaftliche_Nutzung_(in_ha/a/m²)
           */
          E0903211: string;
          /**
           * Forstwirtschaftliche_Nutzung_(in_ha/a/m²)
           */
          E0903219: string;
          /**
           * Übrige_Nutzungen_(in_ha/a/m²)
           */
          E0903222: string;
        }[];
        /**
         * Hof-_und_Gebäudeflächen_(ohne_Grund_und_Boden_für_Wohngebäude)
         * @maxLength: 1
         */
        Hof_Geb: {
          /**
           * Erläuterungen
           */
          E0903321: string;
          /**
           * Landwirtschaftliche_Nutzung_(in_ha/a/m²)
           */
          E0903311: string;
          /**
           * Forstwirtschaftliche_Nutzung_(in_ha/a/m²)
           */
          E0903319: string;
          /**
           * Übrige_Nutzungen_(in_ha/a/m²)
           */
          E0903322: string;
        }[];
        /**
         * In_den_Zeilen_46_und_47_nicht_berücksichtigte_zugepachtete_oder_unentgeltlich_von_Dritten_überlassene_Flächen
         * @maxLength: 50
         */
        Zugep_unent_ueb_n_ber: {
          /**
           * Name_und_Anschrift_des_Verpächters_/_Überlassenden
           */
          E0903801: string;
          /**
           * Katastermäßige_Bezeichnung
           */
          E0903802: string;
          /**
           * Verausgabte_Pachtzinsen_(EUR)
           */
          E0903803: string;
          /**
           * Landwirtschaftliche_Nutzung_(in_ha/a/m²)
           */
          E0903804: string;
          /**
           * Forstwirtschaftliche_Nutzung_(in_ha/a/m²)
           */
          E0903812: string;
          /**
           * Übrige_Nutzungen_(in_ha/a/m²)
           */
          E0903422: string;
        }[];
        /**
         * Summe_Zeilen_46_bis_48
         * @maxLength: 1
         */
        ZwiSum_Flaech: {
          /**
           * Verausgabte_Pachtzinsen_(EUR)
           */
          E0903901: string;
          /**
           * Landwirtschaftliche_Nutzung_(in_ha/a/m²)
           */
          E0903902: string;
          /**
           * Forstwirtschaftliche_Nutzung_(in_ha/a/m²)
           */
          E0903910: string;
          /**
           * Übrige_Nutzungen_(in_ha/a/m²)
           */
          E0903522: string;
        }[];
        /**
         * In_den_Zeilen_46_bis_48_berücksichtigte_verpachtete_oder_unentgeltlich_an_Dritte_überlassene_Flächen
         * @maxLength: 50
         */
        Verp_ueberl_ber: {
          /**
           * Name_und_Anschrift_des_Pächters
           */
          E0904301: string;
          /**
           * Katastermäßige_Bezeichnung
           */
          E0904302: string;
          /**
           * Vereinnahmte_Pachtzinsen_(EUR)
           */
          E0904303: string;
          /**
           * Landwirtschaftliche_Nutzung_(in_ha/a/m²)
           */
          E0904304: string;
          /**
           * Forstwirtschaftliche_Nutzung_(in_ha/a/m²)
           */
          E0904312: string;
          /**
           * Übrige_Nutzungen_(in_ha/a/m²)
           */
          E0903622: string;
        }[];
        /**
         * Summe_der_vereinnahmten_Pachtzinsen
         * @maxLength: 1
         */
        Pachtzins: {
          /**
           * Summe_vereinnahmte_Pachtzinsen
           */
          E0904501: string;
        }[];
        /**
         * Selbstbewirtschaftete_Flächen_insgesamt_(Zeile_49_abzüglich_Zeile_50)
         * @maxLength: 1
         */
        Selbst_bewirt: {
          /**
           * Landwirtschaftliche_Nutzung_(in_ha/a/m²)
           */
          E0904401: string;
          /**
           * Forstwirtschaftliche_Nutzung_(in_ha/a/m²)
           */
          E0904409: string;
          /**
           * Übrige_Nutzungen_(in_ha/a/m²)
           */
          E0903722: string;
        }[];
        /**
         * Von_der_landwirtschaftlichen_Nutzung_(Zeile_51)_entfallen_auf
         * @maxLength: 1
         */
        Obstb_Alm_Hut: {
          /**
           * Obstbau_mit_landwirtschaftlicher_Unternutzung_(in_ha/a/m²)
           */
          E0904909: string;
          /**
           * Almen_und_Hutungen_(in_ha/a/m²)
           */
          E0904910: string;
        }[];
      }[];
      /**
       * Flächenveränderungen_nach_Beginn_des_Wirtschaftsjahres
       * @maxLength: 1
       */
      Aend_Fl_n_Beg_WJ: {
        /**
         * Zugänge_(Kauf,_Zupachtung,_unentgeltliche_Überlassung)
         * @maxLength: 1
         */
        Zugang: {
          /**
           * Erläuterungen
           */
          E0904521: string;
          /**
           * Landwirtschaftliche_Nutzung_(in_ha/a/m²)
           */
          E0904511: string;
          /**
           * Forstwirtschaftliche_Nutzung_(in_ha/a/m²)
           */
          E0904519: string;
          /**
           * Übrige_Nutzungen_(in_ha/a/m²)
           */
          E0903922: string;
        }[];
        /**
         * Abgänge_(Verkauf,_Verpachtung,_unentgeltliche_Überlassung)
         * @maxLength: 1
         */
        Abgang: {
          /**
           * Erläuterungen
           */
          E0904621: string;
          /**
           * Landwirtschaftliche_Nutzung_(in_ha/a/m²)
           */
          E0904611: string;
          /**
           * Forstwirtschaftliche_Nutzung_(in_ha/a/m²)
           */
          E0904619: string;
          /**
           * Übrige_Nutzungen_(in_ha/a/m²)
           */
          E0904022: string;
        }[];
      }[];
      /**
       * Betriebsverpachtung
       * @maxLength: 1
       */
      Betr_Verp: {
        /**
         * Der_Betrieb_ist_verpachtet_seit_dem
         */
        E0905001: string;
      }[];
      /**
       * Veräußerung_/_Entnahme_von_Grundstücken_und_immateriellen_Wirtschaftsgütern
       * @maxLength: 1
       */
      VA_Entn_Grd_Immat: {
        /**
         * Bei_Veräußerung_von_Grundstücken:_Gewinnübertragung_nach_§§_6b,_6c_EStG_wird_beantragt.
         */
        E0905101: string;
        /**
         * Höhe_der_Gewinnübertragung_nach_§§_6b,_6c_EStG
         */
        E0905102: string;
        /**
         * Veräußerung_(Umfang_des_mitveräußerten_Eigenjagdrechts_/_Aufwuchses_auf_und_Anlagen_in_und_auf_dem_Grund_und_Boden_gesondert_erläutern)
         * @maxLength: 50
         */
        Veraeuss: {
          /**
           * Katastermäßige_Bezeichnung
           */
          E0906101: string;
          /**
           * Größe_(in_ha/a/m²)
           */
          E0906102: string;
          /**
           * Tag_der_Veräußerung
           */
          E0906103: string;
          /**
           * Erlös
           */
          E0906104: string;
          /**
           * Entstandene_Kosten
           */
          E0906105: string;
          /**
           * Anschaffungskosten_(gegebenenfalls_Wert_nach_§_55_EStG)
           */
          E0906106: string;
          /**
           * Erläuterungen_zum_Umfang_des_mitveräußerten_Eigenjagdrechts_/_Aufwuchses_auf_Anlagen_in_und_auf_dem_Grund_und_Boden
           */
          E0906116: string;
        }[];
        /**
         * Entnahme_(zum_Beispiel_durch_Schenkung,_Nutzungsänderung,_Bau_einer_eigengenutzten_oder_unentgeltlich_überlassenen_Wohnung)
         * @maxLength: 50
         */
        Entn: {
          /**
           * Katastermäßige_Bezeichnung
           */
          E0905601: string;
          /**
           * Größe_(in_ha/a/m²)
           */
          E0905602: string;
          /**
           * Tag_der_Entnahme
           */
          E0905603: string;
          /**
           * Entnahmewert
           */
          E0905604: string;
          /**
           * Entstandene_Kosten
           */
          E0905605: string;
          /**
           * Anschaffungskosten_(gegebenenfalls_Wert_nach_§_55_EStG)
           */
          E0905606: string;
        }[];
        /**
         * Veräußerung_/_Entnahme_von_Milchlieferrechten
         * @maxLength: 1
         */
        Lief_R_Milch: {
          /**
           * Größe_/_Menge_in_kg
           */
          E0905516: string;
          /**
           * Tag_der_Veräußerung_/_Entnahme
           */
          E0905517: string;
          /**
           * Erlös_/_Entnahmewert
           */
          E0905518: string;
          /**
           * Entstandene_Kosten
           */
          E0905519: string;
          /**
           * Anschaffungskosten_(gegebenenfalls_Wert_nach_§_55_EStG)
           */
          E0905522: string;
        }[];
        /**
         * Veräußerung_/_Entnahme_von_Zuckerrübenlieferrechten
         * @maxLength: 1
         */
        Lief_R_Zucker: {
          /**
           * Größe_/_Menge_in_t
           */
          E0905622: string;
          /**
           * Tag_der_Veräußerung_/_Entnahme
           */
          E0905623: string;
          /**
           * Erlös_/_Entnahmewert
           */
          E0905624: string;
          /**
           * Entstandene_Kosten
           */
          E0905625: string;
          /**
           * Anschaffungskosten_(gegebenenfalls_Wert_nach_§_55_EStG)
           */
          E0905626: string;
        }[];
        /**
         * Veräußerung_/_Entnahme_von_Zahlungsansprüchen_nach_der_GAP-Reform
         * @maxLength: 1
         */
        Zahl_Ansp: {
          /**
           * Anzahl
           */
          E0905422: string;
          /**
           * Tag_der_Veräußerung_/_Entnahme
           */
          E0905423: string;
          /**
           * Erlös_/_Entnahmewert
           */
          E0905424: string;
          /**
           * Entstandene_Kosten
           */
          E0905425: string;
          /**
           * Anschaffungskosten_(gegebenenfalls_Wert_nach_§_55_EStG)
           */
          E0905426: string;
        }[];
      }[];
      /**
       * Tierhaltung_(einschließlich_Pensionstierhaltung_und_Lohnaufzucht)
       * @maxLength: 1
       */
      Tierhalt: {
        /**
         * Jahresdurchschnittsbestand_im_Wirtschaftsjahr_$VZ$_/_$VZ+1$_($VZ$)
         * @maxLength: 1
         */
        Durchsch_Jahr: {
          /**
           * Rindvieh
           * @maxLength: 1
           */
          Rind: {
            /**
             * Kälber_und_Jungvieh_unter_1_Jahr_einschließlich_Mastkälber:_Anzahl
             */
            E0906501: string;
            /**
             * Kälber_und_Jungvieh_unter_1_Jahr_einschließlich_Mastkälber_(0,3_VE_je_Stück):_VE_gesamt
             */
            E0906551: string;
            /**
             * Jungvieh_1-2_Jahre:_Anzahl
             */
            E0906601: string;
            /**
             * Jungvieh_1-2_Jahre_(0,7_VE_je_Stück):_VE_gesamt
             */
            E0906651: string;
            /**
             * Zuchtbullen_und_Zugochsen:_Anzahl
             */
            E0906701: string;
            /**
             * Zuchtbullen_und_Zugochsen_(1,2_VE_je_Stück):_VE_gesamt
             */
            E0906751: string;
            /**
             * Masttiere_(Mastrinder)_-_Mastdauer_weniger_als_1_Jahr:_Anzahl
             */
            E0906801: string;
            /**
             * Masttiere_(Mastrinder)_-_Mastdauer_weniger_als_1_Jahr_-_(1_VE_je_Stück):_VE_gesamt
             */
            E0906851: string;
            /**
             * Färsen_älter_als_2_Jahre:_Anzahl
             */
            E0906901: string;
            /**
             * Färsen_älter_als_2_Jahre_(1_VE_je_Stück):_VE_gesamt
             */
            E0906951: string;
            /**
             * Kühe:_Anzahl
             */
            E0907001: string;
            /**
             * Kühe_(1_VE_je_Stück):_VE_gesamt
             */
            E0907051: string;
          }[];
          /**
           * Ziegen
           * @maxLength: 1
           */
          Ziege: {
            /**
             * Ziegen:_Anzahl
             */
            E0906502: string;
            /**
             * Ziegen_(0,08_VE_je_Stück):_VE_gesamt
             */
            E0906552: string;
          }[];
          /**
           * Pferde
           * @maxLength: 1
           */
          Pferd: {
            /**
             * unter_3_Jahre_und_Kleinpferde:_Anzahl
             */
            E0906602: string;
            /**
             * unter_3_Jahre_und_Kleinpferde_(0,7_VE_je_Stück):_VE_gesamt
             */
            E0906652: string;
            /**
             * 3_Jahre_alt_und_älter:_Anzahl
             */
            E0906702: string;
            /**
             * 3_Jahre_alt_und_älter_(1,1_VE_je_Stück):_VE_gesamt
             */
            E0906752: string;
          }[];
          /**
           * Zwischensumme_1
           * @maxLength: 1
           */
          Zwisum1: {
            /**
             * Summe_der_Vieheinheiten_(VE)
             */
            E0907071: string;
          }[];
          /**
           * Schafe
           * @maxLength: 1
           */
          Schaf: {
            /**
             * unter_1_Jahr_einschließlich_Mastlämmer:_Anzahl
             */
            E0906802: string;
            /**
             * unter_1_Jahr_einschließlich_Mastlämmer_(0,05_VE_je_Stück):_VE_gesamt
             */
            E0906852: string;
            /**
             * 1_Jahr_alt_und_älter:_Anzahl
             */
            E0906902: string;
            /**
             * 1_Jahr_alt_und_älter_(0,1_VE_je_Stück):_VE_gesamt
             */
            E0906952: string;
          }[];
          /**
           * Schweine
           * @maxLength: 1
           */
          Schwein: {
            /**
             * Zuchtschweine:_Anzahl
             */
            E0907002: string;
            /**
             * Zuchtschweine_(0,33_VE_je_Stück):_VE_gesamt
             */
            E0907052: string;
          }[];
          /**
           * Kaninchen
           * @maxLength: 1
           */
          Hase: {
            /**
             * Zucht-_und_Angorakaninchen:_Anzahl
             */
            E0906503: string;
            /**
             * Zucht-_und_Angorakaninchen_(0,025_VE_je_Stück):_VE_gesamt
             */
            E0906553: string;
          }[];
          /**
           * Geflügel
           * @maxLength: 1
           */
          Geflueg: {
            /**
             * Legehennen:_Anzahl
             */
            E0906603: string;
            /**
             * Legehennen_(0,02_VE_je_Stück):_VE_gesamt
             */
            E0906653: string;
            /**
             * Legehennen_aus_zugekauften_Junghennen:_Anzahl
             */
            E0906703: string;
            /**
             * Legehennen_aus_zugekauften_Junghennen_(0,0183_VE_je_Stück):_VE_gesamt
             */
            E0906753: string;
            /**
             * Zuchtenten,_Zuchtputen_und_Zuchtgänse:_Anzahl
             */
            E0906803: string;
            /**
             * Zuchtenten,_Zuchtputen_und_Zuchtgänse_(0,04_VE_je_Stück):_VE_gesamt
             */
            E0906853: string;
          }[];
          /**
           * Sonstige_(zum_Beispiel_Damtiere,_Alpakas,_Lamas,_Strauße)
           * @maxLength: 1
           */
          Sonst: {
            /**
             * Einzelangaben
             * @maxLength: 3
             */
            Einz: {
              /**
               * Tierart:
               */
              E0907003: string;
              /**
               * Stück:
               */
              E0907004: string;
            }[];
            /**
             * VE_gesamt
             * @maxLength: 1
             */
            Sum: {
              /**
               * Betrag
               */
              E0907053: string;
            }[];
          }[];
          /**
           * Zwischensumme_2
           * @maxLength: 1
           */
          ZwiSum2: {
            /**
             * Summe_der_Vieheinheiten_(VE)
             */
            E0907072: string;
          }[];
        }[];
      }[];
      /**
       * Tierhaltung_(einschließlich_Pensionstierhaltung_und_Lohnaufzucht)
       * @maxLength: 1
       */
      Tierhalt_JE: {
        /**
         * Jahreserzeugung_(verkauft_oder_verbraucht)_im_Wirtschaftsjahr_$VZ$_/_$VZ+1$_($VZ$)
         * @maxLength: 1
         */
        Jahreserz: {
          /**
           * Rindvieh
           * @maxLength: 1
           */
          Rind: {
            /**
             * Masttiere_-_Mastdauer_über_1_Jahr:_Anzahl
             */
            E0907201: string;
            /**
             * Masttiere_-_Mastdauer_über_1_Jahr-_(1_VE_je_Stück):_VE_gesamt
             */
            E0907251: string;
          }[];
          /**
           * Schweine
           * @maxLength: 1
           */
          Schwein: {
            /**
             * Leichte_Ferkel_bis_etwa_12_kg:_Anzahl
             */
            E0907301: string;
            /**
             * Leichte_Ferkel_bis_etwa_12_kg_(0,01_VE):_VE_gesamt
             */
            E0907351: string;
            /**
             * Ferkel_bis_etwa_20_kg:_Anzahl
             */
            E0907401: string;
            /**
             * Ferkel_bis_etwa_20_kg_(0,02_VE):_VE_gesamt
             */
            E0907451: string;
            /**
             * Schwere_Ferkel_und_leichte_Läufer_bis_etwa_30_kg:_Anzahl
             */
            E0907501: string;
            /**
             * Schwere_Ferkel_und_leichte_Läufer_bis_etwa_30_kg_(0,04_VE):_VE_gesamt
             */
            E0907551: string;
            /**
             * Läufer_bis_etwa_45_kg:_Anzahl
             */
            E0907601: string;
            /**
             * Läufer_bis_etwa_45_kg_(0,06_VE):_VE_gesamt
             */
            E0907651: string;
            /**
             * Schwere_Läufer_bis_etwa_60_kg:_Anzahl
             */
            E0907701: string;
            /**
             * Schwere_Läufer_bis_etwa_60_kg_(0,08_VE):_VE_gesamt
             */
            E0907751: string;
            /**
             * Mastschweine:_Anzahl
             */
            E0907202: string;
            /**
             * Mastschweine_(0,16_VE):_VE_gesamt
             */
            E0907252: string;
            /**
             * Jungzuchtschweine_bis_etwa_90_kg:_Anzahl
             */
            E0907302: string;
            /**
             * Jungzuchtschweine_bis_etwa_90_kg_(0,12_VE):_VE_gesamt
             */
            E0907352: string;
          }[];
          /**
           * Zwischensumme_3
           * @maxLength: 1
           */
          ZwiSum3: {
            /**
             * Summe_der_Vieheinheiten_(VE)
             */
            E0907073: string;
          }[];

          /**
           * Die_eingetragenen_Tiere_wurden_zugekauft_als:_2._Angabe
           * @maxLength: 2
           */
          Zukauf: {
            /**
             * Tierart:
             */
            E0907502: string;
            /**
             * Anzahl
             */
            E0907503: string;
            /**
             * minus_VE_gesamt:
             */
            E0907553: string;
          }[];
          /**
           * Kaninchen
           * @maxLength: 1
           */
          Kanin: {
            /**
             * Mastkaninchen:_Anzahl
             */
            E0907702: string;
            /**
             * Mastkaninchen_(0,0025_VE):_VE_gesamt
             */
            E0907752: string;
          }[];
          /**
           * Geflügel
           * @maxLength: 1
           */
          Geflueg: {
            /**
             * Jungmasthühner_-_mehr_als_6_Durchgänge_je_Jahr:_Anzahl
             */
            E0907203: string;
            /**
             * Jungmasthühner_-_mehr_als_6_Durchgänge_je_Jahr_(0,0013_VE):_VE_gesamt
             */
            E0907253: string;
            /**
             * Jungmasthühner_-_bis_zu_6_Durchgänge_je_Jahr,_Jungputen_und_-hennen:_Anzahl
             */
            E0907303: string;
            /**
             * Jungmasthühner_-_bis_zu_6_Durchgänge_je_Jahr,_Jungputen_und_-hennen_(0,0017_VE):_VE_gesamt
             */
            E0907353: string;
            /**
             * Mastputen_aus_zugekauften_Jungputen:_Anzahl
             */
            E0907504: string;
            /**
             * Mastputen_aus_zugekauften_Jungputen_(0,005_VE):_VE_gesamt
             */
            E0907554: string;
            /**
             * Mastgänse,_Mastputen_aus_selbst_erzeugten_Jungputen:_Anzahl
             */
            E0907604: string;
            /**
             * Mastgänse,_Mastputen_aus_selbst_erzeugten_Jungputen_(0,0067_VE):_VE_gesamt
             */
            E0907654: string;
            /**
             * Mastenten
             * @maxLength: 1
             */
            Mastenten: {
              /**
               * Mastenten_(Vieheinheiten_pro_Stück_/_Anzahl_/_Zwischensumme)_$index$._Eintrag
               * @maxLength: 3
               */
              Einz: {
                /**
                 * VE_pro_Stück_(je_Aufzuchtphase)
                 */
                E0906610: string;
                /**
                 * Anzahl_(je_Aufzuchtphase)
                 */
                E0907402: string;
                /**
                 * VE_gesamt_einer_Aufzuchtphase_(Zwischensumme)
                 */
                E0908201: string;
              }[];
              /**
               * Mastenten:_Vieheinheiten_gesamt_aller_Aufzuchtphasen_(Summe)
               * @maxLength: 1
               */
              Sum: {
                /**
                 * VE_gesamt_(aller_Aufzuchtphasen)
                 */
                E0907452: string;
              }[];
            }[];
          }[];
          /**
           * Zwischensumme_4
           * @maxLength: 1
           */
          ZwiSum4: {
            /**
             * Summe_der_Vieheinheiten_(VE)
             */
            E0907074: string;
          }[];
          /**
           * Gesamtsumme_VE
           * @maxLength: 1
           */
          VE_Ges: {
            /**
             * Gesamtsumme_Vieheinheiten_(Ergebnis_der_Zwischensummen_1_bis_4)
             */
            E0907075: string;
          }[];
          /**
           * Nur_bei_Pensionstierhaltung_(zum_Beispiel_Pferde,_Rinder):_$index$._Angabe
           * @maxLength: 11
           */
          Pens_Tierh: {
            /**
             * Tierart
             */
            E0910001: string;
            /**
             * Anzahl
             */
            E0910002: string;
          }[];
        }[];
        /**
         * Folgende_in_Zeile_86_enthaltene_Vieheinheiten_wurden_im_Wirtschaftsjahr_$VZ$_/_$VZ+1$_($VZ$)_auf_Tierhaltungsgemeinschaften_nach_§_51a_BewG_übertragen:
         * @maxLength: 50
         */
        P51a: {
          /**
           * Tierhaltungsgemeinschaft,_Steuernummer_der_Gesellschaft,_Einheitswert-Aktenzeichen
           */
          E0908801: string;
          /**
           * Vieheinheiten
           */
          E0908802: string;
        }[];
      }[];
    }[];
  }
  export interface IN {
    /**
     * Einkünfte_aus_nichtselbständiger_Arbeit
     * @maxLength: 2
     */
    N: {
      Person: ElsterPerson;
      /**
       * Angaben_zur_eTIN
       * @maxLength: 12
       */
      ETIN: {
        /**
         * Sofern_keine_Identifikationsnummer_vorhanden:_eTIN_laut_Lohnsteuerbescheinigung
         */
        E0200003: string;
      }[];
      /**
       * Angaben_zum_Arbeitslohn
       * @maxLength: 1
       */
      ArbL: {
        /**
         * Lohnsteuerbescheinigung(en)_Steuerklasse_1-5
         * @maxLength: 99
         */
        LStB_1_5_Einz: {
          /**
           * Bruttoarbeitslohn
           */
          E0200204: string;
          /**
           * Lohnsteuer
           */
          E0200304: string;
          /**
           * Solidaritätszuschlag
           */
          E0200404: string;
          /**
           * Kirchensteuer_des_Arbeitnehmers
           */
          E0200504: string;
          /**
           * Kirchensteuer_für_den_Ehegatten_/_Lebenspartner_(nur_bei_Konfessionsverschiedenheit)
           */
          E0200604: string;
        }[];
        /**
         * Summe_Lohnsteuerbescheinigung(en)_Steuerklasse_1-5
         * @maxLength: 1
         */
        LStB_1_5_Sum: {
          /**
           * Steuerklasse
           */
          E0200002: string;
          /**
           * Summe_Bruttoarbeitslohn
           */
          E0200201: string;
          /**
           * Summe_Lohnsteuer
           */
          E0200301: string;
          /**
           * Summe_Solidaritätszuschlag
           */
          E0200401: string;
          /**
           * Summe_Kirchensteuer_des_Arbeitnehmers
           */
          E0200501: string;
          /**
           * Summe_Kirchensteuer_für_den_Ehegatten_/_Lebenspartner_(nur_bei_Konfessionsverschiedenheit)
           */
          E0200601: string;
        }[];
        /**
         * Lohnsteuerbescheinigung(en)_Steuerklasse_6_oder_einer_Urlaubskasse
         * @maxLength: 99
         */
        LStB_6_Einz: {
          /**
           * Bruttoarbeitslohn
           */
          E0200202: string;
          /**
           * Lohnsteuer
           */
          E0200302: string;
          /**
           * Solidaritätszuschlag
           */
          E0200402: string;
          /**
           * Kirchensteuer_des_Arbeitnehmers
           */
          E0200502: string;
          /**
           * Kirchensteuer_für_den_Ehegatten_/_Lebenspartner_(nur_bei_Konfessionsverschiedenheit)
           */
          E0200602: string;
        }[];
        /**
         * Summe_Lohnsteuerbescheinigung(en)_Steuerklasse_6_oder_einer_Urlaubskasse
         * @maxLength: 1
         */
        LStB_6_Sum: {
          /**
           * Summe_Bruttoarbeitslohn
           */
          E0200203: string;
          /**
           * Summe_Lohnsteuer
           */
          E0200303: string;
          /**
           * Summe_Solidaritätszuschlag
           */
          E0200403: string;
          /**
           * Summe_Kirchensteuer_des_Arbeitnehmers
           */
          E0200503: string;
          /**
           * Summe_Kirchensteuer_für_den_Ehegatten_/_Lebenspartner_(nur_bei_Konfessionsverschiedenheit)
           */
          E0200603: string;
        }[];
        /**
         * Zusätzliche_Angaben_bei_steuerbegünstigten_Versorgungsbezügen
         * @maxLength: 1
         */
        VBez: {
          /**
           * $index$._Versorgungsbezug
           * @maxLength: 3
           */
          Einz: {
            /**
             * Steuerbegünstigte_Versorgungsbezüge_(im_Bruttoarbeitslohn_laut_Zeile_6_enthalten)
             */
            E0200801: string;
            /**
             * Bemessungsgrundlage_für_den_Versorgungsfreibetrag_laut_Nummer_29_der_Lohnsteuerbescheinigung
             */
            E0200902: string;
            /**
             * Maßgebendes_Kalenderjahr_des_Versorgungsbeginns_laut_Nummer_30_der_Lohnsteuerbescheinigung
             */
            E0201307: string;
            /**
             * Bei_unterjähriger_Zahlung:_Erster_Monat,_für_den_Versorgungsbezüge_gezahlt_wurden,_laut_Nummer_31_der_Lohnsteuerbescheinigung
             */
            E0201003: string;
            /**
             * Bei_unterjähriger_Zahlung:_Letzter_Monat,_für_den_Versorgungsbezüge_gezahlt_wurden,_laut_Nummer_31_der_Lohnsteuerbescheinigung
             */
            E0201203: string;
            /**
             * Sterbegeld,_Kapitalauszahlungen_/_Abfindungen_und_Nachzahlungen_von_Versorgungsbezügen_laut_Nummer_32_der_Lohnsteuerbescheinigung_(in_den_Zeilen_6_und_11_enthalten)
             */
            E0201205: string;
            /**
             * Ermäßigt_zu_besteuernde_Versorgungsbezüge_für_mehrere_Jahre_laut_Nummer_9_der_Lohnsteuerbescheinigung
             */
            E0200901: string;
          }[];
          /**
           * 4._Versorgungsbezug_und_weitere
           * @maxLength: 1
           */
          Weit: {
            /**
             * Steuerbegünstigte_Versorgungsbezüge_(im_Bruttoarbeitslohn_laut_Zeile_6_enthalten)
             */
            E0200804: string;
            /**
             * Personell_errechneter_Versorgungsfreibetrag_für_alle_weiteren_Versorgungsbezüge
             */
            E0200905: string;
            /**
             * Maßgebendes_Kalenderjahr_des_Versorgungsbeginns_laut_Nummer_30_der_Lohnsteuerbescheinigung
             */
            E0201310: string;
            /**
             * Bei_unterjähriger_Zahlung:_Erster_Monat,_für_den_Versorgungsbezüge_gezahlt_wurden,_laut_Nummer_31_der_Lohnsteuerbescheinigung
             */
            E0201006: string;
            /**
             * Bei_unterjähriger_Zahlung:_Letzter_Monat,_für_den_Versorgungsbezüge_gezahlt_wurden,_laut_Nummer_31_der_Lohnsteuerbescheinigung
             */
            E0201208: string;
            /**
             * Sterbegeld,_Kapitalauszahlungen_/_Abfindungen_und_Nachzahlungen_von_Versorgungsbezügen_laut_Nummer_32_der_Lohnsteuerbescheinigung_(in_den_Zeilen_6_und_11_enthalten)
             */
            E0201210: string;
            /**
             * Ermäßigt_zu_besteuernde_Versorgungsbezüge_für_mehrere_Jahre_laut_Nummer_9_der_Lohnsteuerbescheinigung
             */
            E0201305: string;
            /**
             * Personell_errechneter_Versorgungsfreibetrag_für_den_4._und_alle_weiteren_steuerbegünstigten_mehrjährigen_Versorgungsbezüge
             */
            E0201306: string;
          }[];
        }[];
        /**
         * Ermäßigt_besteuerte_Entschädigungen_/_Arbeitslohn_für_mehrere_Jahre_laut_Nummer_10_der_Lohnsteuerbescheinigung
         * @maxLength: 1
         */
        Erm_best: {
          /**
           * Einzelangaben
           * @maxLength: 99
           */
          Einz: {
            /**
             * Bezeichnung
             */
            E0201704: string;
            /**
             * Betrag
             */
            E0201705: string;
          }[];
          /**
           * Summe
           * @maxLength: 1
           */
          Sum: {
            /**
             * Betrag
             */
            E0201002: string;
          }[];
        }[];
        /**
         * Entschädigungen_/_Arbeitslohn_für_mehrere_Jahre_-_gegebenenfalls_laut_Nummer_19_der_Lohnsteuerbescheinigung_-_vom_Arbeitgeber_nicht_ermäßigt_besteuert
         * @maxLength: 1
         */
        Nicht_erm_best: {
          /**
           * Einzelangaben
           * @maxLength: 99
           */
          Einz: {
            /**
             * Bezeichnung
             */
            E0201804: string;
            /**
             * Betrag
             */
            E0201805: string;
          }[];
          /**
           * Summe
           * @maxLength: 1
           */
          Sum: {
            /**
             * Betrag
             */
            E0201806: string;
          }[];
        }[];
        /**
         * Steuerabzugsbeträge_zu_ermäßigt_zu_besteuernden_Bezügen_laut_Zeilen_16_und_17
         * @maxLength: 1
         */
        Erm_best_Abz_Betr: {
          /**
           * Einzelangaben
           * @maxLength: 5
           */
          Einz: {
            /**
             * Lohnsteuer
             */
            E0201802: string;
            /**
             * Solidaritätszuschlag
             */
            E0201803: string;
            /**
             * Kirchensteuer_Arbeitnehmer
             */
            E0201903: string;
            /**
             * Kirchensteuer_Ehegatte_/_Lebenspartner
             */
            E0201904: string;
          }[];
          /**
           * Summe
           * @maxLength: 1
           */
          Sum: {
            /**
             * Lohnsteuer
             */
            E0201201: string;
            /**
             * Solidaritätszuschlag
             */
            E0201202: string;
            /**
             * Kirchensteuer_Arbeitnehmer
             */
            E0201301: string;
            /**
             * Kirchensteuer_Ehegatte_/_Lebenspartner
             */
            E0201302: string;
          }[];
        }[];
        /**
         * Steuerpflichtiger_Arbeitslohn_ohne_Lohnsteuerabzug
         * @maxLength: 1
         */
        ArbL_ohne_Abz: {
          /**
           * Steuerpflichtiger_Arbeitslohn,_von_dem_kein_Steuerabzug_vorgenommen_worden_ist_(soweit_nicht_in_der_Lohnsteuerbescheinigung_enthalten)
           */
          E0201401: string;
        }[];
        /**
         * Übertrag_aus_Anlage_N-AUS
         * @maxLength: 1
         */
        Stfr_NAUS: {
          /**
           * Steuerfreier_Arbeitslohn_nach_Doppelbesteuerungsabkommen_/_sonstigen_zwischenstaatlichen_Übereinkommen_(Summe_aus_Zeilen_50,_71_und_/_oder_82_aller_Anlagen_N-AUS)
           */
          E0201502: string;
          /**
           * Steuerfreier_Arbeitslohn_nach_Auslandstätigkeitserlass_(Summe_aus_Zeilen_65_aller_Anlagen_N-AUS)
           */
          E0201602: string;
          /**
           * Steuerfreie_Einkünfte_(Besondere_Lohnbestandteile)_nach_Doppelbesteuerungsabkommen_/_sonstigen_zwischenstaatlichen_Übereinkommen_/_Auslandstätigkeitserlass_(Summe_aus_Zeilen_79_aller_Anlagen_N-AUS)
           */
          E0202324: string;
          /**
           * Beigefügte_Anlage(n)_N-AUS_(Anzahl)
           */
          E0202400: string;
        }[];
        /**
         * Ansässigkeit_in_Belgien_(gemäß_Artikel_4_des_DBA_Belgien)
         * @maxLength: 1
         */
        Belgien: {
          /**
           * Adresse
           */
          E0201604: string;
          /**
           * Arbeitslohn
           */
          E0201605: string;
        }[];
        /**
         * Angaben_zu_Grenzgängern
         * @maxLength: 1
         */
        Grenzg: {
          /**
           * Grenzgänger_nach
           */
          E0201701: string;
          /**
           * Arbeitslohn_in_EUR_/_Schweizer_Franken
           */
          E0201702: string;
          /**
           * Schweizerische_Abzugsteuer_in_Schweizer_Franken
           */
          E0201801: string;
        }[];
        /**
         * Steuerfreie_Aufwandsentschädigungen_/_Einnahmen
         * @maxLength: 1
         */
        Stfr_Aufw_Entsch: {
          /**
           * Steuerfrei_erhaltene_Aufwandsentschädigungen_/_Einnahmen_aus_der_Tätigkeit_als
           */
          E0201901: string;
          /**
           * Betrag
           */
          E0201902: string;
        }[];
        /**
         * Angaben_zu_Lohn-_/_Entgeltersatzleistungen
         * @maxLength: 1
         */
        Ersatzleist: {
          /**
           * Kurzarbeitergeld_einschließlich_Zuschuss_des_Arbeitgebers,_Zuschuss_zum_Mutterschaftsgeld,_Verdienstausfallentschädigung_(Infektionsschutzgesetz),_Aufstockungsbeträge_nach_dem_Altersteilzeitgesetz,_Altersteilzeitzuschläge_nach_Besoldungsgesetzen_(laut_Nummer_15_der_Lohnsteuerbescheinigung)
           * @maxLength: 1
           */
          Betrag: {
            /**
             * Einzelangaben
             * @maxLength: 99
             */
            Einz: {
              /**
               * Bezeichnung
               */
              E0202604: string;
              /**
               * Betrag
               */
              E0202605: string;
            }[];
            /**
             * Summe
             * @maxLength: 1
             */
            Sum: {
              /**
               * Summe
               */
              E0202001: string;
            }[];
          }[];
        }[];
      }[];

      /**
       * ohne_Beträge_laut_Zeile_73_bis_76_-
       * @maxLength: 1
       */
      Wk: {
        /**
         * Wege_zwischen_Wohnung_und_erster_Tätigkeitsstätte_/_Sammelpunkt_/_weiträumigem_Tätigkeitsgebiet_(Entfernungspauschale)
         * @maxLength: 1
         */
        EP: {
          /**
           * Erste_Tätigkeitsstätte_($index$._Angabe)
           * @maxLength: 50
           */
          Erste_Taetig: {
            /**
             * Erste_Tätigkeitsstätte_in_(Postleitzahl,_Ort_und_Straße)
             */
            E0203501: string;
            /**
             * vom_-_bis
             */
            E0203101: string;
            /**
             * Arbeitstage_je_Woche
             */
            E0203508: string;
            /**
             * Urlaubs-,_Krankheits-,_Heimarbeits-_und_Dienstreisetage
             */
            E0203509: string;
            /**
             * aufgesucht_an_Tagen
             */
            E0203503: string;
            /**
             * einfache_Entfernung_in_Kilometern_(auf_volle_Kilometer_abgerundet)
             */
            E0203504: string;
            /**
             * davon_mit_eigenem_oder_zur_Nutzung_überlassenem_PKW_zurückgelegt
             */
            E0203505: string;
            /**
             * davon_mit_Sammelbeförderung_des_Arbeitgebers_zurückgelegt
             */
            E0203510: string;
            /**
             * davon_mit_öffentlichen_Verkehrsmitteln,_Motorrad,_Fahrrad_oder_Ähnliche,_als_Fußgänger,_als_Mitfahrer_einer_Fahrgemeinschaft_zurückgelegt
             */
            E0203506: string;
            /**
             * Aufwendungen_für_Fahrten_mit_öffentlichen_Verkehrsmitteln_(ohne_Flug-_und_Fährkosten)
             */
            E0203611: string;
            /**
             * Behinderungsgrad_mindestens_70_oder_mindestens_50_und_Merkzeichen_\"G\"
             */
            E0203507: string;
          }[];
          /**
           * Sammelpunkt_/_nächstgelegener_Zugang_zum_weiträumigen_Tätigkeitsgebiet_($index$._Angabe)
           * @maxLength: 50
           */
          Sammelp: {
            /**
             * Sammelpunkt_/_nächstgelegener_Zugang_zum_weiträumigen_Tätigkeitsgebiet_(Postleitzahl,_Ort_und_Straße)
             */
            E0203319: string;
            /**
             * vom_-_bis
             */
            E0203325: string;
            /**
             * Arbeitstage_je_Woche
             */
            E0203326: string;
            /**
             * Urlaubs-,_Krankheits-,_Heimarbeits-_und_Dienstreisetage
             */
            E0203327: string;
            /**
             * aufgesucht_an_Tagen
             */
            E0203511: string;
            /**
             * einfache_Entfernung_in_Kilometern_(auf_volle_Kilometer_abgerundet)
             */
            E0203512: string;
            /**
             * davon_mit_eigenem_oder_zur_Nutzung_überlassenem_PKW_zurückgelegt
             */
            E0203513: string;
            /**
             * davon_mit_Sammelbeförderung_des_Arbeitgebers_zurückgelegt
             */
            E0203514: string;
            /**
             * davon_mit_öffentlichen_Verkehrsmitteln,_Motorrad,_Fahrrad_oder_Ähnliche,_als_Fußgänger,_als_Mitfahrer_einer_Fahrgemeinschaft_zurückgelegt
             */
            E0203515: string;
            /**
             * Aufwendungen_für_Fahrten_mit_öffentlichen_Verkehrsmitteln_(ohne_Flug-_und_Fährkosten)
             */
            E0203516: string;
            /**
             * Behinderungsgrad_mindestens_70_oder_mindestens_50_und_Merkzeichen_\"G\"
             */
            E0203517: string;
          }[];
          /**
           * Arbeitgeberleistungen_/_Fahrtkostenzuschüsse
           * @maxLength: 1
           */
          Fahrtk_Ersatz: {
            /**
             * Arbeitgeberleistungen_laut_Nummer_17_und_18_der_Lohnsteuerbescheinigung_(steuerfrei_ersetzt)
             */
            E0204103: string;
            /**
             * Arbeitgeberleistungen_laut_Nummer_17_und_18_der_Lohnsteuerbescheinigung_(pauschal_besteuert)
             */
            E0203901: string;
            /**
             * Von_der_Agentur_für_Arbeit_oder_dem_Jobcenter_gezahlte_Fahrtkostenzuschüsse
             */
            E0204004: string;
          }[];
        }[];
        /**
         * Beiträge_zu_Berufsverbänden
         * @maxLength: 1
         */
        Berufsverb: {
          /**
           * Einzelangaben
           * @maxLength: 99
           */
          Einz: {
            /**
             * Bezeichnung_der_Verbände
             */
            E0204001: string;
            /**
             * Betrag
             */
            E0204003: string;
          }[];
          /**
           * Summe
           * @maxLength: 1
           */
          Sum: {
            /**
             * Summe
             */
            E0204002: string;
          }[];
        }[];
        /**
         * Aufwendungen_für_Arbeitsmittel_-_soweit_nicht_steuerfrei_ersetzt_-
         * @maxLength: 1
         */
        Arbeitsmittel: {
          /**
           * Einzelangaben
           * @maxLength: 99
           */
          Einz: {
            /**
             * Art_der_Arbeitsmittel
             */
            E0204401: string;
            /**
             * Betrag
             */
            E0204402: string;
          }[];
          /**
           * Summe
           * @maxLength: 1
           */
          Sum: {
            /**
             * Summe
             */
            E0204403: string;
          }[];
        }[];
        /**
         * Aufwendungen_für_ein_häusliches_Arbeitszimmer
         * @maxLength: 1
         */
        Arb_Zim: {
          /**
           * Einzelangaben
           * @maxLength: 99
           */
          Einz: {
            /**
             * Art
             */
            E0204503: string;
            /**
             * Betrag
             */
            E0204505: string;
          }[];
          /**
           * Summe
           * @maxLength: 1
           */
          Sum: {
            /**
             * Summe
             */
            E0204504: string;
          }[];
        }[];
        /**
         * Fortbildungskosten_-_soweit_nicht_steuerfrei_ersetzt_-
         * @maxLength: 1
         */
        Fortb: {
          /**
           * Einzelangaben
           * @maxLength: 99
           */
          Einz: {
            /**
             * Bezeichnung
             */
            E0204804: string;
            /**
             * Betrag
             */
            E0204808: string;
          }[];
          /**
           * Summe
           * @maxLength: 1
           */
          Sum: {
            /**
             * Summe
             */
            E0204812: string;
          }[];
        }[];
        /**
         * Weitere_Werbungskosten_-_soweit_nicht_steuerfrei_ersetzt_-
         * @maxLength: 1
         */
        Weitere_Wk: {
          /**
           * Fähr-_und_Flugkosten_bei_Wegen_zwischen_Wohnung_und_erster_Tätigkeitsstätte_/_Sammelpunkt_/_weiträumigem_Tätigkeitsgebiet
           * @maxLength: 99
           */
          Flug: {
            /**
             * Bezeichnung
             */
            E0204801: string;
            /**
             * Betrag
             */
            E0204802: string;
          }[];
          /**
           * Sonstiges_(zum_Beispiel_Bewerbungskosten,_Kontoführungsgebühren)
           * @maxLength: 99
           */
          Sonst: {
            /**
             * Bezeichnung
             */
            E0205405: string;
            /**
             * Betrag
             */
            E0205406: string;
          }[];
          /**
           * Summe
           * @maxLength: 1
           */
          Sum: {
            /**
             * Summe_der_weiteren_Werbungskosten
             */
            E0204803: string;
          }[];
        }[];
        /**
         * Reisekosten_bei_beruflich_veranlassten_Auswärtstätigkeiten
         * @maxLength: 1
         */
        AWT: {
          /**
           * Die_Fahrten_wurden_ganz_oder_teilweise_mit_einem_Firmenwagen_oder_im_Rahmen_einer_unentgeltlichen_Sammelbeförderung_des_Arbeitgebers_durchgeführt_-_Falls_\"Ja\":_Für_die_Fahrten_mit_Firmenwagen_oder_Sammelbeförderung_dürfen_mangels_Aufwands_keine_Eintragungen_zu_Fahrtkosten_in_Zeile_62_vorgenommen_werden.
           */
          E0204901: string;
          /**
           * Fahrtkosten
           * @maxLength: 99
           */
          Fahrt: {
            /**
             * Fahrtkosten
             */
            E0205003: string;
            /**
             * Betrag
             */
            E0205004: string;
          }[];
          /**
           * Übernachtungskosten
           * @maxLength: 99
           */
          Uebernacht: {
            /**
             * Übernachtungskosten
             */
            E0206301: string;
            /**
             * Betrag
             */
            E0206302: string;
          }[];
          /**
           * Reisenebenkosten
           * @maxLength: 99
           */
          Reisenebenk: {
            /**
             * Reisenebenkosten
             */
            E0206402: string;
            /**
             * Betrag
             */
            E0206406: string;
          }[];
          /**
           * Summe
           * @maxLength: 1
           */
          Sum: {
            /**
             * Gesamtsumme_der_Aufwendungen_für_Reisekosten
             */
            E0205518: string;
          }[];
          /**
           * Pauschbeträge_für_Berufskraftfahrer_bei_Übernachtung_im_Kraftfahrzeug
           * @maxLength: 1
           */
          PB_Kraftf: {
            /**
             * Anzahl_der_Tage
             */
            E0206501: string;
          }[];
          /**
           * Steuerfreier_Ersatz
           * @maxLength: 1
           */
          Rk_Ersatz: {
            /**
             * Vom_Arbeitgeber_steuerfrei_ersetzt
             */
            E0205608: string;
          }[];
        }[];
        /**
         * Pauschbeträge_für_Mehraufwendungen_für_Verpflegung
         * @maxLength: 1
         */
        VMA: {
          /**
           * Bei_einer_Auswärtstätigkeit_im_Inland
           * @maxLength: 1
           */
          Inl: {
            /**
             * Abwesenheit_von_mehr_als_8_Stunden:_Anzahl_der_Tage_(bei_Auswärtstätigkeit_ohne_Übernachtung)
             */
            E0205201: string;
            /**
             * An-_und_Abreisetage_(bei_einer_mehrtägigen_Auswärtstätigkeit_mit_Übernachtung):_Anzahl_der_Tage
             */
            E0205302: string;
            /**
             * Abwesenheit_von_24_Stunden:_Anzahl_der_Tage
             */
            E0205409: string;
            /**
             * Kürzungsbeträge_wegen_Mahlzeitengestellung_(eigene_Zahlungen_sind_gegebenenfalls_gegenzurechnen)
             */
            E0205508: string;
          }[];
          /**
           * Bei_einer_Auswärtstätigkeit_im_Ausland
           * @maxLength: 1
           */
          Ausl: {
            /**
             * Aufstellung_zur_Berechnung_der_Mehraufwendungen_für_Verpflegung
             * @maxLength: 99
             */
            Aufst: {
              /**
               * ausländischer_Staat
               */
              E0205609: string;
              /**
               * Abwesenheit_von_mehr_als_8_Stunden:_Anzahl_der_Tage
               */
              E0205610: string;
              /**
               * Pauschbetrag_bei_Abwesenheit_von_mehr_als_8_Stunden
               */
              E0205617: string;
              /**
               * An-_und_Abreisetage_(bei_einer_mehrtägigen_Auswärtstätigkeit_mit_Übernachtung):_Anzahl_der_Tage
               */
              E0205618: string;
              /**
               * Pauschbetrag_für_An-_und_Abreisetage_(bei_einer_mehrtägigen_Auswärtstätigkeit_mit_Übernachtung)
               */
              E0205619: string;
              /**
               * Abwesenheit_von_24_Stunden:_Anzahl_der_Tage
               */
              E0205627: string;
              /**
               * Pauschbetrag_bei_Abwesenheit_von_24_Stunden
               */
              E0205628: string;
            }[];
            /**
             * Kürzungsbetrag
             * @maxLength: 1
             */
            Kuerz_Mahlz: {
              /**
               * Kürzungsbetrag_wegen_Mahlzeitengestellung_(eigene_Zuzahlungen_sind_gegebenenfalls_gegenzurechnen)
               */
              E0205629: string;
            }[];
            /**
             * Summe
             * @maxLength: 1
             */
            Sum: {
              /**
               * Summe_Mehraufwendungen_für_Verpflegung_bei_einer_Auswärtstätigkeit_im_Ausland
               */
              E0205630: string;
            }[];
          }[];
          /**
           * Steuerfreier_Ersatz
           * @maxLength: 1
           */
          VMA_Ersatz: {
            /**
             * Vom_Arbeitgeber_steuerfrei_ersetzt
             */
            E0205108: string;
          }[];
        }[];
        /**
         * Werbungskosten_in_Sonderfällen
         * @maxLength: 1
         */
        Wk_Sonder: {
          /**
           * Werbungskosten_zu_steuerbegünstigten_Versorgungsbezügen_laut_Zeile_11
           * @maxLength: 1
           */
          VBez: {
            /**
             * Art_der_Aufwendungen
             */
            E0206703: string;
            /**
             * Betrag
             */
            E0206702: string;
          }[];
          /**
           * Werbungskosten_zu_steuerbegünstigten_Versorgungsbezügen_für_mehrere_Jahre_laut_Zeile_16
           * @maxLength: 1
           */
          VBez_erm: {
            /**
             * Art_der_Aufwendungen
             */
            E0206803: string;
            /**
             * Betrag
             */
            E0206802: string;
          }[];
          /**
           * Werbungskosten_zu_Entschädigungen_/_Arbeitslohn_für_mehrere_Jahre_laut_Zeile_17_und_/_oder_18
           * @maxLength: 1
           */
          Erm_best: {
            /**
             * Art_der_Aufwendungen
             */
            E0206503: string;
            /**
             * Betrag
             */
            E0206502: string;
          }[];
          /**
           * Werbungskosten_zu_steuerfreiem_Arbeitslohn_laut_Zeile_22_und_23_(Übertrag_aus_den_Zeilen_74_und_83_der_Anlage(n)_N-AUS)
           * @maxLength: 1
           */
          Stfr_NAUS: {
            /**
             * Betrag
             */
            E0206401: string;
          }[];
          /**
           * Werbungskosten_zu_steuerpflichtigem_Arbeitslohn,_von_dem_kein_Steuerabzug_vorgenommen_worden_ist_laut_Zeile_21_-_in_den_Zeilen_31_bis_72_und_91_bis_117_enthalten_-\"
           * @maxLength: 1
           */
          Stpfl_ohne_Abz: {
            /**
             * Art_der_Aufwendungen
             */
            E0209501: string;
            /**
             * Betrag
             */
            E0209502: string;
          }[];
          /**
           * Werbungskosten_zu_Arbeitslohn_für_eine_Tätigkeit_im_Inland,_wenn_ein_weiterer_Wohnsitz_in_Belgien_vorhanden_ist_-_in_den_Zeilen_31_bis_72_und_91_bis_117_enthalten_-
           * @maxLength: 1
           */
          Belgien: {
            /**
             * Betrag
             */
            E0206901: string;
          }[];
        }[];
        /**
         * Mehraufwendungen_für_doppelte_Haushaltsführung_($index$._Angabe)
         * @maxLength: 10
         */
        DHHF: {
          /**
           * Allgemeine_Angaben
           * @maxLength: 1
           */
          Allg: {
            /**
             * Der_doppelte_Haushalt_wurde_aus_beruflichem_Anlass_begründet_am
             */
            E0206103: string;
            /**
             * Grund
             */
            E0206205: string;
            /**
             * Der_doppelte_Haushalt_hat_seitdem_ununterbrochen_bestanden_bis_..._($VZ$)
             */
            E0206304: string;
            /**
             * Beschäftigungsort_(Postleitzahl,_Ort,_sowie_zusätzlich_den_Staat_-_falls_im_Ausland_und_abweichend_vom_Staat,_in_dem_der_doppelte_Haushalt_liegt-)
             */
            E0206404: string;
            /**
             * Der_doppelte_Haushalt_liegt_im_Ausland
             */
            E0206403: string;
            /**
             * Der_doppelte_Haushalt_liegt_im_Ausland:_Staat
             */
            E0206405: string;
            /**
             * Es_liegt_ein_eigener_Hausstand_am_Lebensmittelpunkt_vor
             */
            E0206504: string;
            /**
             * Postleitzahl,_Ort_des_eigenen_Hausstandes
             */
            E0206505: string;
            /**
             * seit
             */
            E0206506: string;
            /**
             * Der_Begründung_des_doppelten_Haushalts_ist_eine_Auswärtstätigkeit_am_selben_Beschäftigungsort_unmittelbar_vorausgegangen
             */
            E0206602: string;
            /**
             * Anstelle_der_Mehraufwendungen_für_doppelte_Haushaltsführung_werden_bei_den_Wegen_zwischen_Wohnung_und_regelmäßiger_Arbeitsstätte_Fahrtkosten_für_mehr_als_eine_Heimfahrt_wöchentlich_geltend_gemacht
             */
            E0206704: string;
          }[];
          /**
           * Fahrtkosten
           * @maxLength: 1
           */
          Fahrtk: {
            /**
             * Die_Fahrten_wurden_mit_einem_Firmenwagen_oder_im_Rahmen_einer_unentgeltlichen_Sammelbeförderung_des_Arbeitgebers_durchgeführt
             */
            E0206805: string;
            /**
             * Erste_Fahrt_zum_Ort_der_ersten_Tätigkeitsstätte_und_letzte_Fahrt_zum_eigenen_Hausstand
             * @maxLength: 1
             */
            Erst_Letzt: {
              /**
               * mit_privatem_Kfz
               * @maxLength: 1
               */
              Priv_Kfz: {
                /**
                 * gefahrene_km
                 */
                E0206903: string;
                /**
                 * Kilometersatz_bei_Einzelnachweis
                 */
                E0206904: string;
                /**
                 * Berechnung_des_Kilometersatzes_bei_Einzelnachweis
                 */
                E0206905: string;
              }[];
              /**
               * mit_privatem_Motorrad_/_Motorroller
               * @maxLength: 1
               */
              Priv_Motorr: {
                /**
                 * gefahrene_km
                 */
                E0206906: string;
                /**
                 * Kilometersatz_bei_Einzelnachweis
                 */
                E0206907: string;
                /**
                 * Berechnung_des_Kilometersatzes_bei_Einzelnachweis
                 */
                E0206908: string;
              }[];
              /**
               * mit_öffentlichen_Verkehrsmitteln_oder_entgeltlicher_Sammelbeförderung
               * @maxLength: 1
               */
              Oeff_VM: {
                /**
                 * Betrag
                 */
                E0207003: string;
              }[];
            }[];
            /**
             * Wöchentliche_Heimfahrten
             * @maxLength: 1
             */
            Woech_Heimf: {
              /**
               * einfache_Entfernung_(ohne_Flugstrecken)_in_km
               */
              E0207116: string;
              /**
               * Anzahl
               */
              E0207117: string;
              /**
               * Kosten_für_öffentliche_Verkehrsmittel_(ohne_Fähr-_und_Flugkosten)
               */
              E0207211: string;
            }[];
            /**
             * Nur_bei_Behinderungsgrad_von_mindestens_70_oder_mindestens_50_und_Merkzeichen_\"G\"
             * @maxLength: 1
             */
            Behind: {
              /**
               * Einfache_Entfernung_(ohne_Flugstrecken)_in_km
               */
              E0207302: string;
              /**
               * davon_mit_privatem_Kfz_zurückgelegt_in_km
               */
              E0207303: string;
              /**
               * Anzahl
               */
              E0207304: string;
              /**
               * Kilometersatz_bei_Einzelnachweis
               */
              E0207305: string;
              /**
               * Berechnung_des_Kilometersatzes_bei_Einzelnachweis
               */
              E0207306: string;
              /**
               * Kosten_für_öffentliche_Verkehrsmittel_(ohne_Fähr-_und_Flugkosten)
               */
              E0207411: string;
            }[];
            /**
             * Flug-_und_Fährkosten_/_entgeltliche_Sammelbeförderung
             * @maxLength: 1
             */
            Flug_Faehr: {
              /**
               * Fähr-_und_Flugkosten_(zu_den_wöchentlichen_Heimfahrten)_oder_Kosten_für_entgeltliche_Sammelbeförderung_für_Heimfahrten
               */
              E0207511: string;
            }[];
          }[];
          /**
           * Kosten_der_Unterkunft_am_Ort_der_ersten_Tätigkeitsstätte
           * @maxLength: 1
           */
          Unterkunft: {
            /**
             * Aufwendungen_(zum_Beispiel_Miete_einschließlich_Stellplatz-_/_Garagenkosten,_Nebenkosten)
             */
            E0207611: string;
            /**
             * Größe_der_Zweitwohnung_des_doppelten_Haushalts_im_Ausland_in_qm
             */
            E0207702: string;
          }[];

          /**
           * Die_Verpflegungsmehraufwendungen_laut_Zeilen_111_bis_114_können_nur_für_einen_Zeitraum_von_3_Monaten_nach_Bezug_der_Unterkunft_am_Ort_der_ersten_Tätigkeitsstätte_geltend_gemacht_werden;_geht_der_doppelten_Haushaltsführung_eine_Auswärtstätigkeit_voraus,_ist_dieser_Zeitraum_auf_den_Dreimonatszeitraum_anzurechnen.
           * @maxLength: 1
           */
          VMA: {
            /**
             * Bei_einer_doppelten_Haushaltsführung_im_Inland
             * @maxLength: 1
             */
            Inl: {
              /**
               * An-_und_Abreisetage:_Anzahl_der_Tage
               */
              E0207901: string;
              /**
               * Abwesenheit_von_24_Stunden:_Anzahl_der_Tage
               */
              E0208011: string;
              /**
               * Kürzungsbetrag_wegen_Mahlzeitengestellung_(eigene_Zuzahlungen_sind_gegebenenfalls_gegenzurechnen)
               */
              E0208301: string;
            }[];
            /**
             * Bei_einer_doppelten_Haushaltsführung_im_Ausland
             * @maxLength: 1
             */
            Ausl: {
              /**
               * An-_und_Abreisetage:_Anzahl_der_Tage
               */
              E0208103: string;
              /**
               * Pauschbetrag_für_An-_und_Abreisetage
               */
              E0208104: string;
              /**
               * Abwesenheit_von_24_Stunden:_Anzahl_der_Tage
               */
              E0208105: string;
              /**
               * Pauschbetrag_bei_Abwesenheit_von_24_Stunden
               */
              E0208106: string;
              /**
               * Kürzungsbetrag_wegen_Mahlzeitengestellung_(eigene_Zuzahlungen_sind_gegebenenfalls_gegenzurechnen)
               */
              E0208401: string;
              /**
               * Summe_der_Verpflegungsmehraufwendungen_bei_einer_doppelten_Haushaltsführung_im_Ausland
               */
              E0208107: string;
            }[];
          }[];
          /**
           * Sonstige_Aufwendungen_(zum_Beispiel_Kosten_für_den_Umzug,_die_Einrichtung_und_den_Hausrat,_jedoch_ohne_Kosten_der_Unterkunft_laut_Zeile_109)
           * @maxLength: 1
           */
          Sonst_Aufw: {
            /**
             * Art_der_Aufwendungen
             */
            E0208201: string;
            /**
             * Betrag
             */
            E0208202: string;
          }[];
        }[];
        /**
         * Zusammenfassung_Mehraufwendungen_für_doppelte_Haushaltsführung
         * @maxLength: 1
         */
        VMA_ges: {
          /**
           * vom_Arbeitgeber_/_von_der_Agentur_für_Arbeit_insgesamt_steuerfrei_ersetzt
           */
          E0206303: string;
        }[];
      }[];
    }[];
  }
  export interface IR {
    /**
     * Renten_und_andere_Leistungen_aus_dem_Inland
     * @maxLength: 2
     */
    R: {
      /**
       * -_Ohne_Leistungen_aus_Altersvorsorgeverträgen_und_aus_der_betrieblichen_Altersversorgung_-
       * @maxLength: 2
       */
      R: {
        Person: ElsterPerson;
        /**
         * Leibrenten_/_Leistungen_aus_gesetzlichen_Rentenversicherungen,_landwirtschaftlicher_Alterskasse,_berufsständischen_Versorgungseinrichtungen,_eigenen_zertifizierten_Basisrentenverträgen
         * @maxLength: 1
         */
        Leibr_gesetzl: {
          /**
           * $#/R/Leibr_gesetzl/Einz$._Rente
           * @maxLength: 15
           */
          Einz: {
            /**
             * Rentenbetrag_einschließlich_Einmalzahlung_und_Leistungen
             */
            E1800301: string;
            /**
             * Rentenanpassungsbetrag_(in_Zeile_4_enthalten)
             */
            E1800606: string;
            /**
             * Beginn_der_Rente
             */
            E1800501: string;
            /**
             * Beginn_der_1._vorhergehenden_Rente
             */
            E1800806: string;
            /**
             * Ende_der_1._vorhergehenden_Rente
             */
            E1800906: string;
            /**
             * Beginn_der_2._vorhergehenden_Rente
             */
            E1800811: string;
            /**
             * Ende_der_2._vorhergehenden_Rente
             */
            E1800911: string;
            /**
             * Beginn_der_3._vorhergehenden_Rente
             */
            E1800821: string;
            /**
             * Ende_der_3._vorhergehenden_Rente
             */
            E1800921: string;
            /**
             * Nachzahlungen_für_mehrere_vorangegangene_Jahre_/_Kapitalauszahlung_(in_Zeile_4_enthalten)
             */
            E1800601: string;
            /**
             * Öffnungsklausel
             * @maxLength: 1
             */
            Oeff_Kl: {
              /**
               * Prozentsatz_(laut_Bescheinigung_Ihres_Versorgungsträgers)
               */
              E1800701: string;
              /**
               * die_Rente_erlischt_/_wird_umgewandelt_spätestens_am
               */
              E1800801: string;
              /**
               * bei_Einmalzahlung:_Betrag
               */
              E1800901: string;
            }[];
          }[];
        }[];
        /**
         * Renten_nach_DBA_Belgien_(bei_Ansässigkeit_in_Belgien_gemäß_Artikel_4_des_DBA_Belgien)
         * @maxLength: 1
         */
        DBA_Belgien: {
          /**
           * Renteneinnahmen_nach_DBA_Belgien
           */
          E1801001: string;
          /**
           * Werbungskosten_zu_Renten_nach_DBA_Belgien
           */
          E1801002: string;
        }[];

        /**
         * (ohne_Renten_laut_Zeile_4_bis_12)
         * @maxLength: 1
         */
        Leibr_priv: {
          /**
           * $#/R/Leibr_priv/Einz$._Rente
           * @maxLength: 15
           */
          Einz: {
            /**
             * Rentenbetrag
             */
            E1801601: string;
            /**
             * Beginn_der_Rente
             */
            E1801701: string;
            /**
             * Geburtsdatum_des_Erblassers_bei_Garantiezeitrenten
             */
            E1801706: string;
            /**
             * Die_Rente_erlischt_mit_dem_Tod_von
             */
            E1801801: string;
            /**
             * Die_Rente_erlischt_/_wird_umgewandelt_spätestens_am
             */
            E1801901: string;
            /**
             * Nachzahlungen_für_mehrere_vorangegangene_Jahre_(in_Zeile_13_enthalten)
             */
            E1802001: string;
          }[];
        }[];

        /**
         * (ohne_Renten_laut_Zeile_4_bis_18)
         * @maxLength: 1
         */
        Leibr_sonst: {
          /**
           * $#/R/Leibr_sonst/Einz$._Rente
           * @maxLength: 15
           */
          Einz: {
            /**
             * Rentenbetrag
             */
            E1803102: string;
            /**
             * Beginn_der_Rente
             */
            E1803202: string;
            /**
             * Geburtsdatum_des_Erblassers_bei_Garantiezeitrenten
             */
            E1803302: string;
            /**
             * Die_Rente_erlischt_mit_dem_Tod_von
             */
            E1803402: string;
            /**
             * Die_Rente_erlischt_/_wird_umgewandelt_spätestens_am
             */
            E1803502: string;
            /**
             * Nachzahlungen_für_mehrere_vorangegangene_Jahre_(in_Zeile_31_enthalten)
             */
            E1803602: string;
          }[];
        }[];
        /**
         * Werbungskosten
         * @maxLength: 1
         */
        WK_Leibr: {
          /**
           * Werbungskosten_zu_den_Zeilen_4,_13_und_Zeile_31_-_ohne_Werbungskosten_laut_Zeile_38_-
           * @maxLength: 1
           */
          Leibr: {
            /**
             * Einzelangaben
             * @maxLength: 99
             */
            Einz: {
              /**
               * Art_der_Aufwendungen
               */
              E1804901: string;
              /**
               * Betrag
               */
              E1804902: string;
            }[];
            /**
             * Summe
             * @maxLength: 1
             */
            Sum: {
              /**
               * Summe
               */
              E1804903: string;
            }[];
          }[];
          /**
           * Werbungskosten_zu_den_Zeilen_9,_18_und_Zeile_36
           * @maxLength: 1
           */
          Leibr_Nachz: {
            /**
             * Einzelangaben
             * @maxLength: 99
             */
            Einz: {
              /**
               * Art_der_Aufwendungen
               */
              E1805101: string;
              /**
               * Betrag
               */
              E1805102: string;
            }[];
            /**
             * Summe
             * @maxLength: 1
             */
            Sum: {
              /**
               * Summe
               */
              E1805103: string;
            }[];
          }[];
        }[];

        /**
         * Einkünfte_aus_Gesellschaften_/_Gemeinschaften_/_ähnlichen_Modellen_im_Sinne_des_§_15b_EStG_(laut_Aufstellung)
         * @maxLength: 1
         */
        Steuerstund_Mod: {
          /**
           * Einkünfte_aus_Gesellschaften_/_Gemeinschaften_/_ähnlichen_Modellen_im_Sinne_des_§_15b_EStG_(laut_Aufstellung)_-_Bezeichnung
           */
          E1805404: string;
          /**
           * Einkünfte_aus_Gesellschaften_/_Gemeinschaften_/_ähnlichen_Modellen_im_Sinne_des_§_15b_EStG_(laut_Aufstellung)_-_Betrag
           */
          E1805405: string;
        }[];
      }[];
    }[];
  }
  export interface ISA {
    /**
     * Angaben_zu_Sonderausgaben
     * -_Ohne_Versicherungsaufwendungen_und_Altersvorsorgebeiträge_-
     * @maxLength: 1
     */
    SA: {
      /**
       * Kirchensteuer
       * @maxLength: 1
       */
      KiSt: {
        /**
         * $VZ$_gezahlt
         * @maxLength: 1
         */
        Gezahlt: {
          /**
           * Einzelbeträge
           * @maxLength: 99
           */
          Einz: {
            /**
             * Art_der_Leistung
             */
            E0108003: string;
            /**
             * soweit_diese_nicht_als_Zuschlag_zur_Abgeltungsteuer_einbehalten_oder_gezahlt_wurde_-_Betrag
             */
            E0108004: string;
          }[];
          /**
           * Summe
           * @maxLength: 1
           */
          Sum: {
            /**
             * soweit_diese_nicht_als_Zuschlag_zur_Abgeltungsteuer_einbehalten_oder_gezahlt_wurde_-_Summe
             */
            E0107601: string;
          }[];
        }[];
        /**
         * $VZ$_erstattet
         * @maxLength: 1
         */
        Erstattet: {
          /**
           * soweit_diese_nicht_als_Zuschlag_zur_Abgeltungsteuer_einbehalten_oder_gezahlt_wurde
           */
          E0107602: string;
        }[];
      }[];
      /**
       * Zuwendungen_(Spenden_und_Mitgliedsbeiträge)
       * @maxLength: 1
       */
      Zuw: {
        /**
         * Spenden_und_Mitgliedsbeiträge_(ohne_Spenden_in_das_zu_erhaltende_Vermögen_einer_Stiftung)
         * @maxLength: 1
         */
        Sp_MB: {
          /**
           * zur_Förderung_steuerbegünstigter_Zwecke_an_Empfänger_im_Inland
           * @maxLength: 1
           */
          Foerd_st_beg_Zw_Inl: {
            /**
             * Einzelangaben
             * @maxLength: 99
             */
            Einz: {
              /**
               * Beschreibung
               */
              E0108102: string;
              /**
               * Betrag_laut_Bestätigungen
               */
              E0108103: string;
              /**
               * Betrag_laut_Betriebsfinanzamt
               */
              E0108104: string;
            }[];
            /**
             * Summen
             * @maxLength: 1
             */
            Sum: {
              /**
               * Summe_Beträge_laut_Bestätigungen
               */
              E0108105: string;
              /**
               * Summe_Beträge_laut_Betriebsfinanzamt
               */
              E0108106: string;
            }[];
          }[];
          /**
           * zur_Förderung_steuerbegünstigter_Zwecke_an_Empfänger_im_EU-_/_EWR-Ausland
           * @maxLength: 1
           */
          Foerd_st_beg_Zw_EU_EWR: {
            /**
             * Einzelangaben
             * @maxLength: 99
             */
            Einz: {
              /**
               * Beschreibung
               */
              E0104704: string;
              /**
               * Betrag_laut_Bestätigungen
               */
              E0104705: string;
              /**
               * Betrag_laut_Betriebsfinanzamt
               */
              E0104708: string;
            }[];
            /**
             * Summen
             * @maxLength: 1
             */
            Sum: {
              /**
               * Summe_Beträge_laut_Bestätigungen
               */
              E0104702: string;
              /**
               * Summe_Beträge_laut_Betriebsfinanzamt
               */
              E0104703: string;
            }[];
          }[];
          /**
           * an_politische_Parteien_(§§_34g,_10b_EStG)
           * @maxLength: 1
           */
          Polit_P: {
            /**
             * Einzelangaben
             * @maxLength: 99
             */
            Einz: {
              /**
               * Beschreibung
               */
              E0108716: string;
              /**
               * Betrag_laut_Bestätigungen
               */
              E0108717: string;
              /**
               * Betrag_laut_Betriebsfinanzamt
               */
              E0108718: string;
            }[];
            /**
             * Summen
             * @maxLength: 1
             */
            Sum: {
              /**
               * Summe_Beträge_laut_Bestätigungen
               */
              E0108701: string;
              /**
               * Summe_Beträge_laut_Betriebsfinanzamt
               */
              E0108702: string;
            }[];
          }[];
          /**
           * an_unabhängige_Wählervereinigungen_(§_34g_EStG)
           * @maxLength: 1
           */
          Unabh_Waehl_V: {
            /**
             * Einzelangaben
             * @maxLength: 99
             */
            Einz: {
              /**
               * Beschreibung
               */
              E0108804: string;
              /**
               * Betrag_laut_Bestätigungen
               */
              E0108805: string;
              /**
               * Betrag_laut_Betriebsfinanzamt
               */
              E0108806: string;
            }[];
            /**
             * Summen
             * @maxLength: 1
             */
            Sum: {
              /**
               * Summe_Beträge_laut_Bestätigungen
               */
              E0108801: string;
              /**
               * Summe_Beträge_laut_Betriebsfinanzamt
               */
              E0108802: string;
            }[];
          }[];
        }[];
        /**
         * Spenden_in_das_zu_erhaltende_Vermögen_(Vermögensstock)_einer_Stiftung:_$Person.Wert$
         * @maxLength: 2
         */
        Sp_erh_Verm_Stift: {
          Person: ElsterPerson;
          /**
           * $VZ$_geleistete_Spenden_an_Empfänger_im_Inland_(laut_Bestätigungen_/_laut_Betriebsfinanzamt)
           */
          E0108405: string;
          /**
           * $VZ$_geleistete_Spenden_(laut_Bestätigungen_/_laut_Betriebsfinanzamt)_an_Empfänger_im_EU-_/_EWR-Ausland
           */
          E0105502: string;
          /**
           * Von_den_Spenden_in_Zeile_9_und_10_sollen_$VZ$_berücksichtigt_werden
           */
          E0108509: string;
          /**
           * $VZ$_zu_berücksichtigende_Spenden_aus_Vorjahren_in_das_zu_erhaltende_Vermögen_(Vermögensstock)_einer_Stiftung,_die_bisher_noch_nicht_berücksichtigt_wurden
           */
          E0108607: string;
        }[];
        /**
         * Berechnung_des_Spendenhöchstbetrags
         * @maxLength: 1
         */
        Ber_Sp_HB: {
          /**
           * Summe_der_Umsätze,_Löhne_und_Gehälter_zur_Ermittlung_des_Spendenhöchstbetrages
           */
          E0105902: string;
        }[];
      }[];
      /**
       * Berufsausbildungskosten
       * @maxLength: 1
       */
      AW_eig_BAusb: {
        /**
         * Aufwendungen_für_die_eigene_Berufsausbildung:_$Person.Wert$
         * @maxLength: 2
         */
        Pers: {
          Person: ElsterPerson;
          /**
           * Einzelangaben
           * @maxLength: 10
           */
          Einz: {
            /**
             * Bezeichnung_der_Ausbildung,_Art_der_Aufwendungen
             */
            E0108201: string;
            /**
             * Höhe_der_Aufwendungen
             */
            E0108002: string;
          }[];
          /**
           * Summe
           * @maxLength: 1
           */
          Sum: {
            /**
             * Höhe_der_Aufwendungen
             */
            E0108202: string;
          }[];
        }[];
      }[];
      /**
       * Weitere_Aufwendungen
       * @maxLength: 1
       */
      Weit_Aufw: {
        /**
         * Gezahlte_Versorgungsleistungen
         * @maxLength: 1
         */
        Gez_VersL: {
          /**
           * Renten
           * @maxLength: 1
           */
          Rent: {
            /**
             * Laut_Vertrag
             * @maxLength: 10
             */
            Vertrag: {
              /**
               * Rechtsgrund,_Datum_des_Vertrags
               */
              E0107301: string;
              /**
               * abziehbar_in_Prozent
               */
              E0107303: string;
              /**
               * tatsächlich_gezahlt
               */
              E0107302: string;
            }[];
            /**
             * Laut_gesonderter_und_einheitlicher_Feststellung
             * @maxLength: 10
             */
            Fest: {
              /**
               * Beschreibung
               */
              E0181601: string;
              /**
               * abziehbar_in_Prozent
               */
              E0181602: string;
              /**
               * tatsächlich_gezahlt
               */
              E0181603: string;
            }[];
          }[];
          /**
           * Dauernde_Lasten
           * @maxLength: 1
           */
          D_L: {
            /**
             * Laut_Vertrag
             * @maxLength: 1
             */
            Vertrag: {
              /**
               * Einzelangaben
               * @maxLength: 10
               */
              Einz: {
                /**
                 * Rechtsgrund,_Datum_des_Vertrags
                 */
                E0107401: string;
                /**
                 * Betrag
                 */
                E0107702: string;
              }[];
              /**
               * Summe
               * @maxLength: 1
               */
              Sum: {
                /**
                 * Betrag
                 */
                E0107402: string;
              }[];
            }[];
            /**
             * Laut_gesonderter_und_einheitlicher_Feststellung
             * @maxLength: 1
             */
            Fest: {
              /**
               * Einzelangaben
               * @maxLength: 10
               */
              Einz: {
                /**
                 * Beschreibung
                 */
                E0181801: string;
                /**
                 * Betrag
                 */
                E0181802: string;
              }[];
              /**
               * Summe
               * @maxLength: 1
               */
              Sum: {
                /**
                 * tatsächlich_gezahlt
                 */
                E0181803: string;
              }[];
            }[];
          }[];
        }[];

        /**
         * dauernd_getrennt_lebenden_Ehegatten_/_Lebenspartner
         * @maxLength: 1
         */
        U_Leist: {
          /**
           * Einzelangaben
           * @maxLength: 10
           */
          Einz: {
            /**
             * Identifikationsnummer_der_unterstützten_Person
             */
            E0104305: string;
            /**
             * Unterhaltsleistungen_(Einzelbetrag)
             */
            E0104408: string;
            /**
             * In_Zeile_19_enthaltene_Beiträge_(abzüglich_Erstattungen_und_Zuschüsse)_zur_Basis-Kranken-_und_gesetzlichen_Pflegeversicherung_(Einzelbetrag)
             */
            E0104503: string;
            /**
             * Davon_entfallen_auf_Krankenversicherungsbeiträge_mit_Anspruch_auf_Krankengeld_(Einzelbetrag)
             */
            E0104504: string;
          }[];
          /**
           * Summe
           * @maxLength: 1
           */
          Sum: {
            /**
             * Unterhaltsleistungen
             */
            E0107501: string;
            /**
             * In_Zeile_19_enthaltene_Beiträge_(abzüglich_Erstattungen_und_Zuschüsse)_zur_Basis-Kranken-_und_gesetzlichen_Pflegeversicherung
             */
            E0104407: string;
            /**
             * Davon_entfallen_auf_Krankenversicherungsbeiträge_mit_Anspruch_auf_Krankengeld
             */
            E0104505: string;
          }[];
        }[];
        /**
         * Ausgleichszahlungen_im_Rahmen_des_schuldrechtlichen_Versorgungsausgleichs
         * @maxLength: 1
         */
        Ausgl_Z_sch_r_VAusgl: {
          /**
           * Einzelangaben
           * @maxLength: 99
           */
          Einz: {
            /**
             * Rechtsgrund,_Datum_der_erstmaligen_Zahlung
             */
            E0104306: string;
            /**
             * Betrag
             */
            E0104307: string;
            /**
             * Name_der_empfangsberechtigten_Person
             */
            E0103904: string;
            /**
             * Identifikationsnummer_der_empfangsberechtigten_Person
             */
            E0103905: string;
          }[];
          /**
           * Summe
           * @maxLength: 1
           */
          Sum: {
            /**
             * Summe
             */
            E0104308: string;
          }[];
        }[];
        /**
         * Ausgleichsleistungen_zur_Vermeidung_des_Versorgungsausgleichs_laut_Anlage_U
         * @maxLength: 1
         */
        Ausgl_L_Verm_VAusgl: {
          /**
           * Identifikationsnummer_der_empfangsberechtigten_Person
           */
          E0182301: string;
          /**
           * Betrag
           */
          E0103903: string;
        }[];
      }[];
    }[];
  }
  export interface ISonst {
    /**
     * Sonstige_Angaben_und_Anträge
     * @maxLength: 1
     */
    Sonst: {
      /**
       * Steuerermäßigung_bei_Belastung_mit_Erbschaftsteuer
       * @maxLength: 1
       */
      Belast_ErbSt: {
        /**
         * Ich_beantrage_eine_Steuerermäßigung,_weil_in_dieser_Steuererklärung_Einkünfte_erklärt_worden_sind,_die_als_Erwerb_von_Todes_wegen_ab_$VZ-4$_der_Erbschaftsteuer_unterlegen_haben_(laut_gesonderter_Aufstellung).
         */
        E0107802: string;
        /**
         * Einkünfte_für_Ermäßigung_wegen_Erbschaftsteuer_(ohne_Einkünfte,_die_nach_§_32d_Absatz_1_EStG_besteuert_werden)
         */
        E0109104: string;
        /**
         * festgesetzte_Erbschaftsteuer
         */
        E0109105: string;
        /**
         * erbschaftsteuerpflichtiger_Erwerb_zuzüglich_Freibeträge_§§_16,_17_ErbStG_und_steuerfreie_Beträge_nach_§_5_ErbStG
         */
        E0109106: string;
        /**
         * In_den_Einkünften,_für_die_die_Ermäßigung_nach_§_35b_EStG_beantragt_wird,_sind_Einkünfte_enthalten,_die_im_Rahmen_der_Einkommensteuer_ermäßigt_besteuert_werden
         */
        E0109107: string;
        /**
         * Erläuterungen
         */
        E0109108: string;
      }[];
      /**
       * Steuerbegünstigung_für_schutzwürdige_Kulturgüter
       * @maxLength: 1
       */
      Schutz_Kulturg: {
        /**
         * Steuerbegünstigung_nach_§_10g_EStG_für_schutzwürdige_Kulturgüter,_die_weder_zur_Einkunftserzielung_noch_zu_eigenen_Wohnzwecken_genutzt_werden:_Abzugsbetrag
         */
        E0107711: string;
      }[];
      /**
       * Spendenvortrag:_$Person.Wert$
       * @maxLength: 2
       */
      Spend_Vortr: {
        Person: ElsterPerson;
        /**
         * Es_wurde_ein_verbleibender_Spendenvortrag_nach_§_10b_EStG_zum_31.12.$VZ-1$_festgestellt
         */
        E0190601: string;
      }[];
      /**
       * Verlustabzug:_$Person.Wert$
       * @maxLength: 2
       */
      Verl_Abz: {
        Person: ElsterPerson;
        /**
         * Es_wurde_ein_verbleibender_Verlustvortrag_nach_§_10d_EStG_zum_31.12.$VZ-1$_festgestellt
         */
        E0190701: string;
        /**
         * Antrag_auf_Beschränkung_des_Verlustrücktrags_nach_$VZ-1$
         * @maxLength: 1
         */
        Ant_Verl_Rueck: {
          /**
           * Von_den_nicht_ausgeglichenen_negativen_Einkünften_$VZ$_soll_folgender_Gesamtbetrag_nach_$VZ-1$_zurückgetragen_werden
           */
          E0109303: string;
        }[];

        /**
         * (nur_bei_Einkünften_aus_Einkunftsquellen_im_Sinne_des_§_2b_EStG,_die_nach_dem_04._März_1999_und_vor_dem_11._November_2005_rechtswirksam_erworben_oder_begründet_wurden)
         * @maxLength: 5
         */
        Neg_Eink: {
          /**
           * Objekt,_Finanzamt,_Steuernummer
           */
          E0109402: string;
          /**
           * Betrag
           */
          E0109403: string;
        }[];
      }[];
      /**
       * Negative_Einkünfte_mit_Bezug_zu_Drittstaaten:_$Person.Wert$:
       * @maxLength: 2
       */
      Neg_Ek_Drittst: {
        Person: ElsterPerson;
        /**
         * Es_wurden_verbleibende_negative_Einkünfte_nach_§_2a_Absatz_1_Satz_5_EStG_zum_31.12.$VZ-1$_festgestellt
         */
        E0190903: string;
      }[];
      /**
       * Freibetrag_für_bestandsgeschützte_Alt-Anteile_an_Investmentfonds:_$Person.Wert$
       * @maxLength: 2
       */
      FB_Alt_Ant_InvStG: {
        Person: ElsterPerson;
        /**
         * Es_wurde_ein_verbleibender_Freibetrag_für_bestandsgeschützte_Alt-Anteile_an_Investmentfonds_nach_§_56_Absatz_6_Satz_2_InvStG_zum_31.12.$VZ-1$_festgestellt
         */
        E0190901: string;
      }[];
      /**
       * Antrag_zur_Aufteilung_der_Abzugsbeträge_bei_Einzelveranlagung_von_Ehegatten_/_Lebenspartnern
       * @maxLength: 1
       */
      EVEG: {
        /**
         * Laut_übereinstimmendem_Antrag_sind_die_Sonderausgaben,_außergewöhnlichen_Belastungen,_die_Steuerermäßigung__für_haushaltsnahe_Beschäftigungsverhältnisse,_Dienstleistungen_und_Handwerkerleistungen,_sowie_die_Steuerermäßigung_für_energetische_Maßnahmen_bei_zu_eigenen_Wohnzwecken_genutzten_Gebäuden_je_zur_Hälfte_aufzuteilen.
         */
        E0109504: string;
      }[];
    }[];
  }
  export interface ISO {
    /**
     * Sonstige_Einkünfte_(ohne_Renten_und_ohne_Leistungen_aus_Altersvorsorgeverträgen)
     * @maxLength: 1
     */
    SO: {
      /**
       * Wiederkehrende_Bezüge:_$Person.Wert$
       * @maxLength: 2
       */
      Wied_Bez: {
        Person: ElsterPerson;
        /**
         * Bezeichnung_der_Einnahmen_aus_wiederkehrenden_Bezügen
         */
        E0304501: string;
        /**
         * Einnahmen_aus_wiederkehrenden_Bezügen
         */
        E0304502: string;
      }[];
      /**
       * Ausgleichsleistungen_zur_Vermeidung_des_Versorgungsausgleichs:_$Person.Wert$
       * @maxLength: 2
       */
      Ausg_L_Verm_Vausg: {
        Person: ElsterPerson;
        /**
         * Ausgleichsleistungen_zur_Vermeidung_des_Versorgungsausgleichs,_soweit_sie_vom_Geber_als_Sonderausgaben_abgezogen_werden_können
         */
        E0300515: string;
      }[];
      /**
       * Unterhaltsleistungen:_$Person.Wert$
       * @maxLength: 2
       */
      Unt_Leist: {
        Person: ElsterPerson;
        /**
         * Unterhaltsleistungen,_soweit_sie_vom_Geber_als_Sonderausgaben_abgezogen_werden_können
         */
        E0304601: string;
      }[];
      /**
       * Werbungskosten:_$Person.Wert$
       * @maxLength: 2
       */
      Wk: {
        Person: ElsterPerson;
        /**
         * Werbungskosten_zu_den_wiederkehrenden_Bezügen,_Ausgleichsleistungen_zur_Vermeidung_des_Versorgungsausgleichs_und_Unterhaltsleistungen_zu_den_Zeilen_4_bis_6
         */
        E0304701: string;
      }[];
      /**
       * Andere_wiederkehrende_Bezüge_/_Unterhaltsleistungen_(Teileinkünfteverfahren):_$Person.Wert$
       * @maxLength: 2
       */
      And_Wied_Bez: {
        Person: ElsterPerson;
        /**
         * Bezüge_im_Sinne_des_§_22_Nummer_1_Satz_2_EStG
         */
        E0301906: string;
        /**
         * Werbungskosten_zu_den_Bezügen_im_Sinne_des_§_22_Nummer_1_Satz_2_EStG
         */
        E0301916: string;
      }[];
      /**
       * Leistungen:_$Person.Wert$
       * @maxLength: 2
       */
      Leist: {
        Person: ElsterPerson;
        /**
         * Einzelangaben
         * @maxLength: 15
         */
        Einz: {
          /**
           * Einnahmen_aus:
           */
          E0305103: string;
          /**
           * Betrag_Einnahmen
           */
          E0305104: string;
        }[];
        /**
         * Einkünfte
         * @maxLength: 1
         */
        Sum: {
          /**
           * Summe_der_Einnahmen_laut_Zeile_10
           */
          E0305101: string;
          /**
           * Werbungskosten_zu_den_Einnahmen_laut_Zeile_10
           */
          E0305201: string;
          /**
           * Einkünfte
           */
          E0305301: string;
        }[];
        /**
         * Verlustabzug
         * @maxLength: 1
         */
        Verl_Abz: {
          /**
           * Die_$VZ-1$_nach_Maßgabe_des_§_10d_Absatz_1_EStG_vorzunehmende_Verrechnung_nicht_ausgeglichener_negativer_Einkünfte_$VZ$_aus_Leistungen_(Zeile_14)_soll_wie_folgt_begrenzt_werden
           */
          E0305401: string;
        }[];
      }[];
      /**
       * Abgeordnetenbezüge:_$Person.Wert$
       * @maxLength: 2
       */
      Abgeo_Bez: {
        Person: ElsterPerson;

        /**
         * 2._Bezug
         * @maxLength: 2
         */
        Bezug: {
          /**
           * Steuerpflichtige_Einnahmen_ohne_Vergütung_für_mehrere_Jahre
           */
          E0305501: string;
          /**
           * In_Zeile_16_enthaltene_Versorgungsbezüge
           */
          E0305601: string;
          /**
           * Bemessungsgrundlage_für_den_Versorgungsfreibetrag
           */
          E0311801: string;
          /**
           * Maßgebendes_Kalenderjahr_des_Versorgungsbeginns
           */
          E0311701: string;
          /**
           * Bei_unterjähriger_Zahlung:_erster_Monat,_für_den_Versorgungsbezüge_gezahlt_wurden
           */
          E0311901: string;
          /**
           * Bei_unterjähriger_Zahlung:_letzter_Monat,_für_den_Versorgungsbezüge_gezahlt_wurden
           */
          E0312101: string;
          /**
           * Sterbegeld,_Kapitalauszahlungen_/_Abfindungen_und_Nachzahlungen_von_Versorgungsbezügen_(in_Zeile_16_enthalten)
           */
          E0312001: string;
          /**
           * In_Zeile_16_nicht_enthaltene_Vergütungen_für_mehrere_Jahre_(Aufstellung)
           */
          E0312005: string;
          /**
           * In_Zeile_16_nicht_enthaltene_Vergütungen_für_mehrere_Jahre_(Angaben_laut_Aufstellung)_(Betrag)
           */
          E0312201: string;
          /**
           * In_Zeile_22_enthaltene_Versorgungsbezüge
           */
          E0312301: string;
          /**
           * Aufgrund_der_vorgenannten_Tätigkeit_als_Abgeordnete(r)_bestand_eine_Anwartschaft_auf_Altersversorgung_ganz_oder_teilweise_ohne_eigene_Beitragsleistung)
           */
          E0312207: string;
          /**
           * Werbungskosten
           */
          E0312105: string;
        }[];
      }[];
      /**
       * Steuerstundungsmodelle:_$Person.Wert$
       * @maxLength: 2
       */
      Steu_St_Mod: {
        Person: ElsterPerson;
        /**
         * Bezeichnung_der_Einkünfte_aus_Gesellschaften_/_Gemeinschaften_/_ähnlichen_Modellen_im_Sinne_des_§_15b_EStG
         */
        E0312305: string;
        /**
         * Einkünfte_aus_Gesellschaften_/_Gemeinschaften_/_ähnlichen_Modellen_im_Sinne_des_§_15b_EStG_(laut_Aufstellung)
         */
        E0312205: string;
      }[];
      /**
       * Private_Veräußerungsgeschäfte
       * @maxLength: 1
       */
      Priv_VA_G: {
        /**
         * Grundstücke_und_grundstücksgleiche_Rechte_(zum_Beispiel_Erbbaurecht):_$Person.Wert$
         * @maxLength: 2
         */
        Grdst: {
          Person: ElsterPerson;
          /**
           * $index$._Angabe
           * @maxLength: 99
           */
          Einz: {
            /**
             * Bezeichnung_des_Grundstücks_(Lage)_/_des_Rechts
             */
            E0306111: string;
            /**
             * Zeitpunkt_der_Anschaffung_(zum_Beispiel_Datum_des_Kaufvertrags,_Zeitpunkt_der_Entnahme_aus_dem_Betriebsvermögen)
             */
            E0306201: string;
            /**
             * Zeitpunkt_der_Veräußerung_(zum_Beispiel_Datum_des_Kaufvertrags,_auch_nach_vorheriger_Einlage_ins_Betriebsvermögen)
             */
            E0306202: string;
            /**
             * Nutzung_des_Grundstücks_bis_zur_Veräußerung_zu_eigenen_Wohnzwecken
             */
            E0306301: string;
            /**
             * Nutzung_zu_eigenen_Wohnzwecken_von_-_bis
             */
            E0306302: string;
            /**
             * Umfang_der_Nutzung_zu_eigenen_Wohnzwecken_in_m²
             */
            E0306303: string;
            /**
             * Nutzung_des_Grundstücks_bis_zur_Veräußerung_zu_anderen_Zwecken_(zum_Beispiel_als_Arbeitszimmer,_Vermietung)
             */
            E0306304: string;
            /**
             * Nutzung_zu_anderen_Zwecken_von_-_bis
             */
            E0306305: string;
            /**
             * Umfang_der_Nutzung_zu_anderen_Zwecken_in_m²
             */
            E0306306: string;
            /**
             * Veräußerungspreis_oder_an_dessen_Stelle_tretender_Wert_(zum_Beispiel_Teilwert,_gemeiner_Wert)
             */
            E0306401: string;
            /**
             * Anschaffungs-_/_Herstellungskosten_oder_an_deren_Stelle_tretender_Wert_(zum_Beispiel_Teilwert,_gemeiner_Wert)_gegebenenfalls_zuzüglich_nachträglicher_Anschaffungs-_/_Herstellungskosten
             */
            E0306501: string;
            /**
             * Absetzungen_für_Abnutzung_/_Erhöhte_Absetzungen_/_Sonderabschreibungen
             */
            E0306601: string;
            /**
             * Werbungskosten_im_Zusammenhang_mit_dem_Veräußerungsgeschäft
             */
            E0306701: string;
            /**
             * Gewinn_/_Verlust
             */
            E0306801: string;
          }[];
        }[];
        /**
         * Andere_Wirtschaftsgüter_(Veräußerungen_von_Gegenständen_des_täglichen_Gebrauchs_sind_ausgenommen):_$Person.Wert$
         * @maxLength: 2
         */
        And_WG: {
          Person: ElsterPerson;
          /**
           * $index$._Angabe
           * @maxLength: 99
           */
          Einz: {
            /**
             * Art_des_Wirtschaftsguts
             */
            E0307101: string;
            /**
             * Zeitpunkt_der_Anschaffung_(zum_Beispiel_Datum_des_Kaufvertrags)
             */
            E0307201: string;
            /**
             * Zeitpunkt_der_Veräußerung_(zum_Beispiel_Datum_des_Kaufvertrags)
             */
            E0307202: string;
            /**
             * Veräußerungspreis_oder_an_dessen_Stelle_tretender_Wert_(zum_Beispiel_gemeiner_Wert)
             */
            E0307401: string;
            /**
             * Anschaffungskosten_(gegebenenfalls_gemindert_um_Absetzung_für_Abnutzung)_oder_an_deren_Stelle_tretender_Wert_(zum_Beispiel_Teilwert,_gemeiner_Wert)
             */
            E0307501: string;
            /**
             * Werbungskosten_im_Zusammenhang_mit_dem_Veräußerungsgeschäft
             */
            E0307601: string;
            /**
             * Gewinn_/_Verlust
             */
            E0307701: string;
          }[];
        }[];
        /**
         * Anteile_an_Einkünften_(einheitlich_und_gesondert_festgestellt):_$Person.Wert$
         * @maxLength: 2
         */
        Ant_Ek: {
          Person: ElsterPerson;
          /**
           * Einzelangaben
           * @maxLength: 99
           */
          Einz: {
            /**
             * Gemeinschaft,_Finanzamt_und_Steuernummer
             */
            E0318801: string;
            /**
             * Anteil_am_Gewinn_/_Verlust
             */
            E0318802: string;
          }[];
          /**
           * Summe_der_Anteile_am_Gewinn_/_Verlust
           * @maxLength: 1
           */
          Sum: {
            /**
             * Summe
             */
            E0318902: string;
          }[];
        }[];
        /**
         * Begrenzung_des_Verlustrücktrags:_$Person.Wert$
         * @maxLength: 2
         */
        Begr_V_Rue: {
          Person: ElsterPerson;
          /**
           * Die_$VZ-1$_nach_Maßgabe_des_§_10d_Absatz_1_EStG_vorzunehmende_Verrechnung_nicht_ausgeglichener_negativer_Einkünfte_$VZ$_aus_privaten_Veräußerungsgeschäften_soll_wie_folgt_begrenzt_werden
           */
          E0318906: string;
        }[];
      }[];
      /**
       * Begünstigte_Einkünfte:_$Person.Wert$
       * @maxLength: 2
       */
      Beg_Ek: {
        Person: ElsterPerson;
        /**
         * In_den_sonstigen_Einkünften_laut_Anlage_SO_enthaltene_begünstigte_Einkünfte_nach_§_34_Absatz_2_EStG_in_Verbindung_mit_§_24_Nummer_1_und_3_EStG
         * @maxLength: 1
         */
        P34_Abs2_P24_Nr_1_3: {
          /**
           * Bezeichnung
           */
          E0315201: string;
          /**
           * Betrag
           */
          E0315202: string;
        }[];
      }[];
    }[];
  }
  export interface IS {
    /**
     * Einkünfte_aus_selbständiger_Arbeit
     * @maxLength: 2
     */
    S: {
      Person: ElsterPerson;
      /**
       * Gewinn_(ohne_die_Beträge_in_den_Zeilen_31,_36_und_42;_bei_ausländischen_Einkünften:_Anlage_AUS_beachten)
       * @maxLength: 1
       */
      Gewinn: {
        /**
         * Gewinn_aus_einer_weiteren_freiberuflichen_Tätigkeit
         * @maxLength: 2
         */
        Freiber_T: {
          /**
           * genaue_Berufsbezeichnung_oder_Tätigkeit
           */
          E0803101: string;
          /**
           * Betrag
           */
          E0803202: string;
        }[];
        /**
         * Gewinn_laut_gesonderter_Feststellung
         * @maxLength: 1
         */
        Ges_Fest: {
          /**
           * Einzelangaben
           * @maxLength: 99
           */
          Einz: {
            /**
             * Finanzamt_und_Steuernummer
             */
            E0803301: string;
            /**
             * Betrag
             */
            E0800505: string;
          }[];
          /**
           * Summe
           * @maxLength: 1
           */
          Sum: {
            /**
             * Betrag
             */
            E0803402: string;
          }[];
        }[];
        /**
         * Gewinn_aus_1._Beteiligung
         * @maxLength: 1
         */
        Beteil_1: {
          /**
           * Gesellschaft,_Finanzamt_und_Steuernummer
           */
          E0803501: string;
          /**
           * Betrag
           */
          E0803602: string;
        }[];
        /**
         * Gewinn_aus_allen_weiteren_Beteiligungen
         * @maxLength: 1
         */
        Weit_Beteil: {
          /**
           * Einzelangaben
           * @maxLength: 99
           */
          Einz: {
            /**
             * Gesellschaft,_Finanzamt,_Steuernummer
             */
            E0803701: string;
            /**
             * Betrag
             */
            E0803804: string;
          }[];
          /**
           * Summe
           * @maxLength: 1
           */
          Sum: {
            /**
             * Betrag
             */
            E0803802: string;
          }[];
        }[];
        /**
         * Gewinn_aus_Gesellschaften_/_Gemeinschaften_/_ähnlichen_Modellen_im_Sinne_des_§_15b_EStG
         * @maxLength: 1
         */
        Ges_Gem_P15b: {
          /**
           * Einzelangaben
           * @maxLength: 99
           */
          Einz: {
            /**
             * Genaue_Bezeichnung
             */
            E0803905: string;
            /**
             * Betrag
             */
            E0803908: string;
          }[];
          /**
           * Summe
           * @maxLength: 1
           */
          Sum: {
            /**
             * Betrag
             */
            E0803906: string;
          }[];
        }[];
        /**
         * Gewinn_aus_sonstiger_selbständiger_Arbeit_(zum_Beispiel_als_Aufsichtsratsmitglied)
         * @maxLength: 1
         */
        So_selb_A: {
          /**
           * Einzelangaben
           * @maxLength: 99
           */
          Einz: {
            /**
             * Genaue_Bezeichnung
             */
            E0803901: string;
            /**
             * Betrag
             */
            E0803902: string;
          }[];
          /**
           * Summe
           * @maxLength: 1
           */
          Sum: {
            /**
             * Betrag
             */
            E0804002: string;
          }[];
        }[];
        /**
         * Gewinn_aus_allen_weiteren_Tätigkeiten
         * @maxLength: 1
         */
        Weit_Taet: {
          /**
           * Einzelangaben
           * @maxLength: 99
           */
          Einz: {
            /**
             * Genaue_Bezeichnung
             */
            E0804101: string;
            /**
             * Betrag
             */
            E0804204: string;
          }[];
          /**
           * Summe
           * @maxLength: 1
           */
          Sum: {
            /**
             * Betrag
             */
            E0804202: string;
          }[];
        }[];
        /**
         * In_den_Zeilen_4_bis_8,_10_und_11_nicht_enthaltener_steuerfreier_Teil_der_Einkünfte,_für_die_das_Teileinkünfteverfahren_gilt
         * @maxLength: 1
         */
        TEV_stfr_T: {
          /**
           * Betrag
           */
          E0804401: string;
        }[];
        /**
         * In_den_Zeilen_4_bis_8,_10_und_11_enthaltene_positive_Einkünfte_im_Sinne_des_§_2_Absatz_4_UmwStG
         * @maxLength: 1
         */
        P_Ek_P2_Abs_4_UmwStG: {
          /**
           * Betrag
           */
          E0821301: string;
        }[];
        /**
         * Steuerpflichtiger_Teil_der_Leistungsvergütungen_als_Beteiligter_einer_Wagniskapitalgesellschaft,_die_vor_dem_01.01.2009_gegründet_wurde_(§_18_Absatz_1_Nummer_4_EStG)
         * @maxLength: 1
         */
        Stpfl_LVerg_WagnKapG_bis_2008: {
          /**
           * Einzelangaben
           * @maxLength: 99
           */
          Einz: {
            /**
             * Gesellschaft,_Finanzamt_und_Steuernummer
             */
            E0804301: string;
            /**
             * Einzelbetrag
             */
            E0801201: string;
          }[];
          /**
           * Summe
           * @maxLength: 1
           */
          Sum: {
            /**
             * Betrag
             */
            E0804403: string;
          }[];
        }[];
        /**
         * Steuerpflichtiger_Teil_der_Leistungsvergütungen_als_Beteiligter_einer_Wagniskapitalgesellschaft,_die_nach_dem_31.12.2008_gegründet_wurde_(§_18_Absatz_1_Nummer_4_EStG)
         * @maxLength: 1
         */
        Stpfl_Lverg_WagnKapG_ab_2009: {
          /**
           * Einzelangaben
           * @maxLength: 99
           */
          Einz: {
            /**
             * Gesellschaft,_Finanzamt_und_Steuernummer
             */
            E0801304: string;
            /**
             * Einzelbetrag
             */
            E0801305: string;
          }[];
          /**
           * Summe
           * @maxLength: 1
           */
          Sum: {
            /**
             * Betrag
             */
            E0801306: string;
          }[];
        }[];
        /**
         * Ich_beantrage_für_den_in_den_Zeilen_4_bis_8_und_36_enthaltenen_Gewinn_die_Begünstigung_nach_§_34a_EStG_und_/_oder_es_wurde_zum_31.12.$VZ-1$_ein_nachversteuerungspflichtiger_Betrag_festgestellt.
         * @maxLength: 1
         */
        Gewinn: {
          /**
           * Einzureichende_Anlage(n)_34a_(Anzahl)
           */
          E0801303: string;
        }[];
      }[];
      /**
       * Veräußerungsgewinn
       * @maxLength: 1
       */
      VAe_Gew: {
        /**
         * vor_Abzug_etwaiger_Freibeträge_bei_Veräußerung_/_Aufgabe_eines_ganzen_Betriebs,_eines_Teilbetriebs_oder_eines_ganzen_Mitunternehmeranteils_(§_16_EStG)
         * @maxLength: 1
         */
        Vor_FB: {
          /**
           * Veräußerungsgewinn,_für_den_der_Freibetrag_nach_§_16_Absatz_4_EStG_wegen_dauernder_Berufsunfähigkeit_oder_Vollendung_des_55._Lebensjahres_beantragt_wird._Für_nach_dem_31.12.1995_erfolgte_Veräußerungen_/_Aufgaben_wurde_der_Freibetrag_nach_§_16_Absatz_4_EStG_bei_keiner_Einkunftsart_in_Anspruch_genommen.
           */
          E0804501: string;
          /**
           * In_Zeile_31_enthaltener_steuerpflichtiger_Teil,_für_den_das_Teileinkünfteverfahren_gilt
           */
          E0804903: string;
          /**
           * Auf_den_Veräußerungsgewinn_laut_Zeile_31_wurde_zumindest_teilweise_§_6b_oder_§_6b_in_Verbindung_mit_§_6c_EStG_angewendet._Die_Übertragungen_von_aufgedeckten_stillen_Reserven_und_/_oder_die_in_Anspruch_genommenen_Rücklagen_nach_§_6b_Absatz_1_bis_9_gegebenenfalls_in_Verbindung_mit_§_6c_EStG_betragen
           */
          E0823302: string;
          /**
           * Auf_den_Veräußerungsgewinn_laut_Zeile_31_wurde_zumindest_teilweise_§_6b_oder_§_6b_in_Verbindung_mit_§_6c_EStG_angewendet._Die_Übertragungen_von_aufgedeckten_stillen_Reserven_und_/_oder_die_in_Anspruch_genommenen_Rücklagen_nach_§_6b_Absatz_10_gegebenenfalls_in_Verbindung_mit_§_6c_EStG_betragen
           */
          E0823401: string;
          /**
           * Veräußerungsgewinn_laut_Zeile_31,_für_den_der_ermäßigte_Steuersatz_des_§_34_Absatz_3_EStG_wegen_dauernder_Berufsunfähigkeit_oder_Vollendung_des_55._Lebensjahres_beantragt_wird._Für_nach_dem_31.12.2000_erfolgte_Veräußerungen_/_Aufgaben_wurde_der_ermäßigte_Steuersatz_nach_§_34_Absatz_3_EStG_bei_keiner_Einkunftsart_in_Anspruch_genommen.
           */
          E0805003: string;
          /**
           * Veräußerungsgewinn(e),_für_den_/_die_der_Freibetrag_nach_§_16_Absatz_4_EStG_nicht_beantragt_wird_oder_nicht_zu_gewähren_ist.
           */
          E0804601: string;
          /**
           * In_Zeile_36_enthaltener_steuerpflichtiger_Teil,_für_den_das_Teileinkünfteverfahren_gilt
           */
          E0805203: string;
          /**
           * Auf_den_/_die_Veräußerungsgewinn(e)_laut_Zeile_36_wurde_zumindest_teilweise_§_6b_Absatz_1_bis_9_gegebenenfalls_in_Verbindung_mit_§_6c_EStG_angewendet_(1_=_Ja,_für_die_/_alle_Veräußerung(en),_2_=_Ja,_aber_nicht_für_alle_Veräußerungen)
           */
          E0823701: string;
          /**
           * Auf_den_/_die_Veräußerungsgewinn(e)_laut_Zeile_36_wurde_zumindest_teilweise_§_6b_Absatz_10_gegebenenfalls_in_Verbindung_mit_§_6c_EStG_angewendet
           */
          E0823901: string;
          /**
           * In_Zeile_36_enthaltener_Veräußerungsgewinn,_für_den_der_ermäßigte_Steuersatz_des_§_34_Absatz_3_EStG_wegen_dauernder_Berufsunfähigkeit_oder_Vollendung_des_55._Lebensjahres_beantragt_wird._Für_nach_dem_31.12.2000_erfolgte_Veräußerungen_/_Aufgaben_wurde_der_ermäßigte_Steuersatz_des_§_34_Absatz_3_EStG_bei_keiner_Einkunftsart_in_Anspruch_genommen.
           */
          E0805305: string;
          /**
           * In_Zeile_40_enthaltener_steuerpflichtiger_Teil,_für_den_das_Teileinkünfteverfahren_gilt
           */
          E0805403: string;
          /**
           * Veräußerungsverlust_nach_§_16_EStG
           */
          E0822201: string;
          /**
           * In_Zeile_42_enthaltener_steuerpflichtiger_Teil,_für_den_das_Teileinkünfteverfahren_gilt
           */
          E0822301: string;
          /**
           * Zu_den_Zeilen_31_bis_41:
           * @maxLength: 1
           */
          VAeGew_o_VAeVerl: {
            /**
             * Erwerber_ist_eine_Gesellschaft,_an_der_die_veräußernde_Person_oder_ein_Angehöriger_beteiligt_ist_(laut_Aufstellung).
             */
            E0804701: string;
            /**
             * Die_Betriebsaufgabe_erstreckt_sich_über_mehr_als_ein_Kalenderjahr.
             */
            E0802209: string;
            /**
             * Erläuterungen_zur_Beteiligung_der_veräußernden_Person_oder_eines_Angehörigen_an_der_erwerbenden_Gesellschaft
             */
            E0802206: string;
          }[];
        }[];
      }[];
      /**
       * Sonstiges
       * @maxLength: 1
       */
      Sonst: {
        /**
         * In_den_Zeilen_4_bis_11_enthaltene_begünstigte_sonstige_Gewinne_im_Sinne_des_§_34_Absatz_2_Nummer_2_bis_4_EStG
         */
        E0804901: string;
        /**
         * Einnahmen_aus_nebenberuflicher_Tätigkeit
         * @maxLength: 99
         */
        Einn_neb_berufl_Taet: {
          /**
           * aus_der_Tätigkeit_als
           */
          E0805301: string;
          /**
           * Gesamtbetrag
           */
          E0805302: string;
          /**
           * davon_als_steuerfrei_behandelt
           */
          E0805303: string;
          /**
           * Rest_enthalten_in_Zeile(n)
           */
          E0805304: string;
        }[];
      }[];
    }[];
  }
  export interface IU {
    /**
     * Angaben_zu_Unterhaltsleistungen_an_bedürftige_Personen
     * @maxLength: 99
     */
    ESt1A_U: {
      /**
       * Haushalt,_in_dem_die_unterstützte(n)_Person(en)_lebte(n)
       * @maxLength: 1
       */
      HH_unt_P: {
        /**
         * Anschrift_dieses_Haushaltes
         */
        E0120101: string;
        /**
         * Wohnsitzstaat,_wenn_Ausland
         */
        E0120102: string;
        /**
         * Anzahl_der_Personen,_die_in_diesem_Haushalt_lebten
         */
        E0120108: string;
      }[];
      /**
       * Aufwendungen_für_den_Unterhalt
       * @maxLength: 1
       */
      AW_U: {
        /**
         * Unterhaltszeitraum
         * @maxLength: 2
         */
        U_Ztr: {
          /**
           * Unterstützungszeitraum,_für_den_Unterhalt_geleistet_wurde
           */
          E0120109: string;
        }[];
        /**
         * Unterhaltszahlungen
         * @maxLength: 12
         */
        U_Zlg: {
          /**
           * Höhe_der_Unterhaltszahlung_(ohne_bei_Familienheimfahrten_mitgenommene_Beträge)
           */
          E0120103: string;
          /**
           * Zeitraum
           */
          E0120104: string;
          /**
           * Beiträge_Basis-Kranken-_und_Pflegeversicherung:_$Unterstuetzte_Person.Wert$
           * @maxLength: 9
           */
          B_KV_PV_U: {
            Unterstuetzte_Person: UnterstuetztePerson;
            /**
             * In_den_Unterhaltsaufwendungen_enthaltene_übernommene_Beiträge_zu_Basis-Kranken-_und_gesetzlichen_Pflegeversicherungen_für_die_unterstützte_Person,_die_von_der_unterstützten_Person_als_Versicherungsnehmer_geschuldet_und_von_mir_getragen_wurden.
             */
            E0121113: string;
            /**
             * In_den_Beiträgen_zu_Basis-Kranken-_und_gesetzlichen_Pflegeversicherungen_für_die_unterstützte_Person_enthaltene_Beiträge,_aus_denen_sich_ein_Anspruch_auf_Krankengeld_ergibt.
             */
            E0121114: string;
          }[];
        }[];
        /**
         * Familienheimfahrten_($#/ESt1A_U/AW_U/Fam_H_Fahr$._Eintrag)
         * @maxLength: 12
         */
        Fam_H_Fahr: {
          /**
           * Einreisedatum
           */
          E0120110: string;
          /**
           * Übergabedatum
           */
          E0120111: string;
          /**
           * Mitgenommener_Betrag
           */
          E0120112: string;
        }[];
        /**
         * Nettomonatslohn_der_steuerpflichtigen_Person
         * @maxLength: 1
         */
        Netto_ML: {
          /**
           * Nettomonatslohn_der_unterstützenden_steuerpflichtigen_Person
           */
          E0120106: string;
        }[];
      }[];
      /**
       * Angaben_zur_unterstützten_Person:_$Unterstuetzte_Person.Wert$
       * @maxLength: 9
       */
      Ang_Unt_Pers: {
        Unterstuetzte_Person: UnterstuetztePerson;
        /**
         * Allgemeine_Angaben_zur_unterstützten_Person
         * @maxLength: 1
         */
        Allg: {
          /**
           * Angaben_zur_Person
           * @maxLength: 1
           */
          Persoenl: {
            /**
             * Identifikationsnummer
             */
            E0120211: string;
            /**
             * Name,_Vorname
             */
            E0120201: string;
            /**
             * Geburtsdatum
             */
            E0120203: string;
            /**
             * Sterbedatum,_wenn_$VZ$_verstorben
             */
            E0120205: string;
            /**
             * Beruf,_Familienstand
             */
            E0120202: string;
            /**
             * Verwandtschaftsverhältnis_zur_unterstützenden_Person
             */
            E0120701: string;
            /**
             * im_Zeitraum
             */
            E0120702: string;
            /**
             * Bei_Unterhaltsempfängern_im_Ausland:_Die_von_der_Heimatbehörde_und_der_unterstützten_Person_bestätigte_Unterhaltserklärung_über_die_Bedürftigkeit_liegt_mir_vor.
             */
            E0120209: string;
            /**
             * Bei_Unterhaltsempfängern_im_Ausland:_Die_von_der_Heimatbehörde_und_der_unterstützten_Person_bestätigte_Unterhaltserklärung_über_die_Bedürftigkeit_liegt_mir_nicht_vor.
             */
            E0120210: string;
            /**
             * Name,_Vorname_des_im_selben_Haushalt_lebenden_Ehegatten_/_Lebenspartners
             */
            E0120204: string;
            /**
             * Nicht_im_unterstützten_Haushalt_lebend_($index$._Zeitraum)
             * @maxLength: 3
             */
            N_im_u_Hh: {
              /**
               * Zeitraum,_in_dem_die_unterstützte_Person_nicht_im_unterstützten_Haushalt_lebte
               */
              E0120206: string;
            }[];
            /**
             * Im_inländischen_Haushalt
             * @maxLength: 1
             */
            Inl_Hh: {
              /**
               * Die_unterstützte_Person_lebte_während_des_ganzen_Kalenderjahres_$VZ$_in_meinem_inländischen_Haushalt.
               */
              E0120703: string;
            }[];
            /**
             * $index$._Zeitraum_im_inländischen_Haushalt
             * @maxLength: 3
             */
            Inl_Hh_Ztr: {
              /**
               * Zeitraum,_in_dem_die_unterstützte_Person_in_meinem_inländischen_Haushalt_lebte
               */
              E0120704: string;
            }[];
          }[];
          /**
           * Unterhaltsberechtigung
           * @maxLength: 1
           */
          U_Berecht: {
            /**
             * Während_des_ganzen_Kalenderjahres_$VZ$_hatte_niemand_für_die_unterstützte_Person_Anspruch_auf_Kindergeld_oder_Freibeträge_für_Kinder.
             */
            E0120401: string;
            /**
             * Während_des_Kalenderjahres_$VZ$_hatte_jemand_für_die_unterstützte_Person_Anspruch_auf_Kindergeld_oder_Freibeträge_für_Kinder.
             */
            E0120402: string;
            /**
             * Kein_Anspruch_auf_Kindergeld_oder_Freibeträge_für_Kinder_($index$._Zeitraum)
             * @maxLength: 3
             */
            Ztr_k_Ansp_KG_FB: {
              /**
               * Zeitraum,_in_dem_niemand_Anspruch_für_die_unterstützte_Person_auf_Kindergeld_oder_Freibeträge_für_Kinder_hatte
               */
              E0120403: string;
            }[];
            /**
             * Scheidung_/_Aufhebung_Lebenspartnerschaft_/_dauernd_getrennt_lebend
             * @maxLength: 1
             */
            Sch_A_LP_dgl: {
              /**
               * Die_unterstützte_Person_ist_mein_geschiedener_Ehegatte,_Lebenspartner_einer_aufgehobenen_Lebenspartnerschaft_oder_dauernd_getrennt_lebender_Ehegatte_/_Lebenspartner_(kein_Abzug_von_Sonderausgaben_nach_§_10_Absatz_1a_Nummer_1_EStG,_keine_Zusammenveranlagung).
               */
              E0120501: string;
            }[];

            /**
             * 2._Zeitraum
             * @maxLength: 2
             */
            Sch_A_LP_dgl_Ztr: {
              /**
               * im_Zeitraum
               */
              E0120502: string;
            }[];
            /**
             * Ehegatte_/_Lebenspartner_nicht_unbeschränkt_steuerpflichtig
             * @maxLength: 1
             */
            EG_LP_Ausl: {
              /**
               * Die_unterstützte_Person_ist_mein_nicht_dauernd_getrennt_lebender_und_nicht_unbeschränkt_einkommensteuerpflichtiger_Ehegatte_/_Lebenspartner.
               */
              E0120504: string;
            }[];

            /**
             * 2._Zeitraum
             * @maxLength: 2
             */
            EG_LP_Ausl_Ztr: {
              /**
               * im_Zeitraum
               */
              E0120505: string;
            }[];
            /**
             * Gesetzliche_Unterhaltsberechtigung
             * @maxLength: 1
             */
            Ges_U_Ber: {
              /**
               * Die_unterstützte_Person_ist_als_Kindesmutter_/_Kindesvater_gesetzlich_unterhaltsberechtigt_(bis_zur_Vollendung_des_dritten_Lebensjahres_des_Kindes).
               */
              E0120601: string;
            }[];

            /**
             * 2._Zeitraum
             * @maxLength: 2
             */
            Ges_U_Ber_Ztr: {
              /**
               * im_Zeitraum
               */
              E0120602: string;
            }[];
            /**
             * Kürzung_öffentlicher_Mittel
             * @maxLength: 1
             */
            Kuerz_oeff_M: {
              /**
               * Die_unterstützte_Person_ist_nicht_unterhaltsberechtigt,_jedoch_wurden_oder_würden_bei_ihr_wegen_der_Unterhaltszahlungen_öffentliche_Mittel_gekürzt_oder_nicht_gewährt.
               */
              E0120604: string;
            }[];

            /**
             * 2._Zeitraum
             * @maxLength: 2
             */
            Kuerz_oeff_M_Ztr: {
              /**
               * im_Zeitraum
               */
              E0120605: string;
            }[];
          }[];
          /**
           * Vermögen_der_unterstützten_Person
           * @maxLength: 1
           */
          Verm_u_P: {
            /**
             * Die_unterstützte_Person_hatte_während_des_gesamten_Kalenderjahres_$VZ$_kein_oder_nur_geringes_Vermögen_(je_nach_Wohnsitzstaat_maximal_15.500_EUR,_bei_Ehegatten_/_Lebenspartnern_im_gemeinsamen_Haushalt_insgesamt_maximal_31.000_EUR)
             */
            E0120301: string;
            /**
             * Gesamtwert_des_Vermögens_($index$._Zeitraum)
             * @maxLength: 3
             */
            GWV_Ztr_Betr: {
              /**
               * Zeitraum,_in_dem_das_Vermögen_der_unterstützten_Person_größer_war_als_maximal_(je_nach_Wohnsitzstaat)_15.500_EUR,_bei_Ehegatten_/_Lebenspartnern_im_gemeinsamen_Haushalt_insgesamt_über_maximal_(je_nach_Wohnsitzstaat)_31.000_EUR
               */
              E0120302: string;
              /**
               * Gesamtwert_des_Vermögens_im_Zeitraum
               */
              E0120305: string;
            }[];
          }[];
          /**
           * Weitere_zum_Unterhalt_beitragende_Personen
           * @maxLength: 1
           */
          Weit_beitr_P: {
            /**
             * Keine_weitere_Person_hat_zum_Unterhalt_der_unterstützten_Person_beigetragen.
             */
            E0120860: string;
            /**
             * $index$._zum_Unterhalt_beitragende_Person
             * @maxLength: 6
             */
            Einz: {
              /**
               * Zum_Unterhalt_der_bedürftigen_Person_haben_auch_beigetragen_(Name,_Anschrift)
               */
              E0120801: string;
              /**
               * Höhe_der_Zahlungen
               */
              E0120802: string;
              /**
               * darin_enthaltene_Beiträge_zur_Basis-Kranken-_und_gesetzlichen_Pflegeversicherung_der_unterstützten_Personen,_die_nicht_bereits_als_Vorsorgeaufwendungen_bei_der_unterstützenden_Person_abziehbar_sind.
               */
              E0120804: string;
              /**
               * In_den_Beiträgen_zur_Basis-Kranken-_und_gesetzlichen_Pflegeversicherung_enthaltene_Beiträge_aus_denen_sich_ein_Anspruch_auf_Krankengeld_ergibt.
               */
              E0120805: string;
              /**
               * Zeitraum_der_Zahlungen
               */
              E0120803: string;
            }[];
          }[];
        }[];
        /**
         * Einkünfte_und_Bezüge_der_unterstützten_Person
         * @maxLength: 1
         */
        Ek_Bez_u_P: {
          /**
           * Angaben_zu_Einkünften_und_Bezügen
           * @maxLength: 1
           */
          Allg: {
            /**
             * Keine_Einkünfte_und_Bezüge_und_keine_öffentlichen_Ausbildungshilfen_im_Kalenderjahr.
             */
            E0120901: string;
          }[];
          /**
           * Einkünfte_aus_nichtselbständiger_Arbeit:_$index$._Angabe
           * @maxLength: 12
           */
          Ek_ns_A: {
            /**
             * Bruttoarbeitslohn
             * @maxLength: 1
             */
            Br_AL: {
              /**
               * Zeitraum
               */
              E0121001: string;
              /**
               * Betrag
               */
              E0121101: string;
            }[];
            /**
             * Werbungskosten_zum_Arbeitslohn_(ohne_Werbungskosten_zu_Versorgungsbezügen)
             * @maxLength: 1
             */
            Wk: {
              /**
               * Zeitraum
               */
              E0121401: string;
              /**
               * Betrag
               */
              E0121501: string;
            }[];
            /**
             * Versorgungsbezüge_-_im_Arbeitslohn_enthalten
             * @maxLength: 1
             */
            VBez: {
              /**
               * Zeitraum
               */
              E0121201: string;
              /**
               * Betrag
               */
              E0121301: string;
              /**
               * Bemessungsgrundlage_für_den_Versorgungsfreibetrag_(Betrag)
               */
              E0125301: string;
              /**
               * Maßgebendes_Kalenderjahr_des_Versorgungsbeginns
               */
              E0125601: string;
            }[];
            /**
             * Werbungskosten_zu_Versorgungsbezügen
             * @maxLength: 1
             */
            Wk_VBez: {
              /**
               * Werbungskosten_zu_Versorgungsbezügen_(Zeitraum)
               */
              E0125401: string;
              /**
               * Werbungskosten_zu_Versorgungsbezügen_(Betrag)
               */
              E0125501: string;
            }[];
          }[];
          /**
           * Sonstige_Einkünfte:_$index$._Angabe
           * @maxLength: 4
           */
          So_Ek: {
            /**
             * Renten
             * @maxLength: 1
             */
            Rent: {
              /**
               * Zeitraum
               */
              E0122001: string;
              /**
               * Betrag
               */
              E0122101: string;
            }[];
            /**
             * Ertragsanteil
             * @maxLength: 1
             */
            ETA: {
              /**
               * steuerpflichtiger_Teil_der_Rente_(Zeitraum)
               */
              E0122201: string;
              /**
               * steuerpflichtiger_Teil_der_Rente_(Betrag)
               */
              E0122301: string;
            }[];
            /**
             * Werbungskosten
             * @maxLength: 1
             */
            Wk: {
              /**
               * Werbungskosten_zu_Renten_(Zeitraum)
               */
              E0122401: string;
              /**
               * Werbungskosten_zu_Renten_(Betrag)
               */
              E0122501: string;
            }[];
          }[];
          /**
           * Einkünfte_aus_Kapitalvermögen_(tarifliche_Einkommensteuer):_$index$._Angabe
           * @maxLength: 4
           */
          KapV_tarif: {
            /**
             * Zeitraum
             */
            E0121605: string;
            /**
             * Betrag
             */
            E0121705: string;
          }[];
          /**
           * Erträge_aus_Kapitalvermögen_(Abgeltungsteuer):_$index$._Angabe
           * @maxLength: 4
           */
          KapV_Abgelt: {
            /**
             * Zeitraum
             */
            E0121609: string;
            /**
             * Betrag
             */
            E0121709: string;
          }[];
          /**
           * Übrige_Einkünfte:_$index$._Angabe
           * @maxLength: 12
           */
          Ueb_Ek: {
            /**
             * Zeitraum
             */
            E0122601: string;
            /**
             * Betrag
             */
            E0122701: string;
          }[];
          /**
           * Sozialleistungen_/_übrige_Bezüge_(zum_Beispiel_aus_Minijobs)_$index$._Angabe
           * @maxLength: 12
           */
          Soz_L_Ueb_B: {
            /**
             * Zeitraum
             */
            E0123201: string;
            /**
             * Betrag
             */
            E0123301: string;
          }[];
          /**
           * Öffentliche_Ausbildungshilfen:_$index$._Angabe
           * @maxLength: 6
           */
          Oe_Ausb_Hi: {
            /**
             * Zeitraum
             */
            E0122801: string;
            /**
             * Betrag
             */
            E0122901: string;
          }[];
          /**
           * Kosten_zu_allen_Bezügen_$index$._Angabe
           * @maxLength: 6
           */
          Kost_alle_Bez: {
            /**
             * Zeitraum
             */
            E0123401: string;
            /**
             * Betrag
             */
            E0123501: string;
          }[];
        }[];
      }[];
    }[];
  }
  export interface IVOR {
    /**
     * Angaben_zu_Vorsorgeaufwendungen
     * @maxLength: 1
     */
    VOR: {
      /**
       * Beiträge_zur_Altersvorsorge:_$Person.Wert$
       * @maxLength: 2
       */
      AVor: {
        Person: ElsterPerson;
        /**
         * Arbeitnehmeranteil_laut_Nummer_23_a/b_der_Lohnsteuerbescheinigung
         */
        E2000401: string;
        /**
         * Beiträge_zur_landwirtschaftlichen_Alterskasse;_zu_berufsständischen_Versorgungseinrichtungen,_die_den_gesetzlichen_Rentenversicherungen_vergleichbare_Leistungen_erbringen_(abzüglich_steuerfreier_Zuschüsse_laut_Nummer_22b_der_Lohnsteuerbescheinigung)_-_ohne_Beiträge,_die_in_Zeile_4_geltend_gemacht_werden_-
         */
        E2000501: string;
        /**
         * Beiträge_zu_gesetzlichen_Rentenversicherungen_-_ohne_Beiträge,_die_in_Zeile_4_geltend_gemacht_werden_-
         */
        E2000601: string;
        /**
         * Erstattete_Beiträge_und_/_oder_steuerfreie_Zuschüsse_zu_den_Zeilen_4_bis_6_(ohne_Zuschüsse,_die_von_den_Beiträgen_laut_Zeile_8_abzuziehen_sind_und_ohne_Zuschüsse_laut_Zeilen_9_und_10)
         */
        E2000703: string;
        /**
         * Beiträge_zu_zertifizierten_Basisrentenverträgen_(sogenannte_Rürup-Verträge)_mit_Laufzeitbeginn_nach_dem_31.12.2004_(abzüglich_steuerfreier_Zuschüsse)_-_ohne_Altersvorsorgebeiträge,_die_in_der_Anlage_AV_geltend_gemacht_werden_-
         */
        E2000701: string;
        /**
         * Arbeitgeberanteil_/_-zuschuss_laut_Nummer_22_a/b_der_Lohnsteuerbescheinigung
         */
        E2000801: string;
        /**
         * Arbeitgeberanteil_zu_gesetzlichen_Rentenversicherungen_im_Rahmen_einer_pauschal_besteuerten_geringfügigen_Beschäftigung
         */
        E2000901: string;
      }[];
      /**
       * Beiträge_zur_inländischen_gesetzlichen_Kranken-_und_Pflegeversicherung:_$Person.Wert$
       * @maxLength: 2
       */
      Beitr_g_KV_PV_Inl: {
        Person: ElsterPerson;
        /**
         * Arbeitnehmerbeiträge_laut_Lohnsteuerbescheinigung
         * @maxLength: 1
         */
        AN: {
          /**
           * Arbeitnehmerbeiträge_zu_Krankenversicherungen_laut_Nummer_25_der_Lohnsteuerbescheinigung
           */
          E2001203: string;
          /**
           * In_Zeile_11_enthaltene_Beiträge,_aus_denen_sich_kein_Anspruch_auf_Krankengeld_ergibt
           */
          E2001405: string;
          /**
           * Arbeitnehmerbeiträge_zu_sozialen_Pflegeversicherungen_laut_Nummer_26_der_Lohnsteuerbescheinigung
           */
          E2001505: string;
          /**
           * Von_der_Kranken-_und_/_oder_sozialen_Pflegeversicherung_erstattete_Beiträge
           */
          E2001605: string;
          /**
           * In_Zeile_14_enthaltene_Beiträge_zur_Krankenversicherung,_aus_denen_sich_kein_Anspruch_auf_Krankengeld_ergibt,_und_zur_sozialen_Pflegeversicherung
           */
          E2001705: string;
        }[];
        /**
         * Beiträge_anderer_Personen_(zum_Beispiel_bei_Rentnern_und_freiwillig_gesetzlich_versicherten_Selbstzahlern)
         * @maxLength: 1
         */
        And_Pers: {
          /**
           * Beiträge_zu_Krankenversicherungen_-_ohne_Beiträge,_die_in_Zeile_11_geltend_gemacht_werden_-_(zum_Beispiel_bei_Rentnern,_bei_freiwillig_gesetzlich_versicherten_Selbstzahlern)
           */
          E2001805: string;
          /**
           * In_Zeile_16_enthaltene_Beiträge_zur_Krankenversicherung,_aus_denen_sich_ein_Anspruch_auf_Krankengeld_ergibt
           */
          E2002005: string;
          /**
           * Beiträge_zu_sozialen_Pflegeversicherungen_-_ohne_Beiträge,_die_in_Zeile_13_geltend_gemacht_werden_-_(zum_Beispiel_bei_Rentnern,_bei_freiwillig_gesetzlich_versicherten_Selbstzahlern)
           */
          E2002105: string;
          /**
           * Von_der_Kranken-_und_/_oder_sozialen_Pflegeversicherung_erstattete_Beiträge
           */
          E2002207: string;
          /**
           * In_Zeile_19_enthaltene_Beiträge_zur_Krankenversicherung,_aus_denen_sich_ein_Anspruch_auf_Krankengeld_ergibt
           */
          E2002302: string;
          /**
           * Zuschuss_zu_den_Beiträgen_laut_Zeile_16_und_/_oder_18_-_ohne_Beträge_laut_Zeile_37_und_39_-_(zum_Beispiel_von_der_Deutschen_Rentenversicherung)
           */
          E2002402: string;
          /**
           * Über_die_Basisabsicherung_hinausgehende_Beiträge_zu_Krankenversicherungen_(zum_Beispiel_für_Wahlleistungen,_Zusatzversicherungen)_abzüglich_erstatteter_Beiträge
           */
          E2002206: string;
        }[];
      }[];
      /**
       * Beiträge_zur_inländischen_privaten_Kranken-_und_Pflegeversicherung:_$Person.Wert$
       * @maxLength: 2
       */
      Beitr_p_KV_PV_Inl: {
        Person: ElsterPerson;
        /**
         * Beiträge_zu_privaten_Krankenversicherungen_(nur_Basisabsicherung,_keine_Wahlleistungen)
         */
        E2003104: string;
        /**
         * Beiträge_zu_Pflege-Pflichtversicherungen
         */
        E2003202: string;
        /**
         * Von_der_privaten_Kranken-_und_/_oder_Pflege-Pflichtversicherung_erstattete_Beiträge
         */
        E2003302: string;
        /**
         * Zuschuss_von_dritter_Seite_zu_den_Beiträgen_laut_Zeile_23_und_/_oder_24_(zum_Beispiel_von_der_Deutschen_Rentenversicherung)
         */
        E2003402: string;
        /**
         * Beiträge_zu_Wahlleistungen_/_Zusatzversicherungen
         * @maxLength: 1
         */
        WL_Zvers: {
          /**
           * Über_die_Basisabsicherung_hinausgehende_Beiträge_zu_Krankenversicherungen_(zum_Beispiel_für_Wahlleistungen,_Zusatzversicherungen)_und_/_oder_zu_zusätzlichen_Pflegeversicherungen_abzüglich_erstatteter_Beiträge
           */
          E2003502: string;
        }[];
      }[];
      /**
       * Beiträge_zur_ausländischen_gesetzlichen_oder_privaten_Kranken-_und_Pflegeversicherung:_$Person.Wert$
       * @maxLength: 2
       */
      Beitr_g_p_KV_PV_Ausl: {
        Person: ElsterPerson;
        /**
         * Beiträge_(abzüglich_steuerfreier_Zuschüsse_-_ohne_Beträge_laut_Zeile_37_-)_zur_Krankenversicherung,_die_mit_einer_inländischen_Krankenversicherung_vergleichbar_ist_(nur_Basisabsicherung,_keine_Wahlleistungen)
         */
        E2002502: string;
        /**
         * In_Zeile_31_enthaltene_Beiträge_zur_Krankenversicherung,_aus_denen_sich_kein_Anspruch_auf_Krankengeld_ergibt
         */
        E2002602: string;
        /**
         * Beiträge_(abzüglich_steuerfreier_Zuschüsse_-_ohne_Beträge_laut_Zeile_39_-)_zur_sozialen_Pflegeversicherung_/_Pflege-Pflichtversicherung,_die_mit_einer_inländischen_Pflegeversicherung_vergleichbar_ist
         */
        E2002703: string;
        /**
         * Von_der_Kranken-_und_/_oder_sozialen_Pflegeversicherung_/_Pflege-Pflichtversicherung_erstattete_Beiträge
         */
        E2002801: string;
        /**
         * In_Zeile_34_enthaltene_Beiträge_zur_Krankenversicherung,_aus_denen_sich_kein_Anspruch_auf_Krankengeld_ergibt,_und_zur_sozialen_Pflegeversicherung
         */
        E2002901: string;
        /**
         * Über_die_Basisabsicherung_hinausgehende_Beiträge_zu_Krankenversicherungen_und_zusätzlichen_Pflegeversicherungen_(zum_Beispiel_für_Wahlleistungen,_Zusatzversicherungen)_abzüglich_erstatteter_Beiträge
         */
        E2003603: string;
      }[];
      /**
       * Steuerfreie_Arbeitgeberzuschüsse:_$Person.Wert$
       * @maxLength: 2
       */
      Stfr_AG_Zusch: {
        Person: ElsterPerson;
        /**
         * Gesetzliche_Krankenversicherung_laut_Nummer_24_a_der_Lohnsteuerbescheinigung
         */
        E2003705: string;
        /**
         * Private_Krankenversicherung_laut_Nummer_24_b_der_Lohnsteuerbescheinigung
         */
        E2003807: string;
        /**
         * Gesetzliche_Pflegeversicherung_laut_Nummer_24_c_der_Lohnsteuerbescheinigung
         */
        E2003907: string;
      }[];
      /**
       * Als_Versicherungsnehmer_für_andere_Personen_übernommene_Kranken-_und_Pflegeversicherungsbeiträge
       * @maxLength: 10
       */
      Uebern_KV_PV_Beitr: {
        /**
         * Identifikationsnummer_der_mitversicherten_Person
         */
        E2003804: string;
        /**
         * Name,_Vorname,_Geburtsdatum_der_mitversicherten_Person
         */
        E2003904: string;
        /**
         * Beiträge_(abzüglich_steuerfreier_Zuschüsse)_zu_privaten_Krankenversicherungen_(nur_Basisabsicherung,_keine_Wahlleistungen)
         */
        E2004003: string;
        /**
         * Beiträge_(abzüglich_steuerfreier_Zuschüsse)_zu_Pflege-Pflichtversicherungen
         */
        E2004103: string;
        /**
         * Von_der_privaten_Kranken-_und_/_oder_Pflege-Pflichtversicherung_erstattete_Beiträge
         */
        E2004203: string;
        /**
         * Beiträge_(abzüglich_erstatteter_Beiträge)_zu_privaten_Kranken-_und_/_oder_Pflegeversicherungen_(ohne_Basisabsicherung,_zum_Beispiel_für_Wahlleistungen,_Zusatzversicherungen)
         */
        E2004303: string;
      }[];
      /**
       * Weitere_sonstige_Vorsorgeaufwendungen
       * @maxLength: 1
       */
      Weit_Sons_VorAW: {
        /**
         * $Person.Wert$
         * @maxLength: 2
         */
        Pers: {
          Person: ElsterPerson;
          /**
           * Arbeitnehmerbeiträge_zur_Arbeitslosenversicherung_laut_Nummer_27_der_Lohnsteuerbescheinigung
           */
          E2004403: string;
        }[];
        /**
         * Steuerpflichtige_Person_/_Ehegatten_/_Lebenspartner:_Beiträge_(abzüglich_steuerfreier_Zuschüsse_und_erstatteter_Beiträge)_zu
         * @maxLength: 1
         */
        A_B_LP: {
          /**
           * Versicherungen_gegen_Arbeitslosigkeit_-_ohne_Beiträge,_die_in_Zeile_45_geltend_gemacht_werden_-
           * @maxLength: 1
           */
          AL_Vers: {
            /**
             * Einzelangaben
             * @maxLength: 50
             */
            Einz: {
              /**
               * Bezeichnung
               */
              E2001401: string;
              /**
               * Betrag
               */
              E2001402: string;
            }[];
            /**
             * Summe
             * @maxLength: 1
             */
            Sum: {
              /**
               * Summe
               */
              E2001403: string;
            }[];
          }[];
          /**
           * freiwilligen_eigenständigen_Erwerbs-_und_Berufsunfähigkeitsversicherungen
           * @maxLength: 1
           */
          ErwU_BU_Vers: {
            /**
             * Einzelangaben
             * @maxLength: 50
             */
            Einz: {
              /**
               * Bezeichnung
               */
              E2001501: string;
              /**
               * Betrag
               */
              E2001502: string;
            }[];
            /**
             * Summe
             * @maxLength: 1
             */
            Sum: {
              /**
               * Summe
               */
              E2001503: string;
            }[];
          }[];
          /**
           * Unfall-_und_Haftpflichtversicherungen_sowie_Risikoversicherungen,_die_nur_für_den_Todesfall_eine_Leistung_vorsehen
           * @maxLength: 1
           */
          U_HP_Ris_Vers: {
            /**
             * Einzelangaben
             * @maxLength: 50
             */
            Einz: {
              /**
               * Bezeichnung
               */
              E2001801: string;
              /**
               * Betrag
               */
              E2001802: string;
            }[];
            /**
             * Summe
             * @maxLength: 1
             */
            Sum: {
              /**
               * Summe
               */
              E2001803: string;
            }[];
          }[];
          /**
           * Rentenversicherungen_mit_Kapitalwahlrecht_und_/_oder_Kapitallebensversicherungen_mit_einer_Laufzeit_von_mindestens_12_Jahren_sowie_einem_Laufzeitbeginn_und_der_ersten_Beitragszahlung_vor_dem_1.1.2005
           * @maxLength: 1
           */
          RV_m_WR_KapLV: {
            /**
             * Einzelangaben
             * @maxLength: 50
             */
            Einz: {
              /**
               * Bezeichnung
               */
              E2001901: string;
              /**
               * Betrag
               */
              E2001902: string;
            }[];
            /**
             * Summe
             * @maxLength: 1
             */
            Sum: {
              /**
               * Summe
               */
              E2001903: string;
            }[];
          }[];
          /**
           * Rentenversicherungen_ohne_Kapitalwahlrecht_mit_Laufzeitbeginn_und_erster_Beitragszahlung_vor_dem_1.1.2005_(auch_steuerpflichtige_Beiträge_zu_Versorgungs-_und_Pensionskassen)_-_ohne_Altersvorsorgebeiträge,_die_in_der_Anlage_AV_geltend_gemacht_werden_-
           * @maxLength: 1
           */
          RV_o_WR_o_AV: {
            /**
             * Einzelangaben
             * @maxLength: 50
             */
            Einz: {
              /**
               * Bezeichnung
               */
              E2002001: string;
              /**
               * Betrag
               */
              E2002002: string;
            }[];
            /**
             * Summe
             * @maxLength: 1
             */
            Sum: {
              /**
               * Summe
               */
              E2002003: string;
            }[];
          }[];
        }[];
      }[];
      /**
       * Ergänzende_Angaben_zu_Vorsorgaufwendungen:_$Person.Wert$
       * @maxLength: 2
       */
      Erg_Ang: {
        Person: ElsterPerson;
        /**
         * Haben_Sie_zu_Ihrer_Krankenversicherung_oder_Ihren_Krankheitskosten_Anspruch_auf_steuerfreie_Zuschüsse,_steuerfreie_Arbeitgeberbeiträge_oder_steuerfreie_Beihilfen?
         */
        E2001001: string;
        /**
         * Es_bestand_$VZ$_keine_gesetzliche_Rentenversicherungspflicht_aus_dem_aktiven_Dienstverhältnis_/_aus_der_Tätigkeit
         * @maxLength: 1
         */
        Keine_RV_Pfl: {
          /**
           * als_Beamter_/_Beamtin
           */
          E2002201: string;
          /**
           * als_Vorstandsmitglied_/_GmbH-Gesellschafter-Geschäftsführer_/_-in
           */
          E2002202: string;
          /**
           * als_(zum_Beispiel_Praktikant/in,_Student/in_im_Praktikum)
           */
          E2005303: string;
          /**
           * als_(zum_Beispiel_Praktikant/in,_Student/in_im_Praktikum)_-_Bezeichnung
           */
          E2002301: string;
          /**
           * Aufgrund_des_genannten_Dienstverhältnisses_/_der_Tätigkeit_bestand_hingegen_eine_Anwartschaft_auf_Altersversorgung
           */
          E2002401: string;
        }[];
        /**
         * Arbeitslohn_aus_einem_nicht_aktiven_Dienstverhältnis
         * @maxLength: 1
         */
        BetrR_WerksP_n_stbeg_VersoB: {
          /**
           * Es_wurde_Arbeitslohn_aus_einem_nicht_aktiven_Dienstverhältnis_-_insbesondere_Betriebsrente_/_Werkspension_-_bezogen,_bei_dem_es_sich_nicht_um_steuerbegünstigte_Versorgungsbezüge_(Zeilen_11_bis_16_der_Anlage_N)_handelt._Bei_Altersteilzeit_ist_hier_keine_Eintragung_vorzunehmen.
           */
          E2005801: string;
        }[];
      }[];
    }[];
  }
  export interface IV {
    /**
     * Einkünfte_aus_Vermietung_und_Verpachtung
     * @maxLength: 1
     */
    V: {
      /**
       * Einkünfte_aus_dem_$index$._bebauten_Grundstück
       * @maxLength: 999
       */
      Ek_b_Gst: {
        /**
         * Allgemeine_Angaben_zum_bebauten_Grundstück
         * @maxLength: 1
         */
        Allg: {
          /**
           * Straße,_Hausnummer
           */
          E0700407: string;
          /**
           * Angeschafft_am
           */
          E0700102: string;
          /**
           * Postleitzahl
           */
          E0700503: string;
          /**
           * Ort
           */
          E0700504: string;
          /**
           * Fertig_gestellt_am
           */
          E0700103: string;
          /**
           * Einheitswert-Aktenzeichen
           */
          E0700605: string;
          /**
           * Veräußert_/_Übertragen_am
           */
          E0700606: string;
          /**
           * Das_in_Zeile_4_bezeichnete_Objekt_wird_ganz_oder_teilweise_als_Ferienwohnung_genutzt
           */
          E0700703: string;
          /**
           * Das_in_Zeile_4_bezeichnete_Objekt_wird_ganz_oder_teilweise_kurzfristig_vermietet
           */
          E0700705: string;
          /**
           * Das_in_Zeile_4_bezeichnete_Objekt_wird_ganz_oder_teilweise_an_Angehörige_zu_Wohnzwecken_vermietet
           */
          E0700704: string;
          /**
           * Gesamtwohnfläche_(in_m²)
           */
          E0700702: string;
          /**
           * davon_eigengenutzter_oder_unentgeltlich_an_Dritte_überlassener_Wohnraum_(in_m²)
           */
          E0700104: string;
          /**
           * davon_als_Ferienwohnung_genutzter_Wohnraum_(in_m²)
           */
          E0700106: string;
        }[];
        /**
         * Einnahmen
         * @maxLength: 1
         */
        Einn: {
          /**
           * Mieteinnahmen
           * @maxLength: 1
           */
          Mieteinn: {
            /**
             * Mieteinnahmen_für_Wohnungen_(ohne_Umlagen)
             * @maxLength: 1
             */
            Whg: {
              /**
               * Einzelangaben
               * @maxLength: 99
               */
              Einz: {
                /**
                 * Mieteinnahmen
                 */
                E0700201: string;
                /**
                 * Anzahl_der_Wohnungen
                 */
                E0700301: string;
                /**
                 * Wohnfläche_in_m²
                 */
                E0700302: string;
              }[];
              /**
               * Summe
               * @maxLength: 1
               */
              Sum: {
                /**
                 * Mieteinnahmen
                 */
                E0700206: string;
              }[];
            }[];
            /**
             * Mieteinnahmen_für_andere_Räume_(ohne_Umlagen_/_Umsatzsteuer)
             * @maxLength: 1
             */
            And_Raeum: {
              /**
               * Einzelangaben
               * @maxLength: 99
               */
              Einz: {
                /**
                 * Mieteinnahmen_für_andere_Räume
                 */
                E0700401: string;
              }[];
              /**
               * Summe
               * @maxLength: 1
               */
              Sum: {
                /**
                 * Mieteinnahmen
                 */
                E0700406: string;
              }[];
            }[];
            /**
             * Einnahmen_für_an_Angehörige_vermietete_Wohnungen_(ohne_Umlagen)
             * @maxLength: 1
             */
            Whg_Angehoe: {
              /**
               * Anzahl
               */
              E0700602: string;
              /**
               * Wohnfläche_in_m²
               */
              E0700603: string;
              /**
               * Einnahmen
               */
              E0700604: string;
            }[];
          }[];
          /**
           * Umlagen_und_sonstige_Einnahmen
           * @maxLength: 1
           */
          Uml_sonst: {
            /**
             * auf_die_Zeilen_9_und_11_entfallende_Umlagen,_verrechnet_mit_Erstattungen_(zum_Beispiel_Wassergeld,_Flur-_und_Kellerbeleuchtung,_Müllabfuhr,_Zentralheizung_und_so_weiter)
             */
            E0700501: string;
            /**
             * auf_die_Zeile_12_entfallende_Umlagen,_verrechnet_mit_Erstattungen_(zum_Beispiel_Wassergeld,_Flur-_und_Kellerbeleuchtung,_Müllabfuhr,_Zentralheizung_und_so_weiter)
             */
            E0700502: string;
            /**
             * Vereinnahmte_Mieten_für_frühere_Jahre_/_verrechnete_Mietkautionen_/_auf_das_Kalenderjahr_entfallende_Mietvorauszahlungen_aus_Baukostenzuschüssen
             */
            E0700601: string;
            /**
             * Einnahmen_aus_Vermietung_von_Garagen,_Werbeflächen,_Grund_und_Boden_für_Kioske_und_so_weiter
             */
            E0700701: string;
            /**
             * Vereinnahmte_Umsatzsteuer
             */
            E0701602: string;
            /**
             * Vom_Finanzamt_erstattete_und_gegebenenfalls_verrechnete_Umsatzsteuer
             */
            E0701701: string;
            /**
             * Öffentliche_Zuschüsse_nach_dem_WoFG_oder_zu_Erhaltungsaufwendungen,_Aufwendungszuschüsse,_Guthabenzinsen_aus_Bausparverträgen_und_sonstige_Einnahmen
             * @maxLength: 1
             */
            Oeff_Zusch: {
              /**
               * Gesamtbetrag
               */
              E0701301: string;
              /**
               * davon_entfallen_auf_eigengenutzte_oder_unentgeltlich_an_Dritte_überlassene_Wohnungen_laut_Zeile_8
               */
              E0701302: string;
              /**
               * Summe
               */
              E0701303: string;
            }[];
          }[];
        }[];
        /**
         * Ermittlung_und_Zuordnung_der_Einkünfte
         * @maxLength: 1
         */
        Erm_Zuord_Ek: {
          /**
           * Summe_der_Einnahmen
           */
          E0701401: string;
          /**
           * Summe_der_Werbungskosten_(Übertrag_aus_Zeile_51)
           */
          E0701501: string;
          /**
           * Überschuss_(zu_übertragen_nach_Zeile_24)
           */
          E0701601: string;
          /**
           * Zurechnung_des_Betrags_aus_Zeile_23_(Steuerpflichtige_Person_/_Ehemann_/_Person_A_/_Gesellschaft)
           */
          E0701801: string;
          /**
           * Zurechnung_des_Betrags_aus_Zeile_23_(Ehefrau_/_Person_B)
           */
          E0701802: string;
        }[];
        /**
         * Werbungskosten_aus_dem_bebauten_Grundstück
         * @maxLength: 1
         */
        Wk: {
          /**
           * Absetzung_für_Abnutzung_für_Gebäude_(ohne_Beträge_in_den_Zeilen_34_und_35)
           * @maxLength: 1
           */
          AfA_Geb: {
            /**
             * Einzelangaben
             * @maxLength: 99
             */
            Einz: {
              /**
               * linear
               */
              E0703501: string;
              /**
               * degressiv
               */
              E0703502: string;
              /**
               * Prozent
               */
              E0703503: string;
              /**
               * wie_$VZ-1$
               */
              E0703504: string;
              /**
               * laut_Erläuterung
               */
              E0703505: string;
              /**
               * Erläuterung
               */
              E0703301: string;
              /**
               * Gesamtbetrag
               */
              E0703506: string;
              /**
               * Ausgaben,_die_nicht_mit_Vermietungseinkünften_zusammenhängen,_wurden_durch_direkte_Zuordnung_ermittelt
               */
              E0703507: string;
              /**
               * Ausgaben,_die_nicht_mit_Vermietungseinkünften_zusammenhängen,_wurden_verhältnismäßig_ermittelt_(in_Prozent)
               */
              E0703508: string;
              /**
               * Werbungskosten
               */
              E0703510: string;
            }[];
            /**
             * Summe
             * @maxLength: 1
             */
            Sum: {
              /**
               * Abzugsfähige_Werbungskosten
               */
              E0703511: string;
            }[];
          }[];
          /**
           * Sonderabschreibung_für_Mietwohnungsneubau_nach_§_7b_EStG
           * @maxLength: 1
           */
          Sonderabschr_P7b: {
            /**
             * Einzelangaben
             * @maxLength: 99
             */
            Einz: {
              /**
               * wie_$VZ-1$
               */
              E0703409: string;
              /**
               * laut_Erläuterung
               */
              E0703410: string;
              /**
               * Erläuterung
               */
              E0703411: string;
              /**
               * Gesamtbetrag
               */
              E0703412: string;
              /**
               * Ausgaben,_die_nicht_mit_Vermietungseinkünften_zusammenhängen,_wurden_durch_direkte_Zuordnung_ermittelt
               */
              E0703413: string;
              /**
               * Ausgaben,_die_nicht_mit_Vermietungseinkünften_zusammenhängen,_wurden_verhältnismäßig_ermittelt_(in_Prozent)
               */
              E0703414: string;
              /**
               * Werbungskosten
               */
              E0703415: string;
            }[];
            /**
             * Summe
             * @maxLength: 1
             */
            Sum: {
              /**
               * Abzugsfähige_Werbungskosten
               */
              E0703416: string;
            }[];
          }[];
          /**
           * Erhöhte_Absetzungen_nach_den_§§_7h,_7i_EStG,_Schutzbaugesetz
           * @maxLength: 1
           */
          Erhoe_Absetz: {
            /**
             * Einzelangaben
             * @maxLength: 99
             */
            Einz: {
              /**
               * wie_$VZ-1$
               */
              E0704001: string;
              /**
               * laut_Erläuterung
               */
              E0704002: string;
              /**
               * Erläuterung
               */
              E0703408: string;
              /**
               * Gesamtbetrag
               */
              E0704003: string;
              /**
               * Ausgaben,_die_nicht_mit_Vermietungseinkünften_zusammenhängen,_wurden_durch_direkte_Zuordnung_ermittelt
               */
              E0704004: string;
              /**
               * Ausgaben,_die_nicht_mit_Vermietungseinkünften_zusammenhängen,_wurden_verhältnismäßig_ermittelt_(in_Prozent)
               */
              E0704005: string;
              /**
               * Werbungskosten
               */
              E0704007: string;
            }[];
            /**
             * Summe
             * @maxLength: 1
             */
            Sum: {
              /**
               * Abzugsfähige_Werbungskosten
               */
              E0704008: string;
            }[];
          }[];
          /**
           * Absetzung_für_Abnutzung_für_bewegliche_Wirtschaftsgüter
           * @maxLength: 1
           */
          AfA_bew_WG: {
            /**
             * Einzelangaben
             * @maxLength: 99
             */
            Einz: {
              /**
               * wie_$VZ-1$
               */
              E0703513: string;
              /**
               * laut_Erläuterung
               */
              E0703514: string;
              /**
               * Erläuterung
               */
              E0703521: string;
              /**
               * Gesamtbetrag
               */
              E0703515: string;
              /**
               * Ausgaben,_die_nicht_mit_Vermietungseinkünften_zusammenhängen,_wurden_durch_direkte_Zuordnung_ermittelt
               */
              E0703516: string;
              /**
               * Ausgaben,_die_nicht_mit_Vermietungseinkünften_zusammenhängen,_wurden_verhältnismäßig_ermittelt_(in_Prozent)
               */
              E0703517: string;
              /**
               * Werbungskosten
               */
              E0703518: string;
            }[];
            /**
             * Summe
             * @maxLength: 1
             */
            Sum: {
              /**
               * Abzugsfähige_Werbungskosten
               */
              E0703519: string;
            }[];
          }[];
          /**
           * Schuldzinsen_(ohne_Tilgungsbeträge)
           * @maxLength: 1
           */
          Schuldzins: {
            /**
             * Einzelangaben
             * @maxLength: 99
             */
            Einz: {
              /**
               * Bank_/_Bezeichnung
               */
              E0703401: string;
              /**
               * Gesamtbetrag
               */
              E0703402: string;
              /**
               * Ausgaben,_die_nicht_mit_Vermietungseinkünften_zusammenhängen,_wurden_durch_direkte_Zuordnung_ermittelt
               */
              E0703403: string;
              /**
               * Ausgaben,_die_nicht_mit_Vermietungseinkünften_zusammenhängen,_wurden_verhältnismäßig_ermittelt_(in_Prozent)
               */
              E0703404: string;
              /**
               * Betrag
               */
              E0703407: string;
            }[];
            /**
             * Summe
             * @maxLength: 1
             */
            Sum: {
              /**
               * Abzugsfähige_Werbungskosten
               */
              E0703406: string;
            }[];
          }[];
          /**
           * Geldbeschaffungskosten_(zum_Beispiel_Schätz-,_Notar-,_Grundbuchgebühren)
           * @maxLength: 1
           */
          Geldbeschaff: {
            /**
             * Einzelangaben
             * @maxLength: 99
             */
            Einz: {
              /**
               * Bezeichnung
               */
              E0704401: string;
              /**
               * Gesamtbetrag
               */
              E0704402: string;
              /**
               * Ausgaben,_die_nicht_mit_Vermietungseinkünften_zusammenhängen,_wurden_durch_direkte_Zuordnung_ermittelt
               */
              E0704403: string;
              /**
               * Ausgaben,_die_nicht_mit_Vermietungseinkünften_zusammenhängen,_wurden_verhältnismäßig_ermittelt_(in_Prozent)
               */
              E0704404: string;
              /**
               * Betrag
               */
              E0703512: string;
            }[];
            /**
             * Summe
             * @maxLength: 1
             */
            Sum: {
              /**
               * Abzugsfähige_Werbungskosten
               */
              E0704406: string;
            }[];
          }[];
          /**
           * Renten,_dauernde_Lasten_(laut_Einzelaufstellung)
           * @maxLength: 1
           */
          Rente_dL: {
            /**
             * Einzelangaben
             * @maxLength: 99
             */
            Einz: {
              /**
               * Bezeichnung
               */
              E0704501: string;
              /**
               * Gesamtbetrag
               */
              E0704502: string;
              /**
               * Ausgaben,_die_nicht_mit_Vermietungseinkünften_zusammenhängen,_wurden_durch_direkte_Zuordnung_ermittelt
               */
              E0704503: string;
              /**
               * Ausgaben,_die_nicht_mit_Vermietungseinkünften_zusammenhängen,_wurden_verhältnismäßig_ermittelt_(in_Prozent)
               */
              E0704504: string;
              /**
               * Betrag
               */
              E0703607: string;
            }[];
            /**
             * Summe
             * @maxLength: 1
             */
            Sum: {
              /**
               * Abzugsfähige_Werbungskosten
               */
              E0704506: string;
            }[];
          }[];
          /**
           * $VZ$_voll_abzuziehende_Erhaltungsaufwendungen,_die_direkt_zugeordnet_werden_können
           * @maxLength: 1
           */
          Erhalt_AW_dir: {
            /**
             * Einzelangaben
             * @maxLength: 99
             */
            Einz: {
              /**
               * Bezeichnung
               */
              E0703707: string;
              /**
               * Rechnungsaussteller
               */
              E0703708: string;
              /**
               * Rechnungsdatum
               */
              E0703709: string;
              /**
               * Gesamtbetrag
               */
              E0704410: string;
              /**
               * Abzugsfähige_Werbungskosten
               */
              E0703911: string;
            }[];
            /**
             * Summe
             * @maxLength: 1
             */
            Sum: {
              /**
               * Abzugsfähige_Werbungskosten
               */
              E0704412: string;
            }[];
          }[];
          /**
           * $VZ$_voll_abzuziehende_Erhaltungsaufwendungen,_die_verhältnismäßig_zugeordnet_werden
           * @maxLength: 1
           */
          Erhalt_AW_verh: {
            /**
             * Einzelangaben
             * @maxLength: 99
             */
            Einz: {
              /**
               * Bezeichnung
               */
              E0703835: string;
              /**
               * Rechnungsaussteller
               */
              E0703836: string;
              /**
               * Rechnungsdatum
               */
              E0703837: string;
              /**
               * Gesamtbetrag
               */
              E0704510: string;
              /**
               * Ausgaben,_die_nicht_mit_Vermietungseinkünften_zusammenhängen,_wurden_verhältnismäßig_ermittelt_(in_Prozent)
               */
              E0704511: string;
              /**
               * Abzugsfähige_Werbungskosten
               */
              E0703838: string;
            }[];
            /**
             * Summe
             * @maxLength: 1
             */
            Sum: {
              /**
               * Abzugsfähige_Werbungskosten
               */
              E0704513: string;
            }[];
          }[];
          /**
           * Auf_bis_zu_5_Jahre_zu_verteilende_Erhaltungsaufwendungen_(§§_11a,_11b_EStG,_§_82b_EStDV)
           * @maxLength: 1
           */
          Erhalt_AW_5_J: {
            /**
             * Summe_Gesamtaufwand_$VZ$
             * @maxLength: 1
             */
            Sum: {
              /**
               * Summe
               */
              E0703907: string;
            }[];
            /**
             * Einzelangaben
             * @maxLength: 99
             */
            Einz: {
              /**
               * Gesamtaufwand_$VZ$
               */
              E0704701: string;
              /**
               * Bezeichnung
               */
              E0703908: string;
              /**
               * Rechnungsaussteller
               */
              E0703909: string;
              /**
               * Rechnungsdatum
               */
              E0703910: string;
              /**
               * davon_$VZ$_abzuziehen:_Gesamtbetrag
               */
              E0704703: string;
              /**
               * Ausgaben,_die_nicht_mit_Vermietungseinkünften_zusammenhängen,_wurden_durch_direkte_Zuordnung_ermittelt
               */
              E0704704: string;
              /**
               * Ausgaben,_die_nicht_mit_Vermietungseinkünften_zusammenhängen,_wurden_verhältnismäßig_ermittelt_(in_Prozent)
               */
              E0704705: string;
              /**
               * davon_$VZ$_abzuziehen:_Abzugsfähige_Werbungskosten
               */
              E0704707: string;
            }[];
            /**
             * davon_$VZ$_abzuziehen_(Summe)
             * @maxLength: 1
             */
            Abzb_VZ: {
              /**
               * Abzugsfähige_Werbungskosten
               */
              E0704708: string;
            }[];
            /**
             * zu_berücksichtigender_Anteil_aus_$VZ-4$
             * @maxLength: 1
             */
            Ant_VZ_abz_4: {
              /**
               * Einzelangaben
               * @maxLength: 99
               */
              Einz: {
                /**
                 * Gesamtbetrag
                 */
                E0704011: string;
                /**
                 * Ausgaben,_die_nicht_mit_Vermietungseinkünften_zusammenhängen,_wurden_durch_direkte_Zuordnung_ermittelt
                 */
                E0704012: string;
                /**
                 * Ausgaben,_die_nicht_mit_Vermietungseinkünften_zusammenhängen,_wurden_verhältnismäßig_ermittelt_(in_Prozent)
                 */
                E0704013: string;
                /**
                 * Werbungskosten
                 */
                E0704014: string;
              }[];
              /**
               * Summe
               * @maxLength: 1
               */
              Sum: {
                /**
                 * Abzugsfähige_Werbungskosten
                 */
                E0704015: string;
              }[];
            }[];
            /**
             * zu_berücksichtigender_Anteil_aus_$VZ-3$
             * @maxLength: 1
             */
            Ant_VZ_abz_3: {
              /**
               * Einzelangaben
               * @maxLength: 99
               */
              Einz: {
                /**
                 * Gesamtbetrag
                 */
                E0704111: string;
                /**
                 * Ausgaben,_die_nicht_mit_Vermietungseinkünften_zusammenhängen,_wurden_durch_direkte_Zuordnung_ermittelt
                 */
                E0704112: string;
                /**
                 * Ausgaben,_die_nicht_mit_Vermietungseinkünften_zusammenhängen,_wurden_verhältnismäßig_ermittelt_(in_Prozent)
                 */
                E0704113: string;
                /**
                 * Werbungskosten
                 */
                E0704114: string;
              }[];
              /**
               * Summe
               * @maxLength: 1
               */
              Sum: {
                /**
                 * Abzugsfähige_Werbungskosten
                 */
                E0704115: string;
              }[];
            }[];
            /**
             * zu_berücksichtigender_Anteil_aus_$VZ-2$
             * @maxLength: 1
             */
            Ant_VZ_abz_2: {
              /**
               * Einzelangaben
               * @maxLength: 99
               */
              Einz: {
                /**
                 * Gesamtbetrag
                 */
                E0704211: string;
                /**
                 * Ausgaben,_die_nicht_mit_Vermietungseinkünften_zusammenhängen,_wurden_durch_direkte_Zuordnung_ermittelt
                 */
                E0704212: string;
                /**
                 * Ausgaben,_die_nicht_mit_Vermietungseinkünften_zusammenhängen,_wurden_verhältnismäßig_ermittelt_(in_Prozent)
                 */
                E0704213: string;
                /**
                 * Werbungskosten
                 */
                E0704214: string;
              }[];
              /**
               * Summe
               * @maxLength: 1
               */
              Sum: {
                /**
                 * Abzugsfähige_Werbungskosten
                 */
                E0704215: string;
              }[];
            }[];
            /**
             * zu_berücksichtigender_Anteil_aus_$VZ-1$
             * @maxLength: 1
             */
            Ant_VZ_abz_1: {
              /**
               * Einzelangaben
               * @maxLength: 99
               */
              Einz: {
                /**
                 * Gesamtbetrag
                 */
                E0704311: string;
                /**
                 * Ausgaben,_die_nicht_mit_Vermietungseinkünften_zusammenhängen,_wurden_durch_direkte_Zuordnung_ermittelt
                 */
                E0704312: string;
                /**
                 * Ausgaben,_die_nicht_mit_Vermietungseinkünften_zusammenhängen,_wurden_verhältnismäßig_ermittelt_(in_Prozent)
                 */
                E0704313: string;
                /**
                 * Werbungskosten
                 */
                E0704314: string;
              }[];
              /**
               * Summe
               * @maxLength: 1
               */
              Sum: {
                /**
                 * Abzugsfähige_Werbungskosten
                 */
                E0704315: string;
              }[];
            }[];
          }[];
          /**
           * Grundsteuer,_Straßenreinigung,_Müllabfuhr,_Wasserversorgung,_Entwässerung,_Hausbeleuchtung,_Heizung,_Warmwasser,_Schornsteinreinigung,_Hausversicherungen,_Hauswart,_Treppenreinigung,_Fahrstuhl
           * @maxLength: 1
           */
          Weit: {
            /**
             * Einzelangaben
             * @maxLength: 99
             */
            Einz: {
              /**
               * Bezeichnung
               */
              E0704413: string;
              /**
               * Gesamtbetrag
               */
              E0704414: string;
              /**
               * Ausgaben,_die_nicht_mit_Vermietungseinkünften_zusammenhängen,_wurden_durch_direkte_Zuordnung_ermittelt
               */
              E0704415: string;
              /**
               * Ausgaben,_die_nicht_mit_Vermietungseinkünften_zusammenhängen,_wurden_verhältnismäßig_ermittelt_(in_Prozent)
               */
              E0704416: string;
              /**
               * Abzugsfähige_Werbungskosten
               */
              E0704417: string;
            }[];
            /**
             * Summe
             * @maxLength: 1
             */
            Sum: {
              /**
               * Summe
               */
              E0704418: string;
            }[];
          }[];
          /**
           * Verwaltungskosten
           * @maxLength: 1
           */
          Verw_Ko: {
            /**
             * Einzelangaben
             * @maxLength: 99
             */
            Einz: {
              /**
               * Verwaltungskosten
               */
              E0705510: string;
              /**
               * Gesamtbetrag
               */
              E0705511: string;
              /**
               * Ausgaben,_die_nicht_mit_Vermietungseinkünften_zusammenhängen,_wurden_durch_direkte_Zuordnung_ermittelt
               */
              E0705512: string;
              /**
               * Ausgaben,_die_nicht_mit_Vermietungseinkünften_zusammenhängen,_wurden_verhältnismäßig_ermittelt_(in_Prozent)
               */
              E0705513: string;
              /**
               * Werbungskosten
               */
              E0704709: string;
            }[];
            /**
             * Summe
             * @maxLength: 1
             */
            Sum: {
              /**
               * Abzugsfähige_Werbungskosten
               */
              E0705515: string;
            }[];
          }[];
          /**
           * Nur_bei_umsatzsteuerpflichtiger_Vermietung:_an_das_Finanzamt_gezahlte_und_gegebenenfalls_verrechnete_Umsatzsteuer
           * @maxLength: 1
           */
          Ust_stpfl_Verm: {
            /**
             * Gesamtbetrag
             */
            E0704811: string;
            /**
             * Abzugsfähige_Werbungskosten
             */
            E0704812: string;
          }[];
          /**
           * Sonstiges
           * @maxLength: 1
           */
          Sonst: {
            /**
             * Einzelangaben
             * @maxLength: 99
             */
            Einz: {
              /**
               * Sonstiges
               */
              E0705601: string;
              /**
               * Gesamtbetrag
               */
              E0705602: string;
              /**
               * Ausgaben,_die_nicht_mit_Vermietungseinkünften_zusammenhängen,_wurden_durch_direkte_Zuordnung_ermittelt
               */
              E0705603: string;
              /**
               * Ausgaben,_die_nicht_mit_Vermietungseinkünften_zusammenhängen,_wurden_verhältnismäßig_ermittelt_(in_Prozent)
               */
              E0705604: string;
              /**
               * Werbungskosten
               */
              E0705606: string;
            }[];
            /**
             * Summe
             * @maxLength: 1
             */
            Sum: {
              /**
               * Abzugsfähige_Werbungskosten
               */
              E0705607: string;
            }[];
          }[];
          /**
           * Summe_der_Werbungskosten
           * @maxLength: 1
           */
          Se_WK: {
            /**
             * Summe_der_Werbungskosten_(zu_übertragen_nach_Zeile_22)
             */
            E0705701: string;
          }[];
          /**
           * Nur_bei_umsatzsteuerpflichtiger_Vermietung:_in_den_Werbungskosten_laut_Zeile_51_enthaltene_abziehbare_Vorsteuerbeträge
           * @maxLength: 1
           */
          VoSt_in_WK_stpfl_Verm: {
            /**
             * Abzugsfähige_Werbungskosten
             */
            E0705107: string;
          }[];
        }[];
        /**
         * Zusätzliche_Angaben
         * @maxLength: 1
         */
        Zusatz_Ang: {
          /**
           * $VZ$_vereinnahmte_oder_bewilligte_Zuschüsse_aus_öffentlichen_Mitteln_zu_den_Anschaffungs-_/_Herstellungskosten_(laut_Aufstellung)
           * @maxLength: 5
           */
          Zusch: {
            /**
             * Bezeichnung
             */
            E0704808: string;
            /**
             * bewilligende_Stelle
             */
            E0704809: string;
            /**
             * Datum_des_Bescheids
             */
            E0704810: string;
            /**
             * Steuerpflichtige_Person_/_Ehemann_/_Person_A_/_Gesellschaft
             */
            E0706001: string;
            /**
             * Ehefrau_/_Person_B
             */
            E0706002: string;
          }[];
        }[];
      }[];
      /**
       * Anteile_an_Einkünften_aus
       * @maxLength: 1
       */
      Anteil_Ek: {
        /**
         * Bauherrengemeinschaft_/_Erwerbsgemeinschaft
         * @maxLength: 1
         */
        Bauh_Erw_Gem: {
          /**
           * Einzelangaben
           * @maxLength: 99
           */
          Einz: {
            /**
             * Bezeichnung
             */
            E0702101: string;
            /**
             * Steuerpflichtige_Person_/_Ehemann_/_Person_A_/_Gesellschaft
             */
            E0702104: string;
            /**
             * Ehefrau_/_Person_B
             */
            E0702105: string;
          }[];
          /**
           * Summe
           * @maxLength: 1
           */
          Sum: {
            /**
             * Steuerpflichtige_Person_/_Ehemann_/_Person_A_/_Gesellschaft
             */
            E0702102: string;
            /**
             * Ehefrau_/_Person_B
             */
            E0702103: string;
          }[];
        }[];

        /**
         * 2._Grundstücksgemeinschaft
         * @maxLength: 2
         */
        GG: {
          /**
           * Bezeichnung
           */
          E0702301: string;
          /**
           * Steuerpflichtige_Person_/_Ehemann_/_Person_A_/_Gesellschaft
           */
          E0702302: string;
          /**
           * Ehefrau_/_Person_B
           */
          E0702303: string;
        }[];
        /**
         * weiteren_Grundstücksgemeinschaften
         * @maxLength: 1
         */
        Weit_GG: {
          /**
           * Einzelangaben
           * @maxLength: 99
           */
          Einz: {
            /**
             * Bezeichnung_(3._und_weitere)
             */
            E0702511: string;
            /**
             * Steuerpflichtige_Person_/_Ehemann_/_Person_A_/_Gesellschaft
             */
            E0702514: string;
            /**
             * Ehefrau_/_Person_B
             */
            E0702515: string;
          }[];
          /**
           * Summe
           * @maxLength: 1
           */
          Sum: {
            /**
             * Steuerpflichtige_Person_/_Ehemann_/_Person_A_/_Gesellschaft
             */
            E0702512: string;
            /**
             * Ehefrau_/_Person_B
             */
            E0702513: string;
          }[];
        }[];
        /**
         * geschlossenen_Immobilienfonds
         * @maxLength: 1
         */
        Gesch_Immo_Fo: {
          /**
           * Einzelangaben
           * @maxLength: 99
           */
          Einz: {
            /**
             * Bezeichnung
             */
            E0702201: string;
            /**
             * Steuerpflichtige_Person_/_Ehemann_/_Person_A_/_Gesellschaft
             */
            E0702204: string;
            /**
             * Ehefrau_/_Person_B
             */
            E0702205: string;
          }[];
          /**
           * Summe
           * @maxLength: 1
           */
          Sum: {
            /**
             * Steuerpflichtige_Person_/_Ehemann_/_Person_A_/_Gesellschaft
             */
            E0702202: string;
            /**
             * Ehefrau_/_Person_B
             */
            E0702203: string;
          }[];
        }[];
        /**
         * Gesellschaften_/_Gemeinschaften_/_ähnlichen_Modellen_im_Sinne_des_§_15b_EStG
         * @maxLength: 99
         */
        Ges_Gem_P15b: {
          /**
           * Bezeichnung
           */
          E0702801: string;
          /**
           * Steuerpflichtige_Person_/_Ehemann_/_Person_A_/_Gesellschaft
           */
          E0702802: string;
          /**
           * Ehefrau_/_Person_B
           */
          E0702803: string;
        }[];
      }[];
      /**
       * Andere_Einkünfte
       * @maxLength: 1
       */
      And_Ek: {
        /**
         * Einkünfte_aus_Untervermietung_von_gemieteten_Räumen
         * @maxLength: 1
         */
        Unterverm: {
          /**
           * Einzelangaben
           * @maxLength: 99
           */
          Einz: {
            /**
             * Bezeichnung
             */
            E0702603: string;
            /**
             * Steuerpflichtige_Person_/_Ehemann_/_Person_A_/_Gesellschaft
             */
            E0702604: string;
            /**
             * Ehefrau_/_Person_B
             */
            E0702605: string;
          }[];
          /**
           * Summe
           * @maxLength: 1
           */
          Sum: {
            /**
             * Steuerpflichtige_Person_/_Ehemann_/_Person_A_/_Gesellschaft
             */
            E0702601: string;
            /**
             * Ehefrau_/_Person_B
             */
            E0702602: string;
          }[];
        }[];
        /**
         * Einkünfte_aus_Vermietung_und_Verpachtung_unbebauter_Grundstücke,_von_anderem_unbeweglichen_Vermögen,_von_Sachinbegriffen_sowie_aus_Überlassung_von_Rechten
         * @maxLength: 1
         */
        VuV_unbeb_Grdst: {
          /**
           * Einzelangaben
           * @maxLength: 99
           */
          Einz: {
            /**
             * Bezeichnung
             */
            E0702703: string;
            /**
             * Steuerpflichtige_Person_/_Ehemann_/_Person_A_/_Gesellschaft
             */
            E0702704: string;
            /**
             * Ehefrau_/_Person_B
             */
            E0702705: string;
          }[];
          /**
           * Summe
           * @maxLength: 1
           */
          Sum: {
            /**
             * Steuerpflichtige_Person_/_Ehemann_/_Person_A_/_Gesellschaft
             */
            E0702701: string;
            /**
             * Ehefrau_/_Person_B
             */
            E0702702: string;
          }[];
        }[];
        /**
         * Außerordentliche_Einkünfte
         * @maxLength: 1
         */
        Ao_Ek: {
          /**
           * Begünstigte_Einkünfte_nach_§_34_Absatz_2_EStG
           * @maxLength: 99
           */
          Einz: {
            /**
             * Bezeichnung
             */
            E0702903: string;
            /**
             * Steuerpflichtige_Person_/_Ehemann_/_Person_A_/_Gesellschaft
             */
            E0702904: string;
            /**
             * Ehefrau_/_Person_B
             */
            E0702905: string;
          }[];
          /**
           * Summe
           * @maxLength: 1
           */
          Sum: {
            /**
             * Steuerpflichtige_Person_/_Ehemann_/_Person_A_/_Gesellschaft
             */
            E0702901: string;
            /**
             * Ehefrau_/_Person_B
             */
            E0702902: string;
          }[];
        }[];
      }[];
    }[];
  }
  export interface IZins {
    /**
     * Betriebsausgabenabzug_für_Zinsaufwendungen_(§_4h_EStG)
     * @maxLength: 2
     */
    Zins: {
      Person: ElsterPerson;
      /**
       * $index$._Betrieb
       * @maxLength: 99
       */
      Betr: {
        /**
         * Angaben_zum_Betrieb
         * @maxLength: 1
         */
        Ang_Betr: {
          /**
           * Bezeichnung_des_Betriebs
           */
          E2110401: string;
        }[];
        /**
         * Zinsvortrag_nach_§_4h_Absatz_1_Satz_5_EStG
         * @maxLength: 1
         */
        Zins_Vort: {
          /**
           * Zinsvortrag_zum_Schluss_des_vorangegangenen_Wirtschaftsjahres.
           */
          E2110501: string;
          /**
           * Verringerung_des_Zinsvortrags,_zum_Beispiel_durch_Aufgabe_oder_Übertragung_eines_Betriebs_oder_Teilbetriebs_oder_Ausscheiden_eines_Mitunternehmers_aus_einer_Gesellschaft_(§_4h_Absatz_5_EStG),_Ausscheiden_einer_Organgesellschaft_aus_dem_Organkreis_(§§_15_Satz_1_Nummer_3,_8a_Absatz_1_KStG_in_Verbindung_mit_§_4h_Absatz_5_EStG),_Sanierungsertrag_(§_3a_Absatz_3_Satz_2_Nummer_13_Buchstabe_a_EStG)
           */
          E2110801: string;
          /**
           * Zinsaufwendungen_des_laufenden_Wirtschaftsjahres_im_Sinne_des_§_4h_Absatz_3_Satz_2_und_4_EStG_(Bei_Organträgern:_einschließlich_der_entsprechenden_Beträge_der_Organgesellschaften)
           */
          E2110601: string;
          /**
           * Nach_Anwendung_des_§_4h_EStG_abziehbare_Beträge_(Aufstellung_zur_Berechnung)
           */
          E2110802: string;
          /**
           * Nach_Anwendung_des_§_4h_EStG_abziehbare_Beträge_(bei_der_Ermittlung_des_Gewinns_berücksichtigt)_(Bei_Organträgern:_einschließlich_der_entsprechenden_Beträge_der_Organgesellschaften)_-_Berechnung_laut_Aufstellung_-_(Betrag)
           */
          E2110701: string;
          /**
           * Die_Voraussetzungen_des_§_8a_KStG_in_Verbindung_mit_§_4h_Absatz_2_Satz_1_EStG_zum_uneingeschränkten_Abzug_der_Zinsaufwendungen_liegen_vor:_§_4h_Absatz_2_Satz_1_Buchstabe_a_EStG_(Zinssaldo_weniger_als_3_Millionen_EUR)
           */
          E2110902: string;
          /**
           * Die_Voraussetzungen_des_§_8a_KStG_in_Verbindung_mit_§_4h_Absatz_2_Satz_1_EStG_zum_uneingeschränkten_Abzug_der_Zinsaufwendungen_liegen_vor:_§_4h_Absatz_2_Satz_1_Buchstabe_b_EStG_(Konzernklausel)
           */
          E2111002: string;
          /**
           * Die_Voraussetzungen_des_§_8a_KStG_in_Verbindung_mit_§_4h_Absatz_2_Satz_1_EStG_zum_uneingeschränkten_Abzug_der_Zinsaufwendungen_liegen_vor:_§_4h_Absatz_2_Satz_1_Buchstabe_c_EStG_(Escape-Klausel)
           */
          E2111102: string;
          /**
           * Nichtabziehbare_Zinsaufwendungen_=_Zinsvortrag_zum_Schluss_des_Wirtschaftsjahres_(gegebenenfalls_nach_Minderung_um_den_Sanierungsertrag_nach_§_3a_Absatz_3_Satz_2_Nummer_13_Buchstabe_a_EStG)
           */
          E2110901: string;
          /**
           * Zinserträge_des_laufenden_Wirtschaftsjahres_nach_§_4h_Absatz_3_Satz_3_und_4_EStG_(Bei_Organträgern:_einschließlich_der_entsprechenden_Beträge_der_Organgesellschaften)
           */
          E2111001: string;
          /**
           * Nach_§§_6_Absatz_2_Satz_1,_6_Absatz_2a_Satz_2_und_7_EStG_abgesetzte_Beträge_(Bei_Organträgern:_einschließlich_der_entsprechenden_Beträge_der_Organgesellschaften)
           */
          E2111101: string;
          /**
           * Vergütungen_für_Fremdkapital_an_wesentlich_beteiligte_Anteilseigner,_diesen_nahestehende_Personen_und_rückgriffsberechtigte_Dritte_-_§_4h_Absatz_2_Satz_2_EStG,_§_8a_Absätze_2_und_3_KStG_-_(Bei_Organträgern:_einschließlich_der_entsprechenden_Beträge_der_Organgesellschaften)
           */
          E2111201: string;
        }[];
        /**
         * EBITDA-Vortrag_nach_§_4h_Absatz_1_Satz_3_EStG
         * @maxLength: 1
         */
        EBITDA_Vortr: {
          /**
           * EBITDA-Vortrag_zum_Schluss_des_vorangegangenen_Wirtschaftsjahres_(Bezeichnung)
           */
          E2111302: string;
          /**
           * EBITDA-Vortrag_zum_Schluss_des_vorangegangenen_Wirtschaftsjahres_(Betrag)
           */
          E2111301: string;
          /**
           * Verringerung_des_EBITDA-Vortrags,_zum_Beispiel_durch_Aufgabe_oder_Übertragung_eines_Betriebs_oder_Teilbetriebs_oder_Ausscheiden_eines_Mitunternehmers_aus_einer_Gesellschaft_(§_4h_Absatz_5_EStG),_Ausscheiden_einer_Organgesellschaft_aus_dem_Organkreis_(§§_15_Satz_1_Nummer_3,_8a_Absatz_1_KStG_in_Verbindung_mit_§_4h_Absatz_5_EStG),_Sanierungsertrag_(§_3a_Absatz_3_Satz_2_Nummer_13_Buchstabe_b_EStG)
           */
          E2111703: string;
          /**
           * Verrechenbares_EBITDA_des_laufenden_Wirtschaftsjahres_(wenn_negativ,_\"0\"_eintragen)_-_nur,_wenn_im_Wirtschaftsjahr_kein_Anwendungsfall_des_§_4h_Absatz_2_EStG_vorliegt_(Bei_Organträgern:_einschließlich_der_entsprechenden_Beträge_der_Organgesellschaften)
           */
          E2111401: string;
          /**
           * Berücksichtigungsfähiges_verrechenbares_EBITDA
           */
          E2111501: string;
          /**
           * Verbrauch_von_verrechenbarem_EBITDA_des_laufenden_Wirtschaftsjahres
           */
          E2111601: string;
          /**
           * Verbrauch_von_zum_Schluss_des_vorangegangenen_Wirtschaftsjahres_gesondert_festgestelltem_verrechenbaren_EBITDA_im_laufenden_Wirtschaftsjahr
           */
          E2111702: string;
          /**
           * Verbleibendes_verrechenbares_EBITDA_=_EBITDA-Vortrag_zum_Schluss_des_Wirtschaftsjahres_(gegebenenfalls_nach_Minderung_um_den_Sanierungsertrag_nach_§_3a_Absatz_3_Satz_2_Nummer_13_Buchstabe_b_EStG)
           */
          E2111701: string;
        }[];
      }[];
    }[];
  }
  export interface IVorsatz {
    Vorsatz: {
      /**
       * Unterfallart (bei ESt ist der Wert 10 zu verwenden)
       */
      Unterfallart: string;

      /**
       * Vorgang
       */
      Vorgang: string;

      /**
       * Steuernummer
       */
      StNr: string;

      /**
       * Ordnungsbegriff
       */
      Ordnungsbegriff: string;

      /**
       * Identifikationsnummer steuerpflichtige Person / Ehemann / Person A
       */
      ID: string;

      /**
       * Identifikationsnummer Ehefrau / Person B
       */
      IDEhefrau: string;

      /**
       * Jahr der Erklärung (Veranlagungszeitraum)
       */
      Zeitraum: string;

      /**
       * Name des Absenders der Erklärung
       */
      AbsName: string;

      /**
       * Straße des Absenders der Erklärung
       */
      AbsStr: string;

      /**
       * Postleitzahl des Absenders der Erklärung
       */
      AbsPlz: string;

      /**
       * Ort des Absenders der Erklärung
       */
      AbsOrt: string;

      /**
       * Copyrightvermerk des Herstellers der Steuersoftware
       */
      Copyright: string;

      /**
       * TeleNummer (für finanzamtsinterne Zwecke; keine Eingabe durch Anwender)
       */
      TeleNummer: string;

      /**
       * Art der Ordnungsnummer (zulässig sind die Werte S für Steuernummer und O für Ordnungsbegriff).
       */
      OrdNrArt: string;

      /**
       * Bundesfinanzamtsnummer
       */
      Bundesfinanzamtsnummer: string;

      Rueckuebermittlung: {
        /**
         * Wird die elektronische Bereitstellung der Bescheiddaten gewünscht ?
         */
        Bescheid: string;

        /**
         * Art der Benachrichtigung über vorliegende Bescheiddaten zur Abholung
         */
        ArtRueckuebermittlung: string;

        /**
         * Schlüssel unter dem die Bescheiddaten verschlüsselt zur Abholung bereitgestellt werden sollen
         */
        SchluesselRueckuebermittlung: string;

        /**
         * Benachrichtigungsadresse (in der Regel E-Mail-Adresse) für die Benachrichtigung über die elektronische Bereitstellung der Bescheiddaten.
         */
        AdresseRueckuebermittlung: string;
      }[];
    }[];
  }
}

export interface Elster2020E10
  extends Elster2020E10.IVorsatz,
    Elster2020E10.IAgB,
    Elster2020E10.I13a,
    Elster2020E10.I34b,
    Elster2020E10.IAUS,
    Elster2020E10.IAV13a,
    Elster2020E10.IAV,
    Elster2020E10.ICorona,
    Elster2020E10.I35c,
    Elster2020E10.IEst1A,
    Elster2020E10.IU,
    Elster2020E10.IFW,
    Elster2020E10.IG,
    Elster2020E10.I35a,
    Elster2020E10.IBET,
    Elster2020E10.II,
    Elster2020E10.IKAP,
    Elster2020E10.IKind,
    Elster2020E10.IL,
    Elster2020E10.IAUS,
    Elster2020E10.IGRE,
    Elster2020E10.IN,
    Elster2020E10.IAUS,
    Elster2020E10.IbAV,
    Elster2020E10.IR,
    Elster2020E10.ISA,
    Elster2020E10.ISonst,
    Elster2020E10.ISO,
    Elster2020E10.IS,
    Elster2020E10.IVOR,
    Elster2020E10.IV,
    Elster2020E10.IEST,
    Elster2020E10.IZins {}
