import { TaxDeduction } from '../taxnotice/taxnotice';

/**
 * Merger Document for payout process. There can only be one active (draft) document per client
 * It merges taxnotices with banktransactionlines of all years
 */
export abstract class PayoutBatchMerger {
  /**
   * taxnotices mapped to banktransactionlines
   * also containing deductions entered in btch merger
   */
  abstract taxnotices:
    | {
        taxnoticeRef: string;
        deductions: TaxDeduction[];
      }[]
    | null;
  /**
   *  banktransactionlines mapped to taxnotices
   */
  abstract bankTransactionLineRefs: string[] | null;
  /**
   * wheter this merger was finished or is still active
   */
  abstract status: 'draft' | 'processed';
  /**
   *  overall sum which is the base of our marge
   */
  abstract payoutSumWithOutDeductions: number | null;
  /**
   * overall sum which we recieved by taxoffice for this batch. Does not include marge subtracted
   */
  abstract payoutSum: number | null;
  /**
   *
   */
  abstract clientId: string;
  static getTemplate(clientId: string): PayoutBatchMerger {
    return {
      taxnotices: null,
      bankTransactionLineRefs: null,
      status: 'draft',
      payoutSumWithOutDeductions: null,
      payoutSum: null,
      clientId,
    };
  }
}
