import { IIsCalculated } from '../interfaces/iTaxform';
import { CalculationData2 } from './calculation';

export class TaxCalculationStatus implements IIsCalculated {
  public static getTemplate(): TaxCalculationStatus {
    return {
      isCalculated: false,
      errorCount: 0.0,
      calculationdata: CalculationData2.getTemplate(),
    };
  }
  isCalculated: boolean = false; // is it calculated
  errorCount: number = 0.0; // If error happened during calculation, we stop at 3
  calculationdata?: CalculationData2 | null = null;
}
