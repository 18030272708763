import { TaxReturnPerson } from './returntaxform';

export class TaxReturn {
  public static getTemplate(): TaxReturn {
    return {
      tax: 0.0,
      incomeTax: 0.0, // Wenn Partner, dann steht hier die gemeinsame Steuer
      taxable_income: 0.0, // Zu versteuerndes Einkommen zusammen
      relocationFlat: 0.0, // Umzugspauschale
      householdServices: 0.0,
      housesehold: {
        cleaning: 0.0,
        maintenance: 0.0,
      },
      expenses: {
        specialExpenses: 0.0,
        outstandingExpenses: 0.0, // Ausserordentliche Belastung (Alleinerziehend/Behindert)
        retirementExpenses: 0.0,
        medicalCareExpenses: 0.0,
      },
      client: TaxReturnPerson.getTemplate(),
      partner: TaxReturnPerson.getTemplate(),
      payed: {
        incomeTax: 0.0,
        totalTaxShortTime: 0.0,
        totalTax: 0.0,
        solidarityTax: 0.0,
        churchTax: 0.0,
      },
      actual: {
        incomeTax: 0.0,
        totalTax: 0.0,
        solidarityTax: 0.0,
        churchTax: 0.0,
        taxableIncome: 0.0,
        governentalAid: 0.0,
      },
      return: {
        return: '300-500',
        incomeTax: 0.0,
        totalTax: 0.0,
        household: 0.0,
        solidarityTax: 0.0,
        churchTax: 0.0,
      },
    };
  }

  public tax = 0;
  public incomeTax = 0; // Wenn Partner, dann steht hier die gemeinsame Steuer
  public taxable_income = 0; // Zu versteuerndes Einkommen zusammen
  public relocationFlat = 0; // Umzugspauschale
  public householdServices = 0;
  public housesehold = {
    cleaning: 0,
    maintenance: 0,
  };
  public expenses = {
    specialExpenses: 0,
    outstandingExpenses: 0, // Ausserordentliche Belastung (Alleinerziehend/Behindert)
    retirementExpenses: 0,
    medicalCareExpenses: 0,
  };
  public client: TaxReturnPerson = TaxReturnPerson.getTemplate();
  public partner: TaxReturnPerson = TaxReturnPerson.getTemplate();
  /**
   * Payed describes what the person already payed to the taxoffice
   */
  public payed = {
    incomeTax: 0,
    totalTax: 0,
    totalTaxShortTime: 0,
    solidarityTax: 0,
    churchTax: 0,
  };
  /**
   * Actual describes what the client realy has to pay to the taxoffice
   */
  public actual = {
    incomeTax: 0,
    totalTax: 0,
    solidarityTax: 0,
    churchTax: 0,
    taxableIncome: 0,
    governentalAid: 0,
  };
  /**
   * What the client gets back (payed - actual)
   */
  public return = {
    return: '',
    incomeTax: 0,
    totalTax: 0,
    household: 0,
    solidarityTax: 0,
    churchTax: 0,
  };
}
