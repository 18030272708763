import firebase from 'firebase/app';
import { ExpsAddress } from '../taxform/taxform';

/**
 * Defines the responsible TaxAdvisor company where we send the Tax to.
 */
export class TaxAdvisor {
  static getTaxadvisorShort(taxadvisor: TaxAdvisor): TaxAdvisorShort {
    return {
      id: taxadvisor.id,
      displayName: taxadvisor.displayName,
    };
  }

  static getTemplate(): TaxAdvisor {
    return {
      id: 'expresssteuermbh',
      displayName: 'ESX Steuerberatungsgesellschaft mbH',
      firstname: 'ESG StB GmbH',
      lastname: 'Postbox 668893',
      address: {
        formatedAddress:
          'Postbox 668893 Barbara-McClintock-Str 11 12489 Berlin',
        unformatedAddress:
          'Postbox 668893 Barbara-McClintock-Str 11 12489 Berlin',
        city: {
          longName: 'Berlin',
          shortName: 'Berlin',
        },
        zipCode: {
          longName: '12489',
          shortName: '12489',
        },
        street: {
          longName: 'Barbara-McClintock-Str',
          shortName: 'Barbara-McClintock-Str',
        },
        nbr: {
          longName: '11',
          shortName: '11',
        },
        region: {
          longName: '',
          shortName: '',
        },
        country: {
          longName: 'Deutschland',
          shortName: 'DE',
        },
        state: {
          longName: 'Berlin',
          shortName: 'BE',
        },
        lat: '',
        long: '',
        placeId: '',
        isChanged: false,
      },
      phoneNumber: '0402286570201',
      email: 'steuerbescheid@esxsteuer.de',
      startDate: null,
      active: false,
      caseDistributionPercentage: 0,
      retrievalActive: false,
    };
  }

  id: string = '';
  displayName: string = '';
  phoneNumber: string = '';
  /**
   * EMail used for Elster responses
   */
  email: string = '';
  address: ExpsAddress | null = null;
  startDate: firebase.firestore.Timestamp | null = null;
  active: boolean = false;
  firstname: string = '';
  lastname: string = '';
  retrievalActive: boolean = false;

  /**
   * How many cases are distributed to the taxadvisor
   * All taxadvisors percentage combined is treated as 100%
   * Also the active flag has to be true in order to get distribution
   */
  caseDistributionPercentage: number = 0.5;
}

export class TaxAdvisorShort {
  static getTemplate(): TaxAdvisorShort {
    return {
      id: '',
      displayName: '',
    };
  }

  public id: string = '';
  public displayName: string = '';
}
