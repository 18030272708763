import firebase from 'firebase/app';
import 'firebase/firestore';
export class MessageAttachment {
  public static getTemplate(): MessageAttachment {
    return {
      id: '',
      created: firebase.firestore.Timestamp.now(),
      name: '',
      size: 0,
      tags: [],
      contentType: '',
      type: '',
      downloadPath: '',
      storagePath: '',
    };
  }
  public id = '';
  public created: firebase.firestore.Timestamp | null = null;
  public name = '';
  public size = 0;
  public tags: string[] = [];
  public contentType = '';
  public type = '';
  public downloadPath = '';
  public storagePath = '';
}
