import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/info' },
  {
    path: '',
    loadChildren: () =>
      import('./pages/signup/v1/taxform.module').then(
        (mod) => mod.TaxformModule
      ),
    data: { animation: 'Info' },
  },
  // { path: 'v2', loadChildren: () => import('./pages/signup/v2/taxform.module').then(mod => mod.TaxformModule), data: {animation: 'V2'} },
  {
    path: 'short',
    loadChildren: () =>
      import('./pages/signup/short/taxform.module').then(
        (mod) => mod.TaxformModule
      ),
    data: { animation: 'Short' },
  },
  {
    path: 'club-member',
    loadChildren: () =>
      import('./pages/signup/offer-only/taxform.module').then(
        (mod) => mod.TaxformModule
      ),
    data: { animation: 'Club-Member' },
  },
  {
    path: 'myaccount',
    loadChildren: () =>
      import('./pages/myaccount/myaccount.module').then(
        (mod) => mod.MyaccountModule
      ),
    data: { animation: 'User Profile' },
  },
  {
    path: 'verification/:formId/:formEmail',
    loadChildren: () =>
      import('./pages/verification/verification.module').then(
        (mod) => mod.VerificationModule
      ),
    data: { animation: 'Verification' },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      paramsInheritanceStrategy: 'always',
      scrollPositionRestoration: 'top',
      relativeLinkResolution: 'legacy',
    }),
  ],
  // imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top'})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
