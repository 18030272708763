import { Timestamp } from '../helpers/timestamp';
import { TaxAdvisorBankAccountShort } from '../taxadvisor/bankaccounts/taxadvisorbank';
import { TaxCase } from '../taxcase/taxcase';
import { MARITALSTATUS } from '../taxform/taxform';
import { TaxId } from './taxId';

/**
 * Describes setting for a client | partner on a specific taxyear
 * This document could also occour multiple times within a year if the client | partner has relocated
 * will be kept in collection /clients/[clientId]/taxsettings/
 */
export class TaxSettings {
  static fromTaxCase(
    taxcase: TaxCase,
    taxAdvisorId: string,
    bank: TaxAdvisorBankAccountShort | null = null
  ): TaxSettings[] {
    const result: TaxSettings[] = [];
    result.push({
      clientId: taxcase.client.id,
      created: null,
      eTin: '',
      financeDepartmentId: '',
      taxCaseId: taxcase.id,
      taxNumber: '',
      taxPerson: 'A',
      type: 'client',
      validFrom: null,
      year: parseInt(taxcase.year),
      taxAdvisor: {
        id: taxAdvisorId || 'expresssteuermbh',
      },
      bank,
    });

    // if married, create it for the partner too
    if (
      taxcase.taxform.current.taxForm.information.maritalStatus ===
      MARITALSTATUS.married
    ) {
      result.push({
        clientId: taxcase.client.id,
        created: null,
        eTin: '',
        financeDepartmentId: '',
        taxCaseId: taxcase.id,
        taxNumber: '',
        taxPerson: 'B',
        type: 'partner',
        validFrom: null,
        year: parseInt(taxcase.year),
        taxId: 0,
        taxAdvisor: {
          id: '',
        },
        bank: null,
      });
    }

    return result;
  }

  static getTemplate(): TaxSettings {
    return {
      created: null,
      validFrom: null,
      taxPerson: 'A',
      clientId: '',
      taxCaseId: '',
      type: 'client',
      year: 2000,
      taxNumber: '',
      financeDepartmentId: '',
      eTin: '',
      taxAdvisor: {
        id: 'expresssteuermbh',
      },
      bank: null,
    };
  }

  static buildDocumentId(taxsettings: TaxSettings): string {
    // this is added to allow multiple settings for one year
    const randomstring = Math.random().toString(36).substring(2, 6);
    return `${taxsettings.type}_${taxsettings.year}_${taxsettings.clientId}_${randomstring}`;
  }

  /**
   * database id;
   */
  id?: string = '';
  /**
   * the client Id the TaxSetting is for
   */
  clientId: string = '';
  /**
   * taxcase if available
   * this could also be empty
   */
  taxCaseId: string = '';

  /**
   * Are this taxsettings related to the client or partner
   */
  type: 'client' | 'partner' = 'client';

  /**
   * A means is the client for sending to elster
   * B is the partner or if the same gender the person later in the alphabet
   */
  taxPerson: 'A' | 'B' = 'A';

  /**
   * If type is partner, we store the taxId here.
   * This allows us to have different settings in every year.
   * @description
   * 2017 single 2018 married 2019 divorced 2020 married with a new partner
   */
  taxId?: number | null = 12345678901;

  /**
   * When the taxsettings have been created
   */
  created: Timestamp | null = null;
  /**
   * If in a specific year there are multiple taxsettings
   * this specifies which one is valid based on the Timestamp
   * This information is listened alos in the taxdocuments recieved from the tax office wagestatement
   */
  validFrom: Timestamp | null = null;

  /**
   * The taxyear this taxsettings are valid
   * We usually take this information from the VollmachstDatenbank
   */
  year: number = 2000;

  /**
   * Steuernummer
   * For every finance department the client has a different taxNumber
   * */
  taxNumber: string = '';
  /**
   * Responsible Department (4 digit number)
   */
  financeDepartmentId: string = '';
  /**
   * eTin (14 Alphanumeric values)
   */
  eTin: string = '';

  /** The taxadvisor used in the current year.
  The settings is only on the partner. 
  */
  taxAdvisor: {
    id: string;
  } = {
    id: 'expresssteuermbh',
  };
  bank?: TaxAdvisorBankAccountShort | null;
}

export interface TaxSettingsForTaxcase {
  clientTaxId: TaxId;
  forClient: TaxSettings;
  forPartner?: TaxSettings;
}
