import { User } from '../../user/user';
import { IFrom } from './inboxFrom';
import { InboxMessage } from './inboxMessage';

export class InboxSender implements IFrom {
  id: string = '';
  from: {
    displayName?: string;
    customerId?: string;
    senderAddress: string;
  } = {
    displayName: '',
    senderAddress: '',
  };
  keyAccount?: User | null;
  lastUnreadMessage?: InboxMessage;
  unreadMessages = 0;
}
