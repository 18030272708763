import { REGULARYWORKINGTIME } from '../taxform';

export class Worksettings {
  public static getTemplate(): Worksettings {
    return {
      hasRegularyWorkingAtSameLocation: false,
      regularyWorkingTime: null,
    };
  }

  hasRegularyWorkingAtSameLocation = false;
  regularyWorkingTime: REGULARYWORKINGTIME | null = null;
}
