export class Tenant {
  public static getTemplate(): Tenant {
    return {
      id: '',
      name: '',
    };
  }
  id: string = '';
  name: string = '';
}
