export interface IFrom {
  from: {
    displayName?: string;
    customerId?: string;
    /**
     * email or whatsapp number
     */
    senderAddress: string;
  };
}
