import firebase from 'firebase/app';
import 'firebase/firestore';
// tslint:disable: object-literal-sort-keys

/**
 * Partner
 */

export enum jobStatus {
  'maried',
  'single',
}

export interface IPartner {
  inChurch: false;
  marriedSince: firebase.firestore.Timestamp | null;
  birthdate: firebase.firestore.Timestamp | null;
  firstname: string;
  lastname: string;
  hasDisability: boolean;
  disability: string;
  jobStatus: jobStatus;
  unionMembership: boolean;
  unionMember: string;
  start: firebase.firestore.Timestamp | null;
  end: firebase.firestore.Timestamp | null;
}
