/**
 * Partner site settings
 */
export class EnvironmentPartner {
  public static getTemplate(): EnvironmentPartner {
    return {
      name: '',
      key: '',
      index: 1,
    };
  }
  /**
   * Company name
   */
  name: string = '';
  /**
   * Company Key, will be used for TAXCASE ID KEY-[TAXYEAR]-[INDEX]
   */
  key: string = '';
  /**
   * The number of taxcases from this partner
   * This number is only allowed to count up with AppDatabase.INCREMENT
   */
  index: number = 1;
}

export class PartnerCustomization {
  public static getTemplate(): PartnerCustomization {
    return {
      css: '',
      js: '',
      logo: '',
      icon: '',
    };
  }
  /**
   * link to css file
   */
  css: string = '';
  /**
   * link to javascript file
   */
  js: string = '';
  /**
   * Logo
   */
  logo: string = '';
  /**
   * favicon
   */
  icon: string = '';
}
