import firebase from 'firebase/app';

export interface UserPresence {
  lastChanged: firebase.firestore.Timestamp | Object;
  activeSessions: number | Object;
}

export interface UserPresenceRealtimeDB
  extends Omit<UserPresence, 'lastChanged'> {
  lastChanged: number | Object;
}

export interface Object_RealtimeDB {
  '{userId}': {
    '{taskId}': {
      pictureUrl: string;
      displayName: string;
      count: number;
    };
  };
}

export interface RealtimeDB {
  users?: {
    [userId: string]: {
      presence?: {
        'task-island': UserPresenceRealtimeDB;
      };
    };
  };
  'smart-tasks-on-screen': {
    [userId: string]: {
      user?: {
        displayName?: string | null;
        email?: string | null;
        photoUrl?: string | null;
      };
      sessions: {
        [userSessionId: string]: {
          taskIds?: string[];
        };
      };
    };
  };
}
