export class Bank {
  validated: boolean = false;
  valid?: boolean;
  IBAN: string = '';
  bankCode: string = '';
  bankName: string = '';
  bankBIC: string = '';
  bankPLZ: string = '';
  bankCity: string = '';

  public static getTemplate(): Bank {
    return {
      validated: false,
      valid: false,
      IBAN: '',
      bankCode: '',
      bankName: '',
      bankBIC: '',
      bankPLZ: '',
      bankCity: '',
    };
  }
}
