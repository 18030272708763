import { Selector, Timestamp } from '@expresssteuer/models';

type DateTypes = 'dd.mm' | 'dd.mm.yyyy';
export type Entry<O, V = any> = {
  selector: Selector<O, V>;
  type: V extends number
    ? 'number'
    : V extends string
    ? 'string'
    : V extends Timestamp
    ? DateTypes
    : never;
  regexp: RegExp;
};

export type AllowedStringTypes = TypeAsString<any>;
export type TypeAsString<T> = T extends number
  ? 'number'
  : T extends string
  ? 'string'
  : T extends Timestamp
  ? DateTypes
  : never;

export function buildRegexEntry<O, V>(
  selector: Selector<O, V>,
  caster: TypeAsString<V>,
  regexp: RegExp
): Entry<O, V> {
  return {
    selector,
    type: caster,
    regexp,
  };
}

//TODO move to lib
export const commonRegex = {
  /**
   * Matches german numbers positive or negative with exactly 2 digits after the comma, that is formatted with dot digit grouping
   * eg: -1.234,56
   */
  money: {
    german: {
      withCentsAndDigitGrouping: /-?\d{1,3}(?:\.\d{3})*(?:,\d{2})/,
    },
  },
};
