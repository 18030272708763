import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export const LANGUAGES = {
  de: 'Deutsch', // defaultLanguage
  en: 'English',
  es: 'Español',
  fr: 'Français',
  it: 'Italiano',
  nl: 'Nederlands',
  pl: 'Polskie',
  ru: 'Pусский',
  ro: 'Română',
  tr: 'Türk',
};
export const SHORTLANGUAGES = Object.keys(LANGUAGES);

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private languages: string[];
  private defaultLanguage: string;

  constructor(public translateService: TranslateService) {
    this.languages = translateService.getLangs();
    this.defaultLanguage = this.languages[0] || 'de'; // in some cases languages is not yet filled
  }

  public useLanguage(language: string): Observable<string> {
    this.defaultLanguage = this.languages[0];
    if (language === '') {
      const browserLang = this.translateService.getBrowserLang();
      language = this.languages.includes(browserLang)
        ? browserLang
        : this.defaultLanguage;
    }

    return this.translateService.use(language).pipe(map(() => language));
  }
}
