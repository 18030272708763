export enum DataRequired {
  'IbanRequired' = 'IBAN_REQUIRED',
  'InvoicesRequired' = 'MISSING_INVOICES',
  'BirthCertificateRequired' = 'BIRTH_CERTIFICATE_REQUIRED',
  'BankAccountExcerptRequired' = 'BANK_ACCOUNT_EXCERPT_REQUIRED',
  'WorkRoomQuestionnaireRequired' = 'WORK_ROOM_QUESTIONNAIRE_REQUIRED',
  'TaxIdChildRequired' = 'TAXID_CHILD_REQUIRED',
  'EstLastYearRequired' = 'EST_LAST_YEAR_REQUIRED',
}
export enum PendlerDataRequired {
  'DriveListingRequired' = 'DRIVE_LISTING_REQUIRED',
  'AdressWorkRequired' = 'ADDRESS_WORK_REQUIRED',
  'WorkDaysRequired' = 'WORKDAYS_REQUIRED',
}
export enum VollmachtRequired {
  'GeldempfangsVollmachtRequired' = 'GELDEMPFANGS_VOLLMACHT_REQUIRED',
  'MasterVollmachtRequired' = 'MASTER_VOLLMACHT_REQUIRED',
  'VmdbVollmachtRequired' = 'VMDB_VOLLMACHT_REQUIRED',
}

/**
 * @description CONTENT TAGS included in one finance office letter
 * used to classify passages of text and
 */
export type ContentTags =
  | DataRequired
  | VollmachtRequired
  | PendlerDataRequired;

export const CONTENT_TAGS_STRUCTURED = [
  {
    category: 'DATA_REQUIRED',
    values: Object.values(DataRequired),
  },
  {
    category: 'PENDLER_DATA_REQUIRED',
    values: Object.values(PendlerDataRequired),
  },
  { category: 'VOLLMACHT_REQUIRED', values: Object.values(VollmachtRequired) },
];
