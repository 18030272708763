// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  logLevel: 'debug',
  production: false,
  emulator: false,
  debug: {
    logging: true,
    view: true,
    buttons: true,
  },
  firebase: {
    apiKey: 'AIzaSyA7aHq45T9jOnO6Gsc8_mxNSx7UEKJZPMc',
    authDomain: 'expresssteuer-development.firebaseapp.com',
    projectId: 'expresssteuer-development',
    storageBucket: 'expresssteuer-development.appspot.com',
    messagingSenderId: '849191636990',
    appId: '1:849191636990:web:c48cfc39beff4c6f87baaf',
    databaseURL: 'https://expresssteuer-development.firebaseio.com',
    measurementId: 'G-ZY2M1Y7PGT',
  },
  GOOGLE_MAPS_API_KEY: 'AIzaSyA_6-IH14ugWxj6UIOaBq5swwN7Ta7dOAQ',
  GA_ACCOUNT: 'UA-137473816-3',
  FB_ACCOUNT: '2327542700662420',
  STRIPE_PAYMENT_PRODUCT_1_PRICE_ID: 'price_1JrkVPDurDNUJrm0mHDVefWP',
  STRIPE_PAYMENT_ENDPOINT:
    'https://europe-west3-expresssteuer-development.cloudfunctions.net/https_payWithStripe',
  features: {
    showDocumentTabInProfile: true,
  },
  clientAccessibleBucket: 'expresssteuer-dev-client-accessible', //has to be the same as in environment-configuration-backend. Not importing directly to reduce risk of leaking sensitive information.
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
