export class ShortTimeWork {
  public static getTemplate(): ShortTimeWork {
    return {
      monthsInShortTime: 0.0,
      shortTime: false,
      workDaysWithShortTime: 0.0,
      workingHoursPerDayRegular: 8.0,
      workingHoursPerDayShortTime: 8.0,
    };
  }
  /**
   * This is for later use
   * bolean = simple version
   * number = days in shorttime in this month 0-20
   * @example jan = true, or jan = 20 workdays
   * @deprecated DO NOT USE NOW. Probably for later use
   */
  public monthsInShorttime?: {
    jan: boolean | number;
    feb: boolean | number;
    mar: boolean | number;
    apr: boolean | number;
    may: boolean | number;
    jun: boolean | number;
    jul: boolean | number;
    aug: boolean | number;
    sep: boolean | number;
    oct: boolean | number;
    nov: boolean | number;
    dec: boolean | number;
  } = {
    jan: false,
    feb: false,
    mar: false,
    apr: false,
    may: false,
    jun: false,
    jul: false,
    aug: false,
    sep: false,
    oct: false,
    nov: false,
    dec: false,
  };

  /**
   * Client was in ShortTime
   */
  public shortTime: boolean = false;
  /**
   * 0-12 months in shorttime
   */
  public monthsInShortTime: number = 0;
  /**
   * How many of the days 1-5 where in shortTime?
   * 0 - max job.worddays
   */
  public workDaysWithShortTime: number = 0.0;
  /**
   * based on the contract how long is the working time
   */
  public workingHoursPerDayRegular: number = 8.0;
  /**
   * how much hours a day has been worked
   */
  public workingHoursPerDayShortTime: number = 8.0;
}
