import { Perms } from './permissions';

/**
 * JWT claims for an authorized user
 * Cannot contain reserved OIDC claim names(sub, iat, iss, ...)
 * Maximum of 1000 bytes
 */
export interface Claims {
  role: ClaimsRole;
  perms: Perms;
}

export type ClaimsRole = 'admin' | 'user' | '';
