interface EventBuildDataOptional {
  taxFormId?: string;
  taxRefund?: number;
  isTest?: boolean;
}

interface EventBuildDataEventName {
  aEventName: string;
  aSite?: string;
}

interface EventBuildDataSite {
  aEventName?: string;
  aSite: string;
}

export type EventBuildData = (EventBuildDataSite | EventBuildDataEventName) &
  EventBuildDataOptional;
