import { EventContext } from 'firebase-functions';
import firebase from 'firebase/app';
import { DOCUMENTSOURCE } from '../document/document';
import { DeepPartial } from '../elster/DeepObjectHelper';
import { User } from '../user/user';

export abstract class BaseTask<
  TTaskMatcherMetadata extends TaskMatcherMetadata = TaskMatcherMetadata
> {
  /**
   * The title of the task
   */
  abstract title: string;
  /**
   * Text description for the task
   */
  abstract description?: string;
  /**
   * The unique id for the task
   * @note this gets set on create
   */
  abstract id?: string;

  /**
   * The creation Date for the task
   * @note this gets set on create
   */
  abstract createdAt?: firebase.firestore.Timestamp;
  /**
   * An object to uniquely identify this task (eg. to match with incoming documents)
   * aka. "Composite Primary Key" for a Task
   */
  abstract taskMatcherMetadata?: TTaskMatcherMetadata | null;
  /**
   * When set to true, an automation may deactivate this task.
   */
  abstract deactivatableByAutomation: boolean;
  /**
   * When set to true, automations are NOT allowed to set the task to done=false.
   * Tasks may still be allowed to be set to done=true.
   */
  abstract notUndoneableByAutomation?: boolean;
  /**
   * Documents (BinaryDocument | AlgoliaDocument) referenced by this task
   */
  abstract referencedDocuments: string[];

  /**
   * When set to true, marks the task as done/completed
   */
  abstract done: boolean;
  /**
   * When set to true, indicates a "soft" delete
   */
  abstract deactivated: boolean;
  /**
   *
   */
  abstract type: TASKTYPE;
  /**
   *
   */
  abstract metadata?: Record<string, unknown>;
  /**
   *
   */
  abstract escalationLevel: number;
  /**
   * TODO
   * keyaccount/groups for task?
   */
  //keyaccount:User[]
}

export abstract class SmartTaskWithoutAClient<
  TTaskMatcherMetadata extends TaskMatcherMetadata = TaskMatcherMetadata
> extends BaseTask<TTaskMatcherMetadata> {
  static isSpecificSmartTaskSchema(
    task: BaseTask
  ): task is SmartTaskWithoutAClient {
    return (task as any)?.hasNoClient === true;
  }

  static getTemplate<STaskMatcherMetadata extends TaskMatcherMetadata>(
    input: Partial<SmartTaskWithoutAClient> & {
      title: string;
      type: TASKTYPE;
    } & {
      taskMatcherMetadata: STaskMatcherMetadata;
    }
  ): SmartTaskWithoutAClient<STaskMatcherMetadata>;

  static getTemplate<STaskMatcherMetadata extends undefined>(
    input: Partial<SmartTaskWithoutAClient> & {
      title: string;
      type: TASKTYPE;
    } & {
      taskMatcherMetadata?: STaskMatcherMetadata;
    }
  ): SmartTaskWithoutAClient;

  static getTemplate(
    input: Partial<SmartTaskWithoutAClient> & {
      title: string;
      type: TASKTYPE;
    }
  ): SmartTaskWithoutAClient {
    return {
      deactivatableByAutomation: false,
      referencedDocuments: [],
      done: false,
      deactivated: false,
      taskMatcherMetadata: null,
      escalationLevel: 0,
      hasNoClient: true,
      ...input,
    };
  }

  abstract hasNoClient: true;
}

export abstract class SmartTaskOnAClient<
  TTaskMatcherMetadata extends TaskMatcherMetadata = TaskMatcherMetadata
> extends BaseTask<TTaskMatcherMetadata> {
  static isSpecificSmartTaskSchema(task: BaseTask): task is SmartTaskOnAClient {
    return typeof (task as any)?.clientId === 'string';
  }

  static getTemplate<STaskMatcherMetadata extends TaskMatcherMetadata>(
    input: Partial<SmartTaskOnAClient> & {
      title: string;
      clientId: string;
      taxcaseIds: string[];
      type: TASKTYPE;
    } & {
      taskMatcherMetadata: STaskMatcherMetadata;
    }
  ): SmartTaskOnAClient<STaskMatcherMetadata>;

  static getTemplate<STaskMatcherMetadata extends undefined>(
    input: Partial<SmartTaskOnAClient> & {
      title: string;
      clientId: string;
      taxcaseIds: string[];
      type: TASKTYPE;
    } & {
      taskMatcherMetadata?: STaskMatcherMetadata;
    }
  ): SmartTaskOnAClient;

  static getTemplate(
    input: Partial<SmartTaskOnAClient> & {
      title: string;
      clientId: string;
      taxcaseIds: string[];
      type: TASKTYPE;
    }
  ): SmartTaskOnAClient {
    return {
      deactivatableByAutomation: false,
      standalone: false,
      referencedDocuments: [],
      done: false,
      deactivated: false,
      taxcaseSpecificOptions: [],
      taskMatcherMetadata: null,
      escalationLevel: 0,
      ...input,
    };
  }

  /**
   * When set to true, this task is important to the client itself and not just
   * a taxcase.
   * Taxcases can still be referenced, BUT, if set to true, the task is also
   * valid with an empty taxcaseIds array.
   */
  abstract standalone: boolean;
  /**
   * Taxcase's ids depending on this task
   */
  abstract taxcaseIds: string[];
  /**
   * Optional options for specific taxcases
   */
  abstract taxcaseSpecificOptions: TaxcaseSpecificOptions[];
  /**
   * Optional options for standalone tasks
   */
  clientSpecificOptions?: SpecificTaskOptions;
  /**
   * The id of the client related to this task
   */
  abstract clientId: string;
  /**
   *
   */
  abstract metadata?: {
    scheduledMessageInfo?: ScheduledMessageInfo;
    source?: DOCUMENTSOURCE;
  };
}

export function getTaskSchema(task: BaseTask) {
  if (SmartTaskWithoutAClient.isSpecificSmartTaskSchema(task)) {
    return SmartTaskWithoutAClient;
  }
  if (SmartTaskOnAClient.isSpecificSmartTaskSchema(task)) {
    return SmartTaskOnAClient;
  }
  return undefined;
}

export type SmartTask = BaseTask &
  Partial<SmartTaskWithoutAClient> &
  Partial<SmartTaskOnAClient>;

export interface ScheduledMessageInfo {
  doNotSendBefore: firebase.firestore.Timestamp;
  maxMessageCount: number;
  lastSentAt?: firebase.firestore.Timestamp | null;
  sentCount: number;
}

export interface TaxcaseSpecificOptions extends SpecificTaskOptions {
  /**
   * This option is for the specific taxcase
   */
  taxcaseId: string;
}
export interface SpecificTaskOptions {
  /**
   * The completion of the task is not strictly required for the instance related to this option
   */
  optional: boolean;
  /**
   * Potentially new options in the future
   */
  // [key: string]: any;
}

export type TaskUser = User | typeof taskSystemUser;

export const taskSystemUser = Object.freeze({
  id: 'TASK_SYSTEM',
  displayName: 'TASK SYSTEM',
  photoUrl: '', // TODO
});

export interface TaskMatcherMetadata<T = any> {
  id: string;
  input: T;
}

export enum TASKTYPE {
  DOCUMENT_REQUEST = 'DOCUMENT_REQUEST',
  DOCUMENT_CLASSIFICATION = 'DOCUMENT_CLASSIFICATION',
  INFORMATION_REQUEST = 'INFORMATION_REQUEST',
  MESSAGE_PROCESSING = 'MESSAGE_PROCESSING',
  OTHER = 'OTHER',
  MERGE_JOBS = 'MERGE_JOBS',
  FINAL_TAXCASE_REVIEW = 'FINAL_TAXCASE_REVIEW',
  FIX_FINANCE_DEPARTMENT_ID = 'FIX_FINANCE_DEPARTMENT_ID',
  TAXNOTICE_VERIFICATION = 'TAXNOTICE_VERIFICATION',
  TAXNOTCE_APPEAL = 'TAXNOTCE_APPEAL',
  TAXNOTCE_SUBMIT_APPEAL = 'TAXNOTCE_SUBMIT_APPEAL',
  DOCUMENT_TO_CLIENT_ASSIGNMENT = 'DOCUMENT_TO_CLIENT_ASSIGNMENT',
  TAXNOTICE_MANUAL_PROCESS = 'TAXNOTICE_MANUAL_PROCESS',
  JOBGROUP_ASSIGNMENT = 'JOBGROUP_ASSIGNMENT',
  JOBGROUP_OPTIMIZATION = 'JOBGROUP_OPTIMIZATION',
  PAYOUT_IBAN_NO_MATCH = 'PAYOUT_IBAN_NO_MATCH',
  PAYOUT_BATCH_MERGER = 'PAYOUT_BATCH_MERGER',
  EXTRACT_TAXOFFICE_REQUEST_CONTENT = 'EXTRACT_TAXOFFICE_REQUEST_CONTENT',
  EXTRACT_TAXOFFICE_INFORMATION_CONTENT = 'EXTRACT_TAXOFFICE_INFORMATION_CONTENT',
  ASSIGN_TAXOFFICE_REMINDER = 'ASSIGN_TAXOFFICE_REMINDER',

  // <-NEW-TASK-PLACEHOLDER-MARKER->
}

export function isTASKTYPE(input: unknown): input is TASKTYPE {
  return Object.values(TASKTYPE).includes(input as TASKTYPE);
}

export interface TaskHistoryEntry {
  diff: DeepPartial<SmartTaskOnAClient>;
  context: EventContext;
  user?: TaskUser;
}
