import 'firebase/firestore';
export interface IIsCalculated {
  isCalculated: boolean; // is it calculated
  errorCount: number; // If error happened during calculation, we stop at 3
}

export interface ITaxReturnPerson {
  gross_wage: number;
  gross_wage_fullYear: number;
  taxable_income: number;
  taxable_income_fullYear: number;
  incomeTax: number;
  incomeTax_fullYear: number;
  solidarityTax: number;
  solidarityTax_fullYear: number;
  churchTax: number;
  churchTax_fullYear: number;
  unionmembership: number;
  commuterReduction: number;
  advertisingExpenses: number;
  disability: number;
  insurances: {
    partial: {
      health: number;
      pension: number;
      unemployment: number;
      nursing: number;
    };
  };
}
/**
 * Tax form
 * This is the main class
 */

export enum DISABILITY {
  /** No Disability **/
  '<25' = '0',
  '25-30' = '25-30',
  '35-40' = '35-40',
  '45-50' = '45-50',
  '55-60' = '55-60',
  '65-70' = '65-70',
  '75-80' = '75-80',
  '85-90' = '85-90',
  '95-100' = '95-100',
}

export enum PRODUCTTYPE {
  'CASHNOW' = 'cashnow',
  'ONSUCCESS' = 'onsuccsess',
  'SELFPASS' = 'selfpass',
  'SHORTREGISTRATION' = 'shortregistration',
  'CLUBMEMBER' = 'clubmember',
  /**
   * taxform input by external lead tool, eg TicToc, sheets, ...; Details in metadata.leadGenerator
   */
  'LEAD' = 'lead',
}

export enum STATUS {
  /** A new TaxForm. New TaxForms can be ignored. **/
  'NEW' = 'new',

  /** The User is filling out the taxform. He did at least one save on the taxform **/
  'INPROGRESS' = 'inProgress',

  /** The TaxForm is ready and will be automatically transformed into a taxcase on save **/
  'READY' = 'ready',

  /** The process was successful and a taxcase has been created out of this taxform **/
  'NEWCASECREATED' = 'newcasecreated',

  /** Currently unused. Should be set by a schedule to mark unused taxforms and remove them later  **/
  'GARBAGE' = 'garbage',

  /** The TaxForm has been already submitted **/
  'ALREADYSUBMITTED' = 'alreadysubmitted',
}
