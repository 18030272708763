import { TaxCase } from './taxcase';

export class TaxCaseElasticResult {
  public hits: Hits = new Hits();
  public max_score = 0;
  public total = {
    relation: '',
    value: 0,
  };
  public timed_out = false;
  public took = 0;
}

export class Hits {
  public max_score = 0;
  public hits: TaxCaseHit[] = [];
  public total = 0;
  public relation = '';
}

export class TaxCaseHit {
  public _id = '';
  public _index = '';
  public _score = 0;
  public _type = '_doc';
  public _source: TaxCase = TaxCase.getTemplate();
}
