import firebase from 'firebase/app';

export enum PAYMENTSTATUS {
  'UNPAID' = 'unpaid',
  'PAYED' = 'payed',
}

export class Payment {
  public payment_status: string | null = null;
  public changedate: firebase.firestore.Timestamp | null = null;
  public amount_total: number = 0; // If we change the payment amount
  public amount_subtotal: number = 0; // The original
}
