import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  public static logger(className: string) {
    return new Logger(className);
  }
}
export enum LoggingLevel {
  Debug = 'debug',
  Info = 'info',
  Warnings = 'warnings',
  Errors = 'errors',
  None = 'none',
}

export class Logger {
  private config = {
    showClassNames: true,
    showTimeStamps: false,
  };
  private className: string;
  private level: string = environment.logLevel;
  private style: string;
  constructor(className: string) {
    this.className = className;
    const color = this.intToRGB(this.hashCode(className));
    this.style = `color: #ffffff; background-color:#${color}; border-radius:2ex; padding: 0 0.5ex 0 0.5ex`;
  }

  log(message: any, level = LoggingLevel.Debug, ...optionalParams: any[]) {
    if (this.shouldLog(level)) {
      const output = [];

      if (this.config.showClassNames) {
        output.push(`%c${this.className}`, this.style);
      }
      if (this.config.showTimeStamps) {
        const date = new Date();
        output.push(
          date.toLocaleString('de-DE', {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          }) + `.${date.getMilliseconds()}`
        );
      }

      output.push(message);
      output.push(...optionalParams);

      switch (level) {
        case LoggingLevel.Errors:
          // eslint-disable-next-line no-console
          console.error(...output);
          break;
        case LoggingLevel.Warnings:
          // eslint-disable-next-line no-console
          console.warn(...output);
          break;
        case LoggingLevel.Info:
          // eslint-disable-next-line no-console
          // eslint-disable-next-line no-restricted-syntax
          console.info(...output);
          break;
        default:
          // eslint-disable-next-line no-console
          // eslint-disable-next-line no-restricted-syntax
          console.debug(...output);
      }
    }
  }

  error(message: any, ...optionalParams: any[]) {
    this.log(message, LoggingLevel.Errors, ...optionalParams);
  }

  warn(message: any, ...optionalParams: any[]) {
    this.log(message, LoggingLevel.Warnings, ...optionalParams);
  }

  info(message: any, ...optionalParams: any[]) {
    this.log(message, LoggingLevel.Info, ...optionalParams);
  }

  debug(message: any, ...optionalParams: any[]) {
    this.log(message, LoggingLevel.Debug, ...optionalParams);
  }
  private shouldLog(level: LoggingLevel) {
    if (this.level === LoggingLevel.None) {
      return false;
    } else if (this.level === LoggingLevel.Errors) {
      return level === LoggingLevel.Errors;
    } else if (this.level === LoggingLevel.Warnings) {
      return level === LoggingLevel.Errors || level === LoggingLevel.Warnings;
    } else if (this.level === LoggingLevel.Info) {
      return (
        level === LoggingLevel.Errors ||
        level === LoggingLevel.Warnings ||
        level === LoggingLevel.Info
      );
    } else {
      return true;
    }
  }
  private hashCode(str: string) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      // eslint-disable-next-line no-bitwise
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  }

  private intToRGB(i: number) {
    // eslint-disable-next-line no-bitwise
    const c = (i & 0x00888888).toString(16).toUpperCase();
    return '00000'.substring(0, 6 - c.length) + c;
  }
}
