import { Timestamp } from '@google-cloud/firestore';
import { Bank } from '../bank/bank';
import { BankProvider } from '../bankAccount/bankProvider';

/**
 * A transaction represents a transfer of money between two accounts
 */
export abstract class Transaction {
  abstract id: string;
  /**
   * the client the transaction belongs to
   */
  abstract client: { id: string };
  /**
   * the source bank to take the amount from
   */
  abstract source: { type: BankProvider; bank: Bank };
  /**
   * The target bank to send the amount to
   */
  abstract destination: {
    type: BankProvider;
    bank: Bank;
  };
  /**
   * The amount to rens
   */
  abstract amount: number;
  /**
   * The reference to put on the send transaction line
   */
  abstract reference: string;
  /**
   * an internal not or the error message if in error state
   */
  abstract note: string;
  abstract created: Timestamp | null;
  /**
   * pending = transaction is not yet completed
   * completed = transaction is completed
   * error = transaction is not completed because of an error
   */
  abstract status: 'pending' | 'completed' | 'error';
  /**
   * flag indicating if whole transaction amount was processed By Payout process
   */
  abstract processedByPayout?: boolean | null;
  /**
   * amount of transaction value proccessed by payout process
   */
  abstract amountProcessedByPayout?: number | null;
}
