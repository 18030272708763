import { Timestamp } from '../helpers/timestamp';
import {
  TAXCASESTATUS,
  TAXCASESTATUS_INPROGRESS,
  TAXCASESTATUS_REJECT,
} from '../taxcase/taxcase';
import { User } from '../user/user';

export enum KIND {
  STATUS = 'status',
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error',
}

export class ModelHistoryItem {
  public static getTemplate(
    subject: string,
    message: string,
    user: User | null
  ): ModelHistoryItem {
    return {
      kind: KIND.STATUS,
      status: null,
      substatus: null,
      status_from: null,
      substatus_from: null,
      created: undefined,
      user: user,
      subject,
      message,
    };
  }
  public kind: KIND = KIND.STATUS;
  public status?: TAXCASESTATUS | null = null;
  public substatus?: TAXCASESTATUS_REJECT | TAXCASESTATUS_INPROGRESS | null =
    null;
  public status_from: TAXCASESTATUS | null = null;
  public substatus_from:
    | TAXCASESTATUS_REJECT
    | TAXCASESTATUS_INPROGRESS
    | null = null;
  public created: Timestamp | undefined;
  public user?: User | null = null;
  public subject: string = '';
  public message: string = '';
  /**
   * Seconds between the old and the new status
   */
  public timedelay?: number = 0;
}
