import { AbstractTimestamp } from '@expresssteuer/firebase-helper';
import { Message } from '../../communication/message';
import { Timestamp } from '../../helpers/timestamp';

export class Invite {
  public static getTemplate(): Invite {
    return {
      name: '',
      friendId: '',
      status: INVITATIONSTATUS.INVITED,
      token: '',
    };
  }

  /**
   * The Friend Name will be filled after the Invitation is accepted
   */
  public name = '';
  /**
   * THE clientID from the inviter
   */
  public friendId = '';
  /**
   * THE invitation message to be delivered => sending formal
   */
  public message?: Message;
  /**
   * THE status of the invitation
   */
  public status: INVITATIONSTATUS = INVITATIONSTATUS.INVITED;
  /**
   * the token (any guid or random code to garanty only the one with the right code can accept this invitation)
   */
  public token: string = '';

  public taxforms?: TaxFormInvited[];
}

export enum INVITATIONSTATUS {
  INVITED = 'invited',
  ACCEPTED = 'accepted',
  DECLINED = 'declined',
}

export class TaxFormInvited {
  public static getTemplate(timestamp: AbstractTimestamp): TaxFormInvited {
    return {
      year: '2020',
      status: TAXCASEINVITEDSTATUS.NEW,
      provision: 0,
      payout: false,
      id: '',
      created: timestamp,
      updated: timestamp,
      payOutSent: null,
    };
  }
  year: string = '2020';
  status: TAXCASEINVITEDSTATUS = TAXCASEINVITEDSTATUS.NEW;
  provision: number = 0;
  payout: boolean = false;
  id: string = '';

  created?: Timestamp | null = null;
  updated?: Timestamp | null = null;
  payOutSent?: Timestamp | null = null;
}

export enum TAXCASEINVITEDSTATUS {
  'NEW' = 'new',
  'VALID' = 'valid',
  'INVALID' = 'invalid',
}
