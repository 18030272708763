import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireStorage } from '@angular/fire/storage';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { environment } from '../environments/environment';
import { slideInAnimation } from './route-animation';
import { AuthService } from './services/auth/auth.service';
import { BusinessService } from './services/business/business.service';
import { SHORTLANGUAGES } from './services/language/language.service';
import { Logger, LoggerService } from './services/logger/logger.service';

declare let $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [slideInAnimation],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'expresssteuer-web';
  environment = environment;

  public currentUrlPath = window.location.pathname;
  public privacyHTML: string;
  public agbHTML: string;
  public imprintHTML: string;
  subscriptions: Subscription[] = [];
  logger: Logger;

  constructor(
    private aff: AngularFireFunctions,
    private afAuth: AngularFireAuth,
    private afStorage: AngularFireStorage,
    public authService: AuthService,
    private router: Router,
    private readonly businessService: BusinessService,
    public translateService: TranslateService
  ) {
    this.logger = LoggerService.logger(AppComponent.name);
  }

  ngOnInit() {
    if (environment.emulator) {
      this.aff
        .useFunctionsEmulator('http://localhost:5001')
        .then(() =>
          console.log(
            '%c RUNNING ON LOCAL FUNCTIONS EMULATOR',
            'font-size:1rem;background: #eeee; color: #3bbcf4'
          )
        )
        .catch((err) => {
          console.error('Unable to use emulator', err);
        });
      this.afAuth
        .useEmulator('http://localhost:9099')
        .then(() =>
          console.log(
            '%c RUNNING ON LOCAL AUTHENTICATION EMULATOR',
            'font-size:1rem;background: #eeee; color: #3bbcf4'
          )
        )
        .catch((err) => {
          console.error('Unable to use emulator', err);
        });

      this.afStorage.storage.useEmulator('localhost', 9199);
      console.log(
        '%c RUNNING ON LOCAL STORAGE EMULATOR',
        'font-size:1rem;background: #eeee; color: #3bbcf4'
      );
    }

    // console.log(
    //   '%c ExpresssSteuer GmbH Calculator v:' + pjson.version,
    //   'font-size:1rem;background: #eeee; color: #3bbcf4'
    // );
    // console.log(
    //   '%c Models v:' + pjson.dependencies['@expresssteuer/models-global'],
    //   'color: #3bbcf4'
    // );

    this.translateService.addLangs(SHORTLANGUAGES);
    this.translateService.setDefaultLang(SHORTLANGUAGES[0]);

    this.openModalForHash();

    this.subscriptions.push(
      this.authService.user$.subscribe((user) =>
        this.logger.info(`Logged in user: `, user)
      )
    );
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  public openModalForHash() {
    const currentHash = window.location.hash;

    if (currentHash.length !== 0) {
      if ($(currentHash).length !== 0) {
        $(currentHash).modal().show();
      }
    }
  }

  public getPartnerName() {
    let partnerName = window.location.host.split('.')[0].split('-')[1];

    if (partnerName !== undefined) {
      partnerName = partnerName.charAt(0).toUpperCase() + partnerName.slice(1);
    }

    return partnerName;
  }
}
