import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { Client, STATUS, TaxForm } from '@expresssteuer/models';
import firebase from 'firebase/app';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { ClientService } from '../client/client.service';
import { LanguageService } from '../language/language.service';
import { Logger, LoggerService } from '../logger/logger.service';
import { LOADINGSTATE } from './loadingstate';

/**
 * @deprecated
 */
declare let $: any;
/**
 * @deprecated
 */
declare let Odometer: any;

/**
 * @deprecated Use dedicated service
 */
@Injectable({
  providedIn: 'root',
})
export class BusinessService {
  /**
   * Is TaxForm loaded
   */
  public taxFormLoaded = false;

  /**
   * Collection for Taxforms last updated in the last 48h
   */
  private activeFormsCollection: AngularFirestoreCollection<TaxForm>;
  /**
   * Taxforms last updated in the last 48h
   */
  public activeForms$: Observable<TaxForm[]>;

  /**
   * All completed Taxcases
   */
  // public clientTaxcases$: Observable<(ClientTaxCase & { id: string })[]>;

  private activeTaxFormId = '';
  public taxForm: TaxForm = TaxForm.getTemplate(); // The current TaxForm

  public firstSave = false;

  /**
   * @deprecated
   */
  public aOdometer; // TODO What is this

  public loadingstate: LOADINGSTATE = LOADINGSTATE.NOTLOADED;
  logger: Logger;

  constructor(
    private readonly afStore: AngularFirestore,
    private authService: AuthService,
    private clientService: ClientService,
    private languageService: LanguageService
  ) {
    this.logger = LoggerService.logger(BusinessService.name);
  }

  public get isBackPayer() {
    const taxRefund =
      this.taxForm?.calculation?.calculationdata?.offer?.taxRefund;
    return taxRefund === undefined ? false : taxRefund <= 0;
  }

  public get isRetention() {
    return this.taxForm?.generator === 'retention';
  }

  /**
   *  Load the forms of the client if anonymous or user
   *  we return only the forms in progress
   *  the first form with status 'inProgress' will be set as active
   */
  public loadActiveTaxForms(userId: string, force = false) {
    // How old can a taxform be to be valid.
    // We check the last update time. Not creation
    const invalidationHours = -48;
    const invalidationDateTime: Date = new Date(
      new Date().setTime(
        new Date().getTime() + invalidationHours * 60 * 60 * 1000
      )
    );

    if (!this.activeFormsCollection || force) {
      this.activeFormsCollection = this.afStore.collection<TaxForm>(
        'taxforms',
        (ref) =>
          ref
            .where('status', 'in', [
              STATUS.NEW,
              STATUS.INPROGRESS,
              STATUS.READY,
            ])
            .where('uid', '==', userId)
            .orderBy('lastUpdate', 'desc')
            .where(
              'lastUpdate',
              '>',
              firebase.firestore.Timestamp.fromDate(invalidationDateTime)
            )
            .limit(50)
      );
    }
    this.activeForms$ = this.activeFormsCollection.valueChanges({
      idField: 'id',
    });
    this.activeForms$.subscribe((openForms) => {
      let routeTaxFormID = '';
      const path = window.location.pathname.split('/');
      if (path.indexOf('taxform') > 0) {
        routeTaxFormID = path[path.length - 1];
      }

      let activeForm: TaxForm;
      if (routeTaxFormID !== '') {
        activeForm = openForms.find(
          (openForm) => openForm.id === routeTaxFormID
        );
      } else {
        activeForm = openForms.find(
          (openForm) => openForm.status === 'inProgress'
        );
      }
      if (activeForm) {
        this.taxForm = activeForm;
        this.setActiveTaxForm(activeForm.id);
        this.updateOdomotor();
      }

      // TODO ?
      this.languageService
        .useLanguage(this.taxForm.language)
        .toPromise()
        .then(
          (language) => {
            this.taxForm.language = language;
          },
          (err) => {
            this.logger.info(err);
          }
        );
      this.taxFormLoaded = true;
    });
  }

  public setTaxForm(aTaxForm: TaxForm) {
    this.taxForm = aTaxForm;
  }

  /**
   * Sets and reconnects the Firebase Live connection to the taxform specified by id
   *
   * @param id The taxform id to connect to
   */
  public setActiveTaxForm(id: string) {
    if (this.activeTaxFormId !== id) {
      return;
    }

    this.logger.info('set active taxform to id ', id);
    this.activeFormsCollection = this.afStore.collection<TaxForm>(
      'taxforms',
      (q) =>
        q
          .where('status', 'in', ['new', 'inProgress', 'ready'])
          .where('uid', '==', this.authService.user.uid)
          .where('id', '==', id)
          .limit(1)
    );

    this.activeTaxFormId = id;
  }

  public async forceActiveTaxForm(id: string) {
    this.logger.info('force active taxform to id ', id);
    this.activeFormsCollection = this.afStore.collection<TaxForm>(
      'taxforms',
      (q) =>
        q
          .where('status', 'in', ['new', 'inProgress', 'ready'])
          .where('id', '==', id)
          .limit(1)
    );

    const taxFormRef = await this.afStore
      .collection<TaxForm>('taxforms', (ref) =>
        ref
          .where('status', 'in', ['new', 'inProgress', 'ready'])
          .where('id', '==', id)
          .limit(100)
      )
      .get()
      .toPromise();

    this.taxForm = taxFormRef.docs[0]?.data();

    this.save();

    this.activeTaxFormId = id;
  }

  /**
   * Saves the current taxform to firestore
   *
   * @param lastchanged Should the lastchanged parameter be updated?
   * @returns async string of the taxform id that was saved
   */
  public async save(lastchanged: boolean = false): Promise<string> {
    this.taxForm.status = 'inProgress';

    if (this.authService.user && this.authService.user.uid) {
      if (this.taxForm.uid !== this.authService.user.uid) {
        this.taxForm.id = '';
        this.taxForm.uid = this.authService.user.uid;
      }
    } else {
      this.logger.info('NO CLIENT');
    }
    this.logger.info('Save', this.taxForm.uid);
    if (this.clientService.client) {
      this.assignCustomerDataToTaxForm(this.clientService.client);
    }

    // If new TaxForm
    if (this.taxForm.id.length < 5 || this.taxForm.uid === '') {
      const id = this.afStore.createId();
      this.taxForm.id = id;
      console.log(
        'Using taxform with id %c' + id,
        'font-weight: bold; font-size: 1.5rem;'
      );
    }

    try {
      if (lastchanged) {
        this.taxForm.lastUpdate = firebase.firestore.Timestamp.now();
      }

      if (!this.firstSave) {
        if (this.taxForm.metadata.pageHistory) {
          this.taxForm.metadata.pageHistory =
            this.taxForm.metadata.pageHistory.slice(-1);
        }
        this.firstSave = true;
      }

      await this.afStore
        .collection('taxforms')
        .doc(this.taxForm.id)
        .set(this.taxForm);
      this.setActiveTaxForm(this.taxForm.id);

      this.logger.info('Loading acitve Taxform', this.taxForm.uid);
      this.loadActiveTaxForms(this.taxForm.uid, true);
      return this.taxForm.id;
    } catch (err) {
      this.logger.error('ERROR saving taxform', err);
      throw Error(err);
    }
  }

  public async complete(): Promise<void> {
    try {
      this.logger.info('Starting COMPLETE');
      this.loadingstate = LOADINGSTATE.SAVING;

      if (
        this.taxForm.status === 'new' ||
        this.taxForm.status === 'inProgress'
      ) {
        await this.afStore
          .collection('taxforms')
          .doc<TaxForm>(this.taxForm.id)
          .update({ status: 'ready' });
        this.taxForm.status = 'ready';
        this.loadingstate = LOADINGSTATE.COMPLETE;

        this.logger.info('=====>   Taxform is set to ready => creating case');
      } else {
        this.logger.error(
          '=====>   This taxform can not be updated. State is ' +
            this.taxForm.status
        );
        this.loadingstate = LOADINGSTATE.ERROR;
      }
    } catch (err) {
      this.logger.error('complete Taxform Error: ', err);
      throw err;
    }
  }

  public async alreadySubmitted(): Promise<void> {
    try {
      this.logger.info('Starting alreadySubmitted');
      this.loadingstate = LOADINGSTATE.SAVING;

      await this.afStore
        .collection('taxforms')
        .doc<TaxForm>(this.taxForm.id)
        .update({ status: STATUS.ALREADYSUBMITTED });
      this.taxForm.status = STATUS.ALREADYSUBMITTED;
      this.loadingstate = LOADINGSTATE.COMPLETE;

      this.logger.info(
        '=====>   Taxform is set to alreadySubmitted => dont creating case'
      );
    } catch (err) {
      this.logger.error('alreadySubmitted Taxform Error: ', err);
      throw err;
    }
  }

  public assignCustomerDataToTaxForm(client: Client) {
    if (client) {
      this.taxForm.information.addressCurrent = client.addressClient;
      this.taxForm.information.addressClient = client.addressClient;

      if (client.birthdate) {
        // this.clientBirthdate = this.dateUtil.getFormattedDateFromFirestoreTimeStamp(this.currentClient.birthdate);
        this.taxForm.information.birthdate = client.birthdate;
      }

      this.taxForm.information.firstname = client.firstname;
      this.taxForm.information.lastname = client.lastname;
      this.taxForm.information.email = client.email;

      if (
        client.mobile !== undefined &&
        client.mobile !== null &&
        client.mobile !== ''
      ) {
        this.taxForm.information.mobile = client.mobile;
      }

      if (
        client.bank !== undefined &&
        client.bank !== null &&
        client.bank.IBAN !== '' &&
        client.bank.IBAN.length > 8 &&
        client.bank.validated
      ) {
        this.taxForm.information.bank = client.bank;
      }
    }
  }

  public updateOdomotor() {
    if (!$('.odometer').hasClass('odometer-auto-theme')) {
      if (this.aOdometer === undefined) {
        const domOdometer = document.querySelector('.odometer');

        if (domOdometer != null) {
          this.aOdometer = new Odometer({
            el: document.querySelector('.odometer'),
            duration: 10000,
            format: '(.ddd)',
          });

          this.aOdometer.update(
            this.taxForm.calculation.calculationdata.offer.taxRefund
          );
        }
      }
    }
    if (this.aOdometer !== undefined) {
      this.aOdometer.update(
        this.taxForm.calculation.calculationdata.offer.taxRefund
      );
    }
  }
}
