import {
  DisablementReduction,
  Flats,
  ProgressionValue,
  S2016,
  S2017,
  S2018,
  S2019,
  S2020,
  S2021,
  TaxClass5,
} from './config/config';
import { RelocationFlat } from './RelocationFlat';
import { State } from './state/state';

export class Statics {
  private states: Array<State> = [];
  // private statics: Array<StaticValues> = [];
  public state!: State;
  // public static!: StaticValues
  private staticYears = [S2016, S2017, S2018, S2019, S2020, S2021];
  /**
   * Initialice the statics with all parameters
   * @param state The State short Bavaria = BY
   * @param year The year you need the statics values for
   */
  constructor() {
    this.initStates();
    // this.state = this.getState(state);
  }

  private initStates() {
    this.states = this.states.concat(S2021.get());
    this.states = this.states.concat(S2020.get());
    this.states = this.states.concat(S2019.get());
    this.states = this.states.concat(S2018.get());
    this.states = this.states.concat(S2017.get());
    this.states = this.states.concat(S2016.get());
  }

  public getDisablementReduction(year: string = '2018'): DisablementReduction {
    for (const d of this.staticYears) {
      if (d.year.toString() === year) {
        return d.getDisablement();
      }
    }
    throw new Error('Static Year ' + year + ' not found');
  }

  public getFlats(year: string = '2018'): Flats {
    for (const d of this.staticYears) {
      if (d.year.toString() === year) {
        return d.getFlats();
      }
    }
    throw new Error('Static Year ' + year + ' not found');
  }

  public getTaxClass5(year: string = '2018'): TaxClass5 {
    for (const d of this.staticYears) {
      if (d.year.toString() === year) {
        return d.getTaxClass5();
      }
    }
    throw new Error('Static Year ' + year + ' not found');
  }

  public getProgression(year: string = '2018'): ProgressionValue {
    for (const s of this.staticYears) {
      if (s.year.toString() === year) {
        return s.getProgression();
      }
    }

    throw new Error('Static Year ' + year + ' not found');
  }

  public getState(state: string, year: string = '2018'): State {
    const matchingStates = this.states.filter((s) => s.state === state);

    if (matchingStates.length === 0) {
      console.error(
        `STATICS getState. No matching state for ==> ${state}/${year} found. returning first state ${this.states[0].state}`
      );
      return this.states[0];
    }

    for (const s of matchingStates) {
      for (const y of s.validForYears) {
        if (y.toString() === year) {
          console.log('statics.getState state:' + state + ' year:' + year);
          return s;
        }
      }
    }

    throw new Error(`State or year found for state:${state} year:${year}`);
  }

  public static getRelocationFlat(relocationDate: Date): RelocationFlat {
    if (relocationDate > new Date(2021, 0, 1)) {
      throw new Error('The Relocation Flat dates for 2021 are not registered');
    }

    const flats: RelocationFlat[] = [];
    flats.push(new RelocationFlat(new Date(2015, 2, 1), 730, 1460, 322));
    flats.push(new RelocationFlat(new Date(2016, 2, 1), 746, 1493, 329));
    flats.push(new RelocationFlat(new Date(2017, 1, 1), 764, 1525, 337));
    flats.push(new RelocationFlat(new Date(2018, 2, 1), 787, 1573, 347));
    flats.push(new RelocationFlat(new Date(2019, 3, 1), 811, 1622, 357));
    flats.push(new RelocationFlat(new Date(2020, 3, 1), 820, 1639, 361));
    flats.push(new RelocationFlat(new Date(2021, 3, 1), 820, 1639, 361));

    const flat = flats.filter((f) => f.from > relocationDate)[0];
    return flat;
  }
}
