import firebase from 'firebase/app';
import 'firebase/firestore';
firebase.firestore.Timestamp;

export interface VollmachtRequestInfo {
  isPartner: boolean;
  clientId: string;
  taxadvisor: string;
  firstname: string;
  lastname: string;
  birthdate: number;
  city: string;
  financeDepartmentId: string;
  taxId: string;
}

export interface VollmachtUpdateInfo {
  clientId: string;
  status: VOLLMACHTUPDATEINFOSTATUS;
  meta?: {
    statusMessage?: string;
  };
}

export function isVollmachtUpdateInfo(obj: any): obj is VollmachtUpdateInfo {
  return (
    typeof obj.clientId === 'string' &&
    obj.status &&
    Object.values(VOLLMACHTUPDATEINFOSTATUS).includes(obj.status)
  );
}

export enum VOLLMACHTUPDATEINFOSTATUS {
  APPROVED = 'approved',
  CREATED = 'created',
  ERROR = 'error',
}
