import { ITransaction } from '@expresssteuer/qonto';
import { Timestamp } from '../../helpers/timestamp';
import { BankProvider } from '../bankProvider';

/**
 * An Entry from a BankTransation
 * Optimized for use with Qonto
 */
export class BankTransactionLine implements ITransaction {
  static extractYear(transaction: BankTransactionLine): number | null {
    /** ERSTATT.11/335/17656 EST-VERANL. 17  ----  extract the year 17 **/

    const yearReg = /\s(\d{2,4})[\s|\n|\r]/gim;
    const match = yearReg.exec(transaction.note);
    console.log(match);

    if (match) {
      if (match[1].length === 2) {
        return parseInt('20' + match[1]);
      }
      if (match[1].length === 4) {
        return parseInt(match[1]);
      }
    }
    return null;
  }

  //
  // TODO: refaktor.
  //static getFromCSVLine(csvLine: string): BankTransactionLine {
  //   const line_arr = csvLine.split(';');
  //   let transactionType = null;
  //   switch (line_arr[3]) {
  //     case 'LASTSCHRIFT':
  //       transactionType = 'direct_debit';
  //       break;
  //     case 'ONLINE-UEBERWEISUNG':
  //       transactionType = 'transfer';
  //       break;
  //     case 'GUTSCHR. UEBERWEISUNG':
  //       transactionType = 'income';
  //       break;
  //     case 'RUECKUEBERWEISUNG':
  //       transactionType = 'recall';
  //       break;
  //     default:
  //       transactionType = 'income';
  //   }
  //   let amount = 0;
  //   if (line_arr[14] !== undefined) {
  //     amount = parseFloat(line_arr[14].replace(',', '.'));
  //   }
  //   let side: 'credit' | 'debit' = 'credit';
  //   if (amount < 0) {
  //     side = 'debit';
  //   }

  //   return {
  //     id: '',
  //     clientId: '',
  //     provider: BankProvider.TAXADVISOR,
  //     amount: amount,
  //     amount_cents: amount * 100,

  //     side: side,
  //     operation_type: transactionType,
  //     currency: line_arr[15],

  //     settled_at: Timestamp.fromDate(
  //       moment(line_arr[1], 'DD.MM.YYYY').toDate()
  //     ),
  //     emitted_at: Timestamp.fromDate(
  //       moment(line_arr[2], 'DD.MM.YYYY').toDate()
  //     ),
  //     status: 'completed',
  //     note: line_arr[4], // decription that contains info about the transaction
  //     //creditorId: line_arr[5],
  //     //mandateRef: line_arr[6],
  //     //customerRef: line_arr[7],
  //     //collectersRef: line_arr[8],
  //     //originalAmount: line_arr[9],
  //     //chargeBack: line_arr[10],
  //     //personOfCharge: line_arr[11],
  //     //info: line_arr[16],

  //     //orderingPartyAccount: line_arr[0],
  //     //clientIBAN: line_arr[12],
  //     //clienBIC: line_arr[13],
  //     attachment_ids: [],
  //     reference: '',
  //     transaction_id: '',
  //     updated_at: null,
  //     vat_amount: 0,
  //     vat_amount_cents: 0,
  //     vat_rate: 0,
  //     label: '',
  //     label_ids: [],
  //     local_amount: 0,
  //     local_amount_cents: 0,
  //     local_currency: 'EUR',

  //   };
  // }

  // TODO: Replace with generic ID Generator
  /*static getId(bt: BankTransactionLog): string {
    const item = JSON.stringify(bt);
    const hash: string = createHash('sha256').update(item).digest('hex');
    return hash.toString();
  }*/

  /**
   * The transaction id Unique for a transaction
   */
  transaction_id: string = '';
  /**
   * If the transaction can be attached to a client, we set it here
   */
  clientId: string | null = null;

  provider: BankProvider = BankProvider.QONTO;
  amount: number = 0;
  amount_cents: number = 0;
  local_amount: number = 0;
  local_amount_cents: number = 0;

  metadata: BankTransactionMetadata | null = null;

  /**
   * credit or debit transaction
   * INBOUND or OUTBOUND transactions
   */
  side: 'credit' | 'debit' = 'credit';
  operation_type:
    | 'income'
    | 'transfer'
    | 'card'
    | 'direct_debit'
    | 'qonto_fee'
    | 'cheque'
    | 'recall'
    | 'swift_income'
    | string = 'income';
  currency: string = '';
  local_currency: string = '';
  label: string = '';
  settled_at: Timestamp | null = null;
  emitted_at: Timestamp | null = null;
  updated_at: Timestamp | null = null;
  /**
   * The status of the invoice line
   * completed, transaction is finished
   * pending transaction is made but not yet completed
   */
  status: 'pending' | 'reversed' | 'declined' | 'canceled' | 'completed' =
    'completed';
  note: string = '';
  reference: string = '';
  vat_amount: number = 0;
  vat_amount_cents: number = 0;
  vat_rate: number = 0;
  initiator_id: string = '';
  card_last_digits: number = 0;
  category: string = '';
  id: string = '';
}

export class BankTransactionMetadata {
  static getTemplate(): BankTransactionMetadata {
    return {
      invoiceId: null,
      clientId: null,
      taxcaseId: null,
    };
  }

  /**
   * If linked to an invoice, the invoice id is set.
   * If null, this Bank Transaction Line line is open and needs to be linked to an invoice.
   */
  invoiceId: string | null = null;
  /**
   * the client the  Bank Transaction Line is related to
   */
  clientId: string | null = null;
  /**
   * Optional the taxcase the  Bank Transaction Line is related to
   */
  taxcaseId: string | null = null;
  /**
   * flag indicating if whole transaction amount was processed By Payout process
   */
  processedByPayout?: boolean | null = null;
  /**
   * amount of transaction value proccessed by payout process
   */
  amountProcessedByPayout?: number | null = null;
}
