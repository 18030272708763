import { Timestamp } from '@expresssteuer/models';

export interface Period {
  start: Timestamp;
  end: Timestamp;
}
export interface CertificateWageReplacemenBenefitsData
  extends Record<string, unknown> {
  /**
   * DE : Art der Leistung, Beispiel Arbeitslosengelds
   */
  aidType: string;
  /**
   * DE : Leistungsbetrag
   */
  aidAmount: number;
  /**
   * DE : Leistungserbringer
   */
  aidOrigin: string;

  periods: Period[];
}

export class CertificateWageReplacemenBenefitsData {
  public static getTemplate(): CertificateWageReplacemenBenefitsData {
    return {
      aidAmount: 0,
      aidOrigin: '',
      aidType: '',
      periods: [],
    };
  }
}
