import firebase from 'firebase/app';
import { Person } from './person';

export class Partner extends Person {
  marriedSince: firebase.firestore.Timestamp | null = null;
  divorcedSince: firebase.firestore.Timestamp | null = null;

  constructor() {
    super();
  }
}
