export interface RiesterRuerupData extends Record<string, unknown> {
  type: 'riester' | 'ruerup';
  providerName: string;
  providerId: string;

  contractNumber: string;

  certifcateNumber: string;

  contributionType: string;
  contributionAmount: number;

  socialSecurity_allowance: string;
}
export class RiesterRuerupData {
  public static getTemplate(type: 'riester' | 'ruerup'): RiesterRuerupData {
    return {
      type,
      providerName: '',
      providerId: '',

      contractNumber: '',

      certifcateNumber: '',

      contributionType: '',
      contributionAmount: 0,

      socialSecurity_allowance: '',
    };
  }
}
