export class Tag {
  public static getTemplate(): Tag {
    return {
      name: '',
      color: '',
    };
  }

  name: string = '';
  color: string = '';
}
