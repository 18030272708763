import { DeepPartial } from './DeepObjectHelper';
import { Elster2020E10 } from './Elster2020E10';
import { ElsterDocument } from './elsterBase';

function getElsterDocumentNutzerDaten2020Template(
  e10Model: DeepPartial<Elster2020E10>,
  year: number | string
) {
  return {
    E10: {
      ...e10Model,
      '@': {
        xmlns: `http://finkonsens.de/elster/elstererklaerung/est/e10/v${year}`,
        version: `${year}`,
      },
    },
  };
}

export abstract class NutzerDaten2020 {
  public static getTemplate: (
    ...args: Parameters<typeof getElsterDocumentNutzerDaten2020Template>
  ) => NutzerDaten2020 = getElsterDocumentNutzerDaten2020Template;
  abstract E10: ReturnType<
    typeof getElsterDocumentNutzerDaten2020Template
  >['E10'];
}

function getElsterDocument2020Template(
  e10Model: DeepPartial<Elster2020E10>,
  year: number | string,
  financeDepartment?: string,
  state?: string,
  production: boolean = false
) {
  const oldElsterDocument = ElsterDocument.getTemplate();
  const testFinanceDepartment = '9198';
  const testState = 'EC';

  if (production === true && (!financeDepartment || !state)) {
    throw new Error(
      '`financeDepartment` and `state` have to be set for ElsterDocument2020 when running against production'
    );
  }

  return {
    Elster: {
      '@': { xmlns: 'http://www.elster.de/elsterxml/schema/v11' },
      TransferHeader: {
        ...oldElsterDocument.Elster.TransferHeader,
        Vorgang: 'send-Auth',
        Testmerker: production === false ? '700000004' : undefined,
        Empfaenger: {
          '@': { id: 'L' },
          Ziel: production === true ? state : testState,
        },
      },
      DatenTeil: {
        Nutzdatenblock: {
          NutzdatenHeader: {
            ...oldElsterDocument.Elster.DatenTeil.Nutzdatenblock
              .NutzdatenHeader,
            Empfaenger: {
              '@': { id: 'F' },
              '#text':
                production === true ? financeDepartment : testFinanceDepartment,
            },
          },
          Nutzdaten: NutzerDaten2020.getTemplate(e10Model, year),
        },
      },
    },
  };
}

export abstract class ElsterDocument2020 {
  public static getTemplate: (
    ...args: Parameters<typeof getElsterDocument2020Template>
  ) => ElsterDocument2020 = getElsterDocument2020Template;
  abstract Elster: ReturnType<typeof getElsterDocument2020Template>['Elster'];
}
