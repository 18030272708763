import firebase from 'firebase/app';
import {
  CHILDSITUATION,
  IChild,
  IChildCalculation,
  IChildExpenses,
  IChildOccupation,
  OCCUPATION,
  RelationshipOtherPerson,
} from '../../interfaces/child';
import { DISABILITY } from '../../interfaces/iTaxform';
import { IPerson } from '../../person/person';
import { ExpsAddress } from '../address/address';

export class ChildCalculation implements IChildCalculation {
  public static getTemplate(): ChildCalculation {
    return {
      disability: 0.0, // reduktion durch Behinderung
    };
  }

  disability: number = 0.0; // reduktion durch Behinderung
}

export class ChildExpenses implements IChildExpenses {
  public static getTemplate(): ChildExpenses {
    return {
      hasChildcareCost: false,
      hasSchoolfees: false,
      childcare: null, // Kinderbetreungskosten
      schoolfees: null, // Schulgeld
    };
  }
  hasChildcareCost: boolean = false;
  hasSchoolfees: boolean = false;
  childcare: number | null = null; // Kinderbetreungskosten
  schoolfees: number | null = null; // Schulgeld
}

export class ChildOccupation implements IChildOccupation {
  public static getTemplate(): ChildOccupation {
    return {
      status: null,
      startdate: null,
      enddate: null,
      description: '',
      isWorking: false, // Arbeitet das Kind zusätzlich?
      workingHours: null,
    };
  }
  public status: OCCUPATION | null = null;
  public startdate: firebase.firestore.Timestamp | null = null;
  public enddate: firebase.firestore.Timestamp | null = null;
  public description: string = '';
  public isWorking: boolean = false; // Arbeitet das Kind zusätzlich?
  public workingHours: number | null = null;
}

class ChildRelationshipOtherPerson {
  public static getTemplate(): RelationshipOtherPerson {
    return {
      unknown: true,
    };
  }
}

export class Child implements IChild, IPerson {
  public static getTemplate(): Child {
    return {
      firstname: '',
      lastname: '',
      situation: CHILDSITUATION.sharedParent,
      // sameHousehold: true,   // lebt im selben Haushalt
      birthdate: null,
      hasDisability: false,
      disability: DISABILITY['<25'],
      calculation: ChildCalculation.getTemplate(),
      expenses: ChildExpenses.getTemplate(),
      address: ExpsAddress.getTemplate(),
      occupation: [],
      careAddress: ExpsAddress.getTemplate(),
      relationshipOtherPerson: ChildRelationshipOtherPerson.getTemplate(),
    };
  }

  firstname = '';
  lastname = '';
  situation: CHILDSITUATION = CHILDSITUATION.sharedParent;
  // sameHousehold = true;   // lebt im selben Haushalt
  birthdate: firebase.firestore.Timestamp | null = null;
  hasDisability = false;
  disability: DISABILITY = DISABILITY['<25'];
  address: ExpsAddress = ExpsAddress.getTemplate();
  calculation: ChildCalculation = ChildCalculation.getTemplate();
  expenses: ChildExpenses = ChildExpenses.getTemplate();
  occupation: ChildOccupation[] = [];
  careAddress: ExpsAddress = ExpsAddress.getTemplate();
  relationshipOtherPerson?: RelationshipOtherPerson;
}
