import { DOCUMENTTYPE, Timestamp } from '@expresssteuer/models';

export type Extraction<T = unknown> = {
  created: Timestamp;
  id: string;
  year: string;
  taxcaseId: string;
  clientOrPartner: 'client' | 'partner';
  /**
   * Link to binary document in documentcenter
   */
  binaryDocumentIds: string[];
  type: DOCUMENTTYPE;
  extractedData?: T[];
  /**
   * If the record has been merged into a taxcase or a new taxcase has been build
   */
  processed: boolean;
};
