import { AbstractTimestamp } from '@expresssteuer/firebase-helper';
import {
  AdditionalExpense,
  GovermentalAid,
  Job,
  TaxForm,
  Timestamp,
} from '@expresssteuer/models';

export class Retention {
  public static getTemplate(
    clientId: string,
    year: string,
    timestamp: AbstractTimestamp
  ): Retention {
    return {
      clientId,
      year,
      status: RetentionStatus.New,
      originalTaxForm: TaxForm.getTemplate(),

      metadata: {
        createdAt: timestamp,
        updatedAt: timestamp,
      },
      additionalInformation: {
        govermentalAidClient: [],
        govermentalAidPartner: [],
        additionalExpensesClient: [],
        additionalExpensesPartner: [],
        jobs: [],
        clientReligion: {},
        partnerReligion: {},
      },
      reminderCount: 0,
    };
  }
}
export interface Retention {
  year: string;
  status: RetentionStatus;
  clientId: string;
  metadata: RetentionMetadata;
  /**
   * The complete taxform has to be saved as it could either be a taxform from a taxcase or a taxform from getTemplate
   *
   * TODO: refactor to original taxcaseId
   */
  originalTaxForm: TaxForm;
  generatedTaxformId?: string;
  appliedExtractions?: string[];
  additionalInformation: RetentionAdditionalInformation;
  reminderCount?: number;
}
export interface RetentionMetadata {
  createdAt: Timestamp;
  updatedAt: Timestamp;
}
export interface RetentionAdditionalInformation {
  govermentalAidClient: GovermentalAid[];
  govermentalAidPartner: GovermentalAid[];
  additionalExpensesClient: AdditionalExpense[];
  additionalExpensesPartner: AdditionalExpense[];
  jobs: Job[];
  clientReligion: {
    religion?: string;
    inChurch?: boolean;
  };
  partnerReligion: {
    religion?: string;
    inChurch?: boolean;
  };
}

export enum RetentionErrorStates {
  /**
   * client already submitted with another tax service provider
   */
  AlreadySubmitted = 'ALREADY_SUBMITTED',
  /**
   * client already has a taxcase this year
   */
  TaxcaseExists = 'TAXCASE_EXISTS',
  /**
   * unable to send retention to anonymous user
   */
  IsAnonymous = 'IS_ANONYMOUS',
  /**
   * clientStatus is deactivated, deleted or fraud
   */
  ClientDeactivated = 'CLIENT_DEACTIVATED',
  /**
   * For some reason the client managed to change their email address wrongly
   */
  EmailMismatch = 'EMAIL_MISMATCH',
  /**
   * general error, should be used seldomly
   */
  Failure = 'FAILURE',
}

export enum RetentionInProgressStates {
  /**
   * retention was created, should automatically move to WaitingForExtracts
   */
  New = 'NEW',
  /**
   * Retention is collecting extracts
   */
  WaitingForExtracts = 'WAITING_FOR_EXTRACTS',
  /**
   * Required extracts exists. More extracts can be added. After timeout or manual confirmation status is changed to Completing
   */
  ReadyToSend = 'READY_TO_SEND',
  /**
   * triggers completion of retention,  should automatically move to TaxformCreated
   */
  Completing = 'COMPLETING',
}
export enum RetentionCompletedStates { // completed states
  /**
   *  Retention has been completed and taxform was created
   */
  TaxformCreated = 'TAXFORM_CREATED',
  /**
   * The taxform generated by retention was completed and converted to a taxcase
   */
  TaxcaseCreated = 'TAXCASE_CREATED',
}

export const RetentionStatus = {
  ...RetentionErrorStates,
  ...RetentionInProgressStates,
  ...RetentionCompletedStates,
};
export type RetentionStatus =
  | RetentionErrorStates
  | RetentionInProgressStates
  | RetentionCompletedStates;
